import { useStyletron } from "baseui";
import { Cell, Grid } from "baseui/layout-grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { HeadingSmall, ParagraphLarge, ParagraphMedium, ParagraphSmall, ParagraphXSmall } from "baseui/typography";

import { app_content_scope_compliance_user_analytics, app_content_scope_users_submitted_responses } from "../../quries";
import { useLazyQuery } from "@apollo/client";
import { gridPaddingMarginOverrides } from "../overrides";
import { ArrowLeft, ChevronRight } from "baseui/icon";
import Table from "../intake/table";
import moment from "moment-timezone";
import StyledButton from "../styledButton";
import { Button, KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { Tag, KIND as TAG_KIND, VARIANT } from "baseui/tag";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import { Drawer } from "baseui/drawer";
import RightSidePageHeader from "../pageHeaders";
import { getServerUrl } from "../../apollo/client";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { ButtonGroup } from 'baseui/button-group';
import {
  Card, StyledBody,
} from "baseui/card";
import { ProgressBar } from "baseui/progress-bar";
import svgIcons from "../../svg";


const pageSize = 10;
const DataLoadingSkeleton = () => {
  return (
    <Grid overrides={gridPaddingMarginOverrides} gridMaxWidth={'unset'}>
      <Cell span={12}>
        <div style={{ marginTop: '2rem' }}>
          {new Array(4).fill('').map((item, index) => (
            <Skeleton
              width="100%"
              height="94px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginBottom: '15px',
                  },
                },
              }}
            />))}
        </div>
      </Cell>
    </Grid>
  )
}

const FormChannelDetailedMetrics = ({ channels, organization_id, selectedChannelId, onBackClick = () => {
  console.log('default')
}, ...props }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState([]);
  const [userFilter, setUserFilter] = useState(0);
  const [noOfComplianceUser, setNoOfComplianceUser] = useState(0);
  const [noOfInComplianceUser, setNoOfInComplianceUser] = useState(0);
  const [totalNoOfUser, setTotalNoOfUser] = useState(0);
  const [css, theme] = useStyletron();

  const [fetchChannelFormResponses] = useLazyQuery(app_content_scope_compliance_user_analytics);

  useEffect(() => {
    return () => {
      setData([]);
      setCount(0);
      setNoOfComplianceUser(0);
      setNoOfInComplianceUser(0);
      setTotalNoOfUser(0)
    }
  }, [])

  useEffect(() => {
    if (!selectedChannelId) {
      return;
    }
    loadData(selectedChannelId, 1)
  }, [selectedChannelId, userFilter])

  ///////////////////////////////////////////////////// COLUMN DATA ///////////////////////////////////////////////
  const formColumns = [
    {
      display: 'Name',
      key: 'name',
      formatter: (v) => v || ''
    },
    {
      display: 'Email',
      key: 'email',
      formatter: (v) => {
        return (
          <div style={{ width: `${100 / 6}vw` }}>
            {v || ''}
          </div>
        )
      }
    },
    {
      display: 'Incomplete forms',
      key: 'forms',
      formatter: (v) => {
        const formList = v || [];
        const filteredForms = formList.filter(el => el.is_submitted === false);
        const filteredFormLength = filteredForms.length
        const onClick = () => {
          setIsDrawerOpen(true);
          setDrawerData(filteredForms)
        }
        return (
          <StatefulPopover
            content={
              <ParagraphSmall marginBottom='0px' padding={'20px'}>
                Click for forms details
              </ParagraphSmall>
            }
            accessibilityType={'tooltip'}
            triggerType={TRIGGER_TYPE.hover}
          >
            <StyledButton
              isSelected={true}
              onClick={onClick}
            >
              {filteredFormLength} Forms
            </StyledButton>
          </StatefulPopover>
        )
      }
    },
    {
      display: 'Complete forms',
      key: 'forms',
      formatter: (v) => {
        const formList = v || [];
        const filteredForms = formList.filter(el => el.is_submitted === true);
        const filteredFormLength = filteredForms.length
        const onClick = () => {
          setIsDrawerOpen(true);
          setDrawerData(filteredForms)
        }
        return (
          <StatefulPopover
            content={
              <ParagraphSmall marginBottom='0px' padding={'20px'}>
                Click for forms details
              </ParagraphSmall>
            }
            accessibilityType={'tooltip'}
            triggerType={TRIGGER_TYPE.hover}
          >
            <StyledButton
              isSelected={true}
              onClick={onClick}
            >
              {filteredFormLength} Forms
            </StyledButton>
          </StatefulPopover>
        )
      }
    },
    {
      display: 'In Compliance',
      key: 'forms',
      formatter: (v) => {
        const formList = v || [];
        const filteredForms = formList.filter(el => el.is_submitted === true);
        return filteredForms.length === formList.length ? 'Yes' : 'No';
      }
    },
    {
      display: 'Date user added',
      key: 'created_at',
      formatter: (v) => v ? moment(v).format('ll') : ''
    }
  ]


  ///////////////////////////////////////////////////// END COLUMN DATA ///////////////////////////////////////////////

  ////////////////////////////////////////////////// Event Functions /////////////////////////////////////////////////
  const loadData = async (channelId, page) => {
    try {
      if (page <= 1) {
        setLoading(true);
      } else {
        setLoadingMore(true);
      }
      const channel_id = channelId
      let user_filter = 'all';
      if (userFilter == 1) {
        user_filter = 'compliance';
      } else if (userFilter == 2) {
        user_filter = 'in_compliance';
      }

      const { data: fetchedData } = await fetchChannelFormResponses({
        variables: {
          organization_id,
          channel_id,
          page,
          pageSize,
          user_filter
        },
        fetchPolicy: 'network-only'
      })

      const {
        app_content_scope_compliance_user_analytics: {
          data: formResponsesData = [],
          count: formResponsesCount = 0,
          no_of_compliant_user = 0,
          no_of_incompliant_user = 0,
          no_of_total_user = 0
        }
      } = fetchedData

      setTableData(formResponsesData, page)
      setCount(formResponsesCount);
      setPages(formResponsesCount);
      setPage(page);
      setNoOfComplianceUser(no_of_compliant_user);
      setNoOfInComplianceUser(no_of_incompliant_user);
      setTotalNoOfUser(no_of_total_user)
      setLoading(false);
      setLoadingMore(false);
    } catch (err) {
      console.log('loadData error', err);
      setLoading(false);
      setLoadingMore(false);
    }
  }

  const setPages = (formResponsesCount) => {
    // console.log('setPages', formResponsesCount, pageSize, formResponsesCount / pageSize)
    setTotalPages(formResponsesCount / pageSize)
  }

  const setTableData = (queryData, page) => {
    const formatedData = queryData
    if (page <= 1) {
      setData(formatedData)
    } else {
      setData([
        ...data || [],
        ...formatedData
      ])
    }
  }

  const closeResponseDrawer = () => {
    setIsDrawerOpen(false);
    setDrawerData([]);
  }

  const loadMoreData = () => {
    // console.log('loadMoreData', totalPages, page);
    let p = page;
    if (totalPages >= p) return loadData(selectedChannelId, ++p);
  }

  ////////////////////////////////////////////////// Render Forms /////////////////////////////////////////////////


  const renderTable = () => {
    const onFilterChange = (index) => {
      setUserFilter(index)
    }

    const onClickAll = () => {
      onFilterChange(0)
    }

    const onClickCompl = () => {
      onFilterChange(1)
    }

    const onClickInCompl = () => {
      onFilterChange(2)
    }

    const selectedChannel = channels.find(el => el.id === selectedChannelId)

    const compliancePerc = ((noOfComplianceUser / totalNoOfUser) * 100).toFixed(2);
    const inCompliancePerc = ((noOfInComplianceUser / totalNoOfUser) * 100).toFixed(2);

    return (
      <div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          })}
        >
          <div
            className={css({
              display: 'flex',
              flexDirection: 'row',
              paddingTop: '5px'
            })}
            onClick={onBackClick}
          >
            <ArrowLeft size={24} />
            <div>
              <ParagraphMedium marginBottom='0px'>Channels</ParagraphMedium>
              <ParagraphXSmall marginBottom='0px'>{selectedChannel?.name}</ParagraphXSmall>
            </div>
          </div>
          <StyledButton
            isSelected={true}
            marginTop={'0px'}
            onClick={() => {
              let user_filter = 'all';
              if (userFilter == 1) {
                user_filter = 'compliance';
              } else if (userFilter == 2) {
                user_filter = 'in_compliance';
              }
              window.open(`http://localhost:5001/app_content_scope_compliance_user_analytics/download/${organization_id}/channel/${selectedChannelId}?user_filter=${user_filter}`, '_blank').focus();
            }}
          >
            Download All Data
          </StyledButton>
        </div>
        {
          loading ? (
            <DataLoadingSkeleton />
          ) : (
            <div
              style={{
                marginTop: '1rem'
              }}
            >
              <div
                className={css({
                  display: 'flex',
                  marginBottom: '1rem'
                })}
              >
                <Card
                  className={css({
                    flex: 1
                  })}
                  overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        outline: userFilter === 0 ? `black solid` : 'transparent'
                      })
                    }
                  }}
                  onClick={onClickAll}
                >
                  <div
                    className={css({
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingBottom: '10px'
                    })}
                  >
                    <img src={svgIcons.UsersIcon} style={{ margin: 0, width: '30px', height: '30px' }} />
                    <ParagraphLarge marginBottom={0}>{totalNoOfUser}</ParagraphLarge>
                  </div>
                  <ParagraphXSmall marginBottom={0}>Total number of users in this channel</ParagraphXSmall>
                </Card>

                <Card
                  className={css({
                    marginLeft: '10px',
                    flex: 1
                  })}
                  overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        outline: userFilter === 1 ? `black solid` : 'transparent'
                      })
                    }
                  }}
                  onClick={onClickCompl}
                >
                  <div
                    className={css({
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingBottom: '10px'
                    })}
                  >
                    <img src={svgIcons.UsersIcon} style={{ margin: 0, width: '30px', height: '30px' }} />
                    <ParagraphLarge marginBottom={0}>{`${noOfComplianceUser} (${compliancePerc}%)`}</ParagraphLarge>
                  </div>
                  <ParagraphXSmall marginBottom={0}>Number of employees not in compliance</ParagraphXSmall>
                  <ProgressBar
                    overrides={{
                      BarContainer: {
                        style: ({ $theme }) => ({
                          marginLeft: '0 !important',
                          marginRight: '0 !important'
                        })
                      }
                    }}
                    value={compliancePerc}
                  />
                </Card>

                <Card
                  className={css({
                    marginLeft: '10px',
                    flex: 1
                  })}
                  overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        outline: userFilter === 2 ? `black solid` : 'transparent'
                      })
                    }
                  }}
                  onClick={onClickInCompl}
                >
                  <div
                    className={css({
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingBottom: '10px'
                    })}
                  >
                    <img src={svgIcons.UsersIcon} style={{ margin: 0, width: '30px', height: '30px' }} />
                    <ParagraphLarge marginBottom={0}>{`${noOfInComplianceUser} (${inCompliancePerc}%)`}</ParagraphLarge>
                  </div>
                  <ParagraphXSmall marginBottom={0}>Number of employees in compliance</ParagraphXSmall>
                  <ProgressBar
                    overrides={{
                      BarContainer: {
                        style: ({ $theme }) => ({
                          marginLeft: '0 !important',
                          marginRight: '0 !important'
                        })
                      }
                    }}
                    value={inCompliancePerc}
                  />
                </Card>
              </div>
              <Table
                data={data}
                count={count}
                columns={formColumns}
                loadMoreData={loadMoreData}
              />
            </div>
          )
        }

      </div>
    )
  }



  const renderFromResponse = () => {
    const drawerDataJson = drawerData || [];
    return (
      <Drawer
        isOpen={isDrawerOpen}
        autoFocus
        onClose={closeResponseDrawer}
        overrides={{
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: '20px',
            })
          },
          Root: {
            style: {
              zIndex: 2
            }
          }

        }}
      >
        <HeadingSmall
          style={{
            textAlign: 'center'
          }}
        >
          Forms
        </HeadingSmall>
        <div
          className={css({
            display: 'flex',
            flex: 1,
            height: '100%',
            flexDirection: 'column'
          })}
        >
          {(drawerDataJson || []).map((el, idx) => {
            const isCat = el.category_id ? true : false;
            const isHomeComp = el.home_component_id ? true : false
            return isCat &&
              (
                <div
                  className={css({
                    display: 'flex'
                  })}
                >
                  {idx + 1}.
                  <div
                    className={css({
                      width: '15px'
                    })}
                  />
                  <Breadcrumbs>
                    <span>{el.category_name}</span>
                    <span>{el.deck_name}</span>
                    <span>{el.card_name}</span>
                    <span><StyledLink>{`${el.title}`}</StyledLink> {` (last updated: ${el?.updated_at ? moment(el.updated_at).format('ll') : ''})`}</span>
                  </Breadcrumbs>
                </div>
              ) || (
                <div
                  className={css({
                    display: 'flex'
                  })}
                >
                  {idx + 1}.
                  <div
                    className={css({
                      width: '15px'
                    })}
                  />
                  <Breadcrumbs>
                    <span>{el.home_component_name}</span>
                    <span><StyledLink>{`${el.title}`}</StyledLink> {` (last updated: ${el?.updated_at ? moment(el.updated_at).format('ll') : ''})`}</span>
                  </Breadcrumbs>
                </div>
              )
          })}
        </div>
      </Drawer >
    )
  }


  // console.log('form-channel', { data, count, page, totalPages, })

  return (
    <>
      <Grid overrides={gridPaddingMarginOverrides} gridMaxWidth={'unset'}>
        <Cell span={12}>
          {selectedChannelId && renderTable()}
          {renderFromResponse()}
          <div>
            {
              loadingMore && (
                <Skeleton
                  width="100%"
                  height="94px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginBottom: '15px',
                      },
                    },
                  }}
                />
              )
            }

          </div>
        </Cell>
      </Grid>
    </>
  )
}

const mapStateToProps = props => {
  const { channels } = props.appBasicControls;
  return { channels };
};

export default connect(mapStateToProps)(FormChannelDetailedMetrics);