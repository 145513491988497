import { useStyletron } from "baseui";
import { Cell, Grid } from "baseui/layout-grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { HeadingSmall, ParagraphLarge, ParagraphMedium, ParagraphSmall, ParagraphXSmall } from "baseui/typography";

import { app_content_scope_compliance_user_analytics, app_content_scope_users_submitted_responses } from "../../quries";
import { useLazyQuery } from "@apollo/client";
import { gridPaddingMarginOverrides } from "../overrides";
import { ArrowLeft, ChevronRight } from "baseui/icon";
import Table from "../intake/table";
import moment from "moment-timezone";
import StyledButton from "../styledButton";
import { Button, KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { Tag, KIND as TAG_KIND, VARIANT } from "baseui/tag";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import { Drawer } from "baseui/drawer";
import RightSidePageHeader from "../pageHeaders";
import { getServerUrl } from "../../apollo/client";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { ButtonGroup } from 'baseui/button-group';
import {
  Card, StyledBody,
} from "baseui/card";
import { ProgressBar } from "baseui/progress-bar";
import svgIcons from "../../svg";


const pageSize = 10;
const DataLoadingSkeleton = () => {
  return (
    <Grid overrides={gridPaddingMarginOverrides} gridMaxWidth={'unset'}>
      <Cell span={12}>
        <div style={{ marginTop: '2rem' }}>
          {new Array(4).fill('').map((item, index) => (
            <Skeleton
              width="100%"
              height="94px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginBottom: '15px',
                  },
                },
              }}
            />))}
        </div>
      </Cell>
    </Grid>
  )
}

const FormChannelMetrics = ({ channels, organization_id, setSelectedChannelId = () => {}, ...props }) => {
  const [css, theme] = useStyletron();

  ///////////////////////////////////////////////////// COLUMN DATA ///////////////////////////////////////////////


  const channelColumns = [
    {
      display: 'Channel Name',
      key: 'name',
      formatter: (v) => v || ''
    },
    {
      display: 'Date created',
      key: 'created_at',
      formatter: (v) => v ? moment(v).format('ll') : ''
    },
    {
      display: '',
      key: 'id',
      formatter: (v) => {
        const onClick = () => {
          console.log(v)
          setSelectedChannelId(v)
        }
        return (
          <StyledButton
            className={css({
              height: 'auto !important',
              lineHeight: 'normal !important'
            })}
            onClick={onClick}
            overrides={{
              Root: {
                style: {
                  height: 'auto !important',
                  lineHeight: 'normal !important'
                }
              }
            }}
            isSelected
          >
            View Users
          </StyledButton>
        )
      }
    }
  ]

  ///////////////////////////////////////////////////// END COLUMN DATA ///////////////////////////////////////////////

  ////////////////////////////////////////////////// Event Functions /////////////////////////////////////////////////


  ////////////////////////////////////////////////// Render Forms /////////////////////////////////////////////////

  const renderChannelsList = () => {
    return (
      <div className={css({ marginTop: '10px' })}>
        {renderChannelTable()}
      </div>
    )
  }


  const renderChannelTable = () => {
    return (
      <Table
        data={channels}
        count={channels.length}
        columns={channelColumns}
      />
    )
  }


  // console.log('form-channel', { data, count, page, totalPages, })

  return (
    <>
      <Grid overrides={gridPaddingMarginOverrides} gridMaxWidth={'unset'}>
        <Cell span={12}>

          {renderChannelsList()}
        </Cell>
      </Grid>
    </>
  )
}

const mapStateToProps = props => {
  const { channels } = props.appBasicControls;
  return { channels };
};

export default connect(mapStateToProps)(FormChannelMetrics);