import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import { client } from '../../../apollo/client';
import { crunchbase_integration_by_org, fetch_form_schema_from_crunchbase } from '../../settings/queries';
import Button from '../../styledButton';

import { useDispatch } from 'react-redux';
import { importFormFieldAction } from '../../../redux/actions/appBasicControls';

export default ({
  value: selectedApiId,
  onChange,
  importFormField,
  title,
  theme,
  marginTop,
  placeholder,
  organization_id
}) => {

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [integrationId,setIntegrationId] = React.useState('');
  const [fieldsLoading, setFieldsLoading] = React.useState(false);

  const dispatch = useDispatch();

  const loadCrunchBaseIntegrations = async () => {
    try {
     
      const { data } = await client.query({
        query: crunchbase_integration_by_org,
        variables: {
          organization_id
        },
        fetchPolicy: 'network-only'
      });
      let integrationData = data.all_crunchbase_integration_by_org;
      setData(integrationData);
      setLoading(false);

    } catch (error) {
      console.log('loadCrunchBaseIntegrations error', error)
      setLoading(false);
    }
  }

  React.useEffect(() => {
    setLoading(true);
    loadCrunchBaseIntegrations();
  }, []);

  const loadFormFieldsFromCrunchBase = async (crunchbase_integration_id) => { 
    try {
      if (crunchbase_integration_id) {

        setFieldsLoading(true)
      
        const { data } = await client.query({
          query: fetch_form_schema_from_crunchbase,
          variables: {
            integration_id: crunchbase_integration_id
          },
          fetchPolicy: 'network-only'
        })
        setFieldsLoading(false)

        const formFields = (data.fetch_form_schema_from_crunchbase) ? await JSON.parse(data.fetch_form_schema_from_crunchbase) : '';
        if (formFields) {
          importFormField ? importFormField(formFields) : dispatch(importFormFieldAction(formFields));
        }
      }
    } catch (error) {
      console.error('Import Fields Error', error);
    }
  }

  let matchingOption = data.filter((el) => el.id == selectedApiId);

  const onOptionChange = ({ option }) => {
    if (option){ 
      onChange(option.id, option)
      setIntegrationId(option.id)
    }
    else {
      onChange(null);
      setIntegrationId(null)
    }
  };


  return (
    <div >
      
      <Select
        options={data}
        onChange={onOptionChange}
        value={matchingOption}
        placeholder={selectedApiId ? '' : placeholder}
        multi={false}
        labelKey={'title'}
        valueKey={'id'}
        maxDropdownHeight="300px"
        type={TYPE.search}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
        isLoading={loading}
      />
      <div style={{ marginTop: '5px' }}>

     {selectedApiId && <Button
        isSelected
        isLoading={fieldsLoading}  
         disabled={fieldsLoading}
        marginTop={'5px'}
        onClick={async ()=>{
          const crunchbase_integration_id = (integrationId) ? integrationId : selectedApiId;
          if (crunchbase_integration_id) {
            const confirmBox = window.confirm(
              "Fields will be imported from Zoho Creator. Do you really want to import ?"
            )
            if (confirmBox === true) {
              await loadFormFieldsFromCrunchBase(crunchbase_integration_id)
            }
          }
        }}>
        Import Fields
      </Button>}
      </div>
    </div>
  )
}
