import React, { useState, useEffect } from 'react';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Input } from 'baseui/input';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { Cell } from 'baseui/layout-grid';
import { Accordion, Panel } from "baseui/accordion";
import TextArea from '../../channel/textarea';
import { Button } from 'baseui/button';
import BaseUiTextArea from './baseuiTextArea';

export default ({ value, onChange, ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (value)
      setOptions(value);
  }, [value]);

  const addNew = () => {
    setOptions(
      [
        ...options,
        {
          title: '',
          description: '',
          is_expanded: false,
          position: options.length,
        }
      ]
    )
  };

  const setValues = (index, key, value) => {
    let o = [...options];
    o[index][key] = value;
    setOptions(o);
    onChange(o);
  };

  return <>
    <Accordion
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            marginLeft: 0,
          })
        },
        // PanelContainer: {
        //   style: ({ $theme }) => ({
        //     backgroundColor: 'white',
        //     marginBottom: '1rem'
        //   })
        // }
      }}
    >
      {options.map((op, index) => {
        return <Panel title={op.title || `Block ${index + 1}`}>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
            <Input
              value={op.title}
              onChange={e => {
                setValues(index, 'title', e.target.value)
              }}
              placeholder="Your title"
              clearOnEscape
            />
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <LabelMedium style={{ marginBottom: '16px' }}>Description</LabelMedium>
            <div style={{ position: 'relative' }}>
              <TextArea
                value={op.description}
                onChange={(v) => setValues(index, 'description', v)}
                textAreaKey="editCategory"
                placeholder="Your description"
              ></TextArea>
            </div>
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <LabelMedium style={{ marginBottom: '16px' }}>Style Override</LabelMedium>
            <div style={{ position: 'relative' }}>
              <BaseUiTextArea
                value={op?.style_override || ''}
                onChange={(v) => setValues(index, 'style_override', v)}
                placeholder={'Write style override here!'}
              />
            </div>
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <Checkbox
              checked={op.is_expanded}
              onChange={e => setValues(index, 'is_expanded', !op.is_expanded)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is Expanded</ParagraphSmall>
            </Checkbox>
          </Cell>
        </Panel>;
      })}
    </Accordion>
    <Cell
      span={[1, 12]}
      overrides={{
        Cell: {
          style: () => ({
            paddingLeft: '0px !important',
          }),
        },
      }}
    >
      <Button
        style={{
          marginTop: '16px',
        }}
        onClick={() => {
          addNew();
        }}>Add New Section</Button>
    </Cell>
  </>;
}