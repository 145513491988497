import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { Select, SIZE } from "baseui/select";
import {
  HeadingSmall,
  HeadingXLarge,
  HeadingXSmall,
  LabelMedium,
  LabelSmall
} from 'baseui/typography';
import { navigate } from '@reach/router';
import InfiniteScroll from 'react-infinite-scroll-component';
import gql from 'graphql-tag';
import AddContentButton from '../addContentButton';
import { editorBtnOptions } from '../../../data/cardBuilderOptions';
import FeedItem from './feed';
import AddNew from './connectModal';
import { gridPaddingOverrides } from '../overrides';
import { client } from '../../apollo/client';
import { fetchCommunications } from './api-data';
import styled from '@emotion/styled';
import * as apiData from './api-data';
const PAGE_SIZE = 10;


const CommunicationOptions = [
  { label: 'All', id: 'all' },
  { label: 'Push Notifications', id: 'push' },
  { label: 'Articles', id: 'article' },
  { label: 'Emails', id: 'email' },
  { label: 'Popups', id: 'popup' },
];

const FeedsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 0 0px 0 0px;
  padding-bottom: 10px;
`;
const FeedsHeaderLeftSide = styled.div`
  display: flex; 
  flex-direction: row;
`;

const FeedsHeaderRightSide = styled.div`
  display: flex; 
  justify-content: flex-end; 
  margin-top: 5px;
`;

const InifinteScrollLoaderContainer = styled.div`
  text-align: center; 
  margin-left: 32px;
  margin-top: 32px; 
  color: ${props => props.theme.colors.primaryA};
`


const Feeds = ({
  setSelectedChannel = () => {},
  selectedChannel,
  tabFilter,
  channels,
  organization_id,
  defaultTheme,
  theme,
  filteredChannels,
  filteredGroups,
  onDetailPress,
}) => {
  const [addNewCommunicationOpen, setAddNewCommunicationOpen] = React.useState(false);
  const [communicationType, setCommunicationType] = React.useState([CommunicationOptions[0]]);
  const [page, setPage] = React.useState(1);
  const [dataToRender, setDataToRender] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);

  const refetch = () => {
    fetchCommunications(communicationType, organization_id, selectedChannel, tabFilter, editorBtnOptions, PAGE_SIZE, page)
      .then(({ data }) => {
        if (page > 1) {
          setDataToRender(dataToRender.concat(data.communication_app_content_scope_by_app_content_scope));
        } else {
          setDataToRender(data.communication_app_content_scope_by_app_content_scope);
        }
        if (data.communication_app_content_scope_by_app_content_scope.length == 0) {
          setHasMore(false);
        }
      })
  }

  React.useEffect(() => {
    setDataToRender([]);
    setHasMore(true);
  }, [communicationType, selectedChannel, tabFilter]);

  React.useEffect(() => {
    refetch();
  }, [communicationType, page, selectedChannel, tabFilter]);

  const onClose = () => {
    setAddNewCommunicationOpen(false);
    setDataToRender([]);
    setPage(1);
    setHasMore(true);
    refetch();
  };

  const onArchive = (communication_id) => {
    client.mutate({
      mutation: gql`mutation archiveCommunication($id: ID!){
          archiveCommunication(id: $id)
        }`,
      variables: { id: communication_id }
    }).then(() => {
      let data = JSON.parse(JSON.stringify(dataToRender));
      let index = data.findIndex(el => el.communication_id == communication_id);
      console.log(index);
      data[index].communication.archived = true;
      setDataToRender(data);
    });
  };

  // ---------------------------------------------> Getter Function -------------------------------------------->
  const getChannelsOfGroup = (groupId) => {
    return filteredChannels.filter(c => c.app_content_scope_group_id == groupId).sort((a, b) => a.position - b.position);
  };

  const getChannelsList = () => {
    let channelsList = {
      '__ungrouped': [{ id: '*', name: 'All' }]
    };
    if (filteredGroups) {
      filteredGroups.map(group => {
        const groupChannels = getChannelsOfGroup(group.id);
        channelsList[group.name] = groupChannels || [];
      })
    }

    return channelsList;
  }
  // ---------------------------------------------> Render Function -------------------------------------------->

  const renderFeedsHeader = () => {
    const channelsList = getChannelsList();
    return (
      <FeedsHeaderContainer>
        <FeedsHeaderLeftSide>
          <div >
            <LabelSmall
              style={{
                marginBottom: '16px',
                textAlign: 'center'
              }}
            >
              Communication Method
            </LabelSmall>
            <Select
              options={CommunicationOptions}
              value={communicationType}
              placeholder="Select Communication Type"
              clearable={false}
              overrides={{
                ControlContainer: {
                  style: {
                    outline: '#C4C4C4 solid',
                  }
                }
              }}
              onChange={params => setCommunicationType(params.value)}
            />
          </div>
          <div style={{ marginLeft: 19, }}>
            <LabelSmall
              style={{
                marginBottom: '16px',
                textAlign: 'center'
              }}
            >
              Channels Posted In
            </LabelSmall>
            <Select
              options={channelsList}
              value={selectedChannel}
              labelKey="name"
              overrides={{
                ValueContainer: {
                  style: {
                    minWidth: "20vh",
                  }
                },
                ControlContainer: {
                  style: {
                    outline: '#C4C4C4 solid',
                  }
                },
                Dropdown: {
                  style: {
                    maxHeight: "40vh",
                    minWidth: "20vh",
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }
                }
              }}
              placeholder={!selectedChannel && "Select Channel" || ""}
              onChange={params => {
                setSelectedChannel(params.option);
                setPage(1);
              }}
            />
          </div>
        </FeedsHeaderLeftSide>

        {tabFilter !== "Archives" && (
          <FeedsHeaderRightSide>
            <AddContentButton
              buttonText={'New Message'}
              helpText={"Click to start a new push notification, newsroom article, or email"}
              inputPlaceholder={'Add New Message'}
              inputValue={"name"}
              setInputValue={() => console.log('Hey')}
              onSave={null}
              isLoading={false}
              buttonClickOverride={
                () => {
                  navigate(`/app/${organization_id}/connect/add`);
                  // setAddNewCommunicationOpen(true)
                }
              }
            />
          </FeedsHeaderRightSide>
        )}
      </FeedsHeaderContainer>
    );
  }

  const InfiniteScrollLoader = () => {
    return (
      <InifinteScrollLoaderContainer theme={theme}>
        <b>Loading...</b>
      </InifinteScrollLoaderContainer>
    )
  }


  return (
    <div style={{ position: 'relative' }}>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'} >
        <Cell span={12}>
          {renderFeedsHeader()}
        </Cell>
        <Cell span={12}>
          <div
            id="scrollableDiv"
            style={{
              // height: 'calc(100vh - 47vh)',
              overflow: 'hidden',
            }}
          >
            <InfiniteScroll
              dataLength={dataToRender.length}
              next={() => {
                let p = page;
                setPage(++p)
              }}
              hasMore={hasMore}
              loader={<InfiniteScrollLoader />}
              scrollableTarget="app-container"
              endMessage={
                <p style={{ textAlign: 'center', marginTop: '32px', color: theme.colors.primaryA }}>
                  {dataToRender.length <= 0 ? <b>No connect messages have been created.  Click New Message to get started.</b> : ''}
                </p>
              }
            >
              {dataToRender.map((element, index) => {
                return <FeedItem
                  feed={element}
                  index={index}
                  key={index}
                  tabFilter={tabFilter}
                  defaultTheme={defaultTheme}
                  onArchive={onArchive}
                  onDetail={onDetailPress}
                ></FeedItem>
              })}
            </InfiniteScroll>
          </div>
        </Cell>
      </Grid>
      {addNewCommunicationOpen && (
        <AddNew
          isOpen={addNewCommunicationOpen}
          onClose={onClose}
          channels={channels}
          organization_id={organization_id}
        ></AddNew>
      )}
    </div>
  )
}


export default Feeds;