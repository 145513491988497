import fetch from 'cross-fetch';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  concat,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getToken } from '../auth/index';

console.log('process.env --------------------------', process.env.BASE_DOMAIN)

// domain fix
const BASE_DOMAIN = process.env.BASE_DOMAIN || `cardware-api-bff-de77ba2761ca.herokuapp.com`;
const IS_HTTPS = (BASE_DOMAIN.indexOf('.com') > -1) || (BASE_DOMAIN.indexOf('.app') > -1)

const BASE_URI = `${IS_HTTPS ? 'https' : 'http'}://${BASE_DOMAIN}`;
const TOUR_BASE_URI = `https://micro-tour.herokuapp.com`;

let options = {
  uri: `${BASE_URI}/graphql`,
  fetch: fetch,
};

const wsLink = new WebSocketLink({
  uri: `${IS_HTTPS ? 'wss' : 'ws'}://${BASE_DOMAIN}/graphql`,
  options: {
    reconnect: true,
  },
  webSocketImpl: typeof window == 'undefined' ? require('ws') : WebSocket,
});
const httpLink = createUploadLink(options);
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);
const authMiddleware = new ApolloLink(async (operation, forward) => {
  const tokens = await getToken();
  let headers = {};
  try {
    headers = operation.getContext().headers || {};
  } catch (e) {

  }
  operation.setContext({
    headers: {
      authorization: tokens.idToken ? `Bearer ${tokens.idToken}` : '',
      'organization-id': tokens.organization_id,
      'source': 'admin',
      ...headers
    },
  });
  return forward(operation);
});
export const client = new ApolloClient({
  link: concat(authMiddleware, splitLink),
  cache: new InMemoryCache(),
});
export const getAnalyticsGraphQLClient = (orgName) => {
  return new ApolloClient({
    link: createUploadLink({
      uri: `https://analytics-microservice.herokuapp.com/graphql/${orgName}`,
      fetch: fetch,
    }),
    cache: new InMemoryCache(),
  });
};
export const tourClient = new ApolloClient({
  link: createUploadLink({
    uri: `${TOUR_BASE_URI}/graphql`,
    fetch: fetch,
  }),
  cache: new InMemoryCache(),
});
export const getServerUrl = () => BASE_URI;