import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';
import * as Sentry from "@sentry/react";

const initialState = {
  appBasicControls: {
    cardBackData: [],
    groups: [],
    channels: [],
    cards: [],
    category: [],
    decks: [],
    organization: null,
    questionCards: [],
    isLoading: false,
    user: {},
    showAlert: {},

    organization_settings: {},
    products: {
      organization_id: '',
      token: '',
      organization_name: '',
      admin_url: '',
      api_url: '',
      pwa_url: '',
    },
    theme:
      typeof window !== `undefined`
        ? localStorage.getItem('cardware_admin_theme')
          ? localStorage.getItem('cardware_admin_theme')
          : 'light'
        : 'light',
  },
};

const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleware), sentryReduxEnhancer)
);

export default store;
