import * as React from 'react';
import { MODE, StatefulButtonGroup } from 'baseui/button-group';
import moment from 'moment';
import gql from 'graphql-tag';
import Button from '../styledButton';
import RightSidePageHeader from '../pageHeaders';
import ApplicationMetrics from './application-metrics';
import LearningMetrics from './learning-metrics';
import QuizMetrics from './quiz-metrics';
import FeedbackMetrics from './feedback-metrics';
import { gridPaddingOverrides } from '../overrides';
import { client as reactClient } from '../../apollo/client';
import { Tabs, Tab } from "baseui/tabs";
import { Grid } from 'baseui/layout-grid';
import FormMetrics from './form-metrics';


const Dashboard = (props) => {
  const [activeKey, setActiveKey] = React.useState('0');
  const [lastUpdate, setLastUpdate] = React.useState(new Date());
  const [headerTitle, setHederTitle] = React.useState('Application Data');
  const [headerDescription, setHeaderDescription] = React.useState(`Overview of Cardware Application Analytics \n Last Quiz taken at ${moment(lastUpdate).format('lll')}`);
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    reactClient.query({
      query: gql`
        query getLastUpdatedAt {
            getLastUpdatedAt
            fetch_user_names_by_ids {
              id
              name
            }
        }
  `
    }).then((result) => {
      setLastUpdate(result.data.getLastUpdatedAt);
      setUsers(result.data.fetch_user_names_by_ids);
    })
  }, []);

  const setHeaderAttr = (tab) => {
    switch(tab) {
      case '0':
        setHederTitle('Application Data');
        setHeaderDescription(`Overview of Cardware Application Analytics \n Last Quiz taken at ${moment(lastUpdate).format('lll')}`);
      break;
      case '1':
        setHederTitle('Learning Data');
        setHeaderDescription(`Overview of Cardware Learning Analytics \n Last Quiz taken at ${moment(lastUpdate).format('lll')}`);
      break;
      case '2':
        setHederTitle('Quiz Data');
        setHeaderDescription(`Overview of Cardware Quiz Analytics \n Last Quiz taken at ${moment(lastUpdate).format('lll')}`);
      break;
      case '3':
        setHederTitle('Feedback Data');
        setHeaderDescription(`Overview of Cardware Feedback Analytics \n Last Quiz taken at ${moment(lastUpdate).format('lll')}`);
      break;
      case '4':
        setHederTitle('Form Data');
        setHeaderDescription(`Overview of Cardware Form Analytics \n Last Response taken at ${moment(lastUpdate).format('lll')}`);
      break;
    }
  }

  const onClickTab = (tab) => {
    setActiveKey(tab);
    setHeaderAttr(tab)
  }

  const onApplicationMetrics = () => onClickTab('0');
  const onLearningMetrics = () => onClickTab('1');
  const onQuizMetrics = () => onClickTab('2');
  const onFeedbackMetrics = () => onClickTab('3');
  const onFormMetrics = () => onClickTab('4');
  return (
    <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'} >
      <RightSidePageHeader
        icon={null}
        title={headerTitle}
        rightContent={
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <StatefulButtonGroup
              mode={MODE.radio}
              initialState={{ selected: 0 }}
            >
              <Button
                onClick={onApplicationMetrics}
              >
                Application Metrics
              </Button>
              <Button
                onClick={onLearningMetrics}
              >
                Learning Metrics
              </Button>
              <Button
                onClick={onQuizMetrics}
              >
                Quiz Metrics
              </Button>
              <Button
                onClick={onFeedbackMetrics}
              >
                Feedback Metrics
              </Button>
              <Button
                onClick={onFormMetrics}
              >
                Form Metrics
              </Button>
            </StatefulButtonGroup>
          </div>
        }
        headerDescription={headerDescription}
      />
      {activeKey == '0' && <ApplicationMetrics {...props} setActiveKey={setActiveKey} />}
      {activeKey == '1' && <LearningMetrics {...props} setActiveKey={setActiveKey} />}
      {activeKey == '2' && <QuizMetrics {...props} setActiveKey={setActiveKey} />}
      {activeKey == '3' && <FeedbackMetrics {...props} setActiveKey={setActiveKey} />}
      {activeKey == '4' && <FormMetrics {...props} setActiveKey={setActiveKey} />}
    </Grid>
    // <Tabs
    //   onChange={({ activeKey }) => {
    //     setActiveKey(activeKey);
    //   }}
    //   activeKey={activeKey}
    //   overrides={{
    //     TabPanel: {
    //       style: {
    //         padding: '0px !important',
    //         backgroundColor: 'red'
    //       }
    //     }
    //   }}
    // >
    //   <Tab
    //     title="Application Metrics"
    //     overrides={{
    //       TabPanel: {
    //         style: {
    //           padding: '0px !important',
    //           backgroundColor: 'red'
    //         }
    //       }
    //     }}>
    //     <ApplicationMetrics {...props} />
    //   </Tab>

    //   <Tab title="Learning Metrics">
    //     <LearningMetrics {...props}/>
    //   </Tab>
    // </Tabs>
  );
};

export default Dashboard;