import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { setCards } from '../../redux/actions/appBasicControls';

const getSubForCardCreate = orgId => {
  return gql`
    subscription OnCardCreate {
      cardCreated(orgId:"${orgId}"){
        card_deck {
          deck_id
          card_id
          position
          published
          isNew
          id
        }
        card {
          id
          title
          sub_title
          card_type
          file_data
          front_image_data
          archived
          archived
          full_bleed
        }
      }
    }
  `;
};

const getSubForCardUpdate = id => {
  return gql`
    subscription OnCardCreate {
      cardUpdated(id:"${id}"){
        card_deck {
          card_id
          deck_id
          position
          published
          isNew
          id
        }
        card {
          id
          title
          sub_title
          card_type
          file_data
          front_image_data
          archived
          full_bleed
        }
      }
    }
  `;
};

let createSub;
let updateSubs = [];
const SubscribeCard = (props) => {
  React.useEffect(() => {
    console.log('[SUBSCRIBE] CARD CREATE');
    createSub = props.subscribeToMore({
      document: getSubForCardCreate(props.orgId),
      variables: { orgId: props.orgId },
      updateQuery: (data1, { subscriptionData }) => {
        if (
          subscriptionData &&
          subscriptionData.data &&
          subscriptionData.data.cardCreated
        ) {
          let c = JSON.parse(JSON.stringify(props.cards || []));
          data1 = JSON.parse(JSON.stringify(data1));
          let { cardCreated } = subscriptionData.data;
          console.log('[SUBSCRIBE] CARD CREATE NEW EVENT');
          data1.card_decks.push({
            ...cardCreated.card_deck,
            card: cardCreated.card,
          });
          // data1.card_decks = c;
          return data1;
        }
      },
    });
    return createSub;
  }, []);


  React.useEffect(() => {
    updateSubs = (props.cards || []).map((el, index) => {
      return props.subscribeToMore({
        document: getSubForCardUpdate(el.card_id),
        variables: { id: el.card_id },
        updateQuery: (data1, { subscriptionData }) => {
          if (
            subscriptionData &&
            subscriptionData.data &&
            subscriptionData.data.cardUpdated
          ) {
            let c = JSON.parse(JSON.stringify(props.cards || []));
            data1 = JSON.parse(JSON.stringify(data1));
            let { cardUpdated } = subscriptionData.data;
            // let index = c.findIndex(el => el.card.id == cardUpdated.card.id);
            console.log('[SUBSCRIBE] replace subscription ', c[index].card.title);
            if (c[index].id === cardUpdated.card_deck.id) {
              c[index] = {
                ...cardUpdated.card_deck,
                card: cardUpdated.card,
              };
            };
            data1.card_decks = c;
            return data1;
          }
        },
      });
    });
    return () => {
      updateSubs.forEach(el => el());
    }
  }, [props.cards]);
  return null;
}

// class SubscribeCard extends React.Component {
//   constructor(props) {
//     super(props);
//   }

//   componentDidMount() {

//   }

//   componentWillUnmount() {
//     console.log('[SUBSCRIBE] UNMOUNTED CARD CREATE');
//     this.createSub && this.createSub();
//   }

//   render() {

//     return <></>;
//   }
// }

const mapStateToProps = props => {
  const { cards } = props.appBasicControls;
  return { cards };
};

export default connect(mapStateToProps)(SubscribeCard);
