import * as React from 'react';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import { StatefulTooltip } from 'baseui/tooltip';
import Overflow from 'baseui/icon/overflow';
import { useStyletron } from 'baseui';
import { StatefulPopover, PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { StatefulMenu, NestedMenus } from 'baseui/menu';
import Toggle from 'react-toggle';
import { Search } from 'baseui/icon';
import { Input, SIZE } from 'baseui/input';
import { showAlert } from '../../redux/actions/appBasicControls';
import 'react-toggle/style.css';
import './card.css';
import store from '../../redux/store';
import settingsIcon from './images/settings.png';
import { connect } from 'react-redux';
import RenderIcon from '../icons/renderIcon';
import styled from '@emotion/styled';
import svgIcons from '../../svg';
import PropTypes from 'prop-types';
import { Label3 } from 'baseui/typography';
import { Tag, VARIANT } from 'baseui/tag';
import * as MaterialIcons from 'react-icons/md';

const BaseImageUrl =
  '';

const CardComponent = props => {
  const {
    title,
    count,
    toggle,
    imageSrc,
    custom_icon,
    deck,
    channels,
    decks,
    isOpen,
    tabFilter,
    onToggleClick,
    onClone,
    onMoveDeck,
    onArchive,
    onUnArchive,
    onMoveCard,
    onMoveSlide,
    onSettingsClick,
    onCardClick,
    showSettingsIcon = true,
    isDeck,
    isQuizType,
    componentType,
    organization_color_palette_index = 0,
    organization,
    showMenuOptions = true,
    width,
    height,
    full_bleed = false,
    cover_type,
    icon_name,
    iconSource,
    isHomeComponent,
    currentSlideComponent,
    home_component_app_content_scopes,
    isCopyCard,
    showPublishToggle,
    cardType,
    onDeleteCard,
    showArchiveOption,
    snapshot,
    provided,
    cardTags
  } = props;

  const [css, theme] = useStyletron();
  const selectedChannel = store.getState().appBasicControls.selectedChannel;
  const categories = store.getState().appBasicControls.category;
  const [searchText, setSearchText] = React.useState('');
  const [newChildItems, setChildItems] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(false);
  }, [toggle])

  React.useEffect(() => {
    let childItems = [];
    if (isHomeComponent) {
      childItems = home_component_app_content_scopes.map(item => ({
        label: item.home_component.title,
        selectedComponent: item
      })).filter(item => item.selectedComponent.home_component.id !== currentSlideComponent.home_component.id)
        .filter(item => {
          if (componentType && componentType !== item.selectedComponent.home_component.component_type) {
            return false;
          }
          return true
        })
        ; // except selfComponent
    } else if (isDeck) {
      childItems = channels
        ? channels
          .map(item => ({ label: item.name, selectedChannel: item }))
          .filter(item =>
            selectedChannel ? item.label !== selectedChannel.name : true
          )
        : [];
    } else {
      childItems = {};
      const newItems = decks
        ? categories
          .map(({ category_id, category }) => {
            const { name } = category;
            let subDecks = [];
            decks.map(item => {
              if (
                category_id === item.category_id &&
                item.deck_category_deck &&
                deck.deck_category_deck &&
                item.deck_category_deck.title !==
                deck.deck_category_deck.title
              ) {
                subDecks.push({
                  label: item.deck_category_deck.title,
                  selectedDeck: item,
                });
              }
            });
            if (subDecks.length > 0) {
              if (searchText !== '') {
                subDecks = subDecks.filter(
                  item => item.label.toLowerCase().indexOf(searchText) !== -1
                );
              }
              childItems[name] = subDecks;
              return {
                [name]: subDecks,
              };
            }
            return false;
          })
          .filter(item => item !== false)
        : [];
    }
    if (searchText !== '')
      if (isDeck) {
        childItems = childItems.filter(
          item => item.label.toLowerCase().indexOf(searchText) !== -1
        );
      }
    setChildItems(childItems);
  }, [searchText]);

  const filter = e => {
    const value = e.target.value;
    setSearchText(value.toLowerCase());
  };

  const onMenuItemClick = ({ item, event }) => {
    if (item.label === 'Settings') {
      onSettingsClick(event);
    }
    else if (item.label === 'Clone') {
      onClone(event);
    }
    else if (item.label === 'Archive') {
      onArchive(event);
    }
    else if (item.label === 'UnArchive') {
      onUnArchive(event);
    } else if (item.label === 'Delete') {
      onDeleteCard(event)
    }

  }

  let menuItems = [
    { label: 'Settings' },
    { label: 'Move ->' },
    { label: 'Clone' },
    { label: tabFilter && tabFilter === 'archived' ? 'UnArchive' : 'Archive' },
  ];

  if (tabFilter === 'archived') {
    menuItems = [
      { label: 'Settings' },
      { label: 'Move ->' },
      { label: tabFilter && tabFilter === 'archived' ? 'UnArchive' : 'Archive' },
    ];
  }

  let imageGradientColor = '#FFFFFF';
  if (icon_name || custom_icon) {
    imageGradientColor = '#52535C'
  }

  const menuOptions = React.useMemo(() => {
    if (cardType === 'single_card') {
      if (showArchiveOption) {
        return [
          { label: 'Delete' },
          { label: tabFilter && tabFilter === 'archived' ? 'UnArchive' : 'Archive' }
        ]
      }
      return [
        { label: 'Delete' }
      ]
    }
    return [
      { label: 'Settings' },
      { label: 'Move ->' },
      // { label: 'Clone' },
      { label: tabFilter && tabFilter === 'archived' ? 'UnArchive' : 'Archive' },
    ]
  }, [cardType])
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        width: '100%',
        minHeight: '64px',
        background: snapshot.isDragging ? 'rgb(128 128 128 / 40%)' : 'rgb(234, 234, 234)',
        padding: '16px',
        borderRadius: '10px',
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'column',
        ...provided.draggableProps.style
      }}>

      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center', minWidth: '60px' }}>

            <div className={css({
              marginRight: '16px',
            })} >
              {imageSrc || BaseImageUrl ? <img
                className={css({
                  height: '44px',
                  backgroundSize: 'cover !important',
                  position: 'relative',
                  float: 'left',
                  borderRadius: '8px',
                  marginBottom: '0px',
                })}
                src={imageSrc || BaseImageUrl}
              /> :
                <MaterialIcons.MdImage size={'45px'} />
              }
            </div>
          </div>
          <Label3>{title}</Label3>
          <div
            style={{
              display: 'flex',
              flex: 1,
              marginLeft: '10px',
              flexWrap: 'wrap'
            }}
          >
            {(cardTags || []).map((el, index) => {
              return (
                <Tag
                  key={`${el.id}-${index}`}
                  closeable={false}
                  variant={VARIANT.solid}
                >
                  {el?.tag?.name || ''}
                </Tag>
              )
            })}
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {showMenuOptions && <div
            className={css({
              marginRight: '16px',
              alignItems: "center",
              justifyContent: "center",
              display: "flex"
            })}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <StatefulPopover
              initialState={{ isOpen: false }}
              dismissOnEsc={true}
              dismissOnClickOutside={true}
              overrides={{
                Body: {
                  style: {
                    zIndex: !isDeck ? 3 : 'unset'
                  }
                }
              }}
              content={() => (
                <NestedMenus>
                  <StatefulMenu
                    items={menuOptions}
                    overrides={{
                      List: {
                        style: {
                          width: '120px',
                          maxHeight: '1000px',
                          overflow: 'auto',
                        },
                      },
                      Option: {
                        props: {
                          getChildMenu: item => {
                            if (item.label === 'Move ->') {
                              return (
                                <div style={{ maxHeight: '250px', zIndex: 101 }}>
                                  <Input
                                    value={searchText}
                                    onChange={e => filter(e)}
                                    placeholder={
                                      isHomeComponent ? 'Choose Component' :
                                        isDeck
                                          ? 'Choose Channel'
                                          : 'Choose Deck by Category'
                                    }
                                    clearOnEscape
                                    size={SIZE.compact}
                                    endEnhancer={<Search size="18px" />}
                                    overrides={{
                                      Input: {
                                        style: ({ $theme }) => ({
                                          outline: `none !important`,
                                          border: '0 !important',
                                          margin: '10px',
                                        }),
                                      },
                                    }}
                                  />
                                  <StatefulMenu
                                    items={newChildItems}
                                    onItemSelect={({ item, event }) => {
                                      if (isHomeComponent) {
                                        onMoveSlide(item.selectedComponent);
                                      } else if (isDeck) {
                                        onMoveDeck(item.selectedChannel);
                                      } else {
                                        onMoveCard(item.selectedDeck);
                                      }
                                    }}
                                    overrides={{
                                      List: {
                                        style: {
                                          width: '300px',
                                          height: '150px',
                                          overflow: 'auto',
                                          zIndex: 101,
                                        },
                                      },
                                      Option: {
                                        props: {
                                          getItemLabel: item => item.label,
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              );
                            }
                          },
                        },
                      },
                    }}
                    onItemSelect={onMenuItemClick}
                  />
                </NestedMenus>
              )}
              placement={PLACEMENT.top}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer', opacity: 1, marginRight: '16px' }}
                onClick={() => {
                  //  setSelectedModule(location);
                  // setIsEditOpen(true);
                }}
              >
                <path
                  d="M19.2022 12.0725C19.243 11.7287 19.2701 11.3713 19.2701 11C19.2701 10.6287 19.243 10.2713 19.1886 9.9275L21.4859 8.1125C21.6898 7.9475 21.7442 7.645 21.6219 7.41125L19.4469 3.6025C19.3109 3.355 19.0255 3.2725 18.7808 3.355L16.0756 4.455C15.5047 4.015 14.9065 3.6575 14.2404 3.3825L13.8326 0.4675C13.7918 0.1925 13.5607 0 13.2889 0H8.93884C8.66696 0 8.44946 0.1925 8.40868 0.4675L8.00086 3.3825C7.33477 3.6575 6.72304 4.02875 6.1657 4.455L3.46053 3.355C3.21584 3.25875 2.93037 3.355 2.79443 3.6025L0.619417 7.41125C0.483478 7.65875 0.537854 7.9475 0.755355 8.1125L3.05271 9.9275C2.99834 10.2713 2.95755 10.6425 2.95755 11C2.95755 11.3575 2.98474 11.7287 3.03912 12.0725L0.741761 13.8875C0.537853 14.0525 0.483478 14.355 0.605823 14.5887L2.78083 18.3975C2.91677 18.645 3.20224 18.7275 3.44693 18.645L6.1521 17.545C6.72304 17.985 7.32117 18.3425 7.98727 18.6175L8.39509 21.5325C8.44946 21.8075 8.66696 22 8.93884 22H13.2889C13.5607 22 13.7918 21.8075 13.819 21.5325L14.2268 18.6175C14.8929 18.3425 15.5047 17.9713 16.062 17.545L18.7672 18.645C19.0119 18.7413 19.2973 18.645 19.4333 18.3975L21.6083 14.5887C21.7442 14.3412 21.6898 14.0525 21.4723 13.8875L19.2022 12.0725ZM11.1139 15.125C8.87087 15.125 7.0357 13.2688 7.0357 11C7.0357 8.73125 8.87087 6.875 11.1139 6.875C13.3568 6.875 15.192 8.73125 15.192 11C15.192 13.2688 13.3568 15.125 11.1139 15.125Z"
                  fill={'black'}
                />
              </svg>
            </StatefulPopover>
          </div>}

          {
            showPublishToggle && (
              <StatefulTooltip
                content={() => (
                  <div padding={'20px'}>{toggle ? 'Toggle off to Publish' : 'Toggle on to Publish'}</div>
                )}
                returnFocus
                autoFocus
              >
                <div
                  className={css({
                    width: theme.sizing.scale1200,
                  })}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Toggle
                    id="cheese-status"
                    checked={toggle}
                    icons={false}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onChange={e => {
                      console.log('toggle change')
                      if (isLoading) {
                        return true;
                      }
                      e.preventDefault();
                      e.stopPropagation();
                      if (tabFilter === "archived") {
                        return props.dispatch(
                          showAlert({
                            msg: 'Archived content can\'t be published!',
                            error: true,
                          })
                        );
                      }
                      setLoading(true);
                      onToggleClick(e);
                    }}
                  />
                </div>
              </StatefulTooltip>
            )
          }
        </div>
      </div>
    </div>
  );
};

CardComponent.propTypes = {
  showPublishToggle: PropTypes.bool,
  onDeleteCard: PropTypes.func
}

CardComponent.defaultProps = {
  showPublishToggle: true,
  onDeleteCard: () => {

  }
}

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  return {
    organization,
  };
};

export default connect(mapStateToProps)(CardComponent);
