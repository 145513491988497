import React, { useEffect, useState } from 'react'
import { useStyletron } from 'baseui';
import { Drawer } from 'baseui/drawer';
import { HeadingSmall, LabelMedium } from 'baseui/typography';
import { FileUploader } from "baseui/file-uploader"
import { getServerUrl } from '../../apollo/client';
import axios from 'axios';
import { getToken } from '../../auth/index';
import { Spinner } from "baseui/spinner";
import { toaster } from 'baseui/toast';
import { Grid, Cell } from 'baseui/layout-grid';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { gridPaddingOverrides } from '../overrides';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import useProgressTracker from '../progress-tracker/useProgressTracker';

function ImportUsers(props) {
    const { isOpen, onClose, organization_id, title, type, isTrainer, refetchUsers } = props;
    const [css, theme] = useStyletron();

    const [loading, setLoading] = useState(false);
    const [importFile, setImportFile] = useState(null);
    const [importLoading, setImportLoading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);

    const [importUsers, setImportUsers] = useState([]);

    const [filtered, setFiltered] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectdUserLength, setSelectdUserLength] = useState(0);
    const [selectdUser, setSelectdUser] = useState([]);
    const [newTags, setNewTags] = useState([]);

    const [isNewTagModalOpen, setIsNewTagModalOpen] = useState(false);

    const { request_id, ProgressComponent, setReportRequestId } = useProgressTracker(onProgressDone, onError);


    function onProgressDone() {
        setImportFile(null)
        setImportLoading(false);
        setReportRequestId('');
        onModalClose();
        refetchUsers();
    }

    function onError() {
        setImportFile(null)
        setImportLoading(false);
        setReportRequestId('')
        showToaster('Something went wrong in importing csv file', 'negative')
    }

    const checkNewTagAndImport = (isFirstCheck, addTags) => {
        let userImportList = selectdUser.filter(item => item.checked)
        if (userImportList.length == 0) return

        let newteamsTag = []
        userImportList.map(item => {
            item.new_teams.forEach(element => {
                newteamsTag.push(element)
            });
        })

        if (isFirstCheck) {
            if (newteamsTag.length == 0) {
                setNewTags([])
                onImport(userImportList, false)
            } else {
                let tags = Array.from(new Set(newteamsTag))
                setNewTags(tags)
                setIsNewTagModalOpen(true)
            }
        } else {
            setIsNewTagModalOpen(false)
            onImport(userImportList, addTags)
        }
    }


    async function onImport(importUserData, addTags) {
        if (importLoading) return
        setImportLoading(true);

        const tokens = await getToken();
        const config = {
            headers: { Authorization: tokens.idToken ? `Bearer ${tokens.idToken}` : '' }
        };

        const formData = fileUploaded ? {} : new FormData();

        let role = isTrainer ? 'trainer' : type == 'admin' ? 'admin' : 'user'

        let updateData = addTags ? importUserData : importUserData.map((item => {
            return {
                ...item,
                new_teams: []
            }
        }))

        formData["user_type"] = role
        formData["user_data"] = updateData
        formData["domain"] = window.location.origin
        formData["organization_id"] = organization_id


        axios.post(`${getServerUrl()}/import_app_user_from_csv/${organization_id}`, formData, config)
            .then((result) => {
                const {
                    status,
                    request_id,
                    message,
                    error,
                } = result?.data || {}

                if (!status) {
                    throw new error
                }

                setReportRequestId(request_id)

                // if (result?.data?.error) {
                //     setImportFile(null)
                //     setImportLoading(false);
                //     showToaster(result?.data?.error ? result?.data?.error : 'Something went wrong in importing csv file', 'negative')
                // } else {
                //     setImportFile(null)
                //     setImportLoading(false);

                //     onModalClose()
                //     refetchUsers()

                // }

            }).catch((err) => {
                setImportFile(null)
                setImportLoading(false);
                showToaster('Something went wrong in importing csv file', 'negative')
            })
    }

    async function fetchImportUserData() {
        if (importLoading) return
        setImportLoading(true);

        const tokens = await getToken();
        const config = {
            headers: { Authorization: tokens.idToken ? `Bearer ${tokens.idToken}` : '' }
        };

        const formData = fileUploaded ? {} : new FormData();

        let role = isTrainer ? 'trainer' : type == 'admin' ? 'admin' : 'user'

        formData.append('file', importFile);
        formData.append('user_type', role);
        formData.append('organization_id', organization_id);

        axios.post(`${getServerUrl()}/import_app_user_from_csv_formated_json/${organization_id}`, formData, config)
            .then((result) => {
                console.log(result);

                if (result?.data?.error) {
                    setImportFile(null)
                    setImportLoading(false);
                    showToaster(result?.data?.error ? result?.data?.error : 'Something went wrong in importing csv file', 'negative')
                } else {
                    setImportFile(null)
                    setImportLoading(false);
                    setFileUploaded(true)
                    setImportUsers(result.data)
                }

            }).catch((err) => {
                setImportFile(null)
                setImportLoading(false);
                showToaster('Something went wrong in importing csv file', 'negative')
            })
    }

    const showToaster = (msg, method = 'info') => {
        let toastKey = toaster[method](
            <>
                {msg}
            </>,
            {
                overrides: {
                    InnerContainer: {
                        style: { width: "100%" }
                    }
                }
            }
        );
        setTimeout(() => { toaster.clear(toastKey) }, 3000);
    };


    const onModalClose = () => {
        onClose()
        setImportLoading(false)
        setFileUploaded(false)
        setImportUsers([])
        setSearchText("")
        setLoading(false)
        setSelectdUserLength(0)
        setSelectdUser([])
    }


    const onCancel = (checkBack) => {
        if (checkBack && fileUploaded) {
            setFileUploaded(false)
            setImportFile(null)
            setImportUsers([])
        } else {
            onModalClose()
        }
    }

    const getCheckedStatus = (valueId, email) => {
        let isExists = false
        if (valueId) {
            isExists = selectdUser.find(el => {
                return (
                    el.id == valueId
                );
            });
        } else if (email) {
            isExists = selectdUser.find(el => {
                return (
                    el.email == email
                );
            });
        }
        if (!isExists) return false;
        if (isExists.checked === undefined) return true;
        return isExists.checked;
    };

    const handleSelectAll = (isChecked) => {
        let users = importUsers?.map(item => {
            return {
                ...item,
                checked: isChecked
            }
        })
        setSelectdUser(users);
    }


    const markChecked = (id, email, checked) => {
        if (selectdUser.length === 0 && id) {

            setSelectdUser(importUsers.map(item => {
                return {
                    ...item,
                    checked: item.id === id && checked ? true : false
                }
            }))
            return
        } else if (selectdUser.length === 0 && email) {
            setSelectdUser(importUsers.map(item => {
                console.log(' item.name === name && checked ? true : false', { item, email, checked }, item.email === email && checked ? true : false)
                return {
                    ...item,
                    checked: item.email === email && checked ? true : false
                }
            }))
            return
        }

        console.log(`markChecked 3`, selectdUser)

        let selectdUserArr = [...selectdUser]
        let findIndex = selectdUserArr.findIndex(item => (id && (item.id === id)) || (item.email === email))
        if (selectdUserArr[findIndex]) {
            selectdUserArr[findIndex].checked = checked;
        }
        setSelectdUser(selectdUserArr)
    }

    useEffect(() => {
        if (searchText == '') {
            setFiltered(importUsers)
        } else {
            setFiltered(importUsers.filter(e => e.name.toLowerCase().startsWith(searchText)))
        }
    }, [searchText, importUsers]);

    useEffect(() => {
        setSelectdUserLength(selectdUser.filter(item => item.checked).length)
    }, [selectdUser]);

    // console.log('importUsers useProgressTracker', request_id)

    return (
        <Drawer
            isOpen={isOpen}
            autoFocus
            onClose={onCancel}
            overrides={{
                DrawerBody: {
                    style: ({ $theme }) => ({
                        marginLeft: '20px',
                    })
                },
            }}>
            <HeadingSmall
                style={{
                    textAlign: 'center'
                }}
            >Import {title}</HeadingSmall>

            {!fileUploaded ? <div >
                <FileUploader
                    accept={["application/vnd.ms-excel", "text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                        setImportFile(acceptedFiles[0])
                    }}
                />

                {importFile && <LabelMedium style={{
                    width: '100%',
                    marginRight: '48px',
                    marginTop: '16px',

                }} >File Selected {importFile.name}</LabelMedium>}

            </div> :
                <div>

                    <Grid overrides={gridPaddingOverrides}>
                        <div
                            style={{
                                float: 'left',
                                width: '100%',
                                marginRight: '48px',
                                paddingTop: '16px',
                                paddingBottom: '16px',

                            }}>
                            {
                                request_id && ProgressComponent
                            }
                        </div>

                        <div
                            style={{
                                float: 'left',
                                width: '100%',
                                marginRight: '48px',
                                paddingTop: '16px',
                                paddingBottom: '16px',

                            }}>
                            <Input
                                placeholder="Search by name"
                                clearOnEscape
                                clearable
                                onChange={search => setSearchText(search.target.value.toLowerCase())}
                                endEnhancer={<Search size="18px" />}
                            />
                        </div>
                        <div
                            className={css({
                                paddingTop: theme.sizing.scale300,
                                paddingRight: '24px',
                                marginBottom: '8px',
                                minWidth: '300px',
                                float: 'left',
                                display: 'block'
                            })}
                        >
                            <Checkbox
                                onChange={event =>
                                    handleSelectAll(event.target.checked)
                                }
                                labelPlacement={LABEL_PLACEMENT.right}
                                isIndeterminate={selectdUserLength > 0 && selectdUserLength < filtered.length}
                                checked={filtered.length === selectdUserLength}
                            >
                                Select All
                            </Checkbox>
                        </div>

                        <div
                            style={{
                                position: 'relative',
                                float: 'left',
                                width: '100%',
                                backgroundColor: theme.colors.primaryB,
                                marginRight: '8px', //: '48px',
                                paddingTop: '8px', //: '16px',
                                paddingBottom: '16px',
                                borderRadius: '4px',
                                marginBottom: '48px',
                            }}
                        >
                            {filtered.map((el, i2) => {
                                return (
                                    <div
                                        key={i2}

                                        className={css({
                                            paddingTop: theme.sizing.scale300,
                                            paddingLeft: '24px',
                                            paddingRight: '24px',
                                            minWidth: '300px',
                                            float: 'left',
                                            display: 'block'
                                        })}
                                    >
                                        <Checkbox
                                            checked={getCheckedStatus(el.id, el.email)}
                                            onChange={e =>
                                                markChecked(el.id, el.email, e.target.checked)
                                            }
                                            labelPlacement={LABEL_PLACEMENT.right}
                                        >
                                            {`${el.name}`}
                                        </Checkbox>
                                    </div>
                                );
                            })}

                        </div>
                    </Grid>
                </div>}



            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    minHeight: '50px',
                    paddingBottom: '10px',
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    flexDirection: 'column',
                }} >
                <div
                    className={css({
                        justifyContent: 'flex-end',
                        display: 'flex'
                    })}>

                    <ModalButton
                        onClick={() => onCancel(true)}>
                        Cancel
                    </ModalButton>

                    <ModalButton
                        isLoading={loading}
                        onClick={() => {
                            if (fileUploaded) {
                                checkNewTagAndImport(true)
                            } else if (!fileUploaded && importFile != null) {
                                fetchImportUserData()
                            }
                        }}>
                        {importLoading && <Spinner style={{ marginRight: "5px" }} size="20px" />} {fileUploaded ? "Import" : "Upload"}
                    </ModalButton>
                </div>
            </div>

            <Modal
                onClose={() => setIsNewTagModalOpen(false)}
                isOpen={isNewTagModalOpen}
                animate
                autoFocus
                size={SIZE.default}
                role={ROLE.dialog}
            >
                <ModalHeader>Action Required</ModalHeader>

                <ModalBody>
                    It looks like you are adding these new teams below you your account. Please confirm this action.
                    <ul>
                        {newTags.map(tag => {
                            return <li>{tag}</li>
                        })}
                    </ul>
                </ModalBody>
                <ModalFooter>
                    <ModalButton onClick={() => setIsNewTagModalOpen(false)}
                        kind={ButtonKind.tertiary}>
                        Cancel
                    </ModalButton>
                    <ModalButton onClick={() => checkNewTagAndImport(false, false)} >Do not add tags</ModalButton>
                    <ModalButton
                        onClick={() => checkNewTagAndImport(false, true)}
                    >Add as new tags</ModalButton>
                </ModalFooter>
            </Modal>
        </Drawer>
    )
}

export default ImportUsers