import React from "react";
import { ProgressBar } from "baseui/progress-bar";
import { ParagraphMedium } from "baseui/typography";

const UploadProgress = ({progress, progressText}) => {

    return <div>
        <ProgressBar
            value={progress}
            getProgressLabel={(value) => <ParagraphMedium paddingBottom={0} marginBottom={'10px'}>{`${value}% import progress is complete`}</ParagraphMedium>}
            showLabel
            overrides={{
                BarProgress: {
                    style: ({ $theme, $value }) => {
                        return {
                            ...$theme.typography.font350,
                            backgroundColor: $theme.colors.positive,
                            color: $theme.colors.mono200,
                            position: 'relative',
                            marginBottom: '0',
                            ':after': {
                                position: 'absolute',
                                content: $value > 5 ? `"${$value}%"` : '',
                                right: '10px',
                            },
                        };
                    },
                },
                Bar: {
                    style: ({ $theme }) => ({
                        height: $theme.sizing.scale800,
                    }),
                },
            }}
        />
    </div>

}

export default UploadProgress