import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { H6, H5 } from 'baseui/typography';
import { Input } from 'baseui/input';
import { Textarea } from "baseui/textarea";
import { useMutation } from '@apollo/client';
import { LabelLarge, ParagraphSmall } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { deck_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import Button from '../styledButton';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { addDeckMutation, addQuizDeckMutation } from '../../quries';
import { saveModalOnEnterPress } from '../../util';
import titlesLength from '../../../data/titlesLength.json';

export default ({ position, category_id, organization_id, onClose, defaultTheme, isHomeComponent, homeComponentId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [css, theme] = useStyletron();
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [addDeck, { loading: onUpdateLoader, data: createdDeck }] = useMutation(
    addDeckMutation
  );

  const [addQuizDeck, { loading: onQuizUpdateLoader, data: createdQuizDeck }] = useMutation(
    addQuizDeckMutation
  );

  const DECK_TYPES = {
    'content': {
      display: 'Content',
      method: addDeck
    },
    'quiz': {
      display: 'Quiz',
      method: addQuizDeck
    }
  };
  const [deckType, setDeckType] = React.useState(Object.keys(DECK_TYPES)[0]);

  const onSave = () => {
    if (!title || title === '') return;
    setIsLoading(true);
    if (!title) return;
    let dataToSave = {
      title,
      description,
      cover_image_data: null,
      color: 'red',
      archived: false,
      organization_color_palette_index: 0,
      icon_name: null,
      cover_type: 'photo',
      created_by_id: '6e7bcba0-f194-4acf-b476-26d6594e88cb',
      created_by_name: null,
      full_bleed: false,
      landscape: false,
      home_featured: false,
      home_featured_image_data: null,
      position: position,
      published: false,
      organization_id,
      category_id: category_id,
      home_component_id: homeComponentId
    };
    if (deckType == 'quiz') {
      dataToSave = {
        title: dataToSave.title,
        description: dataToSave.description,
        organization_color_palette_index: dataToSave.organization_color_palette_index,
        cover_type: dataToSave.cover_type,
        created_by_id: dataToSave.created_by_id,
        position: dataToSave.position,
        category_id: dataToSave.category_id,
        home_component_id: homeComponentId,
        organization_id: dataToSave.organization_id,
        published: false,
      }
    };
    eval(DECK_TYPES[deckType].method)(
      {
        variables: {
          ...dataToSave
        }
      }
    );
  };

  React.useEffect(() => {
    setIsLoading(false);
    if (createdDeck || createdQuizDeck) {
      setIsOpen(false);
      setTitle('');
      setDescription('');
      onClose();
    }
  }, [createdDeck, createdQuizDeck]);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  // const bgColor = defaultTheme === 'dark' ? 'white' : '#5D64AB';
  const bgColor = defaultTheme === 'dark' ? '#5D64AB' : '#5D64AB';

  return (
    <React.Fragment>
      <div
        className={css({
          width: '180px !important',
          minWidth: '180px !important',
          height: '250px',
          marginBottom: '5px',
          marginRight: 0,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          position: 'relative',
          float: 'left',
          border: '2px solid #5D64AB',
          borderRadius: '5px',
          // background: defaultTheme === 'white' ? '#ECEBEB' : 'rgba(74, 76, 160, 0.2)',
          // border: '2px solid rgba(74, 76, 160, 0.2)',
          background: '#FAFAFA',
          border: '3px solid #4A4CA0',
          cursor: 'pointer',
        })}
        onClick={() => {
          setIsOpen(true);
          setTitle('');
          setDescription('');
          onClose();
        }}
      >
        <div
          className={css({
            position: 'absolute',
            width: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
          })}
        >
          <svg
            width="15"
            height="15.75"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={css({
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
              cursor: 'pointer',
            })}
          >
            <path
              d="M32.7917 0.125H4.20833C1.94208 0.125 0.125 1.9625 0.125 4.20833V32.7917C0.125 35.0375 1.94208 36.875 4.20833 36.875H32.7917C35.0375 36.875 36.875 35.0375 36.875 32.7917V4.20833C36.875 1.9625 35.0375 0.125 32.7917 0.125ZM28.7083 20.5417H20.5417V28.7083H16.4583V20.5417H8.29167V16.4583H16.4583V8.29167H20.5417V16.4583H28.7083V20.5417Z"
              fill={bgColor}
            />
          </svg>
          <p
            className={css({
              textAlign: 'center',
              // marginTop: '72px',
              color: bgColor,
              fontFamily: 'Roboto',
              fontWeight: '700',
              fontSize: '14px',
              // textTransform: 'uppercase',
              maxWidth: '180px !important',
              wordBreak: 'break-all !important',
              marginBottom: '0px'
            })}
          >
            Add Deck
          </p>
        </div>
      </div>
      <Modal
        // animate={true}
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setTitle('');
          setDescription('');
          onClose();
        }}
      >
        <ModalHeader>{}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <div
              className={css({
                display: 'flex',
              })}
            >
              <H5>Add Deck</H5>
              <HelpQuestionMark text={deck_help}></HelpQuestionMark>
            </div>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Title</H6>
              <Input
                value={title}
                onChange={e => {
                  e.target.value.length <= titlesLength.deck && setTitle(e.target.value)
                }}
                placeholder="Your title"
                clearOnEscape
                onKeyDown={e =>
                  saveModalOnEnterPress({ e, onEnterPress: onSave })
                }
              />
              <ParagraphSmall style={{ marginBottom: '0px' }}>{title.length}/{titlesLength.deck}</ParagraphSmall>
              {title && title.length > titlesLength.deck && <p style={{ marginTop: '0.5rem', color: 'red' }}>Max {titlesLength.deck} characters allowed</p>}
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6>Description</H6>
              <Textarea
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Your description"
                clearOnEscape
                onKeyDown={e =>
                  saveModalOnEnterPress({ e, onEnterPress: onSave })
                }
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <H6 style={{ marginBottom: '0px' }}>Deck Type</H6>
              <StatefulButtonGroup
                mode={MODE.radio}
                initialState={{ selected: 0 }}
              >
                {Object.keys(DECK_TYPES).map((el, index) => {
                  return (<Button key={index} onClick={() => setDeckType(el)}>{DECK_TYPES[el].display}</Button>)
                })}
              </StatefulButtonGroup>
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            isLoading={isLoading}
            onClick={() => {
              onSave();
            }}
            disabled={!title || title === '' || title.length > titlesLength.deck}
          >
            Add Deck
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
