import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useRef } from "react";
import { import_progress_trackers_by_id } from "./query";

const useProgressTrackerQuery = (requestId) => {
  const [request_id, setRequestId] = React.useState('')
  const [importProgressData, setImportProgressData] = React.useState({});
  const progressIntervalRef = useRef();

  const [fetchProgressTracker, { data: progressTrackerData, error: progressTrackerError }] = useLazyQuery(
    import_progress_trackers_by_id,
    { variables: { id: request_id },  fetchPolicy: 'network-only',}
  );

  useEffect(() => {
    setRequestId(requestId)
  }, [requestId])

  React.useEffect(() => {
    const {
      import_progress_trackers_by_id = {}
    } = progressTrackerData || {}


    setImportProgressData(import_progress_trackers_by_id)
  }, [progressTrackerData]);

  useEffect(() => {
    return () => {
      // console.log('useProgressTrackerQuery unmounted')
      if (progressIntervalRef?.current) {
        clearInterval(progressIntervalRef?.current)
        progressIntervalRef.current = null
        return;
      }
    }
  }, [])

  React.useEffect(() => {
    if (request_id && !progressIntervalRef?.current) {
      // console.log('useProgressTracker interval created')
      let intervalId = setInterval(() => {
        // console.log('useProgressTracker interval', request_id);
        if (!request_id) {
          clearInterval(progressIntervalRef?.current)
          progressIntervalRef.current = null
          return;
        }
        fetchProgressTracker()
      }, 2000)
      progressIntervalRef.current = intervalId
    } else if (!request_id) {
      // console.log('useProgressTracker clear interval')
      clearInterval(progressIntervalRef?.current)
      progressIntervalRef.current = null
    }
  }, [request_id]);

  return ({
    importProgressData
  })
}

export default useProgressTrackerQuery;