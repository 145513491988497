import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import { client } from '../../../apollo/client';
import { apiQuery } from '../../apiMappings/query';

const MongoIntegrationPicker = ({
  value: selectedMongoIntegId,
  onChange,
  title,
  theme,
  marginTop,
  placeholder,
  organization_id
}) => {

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "organization_mongos",
          query: `{\"where\": {\"organization_id\": \"${organization_id}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        setData(JSON.parse(response.data));
      }
      setLoading(false);
    });
  }, []);


  let matchingOption = data.filter((el) => el.id == selectedMongoIntegId);

  const onOptionChange = ({ option }) => {
    if (option) onChange(option.id, option)
    else onChange(null)
  };

  // console.log('Mongo Integration Picker', { data, selectedMongoIntegId, organization_id });

  return (
    <>
      {title ? <h5
        style={{
          color: theme.colors.primaryA,
          marginTop: marginTop || theme.sizing.scale1200,
        }}
      >
        {title}
      </h5> : null}
      <Select
        options={data}
        onChange={onOptionChange}
        value={matchingOption}
        placeholder={selectedMongoIntegId ? '' : placeholder}
        multi={false}
        labelKey={'name'}
        valueKey={'id'}
        maxDropdownHeight="300px"
        type={TYPE.search}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
        isLoading={loading}
      />
    </>
  )
}

export default MongoIntegrationPicker;