
import React, { useCallback, useMemo, useState } from "react";
import { FlexGrid } from "baseui/flex-grid"
import { Cell, Grid } from "baseui/layout-grid"
import styled from '@emotion/styled';
import { useStyletron } from 'baseui';
import { cellPaddingOverrides, gridPaddingOverrides } from "../overrides";
import { arrayMove } from "baseui/dnd-list";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useUrlParams } from "../help";
import AddMultimediaCard from "../cards/addEditMultimediaCard";


const AddCardContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  height: calc(100% + 21px);
  top: 0;
  box-shadow: -4px 1px 2px rgba(0, 0, 0, 0.1);
  padding-left: 25px;
  `;

const DroppableContainer = styled.div`
  display: flex;
  overflow: auto;
  flex-row: 1;
  padding-top: 3px;
  margin-right: 200px;
  scrollbar-width: thin;
  scrollbarColor: ${props => props.scrollbarColor}
`;

const WrapperContainer = styled.div`
  padding-top: ${props => props.theme.sizing.scale600};
  min-height: 274px;
`;


const MultimediaCardComponent = ({
  selectedComponent,
  defaultTheme,
  refetchHomeComponents,
  tabFilter
}) => {
  const [css, theme] = useStyletron();
  const [cards, setCards] = useState([]);

  const params = useUrlParams();

  React.useEffect(() => {
    let s = JSON.parse(JSON.stringify(selectedComponent.home_component.card_content_modules));
    console.log('MultimediaCardComponent', s)
    s = s.filter((el) => {
      if (tabFilter == 'archived') return el.archived === true;
      if (tabFilter === 'published') {
        return el.published == true && el.archived !== true;
      }
      if (tabFilter === 'all') {
        return el.archived !== true
      }
      return true;
    });
    setCards(s.sort((a, b) => a.position - b.position));
  }, [selectedComponent]);

  const onDragEnd = ({ destination, source, ...rest }) => {
    if (!destination) {
      return;
    }
    console.log(destination.index);
    console.log(source.index);
    let newArr = arrayMove(
      JSON.parse(JSON.stringify(cards)),
      source.index,
      destination.index
    );
    newArr = newArr.map((el, i) => {
      el.position = i;
      delete el.isNew;
      return el;
    });

    newArr.forEach(el => {
      let old = cards.find(el1 => el.id == el1.id);
      if (old.position !== el.position) {
        // updateCardStackCard({
        //   variables: {
        //     id: el.id,
        //     position: el.position,
        //     published: el.published,
        //     is_copy_card: isCopyCard
        //   }
        // })
      }
    })
    // setCards(newArr);
  }

  const droppableContainerRenderer = (dropProvided) => {
    const {
      home_component
    } = selectedComponent || {};
    return (
      <DroppableContainer
        // className="scrolls"
        className="scrolls_v1"
        ref={dropProvided.innerRef}
        scrollbarColor={defaultTheme === "dark" ? 'black #282828' : "white black"}
      >
        {
          cards.map((el, index) => {
            return (
              <Draggable
                key={el.id}
                draggableId={el.id}
                index={index}
              >
                {(dragProvided, snapshot) => (
                  <>
                    {/* {renderPortableItem({
                      item: el,
                      dragProvided,
                      snapshot,
                      index
                    })} */}
                  </>
                )}
              </Draggable>
            )
          })
        }
        {/* {tabFilter !== "archived" && ( */}
          <AddCardContainer>
            <AddMultimediaCard
              defaultTheme={defaultTheme}
              cardStyle="Version1"
              cards={cards}
              home_component_id={home_component?.id}
              orgId={params.orgId}
              refetchHomeComponents={refetchHomeComponents}
              modalProps={{ returnFocus: false }}
            />
          </AddCardContainer>
        {/* )} */}
      </DroppableContainer>
    )
  }

  // console.log('MultimediaCardComponent', selectedComponent)
  

  return (
    <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
      <Cell span={12} overrides={cellPaddingOverrides}>
        <WrapperContainer id={`wrapper_card_stack${selectedComponent?.id}`} className="wrapper" theme={theme}>
          <FlexGrid>
            <DragDropContext
              onDragEnd={onDragEnd}
            >
              <Droppable
                droppableId={selectedComponent?.id}
                type={'CARD'}
                direction="horizontal"
                isCombineEnabled={false}
              >
                {droppableContainerRenderer}
              </Droppable>
            </DragDropContext>
          </FlexGrid>
        </WrapperContainer>
      </Cell>
    </Grid>
  )
}

export default MultimediaCardComponent;