import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { Label2, Label3 } from 'baseui/typography';
import { sourceByType } from '../../util';

import { client } from '../../apollo/client';
import { Input } from 'baseui/input';
import { FileUploader } from 'baseui/file-uploader';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import { archiveTemplateTag, updateTemplateTag } from '../templates/queries';
import { Select } from 'baseui/select';


const formReportTypes = [
  {
    id: 'list',
    label: 'List',
  },
  {
    id: 'table',
    label: 'Table',
  }
]


const UpdateTagModal = ({
  tag,
  onClose,
  isOpen,
  onUpdate,
  organization_id,
  tabFilter,
  isFormTemplate,
  ...props
}) => {

  const [tagId, setTagId] = React.useState()
  const [tagName, setTagName] = React.useState();
  const [tagArchived, setTagArchived] = React.useState();
  const [coverImage, setCoverImage] = React.useState();
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [formReportType, setFormReportType] = React.useState('list');

  React.useEffect(() => {
    const {
      id,
      name,
      cover_image,
      archived,
      form_report_type = 'list',
    } = tag || {};
    setTagName(name);
    setTagId(id);
    setCoverImage(cover_image);
    if (tabFilter !== undefined && tabFilter !== null) {
      setTagArchived(tabFilter === 'archived')
    } else {
      setTagArchived(archived)
    }
    setFormReportType(form_report_type)
  }, [tag])

  const onSave = async () => {
    try {
      setIsUpdating(true);
      let variables = {
        id: tagId,
        name: tagName,
        cover_image: Array.isArray(coverImage) ? coverImage : null,
        organization_id: organization_id,
        metadata: tag.metadata,
        form_report_type: formReportType
      }

      await client.mutate({
        mutation: updateTemplateTag,
        variables
      })
      if (onUpdate) {
        onUpdate()
      }
      onClose();
      setIsUpdating(false);
    } catch (err) {
      console.log('on Update error', err);
      setIsUpdating(false);
    }
  };

  const onArchiveClick = async () => {
    try {
      setIsUpdating(true);
      await client.mutate({
        mutation: archiveTemplateTag,
        variables: {
          id: tagId,
          archived: !tagArchived
        }
      })
      if (onUpdate) {
        onUpdate()
      }
      onClose();
      setIsUpdating(false);
    } catch (err) {
      console.log('onArchiveClick error', err);
      setIsUpdating(false);
    }
  }

  const selectReportType = React.useMemo(() => {
    return formReportTypes.find(reportType => reportType.id === formReportType)
  }, [formReportType])

  const onChangeReportType = ({ option }) => {
    if (option) setFormReportType(option.id)
    else setFormReportType(null)
  }

  return (
    <Modal
      onClose={onClose}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      returnFocus={false}
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>{'Update Tag'}</ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <Grid overrides={{
          Grid: {
            style: () => ({
              padding: '0px !important',
            }),
          },
        }}>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important',
                }),
              },
            }}
          >
            <Label2 style={{ marginBottom: '0.5rem' }}>Name</Label2>
            <Input
              value={tagName}
              onChange={e => setTagName(e.target.value)}
              clearOnEscape
            />
          </Cell>

          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important',
                  marginTop: '1rem'
                }),
              },
            }}
          >
            <Label2 style={{ marginBottom: '0.5rem' }}>Cover Image</Label2>
            {coverImage && <img src={Array.isArray(coverImage) ? URL.createObjectURL(coverImage[0]) : sourceByType(coverImage, 'full', 'full')} style={{ width: '100%', margin: 'auto' }}></img>}
            <FileUploader
              onDrop={(acceptedFiles, rejectedFiles) => {
                setCoverImage(acceptedFiles);
              }}
            />
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important',
                  marginTop: '1rem'
                }),
              },
            }}
          >
            <Label2 style={{ marginBottom: '0.5rem' }}>Form Report type</Label2>
            <Select
              options={formReportTypes}
              value={selectReportType}
              placeholder={selectReportType ? '' : 'Select Report Type'}
              onChange={onChangeReportType}
              multi={false}
              labelKey={'label'}
              valueKey={'id'}
              maxDropdownHeight="300px"
              overrides={{
                Popover: {
                  props: {
                    overrides: {
                      Body: { style: { zIndex: 3 } },
                    },
                  },
                },
              }}
            />
          </Cell>
        </Grid>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={ButtonKind.tertiary} onClick={onClose}>
          Cancel
        </ModalButton>
        <ModalButton onClick={onArchiveClick} isLoading={isUpdating}>{tagArchived ? 'Unarchive' : 'Archive'}</ModalButton>
        <ModalButton onClick={onSave} isLoading={isUpdating}>Update</ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default UpdateTagModal;