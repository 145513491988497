import React, { useEffect, useMemo, useState } from 'react';
import { FileUploader } from "baseui/file-uploader"
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal"
import { LabelMedium, LabelLarge, LabelXSmall, LabelSmall } from 'baseui/typography';
import { ProgressBar } from 'baseui/progress-bar';
import { useMutation, useSubscription } from '@apollo/client';
import { toaster } from 'baseui/toast';
import { Tabs, Tab } from "baseui/tabs-motion";
import { importChannelListFromCsv, importChannelListFromCsvSubscription } from '../../quries';
import useProgressTracker from '../progress-tracker/useProgressTracker';
import LibraryDragAndDrop from '../library/DragAndDrop';
import store from '../../redux/store';
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import { fetchCloudinaryFolders, fetchCloudinarySubFolders } from '../../util/index';
import { addChannel } from '../../quries';
import Toggle from 'react-toggle';

const ImportChannelModal = ({
  isOpen = false,
  onCloseImportModal,
  organization_id,
  refreshAppContentScopeGroups,
  refreshAppContentScopes,
  app_content_scope_group_id
}) => {

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [importFile, setImportFile] = React.useState(null);
  const [importLoading, setImportLoading] = React.useState(false);
  const [requestId, setRequestId] = React.useState('');
  const { ProgressComponent, setReportRequestId } = useProgressTracker(onProgressDone, onError);
  const [activeKey, setActiveKey] = React.useState("0");
  const { cloudinarySettings, organization, channels } = store.getState().appBasicControls;
  const [cloudinaryImportLoading, setCloudinaryImportLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [fileUploadMessage, setFileUploadMessage] = React.useState('');
  const [createAppContentScope] = useMutation(addChannel);

  const [published, setPublished] = React.useState(false);

  let toastKey;

  const [importChannelListFromCsvMutation, {
    data: importRes,
    loading: loadingSharepointModule
  }] = useMutation(importChannelListFromCsv)

  useEffect(() => {
    setIsImportModalOpen(isOpen)
  }, [isOpen])

  const onCloseModal = () => {
    onCloseImportModal()
    setImportFile()
    setFileUploadMessage('');
    setChecked(false);
    setCloudinaryImportLoading(false);
  }

  const showToaster = (msg, method = 'info') => {
    let toastKey = toaster[method](
      <>
        {msg}
      </>,
      {
        overrides: {
          InnerContainer: {
            style: { width: "100%" }
          }
        }
      }
    );
    setTimeout(() => { toaster.clear(toastKey) }, 3000);
  };

  const onClickImport = async () => {
    try {
      setImportLoading(true);
      const { data: dataRes } = await importChannelListFromCsvMutation({
        variables: {
          organization_id,
          app_content_scope_group_id,
          file: importFile,
          published
        }
      })
      const {
        status,
        request_id,
        message
      } = dataRes.importChannelListFromCsv || {}

      if (status) {
        setRequestId(request_id);
        setReportRequestId(request_id);
        showToaster('Channel Import In progress', 'positive')
      } else {
        setImportLoading(false);
        setRequestId("");
        setReportRequestId("");
        showToaster(message, 'negative')

      }
    } catch (err) {
      console.log('onClickImport error', err);
      showToast(err.message)
      setImportLoading(false);
      setRequestId("");
      setReportRequestId("");
    }
  }

  const showToast = (message) => {
    if (toastKey) {
      toaster.clear(toastKey);
    }
    toastKey = toaster.info(<>{message}</>, {
      onClose: () => console.log('Toast closed.'),
      overrides: {
        InnerContainer: {
          style: { width: '100%' },
        },
      },
    });
  }

  function onProgressDone() {
    setImportLoading(false);
    setRequestId("");
    setReportRequestId("");
    if (refreshAppContentScopeGroups) {
      refreshAppContentScopeGroups()
    }
    if (refreshAppContentScopes) {
      refreshAppContentScopes()
    }
    onCloseModal();
    showToaster('Channels Imported Successfully', 'positive')
  }

  function onDone() {
    console.log('onDone called')
    setImportLoading(false);
    setRequestId("");
    setReportRequestId("");
    if (refreshAppContentScopeGroups) {
      refreshAppContentScopeGroups()
    }
    if (refreshAppContentScopes) {
      refreshAppContentScopes()
    }
    onCloseModal();

  }

  function onError() {
    setImportLoading(false);
    setRequestId("");
    setReportRequestId("");
    showToaster('Something went wrong in importing channels', 'negative')
  };


  console.log('cloudinarySettings', cloudinarySettings);

  const initChannelCreation = async (folder) => {
    setFileUploadMessage('Loading folders');
    const root_folders = await fetchCloudinaryFolders(organization.id);
    console.log(root_folders);
    const _f = root_folders.find(el => el.name == folder);
    console.log(_f);
    setFileUploadMessage(`Loading sub folders of ${folder}`);
    const sub = await fetchCloudinarySubFolders(organization.id, encodeURIComponent(_f.path));
    console.log(sub);
    const tc = channels.filter(el => el.app_content_scope_group_id == app_content_scope_group_id).length;
    if (checked) {
      setFileUploadMessage('Create Full Collection Channel');
      await createAppContentScope({
        variables: {
          name: 'Full Collection',
          public: false,
          published: false,
          position: tc,
          app_content_scope_group_id,
          organization_id: organization.id,
          is_file_explorer: true,
          file_explorer_root_path: _f.path
        }
      })
    }
    for (var i = 0; i < sub.length; i++) {
      setFileUploadMessage(`Creating channel ${sub[i].name}`);
      await createAppContentScope({
        variables: {
          name: sub[i].name,
          public: false,
          published: false,
          position: tc + i + 1,
          app_content_scope_group_id,
          organization_id: organization.id,
          is_file_explorer: true,
          file_explorer_root_path: sub[i].path
        }
      })
    };
    setFileUploadMessage('All channels has been created');
    onCloseModal();
    showToaster('Channels Imported Successfully', 'positive');
  }

  return (
    <Modal
      overrides={{
        Backdrop: {
          style: ({ $theme }) => ({
            backdropFilter: $theme.modalBackDropFilter,
          }),
        },
        Root: {
          style: {
            zIndex: 3,
            // width: '500px'
          }
        },
        Dialog: {
          style: {
            width: '60vw',
            // height: '500px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          },
          Body: {
            style: ({ $theme }) => ({
              overflow: 'hidden',
            }),
          },
        },
      }}
      isOpen={isImportModalOpen}
      onClose={onCloseModal}
      returnFocus={false}
    >
      <ModalHeader>
        <LabelLarge>
          Import Channels
        </LabelLarge>
      </ModalHeader>
      <ModalBody style={{ flex: '1 1 0' }}>
        <Tabs
          activeKey={activeKey}
          onChange={({ activeKey }) => {
            setActiveKey(activeKey);
          }}
          activateOnFocus
          overrides={{
            Root: {
              style: () => ({
                padding: 0,
              })
            },
            TabPanel: {
              style: {
                padding: '0px !important'
              }
            }
          }}
        >
          <Tab title="CSV Import">
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '10px'
              }}
            >
              <Toggle
                id="cheese-status"
                defaultChecked={published}
                checked={published}
                icons={false}
                onChange={(e) => { setPublished(e.target.checked); }}
              ></Toggle><p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem', marginTop: '3px' }}>Publish all channels after import</p>
            </div>
            <LabelXSmall
              color={'grey'}
              style={{ marginBottom: '18px' }}
            >
              Only Accept .csv file
            </LabelXSmall>
            {importFile && <LabelMedium>File Selected {importFile.name}</LabelMedium>}
            <FileUploader
              onDrop={(acceptedFiles, rejectedFiles) => {
                setImportFile(acceptedFiles[0])

              }}
              accept={'.csv'}

            />
            {
              requestId && ProgressComponent
            }
          </Tab>
          {cloudinarySettings && cloudinarySettings.cloudinaries_org && cloudinarySettings.cloudinaries_org.length > 0 && <Tab title="Files Upload">
            <div style={{ marginTop: '12px' }}>
              <div>
                <LabelXSmall
                  color={'grey'}
                  style={{ marginBottom: '18px' }}
                >
                  Once the folder is dropped file upload will start and after that channels will be created based on sub folders. Full Collection channel will be also created if Create Full Collection is checked.
                </LabelXSmall>
              </div>
              <div style={{ marginBottom: '24px' }}>
                <Checkbox
                  checked={checked}
                  onChange={e => setChecked(e.target.checked)}
                  labelPlacement={LABEL_PLACEMENT.right}
                  disabled={cloudinaryImportLoading}
                >
                  Create Full Collection Channel
                </Checkbox>
              </div>
              {cloudinarySettings && <div style={{ height: '200px' }}><LibraryDragAndDrop
                cloudinarySettings={cloudinarySettings}
                onMessage={(msg) => {
                  setFileUploadMessage(msg);
                }}
                refetch={() => { }}
                organization_id={organization?.id}
                refetchLibraries={() => { }}
                setIsLoading={(v) => setCloudinaryImportLoading(v)}
                onDone={(file) => {
                  const p = (file.filepath);
                  const _root = p.split('/')[0];
                  initChannelCreation(_root);
                }}
              />
              </div>}
              <LabelSmall
                color={'grey'}
                style={{ marginBottom: '18px', marginTop: '18px' }}
              >
                {fileUploadMessage}
              </LabelSmall>
            </div>

          </Tab>}
        </Tabs>
      </ModalBody>
      <ModalFooter>
        {activeKey == 0 && <ModalButton
          isLoading={importLoading || requestId}
          disabled={!importFile || requestId}
          onClick={onClickImport}
        >
          {'Import'}
        </ModalButton>}
      </ModalFooter>
    </Modal>
  )
}

export default ImportChannelModal