import React, { useEffect, useState } from 'react'
import { useStyletron } from 'baseui';
import { useSelector } from 'react-redux';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { client } from '../../apollo/client';
import { getUserTags, updateAdminWithChannels, updateChannel, updateUserWithChannels } from '../../quries';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import gql from 'graphql-tag';
import { Drawer } from 'baseui/drawer';
import { HeadingSmall } from 'baseui/typography';
import { Spinner } from 'baseui/spinner';
import { ModalButton } from 'baseui/modal';

function TagsSidebar(props) {
    const { isOpen, onClose, setUpdateSelectedTags, currentChannel } = props;
    const [css, theme] = useStyletron();
    const [searchText, setSearchText] = useState('');
    const [filteredTagList, setFilteredTagList] = useState([]);
    const [userData, setUserData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { organizationTags } = useSelector(state => state.appBasicControls)
    const teamsTagsList = (organizationTags || [])?.filter(tag => tag.type === "Teams");
    const channelTagsId = (currentChannel?.tags || [])?.map(tag => tag.tag_id) || [];
    const [selectedChannelTags, setSelectedChannelTags] = useState([]);

    useEffect(() => {
        setSelectedChannelTags(channelTagsId)
    }, [currentChannel]);

    useEffect(() => {
        if (searchText === '') {
            setFilteredTagList(teamsTagsList);
        }
        setFilteredTagList((teamsTagsList || []).filter(tag => tag.name.toLowerCase().includes(searchText)))
    }, [searchText])

    const handleChange = (isChecked, tagId) => {
        setSelectedChannelTags((preValue) => {
            if (isChecked) {
                return ([...preValue, tagId])
            } else {
                return (preValue.filter(id => id !== tagId))
            }
        })
    }

    const handleSelectAll = (isChecked) => {
        if (isChecked) {
            setSelectedChannelTags(filteredTagList.map(tag => tag.id))
        } else setSelectedChannelTags([])
    }

    const tagsExcludingTeams = currentChannel.tags?.filter(tag => tag?.tag?.type !== 'Teams')
    const tagsExcludingTeamsList = (organizationTags || []).filter(orgTag => tagsExcludingTeams?.some(tag => tag?.id === orgTag?.id))

    const selectedTagsData = (teamsTagsList || []).filter(tag => selectedChannelTags?.includes(tag.id))
    const allSelectedTagsList = [...selectedTagsData, ...tagsExcludingTeamsList]
    const selectedTagsList = allSelectedTagsList.map(tag => {
        return {
            tag_id: tag.id,
            channel_id: currentChannel.id,
            organization_id: currentChannel.organization_id
        }
    })

    const updateChannelTags = () => {
        setIsSaving(true)
        client.mutate({
            mutation: updateChannel,
            variables: {
                id: currentChannel.id,
                name: currentChannel.name,
                public: currentChannel.public,
                logo: null,
                icon: null,
                logo_data: currentChannel.logo_data,
                icon_data: currentChannel.icon_data,
                description: currentChannel.description,
                media_url: currentChannel.media_url,
                content_controlled: currentChannel.content_controlled,
                pincode: currentChannel.pinCode,
                abbr: currentChannel.abbr,
                sales_button_enabled: currentChannel.sales_button_enabled,
                sales_incentive_button_title: currentChannel.sales_incentive_button_title,
                sales_incentive_description: currentChannel.sales_incentive_description,
                sales_incentive_title: currentChannel.sales_incentive_title,
                sales_incentive_image: currentChannel.sales_incentive_image,
                sales_incentive_image_upload: null,
                is_requires_subscription: currentChannel.is_requires_subscription,
                is_travel_pass_channel: currentChannel.is_travel_pass_channel,
                is_navigation_channel: currentChannel.is_navigation_channel,
                has_data_source_connected: currentChannel.has_data_source_connected,
                subscription_ids: currentChannel.subscription_ids,
                subscription_into_headline: currentChannel.subscription_into_headline,
                subscription_button_text: currentChannel.subscription_button_text,
                subscription_description: currentChannel.subscription_description,
                subscription_image: null,
                can_share_channel_data: currentChannel.can_share_channel_data,
                is_file_explorer: currentChannel.is_file_explorer,
                file_explorer_root_path: currentChannel.file_explorer_root_path,
                file_explorer_default_view: currentChannel.file_explorer_default_view,
                is_firebase_channel: currentChannel.is_firebase_channel,
                is_primary_channel: currentChannel.is_primary_channel,
                is_modal_presentation: currentChannel.is_modal_presentation,
                display_modal_on_no_sub: currentChannel.display_modal_on_no_sub,
                display_modal_on_no_acct: currentChannel.display_modal_on_no_acct,
                hide_from_navigation_list_on_modal_check: currentChannel.hide_from_navigation_list_on_modal_check,
                tags: selectedTagsList,
            }
        }).then((data) => {
            console.log("Channel update", data);
            setIsSaving(false)
            setUpdateSelectedTags((preValue) => !preValue)
            onClose();
            // window.location.reload();
        }).catch(err => {
            console.error("update error", err);
            setIsSaving(false)
        })
    }

    const renderTagList = () => {
        return (filteredTagList.map(tag => {
            return (
                <div
                    key={tag?.id}
                    className={css({
                        paddingTop: theme.sizing.scale300,
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        minWidth: '300px',
                        float: 'left',
                        display: 'block'
                    })}
                >
                    <Checkbox
                        checked={selectedChannelTags.includes(tag.id)}
                        onChange={event =>
                            handleChange(event.target.checked, tag.id)
                        }
                        labelPlacement={LABEL_PLACEMENT.right}
                    >
                        {tag?.name}
                    </Checkbox>
                </div>
            )
        }));
    }


    return (
        <Drawer
            isOpen={isOpen}
            autoFocus
            onClose={onClose}
            overrides={{
                DrawerBody: {
                    style: ({ $theme }) => ({
                        marginLeft: '20px',
                    })
                },
            }}>
            <HeadingSmall
                style={{
                    textAlign: 'center'
                }}
            >
                Team Tags
            </HeadingSmall>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '10px'
                }}
            >
                <Input
                    placeholder="Search by name"
                    clearOnEscape
                    clearable
                    onChange={event => setSearchText(event.target.value.toLowerCase())}
                    endEnhancer={<Search size="18px" />}
                />
            </div>

            {filteredTagList.length > 0 && (
                <div
                    className={css({
                        paddingTop: theme.sizing.scale300,
                        paddingRight: '24px',
                        marginBottom: '8px',
                        minWidth: '300px',
                        float: 'left',
                        display: 'block'
                    })}
                >
                    <Checkbox
                        onChange={event =>
                            handleSelectAll(event.target.checked)
                        }
                        labelPlacement={LABEL_PLACEMENT.right}
                        isIndeterminate={selectedChannelTags.length > 0 && selectedChannelTags.length < filteredTagList.length}
                        checked={filteredTagList.length === selectedChannelTags.length}
                    >
                        Select All Tags
                    </Checkbox>
                </div>
            )}

            {isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '10px'
                    }}
                >
                    <Spinner size={24} />
                </div>
            ) : (
                renderTagList()
            )}
            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    minHeight: '50px',
                    paddingBottom: '10px',
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    flexDirection: 'column',
                }}

            >
                <div
                    className={css({
                        justifyContent: 'flex-end',
                        display: 'flex'
                    })}
                >
                    <ModalButton
                        onClick={onClose}
                    >
                        Cancel
                    </ModalButton>

                    <ModalButton
                        isLoading={isSaving}
                        onClick={updateChannelTags}
                    >
                        Update
                    </ModalButton>
                </div>
            </div>
        </Drawer>
    )
}

export default TagsSidebar