import React from 'react';
import { Card, StyledBody, StyledAction } from "baseui/card";
import { Button } from "baseui/button";
import { Grid, Cell } from 'baseui/layout-grid';
import { H1 } from 'baseui/typography';
import { FaFire, FaRegImages, FaScrewdriver, FaUser } from 'react-icons/fa';
import { IoServerSharp, IoFolderOpenSharp, IoChatbubbleEllipses } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { gql } from '@apollo/client';
import { getToken } from '../../auth';
import { client, getServerUrl } from '../../apollo/client';
import { cellPaddingOverrides } from '../overrides';
import svgIcons from '../../svg';
import { HeadingXSmall, HeadingXXLarge } from 'baseui/typography';

const cards = [
    {
        id: 'breakthrough',
        name: 'Breakthrough',
        description: 'Next gen app builder and content management.',
        icon: FaFire,
    },
    {
        id: 'engage',
        name: 'Engage',
        description: 'Communication platform for chat, push notification and email management.',
        icon: IoChatbubbleEllipses,
    },
    {
        id: 'hubstack',
        name: 'Hubstack',
        description: 'Asset management for media files and content folders.',
        icon: IoFolderOpenSharp,
    },
    {
        id: 'cardbase',
        name: 'Cardbase',
        description: 'Database and content relationship management.',
        icon: IoServerSharp
    },
    {
        id: 'studio',
        name: 'Studio',
        description: 'Graphics and presentation design workspace.',
        icon: FaRegImages,
        url: 'https://cardware-studio-manager.netlify.app/'
    },
    {
        id: 'build',
        name: 'Build',
        description: 'Interactive, real-time mobile app preview.',
        icon: FaScrewdriver,
    },
    {
        id: 'connect',
        name: 'Connect',
        description: 'Customer relationship management and onboarding communications',
        icon: FaUser,
    },
];

export default function AppList({ _subDomain }) {
    const { products, selectedChannel, user } = useSelector(state => state.appBasicControls);
    const [appUrl, setAppUrl] = React.useState('');

    React.useEffect(() => {
        const {
            organization_id
        } = products || {};
        if (!organization_id) {
            return;
        }
        client.query({
            query: gql`
            query organization_pwa_app {
              organization_pwa_app(organization_id: "${organization_id}") {
                app_url
              }
            }
          `
        }).then((response) => {
            try {
                let _appUrl = response.data.organization_pwa_app[0]?.app_url;
                setAppUrl(_appUrl);
            } catch (e) {

            }
        })
    }, [products]);

    const onClickLaunch = async ({ id, url: optionUrl }) => {
        // window.open(card.url, '_blank');
        console.log(id)
        if (optionUrl) {
            window.open(optionUrl, '_blank');
        }
        const {
            organization_name,
            admin_url,
            api_url,
            breakthrough_integration,
            engage_integration,
            crm_integration,
            data_collection_integration,
            hubstack_integration
        } = products || {};


        // console.log('Stash onClick', { id, data_collection_integration, hubstack_integration })
        let integration
        if (id == 'breakthrough') {
            integration = breakthrough_integration
        } else if (id == 'engage') {
            integration = engage_integration
        } else if (id == 'connect') {
            integration = crm_integration
        } else if (id == 'cardbase') {
            integration = data_collection_integration
        } else if (id == 'hubstack') {
            integration = hubstack_integration
        }

        // console.log('integration', { integration, id })
        if (!integration?.id) {
            return;
        }

        const tokens = await getToken();
        const token = tokens.idToken;
        const organization_id = tokens.organization_id;
        const base_uri = getServerUrl();
        const pwa_url = appUrl;
        const channel_id = selectedChannel?.id
        console.log(token, organization_id, base_uri, channel_id, pwa_url);
        const req = { token, organization_id, base_uri, channel_id, pwa_url };
        const {
            idTokenPayload
        } = user || {}
        const userPayload = {
            id: idTokenPayload.app_metadata[`${organization_id}_admin_id`] || idTokenPayload?.user_metadata?.app_user_id,
            name: idTokenPayload?.name,
            email: idTokenPayload?.email
        }
        const payload = {
            organization_id,
            organization_name,
            admin_url,
            api_url,
            pwa_url,
            channel_id,
            integration,
            token,
            user: userPayload,
            crm_api: crm_integration ? crm_integration.api_url : ''
        }
        var credentials = window.btoa(JSON.stringify(payload));
        console.log('credentials ------>', user);
        let url = `${integration.app_url}?__nguk_redirect_token=${credentials}`
        console.log('url', url)
        // window.open(`https://cardware-breakthrough.netlify.app?__nguk_redirect_token=${btoa(JSON.stringify(req))}`, '_blank');
        // https://cardware-breakthrough.netlify.app/builder?referent_id=e8a46c62-f62f-4fb6-af0d-f805ecc1a45f&referent_type=channel
        //   window.open(`${integration.app_url}?referent_id=e8a46c62-f62f-4fb6-af0d-f805ecc1a45f&referent_type=channel&&__nguk_redirect_token=${btoa(JSON.stringify(req))}`, '_blank');
        window.open(url, '_blank');
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
            <H1>Cardware Apps</H1>
            <Cell span={12} >
                <div style={{ width: '90%', marginBottom: '32px', marginLeft: '32px' }}>
                    <Grid overrides={{
                        Grid: {
                            style: () => ({
                                padding: '0px !important',
                                justifyContent: 'space-between'
                            }),
                        }
                    }} gridMaxWidth={'unset'}>
                        <Cell span={3.6} overrides={cellPaddingOverrides}>
                            <div
                                style={{
                                    marginLeft: '20px',
                                    width: '100%',
                                    paddingTop: '6%',
                                    paddingBottom: '4%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    borderRadius: '10px',
                                    background: 'linear-gradient(263.23deg, rgba(0, 0, 0, 0.2) -1.5%, rgba(255, 255, 255, 0.2) 100%), #4A4CA0',
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <img src={svgIcons.UsersIcon} style={{ margin: 0, marginLeft: '2rem', width: '50px', height: '50px' }} />
                                <div style={{ marginLeft: '3rem' }}>
                                    <HeadingXXLarge
                                        color='white'
                                        style={{
                                            marginBottom: '10px',
                                            fontSize: '28px',
                                            lineHeight: '28px',
                                            FontFamily: 'Lexend'
                                        }}
                                    >
                                        {4235}
                                    </HeadingXXLarge>
                                    <HeadingXSmall
                                        color='white'
                                        style={{
                                            fontSize: '16px',
                                            marginBottom: 0,
                                            textTransform: 'capitalize',
                                            FontFamily: 'Lexend'
                                        }}
                                    >
                                        {"Total Users"}
                                    </HeadingXSmall>
                                </div>
                            </div>
                        </Cell>
                        <Cell span={3.6} overrides={cellPaddingOverrides}>
                            <div
                                style={{
                                    marginLeft: '20px',
                                    width: '100%',
                                    paddingTop: '6%',
                                    paddingBottom: '4%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    borderRadius: '10px',
                                    background: 'linear-gradient(263.23deg, rgba(0, 0, 0, 0.2) -1.5%, rgba(255, 255, 255, 0.2) 100%), #EE4678',
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <img src={svgIcons.ActiveIcon} style={{ margin: 0, marginLeft: '2rem', width: '50px', height: '50px' }} />
                                <div style={{ marginLeft: '3rem' }}>
                                    <HeadingXXLarge
                                        color='white'
                                        style={{
                                            marginBottom: '10px',
                                            fontSize: '28px',
                                            lineHeight: '28px',
                                            FontFamily: 'Lexend'
                                        }}
                                    >
                                        {3547}
                                    </HeadingXXLarge>
                                    <HeadingXSmall
                                        color='white'
                                        style={{
                                            fontSize: '16px',
                                            marginBottom: 0,
                                            textTransform: 'capitalize',
                                            FontFamily: 'Lexend'
                                        }}
                                    >
                                        {"Active Users"}
                                    </HeadingXSmall>
                                </div>
                            </div>
                        </Cell>
                        <Cell span={3.6} overrides={cellPaddingOverrides}>
                            <div
                                style={{
                                    marginLeft: '20px',
                                    width: '100%',
                                    paddingTop: '6%',
                                    paddingBottom: '4%',
                                    background: 'linear-gradient(263.23deg, rgba(0, 0, 0, 0.2) -1.5%, rgba(255, 255, 255, 0.2) 100%), #000000',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    borderRadius: '10px',
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <img src={svgIcons.InvitationIcon} style={{ margin: 0, marginLeft: '2rem', width: '50px', height: '50px' }} />
                                <div style={{ marginLeft: '3rem' }}>
                                    <HeadingXXLarge
                                        color='white'
                                        style={{
                                            marginBottom: '10px',
                                            fontSize: '28px',
                                            lineHeight: '28px',
                                            FontFamily: 'Lexend'
                                        }}
                                    >
                                        {286}
                                    </HeadingXXLarge>
                                    <HeadingXSmall
                                        color='white'
                                        style={{
                                            fontSize: '14px',
                                            marginBottom: 0,
                                            textTransform: 'capitalize'
                                        }}
                                    >
                                        {"Notifications Sent"}
                                    </HeadingXSmall>
                                </div>
                            </div>
                        </Cell>
                    </Grid>
                </div>
            </Cell>


            <Grid>
                {cards.map((card, index) => {
                    const onPress = () => {
                        onClickLaunch(card)
                    }
                    return (
                        <Cell
                            span={[4, 8, 4]}
                            key={index}
                            overrides={{ Cell: { style: { marginBottom: '20px' } } }}
                        >
                            <Card
                                overrides={{
                                    Root: { style: { width: "100%", marginBottom: '20px', borderRadius: '15px' } }
                                }}
                                title={<div style={{ display: 'flex', alignItems: 'center' }}>
                                    <card.icon size={24} style={{ marginRight: '8px' }} />
                                    {card.name}
                                </div>}
                            >
                                <StyledBody
                                    style={{
                                        height: '50px'
                                    }}
                                >
                                    {card.description}
                                </StyledBody>
                                <StyledAction>
                                    <Button
                                        onClick={onPress}
                                        overrides={{ BaseButton: { style: { width: "100%" } } }}
                                    >
                                        Launch {card.name}
                                    </Button>
                                </StyledAction>
                            </Card>
                        </Cell>
                    )
                })}
            </Grid>
        </div>
    );
}