import * as React from 'react';
import BaseClass from '../../builder/cardBackEditorComponents/base';
import { Textarea } from 'baseui/textarea';
import { Input } from "baseui/input";
import { Select, TYPE } from 'baseui/select';
import { FileUploader } from 'baseui/file-uploader';
import { Button } from 'baseui/button';
import { sourceByType } from '../../../util';
import { getStoreKeyName } from '@apollo/client/utilities';
import { Block } from 'baseui/block';
import { Accordion, Panel } from 'baseui/accordion';
import ImageUploadCXL from '../../imageUploaderCXL';
import { cardBackImageGuideline } from '../../help/imageSizeGuidelines.json';
import { getTimeDisplayFormat, isValidHttpUrl } from '../../builder/builderUtils/util'
import styled from '@emotion/styled';
import CardBackFloatingListContainer from '../../builder/cardBackEditorComponents/CardBackFloatingListContainer';
import CardBackFloatingListItem from '../../builder/cardBackEditorComponents/CardBackFloatingListContainer/CardBackFloatingListItem';
import CardBackHeaderTitle from '../../builder/cardBackTitle';
import CustomInput from '../../common/input';
import AddContentButton from '../../addContentButton';
import { cardBackListItemMoveMethods, scrollToElement } from '../../builder/builderUtils/helpers';
import _ from 'lodash';
import { client } from '../../../apollo/client';
import { fileUpload } from '../../builder/builderUtils/query';

// const HeaderTitle = styled.h5`
//   fontFamily: 'IBM Plex Sans',
//   fontStyle: 'normal',
//   fontWeight: 500,
//   fontSize: '14px',
//   lineHeight: '16px'
// `;
const getSrc = data => {
  try {
    if (!data) return data;
    return sourceByType(data);
  } catch (e) {
    return null;
  }
};

const getPreviewImage = (file, dbImage) => {
  console.log(file, dbImage);
  if (file) {
    return URL.createObjectURL(file[0]);
  };
  return getSrc(dbImage);
};



const CardFloatingListItem = (props) => {
  const {
    item,
    index,
    setStateInternal,
    onMoveDown,
    onMoveUp,
    onDeleteItem
  } = props;
  const [image_uploading, set_image_uploading] = React.useState(null);
  const [audio_uploading, set_audio_uploading] = React.useState(null);

  const onImageDrop = (acceptedFiles, rejectedFiles) => {
    set_image_uploading(true)
    client.mutate({
      mutation: fileUpload,
      variables: {
        file: acceptedFiles
      }
    }).then((response) => {
      try {
        let p = response.data.upload_supporting_doc;
        setStateInternal({
          value: p,
          key: 'cover_photo_data',
          index,
        });
      } catch (err) {

      };
      set_image_uploading(null)
    })
  }

  const onImageDelete = () => {
    setStateInternal({
      value: null,
      key: 'cover_photo',
      index,
    })
    setStateInternal({
      value: null,
      key: 'cover_photo_data',
      index,
    })
  }


  const onAudioDrop = (acceptedFiles, rejectedFiles) => {
    set_audio_uploading(true);
    client.mutate({
      mutation: fileUpload,
      variables: {
        file: acceptedFiles
      }
    }).then((response) => {
      try {
        let p = response.data.upload_supporting_doc;
        setStateInternal({
          value: p,
          key: 'file_data',
          index,
        });
      } catch (err) {

      };
      set_audio_uploading(null)
    })
  }

  const onAudioDelete = () => {
    setStateInternal({
      value: null,
      key: 'file',
      index,
    })
    setStateInternal({
      value: null,
      key: 'file_data',
      index,
    })
  }

  const onChangeTitle = e =>
    setStateInternal({
      value: e.target.value,
      key: 'caption',
      index,
    })

  const onChangeDescription = e =>
    setStateInternal({
      value: e.target.value,
      key: 'description',
      index,
    })

  const onChangeDuration = e => {
    const minArr = e.target.value;
    const minInMilli = parseInt(minArr) * 60;
    setStateInternal({
      value: 1 * minInMilli,
      key: 'duration',
      index,
    })
  }
  const durationInFormat = item.duration / 60;

  const _onMoveUp = () => {
    onMoveUp(item)
  }

  const _onMoveDown = () => {
    onMoveDown(item)
  }

  const _onDelete = () => {
    onDeleteItem(item)
  }

  return (
    <CardBackFloatingListItem
      id={"audio_position_" + item.position}
      key={"audio_position_" + item.position}
      onMoveUpClick={_onMoveUp}
      onMoveDownClick={_onMoveDown}
      onDeleteClick={_onDelete}
    >
      <CardBackHeaderTitle color={props.theme.colors.primaryA} >Audio Cover Image</CardBackHeaderTitle>
      <ImageUploadCXL
        showHint={false}
        previewUrls={getPreviewImage(item.cover_photo, item.cover_photo_data) || []}
        isMultiple={false}
        style={{ background: 'transparent' }}
        onDrop={onImageDrop}
        onImageDelete={onImageDelete}
        showFullBleedSettings={false}
        imageSize={cardBackImageGuideline.size}
        imageSizeGuidelineURL={cardBackImageGuideline.url}
      ></ImageUploadCXL>
      {image_uploading && <div class="loader">
        <div class="loaderBar"></div>
      </div>}
      <CardBackHeaderTitle color={props.theme.colors.primaryA} >Audio Source</CardBackHeaderTitle>
      <ImageUploadCXL
        showHint={false}
        previewUrls={getPreviewImage(item.file, item.file_data) || []}
        isMultiple={false}
        style={{ background: 'transparent' }}
        onDrop={onAudioDrop}
        onImageDelete={onAudioDelete}
        accept={props.accept}
        showFullBleedSettings={false}
        imageSize={cardBackImageGuideline.size}
        imageSizeGuidelineURL={cardBackImageGuideline.url}
      ></ImageUploadCXL>
      {audio_uploading && <div class="loader">
        <div class="loaderBar"></div>
      </div>}
      <CardBackHeaderTitle color={props.theme.colors.primaryA}>Track Title</CardBackHeaderTitle>
      <CustomInput
        value={item.caption}
        onChange={onChangeTitle}
        placeholder={'Enter Audio Title'}
        clearOnEscape
        maxLength={125}
      />
      <CardBackHeaderTitle color={props.theme.colors.primaryA}>Track Description</CardBackHeaderTitle>
      <Textarea
        value={item.description}
        onChange={onChangeDescription}
        placeholder={'Enter Audio Description'}
        clearOnEscape
      />
      <CardBackHeaderTitle color={props.theme.colors.primaryA}>Track Duration(Mins)</CardBackHeaderTitle>
      <div
        className={props.css({
          maxWidth: '50%',
          minWidth: '133px'
        })}
      >
        <Input
          value={durationInFormat}
          onChange={onChangeDuration}
          placeholder={'Enter Duration'}
          clearOnEscape
          type="number"
        />
      </div>
    </CardBackFloatingListItem>
  )
}

class VideoGalleryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progressAmount: 0,
      // value: this.props.value
    };
    this.setStateInternal = this.setStateInternal.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.value
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextState, this.state)) {
      return true;
    }
    return false;
  }

  setStateInternal({ value, key, index }) {
    let { value: options } = this.state;
    // options[index][key] = value;
    let v = {
      value: options.map((x, idx) => {
        if (idx === index) {
          return { ...x, [key]: value, position: idx };
        }
        return x;
      }),
    };
    this.setState(v);
    this.props.onChange(v.value);
  }

  getSortedListVals(listVal) {
    let tempValue = listVal && listVal.map((el, index) => {
      const position = el.position && el.position !== null ? el.position : index;
      return ({
        ...el,
        position
      })
    }) || [];
    debugger;
    tempValue = tempValue.sort((a, b) => a.position - b.position) || [];

    return tempValue;
  }

  onClick() {
    let options = this.state.value && this.state.value.map((el) => ({ ...el })) || [];
    let position = options && options !== null ? options.length : 0;
    options.push({

      caption: '',

      file_data: '',
      file: '',

      cover_photo_data: '',
      cover_photo: '',

      description: '',
      duration: 0,

      position
    });
    this.setState({ value: options }, () => {
      scrollToElement('.card_back_float_container', '#audio_position_' + position)
    });
  }

  onItemMoveUp = (item) => {
    const updatedValue = cardBackListItemMoveMethods.onMoveUp(item, this.state.value);
    this.setState({
      value: updatedValue
    }, () => {
      if (item.position - 1 < 0) return;
      let position = item.position - 1
      debugger
      scrollToElement('.card_back_float_container', `#audio_position_${position}`)
    })
  }

  onItemMoveDown = (item) => {
    const updatedValue = cardBackListItemMoveMethods.onMoveDowm(item, this.state.value);
    this.setState({
      value: updatedValue
    }, () => {
      if (item.position + 1 >= this.state.value.length) return;
      let position = item.position + 1;
      debugger
      scrollToElement('.card_back_float_container', `#audio_position_${position}`)
    })
  }

  onItemDelete = (item) => {
    const updatedValue = cardBackListItemMoveMethods.onDeleteItem(item, this.state.value);
    this.setState({
      value: updatedValue
    })
  }



  ///////////////////////////////////////////////////// Render Functions /////////////////////////////////////////////////////////



  render() {
    const options = this.getSortedListVals(this.state.value) || [];

    return (
      <>
        <CardBackFloatingListContainer
          addBtnText='Add additional audio to playlist'
          onClickAddBtn={this.onClick}
        >
          {options.map((ele, index) => (
            <CardFloatingListItem
              {...this.props}
              item={ele}
              onMoveUp={this.onItemMoveUp.bind(this)}
              onMoveDown={this.onItemMoveDown.bind(this)}
              onDeleteItem={this.onItemDelete.bind(this)}
              index={index}
              setStateInternal={this.setStateInternal}
            />
          ))}
        </CardBackFloatingListContainer>
        <Block
          marginTop="36px"
          marginBottom="16px"
          display="flex"
          justifyContent="flex-end"
        >
          <AddContentButton
            buttonText={'Add Audio'}
            buttonClickOverride={this.onClick}
          />
        </Block>
        {/* <Accordion
          onChange={({ expanded }) => console.log(expanded)}
          overrides={{
            Root: {
              style: { marginLeft: '0px !important' },
            },
            Content: {
              style: { marginLeft: '0px !important' },
            },
          }}
        >
          {options.map((el, index) => {
            console.log(el);
            return (
              <Panel title={el.caption || `Audio ${index + 1}`} key={index}>
                <div>
                  <HeaderTitle
                    style={{
                      color: this.props.theme.colors.primaryA,
                    }}
                  >
                    {'Caption'}
                  </HeaderTitle>
                  <Input
                    value={el.caption}
                    onChange={e =>
                      this.setStateInternal({
                        value: e.target.value,
                        key: 'caption',
                        index,
                      })
                    }
                    placeholder={'Enter Vide Title'}
                    clearOnEscape
                  />

                  <HeaderTitle
                    style={{
                      color: this.props.theme.colors.primaryA,
                      marginTop: this.props.theme.sizing.scale1200,
                    }}
                  >
                    {'Description'}
                  </HeaderTitle>
                  <Textarea
                    value={el.description}
                    onChange={e =>
                      this.setStateInternal({
                        value: e.target.value,
                        key: 'description',
                        index,
                      })
                    }
                    placeholder={'Enter Vide Description'}
                    clearOnEscape
                  />
                   <HeaderTitle
                    style={{
                      color: this.props.theme.colors.primaryA,
                      marginTop: this.props.theme.sizing.scale1200,
                    }}
                  >
                    {'Duration (In Seconds)'}
                  </HeaderTitle>
                  <Input
                    value={el.duration}
                    onChange={e =>
                      this.setStateInternal({
                        value: 1 * e.target.value,
                        key: 'duration',
                        index,
                      })
                    }
                    placeholder={'Enter Duration'}
                    clearOnEscape
                    type="number"
                  />

                  <HeaderTitle
                    style={{
                      color: this.props.theme.colors.primaryA,
                      marginTop: this.props.theme.sizing.scale1200,
                    }}
                  >
                    {'Audio File'}
                  </HeaderTitle>
                  <ImageUploadCXL
                    previewUrls={getPreviewImage(el.file, el.file_data) || []}
                    isMultiple={false}
                    style={{ background: 'transparent' }}
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      this.setStateInternal({
                        value: acceptedFiles,
                        key: 'file',
                        index,
                      })
                    }
                    onImageDelete={() => {
                      this.setStateInternal({
                        value: null,
                        key: 'file',
                        index,
                      })
                      this.setStateInternal({
                        value: null,
                        key: 'file_data',
                        index,
                      })
                    }}
                    accept={this.props.accept}
                    showFullBleedSettings={false}
                    imageSize={cardBackImageGuideline.size}
                    imageSizeGuidelineURL={cardBackImageGuideline.url}
                  ></ImageUploadCXL>

                  
                  <HeaderTitle
                    style={{
                      color: this.props.theme.colors.primaryA,
                      marginTop: this.props.theme.sizing.scale1200,
                    }}
                  >
                    {'Cover Photo'}
                  </HeaderTitle>
                  <ImageUploadCXL
                    previewUrls={getPreviewImage(el.cover_photo, el.cover_photo_data) || []}
                    isMultiple={false}
                    style={{ background: 'transparent' }}
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      this.setStateInternal({
                        value: acceptedFiles,
                        key: 'cover_photo',
                        index,
                      })
                    }
                    onImageDelete={() => {
                      this.setStateInternal({
                        value: null,
                        key: 'cover_photo',
                        index,
                      })
                      this.setStateInternal({
                        value: null,
                        key: 'cover_photo_data',
                        index,
                      })
                    }}
                    showFullBleedSettings={false}
                    imageSize={cardBackImageGuideline.size}
                    imageSizeGuidelineURL={cardBackImageGuideline.url}
                  ></ImageUploadCXL>
                </div>
              </Panel>
            );
          })}
        </Accordion> */}
        {/* <Block
          marginTop="16px"
          marginBottom="16px"
          display="flex"
          justifyContent="center"
        >
          <Button onClick={() => this.onClick()}>Add Audio File</Button>
        </Block> */}
      </>
    );
  }
}

export default React.memo(VideoGalleryComponent);
