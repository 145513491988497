import React from 'react';
import { Input, Textarea } from 'baseui/textarea';
import BaseClass from '../../builder/cardBackEditorComponents/base';
import { Button } from 'baseui/button';
import { MdDelete } from 'react-icons/md'
import { showAlert } from '../../../redux/actions/appBasicControls';
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.value
    });
  }

  alertUser() {
    // console.log('You must select one answer as correct before continuing.')
    this.props.dispatch(
      showAlert({
        msg: 'You must select one answer as correct before continuing.',
        error: true,
      })
    );
  }

  hasValidAnswer() {
    let value = this.state.value || [];
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    const isCorrectAnswer = value.find(item => item.correct == true)
    return isCorrectAnswer
  }

  onRemoveAnswer(index) {
    let value = this.state.value || [];
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    // if (!this.hasValidAnswer()) {
    //   return this.alertUser()
    // }
    let v = JSON.stringify(value.filter((item, i) => i !== index));
    this.setState({value: v})
    this.props.onChange(v);
  }

  render() {
    let value = this.state.value || [];
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    return (
      <>
        <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {this.props.title || ''}
        </h5>
        {value.map((el, i) => {
          // debugger;
          return <>
          <h5
            style={{
              color: this.props.theme.colors.primaryA,
              marginTop: this.props.theme.sizing.scale1200,
            }}
          >
            {`Option #${i + 1}`}
          </h5>
          <div style={{
              marginBottom: '8px',
              display: 'flex',
              justifyContent: 'space-between'
            }}>
          <Checkbox
            checked={el.correct}
            onChange={e => {
              let status = (e.target.checked);
              let v1 = [...value];
              v1[i].correct = status;
              // const isCorrectAnswer = v1.find(item => item.correct == true)
              // if (!isCorrectAnswer) {
              //   return this.alertUser()
              // }
              this.setState({value: JSON.stringify(v1)})
              this.props.onChange(JSON.stringify(v1));
            }}
            labelPlacement={LABEL_PLACEMENT.right}
          >
           Correct Answer
          </Checkbox>
          <MdDelete size={25} color={'rgb(117, 117, 117)'}  style={{cursor: 'pointer'}} onClick={() => this.onRemoveAnswer(i)}>Remove</MdDelete>
          </div>
          <Textarea
          value={el.answer}
          onChange={e => {
            // this.setState({ value: e.target.value })
            let v1 = [...value];
            v1[i].answer = e.target.value;
            if (!this.hasValidAnswer() && !e.target.value) {
              return this.alertUser()
            }
            this.setState({value: JSON.stringify(v1)})
            this.props.onChange(JSON.stringify(v1));
          }}
          placeholder={this.props.placeholder}
          clearOnEscape
        /></>
        })}
        <Button 
        style={{
          marginTop: '16px',
        }}
        onClick={() => {
          // debugger;
          let v1 = [...value];
          v1.push({
            answer: '',
            correct: false,
          });
          // if (value.length !== 0 && !this.hasValidAnswer()) {
          //   return this.alertUser()
          // }
          this.setState({value: JSON.stringify(v1)})
          this.props.onChange(JSON.stringify(v1));
        }}>Add New Answer</Button>
      </>
    );
  }
}

export default InputComponent;
