import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { Search } from "baseui/icon";
import { Input } from "baseui/input";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { useStyletron } from "baseui";


const TeamTagsList = ({ data = [], onSelectTag = () => { }, selectedTags }) => {
  const [css, theme] = useStyletron();

  return data.map(tag => {
    const isSelected = selectedTags.includes(tag?.id);

    const onChangeTag = event => {
      onSelectTag(event.target.checked, tag.id)
    }


    return (
      <div
        key={tag?.id}
        className={css({
          paddingTop: theme.sizing.scale300,
          paddingLeft: '24px',
          paddingRight: '24px',
          minWidth: '300px',
          float: 'left',
          display: 'block'
        })}
      >
        <Checkbox
          checked={isSelected}
          onChange={onChangeTag}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          {tag?.name}
        </Checkbox>
      </div>
    )
  })
}


const TeamTags = ({
  selectedTeamTags = [], setSelectedTeamTags
}) => {
  const { organizationTags } = useSelector(state => state.appBasicControls)
  const [teamTags, setTeamTags] = React.useState([]);
  const [filteredTagList, setFilteredTagList] = React.useState([]);
  const [searchText, setSearchText] = React.useState([]);

  const [css, theme] = useStyletron();

  React.useEffect(() => {
    const teamsTagsList = (organizationTags || [])?.filter(tag => tag.type === "Teams");
    setTeamTags(teamsTagsList);
  }, [organizationTags])

  React.useEffect(() => {
    setFilteredTagList((teamTags || []).filter(tag => tag.name.toLowerCase().includes(searchText)));
  }, [teamTags])

  const handleChange = (isChecked, tagId) => {
    setSelectedTeamTags((preValue) => {
      if (isChecked) {
        return ([...preValue, tagId])
      } else {
        return (preValue.filter(id => id !== tagId))
      }
    })
  }

  const handleSelectAll = (event) => {
    let isChecked = event.target.checked
    if (isChecked) {
      setSelectedTeamTags(filteredTagList.map(tag => tag.id))
    } else setSelectedTeamTags([])
  }

  const onChangeSearchText = (event) => {
    const text = event.target.value;
    setSearchText(text)
    const teamsTagsList = (organizationTags || [])?.filter(tag => tag.name.toLowerCase().includes(text));
    setTeamTags(teamsTagsList);
  }


  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column'
      })}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '10px'
        }}
      >
        <Input
          placeholder="Search by name"
          clearOnEscape
          clearable
          onChange={onChangeSearchText}
          endEnhancer={<Search size="18px" />}
        />
      </div>

      {filteredTagList.length > 0 && (
        <>
          <div
            className={css({
              paddingTop: theme.sizing.scale300,
              paddingRight: '24px',
              marginBottom: '8px',
              minWidth: '300px',
              float: 'left',
              display: 'block'
            })}
          >
            <Checkbox
              onChange={handleSelectAll}
              labelPlacement={LABEL_PLACEMENT.right}
              isIndeterminate={selectedTeamTags.length > 0 && selectedTeamTags.length < filteredTagList.length}
              checked={filteredTagList.length === selectedTeamTags.length}
            >
              Select All Tags
            </Checkbox>
          </div>
          <TeamTagsList
            data={filteredTagList}
            selectedTags={selectedTeamTags}
            onSelectTag={handleChange}
          />
        </>
      )}
    </div>
  )

}

export default TeamTags;