import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { H6, H5 } from 'baseui/typography';
import { Input } from 'baseui/input';
import { useMutation } from '@apollo/client';
import { LabelLarge, LabelMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { deck_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import Button from '../styledButton';
import gql from 'graphql-tag';
import { Select, TYPE, StatefulSelect, SingleSelect } from 'baseui/select';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import { FileUploader } from 'baseui/file-uploader';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
} from 'baseui/modal';
import { saveModalOnEnterPress } from '../../util';
import { sourceByType } from '../../util';
const getSrc = data => {
    if (!data) return data;
    return sourceByType(data);
};

export default ({ defaultTheme, refetchHomeComponents, slide, onClose }) => {
    console.log(slide);
    const [isOpen, setIsOpen] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [css, theme] = useStyletron();
    const [title, setTitle] = React.useState(slide.title);
    const [linkType, setLinkType] = React.useState(slide.link_type);
    const [linkAsset, setLinkAsset] = React.useState(slide.link_asset);
    const [image, setImage] = React.useState(getSrc(slide.image_data));
    const [uploadedFile, setUploadedFile] = React.useState(null);
    const [preview_image, setPreview] = React.useState(null);
    const id = slide.id;
    const [cloneSlide, { loading: onUpdateLoader, data: clonedSlide }] = useMutation(
        gql`
        mutation cloneSlide(
            $id: ID
            $input: SlideInput
        ) {
            cloneSlide(id:$id, input: $input) {
                title
            }
        }
    `
    );



    const onSave = () => {
        setIsLoading(true);
        if (title) {
            cloneSlide({
                variables: {
                    id,
                    input: {
                        title: title,
                        image: uploadedFile,
                        link_type: linkType,
                        link_asset: linkAsset
                    }
                }
            });
        }
    };

    React.useEffect(() => {
        setIsLoading(false);
        if (clonedSlide) {
            refetchHomeComponents();
            setIsOpen(false);
            onClose();
        }
    }, [clonedSlide]);

    const outerGridOverride = {
        marginTop: '30px',
        marginLeft: '0px !important',
        marginRight: '0px !important',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
    };

    // const bgColor = defaultTheme === 'dark' ? 'white' : '#5D64AB';
    const bgColor = defaultTheme === 'dark' ? '#5D64AB' : '#5D64AB';
    return (
        <React.Fragment>
            <Modal
                // animate={true}
                overrides={{
                    Backdrop: {
                        style: ({ $theme }) => ({
                            backdropFilter: $theme.modalBackDropFilter,
                        }),
                    },
                    Root: {
                        style: {
                          zIndex: 3,
                        }
                    },
                }}
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                    onClose();
                }}
                autoFocus
            >
                <ModalHeader>{}</ModalHeader>
                <ModalBody style={{ flex: '1 1 0' }}>
                    <Grid
                        gridGaps={[12, 12, 24]}
                        overrides={{
                            Grid: {
                                style: ({ $theme }) => outerGridOverride,
                            },
                        }}
                    >
                        <div
                            className={css({
                                display: 'flex',
                            })}
                        >
                            <H5>Clone Slide</H5>
                            <HelpQuestionMark text={deck_help}></HelpQuestionMark>
                        </div>
                        <Cell
                            span={[1, 12]}
                            overrides={{
                                Cell: {
                                    style: () => ({
                                        paddingLeft: '0px !important',
                                    }),
                                },
                            }}
                        >
                            <H6>Title</H6>
                            <Input
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                placeholder="Your title"
                                clearOnEscape
                                onKeyDown={e =>
                                    saveModalOnEnterPress({ e, onEnterPress: onSave })
                                }
                            />
                        </Cell>
                        <Cell
                            span={[1, 12]}
                            overrides={{
                                Cell: {
                                    style: () => ({
                                        paddingLeft: '0px !important',
                                    }),
                                },
                            }}
                        >
                            <H6>Slide Image</H6>
                            <img src={preview_image || image} style={{
                                maxHeight: '150px',
                                cursor: 'pointer',
                                opacity:  1,
                                background: 'none'
                            }} />
                            <FileUploader
                                multiple={false}
                                onDrop={(acceptedFiles, rejectedFiles) => {
                                    setUploadedFile(acceptedFiles);
                                    setPreview(URL.createObjectURL(acceptedFiles[0]));
                                }}
                            />
                        </Cell>
                        <Cell
                            span={[1, 12]}
                            overrides={{
                                Cell: {
                                    style: () => ({
                                        paddingLeft: '0px !important',
                                    }),
                                },
                            }}
                        >
                            <H6>Content Type</H6>
                            <Select
                                options={[
                                    { label: 'Video File', id: 'video' },
                                    { label: 'Web URL', id: 'link' },
                                    { label: 'Image File', id: 'image' },
                                ]}
                                value={[
                                    { label: 'Video File', id: 'video' },
                                    { label: 'Web URL', id: 'link' },
                                    { label: 'Image File', id: 'image' },
                                ].filter(el => el.id == linkType)}
                                labelKey="label"
                                valueKey="id"
                                placeholder=''
                                onChange={({ value }) => setLinkType(value[0].id)}
                                overrides={{
                                    Popover: {
                                      props: {
                                        overrides: {
                                          Body: { style: { zIndex: 3 } },
                                        },
                                      },
                                    },
                                }}
                            />
                        </Cell>
                        <Cell
                            span={[1, 12]}
                            overrides={{
                                Cell: {
                                    style: () => ({
                                        paddingLeft: '0px !important',
                                    }),
                                },
                            }}
                        >
                            <H6>Link Asset</H6>
                            <Input
                                value={linkAsset}
                                onChange={e => setLinkAsset(e.target.value)}
                                placeholder=""
                                clearOnEscape
                                onKeyDown={e =>
                                    saveModalOnEnterPress({ e, onEnterPress: onSave })
                                }
                            />
                        </Cell>
                    </Grid>
                </ModalBody>
                <ModalFooter>
                    <ModalButton
                        isLoading={isLoading}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Clone Slide
          </ModalButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};


