import gql from 'graphql-tag';

export const getJWPlayerData = organization_id => gql`
  query jwplayers {
    jwplayers_org(
      organization_id: "${organization_id}"
    ) {
      api_version
      app_key
      app_secret
      id
    }
  }
`;

export const getCardByIdWithModules = (cardId, options) => gql`
query card {
  card(id: "${cardId}") {
    title
    sub_title
    updated_at
    file_data
    front_image_data
    full_bleed
    id
    archived
    card_type
    link

    visible_time
    asset_url
    source_start_day 
    source_end_day 
    source_type
    other_type 
    source_date
    custom_field_id
    custom_field {
      integration_type
		  field_name
    }
    card_stack {
      id
      published
    }
    card_decks {
      deck_id
      published
      deck_category {
        id
        position
        category_id
      }
    }

    has_data_source_connected
    api_path_id

    description
    show_description_instead_of_title

    card_content_modules{
      id
      position
      content_module{
        __typename
        ${options.map(el => {
  return `
            ...on ${el.__typename}{
              id
              ${el.inputs.map(el1 => {
    return `
                  ${el1.key} ${el1.subQueryKeys
        ? `{
                    ${el1.subQueryKeys.map(el2 => el2 + '\n')}
                  }`
        : ''
      }
                `;
  })}
            }
          `;
})}
      }
    }
  }
}`;


export const getQuizCardByQuestionId = (cardId, options = []) => gql`
query quiz_question_quiz_deck {
  quiz_question_quiz_deck_by_question_id(question_id: "${cardId}"){
  quiz_deck_id
  quiz_question_id
  published
  position
  quiz_question_type 
  quiz_question_data{
    __typename
    ...on QuizMatchingQuestion {
      question_text
      pairs
      updated_at
      archived
      id
    }
    ...on QuizTextQuestion {
      question_text
      valid_text_responses
      updated_at
      archived
      id
    }
    ...on QuizOptionQuestion {
      question_text
      options
      updated_at
      archived
      no_of_correct_answers
      has_multiple_answers
      id
    }
    ...on DefaultQuizQuestion {
      question_text
      valid_text_responses
      updated_at
      archived
      id
    }
  }
  card_content_modules{
    id
    position
    content_module{
      __typename
      ${options.map(el => {
  return `
          ...on ${el.__typename}{
            id
            ${el.inputs.map(el1 => {
    return `
                ${el1.key} ${el1.subQueryKeys
        ? `{
                  ${el1.subQueryKeys.map(el2 => el2 + '\n')}
                }`
        : ''
      }
              `;
  })}
          }
        `;
})}
    }
  }
}
}
`;

export const reference_forms_by_sharepoint_form_id = gql`
  query reference_forms_by_sharepoint_form_id ($id: ID) {
    reference_forms_by_sharepoint_form_id(id: $id) {
        id 
        title
        
    }
  }
`

export const sharepoint_form_modules_by_channel_id = gql`
  query sharepoint_form_modules_by_channel_id (
  $channel_id: ID
  $published: Boolean
  $filter: sharepointFormModulesFilter
) {
  sharepoint_form_modules_by_channel_id (
    channel_id: $channel_id
    published: $published
    filter: $filter
  ) {
    id
    title
    parent_id
    reference_form_field_name
    schema
  }
}
`;

export const fileUpload = gql`
  mutation fileUpload ($file: Upload) {
    upload_supporting_doc(file: $file)
  }
`;