import * as React from 'react';
import RightSidePageHeader from '../pageHeaders';
import Button from '../styledButton';
import { Grid, Cell } from 'baseui/layout-grid';
import { gridPaddingOverrides } from '../overrides';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import { useStyletron } from 'baseui';
import { Button as BaseUiButton, SIZE, KIND } from "baseui/button";
import { Drawer } from "baseui/drawer";
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import moment from 'moment';
import auth0 from 'auth0-js';
import { navigate } from 'gatsby';
import { Input } from "baseui/input";

const TAB_STATUS = {
  REQUESTED: 'Requested',
  APPROVED: 'Approved',
  USED: 'Used',
};


const TRAVEL_PASS_API = 'https://micro-pass.herokuapp.com/tickets';

const Pass = ({ organization, user, admin, tab, toaster }) => {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [passes, setPasses] = React.useState([]);
  const [activePass, setActivePass] = React.useState(null);
  const [isApproveLoading, setIsApproveLoading] = React.useState(false);
  const [isRejectLoading, setIsRejectLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState('')

  React.useEffect(() => {
    let p = (data.filter(el => {
      if (tab == TAB_STATUS.REQUESTED) {
        let s = (el.is_issued == false && el.is_used == false);
        return s
      };
      if (tab == TAB_STATUS.APPROVED) {
        let s = (el.is_issued == true && el.is_used == false);
        return s
      };
      if (tab == TAB_STATUS.USED) {
        let s = (el.is_used == true);
        return s
      };
    }));
    if (searchText) {
      p = p.filter((el) => {
        return el.hash.includes(searchText);
      })
    };
    setPasses(p);
  }, [tab, data, searchText]);

  const fetchData = () => {
    fetch(`${TRAVEL_PASS_API}/organization/${organization.id}?limit=100`, {
      method: 'get',
      headers: new Headers({
        'cardware_api_key': 'eMsM6uvh2nxyMCeWZszCNq5K'
      })
    })
      .then(response => response.json())
      .then(data => {
        setData(data.data);
      });
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  console.log(activePass, passes);

  const getPassUser = (pass, key) => {
    if (key) {
      return JSON.parse(pass[key]);
    };
    if (!pass) return {};
    let metadata = JSON.parse(pass.metadata);
    return metadata.user;
  };


  const approvePass = (pass) => {
    setActivePass(pass);
    setIsApproveLoading(true);
    console.log(user.idTokenPayload);
    const body = {
      title: 'Travel Pass',
      description: 'A travel pass can be used when a member is traveling and must be shown at the gym front desk for verification.',
      issued_at_location_id: organization.id,
      ticket_id: pass.id,
      start_time: moment().toDate().toISOString(),
      end_time: moment().add(30, 'days').toDate().toISOString(),
      adminInfo: {
        id: admin ? admin.id : user.idTokenPayload.user_id,
        name: user.idTokenPayload.name,
        email: user.idTokenPayload.email
      }
    };
    console.log(body);
    fetch(`${TRAVEL_PASS_API}/issue`, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'cardware_api_key': 'eMsM6uvh2nxyMCeWZszCNq5K',
        'Content-Type': 'application/json'
      })
    })
      .then(response => response.json())
      .then(data => {
        if (!data.status) {
          console.log('________', data.error);
          let toastKey;
          const msg = data.error;
          toastKey = toaster.negative(
            <>
              {msg}
            </>,
            {
              onClose: () => console.log("Toast closed."),
              overrides: {
                InnerContainer: {
                  style: { width: "100%" }
                }
              }
            }
          );
          setTimeout(() => {
            toaster.clear(toastKey);
          }, 5000);
        };
        fetchData();
        setIsApproveLoading(false);
      });
  };

  const validatePass = (pass) => {
    console.log(pass);
    setActivePass(pass);
    setIsApproveLoading(true);
    const body = {
      ticket_id: pass.id,
      adminInfo: {
        id: admin ? admin.id : user.idTokenPayload.user_id,
        name: user.idTokenPayload.name,
        email: user.idTokenPayload.email
      },
      used_at_location_id: organization.id
    };
    console.log(body);
    fetch(`https://micro-pass.herokuapp.com/tickets/validate`, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'cardware_api_key': 'eMsM6uvh2nxyMCeWZszCNq5K',
        'Content-Type': 'application/json'
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (!data.status) {
          console.log('________', data.error);
          let toastKey;
          const msg = data.error;
          toastKey = toaster.negative(
            <>
              {msg}
            </>,
            {
              onClose: () => console.log("Toast closed."),
              overrides: {
                InnerContainer: {
                  style: { width: "100%" }
                }
              }
            }
          );
          setTimeout(() => {
            toaster.clear(toastKey);
          }, 5000);
        };
        fetchData();
        setIsApproveLoading(false);
      });
  };

  const rejectPass = (pass) => {
    setIsRejectLoading(true);
  };

  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <RightSidePageHeader
          icon={null}
          title={'Passes'}
          rightContent={
            <div style={{ display: 'flex' }}>
              <div style={{ marginTop: '8px', marginRight: '32px', width: '400px' }}>
                <Input
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                  placeholder="Search by Hash"
                  clearOnEscape
                />
              </div>
              <StatefulButtonGroup
                mode={MODE.radio}
                initialState={{ selected: 0 }}
              >
                {Object.keys(TAB_STATUS).map(el => {
                  return (
                    <Button onClick={() => navigate(`/app/${organization.id}/reward/${TAB_STATUS[el]}`)}>
                      {TAB_STATUS[el]}
                    </Button>
                  );
                })}
              </StatefulButtonGroup>
            </div>
          }
          headerDescription={'Manage users travel passes'}
        ></RightSidePageHeader>
        <Cell span={12}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            background: theme.colors.primaryC,
            padding: '12px',
            color: theme.colors.primaryB,
            borderRadius: '3px',
            marginTop: '16px'
          }}>
            <div style={{ width: '20%' }}>Pass ID</div>
            <div style={{ width: '20%' }}>Name</div>
            <div style={{ width: '30%' }}>Email</div>
            <div style={{ width: '15%' }}>View Pass</div>
            {tab == TAB_STATUS.REQUESTED && <div style={{ width: '15%' }}>Approve</div>}
            {tab == TAB_STATUS.APPROVED && <div style={{ width: '15%' }}>Validate & Use</div>}
          </div>
        </Cell>
        {passes.map(pass => {
          let metadata = JSON.parse(pass.metadata);
          return (<Cell span={12}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              color: theme.colors.primaryA
            }}>
              <div style={{ width: '20%' }}>{pass.hash}</div>
              <div style={{ width: '20%' }}>{metadata.user.name}</div>
              <div style={{ width: '30%' }}>{metadata.user.email}</div>
              <div style={{ width: '15%' }}>
                <BaseUiButton
                  onClick={() => {
                    setActivePass(pass);
                    setIsOpen(true);
                  }}
                  size={SIZE.mini}
                >
                  View
                </BaseUiButton>
              </div>
              {tab == TAB_STATUS.REQUESTED && <div style={{ width: '15%' }}>
                <BaseUiButton
                  onClick={() => approvePass(pass)}
                  size={SIZE.mini}
                  isLoading={activePass && (pass.id == activePass.id) && isApproveLoading}
                  disabled={activePass && (pass.id == activePass.id) && isRejectLoading}
                >
                  Approve
                </BaseUiButton>
              </div>}
              {tab == TAB_STATUS.APPROVED && <div style={{ width: '15%' }}>
                <BaseUiButton
                  onClick={() => validatePass(pass)}
                  size={SIZE.mini}
                  isLoading={activePass && (pass.id == activePass.id) && isApproveLoading}
                  disabled={(activePass && (pass.id == activePass.id) && isRejectLoading) || pass.is_used}
                >
                  Validate
                </BaseUiButton>
              </div>}
            </div>
          </Cell>)
        })}
      </Grid>
      <Drawer
        isOpen={isOpen}
        autoFocus
        onClose={() => {
          console.log('drawer closed');
          setIsOpen(false);
          // setActivePass(null);
        }}
        overrides={{
          Root: {
            style: {
              zIndex: 2
            }
          }
        }}
      >
        {(isOpen && activePass) ? <>
          <div style={{ marginBottom: '24px' }}>
            <LabelMedium style={{ marginBottom: '24px' }}>Requested By</LabelMedium>
            <ParagraphSmall style={{ marginBottom: '8px' }}>{getPassUser(activePass).name}</ParagraphSmall>
            <ParagraphSmall style={{ marginBottom: '8px' }}>{getPassUser(activePass).email}</ParagraphSmall>
            <ParagraphSmall style={{ marginBottom: '8px' }}>Requested at: {moment(activePass.created_at).format('lll')}</ParagraphSmall>
          </div>
          {(tab == TAB_STATUS.APPROVED || tab == TAB_STATUS.USED) && <div style={{ marginBottom: '24px' }}>
            <LabelMedium style={{ marginBottom: '24px' }}>Approved By</LabelMedium>
            <ParagraphSmall style={{ marginBottom: '8px' }}>{getPassUser(activePass, 'issued_by_raw').name}</ParagraphSmall>
            <ParagraphSmall style={{ marginBottom: '8px' }}>{getPassUser(activePass, 'issued_by_raw').email}</ParagraphSmall>
            <ParagraphSmall style={{ marginBottom: '8px' }}>Approved at: {moment(activePass.issued_at).format('lll')}</ParagraphSmall>
          </div>
          }
          {
            tab == TAB_STATUS.USED && <div style={{ marginBottom: '24px' }}>
              <LabelMedium style={{ marginBottom: '24px' }}>Uses Info</LabelMedium>
              <ParagraphSmall style={{ marginBottom: '8px' }}>Used at location: {activePass.used_at_location_id}</ParagraphSmall>
              <ParagraphSmall style={{ marginBottom: '8px' }}>Used at: {moment(activePass.used_at).format('lll')}</ParagraphSmall>
            </div>
          }
          {tab == TAB_STATUS.REQUESTED && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {/* <BaseUiButton
              onClick={() => rejectPass(activePass)}
              kind={KIND.secondary}
              size={SIZE.compact}
              isLoading={isRejectLoading}
              disabled={isApproveLoading}
            >
              Reject
                </BaseUiButton> */}
            <div style={{ marginLeft: '16px' }}>
              <BaseUiButton
                onClick={() => approvePass(activePass)}
                size={SIZE.compact}
                isLoading={isApproveLoading}
                disabled={isRejectLoading}
              >
                Approve
                </BaseUiButton>
            </div>
          </div>}
        </> : <></>}
      </Drawer>
    </>
  )
};

export default Pass;
