import { ProgressBar } from "baseui/progress-bar";
import { ParagraphMedium } from "baseui/typography";
import React from "react";
import { useDispatch } from "react-redux";
import { showAlert } from "../../redux/actions/appBasicControls";
import useProgressTrackerQuery from "./useProgressTrackerQuery";

const useProgressTracker = (onProgressDone, onError, progressText = 'import progress is complete') => {

  const [request_id, setRequestId] = React.useState('')

  const { importProgressData: progressTrackerData } = useProgressTrackerQuery(request_id)

  const [progress, setProgress] = React.useState();

  const dispatch = useDispatch();

  React.useEffect(() => {
    // console.log('SubscriptionData', { onProgressDone, onError, SubscriptionData: progressTrackerData, request_id });
    //   const {
    //     import_progress_trackers_by_id = {}
    //   } = progressTrackerData || {}
    const {
      current_task,
      error,
      is_done,
      is_error,
      result,
      total_task
    } = progressTrackerData;
    // console.log({
    //   current_task,
    //   error,
    //   is_done,
    //   is_error,
    //   result,
    //   total_task
    // })

    if (is_done) {
      // dispatch(
      //   removeSharepointFormImportReques({
      //     request_id
      //   })
      // )
      onProgressDone()
    } else if (is_error) {
      dispatch(
        showAlert({
          msg: JSON.stringify(error),
          error: true,
        })
      );
      // dispatch(
      //   removeSharepointFormImportRequest({
      //     request_id
      //   })
      // )
      onError()
    }
    if (current_task) {
      setProgress(parseInt((current_task / total_task) * 100));
    }

  }, [progressTrackerData])

  React.useEffect(() => {
    setProgress(0)
  }, [request_id])

  function setReportRequestId(id) {
    setRequestId(id)
  }

  // console.log('useProgressTracker', {request_id, onProgressDone, onError})

  return ({
    ProgressComponent: (
      <div>
        <ProgressBar
          value={progress}
          getProgressLabel={(value) => <ParagraphMedium paddingBottom={0} marginBottom={'10px'}>{`${value}% ${progressText}`}</ParagraphMedium>}
          showLabel
          overrides={{
            BarProgress: {
              style: ({ $theme, $value }) => {
                return {
                  ...$theme.typography.font350,
                  backgroundColor: $theme.colors.positive,
                  color: $theme.colors.mono200,
                  position: 'relative',
                  marginBottom: '0',
                  ':after': {
                    position: 'absolute',
                    content: $value > 5 ? `"${$value}%"` : '',
                    right: '10px',
                  },
                };
              },
            },
            Bar: {
              style: ({ $theme }) => ({
                height: $theme.sizing.scale800,
              }),
            },
          }}
        />
      </div>
    ),
    progress,
    request_id,
    setReportRequestId
  })
}

export default useProgressTracker;