import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { useMutation } from '@apollo/client';
import { LabelLarge, LabelMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { deck_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import gql from 'graphql-tag';
import { Select } from 'baseui/select';
import { FileUploader } from 'baseui/file-uploader';
import CardPicker from '../modules/generic_modules/cardPickerCXL'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { saveModalOnEnterPress } from '../../util';
import { sourceByType } from '../../util';
import ModalStateContainer from '../modalStateContainer';
import { slideImageGuideline } from '../help/imageSizeGuidelines.json';
import ImageUploadCXL from '../imageUploaderCXL';
import BaseUiTextArea from '../modules/generic_modules/baseuiTextArea';

const getSrc = data => {
  if (!data) return data;
  return sourceByType(data, 'small');
};

export default ({ defaultTheme, refetchHomeComponents, slide, onClose }) => {
  console.log(slide);
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [css, theme] = useStyletron();
  const [title, setTitle] = React.useState(slide.title);
  const [linkType, setLinkType] = React.useState(slide.link_type);
  const [linkAsset, setLinkAsset] = React.useState(slide.link_asset);
  const [redirectCardId, setRedirectCardId] = React.useState(slide.redirect_card_id);
  const [image, setImage] = React.useState(getSrc(slide.image_data));
  const [style_override, set_style_override] = React.useState(slide?.style_override || '');
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [preview_image, setPreview] = React.useState(null);
  const id = slide.id;
  const archived = slide.archived ? true : false;
  const [updateSlide, { loading: onUpdateLoader, data: updatedSlide }] = useMutation(
    gql`
        mutation updateSlide(
            $id: ID
            $input: SlideInput
        ) {
            updateSlide(id:$id, input: $input) {
                title
            }
        }
    `
  );



  const onSave = (req = {}) => {
    const { archived = false } = req;
    setIsLoading(true);
    if (title) {
      updateSlide({
        variables: {
          id,
          input: {
            title: title,
            image: uploadedFile,
            link_type: linkType,
            link_asset: linkAsset,
            archived: archived,
            redirect_card_id: redirectCardId,
            style_override
          }
        }
      });
    }
  };

  React.useEffect(() => {
    setIsLoading(false);
    if (updatedSlide) {
      refetchHomeComponents();
      setIsOpen(false);
      onClose();
    }
  }, [updatedSlide]);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  // const bgColor = defaultTheme === 'dark' ? 'white' : '#5D64AB';
  const bgColor = defaultTheme === 'dark' ? '#5D64AB' : '#5D64AB';

  return (
    <ModalStateContainer>
      {({ isConfirmationOpen, toggleConfirm }) => (
        <React.Fragment>
          <Modal
            // animate={true}
            overrides={{
              Backdrop: {
                style: ({ $theme }) => ({
                  backdropFilter: $theme.modalBackDropFilter,
                }),
              },
              Root: {
                style: {
                  zIndex: 3,
                }
              },
            }}
            isOpen={isOpen}
            autoFocus
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
          >
            <ModalHeader>{ }</ModalHeader>
            <ModalBody style={{ flex: '1 1 0' }}>
              <Grid
                gridGaps={[12, 12, 24]}
                overrides={{
                  Grid: {
                    style: ({ $theme }) => outerGridOverride,
                  },
                }}
              >
                <div
                  className={css({
                    display: 'flex',
                  })}
                >
                  <LabelLarge style={{ marginBottom: '16px' }}>Edit Slide</LabelLarge>
                </div>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
                  <Input
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    placeholder="Your title"
                    clearOnEscape
                    onKeyDown={e =>
                      saveModalOnEnterPress({ e, onEnterPress: onSave })
                    }
                  />
                </Cell>

                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Content Type</LabelMedium>
                  <Select
                    options={[
                      { label: 'Video File', id: 'video' },
                      { label: 'Web URL', id: 'link' },
                      { label: 'Image File', id: 'image' },
                      { label: 'Card', id: 'card' },
                    ]}
                    value={[
                      { label: 'Video File', id: 'video' },
                      { label: 'Web URL', id: 'link' },
                      { label: 'Image File', id: 'image' },
                      { label: 'Card', id: 'card' },
                    ].filter(el => el.id == linkType)}
                    labelKey="label"
                    valueKey="id"
                    placeholder=''
                    onChange={({ value }) => setLinkType(value[0].id)}
                    overrides={{
                      Popover: {
                        props: {
                          overrides: {
                            Body: { style: { zIndex: 3 } },
                          },
                        },
                      },
                    }}
                  />
                </Cell>

                {linkType == 'card' ?
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <CardPicker
                      marginTop={"0px"}
                      selectedCardId={redirectCardId}
                      onCardSelect={setRedirectCardId}
                      title="Card"
                      theme={theme}
                      placeholder="Select Redirecting Card"
                    />
                  </Cell>
                  :
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Link Asset</LabelMedium>
                    <Input
                      value={linkAsset}
                      onChange={e => setLinkAsset(e.target.value)}
                      placeholder=""
                      clearOnEscape
                      onKeyDown={e =>
                        saveModalOnEnterPress({ e, onEnterPress: onSave })
                      }
                    />
                  </Cell>
                }

                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <ImageUploadCXL
                    title={'Slide Image'}
                    titleHelpText={'Select the image for slide'}
                    previewUrls={preview_image || image}
                    isMultiple={false}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      setUploadedFile(acceptedFiles);
                      setPreview(URL.createObjectURL(acceptedFiles[0]));
                    }}
                    onImageDelete={() => {
                      setUploadedFile(null);
                      setPreview(null);
                      setImage(null)
                    }}
                    showFullBleedSettings={false}
                    imageSize={slideImageGuideline.size}
                    imageSizeGuidelineURL={slideImageGuideline.url}
                  ></ImageUploadCXL>
                </Cell>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Style Override</LabelMedium>
                  <BaseUiTextArea
                    value={style_override}
                    onChange={e => {
                      set_style_override(e)
                    }}
                    placeholder={'Write styles here!'}
                  />
                </Cell>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <ModalButton
                isLoading={isLoading}
                onClick={() => {
                  toggleConfirm(true);
                }}
              >
                {archived ? 'Unarchive' : 'Archive'} Slide
              </ModalButton>
              <ModalButton
                isLoading={isLoading}
                onClick={() => {
                  onSave();
                }}
              >
                Update Slide
              </ModalButton>
            </ModalFooter>
          </Modal>
          <Modal
            onClose={() => toggleConfirm(false)}
            isOpen={isConfirmationOpen}
            overrides={{
              Root: {
                style: {
                  zIndex: 4,
                }
              }
            }}
          >
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody>Are you sure you want to {archived ? 'un archive' : 'archive'} this slide?</ModalBody>
            <ModalFooter>
              <ModalButton
                kind="tertiary"
                onClick={() => toggleConfirm(false)}
              >
                No
              </ModalButton>
              <ModalButton
                onClick={() => {
                  onSave({ archived: !archived })
                }}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Yes
              </ModalButton>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      )}
    </ModalStateContainer>
  );
};


