import { gql } from "@apollo/client";

export const createMultimediaCardModule = gql`
    mutation createMultimediaCardModule (
		$file_data: String
		$organization_id: ID
        $is_photo: Boolean
		$is_video: Boolean
		$title: String
		$description: String
		$body: String
		$is_text: Boolean
		$position: Int
		$card_id: ID
		$communication_id: ID
		$home_component_id: ID
        $file: Upload
    ) {
        createMultimediaCardModule(
            file_data: $file_data
            organization_id: $organization_id
            is_photo: $is_photo
            is_video: $is_video
            title: $title
            description: $description
            body: $body
            is_text: $is_text
            card_id: $card_id
            communication_id: $communication_id
            home_component_id: $home_component_id
            file: $file
            position: $position
        ) {
            id
            file_data
            organization_id
            is_photo
            is_video
            title
            description
            body
            is_text
            card_id
            communication_id
            home_component_id
        } 
    }
`

export const updateMultimediaCardModule = gql`
    mutation updateMultimediaCardModule (
        $id: ID!
		$file_data: String
        $is_photo: Boolean
		$is_video: Boolean
		$title: String
		$description: String
		$body: String
		$is_text: Boolean
		$position: Int
		$card_id: ID
		$communication_id: ID
		$home_component_id: ID
        $file: Upload
			$published: Boolean
            $archived: Boolean
    ) {
        updateMultimediaCardModule(
            id: $id
            file_data: $file_data
            is_photo: $is_photo
            is_video: $is_video
            title: $title
            description: $description
            body: $body
            is_text: $is_text
            card_id: $card_id
            communication_id: $communication_id
            home_component_id: $home_component_id
            file: $file
            position: $position
            published: $published
            archived: $archived
        ) {
            id
            file_data
            organization_id
            is_photo
            is_video
            title
            description
            body
            is_text
            card_id
            communication_id
            home_component_id
        } 
    }
`