import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { checkAndUpdatedSelectedChannel, setCategory, setChannels } from '../../redux/actions/appBasicControls';
import { client } from '../../apollo/client';

const getSubForChannelUpdate = id => {
  return gql` 
  subscription OnChannelUpdate ($id: ID!){
    appContentScopeUpdated(id: $id){
      id
      position
      name
      public
      published
      organization_id
      app_content_scope_group_id
      logo_data
      isNew
      pincode
      description
      content_controlled
      media_url
      isDeleted
      abbr
      sales_button_enabled
      sales_incentive_button_title
      sales_incentive_description
      sales_incentive_title
      sales_incentive_image
      archived
      is_requires_subscription
      subscription_into_headline
      subscription_description
      subscription_button_text
      subscription_image_data
      subscription_id
      subscription {
        name
        id
      }
      is_travel_pass_channel
      is_navigation_channel
      has_data_source_connected
      can_share_channel_data
      is_file_explorer
      file_explorer_root_path
      file_explorer_default_view
      is_firebase_channel
      is_primary_channel
      is_modal_presentation
      hide_from_navigation
      display_modal_on_no_sub
      display_modal_on_no_acct
      hide_from_navigation_list_on_modal_check
      modal_presentation_style
      display_modal_on_sub_no_acct
      display_modal_link_acct
      display_modal_link_user_meta_data
      is_multimedia_channel
      is_event_channel
      is_assets_channel
      is_expense_channel
      is_news_channel
      is_announcement_channel
      is_map_channel
      is_analytics_report_template
      tags {
        id
        tag_id
        channel_id
      }
    }
  }`;
};

const getSubForChannelCreate = orgId => {
  return gql`
    subscription OnChannelCreate {
        appContentScopeCreated(orgId:"${orgId}"){
          id
          position
          name
          public
          published
          organization_id
          app_content_scope_group_id
          logo_data
          isNew
          pincode
          description
          content_controlled
          media_url
          abbr
          sales_button_enabled
          sales_incentive_button_title
          sales_incentive_description
          sales_incentive_title
          sales_incentive_image
          archived
          is_requires_subscription
          subscription_into_headline
          subscription_description
          subscription_button_text
          subscription_image_data
          subscription_id
          subscription {
            name
            id
          }
          is_travel_pass_channel
          is_navigation_channel
          has_data_source_connected
          can_share_channel_data
          is_file_explorer
          file_explorer_root_path
          file_explorer_default_view
          tags {
            id
            tag_id
            channel_id
          }
      }
    }
  `;
};

class SubscribeCategory extends React.Component {
  constructor(props) {
    super(props);

    this.listenForCreate = this.listenForCreate.bind(this);
    this.listenForUpdate = this.listenForUpdate.bind(this);

    this.addNewChannelData = this.addNewChannelData.bind(this);
    this.updateChannelData = this.updateChannelData.bind(this);

    this.createSub = null;
    this.updateSub = null;
    this.channelCreateSubscription = null;
    this.channelUpdatesSubscription = null;
  }

  componentDidMount() {
    const primaryDataByOrg = this.props.primaryDataByOrg;
    // Here we are going to subscribe for category, decks, cards

    // 1. category create
    console.log('[SUBSCRIBE] CHANNEL CREATE');
    // this.createSub = this.props.subscribeToMore({
    //   document: getSubForChannelCreate(this.props.orgId),
    //   variables: { orgId: this.props.orgId },
    //   updateQuery: (data1, { subscriptionData }) => {
    //     if (
    //       subscriptionData &&
    //       subscriptionData.data &&
    //       subscriptionData.data.appContentScopeCreated
    //     ) {
    //       console.log('[SUBSCRIBE] CHANNEL CREATE NEW EVENT');
    //       let c = JSON.parse(JSON.stringify(this.props.channels || []));
    //       data1 = JSON.parse(JSON.stringify(data1));
    //       console.log(subscriptionData.data.appContentScopeCreated);
    //       c.push(subscriptionData.data.appContentScopeCreated);
    //       data1.app_content_scopes_org = c;
    //       return data1;
    //     }
    //   },
    // });

    this.listenForCreate();
    this.listenForUpdate();
  }

  UNSAFE_componentWillMount() {
    console.log('[SUBSCRIBE] MOUNTED CHANNEL CREATE');
    this.subscribeToCreate();
    this.subscribeToUpdate();
  }

  componentWillUnmount() {
    console.log('[SUBSCRIBE] UNMOUNTED CHANNEL CREATE');
    // this.createSub && this.createSub();
    this.channelCreateSubscription && this.channelCreateSubscription !== null && this.channelCreateSubscription.unsubscribe();
    this.channelUpdatesSubscription && this.channelUpdatesSubscription !== null && this.channelUpdatesSubscription.map(el => el.unsubscribe());
  }


  subscribeToCreate = () => {
    this.createSub = client.subscribe({
      query: getSubForChannelCreate(this.props.orgId),
      variables: { orgId: this.props.orgId },
    });
  }

  subscribeToUpdate = () => {
    this.updateSub = (this.props.channels || []).map(el => {
      return client.subscribe({
        query: getSubForChannelUpdate(el.id),
        variables: { id: el.id },
      });
    });
  }

  listenForCreate = () => {
    console.log('[SUBSCRIBE] CHANNEL LISTEN CREATE')
    this.channelCreateSubscription = this.createSub && this.createSub !== null && this.createSub.subscribe({ next: this.addNewChannelData })
  }

  listenForUpdate = () => {
    console.log('[SUBSCRIBE] CHANNEL LISTEN UPDATE')
    this.channelUpdatesSubscription = this.updateSub && this.updateSub !== null && this.updateSub.map(el => {
      return el.subscribe({ next: this.updateChannelData })
    })
  }

  addNewChannelData = (subscriptionData) => {
    debugger
    if (
      subscriptionData &&
      subscriptionData.data &&
      subscriptionData.data.appContentScopeCreated
    ) {
      console.log('[SUBSCRIBE] CHANNEL CREATE NEW EVENT');
      let c = JSON.parse(JSON.stringify(this.props.channels || []));
      console.log(subscriptionData.data.appContentScopeCreated);
      c.push(subscriptionData.data.appContentScopeCreated);
      this.updateSub = [...this.updateSub,client.subscribe({
        query: getSubForChannelUpdate(subscriptionData.data.appContentScopeCreated.id),
        variables: { id: subscriptionData.data.appContentScopeCreated.id },
      })]
      this.listenForUpdate()
      this.props.dispatch(
        setChannels(c)
      );
    }
  }

  updateChannelData = (subscriptionData) => {
    console.log('getSubForChannelUpdate data ', subscriptionData)
    debugger
    if (
      subscriptionData &&
      subscriptionData.data &&
      subscriptionData.data.appContentScopeUpdated
    ) {
      let { appContentScopeUpdated } = subscriptionData.data;
      let c = JSON.parse(JSON.stringify(this.props.channels || []));
      debugger
      let index = c.findIndex(el => (el.id == appContentScopeUpdated.id));
      if (appContentScopeUpdated.isDeleted) {
        c.splice(index, 1)
      } else {
        c[index] = appContentScopeUpdated;
      }

      this.props.dispatch(
        setChannels(c)
      );
      this.props.dispatch(
        checkAndUpdatedSelectedChannel(appContentScopeUpdated)
      )

    }
  }



  render() {
    // console.log('[SUBSCRIBE] LISTENER', { channelUpdatesSubscription: this.channelUpdatesSubscription, channelCreateSubscription: this.channelCreateSubscription, updateSub: this.updateSub })
    // 2. category update

    // unsubscribe
    // this.updateSubs && this.updateSubs.map(el => el());
    // subscribe
    // this.updateSubs = (this.props.channels || []).map((el, index) => {
    //   return this.props.subscribeToMore({
    //     document: getSubForChannelUpdate(el.id),
    //     variables: { id: el.id },
    //     updateQuery: (data1, { subscriptionData }) => {
    //       console.log('getSubForCategoryUpdate data ', subscriptionData)
    //       if (
    //         subscriptionData &&
    //         subscriptionData.data &&
    //         subscriptionData.data.appContentScopeUpdated
    //       ) {
    //         let { appContentScopeUpdated } = subscriptionData.data;
    //         let c = JSON.parse(JSON.stringify(this.props.channels || []));
    //         data1 = JSON.parse(JSON.stringify(data1));
    //         debugger
    //         if (appContentScopeUpdated.isDeleted) {
    //           c.splice(index, 1)
    //         } else {
    //           c[index] = appContentScopeUpdated;
    //         }
    //         data1.app_content_scopes_org = c;
    //         return data1;
    //       }
    //     },
    //   });
    // });

    return <></>;
  }
}

const mapStateToProps = props => {
  const { channels } = props.appBasicControls;
  return { channels };
};

export default connect(mapStateToProps)(SubscribeCategory);
