import React, { useMemo } from 'react';
import { Accordion, Panel } from 'baseui/accordion';
import JSONInput from 'react-json-editor-ajrm';

const BaseUiTextArea = ({
  value,
  placeholder,
  locale,
  onChange = () => { }
}) => {
  const placeholderVal = useMemo(() => {
    try {
    return value ? JSON.parse(value) : {}
  } catch(err) {
    return ''
  }
  }, [value])

  const onChangeValue = e => {
    if(!e.error) {
      onChange(JSON.stringify(e.jsObject))
    }
  }

  return (
    <Accordion
      accordion
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            marginLeft: 0,
          })
        }
      }}
    >
      <Panel title={placeholder}>
        <JSONInput
          id='a_unique_id'
          placeholder={placeholderVal}
          theme="dark_vscode_tribute"
          colors={{
            string: "#DAA520" // overrides theme colors with whatever color value you want
          }}
          locale={locale}
          height='550px'
          width={'40vw'}
          onChange={onChangeValue}
        />
      </Panel>
    </Accordion>
  )
}

export default BaseUiTextArea;
