import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import Button from '../styledButton';
import Category from '../category/index';
import ArchivedCards from '../cards/archivedCards';
import RightSidePageHeader from './channelHeader';
import { StatefulSelect } from 'baseui/select';
import { Modal, ModalHeader, ModalBody } from 'baseui/modal';
import { setCreatedCategory, setCreatedComponent, setSelectedChannel } from '../../redux/actions/appBasicControls';
import { sourceByType } from '../../util';
import ChannelsDropDown from './bk-channelsDropdownV1';
import { gridPaddingOverrides } from '../overrides';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useQuery } from '@apollo/client';
import HomeComponents from '../home-components/index';
import { editorBtnOptions as CardBuilderOptions } from '../../../data/cardBuilderOptions';
import { home_components_query } from '../../quries';
import { client } from '../../apollo/client';
import { connect } from 'react-redux';
import { scrollToBottom } from '../builder/builderUtils/helpers';
import TagsSidebar from './tagsSidebar';

const getSrc = data => {
  if (!data) return data;
  return sourceByType(data);
};

const TAB_STATUS = {
  ALL: 'all',
  LIVE: 'published',
  ARCHIVE: 'archived'
};

const Channels = ({
  selectedChannel,
  organization_id,
  dispatch,
  deckLength,
  cardLength,
  categoryLength,
  channels,
  channelGroups,
  refreshQuizQuestions,
  defaultTheme,
  getDescription,
  deckIdFromPath,
  refreshCategories,
  refreshDecks,
  refreshCards,
  createdCategory,
  createdComponent,
  refreshAppContentScopes,
  refreshAppContentScopeGroups,
  isTemplate
}) => {
  const [css, theme] = useStyletron();
  const [activeKey, setActiveKey] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [tab, setTab] = React.useState(TAB_STATUS.ALL);
  const [isChannelDropDownOpen, setChannelDropDownOpen] = React.useState(false);
  const [home_components, setHomeComponents] = React.useState(null);
  const [showTagsSidebar, setShowTagsSidebar] = React.useState(false);
  const [updateSelectedTags, setUpdateSelectedTags] = React.useState(false);

  const refetch = async () => {
    try {
      const { data } = await client.query({
        query: home_components_query(selectedChannel.id, CardBuilderOptions),
        fetchPolicy: 'network-only'
      })
      setHomeComponents(data);
      return data;
    } catch (err) {
      console.log('refetch home component error', err)
    }

  };

  React.useEffect(() => {
    if (!selectedChannel || !selectedChannel.id) return;
  }, [selectedChannel]);

  const buttonOverrides = {
    BaseButton: {
      style: () => ({
        fontFamily: 'Roboto'
      })
    }
  }

  // const scrollToCategory = (catData) => {
  //   debugger
  //   const { id: catId } = catData || {};
  //   const element = document.getElementById(`wrapper_deck${catId}`);
  //   const element1 = document.getElementById('app-container');
  //   // console.log(element);
  //   if (!element) return;
  //   var headerOffset = 115;
  //   var elementPosition = element.getBoundingClientRect().top;;
  //   var offsetPosition = elementPosition - headerOffset;
  //   console.log('scroll param ' + `wrapper_deck${catId}`, elementPosition, offsetPosition, flag)
  //   element1.scrollTo({
  //     top: offsetPosition,
  //     behavior: "smooth"
  //   });
  //   flag += 1;

  //   if (createdComponent || createdCategory) {
  //     createdCategory && dispatch(setCreatedCategory(undefined));
  //     createdComponent && dispatch(setCreatedComponent(undefined));
  //   }
  //   // setTimeout(() => {
  //   //   elementPosition = element.getBoundingClientRect().top;;
  //   //   offsetPosition = elementPosition - headerOffset;
  //   //   console.log('scroll param again ' + `wrapper_deck${catId}`, elementPosition, offsetPosition)
  //   //   element1.scrollTo({
  //   //     top: offsetPosition,
  //   //     behavior: "smooth"
  //   //   });
  //   // }, 300);

  // }

  const refreshData = async () => {
    setTimeout(() => {
      scrollToBottom('app-container', 115);
    }, 300);
    // await refreshCategories();
    // await refreshDecks();
    // await refreshCards();
    // await refetch();
    setTimeout(() => {
      createdCategory && dispatch(setCreatedCategory(undefined));
      createdComponent && dispatch(setCreatedComponent(undefined));
      setTimeout(() => {
        scrollToBottom('app-container', 115);
      }, 300);

    }, 300);
  }

  React.useEffect(() => {
    if (!selectedChannel || !selectedChannel.id) return;
    // if (createdComponent || createdCategory) {
    // refreshData();
    // return;
    // }

    // refreshCategories();
    // refreshDecks();
    // refreshCards();
    refetch();
  }, [selectedChannel])

  const getIconForChannel = logo_data => {
    if (!logo_data) return null;
    let url = getSrc(logo_data);
    return url;
  };

  const headerDescription = `${categoryLength} Categories, ${deckLength} Decks, ${cardLength} Cards`;
  const icon = getIconForChannel(selectedChannel.logo_data);

  const onMenuClick = () => {
    console.log('menu click')
    setChannelDropDownOpen(true);
  }

  const currentChannel = channels.find(ch => ch.id == selectedChannel.id) || {};
  console.log('_______organization_id', organization_id);
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', minHeight: '100%' }}>
        <ChannelsDropDown
          // channels={channels}
          // channelGroups={channelGroups}
          // dispatch={dispatch}
          // selectedChannel={selectedChannel}
          organization_id={organization_id}
          defaultTheme={defaultTheme}
          // tab={tab}
          onClose={() => setChannelDropDownOpen(false)}
          isOpen={isChannelDropDownOpen}
          refreshAppContentScopeGroups={refreshAppContentScopeGroups}
          refreshAppContentScopes={refreshAppContentScopes}
        ></ChannelsDropDown>
        <TagsSidebar
          isOpen={showTagsSidebar}
          onClose={() => setShowTagsSidebar(false)}
          currentChannel={currentChannel}
          setUpdateSelectedTags={setUpdateSelectedTags}
        />
        <div style={{ flexGrow: 1, maxWidth: '100%' }}>
          <Grid overrides={{
            Grid: {
              style: {
                padding: '10px 13px 0 7px !important'
              }
            }
          }} gridMaxWidth={'unset'}>
            <div style={{ display: 'flex', width: '100%', color: 'rgb(98, 70, 238)' }}>

              <div style={{ flexGrow: 1, marginBottom: '20px' }}>
                <RightSidePageHeader
                  fromPage='builder'
                  icon={icon}
                  onMenuClick={onMenuClick}
                  title={currentChannel.name}
                  tab={tab}
                  rightContent={
                    <StatefulButtonGroup
                      mode={MODE.radio}
                      initialState={{ selected: 0 }}

                    >
                      <Button
                        className={css({
                          fontSize: '14px !important'
                        })}
                        overrides={buttonOverrides}
                        onClick={() => setTab(TAB_STATUS.ALL)}>All</Button>
                      <Button
                        className={css({
                          fontSize: '14px !important'
                        })}
                        overrides={buttonOverrides}
                        onClick={() => setTab(TAB_STATUS.LIVE)}>Published</Button>
                      <Button
                        className={css({
                          fontSize: '14px !important'
                        })}
                        overrides={buttonOverrides}
                        onClick={() => setTab(TAB_STATUS.ARCHIVE)}>Archived</Button>
                      {/* <Button onClick={() => setTab(TAB_STATUS.DESIGN)}>Design</Button> */}
                    </StatefulButtonGroup>
                  }
                  headerDescription={getDescription(tab)}
                  channel_id={currentChannel?.id}
                  organization_id={organization_id}
                ></RightSidePageHeader>
              </div>
            </div>
          </Grid>

          {tab == TAB_STATUS.ALL && (
            <Cell span={12} overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important',
                  height: '100% '
                }),
              }
            }}>
              <Category
                appContentScopeId={selectedChannel.id}
                currentChannel={selectedChannel}
                organization_id={organization_id}
                tabFilter={'all'}
                refreshQuizQuestions={refreshQuizQuestions}
                home_component_app_content_scopes={home_components ? home_components.home_component_app_content_scopes_org : []}
                refetchHomeComponents={refetch}
                deckIdFromPath={deckIdFromPath}
                refreshCategories={refreshCategories}
                refreshDecks={refreshDecks}
                refreshCards={refreshCards}
                setShowTagsSidebar={setShowTagsSidebar}
                updateSelectedTags={updateSelectedTags}
              />
            </Cell>
          )}
          {tab == TAB_STATUS.LIVE && (
            <Cell span={12} overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important',
                }),
              }
            }}>
              <Category
                appContentScopeId={selectedChannel.id}
                organization_id={organization_id}
                currentChannel={selectedChannel}
                tabFilter={'published'}
                refreshQuizQuestions={refreshQuizQuestions}
                home_component_app_content_scopes={home_components ? home_components.home_component_app_content_scopes_org : []}
                refetchHomeComponents={refetch}
                deckIdFromPath={deckIdFromPath}
                refreshCategories={refreshCategories}
                refreshDecks={refreshDecks}
                refreshCards={refreshCards}
              ></Category>
            </Cell>
          )}
          {tab == TAB_STATUS.ARCHIVE && (
            <Cell span={12} overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important',
                }),
              }
            }}>
              <Category
                appContentScopeId={selectedChannel.id}
                currentChannel={selectedChannel}
                organization_id={organization_id}
                tabFilter={'archived'}
                refreshQuizQuestions={refreshQuizQuestions}
                home_component_app_content_scopes={home_components ? home_components.home_component_app_content_scopes_org : []}
                refetchHomeComponents={refetch}
                deckIdFromPath={deckIdFromPath}
                refreshCategories={refreshCategories}
                refreshDecks={refreshDecks}
                refreshCards={refreshCards}
              />
            </Cell>
          )}
          {/* {tab == TAB_STATUS.DESIGN && (
            <Cell span={12} overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important',
                }),
              }
            }}>
              <HomeComponents
                appContentScopeId={selectedChannel.id}
                organization_id={organization_id}
                tabFilter={'design'}
                home_component_app_content_scopes={home_components ? home_components.app_content_scope.home_component_app_content_scopes : []}
                refetchHomeComponents={refetch}
              ></HomeComponents>
            </Cell>
          )} */}
          <Modal
            overrides={{
              Backdrop: {
                style: ({ $theme }) => ({
                  backdropFilter: $theme.modalBackDropFilter,
                }),
              }
            }}
            onClose={() => {
              setIsOpen(false);
            }}
            isOpen={isOpen}
          >
            <ModalHeader>Select Channel</ModalHeader>
            <ModalBody>
              <StatefulSelect
                value={[selectedChannel]}
                options={channels}
                labelKey="name"
                valueKey="id"
                onChange={event => {
                  dispatch(setSelectedChannel(event.value[0]));
                  setIsOpen(false);
                }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>

    </>
  );
};

const mapStateToProps = (props) => {
  const {
    createdCategory,
    createdComponent
  } = props.appBasicControls;

  return ({
    createdCategory,
    createdComponent
  })
}


export default connect(mapStateToProps)(Channels);
