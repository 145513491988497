import gql from 'graphql-tag';

export const statsQuery = (organization_id, type, is_trainer) => gql`
  query stats {
    main_page_stats(organization_id: "${organization_id}" type: ${type} is_trainer: ${is_trainer || false}) {
      total_users
      active_users
      cardware_converted_users
      all_channel_permission_users
      archived_users
    }
  }
`;


export const getUsersQuery = (organization_id, type) => gql`
  query users ($pagination: PaginationInput $filter: UserFilterInput) {
    users_v1(
      organization_id: "${organization_id}"
      type: ${type}
      pagination: $pagination
      filter: $filter
    ) {
      count 
      rows {
        ... on Admin {
          id
          email
          name
          approved
          image
          first_name
          last_name
          job_title
          created_at
          has_all_channels_permission
          is_trainer
          tags
          channels {
            id
            name
            organization_id
          }
          form_email_permissions {
            not_started,
            started,
            submitted,
            completed
          }
        }

        ... on AppUser {
          id
          email
          name
          approved
          first_name
          last_name
          job_title
          created_at
          has_all_channels_permission
          is_converted_to_cardware
          is_connected_with_admin
          admin_id
          tags
          channels {
            id
            name
            organization_id
          }
          devices
        }
      }
    }
  }
`;