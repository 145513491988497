import React from "react";
import CustomFieldPicker from "../../customFieldPicker";

const CustomFieldPickerField = ({
  value,
  placeholder,
  onChange = () => { }
}) => {
  return (
    <CustomFieldPicker
      selectCustomFieldId={value}
      onFieldSelect={onChange}
      placeholder={placeholder || ''}
    />
  )
}

export default CustomFieldPickerField;