import { Cell, Grid } from 'baseui/layout-grid';
import * as React from 'react';
import { useStyletron } from 'baseui';
import { connect } from 'react-redux';
import { HeadingLarge, HeadingSmall, HeadingXSmall, LabelMedium, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { Accordion, Panel } from 'baseui/accordion';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';

import AddContentButton from '../addContentButton';
import { cellPaddingOverrides } from '../overrides';
import ImportTagFormsModal from '../modals/import-tag-form-modal';
import { Modal, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { app_content_scope_imported_template_tags, importSharepointFormTagTemplatesMultipleChannels, importSharepointFormTagTemplatesMultipleChannelsSub } from './queries';
import { toaster } from 'baseui/toast';
import { ProgressBar } from "baseui/progress-bar";
import useProgressTrackerQuery from '../progress-tracker/useProgressTrackerQuery';

const useImportTagsSubscription = (request_id) => {
  const [importProgressData, setImportProgressData] = React.useState({})
  const { data: SubscriptionData, error: SubscriptionError } = useSubscription(
    importSharepointFormTagTemplatesMultipleChannelsSub,
    { variables: { request_id } }
  );

  React.useEffect(() => {
    const {
      importSharepointFormTagTemplatesMultipleChannels = {}
    } = SubscriptionData || {};
    // console.log('useImportTagsSubscription SubscriptionData', SubscriptionData);
    setImportProgressData(importSharepointFormTagTemplatesMultipleChannels)
  }, [SubscriptionData]);

  return ({
    importProgressData
  })
}

const Channels = ({
  organization_id,
  ...props
}) => {

  const [css, theme] = useStyletron();
  const [channelList, setChannelList] = React.useState([]);
  const [selectedChannels, setSelectedChannels] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [channelsPublised, setChannelsPublished] = React.useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = React.useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [isImportSuccessfull, setIsImportSuccessfull] = React.useState(false);


  const [importedChannelsTags, setImportedChannelsTags] = React.useState({});

  const [isTagsImporting, setIsTagsImporting] = React.useState(false);

  const [request_id, setRequestId] = React.useState("");

  const { importProgressData } = useProgressTrackerQuery(request_id);

  const [importSharepointFormTagTemplatesMultipleChannelMutation, {
  }] = useMutation(importSharepointFormTagTemplatesMultipleChannels)

  const [fetchChannelImportedTemplates, { data: channelsImportedTemplates }] = useLazyQuery(app_content_scope_imported_template_tags, {
    variables: {
      organization_id
    },
    fetchPolicy: 'network-only'
  })

  React.useEffect(() => {
    fetchImportedTemplatesList()
  }, [])

  React.useEffect(() => {
    const {
      groups = []
    } = props
    const channelData = [...groups]
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map(group => {
        return props.channels
          .filter(el => el.app_content_scope_group_id == group.id)
          .map(el => {
            return ({
              groupName: group.name,
              ...el
            })
          })
      }) || []
    const channelArr = channelData.reduce((acc, curr) => {
      if (!curr) {
        return acc;
      }
      return ([
        ...acc,
        ...curr
      ])
    }, [])
    setChannelList(channelArr)
  }, [props.groups])


  const showToaster = (msg, method = 'info') => {
    let toastKey = toaster[method](
      <>
        {msg}
      </>,
      {
        overrides: {
          InnerContainer: {
            style: { width: "100%" }
          }
        }
      },

    );

    setTimeout(() => { toaster.clear(toastKey) }, 3000);

  };


  React.useEffect(() => {
    const {
      error,
      is_done,
      is_error,
    } = importProgressData;

    if (is_done) {
      onProgressDone();
    } else if (is_error) {
      onProgressError(error)
    }
  }, [importProgressData])

  const onProgressDone = () => {
    setRequestId("");
    let updatedChannelTags = { ...importedChannelsTags }
    selectedChannels.map(el => {
      updatedChannelTags[el] = [
        ...updatedChannelTags[el] || [],
        ...selectedTags
      ]
    })

    setSelectedTags([]);
    setSelectedChannels([]);
    setIsTagsImporting(false);
    setChannelsPublished(false);
    setIsImportModalVisible(false);
    setChannelsPublished(false);
    setImportedChannelsTags(updatedChannelTags)
    // showToaster('Template tags import successfully done', 'positive');
    setIsImportSuccessfull(true);
    fetchImportedTemplatesList();
  }

  const onProgressError = (err) => {
    setIsTagsImporting(false);
    setRequestId("");
    setIsImportModalVisible(true);
    setChannelsPublished(false);
    showToaster(JSON.stringify(err), 'negative');
    fetchImportedTemplatesList();
  }

  const fetchImportedTemplatesList = async () => {
    try {
      const { data } = await fetchChannelImportedTemplates();
      const {
        app_content_scope_imported_template_tags
      } = data || [];

      if (app_content_scope_imported_template_tags && app_content_scope_imported_template_tags.length > 0) {
        let impTagsObj = {};
        app_content_scope_imported_template_tags.map(el => {
          impTagsObj[el.id] = el.template_tags
        })
        setImportedChannelsTags(impTagsObj)
      }

    } catch (err) {
      console.log('fetchChannelImportedTemplates error', err)
    }
  }

  ///////////////////////////////////////////////////////////// Event Functions ////////////////////////////////////////////////////////////////////////

  const onSelectChannel = (channel_id) => {
    if (selectedChannels.includes(channel_id)) {
      setSelectedChannels(prevState => {
        return (
          [
            ...selectedChannels.filter(el => el !== channel_id)
          ]
        )
      })
      return;
    }
    setSelectedChannels(prevState => {
      return (
        [
          ...selectedChannels,
          channel_id
        ]
      )
    })
  }

  const onSelectAllChannels = () => {
    let allChecked = selectedChannels.length === channelList.length;
    if (allChecked) {
      setSelectedChannels([])
    } else {
      setSelectedChannels(
        [...channelList.map(el => el.id)]
      )
    }
  }

  const onCloseImportModal = () => {
    setIsImportModalVisible(false);
    setSelectedTags([]);
    setChannelsPublished(false);
  }

  const onImportTags = (tags, published) => {
    setSelectedTags(tags);
    setChannelsPublished(published);
    setIsWarningModalOpen(true)
  }

  const onCloseWarningModal = () => {
    setIsWarningModalOpen(false)
  }

  const onImportTagsToChannels = async () => {
    try {
      const tag_ids = selectedTags.map(el => el.id);
      const channel_ids = selectedChannels || [];
      setIsTagsImporting(true)
      const { data: importRes } = await importSharepointFormTagTemplatesMultipleChannelMutation({
        variables: {
          tag_ids,
          channel_ids,
          organization_id,
          published: channelsPublised
        }
      })

      const {
        status,
        request_id,
        message
      } = importRes.importSharepointFormTagTemplatesMultipleChannels || {}

      if (status) {
        setRequestId(request_id);
        showToaster('Tag Templates Import In progress', 'positive')
        setIsWarningModalOpen(false);
        setIsImportModalVisible(false);
      } else {
        setIsTagsImporting(false);
        setRequestId("");
        showToaster(message, 'negative')
      }

    } catch (err) {
      console.log('onImportTagsToChannels error', err)
      setRequestId("");
      showToaster(JSON.stringify(err), 'negative')
      setIsTagsImporting(false);
    }
  }

  const onClickAssign = () => {
    setIsImportModalVisible(true)
  }

  ///////////////////////////////////////////////////////////// Render Functions ////////////////////////////////////////////////////////////////////////
  const renderHeader = () => {
    const canAssign = selectedChannels.length > 0;
    let isIndeterminate = selectedChannels.length > 0 && selectedChannels.length < channelList.length;
    let allChecked = selectedChannels.length === channelList.length;
    return (
      <Cell overrides={cellPaddingOverrides} span={12} >
        <div style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '10px',
          paddingLeft: '22px',
          paddingRight: '22px',
          paddingBottom: '30px'
        }}>
          <div
            style={{
              width: '35%'
            }}
          >
            <Checkbox
              onChange={onSelectAllChannels}
              isIndeterminate={isIndeterminate}
              checked={allChecked}
            >
              Select All Channels
            </Checkbox>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              className={css({
                display: 'flex',
                flexDirection: 'row'
              })}
            >
              {
                canAssign && (
                  <AddContentButton
                    buttonText={'Assign'}
                    helpText={`Click to add new form`}
                    buttonClickOverride={onClickAssign}
                  />
                )
              }


            </div>

          </div>
        </div>
      </Cell>
    )
  }

  const renderChannelItem = (item, idx) => {

    const {
      // current,
      // error,
      // is_done,
      // is_error,
      result,
    } = importProgressData;
    // console.log('renderChannelItem result', result)
    const isSelected = selectedChannels.includes(item.id);
    const resultJson = JSON.parse(result || '{}');
    const isProgress = result ? resultJson.hasOwnProperty(item.id) : false;
    let channelProgressData = resultJson.hasOwnProperty(item.id) ? resultJson[item.id] : { total: selectedTags.length, progress: 0 }
    const onCheckBoxClick = () => {
      if (isSelected && isTagsImporting) {
        return;
      }
      onSelectChannel(item.id)
    }
    const channelProgress = ((channelProgressData.progress / channelProgressData.total) * 100).toFixed(2)
    const importedTagsList = importedChannelsTags[item.id] || [];
    // if (isSelected) {
    //   console.log('renderChannelItem', { isSelected, channelProgressData, channelProgress })
    // }
    return (
      <div>
        <div
          className={css({
            display: 'flex',
            paddingTop: '5px',
            paddingBottom: '5px',
            alignItems: 'center',
            width: '100%'

          })}
        >
          <Checkbox
            checked={isSelected}
            onChange={onCheckBoxClick}
            labelPlacement={LABEL_PLACEMENT.right}
          >
          </Checkbox>
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              flex: 1
            })}
            onClick={onCheckBoxClick}
          >

            <ParagraphMedium
              className={css({
                textTransform: 'capitalize',
                fontFamily: 'Roboto',
                textOverflow: 'ellipsis',
                maxHeight: '24px',
                fontWeight: '600',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              })}
              marginBottom={0}
            >
              {item.name}
            </ParagraphMedium>

            {/* {(isSelected && isTagsImporting) && (
              <div
                className={css({
                  marginLeft: '10px'
                })}
              >
                <Spinner size={25} />
              </div>
            )} */}
            {(isSelected && isTagsImporting) && (
              <ProgressBar
                value={channelProgress}
                getProgressLabel={(value) => <ParagraphMedium paddingBottom={0} marginBottom={'10px'}></ParagraphMedium>}
                showLabel
                overrides={{
                  Root: {
                    style: {
                      width: '40%',
                      marginLeft: '10px'
                    }
                  },
                  BarProgress: {
                    style: ({ $theme, $value }) => {
                      return {
                        ...$theme.typography.font350,
                        backgroundColor: $theme.colors.positive,
                        color: $theme.colors.mono200,
                        position: 'relative',
                        marginBottom: '0',
                        ':after': {
                          position: 'absolute',
                          content: $value > 5 ? `"${$value}%"` : '',
                          right: '10px',
                        },
                      };
                    },
                  },
                  Bar: {
                    style: ({ $theme }) => ({
                      height: $theme.sizing.scale800,
                    }),
                  },
                }}
              />
            )}
          </div>

        </div>

        {importedTagsList.length > 0 && (
          <div
            className={css({
              marginLeft: '10px',
              color: 'grey',
              marginBottom: '10px !important'
            })}
          >
            ({importedTagsList.map(el => `${el.name},`)})
          </div>
        )}


      </div>

    )
  }

  const renderChannelsList = (group) => {
    const {
      channels
    } = props
    const filteredChannel = channels.filter(el => el.app_content_scope_group_id == group.id)
    return (
      <div
        className={
          css({
            paddingLeft: '2%',
            backgroundColor: 'whitesmoke'
          })
        }
      >

        {filteredChannel.map(renderChannelItem)}
      </div>
    )

  }

  const renderGroupList = () => {
    const {
      groups = []
    } = props
    return (

      <Accordion
        accordion
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              marginLeft: 0,
            })
          },
          PanelContainer: {
            style: ({ $theme }) => ({
              backgroundColor: 'white',
              marginBottom: '1rem'
            })
          }
        }}
      >
        {(groups || []).map((group, index) => (
          <Panel
            title={
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'

                }}

                id={'position_' + index}
                key={'position_' + index}
              >
                <HeadingXSmall marginBottom={0}>{group.name}</HeadingXSmall>
              </div>
            }

          >
            {renderChannelsList(group)}
          </Panel>
        ))}
      </Accordion>
    )
  }

  const renderWarningModal = () => {
    const onClickYes = () => {
      onImportTagsToChannels();
    }

    const onClickNo = () => {
      onImportTagsToChannels()
    }
    return (
      <Modal
        overrides={{
          Root: {
            style: () => ({
              zIndex: 1
            })
          }
        }}
        onClose={onCloseWarningModal}
        autoFocus={false}
        isOpen={isWarningModalOpen}
      // closeable={false}
      >
        <ModalHeader>Are you sure that you want to assign all of these templates to the selected channels?</ModalHeader>
        <ModalFooter>
          <ModalButton
            onClick={onClickYes}
            isLoading={isTagsImporting}
          >
            Yes
          </ModalButton>
          {/* <ModalButton
            onClick={onClickNo}
          >
            No
          </ModalButton> */}
        </ModalFooter>
      </Modal>
    )
  }

  const renderSuccessfullModal = () => {
    return (
      <Modal
        overrides={{
          Root: {
            style: () => ({
              zIndex: 1
            })
          }
        }}
        onClose={() => setIsImportSuccessfull(false)}
        autoFocus={false}
        isOpen={isImportSuccessfull || false}
      >
        <ModalHeader>Template tags import successfully done</ModalHeader>
        <ModalFooter>
          <ModalButton
            onClick={() => setIsImportSuccessfull(false)}
          >
            Ok
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  // console.log('----------------------> channelList', importedChannelsTags)

  return (
    <>
      <Grid overrides={{
        Grid: {
          style: () => ({
            padding: '0px !important',
            marginTop: '-50px',
            width: '100%'
          }),
        },
      }}>
        {renderHeader()}
        {renderGroupList()}
      </Grid>
      <ImportTagFormsModal
        isVisible={isImportModalVisible}
        organization_id={organization_id}
        theme={theme}
        onRefresh={() => { }}
        onClose={onCloseImportModal}
        onImportTags={onImportTags}
        isMultipleChannelImport
      />
      {renderWarningModal()}
      {renderSuccessfullModal()}
    </>
  )
}

const mapStateToProps = props => {
  const { groups, channels } = props.appBasicControls;
  return {
    groups,
    channels,
  };
};
export default connect(mapStateToProps)(Channels);