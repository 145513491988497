
import * as React from 'react';
import { useStyletron } from 'baseui';
import { useSelector } from 'react-redux';
import ButtonCardware from '../styledButton';
import { getToken } from '../../auth/index';
import { getServerUrl, client } from '../../apollo/client';
import gql from 'graphql-tag';

const TryBetaButton = ({
    fromPage
  }) => {
    const [css, theme] = useStyletron();
    const { products, selectedChannel, user } = useSelector(state => state.appBasicControls);
  
    const [appUrl, setAppUrl] = React.useState('');
    React.useEffect(() => {
      const {
        organization_id
      } = products || {};
      if (!organization_id) {
        return;
      }
      client.query({
        query: gql`
          query organization_pwa_app {
            organization_pwa_app(organization_id: "${organization_id}") {
              app_url
            }
          }
        `
      }).then((response) => {
        try {
          let _appUrl = response.data.organization_pwa_app[0]?.app_url;
          setAppUrl(_appUrl);
        } catch (e) {
  
        }
      })
    }, [products]);
  
    const canShowBeta = React.useMemo(() => {
      const {
        breakthrough_integration,
        engage_integration,
        crm_integration,
        data_collection_integration
      } = products || {};
  
      console.log('canShowBeta breakthrough_integration', fromPage, products)
      // channel_id && hasNextGenVersionEnabled 
      if (fromPage == 'builder' && breakthrough_integration?.is_breakthrough_integration_button_active == true) {
        return true;
      }
      else if (fromPage == 'connect' && engage_integration?.is_engage_button_active == true) {
        return true;
      } else if (fromPage == 'users' && crm_integration?.is_crm_integration_button_active == true) {
        return true;
      } else if (fromPage == 'stash' && data_collection_integration?.is_data_collection_button_active == true) {
        return true;
      }
  
      return false
  
    }, [products, fromPage]);
  
    console.log('canShowBeta----->', fromPage, canShowBeta);
  
    const onClick = async () => {
      const {
        organization_name,
        admin_url,
        api_url,
        breakthrough_integration,
        engage_integration,
        crm_integration,
        data_collection_integration
      } = products || {};
      console.log('Stash onClick', {fromPage, data_collection_integration} )
      let integration
      if (fromPage == 'builder'){
        integration = breakthrough_integration
      } else if (fromPage == 'connect'){
        integration = engage_integration
      } else if (fromPage == 'users'){
        integration = crm_integration
      } else if (fromPage == 'stash'){
        integration = data_collection_integration
      }
      if (!integration?.id) {
        return ;
      }
  
      const tokens = await getToken();
      const token = tokens.idToken;
      const organization_id = tokens.organization_id;
      const base_uri = getServerUrl();
      const pwa_url = appUrl;
      const channel_id = selectedChannel?.id
      console.log(token, organization_id, base_uri, channel_id, pwa_url);
      const req = { token, organization_id, base_uri, channel_id, pwa_url };
      const {
        idTokenPayload
      } = user || {}
      const userPayload = {
        id: idTokenPayload?.user_metadata?.app_user_id,
        name: idTokenPayload?.name,
        email: idTokenPayload?.email
      }
      const payload =  {
        organization_id,
        organization_name,
        admin_url,
        api_url,
        pwa_url,
        channel_id,
        integration,
        token,
        user: userPayload
      }
      var credentials = window.btoa(JSON.stringify(payload));
      console.log('credentials ------>', user);
      let url = `${integration.app_url}?__nguk_redirect_token=${credentials}`
      console.log('url', url)
      // window.open(`https://cardware-breakthrough.netlify.app?__nguk_redirect_token=${btoa(JSON.stringify(req))}`, '_blank');
      // https://cardware-breakthrough.netlify.app/builder?referent_id=e8a46c62-f62f-4fb6-af0d-f805ecc1a45f&referent_type=channel
    //   window.open(`${integration.app_url}?referent_id=e8a46c62-f62f-4fb6-af0d-f805ecc1a45f&referent_type=channel&&__nguk_redirect_token=${btoa(JSON.stringify(req))}`, '_blank');
    window.open(url, '_blank');
    }
  
    if (!canShowBeta) {
      return null;
    }
  
    return (
      <ButtonCardware
        className={css({
          fontSize: '14px !important',
        })}
        overrides={{
          BaseButton: {
            style: () => ({
              fontFamily: 'Roboto'
            })
          }
        }}
        marginTop={'0px'}
        isSelected
        onClick={onClick}>Try beta &#8594;</ButtonCardware>
    )
  }

  export default TryBetaButton