import React from 'react';
import { Textarea } from "baseui/textarea";
import BaseUiTextAreaBuilder from '../generic_modules/baseuiTextArea'

const BaseUiTextArea = ({
  element_type,
  value,
  placeholder,
  onChange = () => { }
}) => {
  const onChangeValue = e => {
    onChange(e.target.value);
  }

  const onChangeValue1 = e => {
    onChange(e);
  }

  if (element_type == 'json') {
    return (
      <BaseUiTextAreaBuilder
        value={value}
        onChange={onChangeValue1}
        placeholder={placeholder}
      />
    )
  }

  return (
    <Textarea
      value={value}
      onChange={onChangeValue}
      placeholder={placeholder}
      clearOnEscape
    />
  );
}

export default BaseUiTextArea;
