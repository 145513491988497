import React, { useMemo } from 'react';
import { DatePicker } from 'baseui/datepicker';

const DateCXL = ({
  value,
  onChange = () => { },
  ...props
}) => {


  let dateValue = useMemo(() => {
    return new Date(
      value ? value : new Date().toDateString()
    );
  }, [value])

  const onChangeDate = ({ date }) => {
    onChange(date)
  }

  return (
    <DatePicker
      value={dateValue}
      onChange={onChangeDate}
    />
  );
}

export default DateCXL;
