import React, { useEffect, useState } from "react";
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import Radium, { StyleRoot } from 'radium';
import { LabelLarge, LabelSmall } from "baseui/typography";

import { useStyletron } from 'baseui';
import styled from '@emotion/styled';
import { bounce, slideInLeft, slideInRight } from 'react-animations';
import ChannelList from './groupChannelDropdown';
import { SearchInput, RelativeDiv, Reorder,Settings, AddWhite } from "./channelsDropdownV1";
import AddEditChannelGroup from '../groups/addEditGroup';
import { setGroups } from '../../redux/actions/appBasicControls';
import { updateGroup, updateChannel } from '../../quries';
import { useMutation } from '@apollo/client';
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip';
import PortalAwareItem from '../home-components/portal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { cellPaddingOverrides, gridPaddingOverrides } from '../overrides';
import { arrayMove } from 'baseui/dnd-list';
import ReorderInModal from '../reorderInModal';
import { Grid, Cell } from 'baseui/layout-grid';
import Button from '../common/button';
import ImportChannelModal from './ImportChannelModal';


const styles = {
  bounce: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounce, 'bounce')
  },
  slideInLeft: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(slideInLeft, 'slideInLeft')
  },
  slideInRight: {
    animation: 'x 1s',
    animationName: Radium.keyframes(slideInRight, 'slideInRight')
  }
}

const MainDiv = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.channelManagerBackgroundColor};
  box-shadow: ${({ theme }) => theme.lighting.shadowCardware};
  height: calc(100vh - 52px);
  max-height: calc(100vh - 52px);
  overflow: auto;
  scroll-behavior: smooth; 
`;

const GroupContainer = styled.div`
  position: relative;
  float: left;
  width: 100%;
  &:hover {
    background: ${({ theme }) => theme.colors.primaryC};
    .groupName{
      color:${({ theme }) => theme.colors.primaryB};
      cursor:pointer;
    }
  };

`;

const TabRootOverride = {
  TabHighlight: {
    style: ({ $theme }) => ({
      background: $theme.colors.primaryC
    })
  },
  Tab: {
    style: ({ $theme }) => ({
      background: $theme.colors.channelManagerBackgroundColor
    }),
  },
  TabList: {
    style: ({ $theme }) => ({
      background: $theme.colors.channelManagerBackgroundColor
    }),
  }
};

const TabOverride = {
  Tab: {
    style: ({ $theme }) => ({
      background: $theme.colors.channelManagerBackgroundColor,
    })
  },
  TabPanel: {
    style: {
      padding: '0px !important'
    }
  }
};

export const SearchContainer = styled.div`
  padding: ${props => props.theme.sizing.scale600};
`;

export const ReorderModal = ({ elements, onDone, onCancel, css, title }) => {
  const [state, setState] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(true);
  React.useEffect(() => { setState(elements) }, [elements]);
  return <ReorderInModal
    title={title}
    isCollapsed={isOpen}
    onClose={(req) => {
      if (req.isSavedFromReorder) {
        onDone(state);
        setIsOpen(false);
      } else { onCancel(); setIsOpen(false); };
    }}
  >
    <DragDropContext
      onDragEnd={({ destination, source, ...rest }) => {
        if (!destination) {
          return;
        }
        let newArr = arrayMove(
          [...state],
          source.index,
          destination.index
        );
        newArr = newArr.map((item, i) => {
          return {
            ...item,
            position: i
          };
        });
        setState(newArr);
      }}
    >
      <Droppable
        droppableId={'row1'}
        type={'group'}
        direction={'vertical'}
        isCombineEnabled={false}
      >
        {dropProvided => (
          <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
            {state?.map((el, index) => {
              const title = el.name;
              return (
                <Grid
                  overrides={gridPaddingOverrides}
                  key={index}
                  gridMaxWidth={'unset'}
                >
                  <Cell span={12} overrides={cellPaddingOverrides}>
                    <Draggable
                      key={el.id}
                      draggableId={el.id}
                      index={index}
                    >
                      {(dragProvided, snapshot) => (
                        <PortalAwareItem
                          snapshot={snapshot}
                          child={
                            <div
                              {...dragProvided.dragHandleProps}
                              {...dragProvided.draggableProps}
                              ref={dragProvided.innerRef}
                            >
                              <div
                                key={index}
                                className={css({
                                  position: 'relative',
                                  width: '600px',
                                  height: '50px',
                                  lineHeight: '50px',
                                  background: snapshot.isDragging ? '#5D64AB' : 'white',
                                  margin: '0',
                                  display: 'flex',
                                  padding: '16px',
                                  color: 'white',
                                  background: '#0D0E13',
                                  marginBottom: '8px'
                                })}
                              >
                                <StatefulTooltip
                                  content={() => <div padding={'20px'}>Reorder</div>}
                                  placement={PLACEMENT.bottom}
                                  returnFocus
                                  autoFocus
                                >
                                  <svg
                                    width="21"
                                    height="18"
                                    viewBox="0 0 21 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={css({
                                      cursor: 'pointer',
                                    })}
                                  >
                                    <path
                                      d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z"
                                      fill={snapshot.isDragging ? 'white' : 'white'}
                                    />
                                  </svg>
                                </StatefulTooltip>
                                <LabelSmall
                                  className={css({
                                    marginLeft: '16px',
                                    color: 'white !important'
                                  })}
                                >
                                  {title}
                                </LabelSmall>
                              </div>
                            </div>
                          }
                        />
                      )}
                    </Draggable>
                  </Cell>
                </Grid>
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </ReorderInModal>
}

const GroupView = ({ group, onGroupClick, organizationId, groups, channels,openGroupUpdate ,onCloseGroupAddEdit, ...props }) => {
  const [css, theme] = useStyletron();
  const [isGroupReorderOn, setIsGroupReorderOn] = React.useState(false);
  const [updateAppContentScopeGroup] = useMutation(updateGroup);

  // const isChannelArchived = (channel) => {
  //   return;
  //   // if (!channel) return;
  //   // let categoryIds = category
  //   // .filter(el => el.app_content_scope_id == channel.id)
  //   // .map(el => el.category_id);

  //   // let decksIds = decks
  //   //   .filter(el => categoryIds.includes(el.category_id))
  //   //   .filter(el => el.deck_category_deck.archived == true)
  //   //   .map(el => el.deck_id);
  //   // const deckLength = decksIds.length;
  //   // return deckLength > 0
  // }

  // useEffect(()=>{
  //   // SET GROUPS
  //   const filterGroups = groups?.filter((c) => {
  //     // MATCH TAB CONDITION
  //     let status = true;
  //     if (tabStatus == 0) {
  //       status = !c.archived
  //     };
  //     if (tabStatus == 1) {
  //       // first group could be archived
  //       let s1 = c.archived;
  //       // second group can have archived channel
  //       let s2 = channels?.filter(c1 => c1.app_content_scope_group_id == c.id).filter(x => x.archived || isChannelArchived(x));
  //       s2 = s2.length > 0 ? true : false;
  //       status = s1 || s2;
  //     };
  //     return status;
  //   }).sort((a, b) => a.position - b.position);
  //   setFilteredGroups(filterGroups);
  // },[groups, tabStatus])

  const groupReorderSubmit = (sorted) => {
    props.dispatch(setGroups(groups.map(g => {
      let f = sorted.find(el => el.id == g.id);
      if (f) {
        return f;
      };
      return g;
    })));
    Promise.all(sorted.map(g => {
      return updateAppContentScopeGroup({
        variables: {
          id: g.id, position: g.position, name: g.name
        }
      })
    }));
  };
  
  return (
    <>
        {/* GROUP REORDER */}
        {isGroupReorderOn && (
          <ReorderModal 
            elements={groups} 
            css={css} 
            onCancel={() => {
              setIsGroupReorderOn(false);
            }} 
            onDone={(d) => {
            setIsGroupReorderOn(false);
            groupReorderSubmit(d);
            }}
          />)}
        {/* END GROUP REORDER */}
        <GroupContainer theme={theme} key={`group-${group.id}`}>
              <div 
                style={{ padding: theme.sizing.scale600, display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}
                onClick={() => onGroupClick(group.id)}
              >
              <LabelLarge 
                style={{ position: 'relative', float: 'left' }}
                className="groupName"
              >
                {group.name}
              </LabelLarge>
              <div style={{display: 'flex', alignItems: 'center', width: '64px', justifyContent: 'space-between'}}>
              <RelativeDiv 
                style={{ height: '10px', top: '50%', transform: 'translateY(-50%)', marginLeft: theme.sizing.scale500, float: 'right' }} 
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openGroupUpdate(group);
                }}
              >
                <Settings color={theme.colors.primaryA} isActive={false} className="groupSettings"></Settings>
              </RelativeDiv>
              <div 
                onClick={() => setIsGroupReorderOn(true)} 
                style={{ position: 'relative', float: 'right' }}
              >
                <Reorder 
                  css={css} 
                  color={theme.colors.primaryA} 
                  style={{ marginTop: theme.sizing.scale100 }} 
                  className="groupReorder"
                >
                  </Reorder>
                </div>
                </div>
            </div>
        </GroupContainer>
    </>
  )
}



const GroupList = ({
  channels,
  groups,
  decks,
  category,
  organization,
  organization_id,
  selectedChannel = {},
  isOpen,
  onClose,
  refreshAppContentScopeGroups,
  refreshAppContentScopes,
  ...props
})  => {
  
  const [css, theme] = useStyletron();
  const [selectedGroupId, setSelectedGroupId] = useState(null); // State to track selected group
  const [channelList, setChannelList] = useState([]); // State to track selected group
  const [searchText, setSearchText] = React.useState(null);
  const [channelSearchText, setChannelSearchText] = React.useState(null);
  const [isAddEditGroupOpen, setIsAddEditGroupOpen] = React.useState(false);
  const [currentGroupInfo, setCurrentGroupInfo] = React.useState({});
  const [tabStatus, setTabStatus] = React.useState(0);
  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
  const [channelTempList, setChannelTempList] = useState([])
  const [filteredGroups, setFilteredGroups] = React.useState([]);
  const [channelColors, setChannelColors] = React.useState([]);

  console.log('_______organization_id', organization_id);
  useEffect(()=>{
    const searchTerm = channelSearchText?.toLocaleLowerCase()?.trim()
    const list = channelTempList?.filter((a)=>a?.name?.toLocaleLowerCase().includes(searchTerm))
    setChannelList(searchTerm ? list : channelTempList)
  },[channelSearchText])

  const isChannelArchived = (channel) => {
    return;
    // if (!channel) return;
    // let categoryIds = category
    // .filter(el => el.app_content_scope_id == channel.id)
    // .map(el => el.category_id);

    // let decksIds = decks
    //   .filter(el => categoryIds.includes(el.category_id))
    //   .filter(el => el.deck_category_deck.archived == true)
    //   .map(el => el.deck_id);
    // const deckLength = decksIds.length;
    // return deckLength > 0
  }

  const getAvatarRandomBackground = () => {
    let color_palette = organization ? organization.color_palette : [];
    color_palette = color_palette || [];
    if (color_palette.length == 0) color_palette = [theme.colors.primaryA];
    return color_palette[Math.floor(Math.random() * color_palette.length)];
  };

  React.useEffect(() => {
    // SET CHANNEL COLORS
    if (channelColors.length == 0 && channelColors.length !== channels.length) {
      setChannelColors(channels.map(el => getAvatarRandomBackground()));
    }
    // SET CHANNELS
    // const filterChannels = channelList.filter((c) => {
    //   // MATCH TAB CONDITION
    //   let status = true;
    //   const isChannelArchive = isChannelArchived(c)
    //   if (parseInt(tabStatus) == 0) {
    //     status = !c.archived
    //   };

    //   if (parseInt(tabStatus) == 1) {
    //     status = c.archived
    //     // const groupOfChannel = groups.filter(g => g.id == c.app_content_scope_group_id)
    //     // status = false;
    //     // if (groupOfChannel.length > 0 && groupOfChannel[0].archived) {
    //     //   status = true;
    //     // }
    //   }

    //   // MATCH SEARCH TEXT
    //   if (channelSearchText && !c.name.toLowerCase().includes(channelSearchText.toLowerCase())) {
    //     status = false;
    //   };
    //   return status;
    // }).sort((a, b) => a.position - b.position);
    // setChannelTempList(filterChannels);

    // SET GROUPS
    const filterGroups = groups.filter((c) => {
      // MATCH TAB CONDITION
      let status = true;
      if (tabStatus == 0) {
        status = !c.archived
      };
      if (tabStatus == 1) {
        // first group could be archived
        let s1 = c.archived;
        // second group can have archived channel
        let s2 = channels.filter(c1 => c1.app_content_scope_group_id == c.id).filter(x => x.archived || isChannelArchived(x));
        s2 = s2.length > 0 ? true : false;
        status = s1 || s2;
      };
      if (searchText && !c.name.toLowerCase().includes(searchText.toLowerCase())) {
            status = false;
          };
      return status;
    }).sort((a, b) => a.position - b.position);
    setTimeout(()=>{setFilteredGroups([...filterGroups]);},100)
    
  }, [channels, groups, searchText, tabStatus, decks]);



  const openGroupAdd = () => {
    setCurrentGroupInfo({
      // totalCount: groups.length,
      group: {}
    });
    setIsAddEditGroupOpen(true);
  };
  const openGroupUpdate = (group) => {
    setCurrentGroupInfo({
      totalCount: groups?.length,
      group
    });
    setIsAddEditGroupOpen(true);
  };
 
  const onCloseGroupAddEdit = () => {
    setIsAddEditGroupOpen(false);
    setCurrentGroupInfo({});
  }

  React.useEffect(() => {
    const overlay = document.querySelector('#app-dropdown-overlay');
    if (overlay) {
      document.querySelector('#app-dropdown-overlay').addEventListener('click', handleOverLayClick);
      return () => {
        document.querySelector('#app-dropdown-overlay').removeEventListener('click', () => handleOverLayClick);
      };
    };
  }, []);


  const handleOverLayClick = () => {
    onClose();
    // document.querySelector('.side-bar-container').style['z-index'] = 0;
    document.querySelector('#app-dropdown-overlay').style.display = 'none'
    document.querySelector('#app-container').style.overflow = 'auto';
  };
  
  React.useEffect(() => {
    if (isOpen) {
      // do not show overlay for connect page
      if (props.parent && props.parent == 'connect') return;
      document.querySelector('#app-dropdown-overlay').style.display = 'block';
      document.querySelector('#app-container').style.overflow = 'hidden';
    }
  }, [isOpen]);


  const handleGroupClick = (groupId) => {
    setSelectedGroupId(groupId); // Update the selected group
    const list = channels.filter(channel => channel.app_content_scope_group_id === groupId).sort((a, b) => a.position - b.position)
    setTimeout(()=>{
      setChannelList([...list])
      setChannelTempList([...list])
    },100)
  };


  useEffect(()=>{
    if(selectedGroupId){
      const list = channels.filter(channel => channel.app_content_scope_group_id === selectedGroupId).sort((a, b) => a.position - b.position)
      setTimeout(()=>{
        setChannelList([...list])
        setChannelTempList([...list])
      },100)
    }
   
  },[channels])

  const openChannelImport = (appContentScopeGroupId) => {
    setIsImportModalOpen(true);
    setSelectedGroupId(appContentScopeGroupId);
  };

  const closeChannelImport = () => {
    setIsImportModalOpen(false);
    setSelectedGroupId();
  };

  console.log('_______organization_id', organization_id);
  return (
    <>
    <MainDiv theme={theme} style={{ width: selectedGroupId !== null ? '700px' : '350px' }}>
       <div style={{width: 350, float: 'left'}}>
          <SearchContainer theme={theme}>
            <SearchInput 
              placeholder="Search for groups"
              searchText={searchText} 
              setSearchText={setSearchText} 
              theme={theme}
            />
          </SearchContainer>
          <Tabs
            activeKey={tabStatus}
            onChange={({ activeKey }) => {
              setTabStatus(activeKey);
            }}
            activateOnFocus
            fill={FILL.fixed}
            overrides={TabRootOverride}
          >
            <Tab title="Live" overrides={TabOverride}>
              {/* <ChannelList /> */}
              { filteredGroups?.map((group, index) => { 
                  return <GroupView 
                          key={group.id} 
                          group={group} 
                          groups={filteredGroups} 
                          {...props} 
                          onGroupClick={handleGroupClick} 
                          organizationId={organization_id}
                          openGroupUpdate={openGroupUpdate}
                          onCloseGroupAddEdit={onCloseGroupAddEdit}
                          channels={channels}
                        />

              })}
            </Tab>
            <Tab title="Archived" overrides={TabOverride}>
              { filteredGroups?.map((group, index) => { 
                return <GroupView 
                        key={group.id} 
                        group={group} 
                        groups={filteredGroups} 
                        {...props} 
                        onGroupClick={handleGroupClick} 
                        organizationId={organization_id}
                        openGroupUpdate={openGroupUpdate}
                        onCloseGroupAddEdit={onCloseGroupAddEdit}
                        channels={channels}
                      />

            })}
            </Tab>
          </Tabs>
           {isAddEditGroupOpen && (<AddEditChannelGroup
              onClose={onCloseGroupAddEdit}
              totalCount={currentGroupInfo.totalCount}
              organization_id={organization_id}
              {...currentGroupInfo.group}
            />)}
           {
        tabStatus == 0 &&
        <RelativeDiv style={{ padding: theme.sizing.scale400 }}>

          <Button
            text={"Group"}
            border_property={"true"}
            startEnhancer={<AddWhite />}
            style={{
              border: `2px solid ${theme.colors.primaryC}`,
              boxSizing: 'border-box',
            }}
            disableHover={true}
            onClick={() => openGroupAdd()}
          />
          <Button
            text={"Import"}
            border_property={"true"}
            startEnhancer={<AddWhite />}
            style={{
              border: `2px solid ${theme.colors.primaryC}`,
              boxSizing: 'border-box',
              marginLeft: 10
            }}
            disableHover={true}
            onClick={() => openChannelImport()}
          />

        </RelativeDiv>}
       </div>
       <ImportChannelModal
        isOpen={isImportModalOpen}
        onCloseImportModal={closeChannelImport}
        organization_id={organization_id}
        refreshAppContentScopeGroups={refreshAppContentScopeGroups}
        refreshAppContentScopes={refreshAppContentScopes}
        app_content_scope_group_id={selectedGroupId}
      />
       {selectedGroupId !== null && (
          <div style={{width:350, overflow: 'auto'}}>
            <div className="groupIndividualChannelContainer" style={styles.slideInLeft}>
                <SearchContainer theme={theme}>
                  <SearchInput 
                    searchText={channelSearchText} 
                    setSearchText={setChannelSearchText} 
                    theme={theme}
                  />
                </SearchContainer>
                <ChannelList
                  group={filteredGroups?.find((a)=>a.id===selectedGroupId)}
                  groupId={selectedGroupId}
                  channelList={channelList}
                  channels={channels}
                  openChannelImport={openChannelImport}
                  organization_id={organization_id}
                  onClose={onClose}
                  {...props}
                />
            </div>
          </div>
        )}
    </MainDiv>
    </>
  );
};


export default GroupList;




