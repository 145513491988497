import React, { useEffect, useMemo, useState } from "react";
import { Cell, Grid } from "baseui/layout-grid";
import { gridPaddingMarginOverrides } from "../overrides";
import styled from "@emotion/styled";
import CustomRadialChart from "../common/graphs/RadialChart";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { useStyletron } from "baseui";
import { useLazyQuery } from "@apollo/client";
import { organization_app_content_scope_compliance_user_analytics } from "../../quries";
import { Skeleton } from "baseui/skeleton";

const GraphsContainer = styled.div`
  width: 100%;
  min-height: 200px;
`;

const PAGE_SIZE = 6

const dummyData = [
  {
    "id": "e3f7fea2-ad53-468b-b6e2-da491396778c",
    "name": "Anvil Steel",
    "no_of_total_user": 5,
    "no_of_compliant_user": 0,
    "no_of_incompliant_user": 5
  },
  {
    "id": "7b3f9a6d-da08-4104-b003-605f1a4f0ecf",
    "name": "BiologyWorks Inc",
    "no_of_total_user": 5,
    "no_of_compliant_user": 4,
    "no_of_incompliant_user": 1
  },
  {
    "id": "0611bde7-fabe-4738-8768-92a10709e6ff",
    "name": "Cacique Inc",
    "no_of_total_user": 5,
    "no_of_compliant_user": 5,
    "no_of_incompliant_user": 0
  },
  {
    "id": "f8701ccf-a156-4f4f-8ef3-32d741f28747",
    "name": "Caffe Luxxe",
    "no_of_total_user": 5,
    "no_of_compliant_user": 0,
    "no_of_incompliant_user": 5
  },
  {
    "id": "409163b2-961b-4d7e-8cd6-5a7b9030caba",
    "name": "Captain America Cookies",
    "no_of_total_user": 5,
    "no_of_compliant_user": 5,
    "no_of_incompliant_user": 0
  },
  {
    "id": "eda799a3-11e6-4cbe-9373-98ec0320c08a",
    "name": "Elkins Kalt Weintraub Reuben & Gartside LLP",
    "no_of_total_user": 5,
    "no_of_compliant_user": 0,
    "no_of_incompliant_user": 5
  },
  {
    "id": "7f541fdc-40cb-425b-97d6-d72bab42210b",
    "name": "Figma",
    "no_of_total_user": 5,
    "no_of_compliant_user": 5,
    "no_of_incompliant_user": 0
  },
  {
    "id": "e1074ee1-dff7-496f-bfe6-669ecdc9a7f6",
    "name": "GF Sub LLC",
    "no_of_total_user": 5,
    "no_of_compliant_user": 0,
    "no_of_incompliant_user": 5
  },
  {
    "id": "fb465ef8-ee43-4f16-8e5a-1f748cf94974",
    "name": "Hawkeye Windows",
    "no_of_total_user": 5,
    "no_of_compliant_user": 4,
    "no_of_incompliant_user": 1
  },
  {
    "id": "4dd900ae-60fe-42f8-9a6a-1709fc1e0b92",
    "name": "Hulk Enterprises",
    "no_of_total_user": 5,
    "no_of_compliant_user": 5,
    "no_of_incompliant_user": 0
  }
]


const ChannelComplianceMetrics = ({ organization_id, onSelectChannel = () => { }, ...props }) => {

  const [css, theme] = useStyletron();
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [userComplianceAnalyticsLoading, setUserComplianceAnalyticsLoading] = useState(false);

  const [fetchComplianceUserAnalytics, { data: userComplianceAnalytics, loading }] = useLazyQuery(
    organization_app_content_scope_compliance_user_analytics,
    {
      variables: {
        organization_id,
        page,
        pageSize: PAGE_SIZE
      },
    }
  )

  useEffect(() => {
    console.log('ChannelComplianceMetrics useEffect')
    getComplianceUserAnalytics(1)
  }, []);


  const getComplianceUserAnalytics = async (pg) => {
    try {
      setUserComplianceAnalyticsLoading(true);
    const {
      data: {
        organization_app_content_scope_compliance_user_analytics
      }
    } = await fetchComplianceUserAnalytics({
      variables: {
        organization_id,
        page: pg,
        pageSize: PAGE_SIZE
      }
    })

    const {
      count = 0,
      data = []
    } = organization_app_content_scope_compliance_user_analytics || {};

    console.log('getComplianceUserAnalytics', { data, count })

    const modifiedData = data.map(el => {
      const compliancePerc = parseFloat(((el.no_of_compliant_user / el.no_of_total_user) * 100).toFixed(2));
      const inCompliancePerc = parseFloat(((el.no_of_incompliant_user / el.no_of_total_user) * 100).toFixed(2));

      return ({
        ...el,
        data: [{
          name: 'In Compliance',
          y: inCompliancePerc
        },
        {
          name: 'Compliance',
          y: compliancePerc,
          sliced: true,
          selected: true,
        },]
      })
    })

    if (pg <= 1) {
      setAnalyticsData(modifiedData)
    } else {
      setAnalyticsData(
        prevState => ([
          ...prevState,
          ...modifiedData
        ])
      )
    }

    setTotalPages(Math.ceil(count / PAGE_SIZE));
    setPage(pg);
    setUserComplianceAnalyticsLoading(false);
    } catch(err) {
      console.log('getComplianceUserAnalytics error', err)
      setUserComplianceAnalyticsLoading(false);
    }

  }


  const handleScroll = (e) => {
    // console.log('handleScroll', {
    //   scrollHeight: e.target.scrollHeight,
    //   scrollTop: e.target.scrollTop,
    //   clientHeight: e.target.clientHeight
    // })
    // console.log('handleScroll',{
    //   userComplianceAnalyticsLoading,

    // })
    if (userComplianceAnalyticsLoading) {
      return;
    }
    const bottom = e.target.scrollHeight - e.target.scrollTop - 0.5 == e.target.clientHeight;
    if (bottom && (totalPages >= page)) {
      getComplianceUserAnalytics(page + 1)
    }
  }

  ///////////////////////////////////////////////////// Render Function ////////////////////////////////////////////////////
  const renderChannelGraphs = () => {
    // console.log('renderChannelGraphs', analyticsData)
    return (
      <GraphsContainer>
        <FlexGrid
          flexGridColumnCount={[1, 1, 1, 1]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
          marginBottom="scale800"
          marginTop="scale800"
        >
          {
            analyticsData.map((el, index) => {
              return (
                <FlexGridItem key={index} overrides={{
                  Block: {
                    style: ({ $theme }) => ({
                      width: `45% !important`,
                      ... (index % 2 == 0) ? {
                        marginRight: '1% !important'
                      } : ''
                    })
                  }
                }}>
                  <div
                    className={css({
                      position: 'relative',
                      background: 'rgb(226, 226, 226)',
                      border: '1px solid hsla(0, 0%, 0%, 0.2)',
                      borderRadius: '5px',
                      minHeight: '230px',
                      overflow: 'hidden',
                    })}
                    onClick={() => onSelectChannel(el?.id)}
                  >
                    <CustomRadialChart
                      title={el?.name}
                      data={el?.data || []}
                      name='Percentage'
                    />
                  </div>
                </FlexGridItem>
              )
            })
          }
          {
            userComplianceAnalyticsLoading && 
            Array.from({ length: 6 }).map((el, index) => {
              return (
                <FlexGridItem key={index} overrides={{
                  Block: {
                    style: ({ $theme }) => ({
                      width: `45% !important`,
                      ... (index % 2 == 0) ? {
                        marginRight: '1% !important'
                      } : ''
                    })
                  }
                }}>

                  <Skeleton
                    width="100%"
                    height="230px"
                    animation
                    overrides={{
                      Root: {
                        style: {
                          marginBottom: '15px',
                          border: '1px solid hsla(0, 0%, 0%, 0.2)',
                          borderRadius: '5px',
                        },
                      },
                    }}
                  />
                </FlexGridItem>
              )
            })
          }
        </FlexGrid>
      </GraphsContainer>
    )
  }

  return (
    <Grid overrides={gridPaddingMarginOverrides} gridMaxWidth={'unset'}>
      <Cell
        span={12}
        overrides={{
          Cell: {
            style: () => ({
              height: '70vh',
              paddingTop: '10px'
            }),
          },
        }}

      >
        <div
          style={{
            overflowY: 'auto',
            height: '100%'
          }}
          onScroll={handleScroll}
        > 
          {renderChannelGraphs()}
        </div>
      </Cell>
    </Grid>
  )
}

export default ChannelComplianceMetrics;