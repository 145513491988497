import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { cellPaddingOverrides, gridPaddingOverrides } from '../overrides';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { HeadingSmall } from 'baseui/typography';


const permissions = [{
  key: 'not_started',
  title: 'Not started',
},
{
  key: 'started',
  title: 'Started',
},
{
  key: 'submitted',
  title: 'Submitted'
},
{

  key: 'completed',
  title: 'Completed'
}
]

export const defaultFormPermissions = {
  not_started: true,
  started: true,
  submitted: true,
  completed: true
}


const UserEmailPermissions = ({
  formEmailPermissions = {},
  setFormEmailPermissions
}) => {
  const [css, theme] = useStyletron();

  const onSelectAllPermissions = (event) => {
    let updatedPermissions = {};
    permissions.map(el => {
      updatedPermissions[el.key] = event.target.checked;
    })
    setFormEmailPermissions(updatedPermissions);
  }

  const isIndeterminate = React.useMemo(() => {
    let selectedCount = 0;
    Object.keys(formEmailPermissions).map(key => {
      if (formEmailPermissions[key] == true) {
        selectedCount++;
      }
    })

    if (selectedCount < 4 && selectedCount > 0) {
      return true;
    }
    return false;
  }, [formEmailPermissions])

  const isAllSelected = React.useMemo(() => {
    let selectedCount = 0;
    Object.keys(formEmailPermissions).map(key => {
      if (formEmailPermissions[key] == true) {
        selectedCount++;
      }
    })

    if (selectedCount == 4) {
      return true;
    }
    return false;
  }, [formEmailPermissions])

  return (
    <Grid overrides={gridPaddingOverrides}>
      <Cell overrides={cellPaddingOverrides} span={12}>
        <div
          className={css({
            paddingTop: theme.sizing.scale300,
            paddingRight: '24px',
            marginBottom: '8px',
            minWidth: '300px',
            float: 'left',
            display: 'block'
          })}
        >
          <HeadingSmall>Form permissions</HeadingSmall>
          <Checkbox
            onChange={onSelectAllPermissions}
            labelPlacement={LABEL_PLACEMENT.right}
            isIndeterminate={isIndeterminate}
            checked={isAllSelected}
          >
            Select All
          </Checkbox>
        </div>
        <div
          className={css({
            position: 'relative',
            float: 'left',
            width: '100%',
            backgroundColor: theme.colors.primaryB,
            marginRight: '48px',
            paddingTop: '16px',
            paddingBottom: '16px',
            borderRadius: '4px',
            marginBottom: '48px',
          })}
        >
          {
            permissions.map((permission, idx) => {
              const isChecked = formEmailPermissions[permission.key] == true;
              const onChange = (e) => {
                setFormEmailPermissions(prevState => ({
                  ...prevState,
                  [permission.key]: e.target.checked
                }))
              }

              return (
                <div
                  key={idx}
                  className={css({
                    paddingTop: theme.sizing.scale300,
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    minWidth: '300px',
                    float: 'left',
                    display: 'block'
                  })}
                >
                  <Checkbox
                    checked={isChecked}
                    onChange={onChange}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    {`${permission.title}`}
                  </Checkbox>
                </div>
              )
            })
          }
        </div>
      </Cell>
    </Grid>
  )
};

export default UserEmailPermissions;
