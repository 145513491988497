import React from 'react';
import PreviewFromPwa from '../components/builder/previewFromPwa';
import { Router } from '@reach/router';
import { Grid, Cell } from 'baseui/layout-grid';

export default function Home({ }) {
    return <div style={{
        backgroundColor: 'white',
        height: '100vh',
        width: '100%',
        overflow: 'scroll'
    }}>
        <Router>
            <PreviewFromPwa
                visibleHeader={true}
                // {...ROUTES_COMMON_PROPS}
                path="/preview/:organization_id/category/:category_id/channel/:channel_id/deck/:deck_id/card/:card_id/preview/:preview_type"
            ></PreviewFromPwa>
        </Router>
    </div>
};
