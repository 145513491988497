import * as React from 'react';
import {
  HeadingXSmall
} from 'baseui/typography';
import {
  Modal,
  ModalBody,
} from 'baseui/modal';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Input } from "baseui/input";
import { addGroup, addChannel } from '../quries';
import { client } from '../apollo/client';
import Vimeo from '@u-wave/react-vimeo';
import { navigate } from 'gatsby';
import { setSelectedChannel } from '../redux/actions/appBasicControls';
import { debounce } from 'lodash';
import { ProgressSteps, NumberedStep } from 'baseui/progress-steps';
import { Button, SHAPE, KIND, SIZE } from 'baseui/button';
import { useStyletron } from 'baseui';
import ImageUploadCXL from '../components/imageUploaderCXL';
import { organizationLogo, appIconSizeGuideLine } from '../components/help/imageSizeGuidelines.json';
import { fileUpload } from '../components/builder/builderUtils/query';
import { updatePwaTemplate } from '../quries';
import { TwitterPicker } from 'react-color';
import { APPENV_OPTIONS as AppEnvOptions, APPENV_QUERY as AppEnvQuery, APPENV_CREATE, UPDATE_THEME } from '../components/settings/appenv-options';

import gql from 'graphql-tag';

const isOnBoardEnabled = true;

function SpacedButton(props) {
  return (
    <Button
      {...props}
      shape={SHAPE.pill}
      kind={KIND.secondary}
      size={SIZE.compact}
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            marginLeft: $theme.sizing.scale200,
            marginRight: $theme.sizing.scale200,
            marginTop: $theme.sizing.scale800,
          }),
        },
      }}
    />
  );
}


const ModalOverrides = {
  Dialog: {
    style: {
      minWidth: '70vw',
      minHeight: '80vh',
      borderRadius: '4px',
      paddingBottom: '28px'
    },
  },
  Backdrop: {
    style: ({ $theme }) => ({
      backdropFilter: $theme.modalBackDropFilter,
    }),
  },
  Close: {
    style: ({ $theme }) => ({
      color: 'white',
      position: 'absolute',
      right: '10px',
      top: '10px'
    }),
  },
  Root: {
    style: {
      zIndex: 3,
      background: 'transparent',
      borderRadius: '8px'
    }
  },
};


const OnboardNewOrganization = ({ channels, groups, organization, isDataLoading, dispatch }) => {
  const [isOnboardActive, setIsOnBoardActive] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [groupName, setGroupName] = React.useState('');
  const [createdGroup, setCreatedGroup] = React.useState(null);
  const [createdChannel, setCreatedChannel] = React.useState(null);
  const [channelName, setChannelName] = React.useState('');
  const [current, setCurrent] = React.useState(0);
  const [css, theme] = useStyletron();

  const [logoPreview, setLogoPreview] = React.useState(null);
  const [loading1, setLoading1] = React.useState(false);
  const [appIconPreview, setAppIconPreview] = React.useState(null);
  const [loading2, setLoading2] = React.useState(false);

  const [primary, setPrimary] = React.useState('#000000');
  const [secondary, setSecondary] = React.useState('#FFFFFF')
  const [loading3, setLoading3] = React.useState(false);

  const debouncedSet = React.useCallback(
    debounce(() => {
      if (groups.length == 0) {
        setIsOnBoardActive(true);
        return;
      };

      if (groups.length > 0 && channels.length == 0) {
        setGroupName(groups[0].name);
        setIsOnBoardActive(true);
        return;
      }
    }, 1000),
    [], // will be created only once initially
  );

  const canOnBoardActive = () => {
    const isGroupExist = groups && Array.isArray(groups) && groups !== null ? groups.length > 0 : false;
    const isChannelExist = channels && Array.isArray(channels) && channels !== null ? channels.length > 0 : false;
    if (!isDataLoading && !isGroupExist && !isChannelExist && isOnBoardEnabled) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    const isSkipped = sessionStorage.getItem('__onBoardSkipped');
    if (isSkipped) {
      return;
    };

    const isOnBoardActive = canOnBoardActive();
    if (!isOnBoardActive) return;
    debouncedSet();

  }, [channels, groups]);

  console.log('ONBOARD NEW ORGANIZATION');
  console.log('ONBOARD isOnboardActive', isOnboardActive, current, groups.length, channels.length);

  const createGroup = () => {
    if (!groupName) return;
    setIsLoading(true);
    return client.mutate({
      mutation: addGroup,
      variables: {
        position: 0,
        name: groupName,
        organization_id: organization.id
      }
    }).then((result) => {
      setCreatedGroup(result.data.createAppContentScopeGroup)
    })
  };


  const createChannel = ({ }) => {
    if (!channelName) return;
    setIsLoading(true);
    return client.mutate({
      mutation: addChannel,
      variables: {
        position: 0,
        name: channelName,
        organization_id: organization.id,
        app_content_scope_group_id: createdGroup.id
      }
    }).then((result) => {
      setCreatedChannel(result.data.createAppContentScope)
      setIsLoading(false)
    })
  }

  const onReady = () => {
    dispatch(setSelectedChannel(channels[0]));
    navigate(`/app/${organization.id}/groups/${createdGroup.id}/channels/${createdChannel.id}`);
    setIsOnBoardActive(false);
  };


  const onImageDrop = async (image) => {
    setLoading1(true);
    await client.mutate({
      mutation: gql`
        mutation updateOrganization(
          $id: ID!
          $logo: Upload
        ) {
          updateOrganization(
            id: $id
            logo: $logo
          ) {
            logo_data
          }
        }
        `,
      variables: {
        id: organization.id,
        logo: image
      }
    });
    setLoading1(false);
  };

  const onAppIconDrop = (image) => {
    setLoading2(true);
    var sr = localStorage.getItem('signup_response');
    console.log(sr);
    try {
      sr = JSON.parse(sr)
    } catch (err) {

    };
    const template_id = sr?.template?.id;
    if (template_id)
      client.mutate({
        mutation: updatePwaTemplate,
        variables: {
          id: template_id,
          input: {
            icon: image,
            organization_id: organization.id
          },
        }
      }).then(() => {
        setLoading2(false);
      })
        .catch(err => {
          setLoading2(false);
        })
  };

  const saveColors = async () => {
    setLoading3(true);
    const d = await client.query({ query: AppEnvQuery, fetchPolicy: 'network-only' });
    var appEnv = d?.data?.organization_app_env_variable_org;
    if (appEnv == null) {
      await client.mutate({
        mutation: APPENV_CREATE,
        variables: {
          input: AppEnvOptions.fields.reduce((a, b) => {
            a[b.key] = b.isBoolean ? false : '';
            a['HEADER_HEIGHT'] = 0;
            a['LOGIN_BUTTON_STYLE'] = 0;
            a['ENV_NAME'] = 'ENV_' + new Date().getTime();
            console.log(a);
            return a;
          }, {})
        }
      })
    };
    const d1 = await client.query({ query: AppEnvQuery, fetchPolicy: 'network-only' });
    appEnv = d1?.data?.organization_app_env_variable_org;
    await client.mutate({
      mutation: UPDATE_THEME,
      variables: {
        ENV_NAME: appEnv.ENV_NAME,
        theme: JSON.stringify({
          appSpecific: {
            blockedRootOptions: [
              'Account',
              'Locations',
              'TravelPass',
              'Travel',
              'Chat'
            ]
          },
          color: {
            primaryColor: primary,
            secondaryColor: secondary,
            appContentBackground: 'rgba(206,207,207,0.5)',
            defaultTextColor: '#171717'
          }
        })
      }
    });
    setLoading3(false);
    setCurrent(3)
  };

  const onSave = async () => {
    setIsLoading(true);
    const group = await client.mutate({
      mutation: addGroup,
      variables: {
        position: 0,
        name: groupName,
        organization_id: organization.id
      }
    }).then((result) => {
      setCreatedGroup(result.data.createAppContentScopeGroup);
      return result.data.createAppContentScopeGroup;
    })
    console.log(group);
    await client.mutate({
      mutation: addChannel,
      variables: {
        position: 0,
        name: channelName,
        organization_id: organization.id,
        app_content_scope_group_id: group.id
      }
    }).then((result) => {
      setCreatedChannel(result.data.createAppContentScope)
      setIsLoading(false)
    })
    setCurrent(4);
  }

  return <>
    {/* // STEP 1 */}
    <Modal
      isOpen={isOnboardActive}
      closeable={false}
      overrides={ModalOverrides}
    >
      <ModalBody>
        <HeadingXSmall>Let's setup new organization together!</HeadingXSmall>
        <ProgressSteps current={current}>
          <NumberedStep title="Upload Logo">
            <div className={css({ ...theme.typography.ParagraphSmall })}>
              Upload the logo for your organization
        </div>
            <ImageUploadCXL
              title={''}
              titleHelpText={'Select the logo for organization'}
              previewUrls={logoPreview}
              isMultiple={false}
              onDrop={(acceptedFiles, rejectedFiles) => {
                onImageDrop(acceptedFiles);
                setLogoPreview(URL.createObjectURL(acceptedFiles[0]));
              }}
              onImageDelete={() => {
                setLogoPreview(null);
              }}
              showFullBleedSettings={false}
              imageSize={organizationLogo.size}
              imageSizeGuidelineURL={organizationLogo.url}
            />
            {loading1 && <div class="loader">
              <div class="loaderBar"></div>
            </div>}
            <SpacedButton disabled>Previous</SpacedButton>
            <SpacedButton disabled={loading1} onClick={() => setCurrent(1)}>
              Next
        </SpacedButton>
          </NumberedStep>
          <NumberedStep title="Upload App Icon">
            <div className={css({ ...theme.typography.ParagraphSmall })}>
              Upload the icon for you app
        </div>
            <ImageUploadCXL
              title={''}
              titleHelpText={'Select the app icon'}
              previewUrls={appIconPreview}
              isMultiple={false}
              onDrop={(acceptedFiles, rejectedFiles) => {
                onAppIconDrop(acceptedFiles);
                setAppIconPreview(URL.createObjectURL(acceptedFiles[0]));
              }}
              onImageDelete={() => {
                setAppIconPreview(null);
              }}
              showFullBleedSettings={false}
              imageSize={appIconSizeGuideLine.size}
              imageSizeGuidelineURL={appIconSizeGuideLine.url}
            />
            {loading2 && <div class="loader">
              <div class="loaderBar"></div>
            </div>}
            <SpacedButton onClick={() => setCurrent(0)}>
              Previous
        </SpacedButton>
            <SpacedButton disabled={loading2} onClick={() => setCurrent(2)}>
              Next
        </SpacedButton>
          </NumberedStep>
          <NumberedStep title="Design Colors">
            <div className={css({ ...theme.typography.ParagraphSmall })}>
              Lets create some colors
        </div>
            <div className={css({ ...theme.typography.ParagraphSmall })} style={{ marginTop: '24px' }}>
              What is your primary color?
        </div>
            <div style={{ marginTop: '16px' }}>
              <TwitterPicker color={primary} onChangeComplete={color => {
                setPrimary(color.hex);
              }} />
              <div className={css({ ...theme.typography.ParagraphSmall })} style={{ marginTop: '24px' }}>
                What is your secondary color?
        </div>
              <div style={{ marginTop: '16px' }}>
                <TwitterPicker color={secondary} onChangeComplete={color => {
                  setSecondary(color.hex);
                }} />
              </div>
              {loading3 && <div class="loader">
                <div class="loaderBar"></div>
              </div>}
            </div>
            <SpacedButton onClick={() => setCurrent(1)}>
              Previous
        </SpacedButton>
            <SpacedButton onClick={saveColors}>Next</SpacedButton>
          </NumberedStep>
          <NumberedStep title="Add Content">
            <div className={css({ ...theme.typography.ParagraphSmall })}>
              Lets add first group and channel
        </div>
            <div style={{ width: '400px', marginTop: '24px' }}>
              <div className={css({ ...theme.typography.ParagraphSmall })} style={{ marginBottom: '12px' }}>
                What is the name of your first group?
        </div>
              <Input
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
                placeholder="Group Name"
                clearOnEscape
              />
              <div className={css({ ...theme.typography.ParagraphSmall })} style={{ marginBottom: '12px', marginTop: '12px' }}>
                What is the name of your first channel?
        </div>
              <Input
                value={channelName}
                onChange={e => setChannelName(e.target.value)}
                placeholder="Channel Name"
                clearOnEscape
              />
            </div>
            {isLoading && <div class="loader">
              <div class="loaderBar"></div>
            </div>}
            <SpacedButton onClick={() => setCurrent(2)}>
              Previous
        </SpacedButton>
            <SpacedButton disabled={isLoading} onClick={onSave}>Next</SpacedButton>
          </NumberedStep>
          <NumberedStep title="Watch Intro">
            <div className={css({ ...theme.typography.ParagraphSmall })}>
              Lets watch a video together to make you comfortable with Cardware
        </div>
            <div style={{ marginTop: '24px' }}>
              <Vimeo
                autoplay={false}
                video={'https://vimeo.com/549285217'}
                height={400}
                controls
              />
            </div>
            <SpacedButton onClick={() => setCurrent(3)}>
              Previous
        </SpacedButton>
            <SpacedButton onClick={onReady}>I am Ready</SpacedButton>
          </NumberedStep>
        </ProgressSteps>
      </ModalBody>
    </Modal>
  </>;
};

const mapStateToProps = props => {
  const { organization, groups, channels } = props.appBasicControls;
  return { organization, groups, channels };
};

const enhancer = compose(
  connect(mapStateToProps),
  React.memo
)
export default enhancer(OnboardNewOrganization);