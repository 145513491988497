import React, { useMemo } from 'react';
import { TimePicker } from 'baseui/timepicker';

const TimeCXL = ({
  value,
  onChange = () => { }
}) => {

  let formatedValue = useMemo(() => {
    return value ? new Date(value) : new Date()
  }, [value]);


  const onChangeTime = (date) => {
    onChange(date)
  }

  return (
    <TimePicker
      value={formatedValue}
      onChange={onChangeTime}
    />
  );
}

export default TimeCXL;
