import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import store from '../../../redux/store';
import { gql } from '@apollo/client';
import { client } from '../../../apollo/client';

export default ({
  value: ids,
  onChange,
  placeholder,
  disabled
}) => {

  const [options, setOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  let organization_id = store.getState().appBasicControls.organization.id;

  React.useEffect(() => {
    setIsLoading(true);
    client.query({
      query: gql`
            query {
                get_locations_by_orgs(organization_id: "${organization_id}") {
                  name
                  id
                }
              }
          `,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setOptions(data.get_locations_by_orgs)
    }).finally(() => {
      setIsLoading(false);
    })
  }, []);

  let matchingOption
  if (options) {
    matchingOption = options.filter((el) => {
      if (!ids) return false;
      return ids.includes(el.id)
    });
  }
  return (
    <>
      <Select
        options={options}
        onChange={(res) => {
          console.log(res);
          onChange(res.value.map(el => el.id))
        }}
        isLoading={isLoading}
        disabled={disabled}
        value={matchingOption}
        placeholder={ids ? '' : placeholder}
        multi={true}
        labelKey={'name'}
        valueKey={'id'}
        maxDropdownHeight="300px"
        type={TYPE.search}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
      />
    </>
  )
}
