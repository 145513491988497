import gql from "graphql-tag";

export const import_progress_trackers_by_id = gql`
    query import_progress_trackers_by_id ($id: ID) {
        import_progress_trackers_by_id(id: $id) {
            id
            organization_id
            channel_id
            total_task
            current_task
            is_done
            is_error
            error
        }
    }
`;