import React, { useEffect, useMemo, useState } from 'react';
import { Select } from 'baseui/select';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getJWPlayerData } from '../../../quries';


const JwPlayerListSelect = ({
  jwPlayerData: jwPlayerList,
  value,
  onChange = () => { },
  organization_id,
  ...props
}) => {
  const [jwPlayerData, setJwPlayerData] = useState();

  const [fetchJwPlayerData, { loading: isLoadingJWPlayer }] = useLazyQuery(getJWPlayerData(organization_id), { fetchPolicy: 'no-cache' })

  useEffect(() => {
    checkAndsetJwPlayerData();
  }, [jwPlayerList]);

  const checkAndsetJwPlayerData = async () => {
    try {
      if (jwPlayerList) {
        setJwPlayerData(jwPlayerList)
      } else {
        const fetchData = await fetchJwPlayerData();
        const {
          data
        } = fetchData;
        console.log('JwPlayerListSelect fetchData', fetchData)
        setJwPlayerData(data)
      }
    } catch (err) {
      console.log('checkAndsetJwPlayerData error', err)
    }
  }

  useEffect(() => {
    if (jwPlayerData) {
      getJWPlayerList()
    }
  }, [jwPlayerData])

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  function getJWPlayerList() {
    console.log('getJWPlayerList', jwPlayerData && jwPlayerData.jwplayers_org && (jwPlayerData?.jwplayers_org || []).length > 0)
    if (jwPlayerData && jwPlayerData.jwplayers_org && (jwPlayerData?.jwplayers_org || []).length > 0) {
      const { app_key, app_secret } = jwPlayerData.jwplayers_org[0];
      const apiUrl = `https://api.jwplayer.com/v2/sites/${app_key}/playlists/`;
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${app_secret}` },
      };
      setIsLoading(true)
      fetch(apiUrl, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data && data.playlists) {
            const nextOptions = data.playlists.map(item => ({ id: item.metadata.title, label: item.metadata.title, description: item.metadata.description }))
            setOptions(nextOptions);
          }
        }).catch((err) => {
          console.log('getJWPlayerList error', err)
        }).finally(() => {
          setIsLoading(false)
        });
    }
  }

  const selectedValue = useMemo(() => {
    return options.filter(i => i.id == value).length > 0 ?
      options.filter(i => i.id == value) :
      [{ id: value, label: value, isCreatable: true }];
  }, [value, options])


  const onChangeValue = ({ value }) => {
    if (value.length > 0) {
      onChange(value ? value[0].id : null)
    }
  }

  console.log('JwPlayerListSelect jwPlayerData', { jwPlayerData, options })

  return (
    <Select
      isLoading={isLoading || isLoadingJWPlayer}
      creatable
      options={options}
      onChange={onChangeValue}
      labelKey="label"
      valueKey="id"
      value={selectedValue}
    />
  );
}

export default JwPlayerListSelect;