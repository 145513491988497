import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { LabelMedium, LabelSmall, ParagraphSmall } from 'baseui/typography';
import { Input } from 'baseui/input';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { useMutation } from '@apollo/client';
import { addChannel, updateChannel, removeChannel, getTagsAndChannel, migrateChannel } from '../../quries';
import { showAlert } from '../../redux/actions/appBasicControls';
import { sourceByType } from '../../util';
import TextArea from './textarea';
import { cellPaddingOverrides } from '../overrides';
import ModalStateContainer from '../modalStateContainer';
import ImageUploadCXL from '../imageUploaderCXL';
import { channelImageGuideline, channelIconGuideline } from '../help/imageSizeGuidelines.json';
import { Tabs, Tab } from "baseui/tabs-motion";
import titlesLength from '../../../data/titlesLength.json';
import store from '../../redux/store';
import { Select } from 'baseui/select';
import ChannelMappings from '../apiMappings/channel-mappings';
import { client } from '../../apollo/client';
import { Tag } from "baseui/tag";
import { useStyletron } from 'baseui';
import DefaultTemplateSelectorModal from '../modals/selector-default-template-modal';
import { Button } from 'baseui/button';
import useProgressTracker from '../progress-tracker/useProgressTracker';
import CustomButton from '../common/button';
import CloudinaryFolderPicker from './clodinary_folder_picker_modal';
import { setEmailToCookie } from '../../auth/helpers';
import { setLastLoggedInOrgId, setAuth0Token } from '../../auth/helpers';
import { setUserInfoToStateFromToken } from '../../auth/index';
import { navigate as navigateGatsby } from 'gatsby';

const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};
const isValidHttpUrl = (string) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(string);
};
const getSrc = data => sourceByType(data, 'full');

export default ({
  app_content_scope_group_id,
  totalCount,
  organization_id,
  onClose,
  logo_data,
  dispatch,
  ...rest
}) => {
  const ORGANIZATION = store.getState().appBasicControls.organization || {};
  console.log(rest, ORGANIZATION);
  const HAS_SUBSCRIPTION = ORGANIZATION.subscriptions ? ORGANIZATION.subscriptions.length > 0 ? true : false : false;
  const [activeKey, setActiveKey] = React.useState("0");
  const [isOpen, setIsOpen] = React.useState(true);
  // console.log('HAS_SUBSCRIPTION', HAS_SUBSCRIPTION, isOpen);
  const [name, setName] = React.useState(rest.name || '');
  const [logo, SetLogo] = React.useState(null);
  const [logoData, setLogoData] = React.useState(logo_data);
  const [publicS, setPublic] = React.useState(rest.public || false);
  const [preview_image, setPreview] = React.useState(null);

  const [icon, setIcon] = React.useState(null);
  const [iconData, setIconData] = React.useState(rest.icon_data);
  const [icon_preview, setIconPreview] = React.useState(null);

  const [description, setDescription] = React.useState(rest.description || '');
  const [mediaUrl, setMediaUrl] = React.useState(rest.media_url || '');
  const [contentControl, setContentControl] = React.useState(
    rest.content_controlled || false
  );
  const [pinCode, setPinCode] = React.useState(rest.pincode || '');
  const [abbr, setAbbr] = React.useState(rest.abbr || '');
  const [sales_button_enabled, setSalesButtonEnabled] = React.useState(rest.sales_button_enabled || false);
  const [sales_incentive_button_title, set_sales_incentive_button_title] = React.useState(rest.sales_incentive_button_title || '');
  const [sales_incentive_description, set_sales_incentive_description] = React.useState(rest.sales_incentive_description || '');
  const [sales_incentive_title, set_sales_incentive_title] = React.useState(rest.sales_incentive_title || '');
  const [sales_incentive_image, set_sales_incentive_image] = React.useState(rest.sales_incentive_image || '');
  const [sales_incentive_image_preview, set_sales_incentive_image_preview] = React.useState(null);
  const [sales_incentive_image_upload, set_sales_incentive_image_upload] = React.useState(null);


  const id = rest.id;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [defaultTemplateSelectorVisible, setDefaultTemplateSelectorVisible] = React.useState(false);
  const archived = rest.archived || false;

  console.log(rest);
  const [is_requires_subscription, set_is_requires_subscription] = React.useState(rest.is_requires_subscription || false);
  const [subscription_ids, set_subscription_id] = React.useState(rest.subscription_ids || '');
  const [subscription_into_headline, set_subscription_into_headline] = React.useState(rest.subscription_into_headline || '');
  const [subscription_button_text, set_subscription_button_text] = React.useState(rest.subscription_button_text || '');
  const [subscription_description, set_subscription_description] = React.useState(rest.subscription_description || '');
  const [subscription_image, set_subscription_image] = React.useState(rest.subscription_image || null);


  const [is_travel_pass_channel, set_is_travel_pass_channel] = React.useState(rest.is_travel_pass_channel || false);
  const [is_navigation_channel, set_is_navigation_channel] = React.useState(rest.is_navigation_channel || false);
  const [has_data_source_connected, set_has_data_source_connected] = React.useState(rest.has_data_source_connected || false);
  const [can_share_channel_data, set_can_share_channel_data] = React.useState(rest.can_share_channel_data || false);
  const [is_import_default_template, set_is_import_default_template] = React.useState(false);
  const [default_templates, set_default_templates] = React.useState([]);

  const [is_file_explorer, set_is_file_explorer] = React.useState(rest.is_file_explorer || false);
  const [file_explorer_root_path, set_file_explorer_root_path] = React.useState(rest.file_explorer_root_path || '');
  const [is_firebase_channel, set_is_firebase_channel] = React.useState(rest.is_firebase_channel || false);
  const [file_explorer_default_view, set_file_explorer_default_view] = React.useState(rest.file_explorer_default_view || '');
  const [is_primary_channel, set_is_primary_channel] = React.useState(rest.is_primary_channel || false);

  const [is_modal_presentation, set_is_modal_presentation] = React.useState(rest.is_modal_presentation || false);
  const [display_modal_on_no_sub, set_display_modal_on_no_sub] = React.useState(rest.display_modal_on_no_sub || false);
  const [display_modal_on_no_acct, set_display_modal_on_no_acct] = React.useState(rest.display_modal_on_no_acct || false);
  const [hide_from_navigation_list_on_modal_check, set_hide_from_navigation_list_on_modal_check] = React.useState(rest.hide_from_navigation_list_on_modal_check || false);
  const [display_modal_link_user_meta_data, set_display_modal_link_user_meta_data] = React.useState(rest.display_modal_link_user_meta_data || false);

  const [modal_presentation_style, set_modal_presentation_style] = React.useState(rest.modal_presentation_style || 'modal');
  const [display_modal_on_sub_no_acct, set_display_modal_on_sub_no_acct] = React.useState(rest.display_modal_on_sub_no_acct || false);
  const [display_modal_link_acct, set_display_modal_link_acct] = React.useState(rest.display_modal_link_acct || false);
  const [is_analytics_report_template, set_is_analytics_report_template] = React.useState(rest.is_analytics_report_template || false);


  const [hide_from_navigation, set_hide_from_navigation] = React.useState(rest.hide_from_navigation || false);
  const [is_multimedia_channel, set_is_multimedia_channel] = React.useState(rest.is_multimedia_channel || false);
  const [is_map_channel, set_is_map_channel] = React.useState(rest.is_map_channel || false);
  const [is_event_channel, set_is_event_channel] = React.useState(rest.is_event_channel || false);
  const [is_news_channel, set_is_news_channel] = React.useState(rest.is_news_channel || false);
  const [is_announcement_channel, set_is_announcement_channel] = React.useState(rest.is_announcement_channel || false);
  const [is_assets_channel, set_is_assets_channel] = React.useState(rest.is_assets_channel || false);
  const [is_expense_channel, set_is_expense_channel] = React.useState(rest.is_expense_channel || false);
  const [isCloudinaryPickerOpen, setIsCloudinaryPickerOpen] = React.useState(false);

  const { ProgressComponent, request_id, setReportRequestId } = useProgressTracker(onProgressDone, onProgressError, 'template automation is completed');

  const [css] = useStyletron();

  const { is_api_settings_on, is_rewards_nav_on, is_channel_advanced_on, is_sales_button_on, is_subscription_on } = store.getState().appBasicControls.organization_settings;

  const previewImage = file => {
    setPreview(URL.createObjectURL(file));
  };

  const [updateAppContentScope] = useMutation(updateChannel);
  const [createAppContentScope] = useMutation(addChannel);

  const [tags, setTags] = React.useState([]);
  const [tagData, setTagData] = React.useState([]);
  const [migrateChannelLoading, setMigrateChannelLoading] = React.useState(false);
  const _migrateChannel = () => {
    setMigrateChannelLoading(true);
    client.mutate({
      mutation: migrateChannel,
      variables: {
        organization_id,
        channel_id: id,
        admin_location: window.location.origin
      }
    })
      .then((result) => {
        // console.log(result);
        // setMigrateChannelLoading(false);
        let data = result.data.migrateChannelData;
        if (data.status) {
          let response = JSON.parse(data.data);
          console.log(response);
          setEmailToCookie(response.admin.email);
          setLastLoggedInOrgId(response.organization.id);
          setAuth0Token(response.authLogin.idToken);
          setUserInfoToStateFromToken(response.authLogin.idToken, response.organization.id, response.auth.domain, () => {
            localStorage.setItem('signup_response', JSON.stringify(response));
            navigateGatsby(`/app/${response.organization.id}/groups/standard/channels`);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          })
        }
      })
      .catch(err => {
        Error();
      })
  }

  const loadTags = () => {
    if (!id) return;
    client.query({
      query: getTagsAndChannel(organization_id, id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      console.log('tags__result', data);
      let tags = data.tags_org;
      tags = tags.filter(el => {
        return el.type == 'category' || el.type == 'channel';
      });
      tags = tags.sort((a, b) => {
        let v1 = (a.type == 'category' || a.type == 'channel') ? 1 : 0;
        let v2 = (b.type == 'category' || b.type == 'channel') ? 1 : 0;
        return v2 - v1;
      });
      setTagData(tags);
      setTags(data.app_content_scope.tags.map(el => el.tag))
    })
  };

  console.log(tags, tagData);

  React.useEffect(() => {
    loadTags();
    // setIsLoadingCloudinaryData(true);
    // fetchCloudinaryFolders(organization_id).then(r => {
    //   set_cloudinary_folders(processCloudinaryArray(r));
    //   setIsLoadingCloudinaryData(false);
    // });
  }, []);

  const processCloudinaryArray = (f) => {
    let _a = [];
    const p = (t) => {
      t.forEach(el => {
        _a.push(el.path);
        if (el.sub.length > 0) {
          p(el.sub);
        }
      })
    };
    p(f.folders);
    return _a.map(el => {
      return { name: el, id: el }
    });
  }


  // console.log(cloudinary_folders, 'cloudinary_folders');


  const FILE_EXPLORER_VIEW_OPTIONS = [
    {
      id: 'tab_list_view',
      name: 'List View with Filter Tab'
    },
    {
      id: 'tab_list_view_large',
      name: 'List View with Filter Tab (Big Image)'
    },
    {
      id: 'tab_grid_view',
      name: 'Grid Folder View with Filter Tab'
    }
  ];


  function Success() {
    setIsLoading(false);
    setIsRemoveLoading(false);
    setIsOpen(false);
    onClose();
  };

  function Error() {
    setIsLoading(false);
    setIsRemoveLoading(false);
    setError(true);
  }

  function onProgressDone() {
    setReportRequestId('');
    Success();
  }

  function onProgressError() {
    setReportRequestId('');
    Error();
  }

  const onRemove = () => {
    if (!id) {
      return;
    }
    setIsRemoveLoading(true);
    updateAppContentScope({
      variables: {
        id,
        archived: !rest.archived
      },
    }).then(() => Success()).catch(() => Error());
  }

  const onSave = () => {
    if (mediaUrl && mediaUrl !== "" && !isValidHttpUrl(mediaUrl)) {
      return;
    }
    if (id) {
      if (publicS && archived && dispatch) {
        return dispatch(
          showAlert({
            msg: 'Archived channel can\'t be published!',
            error: true,
          })
        );
      }
      setIsLoading(true);
      updateAppContentScope({
        variables: {
          id,
          name,
          // public: publicS,
          logo,
          icon,
          logo_data: logoData,
          icon_data: iconData,
          description,
          media_url: mediaUrl,
          content_controlled: contentControl,
          pincode: pinCode,
          abbr,
          sales_button_enabled,
          sales_incentive_button_title,
          sales_incentive_description,
          sales_incentive_title,
          sales_incentive_image,
          sales_incentive_image_upload,
          is_requires_subscription,
          is_travel_pass_channel,
          is_navigation_channel,
          has_data_source_connected,
          subscription_ids,
          subscription_into_headline,
          subscription_button_text,
          subscription_description,
          subscription_image,
          can_share_channel_data,
          is_file_explorer,
          file_explorer_root_path,
          file_explorer_default_view,
          is_firebase_channel,
          is_primary_channel,
          is_modal_presentation,
          hide_from_navigation,
          modal_presentation_style,
          display_modal_on_sub_no_acct,
          display_modal_link_acct,
          display_modal_on_no_sub,
          display_modal_on_no_acct,
          hide_from_navigation_list_on_modal_check,
          display_modal_link_user_meta_data,
          is_analytics_report_template,
          is_multimedia_channel,
          is_event_channel,
          is_assets_channel,
          is_expense_channel,
          is_news_channel,
          is_announcement_channel,
          is_map_channel,
          tags: tags.map(el => {
            return {
              tag_id: el.id, channel_id: id, organization_id
            }
          })
        },
      }).then(() => Success()).catch(() => Error());
    } else {
      if (!name) return null;
      setIsLoading(true);
      createAppContentScope({
        variables: {
          name,
          public: publicS,
          logo,
          icon,
          position: totalCount,
          app_content_scope_group_id,
          position: totalCount,
          organization_id,
          description,
          media_url: mediaUrl,
          content_controlled: contentControl,
          pincode: pinCode,
          abbr,
          sales_button_enabled,
          sales_incentive_button_title,
          sales_incentive_description,
          sales_incentive_title,
          sales_incentive_image,
          sales_incentive_image_upload,
          is_requires_subscription,
          is_travel_pass_channel,
          is_navigation_channel,
          has_data_source_connected,
          subscription_ids,
          subscription_into_headline,
          subscription_button_text,
          subscription_description,
          subscription_image,
          can_share_channel_data,
          is_file_explorer,
          file_explorer_root_path,
          file_explorer_default_view,
          is_firebase_channel,
          is_primary_channel,
          is_modal_presentation,
          hide_from_navigation,
          display_modal_on_no_sub,
          display_modal_on_no_acct,
          hide_from_navigation_list_on_modal_check,
          default_template_group_ids: default_templates.map(el => el.id),
          modal_presentation_style,
          display_modal_on_sub_no_acct,
          display_modal_link_acct,
          is_analytics_report_template,
          is_event_channel,
          is_assets_channel,
          is_expense_channel,
          is_news_channel,
          is_announcement_channel,
        },
      }).then(({
        data
      }) => {
        const {
          createAppContentScope
        } = data
        // console.log('createAppContentScope res', createAppContentScope)
        if (createAppContentScope.request_id) {
          setReportRequestId(createAppContentScope.request_id)
          return
        }
        return Success()
      }).catch(() => Error());
    }
  };

  const isTagSelected = (el) => {
    let v = tags.find(el1 => {
      return el1.id == el.id
    });
    return v ? true : false;
  }

  const onTagSelect = (el) => {
    console.log('onTagSelect', el, tags, isTagSelected(el));
    let t = tags;
    if (isTagSelected(el)) {
      t = t.filter(el1 => {
        return el1.id != el.id;
      });
      setTags(t);
    } else {
      t.push(el);
      console.log('setting tags', t);
      setTags(t);
      let t1 = JSON.parse(JSON.stringify(tagData));
      setTagData(t1.map(el => {
        el.at = new Date().getTime();
        return el;
      }));
    }
  }

  const onCloseDefaultTemplateSelector = (temps) => {
    setDefaultTemplateSelectorVisible(false)
  }

  const onSelectTemplatesClick = () => {
    setDefaultTemplateSelectorVisible(true)
  }

  const onRemoveSelectedClick = (item) => {
    // console.log('onActionClick', item);
    set_default_templates(default_templates.filter(el => {
      if (el.id !== item.id) {
        return el
      }
    }))
  }

  const onSelectDefaultGroup = (templateGroups) => {
    set_default_templates(templateGroups);
    onCloseDefaultTemplateSelector();
  }

  const onToggleImportDefaultTemplates = e => {
    if (is_import_default_template == true) {
      set_default_templates([])
    }
    set_is_import_default_template(!is_import_default_template)
  }

  return (
    <>
      <ModalStateContainer>
        {({ isConfirmationOpen, toggleConfirm }) => (
          <React.Fragment>
            <Modal
              overrides={{
                Dialog: {
                  style: {
                    width: '70vw',
                    display: 'flex',
                    flexDirection: 'column',
                  },
                },
                Backdrop: {
                  style: ({ $theme }) => ({
                    backdropFilter: $theme.modalBackDropFilter,
                  }),
                },
                Root: {
                  style: {
                    zIndex: 3
                  }
                }
              }}
              isOpen={isOpen}
              // closeable={!isLoading}
              onClose={() => {
                if (isLoading) {
                  return dispatch(
                    showAlert({
                      msg: 'This window can not be closed at this time.  Template automation in progress',
                      error: false,
                    })
                  );
                }
                // console.log('MODAL CLOSED');
                onClose();
                setIsOpen(false);
              }}
            >
              <ModalHeader>{id ? 'Update' : 'Add'} Channel</ModalHeader>
              <ModalBody style={{ flex: '1 1 0' }}>
                <Tabs
                  activeKey={activeKey}
                  onChange={({ activeKey }) => {
                    setActiveKey(activeKey);
                  }}
                  activateOnFocus
                >
                  <Tab title="Basic">
                    <Grid
                      gridGaps={[12, 12, 12]}
                      overrides={{
                        Grid: {
                          style: ({ $theme }) => outerGridOverride,
                        },
                      }}
                    >
                      <Cell span={6} overrides={cellPaddingOverrides}>
                        <Cell
                          span={[1, 12]}
                          overrides={{
                            Cell: {
                              style: () => ({
                                paddingLeft: '0px !important',
                              }),
                            },
                          }}
                        >
                          <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
                          <Input
                            value={name}
                            onChange={e => {
                              e.target.value.length <= titlesLength.channel && setName(e.target.value)
                            }}
                            placeholder="Your title"
                            positive={name && name.length > 0 && name.length < titlesLength.channel}
                            clearOnEscape
                          />
                          <ParagraphSmall style={{ marginBottom: '0px' }}>{name.length}/{titlesLength.channel}</ParagraphSmall>
                          {name.length > titlesLength.channel && <p style={{ color: 'red', marginTop: '1rem' }}>Max {titlesLength.channel} characters allowed</p>}
                        </Cell>

                      </Cell>
                      <Cell span={6} overrides={cellPaddingOverrides}>
                        <Cell
                          span={[1, 12]}
                          overrides={{
                            Cell: {
                              style: () => ({
                                paddingLeft: '0px !important',
                              }),
                            },
                          }}
                        >
                          <LabelMedium style={{ marginBottom: '16px' }}>Description</LabelMedium>
                          <TextArea
                            value={description}
                            onChange={setDescription}
                          ></TextArea>
                        </Cell>
                      </Cell>
                      <Cell span={6} overrides={cellPaddingOverrides}>
                        <Cell
                          span={[1, 12]}
                          overrides={{
                            Cell: {
                              style: () => ({
                                paddingLeft: '0px !important',
                              }),
                            },
                          }}
                        >
                          <ImageUploadCXL
                            title={'Channel Image'}
                            titleHelpText={'Select the channel image'}
                            previewUrls={preview_image || getSrc(logoData, 'small')}
                            isMultiple={false}
                            onDrop={(acceptedFiles, rejectedFiles) => {
                              SetLogo(acceptedFiles);
                              previewImage(acceptedFiles[0]);
                            }}
                            onImageDelete={() => {
                              SetLogo(null);
                              setPreview(null);
                              setLogoData(null);
                            }}
                            showFullBleedSettings={false}
                            imageSize={channelImageGuideline.size}
                            imageSizeGuidelineURL={channelImageGuideline.url}
                          />
                        </Cell>
                      </Cell>
                      <Cell span={6} overrides={cellPaddingOverrides}>
                        <Cell
                          span={[1, 12]}
                          overrides={{
                            Cell: {
                              style: () => ({
                                paddingLeft: '0px !important',
                              }),
                            },
                          }}
                        >
                          <ImageUploadCXL
                            title={'Channel Icon'}
                            titleHelpText={'Select the channel icon which will be used in navigation nar of mobile app'}
                            previewUrls={icon_preview || getSrc(iconData, 'full')}
                            isMultiple={false}
                            onDrop={(acceptedFiles, rejectedFiles) => {
                              setIcon(acceptedFiles);
                              setIconPreview(URL.createObjectURL(acceptedFiles[0]));
                            }}
                            onImageDelete={() => {
                              setIconData(null);
                              setIconPreview(null);
                              setIcon(null);
                            }}
                            showFullBleedSettings={false}
                            imageSize={channelIconGuideline.size}
                            imageSizeGuidelineURL={channelIconGuideline.url}
                          />
                        </Cell>
                      </Cell>
                    </Grid>
                  </Tab>
                  {is_channel_advanced_on && <Tab title="Advanced">
                    <Grid
                      gridGaps={[12, 12, 12]}
                      overrides={{
                        Grid: {
                          style: ({ $theme }) => outerGridOverride,
                        },
                      }}
                    >
                      <Cell span={12} overrides={cellPaddingOverrides}>
                        <Cell
                          span={12}
                          overrides={{
                            Cell: {
                              style: () => ({
                                paddingLeft: '0px !important'
                              }),
                            },
                          }}
                        >
                          <LabelMedium style={{ marginBottom: '16px' }}>Accessibility Control</LabelMedium>
                          <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                            {/* <Checkbox
                            checked={publicS}
                            onChange={e => setPublic(!publicS)}
                            labelPlacement={LABEL_PLACEMENT.right}
                          >
                            <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Public</ParagraphSmall>
                          </Checkbox> */}
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={contentControl}
                                onChange={e => setContentControl(!contentControl)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Content Control</ParagraphSmall>
                              </Checkbox>
                            </div>
                            {is_sales_button_on && <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={sales_button_enabled}
                                onChange={e => setSalesButtonEnabled(!sales_button_enabled)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Active Sales Button</ParagraphSmall>
                              </Checkbox>
                            </div>}
                            {is_rewards_nav_on && <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_travel_pass_channel}
                                onChange={e => set_is_travel_pass_channel(!is_travel_pass_channel)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is Travel Pass Channel</ParagraphSmall>
                              </Checkbox>
                            </div>}
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_navigation_channel}
                                onChange={e => set_is_navigation_channel(!is_navigation_channel)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is Navigation Channel</ParagraphSmall>
                              </Checkbox>
                            </div>
                            {/* <div style={{ marginLeft: '16px' }}>
                            <Checkbox
                              checked={has_data_source_connected}
                              onChange={e => set_has_data_source_connected(!has_data_source_connected)}
                              labelPlacement={LABEL_PLACEMENT.right}
                            >
                              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Has Data Source Connection</ParagraphSmall>
                            </Checkbox>
                          </div> */}

                            {is_api_settings_on && id && <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={has_data_source_connected}
                                onChange={e => set_has_data_source_connected(!has_data_source_connected)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Make Channel as external API Channel</ParagraphSmall>
                              </Checkbox>
                            </div>}
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={can_share_channel_data}
                                onChange={e => set_can_share_channel_data(!can_share_channel_data)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Can Share Channel Data</ParagraphSmall>
                              </Checkbox>
                            </div>
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_file_explorer}
                                onChange={e => set_is_file_explorer(!is_file_explorer)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>File Explorer Channel</ParagraphSmall>
                              </Checkbox>
                            </div>
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_firebase_channel}
                                onChange={e => set_is_firebase_channel(!is_firebase_channel)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Firebase Channel</ParagraphSmall>
                              </Checkbox>
                            </div>
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_primary_channel}
                                onChange={e => set_is_primary_channel(!is_primary_channel)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Primary Channel</ParagraphSmall>
                              </Checkbox>
                            </div>
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={hide_from_navigation}
                                onChange={e => set_hide_from_navigation(!hide_from_navigation)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Hide from Navigation</ParagraphSmall>
                              </Checkbox>
                            </div>
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_modal_presentation}
                                onChange={e => set_is_modal_presentation(!is_modal_presentation)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Modal Presentation</ParagraphSmall>
                              </Checkbox>
                            </div>
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={display_modal_on_no_sub}
                                onChange={e => set_display_modal_on_no_sub(!display_modal_on_no_sub)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present Modal on No Subscription</ParagraphSmall>
                              </Checkbox>
                            </div>

                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={display_modal_on_no_acct}
                                onChange={e => set_display_modal_on_no_acct(!display_modal_on_no_acct)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present Modal on No Account</ParagraphSmall>
                              </Checkbox>
                            </div>

                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={display_modal_on_sub_no_acct}
                                onChange={e => set_display_modal_on_sub_no_acct(!display_modal_on_sub_no_acct)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present Modal on Subscription but no Account</ParagraphSmall>
                              </Checkbox>
                            </div>

                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={display_modal_link_acct}
                                onChange={e => set_display_modal_link_acct(!display_modal_link_acct)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present Modal to link account with Purchase</ParagraphSmall>
                              </Checkbox>
                            </div>

                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={hide_from_navigation_list_on_modal_check}
                                onChange={e => set_hide_from_navigation_list_on_modal_check(!hide_from_navigation_list_on_modal_check)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Hide channel from list if modal condition met</ParagraphSmall>
                              </Checkbox>
                            </div>

                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={display_modal_link_user_meta_data}
                                onChange={e => set_display_modal_link_user_meta_data(!display_modal_link_user_meta_data)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present Modal to set user metadata</ParagraphSmall>
                              </Checkbox>
                            </div>
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_analytics_report_template}
                                onChange={e => set_is_analytics_report_template(!is_analytics_report_template)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is Analytics Report Template</ParagraphSmall>
                              </Checkbox>
                            </div>
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_multimedia_channel}
                                onChange={e => set_is_multimedia_channel(!is_multimedia_channel)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present as multimedia channel</ParagraphSmall>
                              </Checkbox>
                            </div>
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_event_channel}
                                onChange={e => set_is_event_channel(!is_event_channel)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present as event channel</ParagraphSmall>
                              </Checkbox>
                            </div>

                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_assets_channel}
                                onChange={e => set_is_assets_channel(!is_assets_channel)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present as assets channel</ParagraphSmall>
                              </Checkbox>
                            </div>

                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_news_channel}
                                onChange={e => set_is_news_channel(!is_news_channel)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present as news channel</ParagraphSmall>
                              </Checkbox>
                            </div>

                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_announcement_channel}
                                onChange={e => set_is_announcement_channel(!is_announcement_channel)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present as announcement channel</ParagraphSmall>
                              </Checkbox>
                            </div>

                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_map_channel}
                                onChange={e => set_is_map_channel(!is_map_channel)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present as map channel</ParagraphSmall>
                              </Checkbox>
                            </div>
                            <div style={{ marginLeft: '0px', width: '30%', marginBottom: '8px' }}>
                              <Checkbox
                                checked={is_expense_channel}
                                onChange={e => set_is_expense_channel(!is_expense_channel)}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Present as expense channel</ParagraphSmall>
                              </Checkbox>
                            </div>
                          </div>
                        </Cell>
                        <Cell span={[1, 6]}
                          overrides={{
                            Cell: {
                              style: () => ({
                                paddingLeft: '0px !important',
                              }),
                            },
                          }}>
                          <LabelMedium style={{ marginBottom: '16px' }}>Modal Presentation Style</LabelMedium>
                          <Select
                            options={[
                              {
                                name: 'Modal', id: 'modal'
                              },
                              {
                                name: 'Action Sheet', id: 'action_sheet'
                              }
                            ]}
                            value={[
                              {
                                name: 'Modal', id: 'modal'
                              },
                              {
                                name: 'Action Sheet', id: 'action_sheet'
                              }
                            ].filter(el => el.id == modal_presentation_style)}
                            labelKey="name"
                            valueKey="id"
                            placeholder=''
                            onChange={(res) => {
                              if (res.option) {
                                set_modal_presentation_style(res.option.id);
                              }
                            }}
                            overrides={{
                              Popover: {
                                props: {
                                  overrides: {
                                    Body: { style: { zIndex: 3 } },
                                  },
                                },
                              },
                            }}
                          />
                        </Cell>
                        <Cell
                          span={[1, 6]}
                          overrides={{
                            Cell: {
                              style: () => ({
                                paddingLeft: '0px !important',
                              }),
                            },
                          }}
                        >
                          <LabelMedium style={{ marginBottom: '16px' }}>Pincode</LabelMedium>
                          <Input
                            value={pinCode}
                            onChange={e => setPinCode(e.target.value)}
                            placeholder="Your Pincode"
                            clearOnEscape
                          />
                        </Cell>
                        <Cell
                          span={[1, 6]}
                          overrides={{
                            Cell: {
                              style: () => ({
                                paddingLeft: '0px !important',
                              }),
                            },
                          }}
                        >
                          <LabelMedium style={{ marginBottom: '16px' }}>Media Url</LabelMedium>
                          <Input
                            value={mediaUrl}
                            onChange={e => setMediaUrl(e.target.value)}
                            placeholder="Your media url"
                            positive={mediaUrl && !(mediaUrl && mediaUrl !== "" && !isValidHttpUrl(mediaUrl))}
                            clearOnEscape
                          />
                          {(mediaUrl && mediaUrl !== "" && !isValidHttpUrl(mediaUrl)) && <p style={{ color: 'red', marginTop: '1rem' }}>Invalid media URL</p>}
                        </Cell>
                        <Cell
                          span={[1, 6]}
                          overrides={{
                            Cell: {
                              style: () => ({
                                paddingLeft: '0px !important',
                              }),
                            },
                          }}
                        >
                          <LabelMedium style={{ marginBottom: '16px' }}>Abbreviation</LabelMedium>
                          <Input
                            value={abbr}
                            onChange={e => setAbbr(e.target.value)}
                            placeholder="Your abbreviation"
                            clearOnEscape
                          />
                        </Cell>
                        {is_file_explorer && <Cell
                          span={[1, 6]}
                          overrides={{
                            Cell: {
                              style: () => ({
                                paddingLeft: '0px !important',
                              }),
                            },
                          }}
                        >
                          <LabelMedium style={{ marginBottom: '16px' }}>File Explorer Root Path</LabelMedium>
                          {/* <Input
                            value={file_explorer_root_path}
                            onChange={e => set_file_explorer_root_path(e.target.value)}
                            placeholder="File Explorer Root Path"
                            clearOnEscape
                          /> */}
                          {/* <Select
                            isLoading={isLoadingCloudinaryData}
                            options={cloudinary_folders}
                            value={cloudinary_folders.filter(el => el.id == file_explorer_root_path)}
                            labelKey="name"
                            valueKey="id"
                            placeholder=''
                            onChange={(res) => {
                              if (res.option) {
                                set_file_explorer_root_path(res.option.id);
                              }
                            }}
                            overrides={{
                              Popover: {
                                props: {
                                  overrides: {
                                    Body: { style: { zIndex: 3 } },
                                  },
                                },
                              },
                            }}
                          /> */}

                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <CustomButton text={'Select Folder'} onClick={() => {
                              setIsCloudinaryPickerOpen(true);
                            }} />
                            <ParagraphSmall style={{
                              marginBottom: '0px', marginLeft: '12px',
                            }}>{file_explorer_root_path || ''}</ParagraphSmall>
                          </div>
                        </Cell>}
                        {is_file_explorer && <Cell
                          span={[1, 6]}
                          overrides={{
                            Cell: {
                              style: () => ({
                                paddingLeft: '0px !important',
                              }),
                            },
                          }}
                        >
                          <LabelMedium style={{ marginBottom: '16px' }}>File Explorer Default View</LabelMedium>
                          <Select
                            options={FILE_EXPLORER_VIEW_OPTIONS}
                            value={FILE_EXPLORER_VIEW_OPTIONS.filter(el => el.id == file_explorer_default_view)}
                            labelKey="name"
                            valueKey="id"
                            placeholder=''
                            onChange={(res) => {
                              if (res.option) {
                                set_file_explorer_default_view(res.option.id);
                              }
                            }}
                            overrides={{
                              Popover: {
                                props: {
                                  overrides: {
                                    Body: { style: { zIndex: 3 } },
                                  },
                                },
                              },
                            }}
                          />
                        </Cell>}
                        {id && tagData.filter(el => {
                          return el.type == 'category' || el.type == 'channel'
                        }).length > 0 && (
                            <Cell
                              span={[1, 6]}
                              overrides={{
                                Cell: {
                                  style: () => ({
                                    paddingLeft: '0px !important',
                                  }),
                                },
                              }}
                            >
                              <LabelMedium style={{ marginBottom: '16px' }}>Tags</LabelMedium>
                              {/* <Select
                            options={tagData}
                            value={tags}
                            labelKey="name"
                            valueKey="id"
                            multi
                            placeholder=''
                            onChange={({ value }) => {
                              setTags(value);
                            }}
                            overrides={{
                              Root: {
                                style: {
                                  zIndex: 3
                                }
                              },
                              DropdownContainer: {
                                style: {
                                  zIndex: 3
                                }
                              },
                              Dropdown: {
                                style: {
                                  zIndex: 3
                                }
                              },
                              Popover: {
                                style: {
                                  zIndex: 10
                                }
                              }
                            }}
                          /> */}
                              <div style={{
                                display: 'flex', flexDirection: 'row'
                              }}>
                                {tagData.filter(el => {
                                  return el.type == 'category' || el.type == 'channel'
                                }).map(el => {
                                  return <div onClick={() => {
                                    onTagSelect(el);
                                  }}>
                                    <Tag
                                      closeable={false}
                                      variant={isTagSelected(el) ? 'solid' : 'light'}
                                    >{el.name}</Tag>
                                  </div>
                                })}
                              </div>
                            </Cell>
                          )}
                      </Cell>
                      <Cell span={6} overrides={cellPaddingOverrides}>
                        {sales_button_enabled &&
                          <>
                            <Cell
                              span={[1, 12]}
                              overrides={{
                                Cell: {
                                  style: () => ({
                                    paddingLeft: '0px !important',
                                  }),
                                },
                              }}
                            >
                              <LabelMedium style={{ marginBottom: '16px' }}>Sales form title</LabelMedium>
                              <Input
                                value={sales_incentive_title}
                                onChange={e => set_sales_incentive_title(e.target.value)}
                                placeholder="Sales form title"
                                clearOnEscape
                              />
                            </Cell>
                            <Cell
                              span={[1, 12]}
                              overrides={{
                                Cell: {
                                  style: () => ({
                                    paddingLeft: '0px !important',
                                  }),
                                },
                              }}
                            >
                              <LabelMedium style={{ marginBottom: '16px' }}>Sales form description</LabelMedium>
                              <TextArea
                                value={sales_incentive_description}
                                onChange={set_sales_incentive_description}
                              ></TextArea>
                            </Cell>
                            <Cell
                              span={[1, 12]}
                              overrides={{
                                Cell: {
                                  style: () => ({
                                    paddingLeft: '0px !important',
                                  }),
                                },
                              }}
                            >
                              <LabelMedium style={{ marginBottom: '16px' }}>Sales form button title</LabelMedium>
                              <Input
                                value={sales_incentive_button_title}
                                onChange={e => set_sales_incentive_button_title(e.target.value)}
                                placeholder="Sales form button title"
                                clearOnEscape
                              />
                            </Cell>
                            <Cell
                              span={[1, 12]}
                              overrides={{
                                Cell: {
                                  style: () => ({
                                    paddingLeft: '0px !important',
                                  }),
                                },
                              }}
                            >
                              <ImageUploadCXL
                                title={'Sales form image'}
                                titleHelpText={'Select the sales form image'}
                                previewUrls={sales_incentive_image_preview || getSrc(sales_incentive_image, 'small')}
                                isMultiple={false}
                                onDrop={(acceptedFiles, rejectedFiles) => {
                                  set_sales_incentive_image_upload(acceptedFiles);
                                  set_sales_incentive_image_preview(URL.createObjectURL(acceptedFiles[0]));
                                }}
                                onImageDelete={() => {
                                  set_sales_incentive_image_upload(null);
                                  set_sales_incentive_image_preview(null);
                                  set_sales_incentive_image(null);
                                }}
                                showFullBleedSettings={false}
                                imageSize={channelImageGuideline.size}
                                imageSizeGuidelineURL={channelImageGuideline.url}
                              />
                            </Cell>
                          </>
                        }
                      </Cell>
                    </Grid>
                  </Tab>}
                  {
                    !id && (

                      <Tab title="Automation">
                        <Grid
                          gridGaps={[12, 12, 12]}
                          overrides={{
                            Grid: {
                              style: ({ $theme }) => outerGridOverride,
                            },
                          }}
                        >
                          <div
                            // overrides={{
                            //   Cell: {
                            //     style: () => ({
                            //       paddingLeft: '0px !important',
                            //       backgroundColor: 'red',
                            //       width: '100%'
                            //     }),
                            //   },
                            // }}
                            className={css({
                              width: '100%',
                            })}
                          >
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                              {/* <Checkbox
                                checked={is_import_default_template}
                                onChange={onToggleImportDefaultTemplates}
                                labelPlacement={LABEL_PLACEMENT.right}
                              >
                                <ParagraphSmall style={{ marginBottom: '0px' }}>Import default templates</ParagraphSmall>
                              </Checkbox>
                              {
                                is_import_default_template && ( */}
                              <Button
                                // className={css({
                                //   marginLeft: '20px'
                                // })}
                                onClick={onSelectTemplatesClick}
                              >
                                Select Template Groups
                              </Button>
                              {/* )
                              } */}

                            </div>
                            {
                              (default_templates || []).length <= 0 && (
                                <div
                                  className={css({
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    paddingBottom: '10px',
                                    paddingTop: '10px'
                                  })}
                                >
                                  Please Select Template Groups !
                                </div>
                              )
                            }
                            {
                              (default_templates || []).length > 0 && (

                                <div style={{ display: 'flex', width: '400px', flexDirection: 'column' }}>
                                  <LabelSmall>Selected Template Groups</LabelSmall>
                                  <div
                                    className={css({
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      marginTop: '10px',
                                      marginBottom: '10px'
                                    })}
                                  >
                                    {(default_templates || []).map((el, idx) => {
                                      const onActionClick = () => {
                                        onRemoveSelectedClick(el)
                                      }
                                      return (
                                        <Tag
                                          onActionClick={onActionClick}
                                          variant={'solid'}
                                        >
                                          {el?.title}
                                        </Tag>
                                      )
                                    })}

                                  </div>
                                </div>
                              )
                            }
                          </div>
                        </Grid>
                      </Tab>
                    )
                  }
                  {HAS_SUBSCRIPTION && is_subscription_on && <Tab title="Subscription">
                    <Grid
                      gridGaps={[12, 12, 12]}
                      overrides={{
                        Grid: {
                          style: ({ $theme }) => outerGridOverride,
                        },
                      }}
                    >
                      {/* <Cell span={12} overrides={cellPaddingOverrides}> */}
                      <Cell
                        span={[1, 6]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important'
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Requires Subscription</LabelMedium>
                        <div style={{ display: 'flex', width: '400px' }}>
                          <Checkbox
                            checked={is_requires_subscription}
                            onChange={e => set_is_requires_subscription(!is_requires_subscription)}
                            labelPlacement={LABEL_PLACEMENT.right}
                          >
                            <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Yes / No</ParagraphSmall>
                          </Checkbox>
                        </div>
                      </Cell>
                      <Cell
                        span={[1, 6]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important',
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Subscriptions</LabelMedium>
                        <Select
                          options={ORGANIZATION.subscriptions}
                          value={ORGANIZATION.subscriptions.filter(el => subscription_ids.includes(el.id))}
                          labelKey="name"
                          valueKey="id"
                          placeholder=''
                          multi
                          onChange={(res) => {
                            set_subscription_id(res.value.map(el => el.id).toString());
                          }}
                          overrides={{
                            Popover: {
                              props: {
                                overrides: {
                                  Body: { style: { zIndex: 3 } },
                                },
                              },
                            },
                          }}
                        />
                      </Cell>
                      <Cell
                        span={[1, 12]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important'
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '8px', marginTop: '24px' }}>Subscription Setup</LabelMedium>
                        <ParagraphSmall style={{ marginBottom: '16px' }}>To setup this different for each subscription please set that on subscription level from integration settings.</ParagraphSmall>
                      </Cell>
                      <Cell
                        span={[1, 6]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important'
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Intro Headline</LabelMedium>
                        <div style={{ display: 'flex', width: '400px' }}>
                          <Input
                            value={subscription_into_headline}
                            onChange={e => {
                              set_subscription_into_headline(e.target.value)
                            }}
                            placeholder="Headline intro of subscription"
                            clearOnEscape
                          />
                        </div>
                      </Cell>
                      <Cell
                        span={[1, 6]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important'
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Button Text</LabelMedium>
                        <div style={{ display: 'flex', width: '400px' }}>
                          <Input
                            value={subscription_button_text}
                            onChange={e => {
                              set_subscription_button_text(e.target.value)
                            }}
                            placeholder="Headline intro of subscription"
                            clearOnEscape
                          />
                        </div>
                      </Cell>
                      <Cell
                        span={[1, 6]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important',
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Description</LabelMedium>
                        <TextArea
                          value={subscription_description}
                          onChange={set_subscription_description}
                        ></TextArea>
                      </Cell>
                      <Cell
                        span={[1, 6]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important',
                            }),
                          },
                        }}
                      >
                        <ImageUploadCXL
                          title={'Intro Image'}
                          titleHelpText={'Select the intro image'}
                          previewUrls={(subscription_image && URL.createObjectURL(subscription_image[0])) || getSrc(rest.subscription_image_data, 'small')}
                          isMultiple={false}
                          onDrop={(acceptedFiles, rejectedFiles) => {
                            set_subscription_image(acceptedFiles);
                          }}
                          onImageDelete={() => {
                            set_subscription_image(null);
                          }}
                          showFullBleedSettings={false}
                          imageSize={channelImageGuideline.size}
                          imageSizeGuidelineURL={channelImageGuideline.url}
                        />
                      </Cell>
                      {/* </Cell> */}
                    </Grid>
                  </Tab>}
                  {is_api_settings_on && has_data_source_connected && id && <Tab title="ApI Setup">
                    <Grid
                      gridGaps={[12, 12, 12]}
                      overrides={{
                        Grid: {
                          style: ({ $theme }) => outerGridOverride,
                        },
                      }}
                    >
                      {/* <Cell span={12} overrides={cellPaddingOverrides}> */}
                      <Cell
                        span={[1, 12]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important'
                            }),
                          },
                        }}
                      >
                        <ParagraphSmall style={{ marginBottom: '0px' }}>
                          In api setup select your pre defined api paths. If response of api is a single object then it will generate only one channel otherwise if it is an array it will generate multiple channels.
                          As this channel is marked as api channel so in apps you will not be able to see this channel this is going to be used as template for data coming from api.
                        </ParagraphSmall>
                      </Cell>
                      <Cell
                        span={[1, 12]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important'
                            }),
                          },
                        }}
                      >
                        <ChannelMappings
                          organization_id={organization_id}
                          selectedChannel={
                            { id: id }
                          }
                        />
                      </Cell>
                    </Grid>
                  </Tab>
                  }

                  {id && <Tab title="Migration">
                    <LabelSmall style={{ marginBottom: 12 }}>Migrate Channel as new Organization</LabelSmall>
                    <Button
                      isLoading={migrateChannelLoading}
                      onClick={() => {
                        _migrateChannel();
                      }}
                    >
                      Migrate Channel
                    </Button>
                  </Tab>}
                </Tabs>
              </ModalBody>
              <ModalFooter>
                {request_id && ProgressComponent}
                {!!id && (
                  <ModalButton
                    onClick={() => {
                      toggleConfirm(true);
                    }}
                    disabled={isLoading}
                    isLoading={isRemoveLoading}
                  >
                    {archived ? 'Unarchive' : 'Archive'} Channel
                  </ModalButton>
                )}
                <ModalButton
                  onClick={onSave}
                  disabled={!name || name === "" || name.length > titlesLength.channel || isRemoveLoading}
                  isLoading={isLoading}
                >
                  {id ? 'Update' : 'Add'} Channel
                </ModalButton>

              </ModalFooter>
            </Modal>
            <Modal
              onClose={() => toggleConfirm(false)}
              isOpen={isConfirmationOpen}
              overrides={{
                Root: {
                  style: {
                    zIndex: 4,
                  }
                }
              }}
            >
              <ModalHeader>Confirm</ModalHeader>
              <ModalBody>Are you sure you want to {archived ? 'unarchive' : 'archive'} this channel?</ModalBody>
              <ModalFooter>
                <ModalButton
                  kind="tertiary"
                  onClick={() => toggleConfirm(false)}
                >
                  No
                </ModalButton>
                <ModalButton
                  onClick={() => {
                    onRemove();
                  }}
                  isLoading={isRemoveLoading}
                  disabled={isLoading}
                >
                  Yes
                </ModalButton>
              </ModalFooter>
            </Modal>
          </React.Fragment>
        )}
      </ModalStateContainer>
      <DefaultTemplateSelectorModal
        isVisible={defaultTemplateSelectorVisible}
        onClose={onCloseDefaultTemplateSelector}
        organization_id={organization_id}
        selectedDefaultTemplateGroups={default_templates}
        onSelect={onSelectDefaultGroup}
      />
      {isCloudinaryPickerOpen && <CloudinaryFolderPicker
        isOpen={isCloudinaryPickerOpen}
        folder={file_explorer_root_path}
        onClose={(v) => {
          set_file_explorer_root_path(v);
          setIsCloudinaryPickerOpen(false);
        }}
        organization_id={organization_id}
      />}
    </>
  );
};
