import gql from 'graphql-tag';

export const organization_breakthroughs_by_org_id = gql`
  query organization_breakthroughs_by_org_id ($organization_id: ID!){
    organization_breakthroughs_by_org_id(
      organization_id: $organization_id
    ) {
      title
      api_url
      app_url
      api_secret
      api_key
      id
      is_button_active
    }
  }
`;

export const organization_engage_by_org_id = gql`
   query organization_engage_by_org_id ($organization_id: ID!){
        organization_engage_by_org_id(
          organization_id: $organization_id
          ) {
            title
            api_url
            app_url
            api_secret
            api_key
            id
            is_button_active
          }
      }
`;


export const organization_data_collections_by_org_id  = gql `
   query organization_data_collections_by_org_id ($organization_id: ID!){
        organization_data_collections_by_org_id(
          organization_id: $organization_id
          ) {
            title
            api_url
            app_url
            api_secret
            api_key
            id
            is_button_active
            organization_mongo_id
            mongodb {
              uri
              db_name
            }
          }
      }
`

export const organization_marketings_by_org_id = gql`
  query organization_marketings_by_org_id ($organization_id: ID!){
    organization_marketings_by_org_id(
      organization_id: $organization_id
    ) {
      title
      api_url
      app_url
      api_secret
      api_key
      id
      is_button_active
    }
}
`

export const organization_file_by_org_id = gql`
  query organization_file_by_org_id ($organization_id: ID!){
    organization_file_by_org_id(
      organization_id: $organization_id
      ) {
      title
      api_url
      app_url
      api_secret
      api_key
      id
      is_button_active
      project_id
    }
}
`