import React, { useEffect, useState } from 'react';
import { TimePicker } from 'baseui/timepicker';
import BaseClass from '../../builder/cardBackEditorComponents/base';
import FormBuilderComponent from '../../builder/formBuilder';
import Button from '../../styledButton';
import { useDispatch, useSelector } from 'react-redux';
import { importFormFieldAction } from '../../../redux/actions/appBasicControls';

const FormInputList = ({
  value,
  onChange,
  root,
  ...props
}) => {
  const [formBuilderOpen, setFormBuilderOpen] = useState(false);

  const schemaDataArr = useSelector(state => state.appBasicControls.schemaDataArr);
  const dispatch = useDispatch();

  const setFormData = async (data) => { 
    const schemaDataStr = JSON.stringify(data);
    const schemaArr = await JSON.parse(schemaDataStr);
    dispatch(importFormFieldAction(undefined));
    onChange(schemaArr)
    onOpenModal()
  }

  useEffect(()=>{
    if (schemaDataArr !== undefined) {
      setFormData(schemaDataArr)
    }
  },[schemaDataArr])

  const onOpenModal = () => {
    setFormBuilderOpen(true);
  }
  const onCloseModal = () => {
    setFormBuilderOpen(false);
  }

  const onSave = (payload) => {
    return new Promise((resolve, reject) => {
      console.log(payload);
      onChange(payload.schemaArr)
      onCloseModal();
      resolve();
    })
  }

  // const updateMutation = getUpdateMutationsForHomeComponent(this.props.root);
  return (
    <>
      {/* <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {this.props.title || 'Setup Form Fields'}
        </h5> */}
      <Button
        isSelected
        marginTop={'0px'}
        onClick={onOpenModal}>
        Setup Form
      </Button>
      {formBuilderOpen && <FormBuilderComponent
        {...props}
        selectedComponentType={root}
        selectedModule={
          {
            content_module: {
              schema: JSON.stringify(value)
            }
          }
        }
        onClose={onCloseModal}
        onSave={onSave}
        hideBasicInputs={true}
      // updateMutation={updateMutation}
      />}
    </>
  );
}

export default FormInputList;
