import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Drawer } from "baseui/drawer";
import { HeadingSmall, ParagraphMedium } from "baseui/typography";
import { client } from "../../apollo/client";
import { template_tags_integration_group_by_tag, template_tags_org } from "../templates/queries";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Spinner } from "baseui/spinner";
import { ModalButton } from "baseui/modal";
import { default_template_groups } from "../settings/queries";

const drawerOverrides = {
  Root: {
    style: {
      zIndex: 44
    }
  },
  DrawerBody: {
    style: ({ $theme }) => ({
      marginLeft: '20px',
    })
  },
};

const DefaultTemplateSelectorModal = ({
  isVisible,
  onClose = () => { },
  organization_id,
  selectedDefaultTemplateGroups = [],
  onSelect = () => {},
  ...props
}) => {

  const [defaultTemplates, setDefaultTemplates] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [selectedDefaultTemplates, setSelectedDefaultTemplates] = useState([]);

  const [css] = useStyletron();

  const [fetchDefaultTemplateGroups, { data: default_template_groups_data, loading, refetch: refetchDefaultTemplateGroups }] = useLazyQuery(default_template_groups,
    {
      variables: {
        organization_id
      },
      fetchPolicy: 'network-only'
    }
  );


  useEffect(() => {
    if (isVisible) {
      fetchDefaultTemplateGroups()
    }
  }, [isVisible]);

  useEffect(() => {
    setSelectedDefaultTemplates(selectedDefaultTemplateGroups)
  },[selectedDefaultTemplateGroups])

  useEffect(() => {
    // console.log('defaultTemplateGroupsList', default_template_groups_data)
    setDefaultTemplates(default_template_groups_data?.default_template_groups || [])
  }, [default_template_groups_data])


  const onCloseModal = () => {
    onClose();
  }


  const onSelectAllPress = () => {
    if (selectedDefaultTemplates.length === defaultTemplates.length) {
      setSelectedDefaultTemplates([]);
      return;
    }
    setSelectedDefaultTemplates(defaultTemplates);
  }

  const onAddClick = () => {
    onSelect(selectedDefaultTemplates)
  }

  ///////////////////////////////////////////////////////// Render Functions ///////////////////////////////////////////////

  const renderDefaultTemaplateItem = (templateGroup, index) => {
    const isSelected = selectedDefaultTemplates.find(el => el.id === templateGroup.id);
    const onSelectDefaultTemplate = () => {
      if (!isSelected) {
        setSelectedDefaultTemplates([
          ...selectedDefaultTemplates,
          templateGroup
        ])
      } else {
        setSelectedDefaultTemplates(selectedDefaultTemplates.filter(el => el.id !== templateGroup.id));
      }
    }
    return (
      <Checkbox
        checked={isSelected}
        onChange={onSelectDefaultTemplate}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        <ParagraphMedium marginBottom='10px' paddingRight='14px'>{templateGroup?.title}</ParagraphMedium>
      </Checkbox>
    )
  }

  const DefaultTemplatesList = useMemo(() => {
    let isIndeterminate = selectedDefaultTemplates.length > 0 && selectedDefaultTemplates.length < defaultTemplates.length;
    let allChecked = defaultTemplates.length > 0 ? selectedDefaultTemplates.length === defaultTemplates.length : false;
    return (
      <div
        className={css({
          flex: 1,
        })}
      >
        {/* {
          defaultTemplates.length > 1 && ( */}
        <div
          className={css({
            width: '100%',
          })}
        >
          <Checkbox
            isIndeterminate={isIndeterminate}
            checked={allChecked}
            onChange={onSelectAllPress}
          >
            Select All Template Groups
          </Checkbox>
        </div>
        {/* )
        } */}

        <div
          className={css({
            padding: '20px',
          })}
        >
          {defaultTemplates.map(renderDefaultTemaplateItem)}
        </div>

      </div>
    )
  }, [defaultTemplates, selectedDefaultTemplates])

  return (
    <Drawer
      isOpen={isVisible}
      autoFocus
      onClose={onCloseModal}
      overrides={drawerOverrides}
    >
      <HeadingSmall
        style={{
          textAlign: 'center'
        }}
      >
        Select Template Groups
      </HeadingSmall>
      {
        loading && (
          <div
            className={css({
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignContent: 'center',
              paddingBottom: '10px'
            })}
          >
            <Spinner
              size={40}
              color='black'
            />
          </div>
        )
      }
      {DefaultTemplatesList}
      <div
        className={css({
          display: 'flex',
          position: 'absolute',
          minHeight: '50px',
          paddingBottom: '10px',
          paddingRight: '20px',
          paddingLeft: '20px',
          bottom: 0,
          right: 0,
          width: '100%',
          flexDirection: 'column'
        })}

      >
        <div
          className={css({
            justifyContent: 'flex-end',
            display: 'flex'
          })}
        >
          <ModalButton
            onClick={onCloseModal}
          >
            Cancel
          </ModalButton>

          <ModalButton
            // isLoading={}
            onClick={onAddClick}
          >
            Done
          </ModalButton>
        </div>
      </div>
    </Drawer>
  )
}

export default DefaultTemplateSelectorModal;