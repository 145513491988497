import * as React from 'react';
import { Input } from 'baseui/input';
import PropTypes from 'prop-types';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { Drawer } from "baseui/drawer";
import { Select } from 'baseui/select';

import styled from '@emotion/styled';

import CustomInput from '../common/input';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { importSharePointFormModulesByTags, importSharePointFormModulesByTagsSubscription, import_progress_trackers_by_id, template_tags_org } from '../templates/queries';
import { toaster } from 'baseui/toast';

import { client } from '../../apollo/client';
import { getTags, organization as organizationQuery } from '../../quries';
import { useDispatch, useSelector } from 'react-redux';
import { removeSharepointFormImportRequest, setOrg, setSharepointFormImportRequest, showAlert } from '../../redux/actions/appBasicControls';
import { ButtonGroup } from 'baseui/button-group';
import { Button, SHAPE } from 'baseui/button';
import { Check, Upload } from 'baseui/icon';
import { useStyletron } from 'baseui';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { HeadingMedium, HeadingSmall, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { Spinner } from 'baseui/spinner';
import { Cell, Grid } from 'baseui/layout-grid';
import { ProgressBar } from 'baseui/progress-bar';
import useProgressTracker from '../progress-tracker/useProgressTracker';

const HeaderTitle = styled.h5`
  color: black;
  margin-top: 16px;
  margin-bottom: 11px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`

// const ImportProgress = (onProgressDone, onError) => {

//   const [request_id, setRequestId] = React.useState('')

//   const { data: SubscriptionData, error: SubscriptionError } = useSubscription(
//     importSharePointFormModulesByTagsSubscription,
//     { variables: { request_id } }
//   );

//   const [progress, setProgress] = React.useState();

//   const dispatch = useDispatch();

//   React.useEffect(() => {
//     console.log('SubscriptionData', { SubscriptionData, request_id });
//     const {
//       importSharePointFormModulesByTags = {}
//     } = SubscriptionData || {}
//     const {
//       current,
//       error,
//       is_done,
//       is_error,
//       result,
//       total
//     } = importSharePointFormModulesByTags;

//     if (is_done) {
//       dispatch(
//         removeSharepointFormImportRequest({
//           request_id
//         })
//       )
//       onProgressDone()
//     } else if (is_error) {
//       dispatch(
//         showAlert({
//           msg: JSON.stringify(error),
//           error: true,
//         })
//       );
//       dispatch(
//         removeSharepointFormImportRequest({
//           request_id
//         })
//       )
//       onError()
//     }
//     if (current) {
//       setProgress(parseInt((current / total) * 100));
//     }

//   }, [SubscriptionData])

//   React.useEffect(() => {
//     setProgress(0)
//   }, [request_id])

//   function setReportRequestId(id) {
//     setRequestId(id)
//   }

//   return ({
//     ProgressComponent: (
//       <div>
//         <ProgressBar
//           value={progress}
//           getProgressLabel={(value) => <ParagraphMedium paddingBottom={0} marginBottom={'10px'}>{`${value}% import progress is complete`}</ParagraphMedium>}
//           showLabel
//           overrides={{
//             BarProgress: {
//               style: ({ $theme, $value }) => {
//                 return {
//                   ...$theme.typography.font350,
//                   backgroundColor: $theme.colors.positive,
//                   color: $theme.colors.mono200,
//                   position: 'relative',
//                   marginBottom: '0',
//                   ':after': {
//                     position: 'absolute',
//                     content: $value > 5 ? `"${$value}%"` : '',
//                     right: '10px',
//                   },
//                 };
//               },
//             },
//             Bar: {
//               style: ({ $theme }) => ({
//                 height: $theme.sizing.scale800,
//               }),
//             },
//           }}
//         />
//       </div>
//     ),
//     progress,
//     setReportRequestId
//   })
// }



const ImportTagFormsModal = (props) => {
  const {
    isVisible = false,
    onClose = () => { },
    onRefresh,
    channel_id,
    organization_id,
    home_components = [],
    isMultipleChannelImport,
    onImportTags
  } = props || {};

  const dispatch = useDispatch()
  const [tags, setTags] = React.useState([]);
  const [name, setName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [css, theme] = useStyletron();
  const [request_id, setRequestId] = React.useState();
  const [isImportProgress, setIsImportProgress] = React.useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const { ProgressComponent, setReportRequestId } = useProgressTracker(onProgressDone, onErrorProgress);

  const formImportRequests = useSelector(state => state.appBasicControls.formImportRequests);

  const [importSharePointFormModulesByTag, {
    data: importedSharpointModule,
    loading: loadingSharepointModule
  }] = useMutation(importSharePointFormModulesByTags)


  React.useEffect(() => {
    if (isVisible) {
      loadTags();
    }
  }, [isVisible]);


  React.useEffect(() => {
    console.log('formImportRequests', formImportRequests)
    const requestExist = formImportRequests && formImportRequests.find(el => el.channel_id === channel_id);
    if (requestExist) {
      setRequestId(requestExist.request_id)
      setReportRequestId(requestExist.request_id)
      setIsImportProgress(true);
    } else {
      setRequestId()
      setReportRequestId()
      setIsImportProgress(false);
    }
  }, [formImportRequests]);




  const loadTags = () => {
    const { organization_id } = props
    setIsLoading(true);
    client.query({
      query: template_tags_org(organization_id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      let formatedTags = [...data.template_tags_org].sort((a, b) => a.position - b.position)
      setTags(formatedTags);

    }).finally(() => setIsLoading(false))
  };

  const showToaster = (msg, method = 'info') => {
    let toastKey = toaster[method](
      <>
        {msg}
      </>,
      {
        overrides: {
          InnerContainer: {
            style: { width: "100%" }
          }
        }
      }
    );
    setTimeout(() => { toaster.clear(toastKey) }, 3000);
  };

  /////////////////////////////////////////////////// Event Functions //////////////////////////////////////////////

  const onCloseModal = () => {
    onClose();
    setTags([]);
    setName('');
    setSelectedTags([]);
  }

  const importByTags = async (published) => {
    try {
      if (selectedTags && selectedTags?.length > 0) {
        const { data: dataRes } = await importSharePointFormModulesByTag({
          variables: {
            name,
            tag_ids: selectedTags.map(el => el.id),
            organization_id,
            position: home_components.length,
            channel_id,
            published
          }
        })
        console.log('dataRes', { dataRes })
        const {
          status,
          request_id,
          message
        } = dataRes.importSharePointFormModulesByTags || {}

        if (status) {

          setRequestId(request_id);
          setReportRequestId(request_id);
          setIsImportProgress(true);
          dispatch(
            setSharepointFormImportRequest({
              request_id,
              channel_id
            })
          )
          showToaster('Forms Import In progress', 'positive')
        } else {
          setRequestId("");
          setReportRequestId("");
          showToaster(message, 'negative')
          setIsImportProgress(false);
        }
        // if (onRefresh) {
        //   await onRefresh();
        // }
        // fetchIntegrations();
        // onCloseModal();
        // showToaster('Forms Imported Successfully', 'positive')
      } else {
        console.log({ home_components })
        showToaster('Please Select Tags.', 'negative')
      }
    } catch (err) {
      console.log(err)
      showToaster(JSON.stringify(err), 'negative')
    }
  }

  function fetchIntegrations() {
    client.query({
      query: organizationQuery(organization_id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      dispatch(setOrg(data.organization))
    }).catch(err => {
      console.log('fetchIntegrations error', err)
    })
  }

  const onSelectAllTags = () => {
    if (selectedTags.length === tags.length) {
      setSelectedTags([]);
      return;
    }
    setSelectedTags(tags);
  }


  async function onProgressDone() {
    console.log('onProgressDone')
    setRequestId("");
    setReportRequestId("");
    setIsImportProgress(false);
    if (onRefresh) {
      await onRefresh();
    }
    fetchIntegrations();
    onCloseModal();
    showToaster('Forms Imported Successfully', 'positive')
  }

  async function onErrorProgress() {
    console.log('onErrorProgress')
    setRequestId("");
    setReportRequestId("");
    setIsImportProgress(false);
    if (onRefresh) {
      await onRefresh();
    }
    fetchIntegrations();
  }

  const onCloseWarningModal = () => {
    setIsWarningModalOpen(false)
  }

  const onClickImport = () => {
    setIsWarningModalOpen(true)
  }


  /////////////////////////////////////////////////// Render Functions //////////////////////////////////////////////

  const renderTitle = (title) => {
    const { theme } = props;
    return (
      <HeaderTitle theme={theme}>
        {title}
      </HeaderTitle>
    )
  }
  const renderTag = (tag) => {
    const isSelected = selectedTags.find(el => el.id === tag.id);
    const onClickTag = () => {
      if (!isSelected) {
        setSelectedTags([
          ...selectedTags,
          tag
        ])
      } else {
        setSelectedTags(selectedTags.filter(el => el.id !== tag.id));
      }
    }

    return (

      <Checkbox
        checked={isSelected}
        onChange={onClickTag}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        <ParagraphMedium marginBottom='10px' paddingRight='14px'>{tag.name}</ParagraphMedium>
      </Checkbox>
    )
  }

  const renderTagsList = () => {
    let isIndeterminate = selectedTags.length > 0 && selectedTags.length < tags.length;
    let allChecked = selectedTags.length === tags.length;
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          paddingBottom: '100px'

        }}
      >
        {
          tags.length > 0 && (
            <Checkbox
              onChange={onSelectAllTags}
              isIndeterminate={isIndeterminate}
              checked={allChecked}
            >
              Select All Tags
            </Checkbox>
          )
        }

        {/* {renderTitle('Tags')} */}
        <div
          style={{
            paddingLeft: '15px',
            paddingTop: '15px'
          }}
        >

          {tags.map(renderTag)}
        </div>
      </div>
    )
  }

  const renderWarningModal = () => {
    const onClickYes = () => {
      if (isMultipleChannelImport) {
        onImportTags(selectedTags, true);
      } else {
        importByTags(true);
      }
      onCloseWarningModal()
    }

    const onClickNo = () => {
      if (isMultipleChannelImport) {
        onImportTags(selectedTags, false);
      } else {
        importByTags(false);
      }
      onCloseWarningModal();
    }

    return (
      <Modal
        overrides={{
          Root: {
            style: () => ({
              zIndex: 1
            })
          }
        }}
        onClose={onCloseWarningModal}
        autoFocus={false}
        isOpen={isWarningModalOpen}
      >
        <ModalHeader>Do you want to publish all of the content?</ModalHeader>
        <ModalFooter>
          <ModalButton
            onClick={onClickYes}
          >
            Yes
          </ModalButton>
          <ModalButton
            onClick={onClickNo}
          >
            No
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  // console.log({ selectedTags, request_id })
  return (
    <React.Fragment>
      <Drawer
        isOpen={isVisible}
        autoFocus
        onClose={onCloseModal}
        overrides={{
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: '20px',
            })
          },
        }}
      >
        <HeadingSmall
          style={{
            textAlign: 'center'
          }}
        >
          Import Forms By Template Tags
        </HeadingSmall>
        {
          isLoading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '10px'
              }}
            >
              <Spinner
                size={24}

              />
            </div>
          )
        }
        {renderTagsList()}


        <div
          style={{
            display: 'flex',
            position: 'absolute',
            minHeight: '50px',
            paddingBottom: '10px',
            paddingRight: '20px',
            paddingLeft: '20px',
            bottom: 0,
            right: 0,
            width: '100%',
            flexDirection: 'column'
          }}

        >
          {
            request_id && ProgressComponent
          }
          <div
            className={css({
              justifyContent: 'flex-end',
              display: 'flex'
            })}
          >
            <ModalButton
              onClick={onCloseModal}
            >
              Cancel
            </ModalButton>

            <ModalButton
              isLoading={loadingSharepointModule || isImportProgress}
              onClick={onClickImport}
            >
              Import
            </ModalButton>
          </div>

        </div>
      </Drawer>
      {renderWarningModal()}
    </React.Fragment>
  )
}

ImportTagFormsModal.propTypes = {
  home_components: PropTypes.array,
}

ImportTagFormsModal.defaultProps = {
  home_components: []
}

export default (ImportTagFormsModal);