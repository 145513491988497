import React from 'react';
import { Input } from "baseui/input";
import { ParagraphSmall } from 'baseui/typography';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useStyletron } from 'baseui';
import { Check } from 'baseui/icon';

const StyledInput = styled(Input)`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

const TextLengthPara = styled(ParagraphSmall)`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
  margin-top: 8px;
  margin-bottom: 0px;
`;

const CustomInput = (props) => {
  const {
    value,
    onChange,
    placeholder,
    error,
    clearOnEscape,
    maxLength,
    type,
    clearable
  } = props || {};

  const { css } = useStyletron();

  const onChangeValue = (e) => {
    if (!maxLength) {
      return onChange(e);
    }
    if ((e.target.value && e.target.value.length > maxLength) || !maxLength) {
      return;
    }
    onChange(e)
  }

  const getEndEnhancer = () => {
    if (maxLength) {
      return (
        <Check
          size={'28px'}
          color={'#05944F'}
        />
      )
    }
  }

  const updatedValue = value && value !== null ? value : '';
  return (
    <>
      <StyledInput
        value={value}
        onChange={onChangeValue}
        placeholder={placeholder}
        error={false}
        clearOnEscape={clearOnEscape}
        clearable={clearable}
        endEnhancer={getEndEnhancer}
        type={type}
      />
      {
        maxLength && (
          <TextLengthPara
          >{updatedValue.length}/{maxLength}</TextLengthPara>
        )
      }
    </>
  )
}

CustomInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  clearOnEscape: PropTypes.bool,
  maxLength: PropTypes.number,
  clearable: PropTypes.bool
}

CustomInput.defaultProps = {
  value: '',
  onChange: () => { },
  placeholder: '',
  error: false,
  clearOnEscape: true,
  clearable: false
}

export default React.memo(CustomInput);