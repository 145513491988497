import React, { useMemo } from 'react';
import { Textarea } from 'baseui/textarea';
import { Button } from 'baseui/button';

const TextResponse = (
  {
    theme,
    value,
    onChange = () => { },
    placeholder
  }
) => {

  let formatedValue = useMemo(() => {
    let val = value || [];
    if (typeof val === 'string') {
      val = JSON.parse(val);
    }
    return val;
  }, [value])

  const onChangeText = (e, i) => {
    let v1 = [...formatedValue];
    v1[i] = e.target.value;
    onChange(JSON.stringify(v1))
  }

  const onChangeTextV1 = () => {
    let v1 = [...formatedValue];
    v1.push('');
    onChange(JSON.stringify(v1))
  }

  return (
    <>

      {formatedValue && Array.isArray(formatedValue) && formatedValue.map((el, i) => {
        const onChangeEle = (e) => {
          onChangeText(e, i)
        }
        return <>
          <h5
            style={{
              color: theme.colors.primaryA,
              marginTop: theme.sizing.scale1200,
            }}
          >
            {`Correct Text #${i + 1} ${i > 0 ? '(optional)' : ''}`}
          </h5>
          <Textarea
            value={el}
            onChange={onChangeEle}
            placeholder={placeholder}
            clearOnEscape
          /></>
      })}
      <Button
        style={{
          marginTop: '16px',
        }}
        onClick={onChangeTextV1}>
        Add New Answer
      </Button>
    </>
  );
}

export default TextResponse;
