import * as React from 'react';
import { ArrowLeft } from 'baseui/icon';
import { connect } from 'react-redux';
import {
  LabelMedium,
  LabelXSmall,
} from 'baseui/typography';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import ImageGallery from 'react-image-gallery';
import ReactPlayer from 'react-player';

import './preview.css';
import { sourceByType } from '../../util';
import { editorBtnOptions } from '../../../data/cardBuilderOptions';
import ReactAudioPlayer from 'react-audio-player';

import { createEditorCardsFromCardModules } from './builderUtils/helpers';

import GenCardUrl from '../../util/GenCardUrl';
import { navigate } from 'gatsby';

const style = {
  paddingLeft: '20px',
  paddingRight: '20px',
  marginBottom: '20px'
}

const getSrc = (data, type) => {
  try {
    if (!data) return data;
    return sourceByType(data, type);
  } catch (e) {
    return null;
  }
};


const TextCardModule = ({ text, color }) => {
  return <div style={{ ...style, color }}>
    {text}
  </div>
};

const AudioCardModule = ({ url }) => {
  return <div style={{ ...style }}>
    <div style={{
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '6px',
      padding: '18px'
    }}>
      <ReactAudioPlayer
        src={url}
        autoPlay={false}
        controls
      />
    </div>
  </div>
};


const AttachmentCardModule = ({ url, filename, fileSize, color }) => {
  return <div style={{ ...style }}>
    <div style={{
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '6px',
      padding: '18px', display: 'flex', cursor: 'pointer'
    }}
      onClick={() => window.open(url, '_blank')}
    >
      <div style={{
        position: 'relative',
        width: '60px',
        height: '62px',
        background: 'rgba(255, 255, 255, 0.151442)',
        borderRadius: '3px',
      }}>
        <svg
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.394 10.85H21.248L13.394 3.064V10.85ZM3.5 0.955999H14.856L23.356 9.456V26.456C23.356 27.2267 23.0727 27.884 22.506 28.428C21.9393 28.972 21.2707 29.244 20.5 29.244H3.5C2.72933 29.244 2.06067 28.972 1.494 28.428C0.927334 27.884 0.644 27.2267 0.644 26.456L0.712 3.744C0.712 2.97333 0.984 2.316 1.528 1.772C2.072 1.228 2.72933 0.955999 3.5 0.955999Z" fill={color} />
        </svg>
      </div>
      <div style={{ marginLeft: '16px', marginTop: '8px' }}>
        <LabelMedium style={{ color }}>{filename}</LabelMedium>
        <LabelXSmall style={{ color }}>{fileSize} Mb Tap to Download</LabelXSmall>
      </div>
    </div>
  </div>
};

export const ButtonCardModule = ({ title, url, background, overrideStyle }) => {
  return <div style={{ ...style, ...overrideStyle }}>
    <div>
      <div style={{
        background,
        color: 'white',
        fontSize: '14px',
        textAlign: 'center',
        height: '65px',
        lineHeight: '65px',
        borderRadius: '4px',
        fontWeight: 'bold',
        cursor: 'pointer'
      }}
        onClick={() => window.open(url, '_blank')}
      >{title}</div>
    </div>
  </div>
}

export const PhoneButtonCardModule = ({ title, phone_number, background, overrideStyle }) => {
  return <div style={{ ...style, ...overrideStyle }}>
    <div>
      <div style={{
        background,
        color: 'white',
        fontSize: '14px',
        textAlign: 'center',
        height: '65px',
        lineHeight: '65px',
        borderRadius: '4px',
        fontWeight: 'bold',
        cursor: 'pointer'
      }}
        onClick={() => window.open(`tel:${phone_number}`)}
      >{title}</div>
    </div>
  </div>
}


export const CardLinkButtonCardModule = ({ title, cardId, background, overrideStyle }) => {
  return <div style={{ ...style, ...overrideStyle }}>
    <div>
      <div style={{
        background,
        color: 'white',
        fontSize: '14px',
        textAlign: 'center',
        height: '65px',
        lineHeight: '65px',
        borderRadius: '4px',
        fontWeight: 'bold',
        cursor: 'pointer'
      }}
        onClick={async () => {
          let cardUrl = await GenCardUrl(cardId);
          navigate(cardUrl);
        }}

      >{title}</div>
    </div>
  </div>
}


export const ImageCardModule = ({ url, overrideStyle }) => {
  return <div style={{
    background: 'linear-gradient(0deg, #000000 0.45%, rgba(216, 216, 216, 0.0001) 100%)',
    height: '348px',
    mixBlendMode: 'normal',
    opacity: 0.86,
    backgroundImage: `url("${url}")`,
    backgroundSize: 'cover',
    marginBottom: '20px',
    ...overrideStyle
  }}></div>
}

export const HeaderImageCardModule = ({ url, overrideStyle }) => {
  return <div style={{
    background: 'linear-gradient(0deg, #000000 0.45%, rgba(216, 216, 216, 0.0001) 100%)',
    height: '348px',
    mixBlendMode: 'normal',
    opacity: 0.86,
    backgroundImage: `url("${url}")`,
    backgroundSize: 'cover',
    marginBottom: '20px',
    ...overrideStyle
  }}></div>
}

/**
 * 
 * @param {*} images [{src, thumbnail}] 
 */
const ImageGalleryCardModule = ({ images }) => {
  return <div style={{ ...style }}>
    <div style={{
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '6px',
      padding: '1px',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <ImageGallery items={images.map((el) => {
        return { ...el, original: el.src }
      })}
        showPlayButton={false}
        showFullscreenButton={true}
      />
    </div>
  </div>
}

/**
 * 
 * @param {*} videos [{thumbnail, url}] 
 */
const VideoGalleryCardModule = ({ videos }) => {
  return <div style={{ ...style }}>
    <div style={{
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '6px',
      padding: '1px',
      position: 'relative',
      overflow: 'hidden',
    }}>
      <ImageGallery items={
        videos.map((el) => {
          return {
            thumbnail: el.thumbnail,
            renderItem: () => <ReactPlayer controls={true} url={el.url} width="352px" height="196px"></ReactPlayer>
          }
        })
      }
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
      />
    </div>
  </div>
}

const VideoCardModule = ({ url }) => {
  if (!url) return <></>;
  return <div style={{ ...style }}>
    <div>
      <ReactPlayer url={url} width="100%" height="196px"></ReactPlayer>
    </div>
  </div>
}

const getFileName = data => {
  try {
    if (!data) return '';
    return JSON.parse(data).filename || JSON.parse(data).full.metadata.filename;
  } catch (e) {
    return '';
  }
};

export const getModuleStructure = ({ module, width, color, color_palette }) => {
  var component = null;
  try {
    switch (module.__typename) {
      case 'TextCardModule':
        component = <TextCardModule text={ReactHtmlParser(module.inputs[0].value)} color={color}></TextCardModule>;
        break;
      case 'WebCardModule':
        const sourceURL = module.inputs[0].value;
        component = (
          <iframe name="Framename" src={sourceURL} width="600" height="500" frameborder="0" scrolling="yes" style={{ width: "100%" }}></iframe>
        );
        break;
      case 'AudioCardModule':
        component = module.inputs[0].value.map((el) => {
          var url = el.file_data ? getSrc(el.file_data) : URL.createObjectURL(el.file);
          return <AudioCardModule url={url}></AudioCardModule>
        });
        break;
      case 'AttachmentCardModule':
        component = module.inputs[0].value.map((el) => {
          var url = el.file_data ? getSrc(el.file_data) : URL.createObjectURL(el.file);
          var filename = el.file_data ? getFileName(el.file_data) : el.file.name
          var fileSize = '10';
          return <AttachmentCardModule url={url} filename={filename} fileSize={fileSize} color={color}></AttachmentCardModule>
        });
        break;
      case 'ButtonCardModule':
        component = <ButtonCardModule title={module.inputs[0].value} url={module.inputs[1].value} background={color_palette[0] || 'black'}></ButtonCardModule>
        break;
      case 'CardLinkButtonCardModule':
        component = <CardLinkButtonCardModule title={module.inputs[0].value} cardId={module.inputs[1].value} background={color_palette[0] || 'black'}></CardLinkButtonCardModule>
        break;
      case 'ContactCardModule':
        break;
      case 'EmbedCodeCardModule':
        component = <div style={{ ...style }}>
          <div
            style={{
              width: '100% !important',
            }}
            dangerouslySetInnerHTML={{
              __html: module.inputs[0].value,
            }}
          ></div>
        </div>
        break;
      case 'ImageCardModule':
        var f = module.inputs[0].value;
        var isFile = Array.isArray(f) && f[0].path;
        var url = isFile ? URL.createObjectURL(f[0]) : getSrc(f, 'small')
        component = <ImageCardModule url={url}></ImageCardModule>
      case 'HeaderImageCardModule':
        var f = module.inputs[0].value;
        var isFile = Array.isArray(f) && f[0].path;
        var url = isFile ? URL.createObjectURL(f[0]) : getSrc(f, 'small')
        component = <HeaderImageCardModule url={url}></HeaderImageCardModule>
        break;
      case 'ImageGalleryCardModule':
        var images = module.inputs[0].value.map(el => {
          var src = el.file_data ? getSrc(el.file_data, 'small') : URL.createObjectURL(el.file);
          var thumbnail = el.file_data ? getSrc(el.file_data, 'small') : URL.createObjectURL(el.file);
          return { src, thumbnail };
        });
        component = <ImageGalleryCardModule images={images}></ImageGalleryCardModule>
        break;
      case 'VideoGalleryCardModule':
        var videos = module.inputs[2].value || [].map(el => {
          var thumbnail = el.cover_photo_data ? getSrc(el.file_data, 'small') : el.cover_photo ? URL.createObjectURL(el.file) : null;
          var url = '';
          return { thumbnail, url };
        });
        component = <VideoGalleryCardModule videos={videos}></VideoGalleryCardModule>
        break;
      case 'MeetingCardModule':
        break;
      case 'YoutubeCardModule':
        var url = module.inputs[1].value;
        component = <VideoCardModule url={url}></VideoCardModule>
        break;
    }
  } catch (e) {
    console.log('error in rendering preview module', e);
  }
  return component;
};

const PreviewElements = ({ title, cardBackData, dataType, background = '#1D4759', color = 'white', color_palette, max_height, contentModules }) => {
  console.log(cardBackData, contentModules);

  const processFromOriginal = (data) => {
    cardBackData = createEditorCardsFromCardModules(data, { options: editorBtnOptions }) || [];
    cardBackData = cardBackData.sort((a, b) => a.position - b.position);
  };

  if (dataType && dataType === 'originalDBData') {
    processFromOriginal(cardBackData);
  };

  if (cardBackData && cardBackData.length == 0 && contentModules && contentModules.length > 0) {
    processFromOriginal(contentModules);
  };

  console.log('preview elements ---->', cardBackData);
  return (
    <>
      <div style={{ background: background, width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', padding: '16px' }}>
          {title && <ArrowLeft size={32} style={{ color }} />}
          {title && <LabelXSmall style={{ color, lineHeight: '30px', textTransform: 'uppercase' }}>{title}</LabelXSmall>}
        </div>

        <div style={{
          width: '100%',
          background: 'rgba(255, 255, 255, 0.1)',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
          paddingBottom: '32px',
          maxHeight: max_height ? max_height : '72vh',
          overflow: 'auto'
        }}>
          {cardBackData.map((el) => {
            return getModuleStructure({
              module: el,
              width: '100%',
              color,
              color_palette
            })
          })}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  let color_palette = [];
  if (organization && organization.color_palette) {
    color_palette = organization.color_palette;
  };
  return {
    color_palette
  };
};
export default connect(mapStateToProps)(PreviewElements);

