import React, { useMemo } from "react";
import BaseClass from "../builder/cardBackEditorComponents/base";
import _ from "lodash";

class BuilderComponentWrapper extends BaseClass {
  constructor(props) {
    super(props);

    const {
      root,
      extraStateKeyToUpdate,
    } = props;

    let extraKeyValue = {};
    if (extraStateKeyToUpdate && extraStateKeyToUpdate.length > 0) {

      if (root) {
        const {
          inputs = []
        } = root;

        extraStateKeyToUpdate.map((key) => {
          const keyVal = inputs.find(input => input.key === key);
          if (keyVal) {
            extraKeyValue[key] = keyVal?.value || '';
          }
        })
      }
    }

    this.state = {
      value: props.value || null,
      ...extraKeyValue
    }
    this.values = []
    if (extraStateKeyToUpdate && extraStateKeyToUpdate.length > 0) {
      this.values = extraStateKeyToUpdate.map((key) => {
        return extraKeyValue[key] || null;
      })
    }
    this.Component = props._component;

  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const {
      extraStateKeyToUpdate
    } = this.props;
    if (!_.isEqual(nextState, this.state) && extraStateKeyToUpdate && extraStateKeyToUpdate.length > 0) {
      this.values = extraStateKeyToUpdate.map((key) => {
        return nextState[key] || null;
      })
    }
  }


  onChange = (data) => {
    if (!this.props.extraStateKeyToUpdate) {
      this.setState({ value: data });
    } else {
      const newState = (data || []).reduce((acc, curr, currIndx) => {
        if (currIndx === 0) {
          return ({
            value: curr
          })
        }
        return ({
          ...acc,
          [this.props.extraStateKeyToUpdate[currIndx - 1]]: curr
        })
      }, {})
      this.setState({
        ...newState
      })
    }
  }


  render() {
    const Component = this.Component;
    return (
      <Component
        {...this.props}
        values={this.values || {}}
        onChange={this.onChange}
      />
    )
  }
}

const WrapperBuilder = ({
  _component,
  includeWrapperClass = false,
  ...props
}) => {

  const Component = useMemo(() => {
    let ComponentProp = _component;
    if (includeWrapperClass) {
      return (
        <BuilderComponentWrapper
          {...props}
          _component={_component}
        />
      )
    }

    return <ComponentProp {...props} />
  }, [includeWrapperClass, _component, props])

  return (
    <>

      <h5
        style={{
          color: props.theme.colors.primaryA,
          marginTop: props.theme.sizing.scale1200,
          fontFamily: 'IBM Plex Sans',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '16px'
        }}
      >
        {props.title || ''}
      </h5>
      {Component}
    </>
  )
}

export default WrapperBuilder;