import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import _ from 'lodash';
import { Input } from 'baseui/input';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { toaster as newToaster } from "baseui/toast";
import {
  LabelSmall,
  ParagraphSmall,
  LabelMedium
} from 'baseui/typography';
import { client } from '../../apollo/client';
import gql from 'graphql-tag';
import { useStyletron } from 'baseui';
import { sourceByType } from '../../util';
import CardFrontPreview from './cardFrontPreview';
import ImageUploadCXL from '../imageUploaderCXL';
import { cardFrontCoverGuideline } from '../help/imageSizeGuidelines.json';
import titlesLength from '../../../data/titlesLength.json';
import { setIsLoading } from '../../redux/actions/appBasicControls';
import { useDispatch } from 'react-redux';
import { apiQuery } from '../apiMappings/query';
import { Select } from 'baseui/select';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import CardApiMapping from '../apiMappings/card-front-mapping';
import store from '../../redux/store';
import PreviewFromPwa from './previewFromPwa';
import TextArea from '../channel/textarea';

const USE_OLD_PREVIEW = process.env.USE_OLD_PREVIEW || false;

const getSrc = (data, type) => {
  let path = sourceByType(data, type);
  return path;
};

const gridPaddingOverrides = {
  Grid: {
    style: ({ $theme }) => ({
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      marginBottom: $theme.sizing.scale550,
    }),
  },
};

const cellPaddingOverrides = {
  Cell: {
    style: () => ({
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    }),
  },
};

const updateCardMutation = gql`
mutation updateCard(
  $id: ID!
  $title: String
  $sub_title: String
  $full_bleed: Boolean
  $file: Upload
  $front_image: Upload
  $front_image_data: String
  $archived: Boolean
  $has_data_source_connected: Boolean
  $api_path_id: ID
  $description: String
  $show_description_instead_of_title: Boolean
  $asset_url: String
) {
  updateCard(
    id: $id
    title: $title
    sub_title: $sub_title
    full_bleed: $full_bleed
    file: $file
    archived: $archived
    front_image: $front_image
    front_image_data: $front_image_data
    has_data_source_connected: $has_data_source_connected
    api_path_id: $api_path_id
    description: $description
    show_description_instead_of_title: $show_description_instead_of_title
    asset_url: $asset_url
  ) {
    title
    sub_title
    front_image_data
    has_data_source_connected
    api_path_id
    description
    show_description_instead_of_title
    asset_url
  }
}
`;

var onSaveDebounced;

export default ({ card, refetch, toaster, isMainPageModal, organization_id, rest, data }) => {
  if (!toaster) {
    toaster = newToaster;
  };
  const [title, setTitle] = React.useState(card.title || '');
  const [sub_title, setDescription] = React.useState(card.sub_title || '');
  const [cover_image_data, setImage] = React.useState(card.file_data || card.front_image_data || null);
  const [cover_image, setUpload] = React.useState(null);
  const [preivew_image, setPreview] = React.useState(null);
  const [full_bleed, setFullBleed] = React.useState(card.full_bleed || false);
  const [archived, setArchived] = React.useState(card.archived || false);
  const [notificatonOpend, isNotificationOpened] = React.useState(false);
  const dispatch = useDispatch();
  // const [isLoading, setIsLoading] = React.useState(false);
  const [logo, SetLogo] = React.useState(null);
  const id = card.id;
  const [css, theme] = useStyletron();

  const [isMappingOpen, setIsMappingOpen] = React.useState(false);
  const [has_data_source_connected, set_has_data_source_connected] = React.useState(card.has_data_source_connected || false);
  const [api_path_id, set_api_path_id] = React.useState(card.api_path_id || null);
  const [paths, setPaths] = React.useState([]);
  const { is_api_settings_on } = store.getState().appBasicControls.organization_settings || {};

  const [description, setDescriptionEditor] = React.useState(card.description || '');
  const [descriptionDisplayStatus, setDescriptionDisplayStatus] = React.useState(card.show_description_instead_of_title || false);
  const [asset_url, set_asset_url] = React.useState(card.asset_url || '');
  React.useEffect(() => {
    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "api_paths",
          query: `{\"where\": {\"organization_id\": \"${organization_id}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        setPaths(JSON.parse(response.data));
      }
    });
  }, []);


  const previewImage = file => {
    setPreview(URL.createObjectURL(file));
  };

  const onSave = (dataToSave, refetch) => {
    let toastKey;
    if (!notificatonOpend) {
      const msg = 'Saving the card front data.';
      toastKey = toaster.info(<>{msg}</>, {
        onClose: () => console.log('Toast closed.'),
        overrides: {
          InnerContainer: {
            style: { width: '100%' },
          },
        },
      });
      isNotificationOpened(true)
    }
    dispatch(setIsLoading(true));
    client.mutate({
      mutation: updateCardMutation,
      variables: dataToSave
    }).then(() => {
      toaster.update(toastKey, {
        children: <>Card saved.</>,
      });
      if (refetch) {
        refetch();
      }
      setTimeout(() => {
        toaster.clear(toastKey);
        isNotificationOpened(false)
      }, 1500);
    })
      .catch(error => {
        toaster.update(toastKey, {
          children: <>Failed to save card.</>,
        });
        console.log('Card saving issue: ', error);
        setTimeout(() => {
          toaster.clear(toastKey);
          isNotificationOpened(false)
        }, 1500);
      }).finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  React.useEffect(() => {
    if (onSaveDebounced) {
      onSaveDebounced = null;
    };
    onSaveDebounced = _.debounce(onSave, 1500);
  }, []);

  React.useEffect(() => {
    let dataToSave = {
      id,
      title,
      sub_title,
      front_image: cover_image,
      full_bleed,
      archived,
      front_image_data: cover_image_data,
      has_data_source_connected, api_path_id, description,
      show_description_instead_of_title: descriptionDisplayStatus,
      asset_url
    };
    if (!onSaveDebounced) {
      onSaveDebounced = _.debounce(onSave, 1500);
    } else {
      onSaveDebounced.cancel();
    }
    if (cover_image) return onSaveDebounced(dataToSave, refetch);
    if (title != card.title) return onSaveDebounced(dataToSave, refetch);
    if (sub_title != card.sub_title) return onSaveDebounced(dataToSave, refetch);
    if (full_bleed != card.full_bleed) return onSaveDebounced(dataToSave, refetch);
    if (archived != card.archived) return onSaveDebounced(dataToSave, refetch);
    if (cover_image_data != card.front_image_data) return onSaveDebounced(dataToSave, refetch);
    if (has_data_source_connected != card.has_data_source_connected) return onSaveDebounced(dataToSave, refetch);
    if (api_path_id != card.api_path_id) return onSaveDebounced(dataToSave, refetch);
    if (description != card.description) return onSaveDebounced(dataToSave, refetch);
    if (descriptionDisplayStatus != card.show_description_instead_of_title) return onSaveDebounced(dataToSave, refetch);
    if (asset_url != card.asset_url) return onSaveDebounced(dataToSave, refetch);
  }, [title, sub_title, cover_image, full_bleed, archived, cover_image_data, has_data_source_connected, api_path_id, description, descriptionDisplayStatus, asset_url]);

  return (
    <>
      <Grid overrides={gridPaddingOverrides}>
        <Cell span={5} overrides={{
          Cell: {
            style: () => ({
              padding: '0px !important',
            }),
          },
        }}>
          <Cell overrides={cellPaddingOverrides} span={10}>
            <div
              className={css({
                padding: '24px',
                background: theme.colors.primaryB,
                marginBottom: '24px',
              })}
            >
              <LabelSmall>General Settings</LabelSmall>
              <LabelSmall
                style={{
                  marginTop: '16px',
                  marginBottom: '8px',
                }}
              >
                Card Title
              </LabelSmall>
              <Input
                value={title}
                onChange={e => {
                  if (e.target.value.length <= titlesLength.card)
                    setTitle(e.target.value)
                }}
                placeholder="Title"
                clearOnEscape
              />
              <ParagraphSmall>{title.length}/{titlesLength.card}</ParagraphSmall>

              <LabelSmall
                style={{
                  marginTop: '16px',
                  marginBottom: '8px',
                }}
              >
                Card Subtitle
              </LabelSmall>
              <Input
                value={sub_title}
                onChange={e => {
                  if (e.target.value.length <= titlesLength.cardSubtitle)
                    setDescription(e.target.value)
                }}
                placeholder="Subtitle"
                clearOnEscape
              />
              <ParagraphSmall style={{ marginBottom: '0px' }}>{sub_title.length}/{titlesLength.cardSubtitle}</ParagraphSmall>
            </div>

            {is_api_settings_on && <div
              className={css({
                padding: '24px',
                background: theme.colors.primaryB,
                marginBottom: '24px',
              })}
            >
              <Checkbox
                checked={has_data_source_connected}
                onChange={e => set_has_data_source_connected(!has_data_source_connected)}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                <ParagraphSmall style={{ marginTop: '2px', marginBottom: '16px' }}>Has data source</ParagraphSmall>
              </Checkbox>
              <LabelSmall style={{ marginBottom: '16px', marginBottom: '8px', }}>Api Path</LabelSmall>
              <Select
                options={paths}
                value={paths.filter(el => el.id == api_path_id)}
                labelKey="name"
                valueKey="id"
                placeholder=''
                onChange={({ option }) => {
                  if (option)
                    set_api_path_id(option.id);
                  else
                    set_api_path_id(null);
                }}
                overrides={{
                  Popover: {
                    props: {
                      overrides: {
                        Body: { style: { zIndex: 3 } },
                      },
                    },
                  },
                }}
              />
              <LabelMedium style={{ marginTop: '8px', cursor: 'pointer' }}>
                <a onClick={() => {
                  setIsMappingOpen(true);
                }}>Map Category data with Api &#8594;</a>
              </LabelMedium>
            </div>}


            <div
              className={css({
                padding: '24px',
                background: theme.colors.primaryB,
                marginBottom: '24px',
              })}
            >
              <LabelSmall style={{ marginBottom: '16px', marginBottom: '8px', }}>Description</LabelSmall>
              <TextArea
                placeholder="Description"
                value={description}
                onChange={setDescriptionEditor}
                textAreaKey="card_front_description"
              ></TextArea>
              <div style={{ marginTop: '16px' }}>
                <Checkbox
                  checked={descriptionDisplayStatus}
                  onChange={e => setDescriptionDisplayStatus(!descriptionDisplayStatus)}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Show description</ParagraphSmall>
                </Checkbox>
              </div>
            </div>

            <hr style={{ height: '2px', margin: 0, marginTop: '22px', background: '#E2E2E2' }} />
            <ImageUploadCXL
              title={'Cover Image'}
              titleHelpText={'Upload cover image for card front'}
              previewUrls={preivew_image || getSrc(cover_image_data, 'cover_medium_url')}
              downloadUrls={[getSrc(cover_image_data, 'full_url')]}
              isMultiple={false}
              onDrop={(acceptedFiles, rejectedFiles) => {
                setUpload(acceptedFiles[0]);
                previewImage(acceptedFiles[0]);
              }}
              onImageDelete={() => {
                setImage(null);
                setPreview(null);
              }}
              showFullBleedSettings={true}
              fullBleed={full_bleed}
              onBleedChange={(status) => {
                setFullBleed(status);
              }}
              imageSize={cardFrontCoverGuideline.size}
              imageSizeGuidelineURL={cardFrontCoverGuideline.url}
              style={{ padding: '24px', marginTop: '24px' }}
            ></ImageUploadCXL>
            <div
              className={css({
                padding: '24px',
                background: theme.colors.primaryB,
                marginBottom: '24px',
              })}
            >
              <LabelSmall
                style={{
                  marginTop: '16px',
                  marginBottom: '8px',
                }}
              >
                Asset URL
              </LabelSmall>
              <Input
                value={asset_url}
                onChange={e => {
                  // if (e.target.value.length <= titlesLength.cardSubtitle)
                  set_asset_url(e.target.value)
                }}
                placeholder="Asset URL"
                clearOnEscape
              />
            </div>
          </Cell>
        </Cell>
        <Cell overrides={{
          Cell: {
            style: () => ({
              position: 'fixed',
              bottom: '0px', right: '0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'auto',
              top: '99px'
            }),
          },
        }} span={7}>
          {USE_OLD_PREVIEW ? <CardFrontPreview
            image_src={preivew_image || getSrc(cover_image_data)}
            full_bleed={full_bleed}
            title={title}
            sub_title={sub_title}
            cardCoverPreview
          ></CardFrontPreview>
            :
            <PreviewFromPwa
              preview_type={'front'}
              hideOptions={true}
              organization_id={organization_id}
              data={data}
              card_id={data.card.id}
              deck_id={rest.deckId}
              channel_id={rest.channelId}
              add_open_in_new_tab={true}
            />
          }
        </Cell>
      </Grid>
      <Modal
        isOpen={id && isMappingOpen}
        onClose={() => {
          setIsMappingOpen(false);
        }}
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Dialog: {
            style: {
              width: '80vw',
              height: '90vh',
              display: 'flex',
              flexDirection: 'column',
            },
          }
        }}
      >
        <div style={{
          paddingTop: '32px',
          paddingBottom: '32px'
        }}>
          <CardApiMapping
            card_id={id}
            organization_id={organization_id}
            paths={paths}
            api_path_id={api_path_id}
          />
        </div>
      </Modal>
    </>
  );
};
