import React from "react"
import { useStyletron } from "baseui";
import { Cell, Grid } from "baseui/layout-grid";
import { gridPaddingOverrides } from "../overrides";
import Button from "../styledButton";
import { Spinner } from "baseui/spinner";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader
} from "baseui/modal";
import { H6, LabelMedium } from "baseui/typography";
import { Accordion, Panel } from "baseui/accordion";
import { Input } from "baseui/input";
import CodeEditor from '@uiw/react-textarea-code-editor';
import styled from '@emotion/styled';

import { client } from "../../apollo/client";
import { createCommon, updateCommon } from "../apiMappings/query";
import { showAlert } from "../../redux/actions/appBasicControls";
import { deleteOrganizationStasFunction as deleteOrganizationStasFunctionMutation } from "../organizationStashFunctions";

const DeleteButton = styled(Button)`
  background-color: #ef4778;

  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  margin-top: 16px;
  border-radius: 20px;
  font-size: 12px;
`;


const DeleteModal = ({
  isOpen = false,
  onClose = () => { },
  onDelete = () => { },
  isLoading = false
}) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <ModalHeader>
        Are you sure that you want to delete this Integration?
      </ModalHeader>
      <ModalBody>
        <H6>This action can not be undone.</H6>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={onClose}
        >
          Cancel
        </ModalButton>
        <ModalButton
          isLoading={isLoading}
          onClick={onDelete}
        >
          Okay
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

const StashFunctions = ({
  functions,
  isLoading,
  setIsLoading,
  setFunctions,
  queryData,
  organization_id,
  dispatch,
  refetch
}) => {
  const [css, theme] = useStyletron();
  const [selected, setSelected] = React.useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [isModalLoading, setIsModalLoading] = React.useState(false);
  const [isSaveLoading, setIsSaveLoading] = React.useState(false);

  console.log('____', functions);
  const setValue = (index, key, value) => {
    let v1 = JSON.parse(JSON.stringify(functions));
    v1[index][key] = value;
    v1[index].isUpdated = true;
    setFunctions(v1)
  };

  const addNew = () => {
    setIsLoading(true);
    client.mutate({
      mutation: createCommon,
      variables: {
        data: {
          model: "organization_stash_functions",
          data: JSON.stringify({
            title: '',
            comment: '',
            processor_function: '',
            organization_id
          })
        }
      }
    }).then(() => queryData());
  };

  const save = async () => {
    setIsLoading(true);
    let toSave = functions.filter(el => el.isUpdated);
    await Promise.all(
      toSave.map(s => {
        return client.mutate({
          mutation: updateCommon,
          variables: {
            data: {
              model: 'organization_stash_functions',
              data: JSON.stringify({
                ...s,
              }),
            },
          },
        });
      })
    );
    queryData();
    await refetch();
    setIsSaveLoading(false);
    dispatch(
      showAlert({
        msg: 'Successfully stash function!',
        error: false,
      })
    );
  }

  const onDelete = async () => {
    try {
      setIsModalLoading(true);
      await client.mutate({
        mutation: deleteOrganizationStasFunctionMutation,
        variables: {
          id: selected?.id
        },
      });
      queryData();
      onCloseModal()
      await refetch();
      setIsModalLoading(false);
      
      dispatch(
        showAlert({
          msg: 'Successfully deleted stash function!',
          error: false,
        })
      );
    } catch (err) {
      console.log('onDelete error', err);
      setIsModalLoading(false);
      dispatch(
        showAlert({
          msg: err?.message || 'Something went wrong!',
          error: true,
        })
      );
    }
  }

  const onDeleteClick = (func) => {
    setSelected(func);
    setIsDeleteOpen(true);
  }
  const onCloseModal = () => {
    setSelected();
    setIsDeleteOpen(false);
  }

  const functionItem = (func, index) => {
    console.log('functionItem --------', { func, index })
    return (
      <Panel
        overrides={{
          Content: {
            style: ({ $theme }) => ({
              paddingLeft: '0 !impmortant',
            }),
          },
        }}
        key={index}
        title={func.title ? func.title : '...'}
      >
        <Cell span={12}>
          <div
            style={{
              background: theme.colors.primaryB,
              padding: '24px',
            }}
          >
            <div>
              <LabelMedium
                style={{ marginTop: '16px', marginBottom: '16px' }}
              >
                Name
              </LabelMedium>
              <Input
                value={func.title}
                onChange={e => {
                  setValue(index, 'title', e.target.value);
                }}
              />
            </div>

            <div>
              <LabelMedium
                style={{ marginTop: '16px', marginBottom: '16px' }}
              >
                Comment
              </LabelMedium>
              <Input
                value={func.comment}
                onChange={e => {
                  setValue(index, 'comment', e.target.value);
                }}
              />
            </div>
            <LabelMedium style={{ marginTop: '16px' }}>Function Logic</LabelMedium>
            <Accordion
              accordion
              overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    marginLeft: 0,
                  })
                }
              }}
            >
              <Panel title={''}>
                <div>
                  <CodeEditor
                    value={func.processor_function}
                    language="js"
                    placeholder="Please enter JS code."
                    onChange={(evn) => setValue(index, 'processor_function', evn.target.value)}
                    padding={2}
                    minHeight={'50vh'}
                    style={{
                      fontSize: 18,
                      backgroundColor: "#E5E5E5",
                      fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    }}
                  />
                </div>
              </Panel>
            </Accordion>
            <Cell span={12}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                <div style={{}}>
                  <DeleteButton onClick={() => {
                    onDeleteClick(func)
                  }} isSelected>
                    Delete
                  </DeleteButton>
                </div>
                <div style={{ marginLeft: '16px', }}>
                  <Button
                    isLoading={false}
                    onClick={save}
                    isSelected
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Cell>
          </div>
        </Cell>
      </Panel>
    );
  }

  return (
    <Grid overrides={gridPaddingOverrides}>
      <Cell span={12} align={'right'}>

        <div
          className={css({
            marginRight: '16px',
            position: 'relative',
            float: 'right',
            marginBottom: '16px'
          })}
        >
          <Button
            isLoading={false}
            onClick={addNew}
            isSelected
          >
            Add New
          </Button>
        </div>
        {isLoading && <div
          className={css({
            position: 'relative',
            float: 'right',
            marginRight: '16px', marginTop: '18px'
          })}
        >
          <Spinner size={24} />
        </div>}

      </Cell>
      <Cell
        span={12}
        align={'left'}
      >
        <Accordion onChange={({ expanded }) => console.log(expanded)}>
          {functions.map(functionItem)}
        </Accordion>
      </Cell>
      <React.Fragment>
        <DeleteModal
          isOpen={isDeleteOpen}
          onClose={onCloseModal}
          onDelete={onDelete}
          isLoading={isModalLoading}
        />
      </React.Fragment>
    </Grid>
  )
}

export default StashFunctions;