import React from 'react';
import { RadialChart } from 'react-vis';
import ReactHighcharts from 'react-highcharts';

export default function CustomRadialChart(props) {
  const myData = props.data;
  return (
    <ReactHighcharts
      config={{
        chart: {
          plotBackgroundColor: props.backgroundColor,
          backgroundColor: props.backgroundColor,
          type: 'pie'
        },
        title: {
          text: props.title
        },
        tooltip: {
          formatter: function() {
            console.log(this);
            return `
              <div style="background: ${this.color}; color: white">
                <b>${this.key}</b> <br/>
                ${props.name || this.point.y}(${((this.point.y / this.total) * 100).toFixed(2)}%)
              </div>
            `;
          }
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              // format: '{point.name}: <span style="color:#4A4CA0; font-weight:bold;">{point.y}</span>'
              formatter: function() {
                return `<div>
                ${this.point.name}: <span style="color:#4A4CA0; font-weight:bold; font-size: 14px;">${this.point.y}</span>
                </div>`
              }
            },
            colors: [
              'rgba(118, 95, 237, 1)',
              'rgba(16, 156, 241, 1)',
              'rgba(255, 230, 0, 1)',
              'rgba(102, 209, 158, 1)',
              'rgba(255, 138, 0, 1)',
              'rgba(255, 0, 0, 1)'
            ]
          }
        },
        series: [{
          name: props.name ||'',
          colorByPoint: true,
          data: myData
        }]
      }}
    />
  );
}
