import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import Button from '../styledButton';
import { cellPaddingOverrides } from '../overrides';
import { useStyletron } from 'baseui';
import RightSidePageHeader from './../channel/channelHeader';
import {
  Modal,
  ModalBody,
} from "baseui/modal";
import { getHtmlForContentEmail } from './email-template';
import ReactHtmlParser from 'react-html-parser';
import { IFrame } from './iframe';
import ArticlePreview from './article-preview';
import PushPreview from './push-preview';
import prepareDataForEmailPreview from './prepare-data-email';
import PopupPreview from './popup-preview';

const modalCss = {
  width: '80vw',
  height: '97vh',
  display: 'flex',
  flexDirection: 'column',
};

const PreviewModal = ({ feed, isOpen, onClose }) => {
  const [css, theme] = useStyletron();
  const [tabs, setTabs] = React.useState([]);
  const [tab, setTab] = React.useState('');
  console.log(feed);

  React.useEffect(() => {
    let tabs = [];
    if (feed.communication.type_push_notification) {
      tabs.push("Push Notification");
    }
    if (feed.communication.type_email) {
      tabs.push("Email");
    }
    if (feed.communication.type_news) {
      tabs.push("Article");
    };
    if (feed.communication.type_popup) {
      tabs.push("Popup");
    };
    setTabs(tabs);
    setTab(tabs[0]);
  }, []);
  let data = prepareDataForEmailPreview(feed.communication.card_content_modules);
  let emailHtml = (getHtmlForContentEmail(data));
  return (
    <Grid overrides={{
      Grid: {
        style: () => ({
          paddingTop: '0px',
          paddingBottom: '32px',
          width: '100%',
          paddingLeft: '0px',
        }),
      },
    }} gridMaxWidth={'unset'}>
      <Cell span={12} overrides={cellPaddingOverrides} span={10}>
        <Modal
          onClose={() => onClose()}
          closeable
          isOpen={isOpen}
          animate
          autoFocus
          overrides={{
            Backdrop: {
              style: ({ $theme }) => ({
                backdropFilter: $theme.modalBackDropFilter,
              }),
            },
            Root: {
              style: {
                zIndex: 4,
              }
            },
            Dialog: {
              style: modalCss,
            },
          }}
        >
          <ModalBody>
            <div style={{ display: 'flex', width: '100%', color: 'rgb(98, 70, 238)' }}>

              <div style={{ flexGrow: 1 }}>
                <RightSidePageHeader
                  rightContent={
                    <StatefulButtonGroup
                      mode={MODE.radio}
                      initialState={{ selected: 0 }}
                    >
                      {tabs.map((el) => {
                        return <Button onClick={() => setTab(el)}>{el}</Button>
                      })}
                    </StatefulButtonGroup>
                  }
                ></RightSidePageHeader>
              </div>
            </div>
            {tab == "Email" && <Cell span={12} overrides={cellPaddingOverrides}>
              <IFrame style={{
                width: "100%",
                height: "70vh",
                border: "none"
              }}>
                {ReactHtmlParser(emailHtml)}
              </IFrame>
            </Cell>}
            {tab == "Push Notification" && <Cell span={12} overrides={cellPaddingOverrides}>
              <PushPreview feed={feed}></PushPreview>
            </Cell>}
            {tab == "Article" && <Cell span={12} overrides={cellPaddingOverrides}>
              <ArticlePreview feed={feed}></ArticlePreview>
            </Cell>}
            {tab == "Popup" && <Cell span={12} overrides={cellPaddingOverrides}>
              {/* <ArticlePreview feed={feed}></ArticlePreview> */}
              <PopupPreview feed={feed} />
            </Cell>}
          </ModalBody>
        </Modal>
      </Cell>
    </Grid>
  )
}


export default PreviewModal;