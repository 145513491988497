const crypto = require('crypto')

export const getExtension = (filename) => {
    var parts = filename.split('.');
    return parts[parts.length - 1];
}

export const UpdateFileExtension = (filename, updatedExtension='mp4') => {
    const extension = getExtension(filename);
    return filename.replace(extension, updatedExtension)
}
export const getResourceType = (filename) => {
    if (isVideo(filename)) return 'video'
    if (isImage(filename)) return 'image'
    return 'raw'
}
  
export const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'png':
        //etc
        return true;
    }
    return false;
}

export const signKey = (clientKey, msg) => {
    return crypto.createHmac('sha256', clientKey).update(msg).digest('hex');
}
  
export const isVideo = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'mkv':
      case 'mov':
      case 'flv':
      case '3g2':
      case '3gp':
      case 'wmv':
      case 'mpeg':
      case 'webm':
      case 'ogv':
      case 'mxf':
      case 'mpd':
        // etc
        return true;
    }
    return false;
}
