import * as React from 'react';
import PhoneDesign from './phone-design';
import {
  ParagraphSmall,
  ParagraphLarge,
  LabelXSmall
} from 'baseui/typography';
import { ArrowLeft } from 'baseui/icon';

export default ({ image_src, full_bleed, title, sub_title, color ='white', cardCoverPreview }) => {
  return <PhoneDesign title="Card Front Preview" helpText="Preview of front cover" cardCoverPreview={cardCoverPreview} imageSrc={image_src} full_bleed={full_bleed}>
    <div style={{
      background: 'black',
      width: '100%',
      height: '100%',
      position: 'relative',
    }}>
      <div style={{ display: 'flex', padding: '16px' }}>
        <ArrowLeft size={32} style={{ color }} />
        <LabelXSmall style={{ color, lineHeight: '30px', textTransform: 'uppercase' }}>DECK</LabelXSmall>
      </div>
      <div style={
        {
          position: 'absolute',
          background: 'white',
          top: '64px',
          bottom: '64px',
          left: '28px',
          right: '28px',
          borderRadius: '8px',
          overflow: 'hidden'
        }
      }>
        <img src={image_src}
          style={{
            height: '350px',
            width: '100%',
            objectFit: full_bleed ? 'cover' : 'contain',
            position: 'relative',
            // zIndex: -2,
            opacity: 1,
            marginBottom: '8px'
          }}
        ></img>
        <ParagraphLarge style={{ fontSize: '30px', marginTop: 0, marginLeft: '8px', lineHeight: '33px', marginBottom: '14px' }}>{title}</ParagraphLarge>
        <ParagraphSmall style={{ marginLeft: '16px', color: 'rgba(0, 0, 0, 0.596581)', lineHeight: '18px', position: 'absolute', bottom: '12px', marginBottom: '0px' }}>{sub_title}</ParagraphSmall>
      </div>
      <div style={
        {
          position: 'absolute',
          background: 'white',
          top: '82px',
          bottom: '82px',
          width: '48px',
          right: '-38px',
          borderRadius: '8px',
          overflow: 'hidden'
        }
      }>
        <img src={image_src}
          style={{
            height: '350px',
            width: '100%',
            objectFit: full_bleed ? 'cover' : 'contain',
            position: 'relative',
            // zIndex: -2,
            opacity: 1,
          }}
        ></img>
        <ParagraphLarge style={{ fontSize: '30px', marginTop: full_bleed ? '20px' : 0, marginLeft: '16px', lineHeight: '33px', marginBottom: '14px' }}>{title}</ParagraphLarge>
        <ParagraphSmall style={{ marginLeft: '16px', color: 'rgba(0, 0, 0, 0.596581)', lineHeight: '18px', position: 'absolute', bottom: '12px', marginBottom: '0px' }}>{sub_title}</ParagraphSmall>
      </div>
    </div>
  </PhoneDesign>
};