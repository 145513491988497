import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import BaseClass from '../../builder/cardBackEditorComponents/base';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Textarea } from "baseui/textarea";
import { Input } from "baseui/input";
import { StatefulPopover } from "baseui/popover";
import { Button } from "baseui/button";
import { StatefulMenu } from "baseui/menu";
import Plus from 'baseui/icon/plus';
import { LabelXSmall } from 'baseui/typography';
import PortalAwareItem from '../../home-components/portal';
import states from '../../../../data/states.json';
import styled from '@emotion/styled';

const HeaderTitle = styled.h5`
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px'
`;
const CONTACT_METHODS = [
  {
    contact_type: 'Phone',
    fields: [
      {
        key: 'phone_title',
        label: 'Name / Department Title',
        inputType: 'Input',
      },
      {
        key: 'phone_type',
        label: 'Phone Type',
        inputType: 'Select',
        options: [{ id: 'Office', label: 'Office' }, { id: 'Cell', label: 'Cell' }]
      },
      {
        key: 'phone_number',
        label: 'Phone Number',
        inputType: 'Input'
      },
      {
        key: 'phone_ext',
        label: 'Ext',
        inputType: 'Input'
      }
    ]
  },
  {
    contact_type: 'Email',
    fields: [
      {
        key: 'email_address',
        label: 'Email Address',
        inputType: 'Input'
      }
    ]
  },
  {
    contact_type: 'Website',
    fields: [
      {
        key: 'website_title',
        label: 'Website Title',
        inputType: 'Input'
      },
      {
        key: 'website_url',
        label: 'Website URL',
        inputType: 'Input'
      }
    ]
  },
  {
    contact_type: 'Address',
    fields: [
      {
        key: 'address_title',
        label: 'Address Title',
        inputType: 'Input'
      },
      {
        key: 'address_address',
        label: 'Address',
        inputType: 'Textarea'
      },
      {
        key: 'address_unit',
        label: 'Unit/Suite Number',
        inputType: 'Input'
      },
      {
        key: 'address_city',
        label: 'City',
        inputType: 'Input'
      },
      {
        key: 'address_state',
        label: 'State',
        inputType: 'Select',
        selectType: TYPE.search,
        options: states.map((el) => {
          return { label : el.name, id: el.name}
        })
      },
      {
        key: 'address_zip',
        label: 'Zip',
        inputType: 'Input'
      }
    ]
  },
  {
    contact_type: 'Notes',
    fields: [
      {
        key: 'contact_notes',
        label: 'Notes',
        inputType: 'Textarea'
      }
    ]
  }
];

const DEFAULT_OBJ = {
  contact_type: '',
  phone_title: '',
  phone_type: '',
  phone_number: '',
  phone_ext: '',
  email_address: '',
  website_title: '',
  website_url: '',
  address_title: '',
  address_address: '',
  address_unit: '',
  address_city: '',
  address_state: '',
  address_zip: '',
  position: 0,
  contact_notes: '',
};

const getContactMethod = (cm) => CONTACT_METHODS.find((el) => el.contact_type == cm.contact_type);
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class SelectComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.setValue = this.setValue.bind(this);
    this.addNewOption = this.addNewOption.bind(this);
    this.up = this.up.bind(this);
    this.down = this.down.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.value
    });
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let items = reorder(
      JSON.parse(JSON.stringify(this.state.value)),
      result.source.index,
      result.destination.index
    );
    items = items.map((el, i) => {
      el.position = i;
      return el;
    });
    this.setState({
      value: items
    });
    this.props.onChange(items);
  }

  up(index) {
    if (index == 0) return;
    let items = reorder(
      JSON.parse(JSON.stringify(this.state.value)),
      index,
      index - 1
    );
    items = items.map((el, i) => {
      el.position = i;
      return el;
    });
    this.setState({
      value: items
    });
    this.props.onChange(items);
  }

  down(index) {
    if (index == this.state.value.length) return;
    let items = reorder(
      JSON.parse(JSON.stringify(this.state.value)),
      index,
      index + 1
    );
    items = items.map((el, i) => {
      el.position = i;
      return el;
    });
    this.setState({
      value: items
    });
    this.props.onChange(items);
  }

  delete(index) {
    let v = JSON.parse(JSON.stringify(this.state.value));
    v.splice(index, 1);
    this.setState({
      value: v
    });
    this.props.onChange(v);
  }

  setValue(index, key, value) {
    let v = JSON.parse(JSON.stringify(this.state.value));
    v[index][key] = value;
    this.setState({ value: v });
    this.props.onChange(v);
  }

  addNewOption(type) {
    let o = JSON.parse(JSON.stringify(DEFAULT_OBJ));
    o.position = this.state.value.length;
    o.contact_type = type;
    let v = JSON.parse(JSON.stringify(this.state.value));
    v.push(o);
    this.setState({ value: v });
    this.props.onChange(v);
  };

  render() {
    var value = this.state.value || [];
    value = value.sort((a, b) => a.position - b.position).filter((el) => el.contact_type);
    const setValue = this.setValue;
    return (
      <>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="cm-droppable"
              >
                {value.map((item, index) => (
                  <Draggable key={item.position} draggableId={`DRAG-${item.position}`} index={index}>
                    {(provided, snapshot) => (
                      <PortalAwareItem snapshot={snapshot}
                        child={
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              padding: '8px',
                              paddingTop: '16px',
                              paddingBottom: '16px',
                              marginTop: '32px',
                              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                              background: 'rgb(246, 246, 246, 0.5)',
                              borderRadius: '2px'
                            }}
                          >
                            <div {...provided.dragHandleProps}>
                              <LabelXSmall> {`${item.contact_type} Info`}</LabelXSmall>
                              <div style={{ display: 'flex', float: 'right' }}>
                                <svg
                                  width="16"
                                  height="10"
                                  viewBox="0 0 16 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ cursor: 'pointer', marginRight: '16px', marginTop: '4px' }}
                                  onClick={() => this.down(index)}
                                >
                                  <path
                                    d="M14.12 7.8932e-07L8 6.18084L1.88 -2.80735e-07L7.07877e-07 1.90283L8 10L16 1.90283L14.12 7.8932e-07Z"
                                    fill={this.props.theme.colors.primaryA}
                                  />
                                </svg>
                                <svg
                                  width="18"
                                  height="11"
                                  viewBox="0 0 18 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ cursor: 'pointer', marginRight: '16px', marginTop: '4px' }}
                                  onClick={() => this.up(index)}
                                >
                                  <path
                                    d="M2.115 11L9 4.20108L15.885 11L18 8.90688L9 0L0 8.90688L2.115 11Z"
                                    fill={this.props.theme.colors.primaryA}
                                  />
                                </svg>
                                <svg
                                  width="14"
                                  height="18"
                                  viewBox="0 0 14 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ cursor: 'pointer', marginRight: '16px' }}
                                  onClick={() => this.delete(index)}
                                >
                                  <path
                                    d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
                                    fill={this.props.theme.colors.primaryA}
                                  />
                                </svg>
                              </div>
                            </div>
                            <div key={index} style={{ marginTop: '16px' }}>
                              {getContactMethod(item).fields.map((field, index1) => {
                                switch (field.inputType) {
                                  case 'Textarea':
                                    return <div style={{ marginBottom: '16px' }}>
                                      <HeaderTitle
                                        style={{
                                          color: this.props.theme.colors.primaryA,
                                          marginBottom: '16px',
                                        }}
                                      >
                                        {field.label}
                                      </HeaderTitle>
                                      <Textarea
                                        value={item[field.key]}
                                        onChange={e => setValue(index, field.key, e.target.value)}
                                        placeholder={`Enter ${field.label}`}
                                        clearOnEscape
                                      /></div>
                                  case 'Input':
                                    return <div style={{ marginBottom: '16px' }}>
                                      <HeaderTitle
                                        style={{
                                          color: this.props.theme.colors.primaryA,
                                          marginBottom: '16px',
                                        }}
                                      >
                                        {field.label}
                                      </HeaderTitle>
                                      <Input
                                        value={item[field.key]}
                                        onChange={e => setValue(index, field.key, e.target.value)}
                                        placeholder={`Enter ${field.label}`}
                                        clearOnEscape
                                      /></div>
                                  case 'Select':
                                    return <div style={{ marginBottom: '16px' }}>
                                      <h5
                                        style={{
                                          color: this.props.theme.colors.primaryA,
                                          marginBottom: '16px',
                                        }}
                                      >
                                        {field.label}
                                      </h5>
                                      <Select
                                        options={field.options}
                                        value={field.options.filter((el) => el.id == item[field.key])}
                                        placeholder={`Select ${field.label}`}
                                        type={field.selectType}
                                        onChange={params => {
                                          if (params.option) {
                                            let v = params.option.id;
                                            setValue(index, field.key, v)
                                          }
                                        }}
                                      /></div>
                                }
                              })}
                              <hr style={{ height: '2px', margin: 0, marginTop: '22px', background: '#E2E2E2' }} />
                              <div style={{ marginTop: '16px' }}>
                                <Select
                                  options={CONTACT_METHODS.map((el) => {
                                    return { label: el.contact_type, id: el.contact_type }
                                  })}
                                  value={[{ label: item.contact_type, id: item.contact_type }]}
                                  placeholder="Select Method"
                                  onChange={params => {
                                    if (params.option) {
                                      setValue(index, 'contact_type', params.option.id)
                                    };
                                  }}
                                /></div>
                            </div>
                          </div>
                        }
                      ></PortalAwareItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {/* add new method */}
          <div style={{ marginTop: '32px' }}>
            <StatefulPopover
              content={() => (
                <StatefulMenu
                  items={CONTACT_METHODS.map((el) => {
                    return { label: el.contact_type, id: el.contact_type }
                  })}
                  onItemSelect={({ item }) => {
                    this.addNewOption(item.id);
                  }}
                />
              )}
              returnFocus
              autoFocus
            >
              <Button startEnhancer={() => <Plus size={24} />}>Add Contact Method</Button>
            </StatefulPopover>
          </div>
        </DragDropContext>
      </>
    );
  }
}

export default SelectComponent;
