import * as React from 'react';
import modules from '.';

const ModuleRenderer = ({ type, isHidden, ...props }) => {
  // console.log('ModuleRenderer -------', { type, props });
  if (!modules?.[type]?.['home_component'] || isHidden) {
    return null;
  }
  return modules[type]['home_component'](props);
}

export default ModuleRenderer;