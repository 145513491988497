import * as React from 'react';
import { PickerDropPane, client } from 'filestack-react';
import { useMutation, } from '@apollo/client';
import gql from 'graphql-tag';
import { useStyletron } from 'baseui';
import ConfirmModal from './ConfirmModal';
import styled from '@emotion/styled';
import Button from '../../common/button';
import { Plus } from 'baseui/icon';
import HelpQuestionMark from '../../help/questionMarkHelp';

const FileStackContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export default (props) => {
    const { onSubmit, organization_id, refetch } = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [response, setFileStackResponse] = React.useState(null);
    const [error, setError] = React.useState('');
    const [css, theme] = useStyletron();

    const Title = styled.div`
        width: 100%;
        height: 100%;
        font-size: 20px;
        color: ${theme.colors.accent};
        display: flex;
        align-items: center;
    `;

    const [createFilestackLibrary, { loading, data: res }] = useMutation(
        gql`
            mutation createFilestackLibrary(
                $url: String
                $type: String
                $metadata: String
                $organization_id: ID
            ) {
                createFilestackLibrary(url: $url, type: $type, metadata: $metadata, organization_id: $organization_id) {
                    url
                    type
                    metadata
                    organization_id
                }
            }
        `
      );

      React.useEffect(() => {
        if (res) {
            refetch && refetch();
        }
      }, [res])
    
      const onSave = (url, type,metadata) => {
        setIsLoading(true);
        createFilestackLibrary({
            variables: {
                url,
                type,
                metadata,
                organization_id,
            }
          });
      };

    const fileUpload = async files => {
        try {
            const {api_key} = props.credential;
            let resFiles = [];
            files.map(async file => {
                const filestack = await client.init(api_key) 
                const res = await filestack.upload(file);
                resFiles.push(res);
                setFileStackResponse(resFiles[0]);
                setIsOpen(true);
            })
            return resFiles;
        } catch (error) {
            alert(error.toString())
        }
    }

    const onUploadDone = (res) => {
        setFileStackResponse(res);
        setIsOpen(true);
        if (res?.filesUploaded) {
            res.filesUploaded.map(file => {
                onSave(file.url, file.mimetype, JSON.stringify(file));
            });
        }
        if (onSubmit) {
            onSubmit(res);
        }
    };
    const onClose = () => {
        setIsOpen(false);
    };
    
    const openPicker = async () => {
        const options = {
            fromSources: [ 'local_file_system', 'googledrive', 'unsplash', 'facebook', 'instagram'],
            maxFiles: 20,
            uploadInBackground: false,
            onUploadDone: onUploadDone,
        };
        const {api_key} = props.credential;
        if (!api_key) return alert('Invalid filestack credential.')
        const filestack = await client.init(api_key)
        const picker = await filestack.picker(options);
        picker.open();
    };

    return (
        <FileStackContainer>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button
                    border_property={"true"}
                    onClick={openPicker}
                    text='Add files'
                    style={{ textTransform: 'capitalize',  }}
                    startEnhancer={() => (
                        <Plus
                        size={16}
                        overrides={{
                            Svg: {
                            style: {
                                backgroundColor: 'white',
                                color: '#5D64AB',
                                borderRadius: '2px',
                            },
                            },
                        }}
                        />
                    )}
                    endEnhancer={() => (
                        <HelpQuestionMark text={`add new image/video files to the FileStack`} button />
                    )}
                />
            </div>
            <ConfirmModal
                isOpen={isOpen}
                url={response?.filesUploaded ? response?.filesUploaded[0]?.url : response?.url}
                name={response?.filesUploaded ? response?.filesUploaded[0]?.filename : response?.filename}
                onClose={onClose}
            />
        </FileStackContainer>
    );
};


