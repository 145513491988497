import React, { useEffect, useState } from 'react';
import { ModalButton } from 'baseui/modal';
import { Drawer } from "baseui/drawer";
import { toaster } from 'baseui/toast';
import { client } from '../../apollo/client';
import { useStyletron } from 'baseui';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { HeadingSmall, ParagraphMedium } from 'baseui/typography';
import { Spinner } from 'baseui/spinner';
import { allChannelTemplateByOrg, importChannelTemplate } from '../../quries';

const ImportTemplateChannelModal = (props) => {
  const { isVisible = false, onClose = () => { },onRefresh, channel_id, organization_id, onImportTemplatesSuccess } = props || {};
  const [templateChannelList, setTemplateChannelList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [css] = useStyletron();
  const [isImportProgress, setIsImportProgress] = useState(false);

  useEffect(() => {
    if (isVisible) {
      loadTemplates();
    }
  }, [isVisible]);

  const loadTemplates = () => {
    setIsLoading(true);
    client.query({
      query: allChannelTemplateByOrg,
      variables: {
        organization_id
      },
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      const { all_channel_template_by_org = [] } = data || {};
      setTemplateChannelList(all_channel_template_by_org);
    }).finally(() => setIsLoading(false))
  }

  const showToaster = (msg, method = 'info') => {
    let toastKey = toaster[method](
      <>
        {msg}
      </>,
      {
        overrides: {
          InnerContainer: {
            style: { width: "100%" }
          }
        }
      }
    );
    setTimeout(() => { toaster.clear(toastKey) }, 3000);
  };

  const onCloseModal = () => {
    onClose();
    setTemplateChannelList([]);
    setSelectedTemplateId();
  }

  const importTemplates = async () => {
    if (isImportProgress) return
    try {
      setIsImportProgress(true);
      client.mutate({
        mutation: importChannelTemplate,
        variables: {
          id: selectedTemplateId,
          channel_id, 
          organization_id
        }
      }).then(async() => {
        if (onRefresh) {
          await onRefresh();
        }
        onImportTemplatesSuccess();
        setIsImportProgress(false);
        onCloseModal();
      })
    } catch (err) {
      console.log(err)
      showToaster(JSON.stringify(err), 'negative')
    }
  }

  return (<Drawer
    isOpen={isVisible}
    autoFocus
    onClose={onCloseModal}
    overrides={{
      DrawerBody: {
        style: () => ({
          marginLeft: '20px',
        })
      },
    }}>
    <HeadingSmall style={{
      textAlign: 'center'
    }}
    >Import Channel Templates
    </HeadingSmall>
    {
      isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '10px'
          }}>
          <Spinner size={24} />
        </div>
      )
    }

    <div
      style={{
        paddingLeft: '15px',
        paddingTop: '15px'
      }}>
      {templateChannelList.map((templateItem) => {
        const { id, name } = templateItem;
        const isSelected = selectedTemplateId == id;
        return (<Checkbox
          checked={isSelected}
          onChange={() => setSelectedTemplateId(isSelected ? undefined : id)}
          labelPlacement={LABEL_PLACEMENT.right}>
          <ParagraphMedium marginBottom='10px' paddingRight='14px'>{name}</ParagraphMedium>
        </Checkbox>
        )
      })}
    </div>

    <div
      style={{
        display: 'flex',
        position: 'absolute',
        minHeight: '50px',
        paddingBottom: '10px',
        paddingRight: '20px',
        paddingLeft: '20px',
        bottom: 0,
        right: 0,
        width: '100%',
        flexDirection: 'column'
      }}>
      <div
        className={css({
          justifyContent: 'flex-end',
          display: 'flex'
        })}
      >
        <ModalButton
          onClick={onCloseModal}>
          Cancel
        </ModalButton>

        <ModalButton
          isLoading={isImportProgress}
          onClick={importTemplates}>
          Import
        </ModalButton>
      </div>
    </div>
  </Drawer>
  )
}

export default ImportTemplateChannelModal;