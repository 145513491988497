import React, { useState, useEffect } from 'react';
import { isAfter, isBefore } from 'date-fns';
import { useStyletron } from 'baseui';
import { FormControl } from 'baseui/form-control';
import ArrowRight from 'baseui/icon/arrow-right';
import { DatePicker, formatDate } from 'baseui/datepicker';
import { TimePicker } from 'baseui/timepicker';

export default function DateTimeRangePicker({
  values = [], onChange
}) {
  const [css, theme] = useStyletron();
  // const START_DATE = values.s ? new Date(values.s): new Date();
  // const END_DATE = values.e ? new Date(values.e) : new Date();
  const [dates, setDates] = useState([values[0] ? new Date(values[0]) : new Date() || [], values[1] ? new Date(values[1]) : new Date() || []]);
  useEffect(() => {
    console.log('dateTimeRangePicker changed', dates);
    if (dates) {
      onChange([dates[0], ...(dates || [])])
    }
  }, [dates]);


  console.log(values, dates);
  return (
    <div
      className={css({
        [theme.mediaQuery.medium]: {
          display: 'flex',
          alignItems: 'center',
        },
      })}
    >
      <div className={css({ display: 'flex' })}>
        <div
          className={css({
            width: '120px',
            marginRight: theme.sizing.scale300,
          })}
        >
          <FormControl label="Start Date" caption="YYYY/MM/DD">
            <DatePicker
              value={dates}
              onChange={({ date }) => {
                if (date.length == 1 && isAfter(date[0], dates[0])) {
                  setDates([date[0], date[0]]);
                } else if (date.length == 1) {
                  setDates([date[0], dates[1]])
                } else
                  setDates(date)
              }}
              timeSelectStart
              range
              mask="9999/99/99"
              displayValueAtRangeIndex={0}
              placeholder="Start Date"
            />
          </FormControl>
        </div>
        <div
          className={css({
            width: '120px',
            marginRight: theme.sizing.scale300,
          })}
        >
          <FormControl label="Start Time" caption="HH:MM">
            <TimePicker
              value={dates[0]}
              step={60}
              onChange={(time) => {
                if (time) {
                  if (isAfter(time, dates[1])) {
                    setDates([time, time]);
                  } else {
                    setDates([time, dates[1]]);
                  }
                }
              }}
            />
          </FormControl>
        </div>
      </div>
      <div
        className={css({
          display: 'none',
          marginRight: theme.sizing.scale300,
          [theme.mediaQuery.medium]: {
            display: 'block',
          },
        })}
      >
        <ArrowRight size={24} />
      </div>
      <div className={css({ display: 'flex' })}>
        <div
          className={css({
            width: '120px',
            marginRight: theme.sizing.scale300,
          })}
        >
          <FormControl label="End Date" caption="yyyy/MM/DD">
            <DatePicker
              value={dates}
              onChange={({ date }) => {
                if (date.length == 1 && isBefore(date[0], dates[0])) {
                  setDates([date[0], date[0]]);
                } else if (date.length == 1) {
                  setDates([dates[0], date[0]])
                } else {
                  setDates(date)
                }
              }}
              timeSelectEnd
              range
              mask="9999/99/99"
              displayValueAtRangeIndex={1}
              placeholder="End Date"
            />
          </FormControl>
        </div>
        <div
          className={css({
            width: '120px',
          })}
        >
          <FormControl label="End Time" caption="HH:MM">
            <TimePicker
              value={dates[1]}
              onChange={(time) => {
                if (time) {
                  if (isBefore(time, dates[0])) {
                    setDates([time, time]);
                  } else {
                    setDates([dates[0], time]);
                  }
                }
              }}
              step={60}
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
}