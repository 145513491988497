import * as React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from 'baseui/modal';
import { KIND as ButtonKind } from 'baseui/button';
import { Spinner } from "baseui/spinner";
import { LabelMedium } from 'baseui/typography';
import { app_users, get_assigned_users_to_trainer, assign_users_to_trainer } from '../../quries';
import { client } from '../../apollo/client';
import {
  Checkbox,
  STYLE_TYPE,
  LABEL_PLACEMENT
} from "baseui/checkbox";

export default ({
  isOpen, onClose, trainer, organization_id
}) => {

  const [isLoading, setLoading] = React.useState(false);
  const [fetchingData, setFetchingData] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [assigned, setAssigned] = React.useState([]);

  console.log(users, assigned);

  React.useEffect(() => {
    if (!trainer) return;
    if (!isOpen) return;
    setFetchingData(true);
    Promise.all([
      client.query({
        query: app_users(organization_id)
      }).then((response) => {
        setUsers(response.data.app_users_org);
      }),
      client.query({
        query: get_assigned_users_to_trainer(trainer.id),
        fetchPolicy: 'network-only'
      }).then((response) => {
        setAssigned(response.data.fetch_trainer_assigned_users)
      })
    ]).then(() => {
      setFetchingData(false);
    })
  }, [isOpen]);

  const onSave = async () => {
    setLoading(true);
    await client.mutate({
      mutation: assign_users_to_trainer(),
      variables: {
        organization_id, trainer_id: trainer.id,
        users: assigned.map(el => el.app_user_id)
      }
    })
    setLoading(false);
    onClose();
  };

  const isChecked = (u) => {
    let u1 = assigned.find(el => {
      return el.app_user_id == u.id
    });
    return u1 ? true : false;
  }


  const setChecked = (u, status) => {
    let a = JSON.parse(JSON.stringify(assigned));
    if (status) {
      a.push({
        app_user_id: u.id
      })
    } else {
      a = a.filter(el => {
        return el.app_user_id != u.id;
      })
    };
    setAssigned(a);
  }

  return <>
    <Modal
      onClose={() => onClose()}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Dialog: {
          style: {
            width: '70vw',
            display: 'flex',
            flexDirection: 'column',
          },
        },
        Backdrop: {
          style: ({ $theme }) => ({
            backdropFilter: $theme.modalBackDropFilter,
          }),
        },
        Root: {
          style: {
            zIndex: 100
          }
        }
      }}
    >
      <ModalHeader>Assign Users</ModalHeader>
      <ModalBody>
        <div>
          {fetchingData ? <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}>
            <Spinner />
            <LabelMedium style={{ marginTop: '16px' }}>Loading Users</LabelMedium>
          </div> : <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}>
              {users.map((u, index) => {
                return <div key={index} style={{ width: '50%', marginBottom: '16px'}}>
                  <Checkbox
                    checked={isChecked(u)}
                    checkmarkType={STYLE_TYPE.toggle}
                    onChange={e => {
                      setChecked(u, e.target.checked)
                    }}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    {u.name.trim() || u.email}
                  </Checkbox>
                </div>
              })}
            </div>}
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={() => onClose()}
          kind={ButtonKind.tertiary}
        >
          Cancel
          </ModalButton>
        <ModalButton
          onClick={() => onSave()}
          kind={ButtonKind.primary}
          isLoading={isLoading}
          style={{ textTransform: 'capitalize' }}
        >
          Save
        </ModalButton>
      </ModalFooter>
    </Modal>
  </>
}