import * as React from 'react';
import { Select, TYPE } from 'baseui/select';

export default ({
  selectedCardId,
  onCardSelect,
  title,
  theme,
  marginTop,
  placeholder,
  cardOptions
}) => {

  let matchingOption = cardOptions.filter((el) => el.id == selectedCardId);
  // console.log(cardOptions.length);
  // console.log(JSON.stringify(cardOptions));

  return (
    <>
      {title ? <h5
        style={{
          color: theme.colors.primaryA,
          marginTop: marginTop || theme.sizing.scale1200,
        }}
      >
        {title}
      </h5> : null}
      <Select
        options={cardOptions}
        onChange={({ option }) => {
          if (option) onCardSelect(option.id)
          else onCardSelect(null)
        }}
        value={matchingOption}
        placeholder={selectedCardId ? '' : placeholder}
        multi={false}
        labelKey={'title'}
        valueKey={'id'}
        maxDropdownHeight="300px"
        type={TYPE.search}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
      />
    </>
  )
}
