import * as React from 'react';
import ModuleRenderer from './module_renderer';
import { Cell } from 'baseui/layout-grid';
import { getFilesUrls } from '../../util';
import locale from 'react-json-editor-ajrm/locale/en';

const getModuleProps = (item, state, onChangeValue, setStateInternal, root, isAddHomeComponent, onSave) => {
  const { title, placeholder, type, key, typeToPick, mutation, isVisible, visibilityParameter, HideInAdd, extraStateKeyToUpdate, span, ...rest } = item;

  let isHidden = false;

  let {
    inputs
  } =  root;

  let updatedRoot = { ...root};

  let updatedInputs = inputs.map(input => {
    return ({
      ...input,
      value: state[input.key] ? state[input.key] : input.value
    })
  })

  updatedRoot = {
    ...updatedRoot,
    inputs: updatedInputs
  }

  if (isVisible) {
    const visibilityVal = state[visibilityParameter]
    if (isVisible(visibilityVal) === false) {
      isHidden = true
    }
  } else if (HideInAdd && isAddHomeComponent) {
    isHidden = true;
  }

  let dataProps = {
    ...rest,
    title,
    onChange: (v) => {
      onChangeValue(key, v);
    },
    value: state[key],
    placeholder,
    type,
    isHidden,
    maxLength: item.maxLength,
    root: updatedRoot
    // [key]: state[key]
  }

  if (extraStateKeyToUpdate && extraStateKeyToUpdate.length > 0) {
    dataProps['values'] = extraStateKeyToUpdate.map(key => {
      return state[key]
    })
  }

  if (type == 'Input') {
    dataProps = {
      ...dataProps,
      onChange: e => onChangeValue(key, e.target.value),
      clearOnEscape: true
    }
  } else if (type == 'Textarea') {
    dataProps = {
      ...dataProps,
      textAreaKey: key
    }
  } else if (type == 'ActionMetadataInput') {
    dataProps = {
      ...dataProps,
      action_type: state['button_action_type'],
      action_resource: state['button_action_metadata'],

    }
  } else if (type == 'ColorPicker') {
    dataProps = {
      ...dataProps,
      color: state[key]
    }
  } else if (type === 'FilePicker') {
    dataProps = {
      ...dataProps,
      // title: '',
      titleHelpText: '',
      onDrop: (acceptedFiles, rejectedFiles) => {
        onChangeValue(
          mutation ? mutation.key : key, acceptedFiles
        );
      },
      previewUrls: mutation ? (getFilesUrls(state[mutation.key]) || getFilesUrls(state[key])) : getFilesUrls(state[key])
    }
  } else if (type === 'CardPicker') {
    dataProps = {
      ...dataProps,
      selectedCardId: state[key],
      onCardSelect: (id) => {
        onChangeValue(key, id)
      },
      marginTop: '0px',
      placeholder: 'Select Card'
    }
  } else if (type == 'Select') {
    dataProps = {
      ...item,
      ...dataProps,
      overrides: {
        Popover: {
          props: {
            overrides: {
              Body: { style: { zIndex: 3 } },
            },
          },
        },
      }
    }
  } else if (type == 'TableColumnPicker') {
    dataProps = {
      ...dataProps,
      google_sheet_integration_id: state['google_sheet_integration_id'],
      onChange: (value) => {
        onChangeValue(key, JSON.stringify(value));
      },
      typeToPick: typeToPick,
      value: state[key] == '' ? [] : typeof state[key] == 'string' ? JSON.parse(state[key]) : state[key]
    }

  } else if (type == 'BaseUiTextArea') {
    dataProps = {
      ...dataProps,
      locale
    }
  } else if (type == 'DateTimeRange') {
    dataProps = {
      ...dataProps,
      onChange: (res) => {
        console.log('date change', res, key, extraStateKeyToUpdate);
        const [d, s, e] = res;
        console.log(d, s, e);
        let data = { ...state };
        data[key] = d;
        data[extraStateKeyToUpdate[0]] = s;
        data[extraStateKeyToUpdate[1]] = e;
        setStateInternal(data);
      }
    }
  } else if (type == 'DateTimePicker') {
    dataProps = {
      ...dataProps,
      value: [state[key] ? new Date(state[key]) : new Date()],
      onChange: ({ date }) => {
        console.log('date change', date, key);
        if (Array.isArray(date)) {
          onChangeValue(key, date[0])
        } else {
          onChangeValue(key, date)
        }

      }
    }
  } else if (type == 'FormInputListDB') {
    dataProps = {
      ...dataProps,
      onSave: (d) => {
        return new Promise((resolve, reject) => {
          console.log(d);
          setStateInternal(d);
          onSave({}, d).then(() => resolve(d));
        })
      },
    }
  }

  return dataProps;
}

const GenericModuleRenderer = ({
  input,
  state,
  theme,
  onChange,
  setStateInternal,
  organization_id,
  isAddHomeComponent,
  onSave,
  root,
  is_card_link_button_css_on,
  __typename,

  ...props
}) => {

  const moduleProps = React.useMemo(() => {
    let tempProp = getModuleProps(input, state, onChange, setStateInternal, root, isAddHomeComponent, onSave)
    return ({
      ...tempProp,
      theme,
      organization_id,
      ...props,
    })
  }, [state,])


  if ((input.key == 'style' || input.key == 'width' || input.key == 'icon') && !is_card_link_button_css_on && __typename == 'CardLinkButtonCardModule') return null;

  return (
    <Cell
      span={[1, input?.span || 12]}
      overrides={{
        Cell: {
          style: () => ({
            paddingLeft: '0px !important',
          }),
        },
      }}
    >
      <ModuleRenderer
        {...moduleProps}
      />
    </Cell>
  )
}

export default GenericModuleRenderer;

