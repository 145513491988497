import * as React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { client } from '../../apollo/client';
import { setCategory } from '../../redux/actions/appBasicControls';

const getSubForCategoryUpdate = id => {
  return gql`
    subscription OnCategoryUpdate ($id: ID!) {
      categoryUpdated(id: $id){
        category {
          name
          archived
          published
          position
          public
          created_by_id
          deck_count
          id
          organization_id
          description
          created_at
          layout
          has_data_source_connected
          api_path_id
        }
        category_app_content_scope {
          id
          category_id
          position
          published
          app_content_scope_id
        }
      }
    }
  `;
};
const getSubForCategoryArchive = id => {
  return gql`
    subscription OnCategoryArchive {
      categoryArchived(id:"${id}"){
        name
        archived
        published
        position
        public
        created_by_id
        deck_count
        id
        organization_id
        description
        created_at
        layout
        has_data_source_connected
        api_path_id
      }
    }
  `;
};

const getSubForCategoryCreate = orgId => {
  return gql`
    subscription OnCategoryCreate {
      categoryCreated(orgId:"${orgId}"){
        category {
          name
          archived
          published
          position
          public
          created_by_id
          deck_count
          id
          organization_id
          description
          created_at
          layout
          has_data_source_connected
          api_path_id
        }
        category_app_content_scope {
          id
          category_id
          position
          published
          app_content_scope_id
          isNew
        }
      }
    }
  `;
};

class SubscribeCategory extends React.Component {
  constructor(props) {
    super(props);
    this.listenForCreate = this.listenForCreate.bind(this);
    this.listenForUpdate = this.listenForUpdate.bind(this);
    this.listenForArchive = this.listenForArchive.bind(this);
    this.addNewCatData = this.addNewCatData.bind(this);
    this.updateCatData = this.updateCatData.bind(this);
    this.archiveCatData = this.archiveCatData.bind(this);

    this.createSub = null;
    this.updateSub = null;
    this.archiveSub = null;
    this.catCreateSubscription = null;
    this.catUpdatesSubscription = null;
    this.catArchiveSubscription = null;
  }

  componentDidMount() {
    console.log('[SUBSCRIBE] CATEGORY CREATE');
    // this.createSub = this.props.subscribeToMore({
    //   document: getSubForCategoryCreate(this.props.orgId),
    //   variables: { orgId: this.props.orgId },
    //   updateQuery: (data1, { subscriptionData }) => {
    //     if (
    //       subscriptionData &&
    //       subscriptionData.data &&
    //       subscriptionData.data.categoryCreated
    //     ) {
    //       let c = JSON.parse(JSON.stringify(this.props.category || []));
    //       data1 = JSON.parse(JSON.stringify(data1));
    //       console.log('[SUBSCRIBE] CATEGORY CREATE NEW EVENT');
    //       c.push({
    //         ...subscriptionData.data.categoryCreated.category_app_content_scope,
    //         category: subscriptionData.data.categoryCreated.category,
    //       });
    //       data1.category_app_content_scopes = c;
    //       return data1;
    //     }
    //   },
    // });
    this.listenForCreate();
    this.listenForUpdate();
    this.listenForArchive();
  }

  UNSAFE_componentWillMount() {
    this.createSub = client.subscribe({
      query: getSubForCategoryCreate(this.props.orgId),
      variables: { orgId: this.props.orgId },
    });
    this.subscribeToUpdate();
    this.subscribeToArchive();
  }


  componentWillUnmount() {
    console.log('[SUBSCRIBE] UNMOUNTED CARD CREATE');
    // this.createSub && this.createSub();
    this.catCreateSubscription && this.catCreateSubscription !== null && this.catCreateSubscription.unsubscribe();
    this.catUpdatesSubscription && this.catUpdatesSubscription !== null && this.catUpdatesSubscription.map(el => el.unsubscribe());
    this.catArchiveSubscription && this.catArchiveSubscription !== null && this.catArchiveSubscription.map(el => el.unsubscribe());

  }

  subscribeToUpdate = () => {
    this.updateSub = this.props.category && this.props.category.map(el => {
      return client.subscribe({
        query: getSubForCategoryUpdate(el.category_id),
        variables: { id: el.category_id },
      });
    });
  }

  subscribeToArchive = () => {
    this.archiveSub = this.props.category && this.props.category.map(el => {
      return client.subscribe({
        query: getSubForCategoryArchive(this.props.orgId),
        variables: { orgId: this.props.orgId },
      });
    });
  }

  addNewCatData = (subscriptionData) => {
    console.log('subscribe-cat create', subscriptionData);
    if (
      subscriptionData &&
      subscriptionData.data &&
      subscriptionData.data.categoryCreated
    ) {
      let c = JSON.parse(JSON.stringify(this.props.category || []));
      console.log('[SUBSCRIBE] CATEGORY CREATE NEW EVENT');
      c.push({
        ...subscriptionData.data.categoryCreated.category_app_content_scope,
        category: subscriptionData.data.categoryCreated.category,
      });
      this.props.dispatch(setCategory(c));
    }
  }


  updateCatData = (subscriptionData) => {
    console.log('subscribe-cat update', { subscriptionData });
    if (
      subscriptionData &&
      subscriptionData.data &&
      subscriptionData.data.categoryUpdated
    ) {

      let { categoryUpdated } = subscriptionData.data;
      let { category = {}, category_app_content_scope ={}} = categoryUpdated || {};
      let c = JSON.parse(JSON.stringify(this.props.category || []));
      let index = c.findIndex(el => (el.category_id == category.id) && (el.app_content_scope_id == category_app_content_scope.app_content_scope_id));
      // console.log('subscribe-cat update', { subscriptionData, index });
      const updateCategory = {
        ...c[index],
        position: category_app_content_scope.position,
        published: category_app_content_scope.published,
        isNew: null,
        category: {
          ...c[index].category,
          ...category,
        },
      };
      c[index] = updateCategory
      this.props.dispatch(setCategory(c));
    }
  }

  archiveCatData = (subscriptionData) => {
    // console.log('subscribe-cat archive', subscriptionData);
    if (
      subscriptionData &&
      subscriptionData.data &&
      subscriptionData.data.categoryArchived
    ) {
      let { categoryArchived } = subscriptionData.data;
      let c = JSON.parse(JSON.stringify(this.props.category || []));
      let index = c.findIndex(el => (el.category_id == categoryArchived.id));
      c[index] = {
        ...c[index],
        position: categoryArchived.position,
        published: categoryArchived.published,
        isNew: null,
        category: {
          ...(c[index]?.category || {}),
          ...categoryArchived,
          // archived: true
        },
      };
      this.props.dispatch(setCategory(c));
    }
  }

  listenForCreate = () => {
    this.catCreateSubscription = this.createSub && this.createSub !== null && this.createSub.subscribe({ next: this.addNewCatData })
  }

  listenForUpdate = () => {
    this.catUpdatesSubscription = this.updateSub && this.updateSub !== null && this.updateSub.map(el => {
      return el.subscribe({ next: this.updateCatData })
    })
  }

  listenForArchive = () => {
    this.catArchiveSubscription = this.archiveSub && this.archiveSub !== null && this.archiveSub.map(el => el.subscribe({ next: this.archiveCatData }));
  }


  render() {
    // 2. category update
    // (this.props.category || []).forEach(el => {
    //   this.props.subscribeToMore({
    //     document: getSubForCategoryUpdate(el.category_id),
    //     variables: { id: el.category_id },
    //     updateQuery: (data1, { subscriptionData }) => {
    //       if (
    //         subscriptionData &&
    //         subscriptionData.data &&
    //         subscriptionData.data.categoryUpdated
    //       ) {
    //         let { categoryUpdated } = subscriptionData.data;
    //         let c = JSON.parse(JSON.stringify(this.props.category || []));
    //         data1 = JSON.parse(JSON.stringify(data1));
    //         let index = c.findIndex(el => el.category_id == categoryUpdated.id);
    //         c[index] = {
    //           ...c[index],
    //           position: categoryUpdated.position,
    //           published: categoryUpdated.published,
    //           isNew: null,
    //           category: {
    //             ...c[index].category,
    //             ...categoryUpdated,
    //           },
    //         };
    //         data1.category_app_content_scopes = c;
    //         return data1;
    //       }
    //     },
    //   });
    //   this.props.subscribeToMore({
    //     document: getSubForCategoryArchive(this.props.orgId),
    //     variables: { orgId: this.props.orgId },
    //     updateQuery: (data1, { subscriptionData }) => {
    //       if (
    //         subscriptionData &&
    //         subscriptionData.data &&
    //         subscriptionData.data.categoryArchived
    //       ) {
    //         let { categoryArchived } = subscriptionData.data;
    //         let c = JSON.parse(JSON.stringify(this.props.category || []));
    //         data1 = JSON.parse(JSON.stringify(data1));
    //         let index = c.findIndex(el => el.category_id == categoryArchived.id);
    //         c[index] = {
    //           ...c[index],
    //           position: categoryArchived.position,
    //           published: categoryArchived.published,
    //           isNew: null,
    //           category: {
    //             ...c[index].category,
    //             ...categoryArchived,
    //             // archived: true
    //           },
    //         };

    //         data1.category_app_content_scopes = c;
    //         return data1;
    //       }
    //     },
    //   });
    // });

    return <></>;
  }
}

const mapStateToProps = props => {
  const { category } = props.appBasicControls;
  return { category };
};

export default connect(mapStateToProps)(SubscribeCategory);