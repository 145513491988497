import React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import { useSelector } from "react-redux";

const ImportConfirmOrgModal = props => {

    const {
        isOpen = false,
        onExport = () => { },
        onClose = () => { },
    } = props || {};

    const appBasicControls = useSelector(state => state.appBasicControls);
    const organizationName = appBasicControls?.organization?.name

    return (
        <Modal
            onClose={onClose}
            closeable
            isOpen={isOpen}
            animate
            autoFocus
            size={SIZE.auto}
            role={ROLE.dialog}>
            <ModalHeader>Where do you plan on importing this content?</ModalHeader>
            <ModalFooter>
                <ModalButton onClick={() => onExport(false)} kind={ButtonKind.tertiary}>
                    Outside this organization?
                </ModalButton>
                <ModalButton onClick={() => onExport(true)} > Within the organization {organizationName}?</ModalButton>
            </ModalFooter>
        </Modal>
    );
}


export default ImportConfirmOrgModal