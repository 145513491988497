import React, { useState, useEffect } from 'react';
import { LabelSmall } from 'baseui/typography';
import { Input } from 'baseui/input';
import CardPicker from './cardPickerCXL';
import { SelectCXL } from '../../builder/cardBackEditorComponents/select';

export default ({ action_type, action_resource, onChange, theme, isEditor, ...props }) => {
  const [action, setAction] = useState({});
  useEffect(() => {
    if (action_resource)
      setAction(action_resource);
  }, [action_resource]);

  const setActionValue = (key, value) => {
    let o = { ...action };
    o[key] = value;
    setAction(o);
    onChange(o);
  };
  const HeaderTextComponent = (...childProps) => {
    const childProp = (childProps || [])[0] || { children: '' }
    if (isEditor) {
      return (
        <span
          style={{
            color: theme.colors.primaryA,
            marginTop: theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {childProp.children}
        </span>
      )
    }
    return (
      <LabelSmall style={{ marginBottom: '16px' }}>
        {childProp.children}
      </LabelSmall>
    )
  }

  return <div style={{ paddingLeft: isEditor ? '0px' : '12px', paddingRight: isEditor ? '0px' : '12px' }}>
    {action_type == 'video' && <>
      <HeaderTextComponent >Video URL</HeaderTextComponent>
      <Input
        value={action['video_url']}
        onChange={e => {
          setActionValue('video_url', e.target.value)
        }}
        placeholder="Video URL"
        clearOnEscape
      />
    </>}
    {action_type == 'image' && <>
      <HeaderTextComponent >Image URL</HeaderTextComponent>
      <Input
        value={action['image_url']}
        onChange={e => {
          setActionValue('image_url', e.target.value)
        }}
        placeholder="Image URL"
        clearOnEscape
      />
    </>}
    {action_type == 'card' && <>
      <HeaderTextComponent >Select Card</HeaderTextComponent>
      <CardPicker
        selectedCardId={action['card_id']}
        onCardSelect={(id) => setActionValue('card_id', id)}
        theme={theme}
        placeholder={'Select Card'}
      />
    </>}
    {action_type == 'link' && <>
      <HeaderTextComponent>External Link</HeaderTextComponent>
      <Input
        value={action['link']}
        onChange={e => {
          setActionValue('link', e.target.value)
        }}
        placeholder="External URL"
        clearOnEscape
      />
    </>}
    {action_type == 'internal_action' && <>
      <HeaderTextComponent >Select Action</HeaderTextComponent>
      <SelectCXL
        options={
          [
            { label: 'Login', id: 'Login' },
            { label: 'Sign Up', id: 'Signup' },
            { label: 'Travel', id: 'Travel' },
            { label: 'Account', id: 'Account' },
            { label: 'Notification', id: 'Notification' },
            { label: 'Chat', id: 'Chat' },
            { label: 'Profile', id: 'ProfileEdit' },
            { label: 'Article', id: 'ArticleStack' },
            { label: 'Map', id: 'Map' },
            { label: 'Event', id: 'Event' },
            { label: 'Channel', id: 'Home' },
          ]
        }
        onChange={(v) => {
          setActionValue('internal_action', v);
        }}
        theme={theme}
        value={action['internal_action']}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
      ></SelectCXL>
    </>}
  </div>;
}