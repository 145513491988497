import * as React from 'react';
import { useStyletron } from 'baseui';
import { setSelectedChannel } from '../../redux/actions/appBasicControls';
// import ChannelsDropDown from '../channel/channelsDropdown';
import ChannelsDropDown from '../channel/bk-channelsDropdownV1'

import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export default ({ channelGroups, organization_id, close, defaultTheme, dispatch }) => {
  const [css, theme] = useStyletron();


  const fadeRightAnimation = keyframes`
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 420px;
  }
`;

const ChannelContainer = styled.div`
  position: relative;
  animation: .1s ${fadeRightAnimation};
  animation-fill-mode: forwards;
  z-index: 3;
  min-width: 420px;
`

  return (
    <>
      <div
        className="channelsDropDown"
        className={css({
          borderRadius: '15px',
        })}
      >
        <ChannelContainer>
            <ChannelsDropDown
              channels={[]}
              channelGroups={channelGroups}
              dispatch={dispatch}
              selectedChannel={null}
              organization_id={organization_id}
              defaultTheme={defaultTheme}
              close={() => {
                console.log('Close override');
              }}
              showChannelsManger={false}
              showAddButtons={false}
              onChannelClick={(c) => {
                setSelectedChannel(c)
              }}
              showAllGroupsButton={false}
            ></ChannelsDropDown>
          </ChannelContainer>
      </div>
    </>
  );
};