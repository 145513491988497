export const admin_invitation_help =
  'Here you can invite Admins to join your app by creating a subject line and writing a short email invitation.';
export const admin_welcome_help =
  'Here you can update email template for user welcome email. Template will be used directly in the email. \n {{username}} text will be replaced with username';
export const app_user_invitation_help =
  'Here you can invite users to join your app by creating a subject line and writing a short user email invitation.';
export const color_palette_help =
  'Here you can choose from available colors or add custom Hex or RGB brand colors to your color palette.';
export const settings_logo_help =
  'Here you can add your logo to the settings. Your logo will appear in several places in the app.';
export const settings_video_cover_help =
  'Here you can add your video cover to the settings. Your video cover will appear in several places in the app.';
export const category_help =
  'Here you can create a new Category by typing in the name and either hitting return or using the submit button.';
export const organization_help =

  'Here you can create a new Organization.';
export const component_help =
  'Here you can create a new Component by typing in the name and either hitting return or using the submit button.';
export const channel_help =
  'Here you can add a Channel, the second highest level of app content. Channels let you organize information for specific groups of users, such as employees, partners, or customers.';
export const deck_help =
  'Here you can add a new Deck. Decks hold individual “flashcard” style cards and typically cover a single topic.';
export const slide_help =
  'Here you can add a new Slide. Slide hold individual “flashcard” style cards and typically cover a single topic.';
export const card_help =
  'Here you can add a new Card. A card has a front and back, and covers a single topic. The back of cards deliver rich content.';
export const channel_group_help =
  'Here you can add the highest level of content via Channel Groups. Channel Groups house Channels.';
export const email_sender_configuration = 'Here you can set sender of email messages';
export const login_btn_help =
  'Here you can change login style by changing background color, image and button background color , text color.';
  export const import_card_help =
  'Here you can import existing card. A card has a front and back, and covers a single topic. The back of cards deliver rich content.';
  export const import_form_help =
  'Here you can import existing form. A form details, input fields and covers a single topic.';
export const app_installations_help = "Home many users installed app";
export const app_updated_help = "How many users updated app";
export const total_users_help = "Total number of users used app till now";
export const total_sessions_help = "Total number of sessions user used app";
export const active_users_chart_help = "Total number of Users active";
export const active_sessions_chart_help = "Total number of sessions made by user on app";
export const app_installations_graph_help = "Total Number of users installed app";
export const app_sessions_graph_help = "Total number of sessions created by user";
export const app_updated_graph_help = "Total number of users updated app";
export const channel_selected_graph_help = "Number of user opened channel";
export const card_front_view_graph_help = "Number of user view card front";
export const card_back_view_graph_help = "Number of user view card front";
export const screens_graph_help = "screens_graph_help";
export const top_channels_graph_help = "top_channels_graph_help";
export const top_cards_graph_help = "top_cards_graph_help";
export const version_analysis_graph_help = "version_analysis_graph_help";
export const platform_analysis_graph_help = "platform_analysis_graph_help";
export const top_users_graph_help = "top_users_graph_help";
export const top_categories_graph_help = "top_categories_graph_help";
export const quizzes_help = 'quizzes_help';
export const top_performing_users_help = 'top_performing_users_help';
export const top_quiz_decks_help = 'top_quiz_decks_help';
export const quizzes_by_time_help = 'quizzes_by_time_help';
export const users_count_quiz_decks_help = 'users_count_quiz_decks_help';
export const confirmation_text_help ='Here you can you can add a text which will be use as confirmation text ';

