/**
 * THIS IS OLD VERSION OF ADD COMMUNICATION IN MODAL
 */

import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import {
  H6,
  Paragraph2,
  HeadingLarge
} from 'baseui/typography';
import { StatefulPopover } from "baseui/popover";
import { Tag, VARIANT as TAG_VARIANT } from 'baseui/tag';
import { useStyletron } from 'baseui';
import { FileUploader } from 'baseui/file-uploader';
import Builder from '../builder/builder';
import { editorBtnOptions } from '../../../data/cardBuilderOptions';
import { Select, TYPE } from 'baseui/select';
import store from '../../redux/store';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { saveCommunicationBackData } from '../builder/builderUtils/save'
import {
  ProgressSteps,
  Step
} from "baseui/progress-steps";
import { ButtonGroup } from "baseui/button-group";
import { Button } from "baseui/button";
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { Input, StyledInput } from 'baseui/input';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { setCardBackData } from '../../redux/actions/appBasicControls';

const defaultOptions = {
  isOpen: false,
  isPushNotificationChecked: false,
  isArticleChecked: false,
  isEmailChecked: false,
  isSendToEntireOrg: false,
  isSentToSpefic: false,
};

const innerGridOverride = {
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

const outerGridOverride = {
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

const modalCss = {
  width: '90vw',
  // height: '90vh',
  display: 'flex',
  flexDirection: 'column',
};

const overrideCellCss = {
  Cell: {
    style: ({ $theme }) => ({
      // marginBottom: '20px !important',
      // boxShadow: $theme.lighting.shadow400,
      // paddingTop: '50px !important',
      // paddingBottom: '50px !important'
    }),
  },
};

const overrideParentCellCss = {
  Cell: {
    style: ({ $theme }) => ({
      marginBottom: '20px !important',
    }),
  },
};

export default ({ isOpen, onClose, channels, organization_id }) => {
  const [options, setOptions] = React.useState({
    ...defaultOptions,
    isOpen: isOpen,
  });
  const [current, setCurrent] = React.useState(0);

  const toggleModal = val => {
    setOptions({
      ...options,
      isOpen: val,
    });
    onClose();
  };

  // CHECKBOXES
  const toggleProperty = propert => {
    setOptions({
      ...options,
      [propert]: options[propert] === true ? false : true,
    });
  };

  const [isLoading, setIsLoading] = React.useState(false);

  // TAG RELATED
  const [value, setValue] = React.useState('');
  const [tags, setTags] = React.useState([]);
  const addTag = tag => {
    setTags([...tags, tag]);
  };
  const removeTag = tag => {
    setTags(tags.filter(t => t !== tag));
  };
  const handleKeyDown = event => {
    switch (event.keyCode) {
      // Enter
      case 13: {
        if (!value) return;
        addTag(value);
        setValue('');
        return;
      }
      // Backspace
      case 8: {
        if (value || !tags.length) return;
        removeTag(tags[tags.length - 1]);
        return;
      }
    }
  };
  // END TAG RELATED

  const [channelsSelected, setSelectedChannels] = React.useState([]);
  const [messageTitle, setMessageTitle] = React.useState('');
  const [pushNotificationMessage, setPushNotificationMessage] = React.useState(
    ''
  );
  const [frontImage, setFrontImage] = React.useState(null);
  const [preivew_image, setPreview] = React.useState(null);
  const previewImage = file => {
    setPreview(URL.createObjectURL(file));
  };

  const [cardBackData, setCardBackDataState] = React.useState(null);
  console.log(cardBackData);
  console.log(channels);
  const getUsersMessage = () => {
    if (options.isSendToEntireOrg) {
      return 'all users of entire organization'
    } else {
      return `all users of ${channelsSelected.length} ${channelsSelected.length == 1 ? 'channel' : 'channels'}`
    }
  }

  const [addCommunication, { loading, data, error }] = useMutation(gql`
    mutation addCommunication($input: CommunicationInput) {
      addCommunication(input: $input) {
        id
        title
        front_image_data
        message_body
      }
    }
  `);



  const [addCommunicationToChannel, { data: addtoChannel }] = useMutation(gql`
    mutation addCommunicationToAppContent(
      $input: [CommunicationAppContentScopeInput]
    ) {
      addCommunicationToAppContent(input: $input) {
        id
      }
    }
  `);

  const onSave = () => {
    setIsLoading(true);
    addCommunication({
      variables: {
        input: {
          type_push_notification: options.isPushNotificationChecked,
          type_news: options.isArticleChecked,
          type_email: options.isEmailChecked,
          type_popup: options.isPopupChecked,
          title: messageTitle,
          front_image: frontImage,
          tags: tags,
          organization_id,
          message_body: pushNotificationMessage,
        },
      },
    }).catch((error) => {
      setIsLoading(false);
    })
  };

  React.useEffect(() => {
    if (data) {
      let communicationId = data.addCommunication.id;
      // now add it to the channels
      let selected = JSON.parse(JSON.stringify(channelsSelected));
      if (options.isSendToEntireOrg) {
        selected = [...channelsSelected, ...channels]
      }
      // process card content modules
      saveCommunicationBackData(editorBtnOptions, communicationId, organization_id, cardBackData)
      
        .then(() => {
          debugger
          addCommunicationToChannel({
            variables: {
              input: selected.map((ch) => {
                return {
                  organization_id,
                  communication_id: communicationId,
                  app_content_scope_id: ch.id,
                }
              })
            },
          });
        });
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  React.useEffect(() => {
    if (addtoChannel) {
      toggleModal(false);
    }
  }, [addtoChannel]);

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Root: {
            style: {
              zIndex: 4,
            }
          },
          Dialog: {
            style: modalCss,
          },
        }}
        onClose={() => toggleModal(false)}
        isOpen={options.isOpen}
      >
        <ModalHeader>New Communication</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <ProgressSteps current={current}>
            <Step title="Connect Content Overview">
              <div style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                <Grid
                  gridGaps={[2, 6, 64]}
                  gridMaxWidth={'unset'}
                  overrides={{
                    Grid: {
                      style: ({ $theme }) => outerGridOverride,
                    },
                  }}
                >
                  <Cell span={[1, 6]} overrides={overrideParentCellCss}>
                    <Grid
                      gridGaps={[2, 6, 64]}
                      gridMaxWidth={'unset'}
                      overrides={{
                        Grid: {
                          style: ({ $theme }) => outerGridOverride,
                        },
                      }}
                    >
                      <Cell span={[1, 12]} overrides={overrideCellCss}>
                        <H6>Select Message Type</H6>
                        <Paragraph2>
                          This is the method in which your message is delivered.
                          Choose one or more types that matches best for your
                          audience.
                  </Paragraph2>
                        <Grid
                          overrides={{
                            Grid: {
                              style: ({ $theme }) => innerGridOverride,
                            },
                          }}
                        >
                          <Cell span={[1, 4]}>
                            <Checkbox
                              checked={options.isPushNotificationChecked}
                              onChange={e =>
                                toggleProperty('isPushNotificationChecked')
                              }
                              labelPlacement={LABEL_PLACEMENT.left}
                            >
                              Push Notification
                      </Checkbox>
                          </Cell>
                          <Cell span={[1, 4]}>
                            <Checkbox
                              checked={options.isArticleChecked}
                              onChange={e => toggleProperty('isArticleChecked')}
                              labelPlacement={LABEL_PLACEMENT.left}
                            >
                              Article
                      </Checkbox>
                          </Cell>
                          <Cell span={[1, 4]}>
                            <Checkbox
                              checked={options.isEmailChecked}
                              onChange={e => toggleProperty('isEmailChecked')}
                              labelPlacement={LABEL_PLACEMENT.left}
                            >
                              Email
                      </Checkbox>
                          </Cell>
                        </Grid>
                      </Cell>
                      <Cell span={[1, 12]} overrides={overrideCellCss}>
                        <H6>Channels</H6>
                        <Paragraph2>
                          Select one or many channels below to designate who will see
                          this message in there inbox, newsroom or push notification
                          list.
                  </Paragraph2>
                        <Grid
                          overrides={{
                            Grid: {
                              style: ({ $theme }) => innerGridOverride,
                            },
                          }}
                        >
                          <Cell span={[1, 6]}>
                            <Checkbox
                              checked={options.isSendToEntireOrg}
                              onChange={e => {
                                if (options.isSentToSpefic)
                                  toggleProperty('isSentToSpefic');
                                toggleProperty('isSendToEntireOrg');
                              }}
                              labelPlacement={LABEL_PLACEMENT.left}
                            >
                              Send to Entire Organization
                      </Checkbox>
                          </Cell>
                          <Cell span={[1, 6]}>
                            <Checkbox
                              checked={options.isSentToSpefic}
                              onChange={e => {
                                if (options.isSendToEntireOrg)
                                  toggleProperty('isSendToEntireOrg');
                                toggleProperty('isSentToSpefic');
                              }}
                              labelPlacement={LABEL_PLACEMENT.left}
                            >
                              Send to Specific Channels
                      </Checkbox>
                          </Cell>

                          {options.isSentToSpefic && (
                            <Cell span={[1, 12]}>
                              <Paragraph2></Paragraph2>
                              <Paragraph2>Select channels below</Paragraph2>
                              <Select
                                options={channels}
                                labelKey="name"
                                valueKey="id"
                                placeholder="Choose a channel"
                                maxDropdownHeight="300px"
                                type={TYPE.search}
                                multi
                                onChange={({ value }) => setSelectedChannels(value)}
                                value={channelsSelected}
                                overrides={{
                                  Popover: {
                                    props: {
                                      overrides: {
                                        Body: {style: {zIndex: 5}},
                                      },
                                    },
                                  },
                                }}
                              />
                            </Cell>
                          )}
                        </Grid>
                      </Cell>

                      {(options.isArticleChecked || options.isEmailChecked) && (
                        <Cell span={[1, 12]} overrides={overrideCellCss}>
                          <H6>#Hashtags</H6>
                          <Paragraph2>
                            Add any hashtags that pertain to subject content of your
                            message as it appears in your newsroom.
                    </Paragraph2>
                          <Grid
                            overrides={{
                              Grid: {
                                style: ({ $theme }) => innerGridOverride,
                              },
                            }}
                          >
                            <Cell
                              span={[1, 12]}
                              overrides={{
                                Cell: {
                                  style: () => ({
                                    paddingLeft: '0px !important',
                                  }),
                                },
                              }}
                            >
                              <Input
                                placeholder={tags.length ? '' : 'Enter A Tag'}
                                value={value}
                                onChange={e => setValue(e.currentTarget.value)}
                                overrides={{
                                  Input: {
                                    style: { width: 'auto', flexGrow: 1 },
                                    component: InputReplacement,
                                    props: {
                                      tags: tags,
                                      removeTag: removeTag,
                                      onKeyDown: handleKeyDown,
                                    },
                                  },
                                }}
                              />
                            </Cell>
                          </Grid>
                        </Cell>
                      )}
                    </Grid>
                  </Cell>
                  <Cell span={[1, 6]} overrides={overrideParentCellCss}>
                    <Grid
                      gridGaps={[2, 6, 64]}
                      gridMaxWidth={'unset'}
                      overrides={{
                        Grid: {
                          style: ({ $theme }) => outerGridOverride,
                        },
                      }}
                    >
                      <Cell span={[1, 12]} overrides={overrideCellCss}>
                        <H6>Message Title</H6>
                        <Paragraph2>
                          This is the title of your message. It appears in the header
                          in your message block.
                  </Paragraph2>
                        <Grid
                          overrides={{
                            Grid: {
                              style: ({ $theme }) => innerGridOverride,
                            },
                          }}
                        >
                          <Cell
                            span={[1, 12]}
                            overrides={{
                              Cell: {
                                style: () => ({
                                  paddingLeft: '0px !important',
                                }),
                              },
                            }}
                          >
                            <Input
                              value={messageTitle}
                              onChange={e => setMessageTitle(e.target.value)}
                              placeholder="Your Message Title"
                              clearOnEscape
                            />
                          </Cell>
                        </Grid>
                      </Cell>
                      {options.isPushNotificationChecked && (
                        <Cell span={[1, 12]} overrides={overrideCellCss}>
                          <H6>Push Notification Message</H6>
                          <Paragraph2>
                            This is the body of your push notification that will
                            appear in the push notification area on your users
                            devices.
                    </Paragraph2>
                          <Grid
                            overrides={{
                              Grid: {
                                style: ({ $theme }) => innerGridOverride,
                              },
                            }}
                          >
                            <Cell
                              span={[1, 12]}
                              overrides={{
                                Cell: {
                                  style: () => ({
                                    paddingLeft: '0px !important',
                                  }),
                                },
                              }}
                            >
                              <Input
                                value={pushNotificationMessage}
                                onChange={e =>
                                  setPushNotificationMessage(e.target.value)
                                }
                                placeholder="Your Message Body"
                                clearOnEscape
                              />
                            </Cell>
                          </Grid>
                        </Cell>
                      )}
                      {(options.isArticleChecked || options.isEmailChecked) && (
                        <Cell span={[1, 12]} overrides={overrideCellCss}>
                          <H6>Cover Image</H6>
                          <Paragraph2>
                            Upload an image below that is the featured image for this
                            message. It will appear at the top of the message and must
                            not exceed 2800px in width.
                    </Paragraph2>
                          <Grid
                            overrides={{
                              Grid: {
                                style: ({ $theme }) => innerGridOverride,
                              },
                            }}
                          >
                            <Cell
                              span={[1, 12]}
                              overrides={{
                                Cell: {
                                  style: () => ({
                                    paddingLeft: '0px !important',
                                  }),
                                },
                              }}
                            >
                              {preivew_image && <img src={preivew_image}></img>}
                              <FileUploader
                                onDrop={(acceptedFiles, rejectedFiles) => {
                                  setFrontImage(acceptedFiles[0]);
                                  previewImage(acceptedFiles[0]);
                                }}
                              ></FileUploader>
                            </Cell>
                          </Grid>
                        </Cell>
                      )}
                    </Grid>
                  </Cell>
                </Grid>
              </div>
            </Step>
            <Step title="Content">
              <div style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                <Grid
                  gridGaps={[2, 6, 64]}
                  gridMaxWidth={'unset'}
                  overrides={{
                    Grid: {
                      style: ({ $theme }) => outerGridOverride,
                    },
                  }}
                >
                  <Cell span={[1, 12]} overrides={overrideParentCellCss}>
                    <Grid
                      gridGaps={[2, 6, 64]}
                      gridMaxWidth={'unset'}
                      overrides={{
                        Grid: {
                          style: ({ $theme }) => outerGridOverride,
                        },
                      }}
                    >
                      {(options.isArticleChecked || options.isEmailChecked) && (
                        <Cell span={[1, 12]} overrides={overrideCellCss}>
                          <H6>Message Body</H6>
                          <Paragraph2>
                            Drag content modules from the bottom into the blank space
                            to create rich media for your messaging.
                    </Paragraph2>
                          <Grid
                            overrides={{
                              Grid: {
                                style: ({ $theme }) => innerGridOverride,
                              },
                            }}
                          >
                            <Cell
                              span={[1, 12]}
                              overrides={{
                                Cell: {
                                  style: () => ({
                                    paddingLeft: '0px !important',
                                  }),
                                },
                              }}
                            >
                              {/* <Grid> */}
                              <Builder
                                noPreview={true}
                                leftCellSpan={1.2}
                                middleCellSpan={2}
                                rightCellSpan={8}
                                options={editorBtnOptions.filter(
                                  el => el.addInNewCommunication
                                )}
                                isModal={true}
                              // gridOverrides={{
                              //   Grid: {
                              //     style: ({ $theme }) => outerGridOverride,
                              //   },
                              // }}
                              // cellOverride={{
                              //   Cell: {
                              //     style: () => ({
                              //       padding: '0px !important',
                              //       height: '100%',
                              //     }),
                              //   },
                              // }}
                              ></Builder>
                              {/* </Grid> */}
                            </Cell>
                          </Grid>
                        </Cell>
                      )}
                    </Grid>
                  </Cell>
                </Grid>
              </div>
            </Step>
            <Step title="Approve and Send">
              <div style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                <Grid
                  gridGaps={[2, 6, 64]}
                  gridMaxWidth={'unset'}
                  overrides={{
                    Grid: {
                      style: ({ $theme }) => outerGridOverride,
                    },
                  }}
                >
                  <HeadingLarge>Are you sure you want to send this message to {getUsersMessage()}? This action can not be undone.</HeadingLarge>
                  <ModalButton onClick={() => onSave()} isLoading={isLoading}>
                    Send Message
                  </ModalButton>
                </Grid>
              </div>
            </Step>
          </ProgressSteps>
        </ModalBody>
        <ModalFooter>
          <div style={{ float: 'right'}}>
            {current === 0 &&
              <ButtonGroup>
                <Button onClick={() => {
                  if (options.isArticleChecked || options.isEmailChecked) {
                    setCurrent(1);
                  } else {
                    setCurrent(2);
                  }
                }}>Next</Button>
              </ButtonGroup>}

            {current === 1 &&
              <ButtonGroup>
                <Button onClick={() => {
                  setCardBackDataState([...store.getState().appBasicControls.cardBackData] || []);
                  setCurrent(0);
                }}>Prev</Button>
                <Button onClick={() => {
                  setCardBackDataState([...store.getState().appBasicControls.cardBackData] || []);
                  setCurrent(2);
                }}>Next</Button>
              </ButtonGroup>}

            {current === 2 &&
              <ButtonGroup>
                <Button onClick={() => {
                  setCurrent(1);
                }}>Prev</Button>
              </ButtonGroup>}
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const InputReplacement = React.forwardRef(
  ({ tags, removeTag, ...restProps }, ref) => {
    const [css] = useStyletron();
    return (
      <div
        className={css({
          flex: '1 1 0%',
          flexWrap: 'wrap',
          display: 'flex',
          alignItems: 'center',
        })}
      >
        {tags.map((tag, index) => (
          <Tag
            variant={TAG_VARIANT.solid}
            onActionClick={() => removeTag(tag)}
            key={index}
          >
            {tag}
          </Tag>
        ))}
        <StyledInput ref={ref} {...restProps} />
      </div>
    );
  }
);
