import React, { useMemo } from 'react';
import { Textarea } from 'baseui/textarea';
import { Button } from 'baseui/button';

const MatchingPair = ({
  value,
  placeholder,
  theme,
  onChange = () => { }
}) => {

  let formatedValue = useMemo(() => {
    let val = value || [];
    if (typeof val === 'string') {
      val = JSON.parse(val);
    }
    return val;
  }, [value])
  // console.log('value value value', value);

  const onChangeText = (e, i, i1) => {
    let v1 = [...formatedValue];
    v1[i][i1] = e.target.value;
    onChange(JSON.stringify(v1))
  }

  const onChangeTextV1 = () => {
    let v1 = [...formatedValue];
    v1.push(["", ""]);
    onChange(JSON.stringify(v1))
  }

  return (
    <>
      {formatedValue.map((el, i) => {
        return <>
          <h5
            style={{
              color: theme.colors.primaryA,
              marginTop: theme.sizing.scale1200,
            }}
          >
            {`Matching Pair #${i + 1}`}
          </h5>
          {el.map((el1, i1) => {
            const onChangeEle = (e) => {
              onChangeText(e, i, i1)
            }
            return <>
              <h5
                style={{
                  color: theme.colors.primaryA,
                  marginTop: theme.sizing.scale1200,
                }}
              >
                {`Matching Item`}
              </h5>
              <Textarea
                value={el1}
                onChange={onChangeEle}
                placeholder={placeholder}
                clearOnEscape
              /></>
          })}
        </>
      })}
      <Button
        style={{
          marginTop: '16px',
        }}
        onClick={onChangeTextV1}>
        Add New Matching Pair
      </Button>
    </>
  );
}

export default MatchingPair;
