import React, { useState, useEffect } from "react";
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import Radium, { StyleRoot } from 'radium';
import { LabelLarge, LabelSmall } from "baseui/typography";

import { useStyletron } from 'baseui';
import styled from '@emotion/styled';
import { bounce, slideInLeft, slideInRight } from 'react-animations';
import { SearchInput, SearchContainer, RelativeDiv, Reorder,Settings, ChannelDiv,ChannelAvatar, RelativeControlsDiv, AddSecondary, ChannelDivButton } from "./channelsDropdownV1";
import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import Toggle from 'react-toggle';
import { MdGroupOff, MdGroup } from "react-icons/md";
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip';
import { Spinner } from 'baseui/spinner';
import { useMutation } from '@apollo/client';
import { organization, updateChannel } from '../../quries';
import { navigate } from '@reach/router';
import { ReorderModal } from "./groupsDropdown";


import Button from '../common/button';
import AddEditChannel from './addEditChannel';

import { setSelectedChannel, setSelectedGroup, setChannels, showAlert } from '../../redux/actions/appBasicControls';


const styles = {
  // ...other styles...

  slideInLeft: {
    width: '350px',
    top: 0,
    height: 'calc(100vh - 52px)',
    maxHeight: 'calc(100vh - 52px)',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    animation: 'x 0.5s', // Use the slideInLeft animation
    animationName: Radium.keyframes(slideInLeft, 'slideInLeft')
  }
};

const ChannelView = ({ group, channel, channels, groupId, organization_id, openChannelUpdate,getChannelsOfGroup ,index, ...props}) => {
  const [css, theme] = useStyletron();
  console.log('_______organization_id', organization_id);
  const [isChannelReorderOn, setIsChannelReorderOn] = React.useState(false);
  const [channelColors, setChannelColors] = React.useState([]);
  const [tabStatus, setTabStatus] = React.useState(0);
  const [channelsPublishing, setChannelsPublishing] = React.useState([]);
  const [groupIdForChannelReorder, setGroupIdForChannelReorder] = React.useState(null);
  const [updateAppContentScope] = useMutation(updateChannel);
  const isControlActive = false//selectedChannel.id == channel.id;
  const isPublishing = channelsPublishing.find(el => el === channel.id);

  const _setSelectedChannel = (hasGroup, channel) => {
    props.dispatch(setSelectedChannel(channel));
    props.dispatch(setSelectedGroup(hasGroup));
    handleOverLayClick();
    props && props.onClose();
    // do not navigate for connect page
    if (props?.parent && props?.parent == 'connect') return;
    navigate(
      `/app/${organization_id}/groups/${groupId}/channels/${channel.id}`
    );
  };

  const handleOverLayClick = () => {
    // onClose();
    // document.querySelector('.side-bar-container').style['z-index'] = 0;
    document.querySelector('#app-dropdown-overlay').style.display = 'none'
    document.querySelector('#app-container').style.overflow = 'auto';
  };


  const addChannelPublishing = (channel) => {
    setChannelsPublishing(prevState => ([
      ...prevState,
      channel.id
    ]))
  }

  const removeChannelPublishing = (channel) => {
    setChannelsPublishing(prevState => (prevState.filter(el => el !== channel.id)))
  }

  const updateChannelValue = (id, key, value) => {
    let updatedChannelList = [...channels];
    const channelIndx = channels.findIndex(el => el.id === id)
    updatedChannelList[channelIndx] = {
      ...updatedChannelList[channelIndx],
      [key]: value
    }
    props.dispatch(setChannels(updatedChannelList))
  }


  const channelReorderSubmit = async (sorted, publish = false, publicOption = false) => {
 if (publish) {
      const channel = sorted[0];
      const { id, position, } = channel;
      addChannelPublishing(channel)
      props.dispatch(
        showAlert({
          msg: channel.published ? 'Publishing channel...' : 'Unpublishing channel...',
          error: false,
        })
      );
      const updatedAppContentScopeData = await updateAppContentScope({
        variables: {
          id, position,
          published: channel.published
        }
      })
      if (!updatedAppContentScopeData.errors) {
        updateChannelValue(id, 'published', channel.published)
      }

      removeChannelPublishing(channel);
      return updatedAppContentScopeData;
    }
    if (publicOption) {
      const channel = sorted[0];
      const { id, position, } = channel;
      addChannelPublishing(channel)
      props.dispatch(
        showAlert({
          msg: channel.public ? 'Making public channel...' : 'Making private channel...',
          error: false,
        })
      );
      const updatedAppContentScopeData = await updateAppContentScope({
        variables: {
          id, position, public: channel.public,
        }
      })
      if (!updatedAppContentScopeData.errors) {
        updateChannelValue(id, 'public', channel.public)
      }
      removeChannelPublishing(channel);
      return updatedAppContentScopeData;
    }

    props.dispatch(setChannels(channels.map(c => {
      let f = sorted.find(el => el.id == c.id);
      if (f) {
        return f;
      };
      return c;
    })));
    Promise.all(sorted.map(c => {
      return updateAppContentScope({
        variables: {
          id: c.id, position: c.position, public: c.public, published: c.published
        }
      })
    }));
  };

  return (
      <>
      {isChannelReorderOn && <ReorderModal elements={getChannelsOfGroup(groupIdForChannelReorder)} css={css} onCancel={() => {
        setIsChannelReorderOn(false);
      }} onDone={(d) => {
        setIsChannelReorderOn(false);
        channelReorderSubmit(d);
      }}></ReorderModal>}
      <ChannelDiv
        theme={theme} selected={isControlActive} key={`channel-${index}`}
        // onMouseEnter={() => setHoveredChannel(channel.id)}
        // onMouseLeave={() => setHoveredChannel(null)}
        onClick={(e) => {
          console.log('Parent clicking')
          _setSelectedChannel(group, channel)
        }}
        id={channel.id}
      // className={"channelDiv"}
      >

        <div style={{ position: 'relative', float: 'left', width: '24px', height: '100%' }} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setGroupIdForChannelReorder(groupId);
          setIsChannelReorderOn(true);
        }}>
          <Reorder css={css} isActive={true} color={theme.colors.primaryB} style={{
            position: 'absolute', top: '50%', transform: 'translateY(-50%)', display: isControlActive ? 'block' : 'none'
          }} className="channelReorder"></Reorder>
        </div>

        <RelativeDiv style={{ marginLeft: theme.sizing.scale200 }}>
          <ChannelAvatar
            channel={channel}
            channelColors={channelColors}
            index={index}
          />
        </RelativeDiv>

        <RelativeDiv style={{ marginLeft: theme.sizing.scale200, display: 'flex', alignItems: 'center', maxWidth: '43%' }}>
          <LabelSmall className="channelName" title={channel.name} style={{
            color: theme.colors.primaryB,
            maxHeight: '16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: isControlActive ? theme.colors.primaryB : theme.colors.primaryA,
          }}
          >{channel.name}
          </LabelSmall>
        </RelativeDiv>

        <RelativeControlsDiv float="right" className="channelControls" isControlActive={isControlActive}>
          {
            isPublishing && (
              <RelativeDiv style={{ height: '24px', top: '50%', transform: 'translateY(-50%)', marginRight: '0.5rem' }} >
                <Spinner
                  size={24}
                  color='green'
                />
              </RelativeDiv>
            ) || (
              <>
                <StatefulPopover
                  content={() => (
                    <div
                      className={css({
                        fontSize: '14px',
                        padding: '16px',
                      })}
                    >
                      {channel.published ? 'Unpublish channel' : 'Publish channel'}
                    </div>
                  )}
                  triggerType={TRIGGER_TYPE.hover}
                  accessibilityType={'tooltip'}
                  showArrow
                  placement={PLACEMENT.topRight}
                  overrides={{
                    Body: {
                      style: {
                        zIndex: 100,
                      }
                    }
                  }}
                >
                  <RelativeDiv style={{ height: '24px', top: '50%', transform: 'translateY(-50%)', marginRight: '0.5rem', cursor: 'pointer' }} onClick={e => e.stopPropagation()}>
                    <Toggle
                      id="cheese-status"
                      defaultChecked={channel.published}
                      icons={false}

                      onChange={(e) => {
                        console.log('onClick Event')
                        e.preventDefault();
                        e.stopPropagation();
                        if (tabStatus !== 0 && tabStatus !== '0') {
                          return props.dispatch(showAlert(
                            {
                              msg: 'Archived channel can\'t be published',
                              error: true
                            }
                          ))
                        }
                        let dataToUpdate = [{ ...channel }];
                        dataToUpdate[0].published = !dataToUpdate[0].published;
                        channelReorderSubmit(dataToUpdate, true);
                      }}
                    ></Toggle>
                  </RelativeDiv>
                </StatefulPopover>
                <StatefulPopover
                  content={() => (
                    <div
                      className={css({
                        fontSize: '14px',
                        padding: '1rem'
                      })}
                    >
                      {channel.public ? 'Make Private' : 'Make Public'}
                    </div>
                  )}
                  triggerType={TRIGGER_TYPE.hover}
                  accessibilityType={'tooltip'}
                  showArrow
                  placement={PLACEMENT.topRight}
                  overrides={{
                    Body: {
                      style: {
                        zIndex: 100,
                      }
                    }
                  }}
                >
                  <span>
                    {channel.public ? (
                      <MdGroup
                        color={'#0adb0a'}
                        size={25}
                        style={{ cursor: 'pointer', margin: '7px 5px 0 5px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          let dataToUpdate = [{ ...channel }];
                          dataToUpdate[0].public = !dataToUpdate[0].public;
                          channelReorderSubmit(dataToUpdate, false, true);
                        }}
                      />) : (
                      <MdGroupOff
                        color={'#EA4576'}
                        size={25}
                        style={{ cursor: 'pointer', margin: '7px 5px 0 5px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (tabStatus !== 0 && tabStatus !== '0') {
                            return props.dispatch(showAlert(
                              {
                                msg: 'Archived channel can\'t be published',
                                error: true
                              }
                            ))
                          }
                          let dataToUpdate = [{ ...channel }];
                          dataToUpdate[0].public = !dataToUpdate[0].public;
                          channelReorderSubmit(dataToUpdate, false, true);
                        }} />

                    )}
                  </span>
                </StatefulPopover>
              </>
            )
          }

          <RelativeDiv style={{ height: '20px', marginLeft: theme.sizing.scale200, marginTop: '10px', float: 'right' }} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            openChannelUpdate(groupId, channel);
          }}>
            <Settings color={theme.colors.primaryB}></Settings>
          </RelativeDiv>
        </RelativeControlsDiv>
      </ChannelDiv>
    </>

  )
}

const ChannelList = ({
  group,
  groupId,
  channelList,
  channels,
  organization_id,
  openChannelImport,
  ...props
})  => {
  console.log('_______organization_id', organization_id);
  const [css, theme] = useStyletron();
  const [isAddEditChannelOpen, setIsAddEditChannelOpen] = React.useState(false);
  const [currentChannelInfo, setCurrentChannelInfo] = React.useState({});
  const [selectedGroupId, setSelectedGroupId] = React.useState('');
  const [tabStatus, setTabStatus] = React.useState(0);

  React.useEffect(() => {
    if (true) {
      // do not show overlay for connect page
      // if (props.parent && props.parent == 'connect') return;
      document.querySelector('#app-dropdown-overlay').style.display = 'block';
      document.querySelector('#app-container').style.overflow = 'hidden';
    }
  }, []);

  const onClickChannelImport = (groupId) => {
    openChannelImport(groupId)
  }

  const openChannelAdd = (groupId) => {
    setCurrentChannelInfo({
      app_content_scope_group_id: groupId,
      totalCount: getChannelsOfGroup(groupId).length,
      channel: {}
    });
    setIsAddEditChannelOpen(true);
  };
  const openChannelUpdate = (groupId, channel) => {
    setCurrentChannelInfo({
      app_content_scope_group_id: groupId,
      totalCount: getChannelsOfGroup(groupId).length,
      channel
    });
    setIsAddEditChannelOpen(true);
  };

  const getChannelsOfGroup = (groupId) => {
    return channels.filter(c => c.app_content_scope_group_id == groupId).sort((a, b) => a.position - b.position);
  };

  const onCloseChannelAddEdit = () => {
    setIsAddEditChannelOpen(false);
    setCurrentChannelInfo({});
  }
  

  return (
    <>
       {isAddEditChannelOpen && 
       <AddEditChannel
        onClose={onCloseChannelAddEdit}
        {...currentChannelInfo.channel}
        totalCount={currentChannelInfo.totalCount}
        app_content_scope_group_id={currentChannelInfo.app_content_scope_group_id}
        organization_id={organization_id}
        dispatch={props.dispatch}
      ></AddEditChannel>}
      <div theme={theme}>
        <div style={styles.slideInLeft}>
            { channelList?.map((channel, index) => {
              return <ChannelView group={group} getChannelsOfGroup={getChannelsOfGroup}  channel={channel} channels={channelList} groupId={groupId} organization_id={organization_id} openChannelUpdate={openChannelUpdate} index={index} {...props}/>
            })}
        </div>
      </div>
      {tabStatus == 0 && <ChannelDivButton theme={theme} >
        <div style={{ position: 'relative', float: 'left', width: '24px', height: '100%' }}>
        </div>
        <Button
          text={"Channel"}
          border_property
          startEnhancer={<AddSecondary />}
          backgroundColor={theme.colors.primaryB}
          style={{
            color: theme.colors.primaryC,
            border: `2px solid ${theme.colors.primaryC}`,
            boxSizing: 'border-box',
          }}
          disableHover={true}
          onClick={() => openChannelAdd(groupId)}
        />
        <Button
          text={"Import"}
          border_property
          startEnhancer={<AddSecondary />}
          backgroundColor={theme.colors.primaryB}
          style={{
            color: theme.colors.primaryC,
            border: `2px solid ${theme.colors.primaryC}`,
            boxSizing: 'border-box',
            marginLeft: 10
          }}
          disableHover={true}
          onClick={onClickChannelImport}
        />
      </ChannelDivButton>}
    </>
  );
};


export default ChannelList;


