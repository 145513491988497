import * as React from 'react';
import { connect } from 'react-redux';
import { setSelectedChannel, setIsLoading } from '../../redux/actions/appBasicControls';
import Channel from './channel';
import { _navigate, setChannelToCookies, } from './channel-route-management';
import { debounce } from 'lodash';
import ApiChannel from '../apiMappings/api-channel';
import OnboardNewOrganization from '../onboard';
const _navigateDebounced = debounce(_navigate, 100);

const Channels = ({
  selectedChannel,
  channels,
  organization_id,
  dispatch,
  channelGroups,
  category,
  decks,
  cards,
  groupId,
  refreshQuizQuestions,
  defaultGroupId,
  defaultTheme,
  deckId,
  refreshCategories,
  refreshDecks,
  refreshCards,
  refreshAppContentScopeGroups,
  refreshAppContentScopes,
  refreshOrgTags,
  isDataLoading,
  isLoading,
  isTemplate = false,
  ...rest
}) => {
  const [isRouteValid, setIsRouteValid] = React.useState(false);

  React.useEffect(() => {
    if (isDataLoading || isRouteValid) {
      return;
    };
    let channelId = rest.channelId;
    if (rest['*']) {
      let restArr = rest['*'].split('/');
      channelId = restArr[0];
    }

    _navigateDebounced(channelGroups, channels, organization_id, channelId, groupId, (channel) => {
      dispatch(setSelectedChannel(channel));
      setIsRouteValid(true);
    })
  }, [isDataLoading, channelGroups, channels, rest.channelId]);

  React.useEffect(() => {
    selectedChannel && setChannelToCookies(selectedChannel.id);
  }, [selectedChannel]);

  // if (!isRouteValid) return <></>;

  // let deckLength = 0;
  // let cardLength = 0;
  // let categoryLength = 0;

  const getDescription = (tab) => {
    if (tab === 'all') {
      let categoryIds = category
        .filter(el => el.app_content_scope_id == (selectedChannel || {}).id)
        .filter(el => el.category.archived !== true)
        .map(el => el.category_id);
      categoryLength = categoryIds.length;

      let decksIds = decks
        .filter(el => categoryIds.includes(el.category_id))
        .filter(el => el.deck_category_deck.archived != true)
        .map(el => el.deck_id);
      deckLength = decksIds.length;

      let cardIds = cards
        .filter(el => decksIds.includes(el.deck_id) && el.card.archived != true)
        .map(el => el.card_id);
      cardLength = cardIds.length;

    } else if (tab === 'published') {
      let categoryIds = category
        .filter(el => el.app_content_scope_id == (selectedChannel || {}).id)
        .filter(el => el.published === true && el.category.archived !== true)
        .map(el => el.category_id);
      categoryLength = categoryIds.length;

      let decksIds = decks
        .filter(el => categoryIds.includes(el.category_id))
        .filter(el => el.published === true && el.deck_category_deck.archived != true)
        .map(el => el.deck_id);
      deckLength = decksIds.length;

      let cardIds = cards
        .filter(el => decksIds.includes(el.deck_id) && el.card.archived !== true && el.published === true)
        .map(el => el.card_id);
      cardLength = cardIds.length;
    } else {
      let categoryIds = category
        .filter(el => el.app_content_scope_id == (selectedChannel || {}).id)
        .filter(el => el.category.archived === true)
        .map(el => el.category_id);
      categoryLength = categoryIds.length;

      let decksIds = decks
        .filter(el => categoryIds.includes(el.category_id))
        .filter(el => el.deck_category_deck.archived === true)
        .map(el => el.deck_id);
      deckLength = decksIds.length;

      let cardIds = cards
        .filter(el => decksIds.includes(el.deck_id) && el.card.archived === true)
        .map(el => el.card_id);
      cardLength = cardIds.length;
    }

    return `${categoryLength} Categories, ${deckLength} Decks, ${cardLength} Cards`;
  }

  let categoryIds = React.useMemo(() => {
    return category
      .filter(el => el.app_content_scope_id == (selectedChannel || {}).id)
      .map(el => el.category_id);
  }, [category]);

  let categoryLength = React.useMemo(() => {
    const ids = category
      .filter(el => el.app_content_scope_id == (selectedChannel || {}).id)
      .map(el => el.category_id);
    return ids && ids.length || 0
  }, [category]);

  let decksIds = React.useMemo(() => decks
    .filter(el => categoryIds.includes(el.category_id))
    .map(el => el.deck_id), [decks]);

  let deckLength = React.useMemo(() => {
    const ids = decks
      .filter(el => categoryIds.includes(el.category_id))
      .map(el => el.deck_id);
    return ids && ids.length || 0;
  }, [decks]);

  let cardIds = React.useMemo(() => {
    return cards
      .filter(el => decksIds.includes(el.deck_id))
      .map(el => el.card_id);
  }, [cards]);

  let cardLength = React.useMemo(() => {
    const ids = cards
      .filter(el => decksIds.includes(el.deck_id))
      .map(el => el.card_id);
    return ids && ids.length || 0;
  }, [cards]);

  let _selected = React.useMemo(() => {
    if (channels && selectedChannel && channels.length > 0 && selectedChannel.id) {
      return channels.find(el => el.id == selectedChannel.id);
    }
    return null;
  }, [selectedChannel]);

  // if (_selected && _selected.has_data_source_connected) return <ApiChannel
  //   selectedChannel={_selected || {}}
  //   organization_id={organization_id}
  //   dispatch={dispatch}
  //   deckLength={deckLength}
  //   categoryLength={categoryLength}
  //   cardLength={cardLength}
  //   channels={channels}
  //   getDescription={getDescription}
  //   channelGroups={channelGroups}
  //   refreshQuizQuestions={refreshQuizQuestions}
  //   defaultTheme={defaultTheme}
  //   deckIdFromPath={deckId}
  //   refreshCategories={refreshCategories}
  //   refreshDecks={refreshDecks}
  //   refreshCards={refreshCards}
  // />

  return (
    <>
      <Channel
        selectedChannel={selectedChannel || {}}
        organization_id={organization_id}
        dispatch={dispatch}
        deckLength={deckLength}
        categoryLength={categoryLength}
        cardLength={cardLength}
        channels={channels}
        getDescription={getDescription}
        channelGroups={channelGroups}
        refreshQuizQuestions={refreshQuizQuestions}
        defaultTheme={defaultTheme}
        deckIdFromPath={deckId}
        refreshCategories={refreshCategories}
        refreshDecks={refreshDecks}
        refreshCards={refreshCards}
        refreshAppContentScopeGroups={refreshAppContentScopeGroups}
        refreshAppContentScopes={refreshAppContentScopes}
        refreshOrgTags={refreshOrgTags}
        isTemplate={isTemplate}
      />
      <OnboardNewOrganization isDataLoading={isDataLoading}/>
    </>
  );
};

const mapStateToProps = props => {
  let {
    selectedChannel,
    channels,
    category,
    cards,
    decks,
    groups,
    theme,
    isLoading,
  } = props.appBasicControls;
  return {
    selectedChannel,
    channels,
    channelGroups: groups,
    category,
    decks,
    cards,
    defaultTheme: theme,
    isLoading,
    // deckLength: (decks || []).length,
    // cardLength: (cards || []).length,
    // categoryLength: (category || []).length
  };
};
export default connect(mapStateToProps)(Channels);
