import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import { Input } from 'baseui/input';
import { LabelSmall } from 'baseui/typography';
import { client } from '../../apollo/client';
import { toaster } from "baseui/toast";
import { exportChannelTemplate } from "../../quries";

const ExportTemplateChannelModal = props => {
    const {
        isOpen = false,
        onClose = () => { },
        organization_id,
        channel_id
    } = props || {};
    const [templateName, setTemplateName] = useState("");
    const [loader, setLoader] = useState(false);

    const onCloseModal = () => {
        setTemplateName("")
        onClose()
    };

    const showToaster = (msg, method = 'info') => {
        let toastKey = toaster[method](<>{msg}</>, {
            overrides: {
                InnerContainer: {
                    style: { width: "100%" }
                }
            }
        }
        );
        setTimeout(() => { toaster.clear(toastKey) }, 3000);
    };

    const onExportTemplate = () => {
        if (loader) return
        setLoader(true)
        client.mutate({
            mutation: exportChannelTemplate,
            variables: {
                organization_id,
                channel_id,
                name: templateName
            }
        }).then(() => {
            setLoader(false)
            showToaster("Template Saved!");
            onCloseModal()
        }).catch((err) => {
            setLoader(false)
            showToaster(JSON.stringify(err), 'negative')
        });
    }

    return (
        <Modal
            onClose={onCloseModal}
            closeable
            isOpen={isOpen}
            animate
            autoFocus
            size={SIZE.auto}
            role={ROLE.dialog}>
            <ModalHeader>Export Channel to Template</ModalHeader>
            <ModalBody>
                <LabelSmall
                    style={{
                        marginTop: '16px',
                        marginBottom: '8px',
                    }}>Template Name</LabelSmall>

                <Input
                    value={templateName}
                    onChange={e => setTemplateName(e.target.value)}
                    placeholder={"Template Name"}
                    clearOnEscape
                />

            </ModalBody>
            <ModalFooter>
                <ModalButton onClick={onCloseModal} kind={ButtonKind.tertiary}>
                    Cancel
                </ModalButton>
                <ModalButton disabled={!templateName} isLoading={loader} onClick={onExportTemplate}>Export</ModalButton>
            </ModalFooter>
        </Modal>
    );
}


export default ExportTemplateChannelModal