import React, { useState } from 'react';
import { ModalButton } from 'baseui/modal';
import { Drawer } from "baseui/drawer";
import { toaster } from 'baseui/toast';
import { FileUploader } from "baseui/file-uploader"
import { useStyletron } from 'baseui';
import { HeadingSmall, LabelMedium } from 'baseui/typography';
import { Spinner } from 'baseui/spinner';
import { getServerUrl } from '../../apollo/client';
import axios from 'axios';
import { getToken } from '../../auth/index';
import { useSelector } from 'react-redux';
import UploadProgress from '../upload-progress';

const ImportChanneleModal = (props) => {
    const {
        isVisible = false,
        onClose = () => { },
        channel_id,
        organization_id,
        onImportChannelSuccess
    } = props || {};


    const [importLoading, setImportLoading] = useState(false);
    const [css] = useStyletron();
    const [importFile, setImportFile] = useState(null);
    const [progress, setProgress] = useState(0);


    const appBasicControls = useSelector(state => state.appBasicControls);
    const app_content_scope_group_id = appBasicControls?.selectedChannel?.app_content_scope_group_id

    const showToaster = (msg, method = 'info') => {
        let toastKey = toaster[method](
            <>
                {msg}
            </>,
            {
                overrides: {
                    InnerContainer: {
                        style: { width: "100%" }
                    }
                }
            }
        );
        setTimeout(() => { toaster.clear(toastKey) }, 3000);
    };


    const onCloseModal = () => {
        onClose();
        setImportFile(null)
        setImportLoading(false)
    }

    const importChannel =  () => {
        if (importFile == null || importLoading) return
        setImportLoading(true);

        const tokens =  getToken();
        const config = {
            headers: { Authorization: tokens.idToken ? `Bearer ${tokens.idToken}` : '' },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                console.log(`${loaded}kb of ${total}kb | ${percent}%`)

                if (percent <= 100) {
                    setProgress(percent)
                }
            }
        };

        const formData = new FormData();
        formData.append('file', importFile);
        formData.append('organization_id', organization_id);
        formData.append('app_content_scope_id', channel_id);

        setProgress(0)
        axios.post(`${getServerUrl()}/import_channel_content/${organization_id}`, formData, config)
            .then((result) => {
                console.log(result);
                if (result?.data?.error) {
                    setImportFile(null)
                    setImportLoading(false);
                    showToaster(result?.data?.error ? result?.data?.error : 'Something went wrong in importing file', 'negative')
                } else {
                    onImportChannelSuccess()
                    setImportFile(null)
                    setImportLoading(false);
                    onCloseModal()
                    showToaster('Channels Imported Successfully', 'positive')
                }
            }).catch((err) => {
                setImportFile(null)
                setImportLoading(false);
                showToaster('Something went wrong in importing channels', 'negative')
            }).finally(() => {
                setTimeout(() => {
                    setProgress(0)
                }, 500)
            })
    }


    return (
        <Drawer
            isOpen={isVisible}
            autoFocus
            onClose={onCloseModal}
            overrides={{
                DrawerBody: {
                    style: ({ $theme }) => ({
                        marginLeft: '20px',
                    })
                },
            }}
        >
            <HeadingSmall
                style={{
                    textAlign: 'center'
                }}
            >
                Import Channel
            </HeadingSmall>


            <div style={{
                marginTop: '20px'
            }} >
                <FileUploader
                    accept={["application/json"]}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                        setImportFile(acceptedFiles[0])
                    }}
                />

                {importLoading &&
                    <UploadProgress progress={progress} progressText={"import progress is complete"} />
                }
                {importFile && <LabelMedium style={{
                    width: '100%',
                    marginRight: '48px',
                    marginTop: '16px',

                }} >File Selected {importFile.name}</LabelMedium>}

            </div>


            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    minHeight: '50px',
                    paddingBottom: '10px',
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    flexDirection: 'column'
                }}>

                <div
                    className={css({
                        justifyContent: 'flex-end',
                        display: 'flex'
                    })}
                >
                    <ModalButton
                        onClick={onCloseModal}
                    >
                        Cancel
                    </ModalButton>

                    <ModalButton
                        isLoading={importLoading}
                        onClick={importChannel}
                    >
                        {importLoading && <Spinner style={{ marginRight: "5px" }} size="20px" />} Import
                    </ModalButton>
                </div>

            </div>
        </Drawer>

    )
}


export default ImportChanneleModal