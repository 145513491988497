import * as React from 'react';
import { Grid } from 'baseui/layout-grid';
import { H6, H5 } from 'baseui/typography';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
} from 'baseui/modal';
import { useMutation } from '@apollo/client';
import { moveCategoryToChannel } from '../../quries';
import { showAlert } from '../../redux/actions/appBasicControls';

export default ({
    currentChannel,
    category,
    nextChannel,
    onClose = () => { },
    isOpen = false,
    dispatch,
    onSuccess = async () => { return; }
}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const outerGridOverride = {
        marginTop: '30px',
        marginLeft: '0px !important',
        marginRight: '0px !important',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
    };


    const [moveCategoryToChannelMutation] = useMutation(moveCategoryToChannel);

    const onCancel = () => {
        onClose();
    }

    const onMove = async () => {
        try {
            dispatch(
                showAlert({
                    msg: `Category is moving ..........`,
                    error: false,
                })
            );
            setIsLoading(true)
            const movedCategoryData = await moveCategoryToChannelMutation({
                variables: {
                    category_id: category.id,
                    from_channel_id: currentChannel.id,
                    to_channel_id: nextChannel.id
                }
            })

            await onSuccess();
            
            setIsLoading(false)
            // console.log('onMoveCategoryClick', movedCategoryData)
            dispatch(
                showAlert({
                    msg: `Category is moved successfully ..........`,
                    error: false,
                })
            );
        } catch (err) {
            console.log('onMoveCategoryClick error', err);
            setIsLoading(false);
            dispatch(
                showAlert({
                    msg: err.message,
                    error: true,
                })
            );
        }
    }

    return (
        <React.Fragment>
            <Modal
                overrides={{
                    Root: {
                        style: {
                            zIndex: 100
                        }
                    },
                    Backdrop: {
                        style: ({ $theme }) => ({
                            backdropFilter: $theme.modalBackDropFilter,
                        }),
                    }
                }}
                isOpen={isOpen}
                onClose={() => {
                    onCancel();
                }}
            >
                <ModalHeader>{ }</ModalHeader>
                <ModalBody style={{ flex: '1 1 0' }}>
                    <Grid
                        gridGaps={[12, 12, 24]}
                        overrides={{
                            Grid: {
                                style: ({ $theme }) => outerGridOverride,
                            },
                        }}
                    >
                        <H5>
                            Are you sure you want to move this category -{' '}
                            <b style={{ color: 'rgb(118, 95, 237)' }}>
                                {category.name || ''}
                            </b>{' '}
                            to new channel - {nextChannel ? nextChannel.name : 'NA'}?{' '}
                        </H5>
                        <H6>
                            This process may take a few moments and all changes are final
                        </H6>
                    </Grid>
                </ModalBody>
                <ModalFooter>
                    <ModalButton
                        onClick={onCancel}
                    >
                        Cancel
                    </ModalButton>
                    <ModalButton
                        isLoading={isLoading}
                        onClick={onMove}
                    >
                        Move this anyway
                    </ModalButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};
