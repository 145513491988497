import * as React from 'react';
import moment from 'moment-timezone';
import ReactPlayer from 'react-player';
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import { DeleteIcon, CopyIcon, DotIcon } from './icons';

const fileMenuOptions = [
    { label: <div style={{ display: 'flex', alignItems: 'center', }}><CopyIcon />Copy</div>, value: 'copy' },
    { label: <div style={{ display: 'flex', alignItems: 'center' }}><DeleteIcon />Delete</div>, value: 'delete' },
];

export default (props) => {
    const { onMessage, onDelete, onClick, index } = props;
    const { filename, secure_url, bytes, format, duration, width, height, resource_type,  } = props.data;
    const durations = moment.duration(duration, 'seconds');
    return (
        <div className='cloudinary-container' onClick={() => onClick && onClick(props.data)}>
            {resource_type === 'video' && <div className='video-container'><ReactPlayer controls={true} url={secure_url} width="100%" height="200px" /></div>}
            {resource_type === 'image' && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <img src={secure_url} style={{ objectFit: 'cover', width: '100%' }} height={200} />
            </div>}
            {resource_type === 'raw' && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <span className='fileIcon'>
                    <svg data-test="icon" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid" class="sc-dkrFOg jzoYvv"><path d="M4.28125 24C3.80625 24 3.39062 23.82 3.03437 23.46C2.67812 23.1 2.5 22.68 2.5 22.2V1.8C2.5 1.32 2.67812 0.9 3.03437 0.54C3.39062 0.18 3.80625 0 4.28125 0H14.2562C14.4937 0 14.7263 0.05 14.9539 0.15C15.1815 0.25 15.3745 0.38 15.5328 0.54L20.9656 6.03C21.124 6.19 21.2526 6.385 21.3516 6.615C21.4505 6.845 21.5 7.08 21.5 7.32V22.2C21.5 22.68 21.3219 23.1 20.9656 23.46C20.6094 23.82 20.1938 24 19.7188 24H4.28125ZM14.1078 6.48V1.8H4.28125V22.2H19.7188V7.38H14.9984C14.7411 7.38 14.5284 7.295 14.3602 7.125C14.1919 6.955 14.1078 6.74 14.1078 6.48Z"></path></svg>
                </span>
            </div>}
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <p>{filename}</p>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <p className='m-0'>{format}</p>
                    {duration && <p className='m-0'>{durations.minutes() + ':' + durations.seconds()}</p>}
                    {width && resource_type === 'image' && <p className='m-0'>{width + ' x ' + height}</p>}
                    {bytes && <p className='m-0'>{(bytes / 1024 / 1024).toFixed(2)}MB</p>}
                </div>
            </div>
            <StatefulPopover
                placement={PLACEMENT.bottom}
                content={() => (
                    <StatefulMenu
                        items={fileMenuOptions}
                        onItemSelect={({ item }) => {
                            if (item.value === 'copy') {
                                navigator.clipboard.writeText(secure_url)
                                onMessage && onMessage('Copied to clipboard!');
                                document.querySelectorAll('.file-setting')[index].click();
                            }
                            if (item.value === 'delete') {
                                onDelete && onDelete(props.data);
                                document.querySelectorAll('.file-setting')[index].click();
                            }
                        }}
                    />
                )}
                triggerType={TRIGGER_TYPE.click}
                returnFocus
                autoFocus
            >
                <span className='file-setting'>
                    <DotIcon color="white" />
                </span>
            </StatefulPopover>
        </div>
    )
}