import React from 'react';
import BaseClass from '../../builder/cardBackEditorComponents/base';

const Info = ({
  value,
  theme
}) => {
  return (
    <>
      <h5
        style={{
          color: theme.colors.primaryA,
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 'normal'
        }}
      >
        {value || ''}
      </h5>
    </>
  );
}

export default Info;
