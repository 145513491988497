import React from 'react';
import { useQuery } from '@apollo/client';
import { getAdminPageSliders } from '../quries';
import { Router } from '@reach/router';
import LoginContainer from '../components/loginContainer';
import { useStyletron } from 'baseui';
import { Input } from 'baseui/input';
import { Button } from 'baseui/button';
import { Grid, Cell } from 'baseui/layout-grid';
import { gridPaddingOverrides } from '../components/overrides';
import PasswordStrengthBar from 'react-password-strength-bar';
import { navigate } from '@reach/router';
import ImageUploadCXL from '../components/imageUploaderCXL';
import { organizationLogo, appIconSizeGuideLine } from '../components/help/imageSizeGuidelines.json';
import { Notification, KIND } from "baseui/notification";
import { Spinner } from "baseui/spinner";
import { HeadingLarge, LabelMedium, LabelSmall } from 'baseui/typography';
import { client } from '../apollo/client';
import gql from 'graphql-tag';
import queryString from 'query-string';
import styled from '@emotion/styled';
import { setEmailToCookie } from '../auth/helpers';
import HelpQuestionMark from '../components/help/questionMarkHelp';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { getServerUrl } from '../apollo/client';
import CardInput from '../components/cardInput';
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Select } from "baseui/select";
import { Country, State, City } from 'country-state-city';
import { updatePwaTemplate } from '../quries';
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";
import { navigate as navigateGatsby } from 'gatsby';
import { setLastLoggedInOrgId, setAuth0Token } from '../auth/helpers';
import { setUserInfoToStateFromToken } from '../auth/index';

const promoCode = process.env.PROMO_CODE || 'buildwithcardware';
const hideAutoPopulateOption = process.env.HIDE_AUTO_POPULATE_OPTION || false;

// const promoCode = 'buildwithcardware';
// const hideAutoPopulateOption = true;

const stripePromise = loadStripe(process.env.STRIPE_KEY);
const AdminSignUpMutation = gql`
  mutation singUp($data: AdminSignUpWithOrganizationInput) {
    adminSignUpWithOrganization(data: $data) {
      data
      status
      error
    }
  }
`;

const AdminUpdateMutation = gql`
  mutation singUp($data: AdminUpdateWithOrganizationInput) {
    adminUpdateWithOrganization(data: $data) {
      data
      status
      error
    }
  }
`;

const isExistingAdminUserMutation = gql`
  mutation isExistingAdminUser($email: String!) {
    isExistingAdminUser(email: $email)
  }
`
const InputContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

const InputLabel = styled.label`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;


const Payment = (props) => {
  const [css, theme] = useStyletron();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [products, setProducts] = React.useState([]);
  React.useEffect(() => {
    axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      setZip(data.postal);
      let c = Country.getAllCountries().find(el => {
        return el.isoCode == data.country_code
      });
      if (!c) return;
      setCountry(c);
      let s = State.getStatesOfCountry(c.isoCode).find(el => {
        return el.isoCode == data.region_code;
      });
      if (!s) return;
      setState(s);
      let ci = City.getCitiesOfState(c.isoCode, s.isoCode).find(el => {
        return el.name.toLowerCase() == data.city.toLowerCase();
      });
      if (ci) setCity(ci);
    }).catch((error) => {
      console.log(error);
    });
    try {
      axios.get(`${getServerUrl()}/cardware-subscription`)
        .then(res => {
          setProducts(res.data);
          setSelectedProduct(res.data);
          setIsReady(true);
        })
    } catch (error) {
      setIsReady(true);
    }
  }, []);

  const response = props.location.state.response || {};
  const email = response.admin.email;
  const pwa_id = response.pwa.id;
  const [name, setName] = React.useState(response.admin.name);
  const [address, setAddress] = React.useState('');

  const [country, setCountry] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [zip, setZip] = React.useState('');
  console.log(response, email, pwa_id, country, state, city, products);

  const [isReady, setIsReady] = React.useState(false);
  const [readyText, setReadyText] = React.useState('Setting Up Payments');
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [coupon, setCoupon] = React.useState('');

  const onSubscribe = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    };
    setIsLoading(true);
    setReadyText('Processing your transaction');
    setIsReady(false);
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });

    if (result.error) {
      setError(result.error.message);
      setIsLoading(false);
      setIsReady(true);
      return;
    }

    const res = await axios.post(`${getServerUrl()}/cardware-subscription`, { 'payment_method': result.paymentMethod.id, 'email': email, prev_response: response, coupon });
    const { client_secret, status, error } = res.data;
    if (status == 'error') {
      setError(error);
      setIsLoading(false);
      setIsReady(true);
      return;
    };
    if (status === 'requires_action') {
      stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name,
            address: {
              line1: address,
              postal_code: zip,
              city: city.name,
              state: state.isoCode,
              country: country.isoCode,
            }
          }
        },
        shipping: {
          name,
          address: {
            line1: address,
            postal_code: zip,
            city: city.name,
            state: state.isoCode,
            country: country.isoCode,
          }
        }
      }).then(function (result) {
        if (result.error) {
          setError(result.error.message);
          setIsLoading(false);
          setIsReady(true);
        } else {
          setReadyText('Payment Done :-)');
          setTimeout(function () {
            navigateGatsby(`/app/${response.organization.id}/groups/standard/channels`);
          }, 1000);
        }
      });
    } else {
      setReadyText('Payment Done :-)');
      setTimeout(function () {
        navigateGatsby(`/app/${response.organization.id}/groups/standard/channels`);
      }, 1000);
    }
  };
  if (!email) return;
  return <LoginContainer sliders={props.sliders || []} sloganSub={' '} height="500px">
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        color: theme.colors.primaryA,
      }}
    >
      Subscribe to Cardware Subscription Plans
    </div>
    {!isReady &&
      <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        marginTop: '24px',
        position: 'absolute',
        zIndex: 1,
        background: 'rgb(255 255 255 / 80%)',
        height: '100%',
        paddingTop: '48px'
      }}>
        <Spinner size={48} />
        <LabelMedium style={{ marginTop: '8px' }}>{readyText}</LabelMedium>
      </div>
    }

    <Cell
      span={12}
      overrides={{
        Cell: {
          style: () => ({
            marginTop: '32px',
          }),
        },
      }}
    >
      <div style={{
        marginTop: '16px',
        marginBottom: '16px',
      }}>
        <CardInput />
      </div>
      <div style={{
        marginBottom: '16px',
      }}>
        <Select
          options={products}
          value={selectedProduct}
          valueKey={'id'}
          placeholder="Select Product"
          labelKey={'name'}
          onChange={params => {
            setSelectedProduct(params.value);
          }}
        />
      </div>
      <div style={{
        marginBottom: '16px',
      }}>
        <Input
          value={coupon}
          onChange={e => setCoupon(e.target.value)}
          placeholder="Coupon Code"
          clearOnEscape
        // size={'mini'}
        />
      </div>
      <div style={{
        marginBottom: '16px',
      }}>
        <Input
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Name on the card"
          clearOnEscape
        // size={'mini'}
        />
      </div>

      <div style={{
        marginBottom: '16px',
      }}>
        <Input
          value={address}
          onChange={e => setAddress(e.target.value)}
          placeholder="Address"
          clearOnEscape
        // size={'mini'}
        />
      </div>

      <div style={{
        marginBottom: '16px',
        display: 'flex',
        justifyContent: 'space-between'
      }}>

        <div style={{
          width: '48%'
        }}>
          <Select
            options={country ? State.getStatesOfCountry(country.isoCode) : []}
            value={state ? [state] : null}
            placeholder="Select City"
            labelKey={'name'}
            valueKey={'isoCode'}
            onChange={params => {
              if (params.option) {
                setState(params.option)
              }
            }}
          />
          
        </div>
        <div style={{
          width: '48%'
        }}>
          <Select
            options={Country.getAllCountries()}
            value={country ? [country] : null}
            valueKey={'isoCode'}
            placeholder="Select Country"
            labelKey={'name'}
            onChange={params => {
              if (params.option) {
                setCountry(params.option)
              }
            }}
          />
        </div>
      </div>

      <div style={{
        marginBottom: '16px',
        display: 'flex',
        justifyContent: 'space-between'
      }}>

        <div style={{
          width: '48%'
        }}>
          <Select
            options={state ? City.getCitiesOfState(country.isoCode, state.isoCode) : []}
            value={city ? [city] : null}
            placeholder="Select City"
            labelKey={'name'}
            valueKey={'name'}
            onChange={params => {
              if (params.option) {
                setCity(params.option)
              }
            }}
          />
        </div>

        <div style={{
          width: '48%'
        }}>
          <Input
            value={zip}
            onChange={e => setZip(e.target.value)}
            placeholder="Zip Code"
            clearOnEscape
          // size={'mini'}
          />
        </div>
      </div>
      {error && <Notification
        kind={KIND.negative}
        overrides={{
          Body: { style: { width: 'auto' } },
        }}
      >
        {() => error}
      </Notification>}
      <Button
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              marginTop: '16px'
            }
          },
        }}
        onClick={onSubscribe}
        isLoading={isLoading}
      >
        Subscribe Now
      </Button>
    </Cell>
  </LoginContainer>
}

const AdminInfo = (props) => {
  const [css, theme] = useStyletron();
  const params = queryString.parse(props.location.search);
  const organization_id = params.organization_id;
  return <LoginContainer sliders={props.sliders || []} sloganSub={' '} height="500px">
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        color: theme.colors.primaryA,
      }}
    >
      Enter your account and application details below
    </div>
    <Cell
      span={12}
      overrides={{
        Cell: {
          style: () => ({
            marginTop: '32px',
          }),
        },
      }}
    >
      <InputContainer>
        <InputLabel>Name</InputLabel>
        <HelpQuestionMark
          text={'This is your username'}
          embedCss={{ marginTop: '0px', marginLeft: '8px', height: '18px' }}
        ></HelpQuestionMark>
      </InputContainer>
      <Input
        value={props.name}
        onChange={e => props.setName(e.target.value)}
        placeholder="Name"
        clearOnEscape
      />
      <InputContainer>
        <InputLabel>Email</InputLabel>
        <HelpQuestionMark
          text={'This is your email address'}
          embedCss={{ marginTop: '0px', marginLeft: '8px', height: '18px' }}
        ></HelpQuestionMark>
      </InputContainer>
      <Input
        value={props.email}
        onChange={e => {
          props.setEmail(e.target.value)
          props.setIsExistingUserEmail(false);
        }}
        placeholder="Email Address"
        error={props.isExistingUserEmail}
        clearOnEscape
      />
      <InputContainer>
        <InputLabel>Password</InputLabel>
        <HelpQuestionMark
          text={'This is your password'}
          embedCss={{ marginTop: '0px', marginLeft: '8px', height: '18px' }}
        ></HelpQuestionMark>
      </InputContainer>
      <Input
        value={props.password}
        placeholder="Password"
        onChange={e => props.setPassword(e.target.value)}
        type={"password"}
        clearOnEscape
      />
      <PasswordStrengthBar password={props.password} />
      <InputContainer>
        <InputLabel>Company Name</InputLabel>
        <HelpQuestionMark
          text={'This is the company name for your app'}
          embedCss={{ marginTop: '0px', marginLeft: '8px', height: '18px' }}
        ></HelpQuestionMark>
      </InputContainer>
      <Input
        value={props.companyName}
        onChange={e => props.setCompanyName(e.target.value)}
        // placeholder="Company Name"
        clearOnEscape
      />
      {/* <InputContainer>
        <label style={{
          fontFamily: 'IBM Plex Sans',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
        }}>App Name</label>
        <HelpQuestionMark
          text={'This is the name of the app as it appears on your phone or other device. It will be displayed under the icon on your device.'}
          embedCss={{ marginTop: '0px', marginLeft: '8px', height: '18px' }}
        ></HelpQuestionMark>
      </InputContainer>
      <Input
        value={props.appName}
        onChange={e => props.setAppName(e.target.value)}
        // placeholder="Company Name"
        clearOnEscape
      /> */}
      {/* {true && <>
        <InputContainer>
          <label style={{
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
          }}>Invitation Code</label>
          <HelpQuestionMark
            text={'This is the invitation code.'}
            embedCss={{ marginTop: '0px', marginLeft: '8px', height: '18px' }}
          ></HelpQuestionMark>
        </InputContainer>
        <Input
          value={props.promoInput}
          onChange={e => props.setPromoInput(e.target.value)}
          // placeholder="Invitation Code"
          clearOnEscape
        />

      </>} */}

      {/* <div style={{ width: '100%', marginTop: '16px' }}></div>
      {!organization_id && !hideAutoPopulateOption && <LabelSmall style={{ cursor: 'pointer' }} onClick={() => {
        props.setIsAddAuth0Info(!props.isAddAuth0Info);
      }}>{props.isAddAuth0Info ? '- ' : '+ '}Add Auth0 Info (If server does not have any auth setup already)</LabelSmall>}
      {props.isAddAuth0Info && <div style={{ padding: '8px', paddingLeft: '16px', paddingRight: '16px' }}>
        <div style={{ width: '100%', marginTop: '16px' }}></div>
        <Input
          value={props.domain}
          onChange={e => props.setDomain(e.target.value)}
          placeholder="Domain"
          clearOnEscape
        />
        <div style={{ width: '100%', marginTop: '16px' }}></div>
        <Input
          value={props.clientId}
          onChange={e => props.setClientId(e.target.value)}
          placeholder="Client ID"
          clearOnEscape
        />
        <div style={{ width: '100%', marginTop: '16px' }}></div>
        <Input
          value={props.clientSecret}
          onChange={e => props.setClientSecret(e.target.value)}
          placeholder="Client Secret"
          clearOnEscape
        />
      </div>}


      <div style={{ width: '100%', marginTop: '16px' }}></div>
      {!organization_id && !hideAutoPopulateOption && <LabelSmall style={{ cursor: 'pointer' }} onClick={() => {
        props.setIsAdvanceOptionOn(!props.isAdvanceOptionOn);
      }}>{props.isAdvanceOptionOn ? '- ' : '+ '}Advance</LabelSmall>}

      {
        props.isAdvanceOptionOn && <div style={{ padding: '8px', paddingLeft: '16px', paddingRight: '16px' }}>
          <div style={{ width: '100%', marginTop: '16px' }}></div>
          <Checkbox
            checked={props.autoPopulateCrunchBaseData}
            onChange={e => props.setAutoPopulateCrunchBaseData(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            Auto Populate Data
            </Checkbox>
          <div style={{ width: '100%', marginTop: '16px' }}></div>
          <Input
            value={props.dataSource}
            onChange={e => props.setDataSource(e.target.value)}
            placeholder="Data Source"
            clearOnEscape
          />
          <div style={{ width: '100%', marginBottom: '16px' }}></div>
        </div>
      } */}

      <div style={{ width: '100%', marginTop: '16px' }}></div>
      <Checkbox
        checked={props.termsAndConditions}
        onChange={e => props.setTermsAndConditions(e.target.checked)}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        <LabelSmall>By completing this form I adhere to the Cardware <span style={{
          textDecoration: 'underline'
        }} onClick={() => {
          window.open(`${getServerUrl()}/privacy`, '_blank');
        }}>Terms of Use and Privacy Policy</span></LabelSmall>
      </Checkbox>

      <div style={{ width: '100%', marginTop: '16px' }}></div>
      {props.organizationInfoAllFieldsRequired && <Notification
        kind={KIND.negative}
        overrides={{
          Body: { style: { width: 'auto' } },
        }}
      >
        {() => 'All fields are required'}
      </Notification>}
      {props.termsAndConditionRequired && <Notification
        kind={KIND.negative}
        overrides={{
          Body: { style: { width: 'auto' } },
        }}
      >
        {() => 'Please check terms and conditions.'}
      </Notification>}
      {props.codeMismatch && <Notification
        kind={KIND.negative}
        overrides={{
          Body: { style: { width: 'auto' } },
        }}
      >
        {() => 'Invitation code is not valid.'}
      </Notification>}
      <Button
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              marginTop: '16px'
            }
          },
        }}
        onClick={props.onCreateOrganization}
        isLoading={props.isLoading}
      >
        Create Organization
      </Button>
      {props.isError && <Notification
        kind={KIND.negative}
        overrides={{
          Body: { style: { width: 'auto' } },
        }}
      >
        {() => props.errMessage}
      </Notification>}



      {props.isExistingUserEmail && <p style={{ color: 'red', margin: 0, fontSize: '15px', marginTop: '8px' }}>User email already exists!</p>}

      <div style={{ width: '100%', marginTop: '16px' }}></div>
      {props.adminInfoAllFieldsRequired && <Notification
        kind={KIND.negative}
        overrides={{
          Body: { style: { width: 'auto' } },
        }}
      >
        {() => 'All fields are required'}
      </Notification>}
      {/* <Button
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              marginTop: '16px'
            }
          },
        }}
        onClick={props.onSignUp}
        isLoading={props.isLoading}
      >
        Sign Up
  </Button> */}
    </Cell>
  </LoginContainer>
}

const OrganizationInfo = (props) => {
  const [css, theme] = useStyletron();
  const [error, setError] = React.useState(null);
  const response = props.location.state.response || {};
  const organization_id = response.organization.id;
  const template_id = response.template.id;
  const [isLoading, setIsLoading] = React.useState(false);

  const [logoPreview, setLogoPreview] = React.useState(null);
  const [logoUpload, setLogoUpload] = React.useState(null);

  const [appIconPreview, setAppIconPreview] = React.useState(null);
  const [appIconUpload, setAppIconUpload] = React.useState(null);


  const onUpload = async () => {
    console.log(logoUpload, appIconUpload);
    setIsLoading(true);
    // setTimeout(() => {
    //   setIsLoading(false);
    //   navigate('/signup/payment', {
    //     state: { response }
    //   });
    // }, 1500);

    await Promise.all([
      client.mutate({
        mutation: gql`
        mutation updateOrganization(
          $id: ID!
          $logo: Upload
        ) {
          updateOrganization(
            id: $id
            logo: $logo
          ) {
            logo_data
          }
        }
        `,
        variables: {
          id: organization_id,
          logo: logoUpload
        }
      })
      ,
      client.mutate({
        mutation: updatePwaTemplate,
        variables: {
          id: template_id,
          input: {
            icon: appIconUpload,
            organization_id
          },
        },
      }).then(() => {
        setIsLoading(false);
        navigate('/signup/payment', {
          state: { response }
        });
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      })
    ])
  };

  const skipUpload = () => {
    navigate('/signup/payment', {
      state: { response }
    });
  }

  return <LoginContainer sliders={props.sliders || []} sloganSub={' '} height={'calc(100% - 200px)'}>
    <div style={{
      width: '100%',
      height: '100%',
      // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    }}>
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          color: theme.colors.primaryA,
          marginBottom: '0.5rem'
        }}
      >
        Upload your app branding and artwork below
    </div>
      <div style={{
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
      }}>
        <Cell
          span={12}
          overrides={{
            Cell: {
              style: () => ({
                marginTop: '32px',
              }),
            },
          }}
        >
          <div style={{ width: '100%', marginTop: '16px' }}></div>
          <ImageUploadCXL
            title={'Organization Logo'}
            titleHelpText={'Select the logo for organization'}
            previewUrls={logoPreview}
            isMultiple={false}
            onDrop={(acceptedFiles, rejectedFiles) => {
              setLogoUpload(acceptedFiles);
              setLogoPreview(URL.createObjectURL(acceptedFiles[0]));
            }}
            onImageDelete={() => {
              setLogoUpload(null);
              setLogoPreview(null);
            }}
            showFullBleedSettings={false}
            imageSize={organizationLogo.size}
            imageSizeGuidelineURL={organizationLogo.url}
          />
          <div style={{ width: '100%', marginTop: '16px' }}></div>
          <ImageUploadCXL
            title={'App Icon'}
            titleHelpText={'Select the app icon'}
            previewUrls={appIconPreview}
            isMultiple={false}
            onDrop={(acceptedFiles, rejectedFiles) => {
              setAppIconUpload(acceptedFiles);
              setAppIconPreview(URL.createObjectURL(acceptedFiles[0]));
            }}
            onImageDelete={() => {
              setAppIconUpload(null);
              setAppIconPreview(null);
            }}
            showFullBleedSettings={false}
            imageSize={appIconSizeGuideLine.size}
            imageSizeGuidelineURL={appIconSizeGuideLine.url}
          />

          <Button
            overrides={{
              BaseButton: {
                style: {
                  width: '100%',
                  marginTop: '16px'
                }
              },
            }}
            onClick={onUpload}
            isLoading={isLoading}
          >
            Upload Logo & Icon
          </Button>

          <LabelSmall style={{
            marginTop: '4px',
            textAlign: 'center',
            textDecoration: 'underline',
            cursor: 'pointer'
          }} onClick={skipUpload}>I want to skip uploading app artwork for now</LabelSmall>
          {error && <Notification
            kind={KIND.negative}
            overrides={{
              Body: { style: { width: 'auto' } },
            }}
          >
            {() => error}
          </Notification>}
        </Cell>
      </div>
    </div>
  </LoginContainer>
};


let interval;
const SignUpLoading = (props) => {
  const pwa_id = props.id;
  const fetchStatus = () => {
    client.query({
      query: gql`
        query pwa {
          pwa_app(id: "${pwa_id}") {
            heroku_deployment_status
          }
        }
      `,
      fetchPolicy: 'network-only'
    }).then(result => {
      let d = result.data.pwa_app;
      console.log('status received', d);
      if (d.heroku_deployment_status == 'failed') {
        interval && clearInterval(interval);
        navigate(`/signup/failed/${pwa_id}`);
        return;
      }
      if (d.heroku_deployment_status != 'pending') {
        interval && clearInterval(interval);
        navigate(`/signup/success/${pwa_id}`);
      }
    })
  }
  React.useEffect(() => {
    fetchStatus();
    interval = setInterval(() => {
      fetchStatus();
    }, 30 * 1000);
  }, []);
  return <LoginContainer hasSliders={false} width={'100vw'} hideSlogan={true}>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    }}>
      <Spinner />
      <HeadingLarge style={{ marginTop: '8px' }}>
        Your app environment is being built. Please wait...
      </HeadingLarge>
    </div>
  </LoginContainer>;
}

const SignUpSuccess = (props) => {
  const pwa_id = props.id;
  const [app_url, set_app_url] = React.useState('');
  React.useEffect(() => {
    client.query({
      query: gql`
          query pwa {
            pwa_app(id: "${pwa_id}") {
              heroku_deployment_status
              app_url
            }
          }
        `
    }).then(result => {
      debugger
      let d = result.data.pwa_app;
      console.log('status received', d);
      set_app_url(d.app_url);
    })
  })
  return <LoginContainer hasSliders={false} hideSlogan={true}>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    }}>
      <HeadingLarge style={{ marginTop: '8px' }}>
        Congrats your app is built!
      </HeadingLarge>
      <Button
        overrides={{
          BaseButton: { style: { width: '100%', marginTop: '16px' } },
        }}
        onClick={() => {
          let admin_url = window.location.origin;
          window.open(admin_url, '_blank').focus();
        }}
      >
        Take me to my admin
      </Button>
      <Button
        overrides={{
          BaseButton: { style: { width: '100%', marginTop: '16px' } },
        }}
        onClick={() => {
          window.open(app_url, '_blank').focus();
        }}
      >
        I want to view my app
      </Button>
    </div>
  </LoginContainer>;
}

const SignUpFailed = (props) => {
  const pwa_id = props.id;
  const [app_url, set_app_url] = React.useState('');
  React.useEffect(() => {
    client.query({
      query: gql`
          query pwa {
            pwa_app(id: "${pwa_id}") {
              heroku_deployment_status
              app_url
              failure_message
            }
          }
        `
    }).then(result => {
      debugger
      let d = result.data.pwa_app;
      console.log('status received', d);
      try {
        d = JSON.parse(d.failure_message);
        set_app_url(d.build.output_stream_url);
      } catch (e) {

      }
    })
  })

  const rebuildApp = () => {

  }

  return <LoginContainer hasSliders={false} hideSlogan={true}>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    }}>
      <HeadingLarge style={{ marginTop: '8px' }}>
        Congrats your admin has been built!
      </HeadingLarge>
      <Button
        overrides={{
          BaseButton: { style: { width: '100%', marginTop: '16px' } },
        }}
        onClick={() => {
          let admin_url = window.location.origin;
          window.open(admin_url, '_blank').focus();
        }}
      >
        Take me to my admin
      </Button>
      <Button
        overrides={{
          BaseButton: { style: { width: '100%', marginTop: '16px' } },
        }}
        onClick={() => {
          window.open(app_url, '_blank').focus();
        }}
      >
        View Build Logs
      </Button>
    </div>
  </LoginContainer>;
}

export default function SignUp(props) {
  const { data: sliderData } = useQuery(getAdminPageSliders);
  const [sliders, setSliders] = React.useState([]);
  React.useEffect(() => {
    if (sliderData && sliderData.adminIndexPageSliders && sliderData.adminIndexPageSliders.length > 0) {
      setSliders(sliderData.adminIndexPageSliders);
    }
  }, [sliderData]);

  const [isLoading, setIsLoading] = React.useState(false);

  const [email, setEmail] = React.useState('');
  const [isExistingUserEmail, setIsExistingUserEmail] = React.useState(false)
  const [name, setName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [buildPath, setBuildPath] = React.useState('')

  const [adminInfoAllFieldsRequired, setAdminInfoAllFieldsRequired] = React.useState(false);
  const [organizationInfoAllFieldsRequired, setOrganizationInfoAllFieldsRequired] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState('');

  const [companyName, setCompanyName] = React.useState('');
  const [appName, setAppName] = React.useState('');
  const [isAddAuth0Info, setIsAddAuth0Info] = React.useState(false);
  const [domain, setDomain] = React.useState('');
  const [clientId, setClientId] = React.useState('');
  const [clientSecret, setClientSecret] = React.useState('');

  const [isAdvanceOptionOn, setIsAdvanceOptionOn] = React.useState(false);
  const [autoPopulateCrunchBaseData, setAutoPopulateCrunchBaseData] = React.useState(false);

  const [termsAndConditions, setTermsAndConditions] = React.useState(false);
  const [promoInput, setPromoInput] = React.useState('buildwithcardware');

  const [codeMismatch, setCodeMismatch] = React.useState(false);

  const [termsAndConditionRequired, setTermsAndConditionRequired] = React.useState(false);

  const [dataSource, setDataSource] = React.useState('organization-data');

  React.useEffect(() => {
    setAppName(companyName)
  }, [companyName]);


  const onCreateOrganization = async () => {
    if (!name || !password || !email || !companyName || !appName) {
      return setAdminInfoAllFieldsRequired(true);
    };
    setIsLoading(true);
    setAdminInfoAllFieldsRequired(false);

    const adminCheck = await client.mutate({
      mutation: isExistingAdminUserMutation,
      variables: {
        email: email
      }
    });

    if (!adminCheck.data.isExistingAdminUser) {
    } else {
      // existing user email
      setIsLoading(false);
      setIsExistingUserEmail(true);
      return;
    };

    if (promoInput.toLowerCase().trim() != promoCode) {
      setIsLoading(false);
      return setCodeMismatch(true);
    } else setCodeMismatch(false);

    if (!termsAndConditions) {
      setIsLoading(false);
      return setTermsAndConditionRequired(true);
    } else setTermsAndConditionRequired(false);

    setIsError(false);
    setOrganizationInfoAllFieldsRequired(false);
    setIsLoading(true);
    console.log('onCreateOrganization');
    console.log(`${name} ${email} ${password} \n ${companyName} ${appName}`);
    let params = queryString.parse(props.location.search);
    let mutation = AdminSignUpMutation;
    let mutationPayload = {
      name, email, password, companyName, appName, buildPath, domain, clientId, clientSecret, autoPopulateCrunchBaseData,
      logo: null,
      appIcon: null,
      data_source: dataSource,
      current_admin_location: window.location.origin
    }
    if (params.organization_id) {
      mutation = AdminUpdateMutation;
      mutationPayload.organization_id = params.organization_id
    }

    client.mutate({
      mutation,
      variables: {
        data: mutationPayload
      }
    }).then((result) => {
      console.log(result);
      setIsLoading(false);
      let data = result.data.adminSignUpWithOrganization;
      if (params.organization_id) {
        data = result.data.adminUpdateWithOrganization;
      };
      setEmailToCookie(email);
      if (data.status) {
        let response = JSON.parse(data.data);
        let params = queryString.parse(props.location.search);
        // making the new admin as logged in to the system
        setLastLoggedInOrgId(response.organization.id);
        setAuth0Token(response.authLogin.idToken);
        setUserInfoToStateFromToken(response.authLogin.idToken, response.organization.id, response.auth.domain, () => {
          // navigate(`/app/${organization.id}/groups/standard/channels`);
          // if (params.organization_id) {
          //   setTimeout(() => {
          //     navigate(`/signup/organizations?organization_id=${params.organization_id}`, {
          //       state: { response }
          //     });
          //   }, 100);
          //   return;
          // }
          // navigate('/signup/organizations', {
          //   state: { response }
          // });
          localStorage.setItem('signup_response', JSON.stringify(response));
          navigateGatsby(`/app/${response.organization.id}/groups/standard/channels`);
        });
      } else {
        setIsLoading(false);
        setIsError(true);
      }
    }).catch((error) => {
      setEmailToCookie(email);
      setIsLoading(false);
      setIsError(true);
      setErrMessage(error.message);
    })
  };

  return (
    // <LoginContainer sliders={sliders || []} sloganSub={' '} height="600px">
    <Elements stripe={stripePromise}>
      <Router>
        <AdminInfo
          email={email}
          setEmail={setEmail}
          isExistingUserEmail={isExistingUserEmail}
          setIsExistingUserEmail={setIsExistingUserEmail}
          password={password}
          setPassword={setPassword}
          name={name}
          setName={setName}
          // onSignUp={onSignUp}
          companyName={companyName}
          setCompanyName={setCompanyName}
          appName={appName}
          setAppName={setAppName}
          promoInput={promoInput}
          setPromoInput={setPromoInput}
          sliders={sliders}
          adminInfoAllFieldsRequired={adminInfoAllFieldsRequired}
          isLoading={isLoading}
          buildPath={buildPath}
          setBuildPath={setBuildPath}
          organizationInfoAllFieldsRequired={organizationInfoAllFieldsRequired}
          onCreateOrganization={onCreateOrganization}
          isLoading={isLoading}
          isAddAuth0Info={isAddAuth0Info}
          setIsAddAuth0Info={setIsAddAuth0Info}
          domain={domain}
          setDomain={setDomain}
          clientId={clientId}
          setClientId={setClientId}
          clientSecret={clientSecret}
          setClientSecret={setClientSecret}
          isAdvanceOptionOn={isAdvanceOptionOn}
          setIsAdvanceOptionOn={setIsAdvanceOptionOn}
          autoPopulateCrunchBaseData={autoPopulateCrunchBaseData}
          setAutoPopulateCrunchBaseData={setAutoPopulateCrunchBaseData}
          isError={isError}
          errMessage={errMessage}
          termsAndConditions={termsAndConditions}
          setTermsAndConditions={setTermsAndConditions}
          codeMismatch={codeMismatch}
          termsAndConditionRequired={termsAndConditionRequired}
          dataSource={dataSource}
          setDataSource={setDataSource}
          path="/signup"
        ></AdminInfo>

        <OrganizationInfo
          sliders={sliders}
          path="/signup/organizations"
        ></OrganizationInfo>
        <SignUpLoading
          path="/signup/loading/:id"
        ></SignUpLoading>
        <SignUpSuccess
          path="/signup/success/:id"
        ></SignUpSuccess>
        <SignUpFailed
          path="/signup/failed/:id"
        ></SignUpFailed>
        <Payment
          path="/signup/payment"
        ></Payment>
      </Router>
    </Elements>
    // </LoginContainer>
  );
}