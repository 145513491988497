import React from 'react';

export const ArrowDownIcon = () => {
    return (
        <span className='svgIcon'>
            <svg class="svgIcon" preserveAspectRatio="xMidYMid" viewBox="0 0 24 24" fill='#333b4c'><path d="M7 10l5 5 5-5z"></path></svg>
        </span>
    )
}
export const ArrowRightIcon = () => {
    return (
        <span className='svgIcon' style={{ transform: 'rotate(-90deg)' }}>
            <svg class="svgIcon" preserveAspectRatio="xMidYMid" viewBox="0 0 24 24" fill='#333b4c'><path d="M7 10l5 5 5-5z"></path></svg>
        </span>
    )
}
export const DeleteIcon = () => {
    return (
        <span style={{ width: '16px', height: '16px', marginRight: '1rem' }}>
            <svg data-test="icon" class="Icon__StyledIcon-NfKxR gXQWeA asStyledComponent__WrappedComponent-kakoBK PopoverMenuItem__StyledIcon-sc-1hwba4h-1 bsepId evpWQf" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid"><path d="M4,21.3333333 C4,22.8 5.2,24 6.66666667,24 L17.3333333,24 C18.8,24 20,22.8 20,21.3333333 L20,5.33333333 L4,5.33333333 L4,21.3333333 Z M21.3333333,1.33333333 L16.6666667,1.33333333 L15.3333333,0 L8.66666667,0 L7.33333333,1.33333333 L2.66666667,1.33333333 L2.66666667,4 L21.3333333,4 L21.3333333,1.33333333 Z" class="Icon__StyledPath-hyHnbn fPidzV"></path></svg>
        </span>
    )
}

export const FolderIcon = (props) => {
    return (
        <span className='icon' style={{ width: '14px', height: '16px', marginRight: '1rem' }}>
            <svg width={props.width || 16} height={props.height || 16} fill={props.color || '#333b4c'} viewBox="0 0 24 24"><path d="M9.6 2.4H2.4A2.4 2.4 0 0 0 .01 4.8L0 19.2a2.4 2.4 0 0 0 2.4 2.4h19.2a2.4 2.4 0 0 0 2.4-2.4v-12a2.4 2.4 0 0 0-2.4-2.4H12L9.6 2.4z"></path></svg>
        </span>
    )
}
export const DotIcon = (props) => {
    return (     
        <svg data-test="icon" fill={props.color || 'black'} color="contrastHigh" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid" class="sc-dkrFOg dduJoZ"><path d="M12 6c1.6 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.4 3 3 3zm0 3c-1.6 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.4-3-3-3zm0 9c-1.6 0-3 1.4-3 3s1.3 3 3 3 3-1.4 3-3a3 3 0 0 0-3-3z"></path></svg>
    )
}
export const CopyIcon = () => {
    return (
        <span className='icon' style={{ width: '14px', height: '16px', marginRight: '1rem' }}>
            <svg data-test="icon" class="Icon__StyledIcon-NfKxR gXQWeA asStyledComponent__WrappedComponent-kakoBK PopoverMenuItem__StyledIcon-sc-1hwba4h-1 bsepId evpWQf" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid"><path d="M8.9 17.6L3.4 12l5.5-5.5-1.7-1.7L0 12l7.2 7.2 1.7-1.6zm6.2 0l5.5-5.5-5.5-5.5 1.7-1.7L24 12l-7.2 7.2-1.7-1.6z" class="Icon__StyledPath-hyHnbn fPidzV"></path></svg>
        </span>
    )
}