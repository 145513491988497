import React, { useState, useEffect } from 'react';
import { LabelSmall } from 'baseui/typography';
import { Input } from 'baseui/input';
import CardPicker from './cardPickerCXL';
import { SelectCXL } from '../../builder/cardBackEditorComponents/select';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import ChannelPickerCXL from '../../channelPickerCXL';
import store from '../../../redux/store';
import { Select } from 'baseui/select';


export default ({ value: action, onChange, theme }) => {
  const [actionData, setActionData] = useState({});
  const ORGANIZATION = store.getState().appBasicControls.organization || {};
  useEffect(() => {
    if (!action) {
      setActionData({});
      onChange({});
      return;
    };
    if (!action.action_type) {
      action.action_type = '';
    }
    setActionData(action);
  }, [action]);

  const setActionValue = (key, value) => {
    let o = { ...actionData };
    o[key] = value;
    if (key == 'action_type') {
      o.action_resource = '';
    }
    setActionData(o);
    onChange(o);
  };

  const action_type = actionData.action_type;

  const getChannelIdFroMetadata = (m) => {
    try {
      return m.channel_ids || m.params.id;
    } catch (err) {
      return null;
    }
  }

  return <div style={{ paddingLeft: '0px', paddingRight: '0px' }}>
    <div style={{ marginBottom: '16px' }}>
      <LabelSmall style={{ marginBottom: '8px' }}>Action Type</LabelSmall>
      <SelectCXL
        options={
          [
            { label: 'video', id: 'video' },
            { label: 'image', id: 'image' },
            { label: 'card', id: 'card' },
            { label: 'external link', id: 'link' },
            { label: 'internal action', id: 'internal_action' }
          ]
        }
        onChange={(v) => {
          setActionValue('action_type', v);
        }}
        theme={theme}
        value={actionData['action_type']}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
      ></SelectCXL>
    </div>
    <LabelSmall style={{ marginBottom: '8px' }}>Action Resource</LabelSmall>
    {action_type == 'video' && <>
      <LabelSmall style={{ marginBottom: '16px' }}>Video URL</LabelSmall>
      <Input
        value={actionData['action_resource']}
        onChange={e => {
          setActionValue('action_resource', e.target.value)
        }}
        placeholder="Video URL"
        clearOnEscape
      />
    </>}
    {action_type == 'image' && <>
      <LabelSmall style={{ marginBottom: '16px' }}>Image URL</LabelSmall>
      <Input
        value={actionData['action_resource']}
        onChange={e => {
          setActionValue('action_resource', e.target.value)
        }}
        placeholder="Image URL"
        clearOnEscape
      />
    </>}
    {action_type == 'card' && <>
      <LabelSmall style={{ marginBottom: '16px' }}>Select Card</LabelSmall>
      <CardPicker
        selectedCardId={actionData['action_resource']}
        onCardSelect={(id) => setActionValue('action_resource', id)}
        theme={theme}
        placeholder={'Select Card'}
      />
    </>}
    {action_type == 'link' && <>
      <LabelSmall style={{ marginBottom: '16px' }}>External Link</LabelSmall>
      <Input
        value={actionData['action_resource']}
        onChange={e => {
          setActionValue('action_resource', e.target.value)
        }}
        placeholder="External URL"
        clearOnEscape
      />
    </>}
    {action_type == 'internal_action' && <>
      <LabelSmall style={{ marginBottom: '16px' }}>Select Action</LabelSmall>
      <SelectCXL
        options={
          [
            { label: 'Channel', id: 'Home' },
            { label: 'Login', id: 'Login' },
            { label: 'Sign Up', id: 'Signup' },
            // { label: 'Travel', id: 'Travel' },
            // { label: 'Account', id: 'Account' },
            { label: 'Notification', id: 'Notification' },
            // { label: 'Chat', id: 'Chat' },
            { label: 'Profile', id: 'ProfileEdit' },
            { label: 'Article', id: 'ArticleStack' },
            { label: 'Subscription', id: 'Subscription' },
            { label: 'Restore', id: 'Restore' },
            { label: 'Redeem Code', id: 'RedeemCode' },
            { label: 'Link Account With Subscription', id: 'LinkSubscription' },
            { label: 'Map', id: 'Map' },
            { label: 'Event', id: 'Event' },
          ]
        }
        onChange={(v) => {
          setActionValue('action_resource', v);
        }}
        theme={theme}
        value={actionData['action_resource']}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
      ></SelectCXL>
    </>}
    {/* {action_type == 'internal_action' && <>
      <LabelSmall style={{ marginTop: '16px', marginBottom: '16px' }}>Action Query</LabelSmall>
      <Input
        value={action['action_query']}
        onChange={e => {
          setActionValue('action_query', e.target.value);
          let a;
          debugger;
          try {
            a = JSON.parse(action['action_metadata'])
          } catch (e) {
            a = {}
          };
          if (!a) a = {}
          a['query'] = e.target.value;
          setActionValue('action_metadata', JSON.stringify(a));
        }}
        placeholder="category=Free Parking"
        clearOnEscape
      />
    </>} */}

    {actionData['action_resource'] == "Home" && <div style={{ marginTop: '16px', marginBottom: '16px' }}>
      <ChannelPickerCXL
        selectedChannelId={getChannelIdFroMetadata(actionData['action_metadata'] ? JSON.parse(actionData['action_metadata']) : {})}
        onChannelSelect={(id, channel) => {
          let screen = channel.is_file_explorer ? 'ChannelFileExplorer' : 'Channel';
          let obj = {
            screen,
            params: { id }
          };
          console.log(obj);
          setActionValue('action_metadata', JSON.stringify(obj));
        }}
        title={''}
        theme={theme}
        marginTop={'0px'}
        placeholder={'Select Channel'}
      />
    </div>}

    {(actionData['action_resource'] == "Subscription" || actionData['action_resource'] == "Restore" ) && <div style={{ marginTop: '16px', marginBottom: '16px' }}>
      <LabelSmall style={{ marginBottom: '16px', marginTop: '16px' }}>Channels</LabelSmall>
      <ChannelPickerCXL
        selectedChannelId={getChannelIdFroMetadata(actionData['action_metadata'] ? JSON.parse(actionData['action_metadata']) : {})}
        onChannelSelect={(id) => {
          console.log(id);
          let am = actionData['action_metadata'] ? JSON.parse(actionData['action_metadata']) : {};
          let obj = {
            ...am,
            channel_ids: id
          };
          setActionValue('action_metadata', JSON.stringify(obj));
        }}
        title={''}
        theme={theme}
        marginTop={'0px'}
        placeholder={'Select Channels'}
        multi
      />
      <LabelSmall style={{ marginBottom: '16px', marginTop: '16px' }}>Subscription</LabelSmall>
      <Select
        options={ORGANIZATION.subscriptions}
        value={ORGANIZATION.subscriptions.filter(el => {
          let am = actionData['action_metadata'] ? JSON.parse(actionData['action_metadata']) : {};
          return am.subscription_id == el.id
        })}
        labelKey="name"
        valueKey="id"
        placeholder='Select Subscription'
        onChange={(res) => {
          if (res.option && res.option.id) {
            let am = actionData['action_metadata'] ? JSON.parse(actionData['action_metadata']) : {};
            let obj = {
              ...am,
              subscription_id: res.option.id
            };
            setActionValue('action_metadata', JSON.stringify(obj));
          }
        }}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
      />
    </div>}

    {action_type == 'internal_action' && actionData['action_resource'] !== "Home" && actionData['action_resource'] !== "Subscription" &&  actionData['action_resource'] !== "Restore" && <>
      <LabelSmall style={{ marginTop: '16px', marginBottom: '16px' }}>Action Metadata</LabelSmall>
      <JSONInput
        id='a_unique_id____1'
        placeholder={actionData['action_metadata'] ? JSON.parse(actionData['action_metadata']) : {}}
        theme="dark_vscode_tribute"
        colors={{
          string: "#DAA520" // overrides theme colors with whatever color value you want
        }}
        locale={locale}
        height='550px'
        width={'40vw'}
        onChange={e => {
          console.log(e.jsObject);
          setActionValue('action_metadata', JSON.stringify(e.jsObject));
        }}
      />
    </>}
  </div>;
}