import * as React from 'react';
import Button from '../common/button';
import StyleButton from '../styledButton';
import { Plus } from 'baseui/icon';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { getUserTags } from '../../quries';
import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from 'baseui/checkbox';
import { useStyletron } from 'baseui';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Input } from 'baseui/input';
import { KIND as ButtonKind } from 'baseui/button';
import { Grid, Cell } from 'baseui/layout-grid';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import UserTags from './userTags'
import { client } from '../../apollo/client';
import RightSidePageHeader from '../pageHeaders';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import HelpQuestionMark from '../help/questionMarkHelp';
import Toggle from 'react-toggle';
import { Select, Value } from 'baseui/select';
import 'react-toggle/style.css';

import DisabledArea from '../DisabledArea';
import UserEmailPermissions, { defaultFormPermissions } from './userEmailPermissions';

const UpdateUser = ({
  updateUser,
  appChannels,
  isUpdateUserWithChannelLoading,
  isEditOpen,
  type,
  errorInUserUpdate,
  groups,
  user,
  onClose,
  updatedUser,
  organization_id
}) => {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState(user.first_name);
  const [lastName, setLastName] = React.useState(user.last_name);
  const [email, setEmail] = React.useState(user.email);
  const [selectedChannels, setSelectedChannels] = React.useState(user.channels.filter((el) => el && el.organization_id == organization_id));
  const [isLoading, setIsLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(user.channels.length == appChannels.length);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState(user.job_title);
  const [allChannelsPerUser, setAllChannelsPerUser] = React.useState(user.has_all_channels_permission);
  const [userTags, setUserTags] = React.useState([]);
  const [formEmailPermissions, setFormEmailPermissions] = React.useState(user.form_email_permissions || defaultFormPermissions);

  const [devices, setDevices] = React.useState([]);

  React.useEffect(() => {
    try {
      let t = JSON.parse(user.devices);
      if (Array.isArray(t)) {
        setDevices(t);
      }
    } catch (e) { }
  }, []);

  const handleCancel = () => {
    setIsOpen(false);
    onClose();
  };
  const TAB_STATUS = {
    USER: 'User',
    TAGS: 'Tags',
    DEVICES: 'Devices',
    PERMISSIONS: 'Email Permissions',
  };

  const pageTitlesAndDescriptions = {
    [TAB_STATUS.USER]: {
      title: `Add ${type}`,
      description: ''
    },
    [TAB_STATUS.TAGS]: {
      title: 'Tags',
      description: 'Assign tags to the user'
    },
    [TAB_STATUS.PERMISSIONS]: {
      title: 'Email Permissions',
      description: 'Assign email permissions to the user'
    }

  }
  const [tab, setTab] = React.useState(TAB_STATUS.USER);
  React.useEffect(() => {
    setIsOpen(isEditOpen);
    loadUserTags()
  }, [isEditOpen]);

  const handleCreateUser = () => {
    let tags = []
    userTags.map(e => {
      if (e.checked != false) {
        tags.push(e.tag_id)
      }
    })
    let channels = selectedChannels.map(el => el.id);
    // if (!firstName || !email) {
    //   setErrorMessage('All fields required!');
    //   return false;
    // }
    // if (channels.length == 0 && allChannelsPerUser == false) {
    //   setErrorMessage('All fields required!');
    //   return false;
    // };

    let updatedPermissions = {
      ...(formEmailPermissions || {})
    }

    delete updatedPermissions.__typename;

    updateUser(user.id, {
      firstName,
      lastName,
      channels,
      email,
      jobTitle,
      hasAllChannelsPermission: allChannelsPerUser,
      userTags: tags,
      form_email_permissions: updatedPermissions,
      devices: JSON.stringify(devices)
    });
  };

  React.useEffect(() => {
    if (isUpdateUserWithChannelLoading) {
      setIsLoading(true);
      return;
    }
    if (errorInUserUpdate) {
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    updatedUser && handleCancel();
  }, [isUpdateUserWithChannelLoading]);
  React.useEffect(() => {
    errorInUserUpdate && setErrorMessage(errorInUserUpdate.message);
  }, [errorInUserUpdate]);


  const loadUserTags = () => {
    client.query({
      query: getUserTags(user.id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setUserTags(data.user_tag_by_user)
    })
  };
  return (
    <>
      <Modal
        onClose={() => handleCancel()}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        returnFocus={false}
        size={SIZE.default}
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              width: '70vw',
              display: 'flex',
              flexDirection: 'column',
            },
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Root: {
            style: {
              zIndex: 3
            }
          }
        }}
      >
        <ModalHeader style={{ textTransform: 'capitalize' }}>Update {type}
          <RightSidePageHeader
            // icon={null}
            // title={pageTitlesAndDescriptions[tab].title}
            rightContent={
              <StatefulButtonGroup
                mode={MODE.radio}
                initialState={{ selected: 0 }}
              >
                {Object.keys(TAB_STATUS).map(el => {
                  if (type !== 'admin' && TAB_STATUS.PERMISSIONS == TAB_STATUS[el]) { return null; }
                  return (
                    <StyleButton onClick={() => setTab(TAB_STATUS[el])}>
                      {TAB_STATUS[el]}
                    </StyleButton>
                  );
                })}
              </StatefulButtonGroup>
            }
          // headerDescription={pageTitlesAndDescriptions[tab].description}
          ></RightSidePageHeader>
        </ModalHeader>
        <ModalBody>
          {tab == TAB_STATUS.USER && (
            <Grid overrides={gridPaddingOverrides}>
              <Cell span={6} overrides={cellPaddingOverrides}>
                <LabelMedium
                  style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                  }}
                >
                  First Name
                </LabelMedium>
                <Input
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  placeholder="First Name"
                  clearOnEscape
                />
                <LabelMedium
                  style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                  }}
                >
                  Last Name
                </LabelMedium>
                <Input
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  placeholder="Last Name"
                  clearOnEscape
                />
                <LabelMedium
                  style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                  }}
                >
                  Email
                </LabelMedium>
                <Input
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email"
                  clearOnEscape
                  disabled
                />
                <LabelMedium
                  style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                  }}
                >
                  Job Title
                </LabelMedium>
                <Input
                  value={jobTitle}
                  onChange={e => setJobTitle(e.target.value)}
                  placeholder="Job Title"
                  clearOnEscape
                />
                <div style={{
                  marginTop: '16px',
                  marginBottom: '8px',
                }}>
               
                </div>
                {errorMessage && (
                  <LabelMedium
                    style={{ color: theme.colors.negative, marginTop: '16px' }}
                  >
                    {errorMessage}
                  </LabelMedium>
                )}
              </Cell>
              <Cell span={6} overrides={cellPaddingOverrides}>
                <div>
                  <div
                    className={css({
                      paddingLeft: '18px',
                      width: '100%',
                      height: '100%',
                      overflow: 'scroll',
                      maxHeight: '368px',
                    })}
                  >
                    <LabelMedium
                      style={{
                        marginTop: '16px',
                        marginBottom: '8px',
                      }}
                    >
                      Channels
                </LabelMedium>
                    <div className={'user-toggle-container'} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', paddingLeft: '5px' }}>
                      <Toggle
                        id="cheese-status"
                        defaultChecked={allChannelsPerUser}
                        icons={false}
                        onChange={(e) => { setAllChannelsPerUser(e.target.checked); }}
                      ></Toggle><p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem', marginRight: '0.5rem', marginTop: '3px' }}>Channel Super Admin</p>
                      <HelpQuestionMark backgroundColor='black' text={`This setting will provide access to the admin for all current and future channels for this organization.`} button />
                    </div>
                    <DisabledArea isDisabled={allChannelsPerUser}>
                      <div className={'user-toggle-container'} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: '0.3rem' }}>
                        <Toggle
                          id="cheese-status"
                          defaultChecked={checked}
                          icons={false}
                          onChange={(e) => {
                            setChecked(e.target.checked);
                            if (e.target.checked) setSelectedChannels(appChannels);
                            else setSelectedChannels([]);
                          }}
                        ></Toggle><p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem', marginTop: '3px' }}>Select All</p>
                      </div>
                      {[...groups]
                        .sort((a, b) => {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map(group => {
                          return appChannels
                            .filter(el => el.app_content_scope_group_id == group.id)
                            .map(el => {
                              return (
                                <div sclassName={'user-toggle-container'} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: '0.3rem' }}>
                                  <Toggle
                                    id="cheese-status"
                                    defaultChecked={selectedChannels.find(
                                      el1 => el.id == el1.id
                                    )}
                                    key={el.id}
                                    icons={false}
                                    onChange={(e) => { 
                                      if (e.target.checked) {
                                        let newValues = [...selectedChannels, el];
                                        setSelectedChannels(newValues);
                                        // console.log(selectedChannels);
                                        // console.log(newValues);
                                        // console.log(appChannels);
                                        if (newValues.length == appChannels.length) {
                                          setChecked(true);
                                        };
                                      } else {
                                        let index = selectedChannels.findIndex(
                                          el1 => el.id == el1.id
                                        );
                                        let newValues = JSON.parse(
                                          JSON.stringify(selectedChannels)
                                        );
                                        newValues.splice(index, 1);
                                        setSelectedChannels(newValues);
                                        if (newValues.length != appChannels.length) {
                                          setChecked(false);
                                        };
                                      }
                                    }}
                                  ></Toggle><p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem', marginTop: '3px'  }}><b>{group.name}</b> - {el.name}</p>
                                </div>
                            );
                          });
                      })}
                      </DisabledArea>
                  </div>
                </div>
              </Cell>
            </Grid>
          )}

          {tab == TAB_STATUS.TAGS && (
            <UserTags
              organization_id={organization_id}
              userTags={userTags}
              setUserTags={setUserTags}
              showLocationsTags={true}
            />
          )}
          {tab == TAB_STATUS.PERMISSIONS && (
            <UserEmailPermissions
              organization_id={organization_id}
              formEmailPermissions={formEmailPermissions}
              setFormEmailPermissions={setFormEmailPermissions}
            />
          )}

          {tab == TAB_STATUS.DEVICES && (
            <Cell span={12} overrides={cellPaddingOverrides}>
              <div>
                <div
                  className={css({
                    paddingLeft: '0px',
                    width: '100%',
                    height: '100%',
                    overflow: 'scroll',
                    maxHeight: '368px',
                  })}
                >
                  <LabelMedium
                    style={{
                      marginTop: '16px',
                      marginBottom: '8px',
                    }}
                  >
                    Devices
                  </LabelMedium>
                  <ParagraphSmall
                    style={{
                      marginTop: '16px',
                      marginBottom: '8px',
                    }}
                  >
                    Add device ids here so you can skip them in Analytics if this user is a test user.
                  </ParagraphSmall>
                </div>

                <Select
                  creatable
                  multi
                  options={devices.map(el => {
                    return {id: el}
                  })}
                  labelKey="id"
                  valueKey="id"
                  onChange={(data) => setDevices(data.value.map(el => el.id))}
                  value={devices.map(el => {
                    return {id: el}
                  })}
                />
              </div>
            </Cell>
          )}
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => handleCancel()}
            kind={ButtonKind.tertiary}
          >
            Cancel
          </ModalButton>
          <ModalButton
            onClick={() => handleCreateUser()}
            kind={ButtonKind.tertiary}
            isLoading={isLoading}
            style={{ textTransform: 'capitalize' }}
          >
            Update {type}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = props => {
  const { groups } = props.appBasicControls;
  return {
    groups,
  };
};
export default connect(mapStateToProps)(UpdateUser);

