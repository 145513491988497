import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import {
  HeadingMedium,
  ParagraphSmall,
  HeadingSmall,
  ParagraphLarge,
  LabelSmall
} from 'baseui/typography';
import { useStyletron } from 'baseui';
import { ButtonGroup } from 'baseui/button-group';
import Button from './full-size-button';
import moment from 'moment-timezone';
import { DatePicker } from "baseui/datepicker";
import { TimePicker } from "baseui/timepicker";
import { TimezonePicker } from "baseui/timezonepicker";
import { cellPaddingOverrides } from '../overrides';
import { Input, StyledInput } from 'baseui/input';

const Step5 = ({ deliveryType, setDeliveryType, deliveryTime, setDeliveryTime, timeZone, setTimeZone, ...props }) => {
  const [css, theme] = useStyletron();
  const [isCheckboxDisabled, setIsCheckboxDisabled] = React.useState(false);
  console.log(deliveryTime);
  const { popup_persistent_end_date, set_popup_persistent_end_date, popup_interval_days, set_popup_interval_days, popup_show_once_once, set_popup_show_once_once, is_popup } = props;
  console.log('is_popup', is_popup);
  React.useEffect(() => {
    if (deliveryType == 0) {
      setDeliveryTime(new Date());
      setIsCheckboxDisabled(true);
    };
    if (deliveryType == 1) {
      setIsCheckboxDisabled(false);
    };
  }, [deliveryType]);
  React.useEffect(() => {
    // debugger;
    let dt = moment(deliveryTime);
    setDeliveryTime(dt.tz(timeZone).format());
  }, [timeZone]);
  return (
    <Grid overrides={{
      Grid: {
        style: () => ({
          paddingTop: '0px',
          paddingBottom: '32px',
          width: '100%',
          paddingTop: '29px'
        }),
      },
    }} gridMaxWidth={'unset'}>
      {!is_popup && <>
        <Cell span={12} overrides={cellPaddingOverrides} span={10}>
          <div
            className={css({
              marginBottom: '24px',
            })}
          >
            <ButtonGroup
              mode="checkbox"
              selected={deliveryType}
              onClick={(event, index) => {
                setDeliveryType(index);
              }}
            >
              <Button startEnhancer={(p) => <svg width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.5 3.125H24.125V0.375H21.375V3.125H7.625V0.375H4.875V3.125H3.5C1.9875 3.125 0.75 4.3625 0.75 5.875V27.875C0.75 29.3875 1.9875 30.625 3.5 30.625H25.5C27.0125 30.625 28.25 29.3875 28.25 27.875V5.875C28.25 4.3625 27.0125 3.125 25.5 3.125ZM25.5 27.875H3.5V10H25.5V27.875Z" fill={p.$isSelected ? 'white' : 'black'} />
              </svg>
              }>Send message now</Button>
              <Button startEnhancer={(p) => <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.875 9.915H15.5525L19.32 6.0375C15.5663 2.325 9.48879 2.1875 5.73504 5.9C1.98129 9.62625 1.98129 15.635 5.73504 19.3612C9.48879 23.0875 15.5663 23.0875 19.32 19.3612C21.19 17.5187 22.125 15.36 22.125 12.6375H24.875C24.875 15.36 23.665 18.8937 21.245 21.2862C16.4188 26.0712 8.58129 26.0712 3.75504 21.2862C-1.05746 16.515 -1.09871 8.76 3.72754 3.98875C8.55379 -0.7825 16.295 -0.7825 21.1213 3.98875L24.875 0.125V9.915ZM13.1875 7V12.8438L18 15.7037L17.01 17.3675L11.125 13.875V7H13.1875Z" fill={p.$isSelected ? 'white' : 'black'} />
              </svg>

              }>Send message at a later date</Button>
            </ButtonGroup>
          </div>
        </Cell>
        <Cell overrides={cellPaddingOverrides} span={10}>
          <div
            className={css({
              padding: '24px',
              background: theme.colors.primaryB,
              marginBottom: '24px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
            })}
          >
            {deliveryType == 0 && <div style={{ marginBottom: '24px', display: 'flex' }}>
              <img style={{ height: '24px', cursor: 'pointer', marginBottom: '0px' }} onClick={() => setDeliveryType(1)} src="https://img.icons8.com/metro/26/000000/lock-2.png" />
              <ParagraphLarge style={{ marginLeft: '4px', marginBottom: '0px' }}>Unlock this selection to send at specific time</ParagraphLarge>
            </div>}
            {deliveryType == 1 && <div style={{ marginBottom: '24px', display: 'flex' }}>
              <img style={{ height: '24px', cursor: 'pointer', marginBottom: '0px' }} onClick={() => setDeliveryType(0)} src="https://img.icons8.com/metro/26/000000/unlock-2.png" />
              <ParagraphLarge style={{ marginLeft: '4px', marginBottom: '0px' }}>Lock this selection to send right now</ParagraphLarge>
            </div>}
            <LabelSmall>{"Date & Time Settings"}</LabelSmall>
            <LabelSmall
              style={{
                marginTop: '16px',
                marginBottom: '8px',
              }}
            >
              Date
              </LabelSmall>
            <DatePicker
              value={[new Date(deliveryTime)]}
              disabled={isCheckboxDisabled}
              onChange={({ date }) =>
                setDeliveryTime(date)
              }
            />
            <LabelSmall
              style={{
                marginTop: '16px',
                marginBottom: '8px',
              }}
            >
              Time
          </LabelSmall>
            <TimePicker
              value={new Date(deliveryTime)}
              creatable={true}
              disabled={isCheckboxDisabled}
              onChange={date => setDeliveryTime(date)}
            />
            <LabelSmall
              style={{
                marginTop: '16px',
                marginBottom: '8px',
              }}
            >
              Time Zone
          </LabelSmall>
            <TimezonePicker
              value={timeZone}
              disabled={isCheckboxDisabled}
              onChange={({ id }) => setTimeZone(id)}
              date={new Date(deliveryTime)}
            />
          </div>
        </Cell>
      </>}
      <>
        <Cell span={12} overrides={cellPaddingOverrides} span={10}>
          <div
            className={css({
              marginBottom: '24px',
            })}
          >
            <ButtonGroup
              mode="checkbox"
              selected={popup_show_once_once ? 0 : 1}
              onClick={(event, index) => {
                set_popup_show_once_once(index == 0 ? true : false);
              }}
            >
              <Button startEnhancer={(p) => <svg width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.5 3.125H24.125V0.375H21.375V3.125H7.625V0.375H4.875V3.125H3.5C1.9875 3.125 0.75 4.3625 0.75 5.875V27.875C0.75 29.3875 1.9875 30.625 3.5 30.625H25.5C27.0125 30.625 28.25 29.3875 28.25 27.875V5.875C28.25 4.3625 27.0125 3.125 25.5 3.125ZM25.5 27.875H3.5V10H25.5V27.875Z" fill={p.$isSelected ? 'white' : 'black'} />
              </svg>
              }>Send popup once</Button>
              <Button startEnhancer={(p) => <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.875 9.915H15.5525L19.32 6.0375C15.5663 2.325 9.48879 2.1875 5.73504 5.9C1.98129 9.62625 1.98129 15.635 5.73504 19.3612C9.48879 23.0875 15.5663 23.0875 19.32 19.3612C21.19 17.5187 22.125 15.36 22.125 12.6375H24.875C24.875 15.36 23.665 18.8937 21.245 21.2862C16.4188 26.0712 8.58129 26.0712 3.75504 21.2862C-1.05746 16.515 -1.09871 8.76 3.72754 3.98875C8.55379 -0.7825 16.295 -0.7825 21.1213 3.98875L24.875 0.125V9.915ZM13.1875 7V12.8438L18 15.7037L17.01 17.3675L11.125 13.875V7H13.1875Z" fill={p.$isSelected ? 'white' : 'black'} />
              </svg>
              }>Send popup multiple times</Button>
            </ButtonGroup>
          </div>
        </Cell>
        {!popup_show_once_once && <Cell overrides={cellPaddingOverrides} span={10}>
          <div
            className={css({
              padding: '24px',
              background: theme.colors.primaryB,
              marginBottom: '24px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
            })}
          >
            <LabelSmall
              style={{
                marginTop: '16px',
                marginBottom: '8px',
              }}
            >
              Popup End date
              </LabelSmall>
            <DatePicker
              value={[new Date(popup_persistent_end_date)]}
              // disabled={isCheckboxDisabled}
              onChange={({ date }) =>
                set_popup_persistent_end_date(date)
              }
            />
            <LabelSmall
              style={{
                marginTop: '16px',
                marginBottom: '8px',
              }}
            >
              Popup Frequency (in days)
              </LabelSmall>
            <ParagraphSmall>This setting will set in how many days you want the popup reappear. If this is set to 0 popup will appear in every session if it is 1 then popup will appear once a day till end date.</ParagraphSmall>
            <Input
              value={popup_interval_days}
              onChange={e => {
                set_popup_interval_days(1 * e.target.value)
              }}
              placeholder="Interval (Day)"
              clearOnEscape
              type={'number'}
            />
          </div>
        </Cell>}
      </>
    </Grid>
  )
}


export default Step5;