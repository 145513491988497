import * as React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { navigate } from '@reach/router';

import store from '../../redux/store';
import { client } from '../../apollo/client';

import Step3 from './step3';
import Step1 from './step1';
import Step2 from './step2';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';

import StepsWizard from './steps-wizard';

import ConnectSvg from '../sidebar/sideNavIcons/connect.svg';
import { editorBtnOptions } from '../../../data/cardBuilderOptions';
import { saveCommunicationBackData } from '../builder/builderUtils/save';

import moment from 'moment-timezone';
import Step7 from './step7';

import MainPageModal from '../builder/mainPageModal';
import { showToaster } from '../../util';

const AddCommunication = ({ channels, organization_id }) => {
  const [messageType, setMessageType] = React.useState([]);
  const [audienceType, setAudienceType] = React.useState(0);
  const [channelsSelected, setSelectedChannels] = React.useState([]);
  const [messageTitle, setMessageTitle] = React.useState('');
  const [messageBody, setMessageBody] = React.useState('');
  const [frontImage, setFrontImage] = React.useState(null);
  const [tags, setTags] = React.useState([]);
  const [tagValue, setTagValue] = React.useState('');
  const [deliveryType, setDeliveryType] = React.useState(0);
  const [deliveryTime, setDeliveryTime] = React.useState(new Date());
  const [cardBackData, setCardBackDataState] = React.useState(null);
  const [timeZone, setTimeZone] = React.useState(moment.tz.guess())
  const [current, setCurrent] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [communicationType, setCommunicationType] = React.useState(null)
  const [redirectCardId, setRedirectCardId] = React.useState(null);
  const [redirectCommunicationId, setRedirectCommunicationId] = React.useState(null);
  const [selfCommunication, setSelfCommunication] = React.useState(false);


  // popup_persistent_end_date: String
  // 	popup_interval_days: Int
  //   popup_show_once_once: Boolean
  const [popup_persistent_end_date, set_popup_persistent_end_date] = React.useState(new Date());
  const [popup_interval_days, set_popup_interval_days] = React.useState(0);
  const [popup_show_once_once, set_popup_show_once_once] = React.useState(false);


  const onSave = () => {
    let feed = getFeedObject();
    setIsLoading(true);
    // debugger;
    client.mutate({
      mutation: gql`
      mutation addCommunication($input: CommunicationInput) {
        addCommunication(input: $input) {
          id
          title
          front_image_data
          message_body
        }
      }
    `,
      variables: {
        input: {
          type_push_notification: feed.communication.type_push_notification,
          type_news: feed.communication.type_news,
          type_email: feed.communication.type_email,
          type_popup: feed.communication.type_popup,
          title: feed.communication.title,
          front_image: frontImage,
          tags: feed.communication.tags,
          organization_id,
          message_body: feed.communication.message_body,
          is_scheduled: deliveryType == 1 ? true : false,
          scheduled_at: deliveryTime,
          cron_expression: null,
          push_action_type: communicationType,
          redirect_card_id: redirectCardId,
          redirect_communication_id: selfCommunication ? "_self" : redirectCommunicationId,
          popup_persistent_end_date,
          popup_interval_days,
          popup_show_once_once
        },
      }
    }).then((data) => {
      // debugger;
      let communicationId = data.data.addCommunication.id;

      saveCommunicationBackData(editorBtnOptions, communicationId, organization_id, cardBackData)

        .then(async () => {
          // debugger
          let selected = [...channelsSelected];

          let communicationCreated;

          if (audienceType === 2) {
            communicationCreated = await client.mutate({
              mutation: gql`
            mutation addCommunicationToTeam(
              $input: CommunicationTeamInput
            ) {
              addCommunicationToTeam(input: $input) {
                id
              }
            }
          `,
              variables: {
                input: {
                  organization_id,
                  communication_id: communicationId,
                  team_ids: selected
                }
              }
            })
          } else {

            communicationCreated = await client.mutate({
              mutation: gql`
            mutation addCommunicationToAppContent(
              $input: [CommunicationAppContentScopeInput]
            ) {
              addCommunicationToAppContent(input: $input) {
                id
              }
            }
          `,
              variables: {
                input: selected.map((ch) => {
                  return {
                    organization_id,
                    communication_id: communicationId,
                    app_content_scope_id: ch.id,
                  }
                })
              }
            })
          }

          setIsLoading(false);
          navigate(`/app/${organization_id}/connect`);

        });
    }).catch(err => {
      showToaster(err.message, 'negative')
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const STEPS = [
    {
      buttonTitle: 'Type',
      component: () => <Step1
        messageType={messageType}
        setMessageType={setMessageType}
      ></Step1>,
      isReady: (function () {
        return messageType.length > 0 ? true : false
      }()),
    },
    {
      buttonTitle: "Audience",
      component: () => <Step2
        channelsSelected={channelsSelected}
        setSelectedChannels={setSelectedChannels}
        channels={channels}
        audienceType={audienceType}
        setAudienceType={setAudienceType}
      ></Step2>,
      disabled: messageType.includes(3) ? true : false,
      isReady: (function () {
        return channelsSelected.length > 0 ? true : false
      }())
    },
    {
      buttonTitle: 'Design',
      component: () => <Step3
        messageType={messageType}
        messageTitle={messageTitle}
        messageBody={messageBody}
        setMessageBody={setMessageBody}
        setMessageTitle={setMessageTitle}
        frontImage={frontImage}
        setFrontImage={setFrontImage}
        tags={tags}
        setTags={setTags}
        tagValue={tagValue}
        setTagValue={setTagValue}
      ></Step3>,
      disabled: false,
      isReady: (function () {
        let s = true;
        if (messageTitle == '') s = false;
        if (messageType.includes(0) && messageBody == '') s = false
        // if (frontImage == null) s = false
        return s;
      }())
    },
    {
      buttonTitle: 'Content',
      component: () => <Step4
        messageType={messageType}
        setMessageType={setMessageType}
        cardBackData={cardBackData}
        setCardBackDataState={setCardBackDataState}
      ></Step4>,
      disabled: (messageType.includes(1) || messageType.includes(2) || messageType.includes(3)) ? false : true,
      isReady: true
    },
    {
      buttonTitle: 'Actions',
      component: () => <Step7
        redirectCardId={redirectCardId}
        setRedirectCardId={setRedirectCardId}
        communicationType={communicationType}
        setCommunicationType={setCommunicationType}
        redirectCommunicationId={redirectCommunicationId}
        setRedirectCommunicationId={setRedirectCommunicationId}
        selfCommunication={selfCommunication}
        setSelfCommunication={setSelfCommunication}
        messageType={messageType}
      ></Step7>,
      disabled: (messageType.includes(0)) ? false : true,
      isReady: true
    },
    {
      buttonTitle: 'Delivery',
      component: () => <Step5
        deliveryType={deliveryType}
        setDeliveryType={setDeliveryType}
        deliveryTime={deliveryTime}
        setDeliveryTime={setDeliveryTime}
        timeZone={timeZone}
        setTimeZone={setTimeZone}
        popup_persistent_end_date={popup_persistent_end_date}
        set_popup_persistent_end_date={set_popup_persistent_end_date}
        popup_interval_days={popup_interval_days}
        set_popup_interval_days={set_popup_interval_days}
        popup_show_once_once={popup_show_once_once}
        set_popup_show_once_once={set_popup_show_once_once}
        is_popup={messageType.includes(3)}
      ></Step5>,
      disabled: false,
      isReady: (function () {
        let s = true;
        if (deliveryType == null) s = false;
        return s;
      }())
    },
    {
      buttonTitle: 'Preview',
      component: () => <Step6
        feed={getFeedObject()}
        STEPS={STEPS}
        onSave={onSave}
        isLoading={isLoading}
      ></Step6>,
      disabled: false,
      isReady: true
    }
  ];

  const getFeedObject = () => {
    return {
      front_image: frontImage ? URL.createObjectURL(frontImage) : null,
      communication: {
        title: messageTitle,
        tags,
        type_news: messageType.includes(1),
        type_email: messageType.includes(2),
        type_push_notification: messageType.includes(0),
        type_popup: messageType.includes(3),
        message_body: messageBody,
        card_content_modules: cardBackData || []
      },
      isSendToEntireOrg: audienceType == 0,
      isSendToTeam: audienceType == 2,
      channelsSelected,
      dataType: "card_back_data",
      deliveryTime,
      deliveryType,
      isSendButtonReady: STEPS.find((el) => {
        return el.isReady === false
      }) ? false : true
    }
  };

  const onStepClick = (index) => {
    if (current == 3) {
      setCardBackDataState([...store.getState().appBasicControls.cardBackData] || []);
    };
    if (STEPS[index].disabled) return;
    setCurrent(index);
  };

  React.useEffect(() => {
    setSelectedChannels(channels)
  }, [channels]);

  return (
    <>
      <MainPageModal
        isOpen={true}
        onClose={() => { }}
      >
        <StepsWizard
          STEPS={STEPS}
          current={current}
          onStepClick={onStepClick}
          isModal={true}
          version={'1'}
          onModalClose={() => { navigate(`/app/${organization_id}/connect`); }}
          title={"Add communication"}
          subtitle={"Send message to users as email, article or push notification"}
        ></StepsWizard>
      </MainPageModal>
    </>
  )
};

const mapStateToProps = props => {
  let { channels, groups, theme } = props.appBasicControls;
  return {
    channels,
    channelGroups: groups,
    defaultTheme: theme
  };
};
export default connect(mapStateToProps)(AddCommunication);