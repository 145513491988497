import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import {
  HeadingMedium,
  ParagraphSmall,
  ParagraphLarge,
} from 'baseui/typography';
import { ButtonGroup, MODE } from 'baseui/button-group';
import Button from '../../connect/full-size-button';
import { Select, TYPE } from 'baseui/select';
import { cellPaddingOverrides } from '../../overrides';
import { useStyletron } from 'baseui';
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';
import { client } from '../../../apollo/client';
import gql from 'graphql-tag';
import Table from '../../intake/table';

const SearchableCheckList = ({ google_sheet_integration_id, onChange, typeToPick = 'Dimensions', value }) => {
  const [css, theme] = useStyletron();
  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  // console.log(',,,, ', typeToPick, value);

  const [searchText, setSearchText] = React.useState('');
  const [filtered, setFiltered] = React.useState([]);

  const [firstRow, setFirstRow] = React.useState(null);

  function isObject(objValue) {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object;
  }

  const fetchFirstRowOfData = (cols) => {
    client.query({
      query: gql`
      query xx ($dimensions: [String], $measures: [String]) {
        organization_google_sheet_data(
            id: "${google_sheet_integration_id}",
            dimensions: $dimensions,
            measures: $measures,
            skip: 0,
            limit: 2,
        ) {
            result
        }
      }
      `,
      variables: {
        dimensions: cols,
        measures: [],
        skip: 0,
        limit: 1
      }
    }).then(({ data }) => {
      try {
        if (data.organization_google_sheet_data) {
          let _d = JSON.parse(data.organization_google_sheet_data.result);
          _d = _d[0];
          console.log('setFirstRow', _d.data)
          setFirstRow((_d?.data || []).map((row) => {
            let updatedRow = {}
            Object.keys(row).map(key => {
              if (key == '_id') {
                updatedRow[key] = row[key]
                return;
              }
              if (isObject(row[key])) {
                updatedRow[key] = row[key].result;
                return;
              }
              updatedRow[key] = row[key]
            })
            return updatedRow
          }))
          // setFirstRow(_d.data);
        }
      } catch (err) {
        console.log(err)
      }
    });
  }

  React.useEffect(() => {
    if (google_sheet_integration_id) {
      setLoading(true);
      client.query({
        query: gql`
        query {
            organization_google_sheet_columns(id: "${google_sheet_integration_id}") {
                result
              }
          }
      `,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        setColumns(data.organization_google_sheet_columns.result);
        fetchFirstRowOfData(data.organization_google_sheet_columns.result);
        setLoading(false);
      })
    }
  }, [google_sheet_integration_id]);

  const [selected, setSelected] = React.useState(value || []);
  const [selectionType, setSelectionType] = React.useState(1);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = React.useState(false);

  // console.log(firstRow);
  // console.log(filtered);

  React.useEffect(() => {
    onChange(selected);
  }, [selected]);

  React.useEffect(() => {
    if (selectionType == 0) {
      setSearchText('');
      setSelected(columns);
      setIsCheckboxDisabled(true);
    };
    if (selectionType == 1) {
      setSelected(value);
      setIsCheckboxDisabled(false);
    };
  }, [selectionType, columns]);

  React.useEffect(() => {
    if (searchText == '') {
      setFiltered(columns);
    } else {
      let filterColumns = columns.filter(el => el.toLowerCase().includes(searchText.trim().toLocaleLowerCase()));
      setFiltered(filterColumns);
    }
  }, [searchText, columns]);

  if (!google_sheet_integration_id) return <></>;

  // console.log(selected);
  if (!selected) return <></>;
  return (
    <>
      <hr style={{ height: '2px', margin: 0, marginTop: '16px', marginBottom: '0px', background: '#E2E2E2' }} />
      <Grid overrides={{
        Grid: {
          style: () => ({
            paddingTop: '0px',
            paddingBottom: '32px',
            marginLeft: '0px',
            paddingTop: '0px',
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
          }),
        },
      }} gridMaxWidth={'unset'}>
        <Cell span={12} overrides={cellPaddingOverrides} span={12}>
          <div
            className={css({
              marginBottom: '24px',
            })}
          >
            <ButtonGroup
              mode={MODE.radio}
              selected={0}
              onClick={(event, index) => {
                // setSelectionType(index);
              }}
            >
              <Button startEnhancer={(p) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.0208 15.0833H16.0004L15.6388 14.7346C16.9046 13.2621 17.6667 11.3504 17.6667 9.27083C17.6667 4.63375 13.9079 0.875 9.27083 0.875C4.63375 0.875 0.875 4.63375 0.875 9.27083C0.875 13.9079 4.63375 17.6667 9.27083 17.6667C11.3504 17.6667 13.2621 16.9046 14.7346 15.6388L15.0833 16.0004V17.0208L21.5417 23.4662L23.4662 21.5417L17.0208 15.0833ZM9.27083 15.0833C6.05458 15.0833 3.45833 12.4871 3.45833 9.27083C3.45833 6.05458 6.05458 3.45833 9.27083 3.45833C12.4871 3.45833 15.0833 6.05458 15.0833 9.27083C15.0833 12.4871 12.4871 15.0833 9.27083 15.0833Z" fill={p.$isSelected ? 'white' : 'black'} />
              </svg>
              }>
                Select {typeToPick}
              </Button>
            </ButtonGroup>
          </div>
        </Cell>

        <Cell overrides={cellPaddingOverrides} span={4}>
          <div
            className={css({
              padding: '24px',
              background: theme.colors.primaryB,
              marginBottom: '24px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
            })}
          >

            {selectionType == 1 && <div style={{ marginBottom: '24px' }}>
              <Input
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                endEnhancer={<Search size="18px" />}
                placeholder="Search Columns"
              />
            </div>}
            {filtered.map((column, index) => {
              return <Cell overrides={cellPaddingOverrides} span={12} key={index}>
                {/* <hr style={{ height: '2px', margin: 0, marginTop: '8px', marginBottom: '8px', background: '#E2E2E2' }} /> */}
                <div>
                  <Checkbox
                    checked={selected.find(el => el == column)}
                    checkmarkType={STYLE_TYPE.default}
                    disabled={isCheckboxDisabled}
                    labelPlacement={LABEL_PLACEMENT.right}
                    onChange={e => {
                      if (e.target.checked) {
                        let s = new Set(selected);
                        s.add(column);
                        setSelected(Array.from(s))
                      } else {
                        let s = new Set(selected);
                        s.delete(column);
                        setSelected(Array.from(s))
                      }
                    }}
                  >
                    <div style={{ color: theme.colors.primaryA, fontWeight: 500 }}>{column}</div>
                  </Checkbox>
                </div>
              </Cell>
            })}
          </div>
        </Cell>

        <Cell overrides={cellPaddingOverrides} span={8}>
          <div style={{ marginLeft: '32px' }}>
            <ParagraphSmall>
              Data Preview
            </ParagraphSmall>
            {firstRow && firstRow.length > 0 && <Table
              data={firstRow}
              columns={columns.map((el) => {
                return { key: el, display: el }
              })}
              count={5}
              loadMoreData={() => { }}
              height={'225px'}
              width={'100%'}
            ></Table>}
          </div>
        </Cell>

      </Grid>
    </>
  )
};

export default SearchableCheckList;
