import * as React from 'react';
import { Button, SHAPE } from 'baseui/button';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { Select, SIZE } from 'baseui/select';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { getFilesUrls, isEqualDate, sourceByType } from '../../util';
import ImageUploadCXL from '../imageUploaderCXL';
import { cardBackImageGuideline } from '../help/imageSizeGuidelines.json';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

import styled from '@emotion/styled';
import { Accordion, Panel } from 'baseui/accordion';

import svgIcons from './cardBackEditorComponents/images';
import CustomInput from '../common/input';
import _ from 'lodash';
import { SelectCXL } from './cardBackEditorComponents/select';
import { ParagraphSmall } from 'baseui/typography';
import { setSharepointFormSheet, showAlert } from '../../redux/actions/appBasicControls';
import { client } from '../../apollo/client';
import TagsInput from '../common/tagsInput';
import { connect, useDispatch } from 'react-redux';
import { createTemplateTag, sharepoint_form_templates, template_tags_org } from '../templates/queries';
import { reference_forms_by_sharepoint_form_id, sharepoint_forms_by_reference_form_id, sharepoint_form_modules_by_channel_id } from './builderUtils/query';
import { organization_share_point_sheets_org } from '../settings/queries';
import { Datepicker } from 'baseui/datepicker';
import { getDatesFromDateByNumber } from '../dashboard/utils';
import { fetch_form_schema_from_crunchbase } from '../settings/queries';
import {
  PLACEMENT,
  StatefulTooltip,
  TRIGGER_TYPE
} from "baseui/tooltip";
import { Block } from 'baseui/block';
import { Input } from 'baseui/input';
import { StatefulPopover } from 'baseui/popover';
import { Textarea } from 'baseui/textarea';
import CrunchbaseIntegrationPicker from '../modules/generic_modules/crunchbaseIntegrationPicker';
const HeaderTitle = styled.h5`
  color: black;
  margin-top: 16px;
  margin-bottom: 11px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`
const HeaderSubTitle = styled.h5`
  color: black;
  margin-top: 10px;
  margin-bottom: 11px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`


const BtnImageSvg = styled.svg`
  position: relative;
  
  cursor: pointer;
`;
// float: right;
//   margin-right: 18px;
// margin-top: 6px;

const BtnImageContainer = styled.div`
  padding: 6px;
`

const FormActionButtonContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;
// height: 50px;


// max-width: 405px;
const AddAdditionalContainer = styled.div`
  width: 90%;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 8%;
  display: flex;
  justify-content: center;
`;

const FieldNumberContainer = styled.div`
  border-radius: 20px;
  background-color: black;
  text-align: center;
  color: white;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
`

const FormFieldHeaderTextContainer = styled.div`
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`

const GreaterLessThanContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const GreaterLessThanSeparator = styled.div`
  display: flex;
  padding: 10px;
  align-items: end;
`

const DateRestrictionContaner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 10px;
`

const getSrc = data => {
  try {
    if (!data) return data;
    return sourceByType(data);
  } catch (e) {
    return null;
  }
};

const getPreviewImage = (file, dbImage) => {
  if (file) {
    return URL.createObjectURL(file[0]);
  };
  return getSrc(dbImage);
};

const formFieldType = [{
  id: 'String',
  label: 'String'
}, {
  id: 'Number',
  label: 'Number',
}, {
  id: 'Boolean',
  label: 'Boolean',
}, {
  id: 'Date',
  label: 'Date',
}, {
  id: 'Select',
  label: 'Select',
}, {
  id: 'Multiple-Select',
  label: 'Multiple-Select',
}, {
  id: 'File',
  label: 'File',
}, {
  id: 'Signature',
  label: 'Signature',
}, {
  id: 'Reference',
  label: 'Reference',
}, {
  id: 'URL',
  label: 'URL',
}, {
  id: 'Paragraph',
  label: 'Paragraph',
}, {
  id: 'Amount',
  label: 'Amount',
}];

const restricted_field_types = [{
  label: 'Today',
  value: 'today'
}, {
  label: 'Custom',
  value: 'custom'
}]

const referenceFormFieldType = [{
  id: 'Select',
  label: 'Select'
}, {
  id: 'Multiple-Select',
  label: 'Multiple-Select'
}, {
  id: 'Checkbox',
  label: 'Checkbox',
}];

const selectFieldPresentationTypes = [{
  label: 'Default',
  id: 'default'
}, {
  label: 'Action-Sheet',
  id: 'action-sheet'
}]

const dateOverrides = {
  Popover: {
    props: {
      overrides: {
        Body: { style: { zIndex: 3 } },
      },
    },
  },
  Input: {
    props: {
      overrides: {
        Input: {
          style: ({ $theme }) => ({
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "24px",
            paddingTop: '10px',
            paddingBottom: '10px'
          })
        }
      }
    }
  }
}
const getFormFieldId = () => Math.random().toString(36).slice(2)
class FormBuilderComponent extends React.Component {
  constructor(props) {
    super(props);
    const { selectedModule, selectedComponentType } = props;

    const { display, inputs, __typename } = selectedComponentType;
    let schemaArr = selectedModule.content_module['schema'] && selectedModule.content_module['schema'] !== null && selectedModule.content_module['schema'] || '';
    schemaArr = schemaArr && JSON.parse(schemaArr) || [];
    let concatenatedFields = {}
    schemaArr = schemaArr.map(schemaEle => {
      const {
        show_concatenated_value = false,
        concatenated_fields = []
      } = schemaEle || {}
      let id = schemaEle?.id;

      if (!id) {
        id = getFormFieldId()
      }

      if (show_concatenated_value) {
        concatenatedFields[id] = concatenated_fields.map(el => el.id || el.name)
      }
      return ({
        id,
        ...schemaEle,
      })
    })
    // console.log('fields before', {concatenatedFields})
    this.state = {
      isOpen: this.props.isOpen,
      inputs,
      formElements: schemaArr || [],
      isLoading: false,
      inputValues: {},
      tags: [],
      selectedTags: [],
      reference_forms: [],
      isReferenceFormsLoading: false,
      concatenatedFields,
      isTagCreating: false,
      tagsLoading: false,
      is_archived: false,
      importLoading:false,
    };
    this.onAdd = this.onAdd.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onChangeItemValue = this.onChangeItemValue.bind(this);
    this.createNewTags = this.createNewTags.bind(this);
    this.importFormFields = this.importFormFields.bind(this);
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (_.isEqual(this.props, nextProps)) {
      return;
    }
    // this.setUpInputValues();
  }

  componentDidMount() {
    // this.setFormList()
    this.setUpInputValues();
    this.loadTags();
    // this.loadReferenceForm();
    this.loadChannelReferenceFormModules();
    this.loadTemplateReferenceFormModules();
    this.fetchMicrosoftSharepointSheets();
  }


  fetchMicrosoftSharepointSheets = async () => {
    console.log('fetchMicrosoftSharepointSheets ------>')
    try {
      const { organization_id, dispatch } = this.props
      const { data } = await client.query({
        query: organization_share_point_sheets_org,
        variables: {
          organization_id
        },
        fetchPolicy: 'network-only'
      })
      dispatch(setSharepointFormSheet(data.organization_share_point_sheets_org));
    } catch (error) {
      console.log('fetchMicrosoftSharepointSheets error', error)
    }
  }

  setFormList = async () => {
    try {
      const {
        app_content_scope_id,
        selectedModule
      } = this.props || {};
      const {
        content_module = {}
      } = selectedModule || {};
      console.log('setFormList ---->', app_content_scope_id)
      if (!app_content_scope_id) {
        this.setState({
          homeComponentFormList: []
        })
      }
      let { data } = await client.query({
        query: sharepoint_form_modules_by_channel_id,
        fetchPolicy: 'network-only',
        variables: {
          channel_id: app_content_scope_id,
          published: true
        }
      })
      let homeComponentFormList = data?.sharepoint_form_modules_by_channel_id ? data.sharepoint_form_modules_by_channel_id : [];
      homeComponentFormList = homeComponentFormList.map(el => {
        return ({
          id: el?.id,
          label: el?.title
        });
      })
      homeComponentFormList = homeComponentFormList.filter(el => el.id !== content_module.id);
      this.setState({
        homeComponentFormList
      })
    } catch (err) {
      console.log('setFormList error', err);
      this.setState({
        homeComponentFormList: []
      })
    }

  }

  setUpInputValues = () => {
    const { selectedModule } = this.props
    if (selectedModule && selectedModule.content_module) {
      let inputValues = {}
      let updatedInputs = this.state.inputs.map(el => {
        const {
          mutation
        } = el || {}
        let key = mutation ? mutation.key : el.key;
        inputValues[key] = selectedModule.content_module[key];
        return ({
          ...el,
          value: selectedModule.content_module[el.key]
        })
      });
      let tags = selectedModule.content_module['tags'] && selectedModule.content_module['tags'] !== null ? selectedModule.content_module['tags'] : [];
      this.setState({
        inputs: updatedInputs,
        inputValues,
        selectedTags: tags
      })
    }
  }

  loadTags = () => {
    const {
      inputValues = {}
    } = this.state
    // if (!this.props.isTemplate && !inputValues.is_reference_form) {
    //   return;
    // }
    const { organization_id } = this.props;

    this.setState({
      tagsLoading: true
    })

    client.query({
      query: template_tags_org(organization_id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      let formatedTags = [...data.template_tags_org].sort((a, b) => a.position - b.position)
      this.setState({
        tags: formatedTags
      })
    }).catch((err) => {
      console.log('loadTags error', err)
    }).finally(() => {
      this.setState({
        tagsLoading: false
      })
    })
  };


  loadReferenceForm = () => {
    const { selectedModule } = this.props;
    const {
      content_module
    } = selectedModule || {};
    if (!content_module) {
      return;
    }
    this.setState({
      isReferenceFormsLoading: true
    })
    client.query({
      query: reference_forms_by_sharepoint_form_id,
      fetchPolicy: 'network-only',
      variables: {
        id: content_module.id
      }
    }).then(({ data }) => {
      let reference_forms = [...data.reference_forms_by_sharepoint_form_id]
      this.setState({
        reference_forms
      })
    }).catch(err => {
      // console.log('loadReferenceForm error', err)
    }).finally(() => {
      this.setState({
        isReferenceFormsLoading: false
      })
    })
  }

  loadChannelReferenceFormModules = async () => {
    try {
      if (this.props.isTemplate) {
        return;
      }
      const {
        app_content_scope_id,
        selectedModule
      } = this.props || {};
      const {
        content_module = {}
      } = selectedModule || {};
      if (!app_content_scope_id) {
        this.setState({
          reference_forms: []
        })
      }
      this.setState({
        isReferenceFormsLoading: true
      })
      let { data } = await client.query({
        query: sharepoint_form_modules_by_channel_id,
        fetchPolicy: 'network-only',
        variables: {
          channel_id: app_content_scope_id,
          // published: true,
          filter: {
            is_reference_form: true
          }
        }
      })
      let reference_forms = data?.sharepoint_form_modules_by_channel_id ? data.sharepoint_form_modules_by_channel_id : [];
      // reference_forms = reference_forms.map(el => {
      //   return ({
      //     id: el?.id,
      //     label: el?.title
      //   });
      // })
      reference_forms = reference_forms.filter(el => el.id !== content_module.id);
      this.setState({
        reference_forms,
        isReferenceFormsLoading: false
      })
    } catch (err) {
      console.log('loadChannelReferenceFormModules error', err);
      this.setState({
        reference_forms: [],
        isReferenceFormsLoading: false
      })
    }
  }

  loadTemplateReferenceFormModules = async () => {
    const {
      organization_id,
      selectedModule
    } = this.props;
    const {
      content_module = {}
    } = selectedModule || {};
    try {
      if (!this.props.isTemplate) {
        return;
      }
      this.setState({
        isReferenceFormsLoading: true
      })
      let { data } = await client.query({
        query: sharepoint_form_templates,
        fetchPolicy: 'network-only',
        variables: {
          organization_id
        }
      })
      let reference_forms = data?.sharepoint_form_templates ? data.sharepoint_form_templates : [];
      reference_forms = reference_forms.filter(el => el.id !== content_module.id);
      this.setState({
        reference_forms,
        isReferenceFormsLoading: false
      })
    } catch (err) {
      console.log('loadChannelReferenceFormModules error', err);
      this.setState({
        reference_forms: [],
        isReferenceFormsLoading: false
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(nextState, this.state)) {
      return true;
    }
    return false;
  }


  /////////////////////////////////////////////////// Event Functions ////////////////////////////////////////////////////

  onAdd() {
    let options = this.state.formElements || [];
    options = options.map(el => ({ ...el }));
    options.push({
      id: getFormFieldId(),
      name: '',
      displayName: '',
      type: '',
      score: 0,
      position: options.length,
      is_required: false,
      is_hidden: false
    });
    this.setState({ formElements: options }, () => {
      const lastElement = `position_${this.state.formElements.length - 1}`;
      this.scrollToElemet(`#${lastElement}`);
    });
  }


  onMoveUp = (item) => {
    if (item.position - 1 < 0) return;
    let temp = this.state.formElements.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    filteredTemp.splice(item.position - 1, 0, item);
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));

    this.setState({
      formElements: updatedTemp
    }, () => {
      const ele = '#position_' + (item.position - 1);
      this.scrollToElemet(ele)
    })
  }

  onMoveDowm = (item) => {
    let tempOptions = [...this.state.formElements] || []
    if (item.position + 1 >= tempOptions.length) return;
    let temp = tempOptions.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    filteredTemp.splice(item.position + 1, 0, item);
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));

    this.setState({
      formElements: updatedTemp
    }, () => {
      const ele = '#position_' + (item.position + 1);
      this.scrollToElemet(ele)
    })
  }

  onDeleteItem = (item) => {
    let temp = this.state.formElements.sort((a, b) => a.position - b.position);
    let filteredTemp = temp.filter(ele => {
      if (ele.position !== item.position) {
        return ele;
      }
    });
    let updatedTemp = filteredTemp.map((ele, indx) => ({
      ...ele,
      position: indx
    }));

    this.setState({
      formElements: updatedTemp
    })
  }


  scrollToElemet = (elementName) => {
    const lastContainer = document.querySelector(elementName);
    const scrollContainer = document.querySelector('.form_builder_container');
    if (!scrollContainer) {
      return;
    }
    scrollContainer.scrollTo({
      top: lastContainer.offsetTop,
      behavior: 'auto'
    });
  }

  onImageDrop = (acceptedFiles, item) => {
    const {
      inputValues
    } = this.state;
    const options = [...this.state.inputs]
    const temp = options.map(item => {
      if (item.key == 'cover_image_data') {
        return {
          ...item,
          value: acceptedFiles
        }
      }
      return item
    });

    let updateVals = inputValues || {}
    updateVals[item.mutation ? item.mutation.key : item.key] = acceptedFiles
    // setState(
    //   mutation ? mutation.key : key, acceptedFiles
    // );
    this.setState({
      inputs: temp,
      inputValues: updateVals
    })
  }

  onImageDelete = (index, item) => {
    const {
      inputValues
    } = this.state;
    const options = [...this.state.inputs]
    const temp = options.map(item => {
      if (item.key == 'cover_image_data') {
        return {
          ...item,
          value: null
        }
      }
      return item
    })
    let updateVals = inputValues || {};
    updateVals[item.mutation ? item.mutation.key : item.key] = null;
    this.setState({
      inputs: temp,
      inputValues: updateVals
    })
  }

  onSave = () => {
    const {
      inputs,
      formElements,
      inputValues,
      selectedTags,
      is_archived,
    } = this.state || {};
    let payload = {};
    if (inputValues) {
      payload = {
        ...payload,
        ...inputValues
      }
    }

    if (!payload.is_reference_form) {
      payload.reference_form_ids = [];
      payload.reference_form_field_name = "";
      payload.default_value_reference_form_field_name = "";
    } else {
      payload.reference_form_ids = payload.reference_form_ids && payload.reference_form_ids.map(el => el.id) || [];
    }

    // if (this.props.isTemplate || inputValues.is_template) {
    payload.tag_ids = selectedTags.map(el => el.id)
    // } else {
    //   payload.tag_ids = []
    // }
    // inputs.map((el, idx) => {
    //   payload[el.key] = el.value
    //   if (el.type === 'FilePicker' && el.mutation && el.mutation.key) {
    //     payload[el.key] = inputs[idx].value;
    //     payload[el.mutation.key] = el.value;
    //   }
    // })

    if (this.props.isTemplate) {
      payload.is_archived = is_archived
    }

    const schemaArr = formElements && formElements.map(formEle => {
      const {
        id,
        show_concatenated_value,
        reference_field_form_id,
        reference_field_form_name
      } = formEle || {};
      const {
        build_concatenated_value,
        ...schemaRest
      } = formEle || {}
      let concatenated_fields = [];
      const canAddConcatenatedFields = show_concatenated_value === true || build_concatenated_value === true ? true : false;
      if (canAddConcatenatedFields && (reference_field_form_id || reference_field_form_name)) {
        concatenated_fields = this.getReferenceSelectedValues(reference_field_form_id, reference_field_form_name, id)
      } else if (canAddConcatenatedFields) {
        concatenated_fields = this.getSelectedValues(id)
      }

      // console.log({ show_concatenated_value, canAddConcatenatedFields, concatenated_fields })
      return ({
        ...schemaRest,
        concatenated_fields
      })
    }) || [];
    payload.schemaArr = schemaArr;
    // console.log({ payload, formElements });
    // // console.log('values  ------->', {
    //   id,
    //   // published,
    //   // archived,
    //   ...payload,
    // })
    this.setState({
      isLoading: true
    });
    // console.log(this.props);
    this.props.onSave(payload)
      .then(() => {
        this.setState({
          isLoading: false
        });
      });
  }

  importFormFields = async (formFields) => {
     this.setState({
      formElements: formFields,
      importLoading: false
    })
  }

  resetFields = () => {
    const {
      inputs
    } = this.state
    let updatedInputs = inputs.map(el => {
      return ({
        ...el,
        value: ''
      })
    })

    this.setState({
      inputs: updatedInputs,
      formElements: [],
      inputValues: {}
    })
  }


  onChangeItemValue = (newKey, newValue) => {
    // console.log('onChangeItemValue', newKey, newValue);
    const options = [...this.state.inputs];
    let optionIdx = -1;
    const temp = options.map((item, idx) => {
      if (item.key == newKey) {
        optionIdx = idx;
        return {
          ...item,
          value: newValue
        }
      }
      return item
    });

    let inputOption = options[optionIdx];
    const {
      mutation
    } = inputOption || {}
    let key = mutation ? mutation.key : newKey;
    let updatedInputVals = this.state.inputValues || {};
    // console.log('renderItem ----->', key, newValue)
    updatedInputVals[key] = newValue;
    // console.log({ updatedInputVals, temp })
    this.setState({
      inputs: temp,
      inputValues: updatedInputVals
    })
  }


  getSelectedValues = (fieldId) => {
    const {
      concatenatedFields = {},
      formElements = []
    } = this.state;
    let vals = []
    if (concatenatedFields[fieldId]) {
      concatenatedFields[fieldId].map(el => {
        const existedField = formElements.find(el1 => el1.id === el);
        if (existedField) {
          vals.push({
            id: existedField.id,
            name: existedField.name,
            displayName: existedField.displayName,
            type: existedField.type
          })
        }
      })
    }
    return vals
  }

  getReferenceForm = (reference_form_id, reference_form_name) => {
    const {
      reference_forms = []
    } = this.state;
    const {
      isTemplate
    } = this.props;
    // console.log('getReferenceForm', {reference_form_id, reference_form_name})

    let refForm
    if (isTemplate) {
      refForm = reference_forms.find(el => (!reference_form_id && el.title === reference_form_name) || el.id === reference_form_id);
    } else {
      refForm = reference_forms.find(el => el.id === reference_form_id);
    }

    return refForm;
  }

  getReferenceSelectedValues = (reference_form_id, reference_form_name, fieldId) => {
    const {
      concatenatedFields = {},
      // formElements = []
      reference_forms = []
    } = this.state;
    const {
      isTemplate
    } = this.props;
    let refForm = this.getReferenceForm(reference_form_id, reference_form_name)

    let schemaArr = [];

    if (refForm && refForm?.schema) {
      schemaArr = JSON.parse(refForm?.schema);

    }

    let vals = []
    if (concatenatedFields[fieldId]) {
      // console.log('getReferenceSelectedValues', concatenatedFields[fieldId], schemaArr)
      concatenatedFields[fieldId].map(concField => {
        const existedField = schemaArr.find(schField => (schField.id || schField.name) === concField);
        if (existedField) {
          vals.push({
            id: existedField.id || '',
            name: existedField.name,
            displayName: existedField.displayName,
            type: existedField.type
          })
        }
      })
      // console.log('getReferenceSelectedValues', vals)
    }
    return vals
  }

  checkConcatenatedReferenceValueKey = (schema) => {
    try {
      let isIdExist = true
      schema.map(schemaEle => {
        // console.log('schemaEle', schemaEle)
        const existId = schemaEle ? schemaEle.hasOwnProperty('id') && schemaEle.id : false
        if (!existId) {
          isIdExist = false
        }
      })

      if (isIdExist) {
        return 'id'
      }
      return 'name'
    } catch (err) {
      console.log('checkConcatedReferenceValueKey error', err)
    }
  }

  createNewTags = async (tag) => {
    try {
      // console.log('createNewTags', tag)
      const { organization_id } = this.props;
      const { tags, selectedTags } = this.state;
      const { data: createdTag } = await client.mutate({
        mutation: createTemplateTag,
        variables: {
          name: tag.name,
          organization_id: organization_id,
          position: tags.length
        }
      })
      const {
        createTemplateTag: createTemplateTagData
      } = createdTag || {};
      // console.log('createTemplateTagData', createTemplateTagData);
      const {
        id,
        name
      } = createTemplateTagData || {}

      if (id) {
        this.loadTags();
        this.setState({
          selectedTags: [
            ...selectedTags,
            {
              id,
              name
            }
          ]
        })
      }
      return createdTag;
    } catch (err) {
      console.log('createNewTags error', err)
    }
  }


  onClickArchived = () => {
    this.setState({
      is_archived: !this.state.is_archived
    })
  }

  onClickArchiveTemplate = async () => {
    if (!this.props.onArchive) {
      return;
    }
    await this.props.onArchive()
  }

  ///////////////////////////////////////////// Render Functions ////////////////////////////////////////////////////

  renderFormOptionButtons = (item, index) => {
    const { theme } = this.props;
    const onClickUp = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.onMoveUp(item)
    }
    const onClickDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.onMoveDowm(item)
    }
    const onClickDelete = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.onDeleteItem(item)
    }

    const renderUpButton = () => {
      return (
        <BtnImageContainer
          onClick={onClickUp}
        >
          <BtnImageSvg
            width="18"
            height="11"
            viewBox="0 0 18 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.115 11L9 4.20108L15.885 11L18 8.90688L9 0L0 8.90688L2.115 11Z"
              fill={'black'}
            />
          </BtnImageSvg >
        </BtnImageContainer>
      )
    }
    const renderDownButton = () => {
      return (
        <BtnImageContainer
          onClick={onClickDown}
          style={{
            marginLeft: '5px',
            marginRight: '5px',
          }}
        >
          <BtnImageSvg
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.12 7.8932e-07L8 6.18084L1.88 -2.80735e-07L7.07877e-07 1.90283L8 10L16 1.90283L14.12 7.8932e-07Z"
              fill={'black'} // theme.colors.mono100 || 
            />
          </BtnImageSvg>
        </BtnImageContainer>
      )
    }
    const renderDeleteButton = () => {
      return (
        <BtnImageContainer
          onClick={onClickDelete}
        >
          <BtnImageSvg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
              fill={'black'}
            />
          </BtnImageSvg>
        </BtnImageContainer>
      );
    }
    return (
      <div
        className='form_item_option_container'
      // style={{ height: '40px' }}
      >
        <FormActionButtonContainer>
          {renderDeleteButton()}
          {renderDownButton()}
          {renderUpButton()}
        </FormActionButtonContainer>
      </div>
    )
  }

  renderTitle = (title) => {
    const { theme } = this.props;
    return (
      <HeaderTitle theme={theme}>
        {title}
      </HeaderTitle>
    )
  }

  renderSubTitle = (title) => {
    const { theme } = this.props;
    return (
      <HeaderSubTitle theme={theme}>
        {title}
      </HeaderSubTitle>
    )
  }

  renderVideoImage = (item = {}, index = 0) => {
    const onDrop = (acceptedFiles, rejectedFiles) => {
      this.onImageDrop(acceptedFiles, item)
    }

    const onDelete = () => {
      this.onImageDelete(index, item)
    }
    // const coverUrl = getPreviewImage(item.value, item.value) || [];
    const { title, placeholder, type, key, typeToPick, mutation } = item;
    const {
      inputValues
    } = this.state;

    const previewUrls = mutation ? (inputValues[mutation.key] ? getFilesUrls(inputValues[mutation.key]) : getFilesUrls(inputValues[key])) : getFilesUrls(inputValues[key]);
    return (
      <ImageUploadCXL
        showHint={false}
        isMultiple={false}
        style={{ background: 'transparent' }}
        title={title}
        titleHelpText={placeholder}
        onDrop={onDrop}
        previewUrls={previewUrls}
        onImageDelete={onDelete}
      ></ImageUploadCXL>
    )
  }

  renderFormList = (item, index) => {
    const {
      id,
      name, displayName, position, type, options, optionsObject, score,
      reference_field_form_id, reference_field_form_name,
      reference_field_type, reference_form_field_name,
      default_value_reference_form_field_name,
      is_required,
      description,
      show_concatenated_value,
      build_concatenated_value = show_concatenated_value,
      greater_than,
      less_than,
      can_use_restricted_values,
      restriction_type,
      restricted_values,
      restriction_from,
      options_metadata,
      presentation_type,
      enable_date_buffer_checker,
      is_hidden,
      default_value_cal_fns
    } = item;

    const todayDate = new Date();

    const restrictionType = restriction_type;

    // restriction_type : !restriction_from && !restriction_type ? 'today' :
    //   restriction_from && isEqualDate(new Date(`${todayDate.getMonth() + 1}/${todayDate.getDate()}/${todayDate.getFullYear()}`), new Date(restriction_from)) & restriction_type == 'today' ?
    //     'today' : 'custom';


    const amountOptions = (optionsObject || []).map(option => {
      if (option) {
        return option.display
      }
      return '';
    })

    const onChange = (key, value) => {
      const options = [...this.state.formElements]
      const temp = options.map(i => {

        if (i.position == position) {
          if (i.type !== 'Reference' && i['reference_field_form_id']) {
            return {
              ...i,
              [key]: value,
              reference_field_form_id: '',
              reference_field_type: '',
              reference_form_field_name: ''
            }
          }
          return {
            ...i,
            [key]: value
          }
        }
        return i;
      })
      // console.log('temp ', { key, temp, options })
      this.setState({ formElements: temp })
    }

    const onChangeMultipleValues = (updatedValues) => {
      const options = [...this.state.formElements]
      const temp = options.map(i => {

        if (i.position == position) {
          if (i.type !== 'Reference' && i['reference_field_form_id']) {
            return {
              ...i,
              ...(updatedValues || {}),
              reference_field_form_id: '',
              reference_field_type: '',
              reference_form_field_name: ''
            }
          }
          return {
            ...i,
            ...(updatedValues || {})
          }
        }
        return i;
      })
      // console.log('temp ', { temp, options })
      this.setState({ formElements: temp })
    }

    const onChangeReferenceFieldForm = ({ value }) => {
      // console.log('onChangeReferenceFieldForm', { value }, value[0].id)
      // onChange('reference_field_form_id', value[0].id)
      // onChange('reference_field_form_name', value[0].title)
      onChangeMultipleValues({
        'reference_field_form_id': value[0].id,
        'reference_field_form_name': value[0].title
      })
    }

    const onChangeReferenceFieldFormName = ({ value }) => {
      // console.log('onChangeReferenceFieldForm', { value }, value[0].name)
      onChange('reference_form_field_name', value[0].name);
      if (!default_value_reference_form_field_name) {
        onChange('default_value_reference_form_field_name', value[0].name)
      }
    }

    const onChangeDefaultReferenceFieldFormName = ({ value }) => {
      // console.log('onChangeDefaultReferenceFieldFormName', { value }, value[0].name)
      onChange('default_value_reference_form_field_name', value[0].name)
    }

    const onAddOption = (options) => {
      onChange('options', options)
    }

    const onAddAmountOption = (options) => {
      var numberPattern = /\d+/g;
      const formatedOptions = options.map(option => {
        const optionNum = option.match(numberPattern).join('')
        console.log(option, optionNum)
        return ({
          display: option,
          value: optionNum
        });
      })
      onChange('optionsObject', formatedOptions)
    }

    const onClickShowConcatednatedValue = e => {
      onChange('show_concatenated_value', e.target.checked)
    }

    const onClickBuildConcatednatedValue = (e) => {
      onChange('build_concatenated_value', e.target.checked)
    }

    const onClickEnableDateChecker = (e) => {
      onChange('enable_date_buffer_checker', e.target.checked)
    }

    const onChangeOptionHelper = (option, e) => {
      let updatedOptionMetadata = [...(options_metadata || [])];
      let optionIdx = updatedOptionMetadata.findIndex(optionM => optionM.key === option)
      let optionMetadata = {
        ...(updatedOptionMetadata[optionIdx] || {
          key: option
        })
      }

      optionMetadata['help_text'] = e.target.value;
      if (optionIdx >= 0) {
        updatedOptionMetadata[optionIdx] = optionMetadata;
      } else {
        updatedOptionMetadata.push({
          ...optionMetadata
        })
      }
      onChange('options_metadata', updatedOptionMetadata)
    }


    ///////////////////////////////////////////////// Render Functions //////////////////////////////////////////////////

    const renderNumberGreaterLessThan = () => {
      if ((type || '').toLowerCase() !== 'number') return;
      const onChangeGreaterThan = (e) => {
        onChange('greater_than', e.target.value)
      }

      const onChangeLessThan = (e) => {
        onChange('less_than', e.target.value)
      }
      return (
        <GreaterLessThanContainer>
          <div
            style={{
              flex: 1,
            }}
          >
            {this.renderTitle('Greater Than')}
            <CustomInput
              value={greater_than}
              onChange={onChangeGreaterThan}
              placeholder={`Enter Greater Than Limit`}
              clearOnEscape
              type='number'
            />
          </div>
          <GreaterLessThanSeparator>
            <span>
              -
            </span>
          </GreaterLessThanSeparator>
          <div
            style={{
              flex: 1
            }}
          >
            {this.renderTitle('Less Than')}
            <CustomInput
              value={less_than}
              onChange={onChangeLessThan}
              placeholder={`Enter Less Than Limit`}
              clearOnEscape
              type='number'
            />
          </div>
        </GreaterLessThanContainer>
      )
    }

    const renderDateGreaterLessThan = () => {
      if ((type || '').toLowerCase() !== 'date') return;

      const onChangeGreaterThan = ({ date }) => {
        console.log('greater_than', date)
        if (!date) {
          onChange('greater_than', '')
          return;
        }
        onChange('greater_than', date.toISOString())
      }

      const onChangeLessThan = ({ date }) => {
        if (!date) {
          onChange('less_than', '')
          return;
        }
        onChange('less_than', date.toISOString())
      }

      const greaterThan = greater_than ? new Date(greater_than) : '';
      const lessThan = less_than ? new Date(less_than) : '';
      return (
        <GreaterLessThanContainer>
          <div
            style={{
              flex: 1,
            }}
          >
            {this.renderTitle('Greater Than')}
            <Datepicker
              value={greaterThan}
              onChange={onChangeGreaterThan}
              size={SIZE.mini}
              maxDate={lessThan}
              formatString="MM/dd/yyyy"
              placeholder="MM/dd/yyyy"
              overrides={dateOverrides}
              clearable
            />
          </div>
          <GreaterLessThanSeparator>
            <span>
              -
            </span>
          </GreaterLessThanSeparator>
          <div
            style={{
              flex: 1,
            }}
          >
            {this.renderTitle('Less Than')}
            <Datepicker
              value={lessThan}
              onChange={onChangeLessThan}
              minDate={greaterThan}
              size={SIZE.mini}
              formatString="MM/dd/yyyy"
              placeholder="MM/dd/yyyy"
              overrides={dateOverrides}
              clearable
            />
          </div>
        </GreaterLessThanContainer>
      )
    }

    const renderDateRestricteDates = () => {
      if ((type || '').toLowerCase() !== 'date') return;

      const onChangeFromDate = ({ date }) => {
        if (!date) {
          onChange('restriction_from', '')
          return;
        }

        let restrictedValues = getDatesFromDateByNumber(date.toISOString(), noOfDays);
        onChangeMultipleValues({
          'restriction_from': `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
          'restricted_values': restrictedValues
        })
      }

      const onChangeNumberofDays = (e) => {
        const numberOfDays = parseInt(e.target.value || 0);
        let restrictedValues = getDatesFromDateByNumber(fromDate, numberOfDays);

        onChange('restricted_values', restrictedValues)
      }

      const onChangeUseRestriction = e => {
        const changedValue = e.target.checked
        if (!changedValue) {
          onChangeMultipleValues({
            'can_use_restricted_values': false,
            'restriction_from': '',
            'restricted_values': '',
            'restriction_type': '',
          })
        } else {
          onChange('can_use_restricted_values', e.target.checked)
        }

      }

      // const fromDate = restriction_from ? new Date(restriction_from) : new Date();
      const fromDate = restrictionType === 'today' ? new Date() : restriction_from ? new Date(restriction_from) : new Date();
      const noOfDays = restricted_values && Array.isArray(restricted_values) ? restricted_values.length : 0;
      // console.log('renderDateRestricteDates  ----------', { restriction_type, restrictionType, restriction_from, restricted_values })
      return (
        <DateRestrictionContaner>
          <div
            style={{
              flex: 1
            }}
          >
            <Checkbox
              checked={can_use_restricted_values}
              onChange={onChangeUseRestriction}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Restrict Dates</ParagraphSmall>
            </Checkbox>
          </div>
          {
            can_use_restricted_values && (
              <>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  {this.renderSubTitle(`Restriction From`)}
                  <Select
                    options={restricted_field_types}
                    labelKey="label"
                    valueKey="value"
                    overrides={{
                      Popover: {
                        props: {
                          overrides: {
                            Body: { style: { zIndex: 3 } },
                          },
                        },
                      },
                    }}
                    value={restricted_field_types.filter((el) => el.value == restrictionType)}
                    onChange={({ value }) => {

                      let updatePaylod = {

                      }
                      let selectedTypeDate = new Date();
                      if (value[0].value === 'today') {
                        selectedTypeDate = todayDate;
                        let restrictedValues = getDatesFromDateByNumber(todayDate.toISOString(), noOfDays ? noOfDays : 1);

                        updatePaylod = {
                          "restriction_from": `${todayDate.getMonth() + 1}/${todayDate.getDate()}/${todayDate.getFullYear()}`,
                          'restriction_type': value[0].value,
                          'restricted_values': restrictedValues
                        }
                      } else {
                        if (fromDate) {
                          let restrictedValues = getDatesFromDateByNumber(fromDate.toISOString(), noOfDays ? noOfDays : 1);
                          updatePaylod = {
                            'restriction_type': value[0].value,
                            'restricted_values': restrictedValues
                          }
                        } else {
                          updatePaylod = {
                            'restriction_type': value[0].value,
                          }
                        }
                      }

                      onChangeMultipleValues({
                        ...updatePaylod
                      })

                    }}
                  />

                </div>
                <GreaterLessThanContainer>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    {this.renderSubTitle(`Restriction From Date`)}
                    <Datepicker
                      value={fromDate}
                      onChange={onChangeFromDate}
                      size={SIZE.mini}
                      formatString="MM/dd/yyyy"
                      placeholder="MM/dd/yyyy"
                      overrides={dateOverrides}
                      disabled={restrictionType == 'today'}
                      clearable
                    />

                  </div>
                  <GreaterLessThanSeparator>
                    <span>
                      -
                    </span>
                  </GreaterLessThanSeparator>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    {this.renderSubTitle('Restricted Number of Dates')}
                    <CustomInput
                      value={noOfDays}
                      onChange={onChangeNumberofDays}
                      placeholder={`Enter number of days`}
                      clearOnEscape
                      type='number'
                    />
                  </div>
                </GreaterLessThanContainer>
              </>
            )
          }
        </DateRestrictionContaner>
      )
    }

    const renderTagsPopOverContent = (tag) => {
      const onChangeVal = (e) => {
        onChangeOptionHelper(tag, e)
      }

      let optionIdx = (options_metadata || []).findIndex(optionM => optionM.key === tag)

      const optionMeta = (options_metadata || [])[optionIdx] || {};

      return (
        <Block padding={"20px"} minWidth={100} minHeight={100} backgroundColor={'white'} >
          {this.renderSubTitle('Helper Text')}
          <Textarea
            placeholder="Enter help text"
            value={optionMeta?.help_text || ''}
            onChange={onChangeVal}
          />
        </Block>
      )
    }



    const buildConcatenatedValue = build_concatenated_value;

    let fieldPresentationType = selectFieldPresentationTypes?.filter(el => el.id === presentation_type);
    if (fieldPresentationType.length <= 0) {
      fieldPresentationType = [selectFieldPresentationTypes[0]]
    }

    // console.log('renderFormList ----->', reference_field_form_id, show_concatenated_value && type === 'Reference')
    return (
      <Panel
        title={
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'

            }}

            id={'position_' + index}
            key={'position_' + index}
          >
            {
              displayName && (
                <FormFieldHeaderTextContainer>
                  {displayName}
                </FormFieldHeaderTextContainer>
              ) || (
                <FieldNumberContainer>
                  {`${index + 1}`}
                </FieldNumberContainer>
              )
            }
            {this.renderFormOptionButtons(item, index)}
          </div>
        }

      >
        <div
          className='form_list_container'
        // style={{ marginTop: '1rem' }}
        >
          <div className='form_item_container'>
            {/* {this.renderFormOptionButtons(item, index)} */}
            <div
              className='form_item_innner_container'
            // style={{ 
            //   background: 'white', 
            //   // padding: '1rem' 
            // }}
            >
              <div>
                {this.renderTitle('Form Label')}
                <CustomInput
                  value={displayName}
                  onChange={(e) => onChange('displayName', e.target.value)}
                  placeholder={`Enter Form Label`}
                  clearOnEscape
                // maxLength={125}
                />
                {this.renderTitle('Form Field')}
                <CustomInput
                  value={name}
                  onChange={(e) => onChange('name', e.target.value)}
                  placeholder={`Enter Form Field`}
                  clearOnEscape
                // maxLength={125}
                />
                {this.renderTitle('Form Field Description')}
                <CustomInput
                  value={description}
                  onChange={(e) => onChange('description', e.target.value)}
                  placeholder={`Enter Form Field Description`}
                  clearOnEscape
                // maxLength={125}
                />
                {
                  type === 'Reference' && (
                    <>
                      {this.renderTitle('Reference Form')}
                      {this.renderReferenceFormsField(reference_field_form_id, reference_field_form_name, onChangeReferenceFieldForm)}
                      {this.renderTitle('Reference Form Field Name')}
                      {this.renderReferenceFormsFieldName(reference_field_form_id, reference_field_form_name, reference_form_field_name, onChangeReferenceFieldFormName)}
                      {this.renderTitle('Reference Form Type')}
                      <Select
                        options={referenceFormFieldType}
                        labelKey="label"
                        valueKey="id"
                        overrides={{
                          Popover: {
                            props: {
                              overrides: {
                                Body: { style: { zIndex: 3 } },
                              },
                            },
                          },
                        }}
                        value={referenceFormFieldType.filter((el) => el.id == reference_field_type)}
                        onChange={({ value }) => {
                          if (value.length == 0) onChange('type', '');
                          else {
                            onChange('reference_field_type', value[0].id);
                          }
                        }}
                      ></Select>
                    </>
                  )
                }

                {this.renderTitle('Score')}
                <CustomInput
                  value={score}
                  onChange={(e) => onChange('score', 1 * e.target.value)}
                  placeholder={`Enter Score of Question`}
                  clearOnEscape
                  maxLength={125}
                />
                {this.renderTitle('Field Type')}
                <Select
                  options={formFieldType}
                  labelKey="label"
                  valueKey="id"
                  overrides={{
                    Popover: {
                      props: {
                        overrides: {
                          Body: { style: { zIndex: 3 } },
                        },
                      },
                    },
                  }}
                  value={formFieldType.filter((el) => el.id == type)}
                  onChange={({ value }) => {
                    if (value.length == 0) onChange('type', '');
                    else {
                      onChange('type', value[0].id);
                    }
                  }}
                ></Select>
                {
                  type === 'Select' && (
                    <>
                      {this.renderTitle(`${type} Field Presentation Type`)}
                      <Select
                        options={selectFieldPresentationTypes}
                        labelKey="label"
                        valueKey="id"
                        overrides={{
                          Popover: {
                            props: {
                              overrides: {
                                Body: { style: { zIndex: 3 } },
                              },
                            },
                          },
                        }}
                        value={fieldPresentationType}
                        onChange={({ value }) => {
                          if (value.length == 0) onChange('presentation_type', '');
                          else {
                            onChange('presentation_type', value[0].id);
                          }
                        }}
                      ></Select>
                    </>
                  )
                }
                {
                  type === 'Select' && (
                    <TagsInput
                      tags={options}
                      title={'Dropdown Options'}
                      placeholder={'Enter a Option and hit Enter'}
                      inputTip={'Type name of your option and hit "Enter" to select it'}
                      onChangeOption={onAddOption}
                      showPopOver={true}
                      popOverContent={renderTagsPopOverContent}
                    />
                  )
                }
                {
                  type === 'Amount' && (
                    <TagsInput
                      tags={amountOptions}
                      title={'Dropdown Options'}
                      placeholder={'Enter a Option and hit Enter'}
                      inputTip={'Type your amount and hit "Enter" to select it'}
                      onChangeOption={onAddAmountOption}
                    />
                  )
                }
                {
                  type === 'Multiple-Select' && (
                    <TagsInput
                      tags={options}
                      title={'Multiple Select Options'}
                      placeholder={'Enter a Option and hit Enter'}
                      inputTip={'Type name of your option and hit "Enter" to select it'}
                      onChangeOption={onAddOption}
                      showPopOver={true}
                      popOverContent={renderTagsPopOverContent}
                    />
                  )
                }
                {renderNumberGreaterLessThan()}
                {renderDateGreaterLessThan()}
                {renderDateRestricteDates()}
                {(type.toLowerCase() === 'date' && can_use_restricted_values) && (
                  <Checkbox
                  checked={enable_date_buffer_checker}
                  onChange={onClickEnableDateChecker}
                  overrides={{
                    Root: {
                      style: () => ({
                        marginTop: '16px',
                        marginBottom: '11px'
                      })
                    }
                  }}
                >
                  <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Do you want to enable date checker</ParagraphSmall>
                </Checkbox>
                )}
                <Checkbox
                  checked={buildConcatenatedValue}
                  onChange={onClickBuildConcatednatedValue}
                  overrides={{
                    Root: {
                      style: () => ({
                        marginTop: '16px',
                        marginBottom: '11px'
                      })
                    }
                  }}
                >
                  <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Build concatenated string </ParagraphSmall>
                </Checkbox>

                {
                  type === 'Reference' && (
                    <>
                      {this.renderTitle('Set Default Value Reference Field')}
                      {this.renderReferenceFormsFieldName(reference_field_form_id, reference_field_form_name, default_value_reference_form_field_name, onChangeDefaultReferenceFieldFormName)}
                    </>
                  )
                }
                {
                  buildConcatenatedValue && type === 'Reference' && (
                    <>
                      {this.renderTitle('Fields Concatenated values')}
                      {this.renderReferenceConcatedFormFields(reference_field_form_id, reference_field_form_name, id)}
                    </>
                  )
                }
                {
                  buildConcatenatedValue && type !== 'Reference' && (
                    <>
                      {this.renderTitle('Fields Concatenated values')}
                      {this.renderConcatedFormFields(id)}
                    </>
                  )
                }


                <Checkbox
                  checked={show_concatenated_value}
                  onChange={onClickShowConcatednatedValue}
                  overrides={{
                    Root: {
                      style: () => ({
                        marginTop: '16px',
                        marginBottom: '11px'
                      })
                    }
                  }}
                >
                  <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Replace string with concatenated values</ParagraphSmall>
                </Checkbox>

                <Checkbox
                  checked={is_required}
                  onChange={e => onChange('is_required', e.target.checked)}
                  overrides={{
                    Root: {
                      style: () => ({
                        marginTop: '16px',
                        marginBottom: '11px'
                      })
                    }
                  }}
                >
                  <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Required</ParagraphSmall>
                </Checkbox>

                <Checkbox
                  checked={is_hidden}
                  onChange={e => onChange('is_hidden', e.target.checked)}
                  overrides={{
                    Root: {
                      style: () => ({
                        marginTop: '16px',
                        marginBottom: '11px'
                      })
                    }
                  }}
                >
                  <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is Hidden Field?</ParagraphSmall>
                </Checkbox>

                {is_hidden && <>
                  {this.renderTitle('Default Value calculation function')}
                  <ParagraphSmall style={{ marginTop: '1px', marginBottom: '4px' }}>This function is going to have two arguments first one is app context(appCtx) and second one is auth context(authCtx).</ParagraphSmall>
                  <CustomInput
                    value={default_value_cal_fns}
                    onChange={(e) => onChange('default_value_cal_fns', e.target.value)}
                    placeholder={`Write Function`}
                    clearOnEscape
                  // maxLength={125}
                  />
                </>}

                {/* <CustomInput
                value={field_type}
                onChange={(e) => onChange('field_type', e.target.value)}
                placeholder={`Enter Field Type`}
                clearOnEscape
                maxLength={125}
              /> */}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    )
  }

  renderAddFormButton = () => {
    const { css } = this.props;
    const btnOverrides = {
      BaseButton: {
        style: {
          color: '#4A4CA0',
          maxWidth: '356px',
          width: '100%',
          border: '3px solid #4D4B45',
          boxSizing: 'border-box',
          boxShadow: '0px 8px 36px rgba(0, 0, 0, 0.17)',
          borderRadius: '20px',
          background: 'white',
          ':hover': {
            background: '#4A4CA0',
            color: 'white'
          }
        }
      }
    };

    const startEnhancer = () => (
      <img
        src={svgIcons.AddGradientIcon}
        style={{
          width: '20px',
          height: '20px',
          marginBottom: '0'
        }}
      />
    )
    return (
      <AddAdditionalContainer>
        <Button
          onClick={this.onAdd}
          overrides={btnOverrides}
          startEnhancer={startEnhancer}
        >
          Add additional form element
        </Button>
      </AddAdditionalContainer>
    )
  }


  renderItem(item) {
    const { key, type, title, value, options, placeholder, isVisible, visibilityParameter, mutation } = item;
    const {
      inputValues = {}
    } = this.state
    const itemKey = key;
    const itemValue = value;
    const { theme, organization_id } = this.props;


    switch (type) {
      case 'Input':
        return (
          <CustomInput
            value={itemValue}
            onChange={(e) => this.onChangeItemValue(itemKey, e.target.value)}
            placeholder={`Enter ${title || ''}`}
            clearOnEscape
          // maxLength={125}
          />
        )
      case 'Select':
        return (
          <SelectCXL
            state={this.state}
            {...item}
            onChange={(value) => this.onChangeItemValue(itemKey, value)}
            value={itemValue}
            overrides={{
              Popover: {
                props: {
                  overrides: {
                    Body: { style: { zIndex: 3 } },
                  },
                },
              },
            }}
          ></SelectCXL>
        )
      case 'FilePicker':
        return this.renderVideoImage(item)
      case 'Checkbox':
        return (
          <>
            {placeholder && this.renderTitle(title)}
            <Checkbox
              checked={itemValue}
              onChange={e => this.onChangeItemValue(itemKey, e.target.checked)}
              overrides={{
                Root: {
                  style: () => ({
                    marginTop: !placeholder && '16px' || '0px',
                    marginBottom: !placeholder && '11px' || '0px'
                  })
                }
              }}
            >
              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>{placeholder || title}</ParagraphSmall>
            </Checkbox>
          </>
        )
        case 'CrunchbaseIntegrationPicker':
          return (
            <CrunchbaseIntegrationPicker
              organization_id={organization_id}
              {...item} 
              theme={theme}
              value={itemValue}
              onChange={(value) => this.onChangeItemValue(itemKey, value)}
              importFormField={(value) => this.importFormFields(value)}
               />
          )
      default:
        break;

    }
  }

  renderInputFields = () => {
    return this.state.inputs.map(item => {
      const { title, isVisible, visibilityParameter, type } = item;
      if (isVisible) {
        const visibilityVal = this.state.inputValues[visibilityParameter]
        if (isVisible(visibilityVal) === false) {
          return null;
        }
      }
      return (
        <div>
          {title && type !== 'Checkbox' && this.renderTitle(title)}
          {this.renderItem(item)}
        </div>
      )

    })
  }

  renderReferenceFormsField = (value, field_name, onChange) => {
    const {
      isReferenceFormsLoading,
      reference_forms
    } = this.state || {};
    const {
      isTemplate
    } = this.props

    let rfValue = this.getReferenceForm(value, field_name);
    // if (isTemplate) {
    //   rfValue = reference_forms.filter((el) => el.title === field_name)
    // } else {
    //   rfValue = reference_forms.filter((el) => el.id == value)
    // }
    console.log('renderReferenceFormsField', { value, reference_forms, rfValue, field_name })
    //1f5b8e34-b335-4ceb-b74b-8f8b03bce63a
    return (

      <Select
        isLoading={isReferenceFormsLoading}
        options={reference_forms}
        value={rfValue}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
        disabled={rfValue && isTemplate}
        placeholder={rfValue ? '' : "Select Reference Form"}
        valueKey={'id'}
        labelKey={'title'}
        onChange={onChange}
      />
    )
  }

  renderReferenceFormsFieldName = (reference_form_id, reference_form_name, value, onChange) => {
    const {
      isReferenceFormsLoading,
      reference_forms,
    } = this.state || {};
    const {
      isTemplate
    } = this.props
    let rfValue;

    let refForm = this.getReferenceForm(reference_form_id, reference_form_name)
    // if (isTemplate) {
    //   refForm = reference_forms.find(el => el.title === reference_form_name);
    // } else {
    //   refForm = reference_forms.find(el => el.id === reference_form_id);
    // }

    // console.log('renderReferenceFormsFieldName', { reference_form_id, value, refForm, reference_forms })
    let schemaArr = [];

    if (refForm && refForm?.schema) {
      schemaArr = JSON.parse(refForm?.schema);
      rfValue = schemaArr.find((el) => el.name == value)
    }

    return (

      <Select
        isLoading={isReferenceFormsLoading}
        options={schemaArr}
        value={rfValue}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
        placeholder={rfValue ? '' : "Select Reference Form Field"}
        valueKey={'name'}
        labelKey={'displayName'}
        onChange={onChange}
      />
    )
  }

  renderDefaultReferenceFormsFieldName = (reference_form_id, reference_form_name, value, onChange) => {
    const {
      isReferenceFormsLoading,
      reference_forms,
    } = this.state || {};
    const {
      isTemplate
    } = this.props
    let rfValue;

    let refForm = this.getReferenceForm(reference_form_id, reference_form_name)
    // if (isTemplate) {
    //   refForm = reference_forms.find(el => el.title === reference_form_name);
    // } else {
    //   refForm = reference_forms.find(el => el.id === reference_form_id);
    // }

    // console.log('renderDefaultReferenceFormsFieldName', { reference_form_id, value, refForm, reference_forms })
    let schemaArr = [];

    if (refForm && refForm?.schema) {
      schemaArr = JSON.parse(refForm?.schema);
      rfValue = schemaArr.find((el) => el.name == value)
    }

    return (

      <Select
        isLoading={isReferenceFormsLoading}
        options={schemaArr}
        value={rfValue}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
        placeholder={"Select Reference Form Field"}
        valueKey={'name'}
        labelKey={'displayName'}
        onChange={onChange}
      />
    )
  }

  renderConcatedFormFields = (fieldId) => {
    const {
      formElements = [],
      concatenatedFields = {}
    } = this.state;
    const formFieldsList = [...formElements];
    const onChange = ({ value }) => {
      console.log(fieldId, value)
      let updatedConcatenatedFields = {
        ...concatenatedFields,
        [fieldId]: value.map(el => el.id)
      }
      console.log({ updatedConcatenatedFields })
      this.setState({
        concatenatedFields: updatedConcatenatedFields
      }, () => {
        // console.log('after', { state: this.state })
      })
    }

    const selectedValues = concatenatedFields[fieldId] && concatenatedFields[fieldId].map(el => ({ id: el })) || [];
    // console.log({ fieldId, selectedValues, concatenatedFields })
    return (
      <Select
        options={[...formFieldsList]}
        value={selectedValues}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
        multi
        placeholder={"Select Concatenated Fields"}
        valueKey={'id'}
        labelKey={'displayName'}
        onChange={onChange}
      />
    )
  }

  renderReferenceConcatedFormFields = (reference_form_id, reference_form_name, fieldId) => {

    const {
      concatenatedFields = {},
      reference_forms = []
    } = this.state;

    const {
      isTemplate
    } = this.props
    let refForm = this.getReferenceForm(reference_form_id, reference_form_name)
    // if (isTemplate) {
    //   refForm = reference_forms.find(el => el.title === reference_form_name);
    // } else {
    //   refForm = reference_forms.find(el => el.id === reference_form_id);
    // }


    let schemaArr = [];

    if (refForm && refForm?.schema) {
      schemaArr = JSON.parse(refForm?.schema);

    }
    const formFieldsList = [...schemaArr];
    let valueKey = this.checkConcatenatedReferenceValueKey(formFieldsList);

    const onChange = ({ value }) => {
      console.log(fieldId, value)
      let updatedConcatenatedFields = {
        ...concatenatedFields,
        [fieldId]: value.map(el => (el.id || el.name))
      }
      // console.log({ updatedConcatenatedFields })
      this.setState({
        concatenatedFields: updatedConcatenatedFields
      }, () => {
        // console.log('after', { state: this.state })
      })
    }

    const selectedValues = concatenatedFields[fieldId] && concatenatedFields[fieldId].map(el => ({ [valueKey]: el })) || [];
    // console.log('renderReferenceConcatedFormFields', { fieldId, selectedValues, concatenatedFields, formFieldsList, schemaArr, refForm })
    return (
      <Select
        options={[...formFieldsList]}
        value={selectedValues}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Body: { style: { zIndex: 3 } },
              },
            },
          },
        }}
        multi
        placeholder={"Select Concatenated Fields"}
        valueKey={valueKey}
        labelKey={'displayName'}
        onChange={onChange}
      />
    )
  }

  renderTags = () => {
    const {
      selectedTags = [],
      tags,
      isTagCreating = false,
      tagsLoading,
      inputValues
    } = this.state || {};
    // if (!this.props.isTemplate && !inputValues.is_template) {
    //   return null;
    // }

    const onSelectTags = ({ value, option }) => {
      if (option.isCreatable) {
        this.createNewTags(option);
        return;
      }
      // setSelectedChannels(params.value);
      this.setState({
        selectedTags: [
          ...value
        ]
      })
    }

    return (
      <>
        {this.renderTitle('Tags')}
        <Select
          creatable
          options={tags}
          value={selectedTags}
          overrides={{
            Popover: {
              props: {
                overrides: {
                  Body: { style: { zIndex: 3 } },
                },
              },
            },
          }}
          multi
          placeholder="Select Tags"
          valueKey={'id'}
          labelKey={'name'}
          onChange={onSelectTags}
          isLoading={tagsLoading || isTagCreating}
        />
      </>
    )
  }

 

  render() {

    const {
      formElements,
      isLoading,
      inputValues,
      inputs,
      homeComponentFormList,
      importLoading
    } = this.state || {};
    const {
      isTemplateArchived,
      isTemplate
    } = this.props
    const options = formElements || [];
    console.log('inputValues', { formElements, selectedModule: this.props.selectedModule, options }, this.state)
    return (
      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Root: {
            style: {
              zIndex: 3,
            }
          },
        }}
        isOpen={true}
        returnFocus={false}
        size={'full'}
        onClose={() => {
          // this.props.onSave && this.props.onSave(this.state);
          this.props.onClose && this.props.onClose();
        }}
      >
        <ModalHeader>Form Information</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {!this.props.hideBasicInputs && <div style={{ width: '50%', padding: '1rem', height: '80vh', overflowY: 'auto' }}>
              {this.renderInputFields()}  

              {this.renderTags()}

            </div>}
            <div className='form_builder_container' style={{ width: '50%', background: 'black', padding: '1rem', maxHeight: '80vh', overflowY: 'auto' }}>
              <Accordion

                accordion
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      marginLeft: 0,
                    })
                  },
                  PanelContainer: {
                    style: ({ $theme }) => ({
                      backgroundColor: 'white',
                      marginBottom: '1rem'
                    })
                  }
                }}
              >
                {options && options.map(this.renderFormList)}
              </Accordion>
              {this.renderAddFormButton()}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {/* {
            isTemplate && (

              <ModalButton
                onClick={this.onSave}
                isLoading={isLoading}
              >
                {isTemplateArchived ? 'Unarchive' : 'Archive'}
              </ModalButton>
            )
          } */}
          <ModalButton
            onClick={this.onSave}
            isLoading={isLoading}
          >
            Save
          </ModalButton>
          <ModalButton
            onClick={() => {
              this.props.onClose && this.props.onClose();
            }}
          >
            Cancel
          </ModalButton>
        </ModalFooter>
      </Modal>

    );
  }
};


export const FormBuilderHomeComponent = (props) => {
  const dispatch = useDispatch();

  const onSave = (payload) => {
    const { refetchHomeComponents, selectedModule, updateMutation, onClose } = props
    const id = selectedModule.content_module.id;
    const mutation = updateMutation;
    // console.log('mutation', updateMutation)
    return client.mutate({
      mutation,
      variables: {
        id,
        // published,
        // archived,
        ...payload,
      }
    }).then(async (result) => {
      dispatch(
        showAlert({
          msg: 'Successfully updated data!',
          error: false,
        })
      );
      await refetchHomeComponents();
      onClose();
      return;
    }).catch((err) => {
      console.log('error', err);
    })
  };
  return <FormBuilderComponent onSave={onSave} {...props} dispatch={dispatch} />
};

const mapStateToProps = (props) => {
  let { selectedChannel } = props.appBasicControls;
  return {
    selectedChannel
  }
}


export default connect(mapStateToProps)(FormBuilderComponent);
