import * as React from 'react';
import { useMutation } from '@apollo/client';
import { addCategoryMutation } from '../../quries';
import { category_help, component_help } from '../help/helpTexts';
import AddContentButton from '../addContentButton';
import AddEditCategory from './editCategory';
import AddEditComponent from './addOrEditComponent';
import { themeColor } from '../../../data/config';
import { useStyletron } from 'baseui';
import { Cell, Grid } from 'baseui/layout-grid';
import { gridPaddingMarginOverrides } from '../overrides';

// to switch between the add category type and the
// modal means on button click modal will appear which will add new category
// in inline style input box with with only name will appear on screen at same place of button
const ADD_CATEGORY_TYPE = 'modal'; // inline;

export default ({ position, app_content_scope_id, organization_id, home_component_app_content_scopes, refetchHomeComponents }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [name, setName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [
    addCategory,
    { loading: onCreateLoader, data: createdChannel },
  ] = useMutation(addCategoryMutation);

  const [addInModal, setAddInModal] = React.useState(false);
  const [addComponentInModal, setAddComponentInModal] = React.useState(false);
  const [css, theme] = useStyletron();

  React.useEffect(() => {
    setIsLoading(false);
    setName('');
    createdChannel && setIsOpen(false);
  }, [createdChannel]);

  const onSave = () => {
    setIsLoading(true);
    addCategory({
      variables: {
        name,
        app_content_scope_id: app_content_scope_id,
        position: position,
        created_by_id: '6e7bcba0-f194-4acf-b476-26d6594e88cb',
        organization_id,
        public: true,
        description: '',
        archived: false,
        published: true,
      },
    });
  };

  const onClose = () => { setAddInModal(false); setAddComponentInModal(false); };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='add-category-container'>

        <Grid
          overrides={gridPaddingMarginOverrides}
        >
          <Cell
            span={[5, 4, 6]}
            overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important'
                })
              }
            }}
          >
            <AddContentButton
              style={{
                marginTop: '5px',
                padding: '13px 17px',
              }}
              buttonText={'Category'}
              helpText={category_help}
              inputPlaceholder={'Add New Category'}
              inputValue={name}
              setInputValue={setName}
              onSave={onSave}
              isLoading={isLoading}
              buttonClickOverride={
                ADD_CATEGORY_TYPE == 'modal' ? () => setAddInModal(true) : null
              }
            />
          </Cell>

          <Cell
            span={[5, 4, 6]}
            overrides={{
              Cell: {
                style: () => ({
                  padding: '0px !important',
                  paddingLeft: '9.5px !important',
                })
              }
            }}
          >
            <AddContentButton
              buttonText={'Component'}
              buttonType='light'
              helpText={component_help}
              inputPlaceholder={'Add New Component'}
              inputValue={name}
              setInputValue={setName}
              onSave={null}
              isLoading={isLoading}
              style={{
                marginTop: '5px',
                background: 'white',
                border: `3px solid ${theme.colors.primaryC}`,
                color: `${theme.colors.primaryC} !important`,
                ':hover': {
                  border: '0px solid !important',
                  padding: '13px 17px',
                },
                ':hover .question-mark-help-icon-div svg': {
                  color: theme.colors.primaryC
                },
                ':hover .question-mark-help-icon-div i': {
                  background: 'white !important',
                },
              }}
              buttonClickOverride={
                ADD_CATEGORY_TYPE == 'modal' ? () => setAddComponentInModal(true) : null
              }
            />
          </Cell>
        </Grid>

        {/* <span style={{width: '1rem'}}></span> */}

      </div>
      {addInModal && (
        <AddEditCategory
          category={{}}
          position={position}
          app_content_scope_id={app_content_scope_id}
          onClose={onClose}
          organization_id={organization_id}
        ></AddEditCategory>
      )}
      {addComponentInModal && (
        <AddEditComponent
          component={{}}
          position={position}
          app_content_scope_id={app_content_scope_id}
          onClose={onClose}
          organization_id={organization_id}
          refetchHomeComponents={refetchHomeComponents}
          organization_id={organization_id}
        ></AddEditComponent>
      )}
    </>
  );
};
