import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import gql from 'graphql-tag';
import { useLazyQuery, useQuery } from '@apollo/client';
import { navigate } from 'gatsby-link';
import { Skeleton } from 'baseui/skeleton';
import { LabelLarge, LabelMedium, ParagraphSmall } from 'baseui/typography';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { useMutation } from '@apollo/client';
import { Textarea } from 'baseui/textarea';
import { useStyletron } from 'baseui';

import RightSidePageHeader from '../channel/channelHeader';
// import ChannelsDropDown from '../channel/channelsDropdownV1';
import ChannelsDropDown from '../channel/bk-channelsDropdownV1'
import { editorBtnOptions as CardBuilderOptions } from '../../../data/cardBuilderOptions';
import AddContentButton from '../addContentButton';
import Card from '../cards/card';
import { getSrc } from '../../util';
import { useUrlParams } from '../help';
import AddCard from '../cards/addCardStackCard';
import { card_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import titlesLength from '../../../data/titlesLength.json';
import { addCardStackCard, addCopyCard } from '../../quries';
import FormBuilderComponent, { FormBuilderHomeComponent } from '../builder/formBuilder';
import { getUpdateMutationsForHomeComponent } from '../builder/builderUtils/save';
import { createSharePointFormModuleTemplate, sharepoint_form_templates, template_tags_org, updateSharePointFormModuleTemplate } from './queries';
import { Select } from 'baseui/select';
import { FileUploader } from 'baseui/file-uploader';
import axios from 'axios';
import { client, getServerUrl } from '../../apollo/client';
import TemplateTags from './templateTags';
import SharepointFormTemplates from './SharepointFormTemplates';
import Channels from './channels';
import Templates from './templates';
import moment from 'moment';

const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

const AllCardsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const CardsContainer = styled.div`
  display: flex;
  flex: 1;
  backgroundColor: red;
`;

const CardListContainer = styled.div`
  display: flex;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
`;

const CardListInnerContainer = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
  flex-wrap: wrap;
`;

const CardContainer = styled.div`
  display: flex;
  padding-left: 35px;
  padding-bottom: 35px;
  justify-content: flex-end;
`;

const AddCardContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  height: calc(100% + 21px);
  top: 0;
  box-shadow: -4px 1px 2px rgba(0, 0, 0, 0.1);
  padding-left: 25px;
  `;

const itemProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddinBottom: '100px'
};

const TAB_STATUS = {
  templates: "Templates",
  all_cards: "All Cards",
  form_templates: "Form Templates",
  template_tags: "Template Tags",
  channels: 'Channels'
}

// padding: 10px 13px;

const AllCards = ({
  organization_id,
  defaultTheme,
  tabStatus,
  refreshCategories,
  refreshDecks,
  refreshCards,
  refreshQuizQuestions
}) => {
  const [css, theme] = useStyletron();
  const [isChannelDropDownOpen, setChannelDropDownOpen] = useState(false);
  const [cards, setCards] = useState([]);
  const [cardTitle, setCardTitle] = useState('');
  const [cardSubTitle, setCardSubTitle] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [tab, setTab] = React.useState('templates');
  const [updated_at, set_updated_at] = useState(null);
  const [selectedModule, setSelectedModule] = React.useState();
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [importFile, setImportFile] = React.useState(null);
  const [importLoading, setImportLoading] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const [selectedTag, setSelectedTag] = React.useState();
  const params = useUrlParams();

  const currentComponentConfig = CardBuilderOptions.find((el) => el.__typename == "SharePointFormModule");

  const [addCard, { loading: addCardLoading, data: createdCard }] = useMutation(
    addCopyCard
  );

  const [createForm, { loading: createFormLoading, data: createdForm }] = useMutation(
    createSharePointFormModuleTemplate
  );

  const [fetchCopyCards, { data: copyCards, loading: copyCardsLoading, refetch: refetchCopyCards }] = useLazyQuery(gql`
    query copy_cards_by_org ($organization_id: ID!) {
      copy_cards_by_org(organization_id: $organization_id) {
        id
        title
        sub_title
        card_type
        file_data
        front_image_data
        archived
        full_bleed
      }
    }
  `,
    {
      variables: {
        organization_id
      },
      fetchPolicy: 'network-only'
    }
  );

  const [fetchFormTemplates, { data: formTemplates, loading: formTemplatesLoading, refetch: refetchFormTemplates }] = useLazyQuery(sharepoint_form_templates,
    {
      variables: {
        organization_id
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (tabStatus) {
      setTab(tabStatus)
    }
    fetchAllTemplates(tab)
  }, [])
  

  // useEffect(() => {
  // if (TAB_STATUS[tab] === TAB_STATUS.templates) {
  //   loadTags()
  // }
  // }, [tab])


  useEffect(() => {
    let fetchedCards = []
    if (copyCards?.copy_cards_by_org) {
      fetchedCards = copyCards?.copy_cards_by_org || []
    }
    setCards(fetchedCards.sort((a, b) => a.position - b.position))
  }, [copyCards])

  ////////////////////////////////////////////////////// Event Functions //////////////////////////////////////////////
  const onMenuClick = () => {
    setChannelDropDownOpen(true)
  }

  const closeChannelDropDown = () => {
    setChannelDropDownOpen(false)
  }

  const onClickAddCard = () => {
    if (TAB_STATUS[tab] == TAB_STATUS.all_cards) {
      setIsModalOpen(true);
    }
    // else if (TAB_STATUS[tab] == TAB_STATUS.templates) {
    //   setIsFormModalOpen(true);
    // }

  }

  const onClickCard = data => {
    const url = `/app/${params.orgId}/cards/card/${data.card_id}`
    localStorage.setItem('router_history', url);
    localStorage.setItem('card_id', data.card_id);
    navigate(url);
  };

  const onSaveInterNal = async () => {
    if (cardTitle != '') {
      let d = {
        title: cardTitle,
        sub_title: cardSubTitle || '',
        card_type: 'modular',
        front_image_data: null,
        link: null,
        back_image_data: null,
        back_description: null,
        youtube_video_id: null,
        file_data: null,
        archived: false,
        full_bleed: false,
        organization_id,
        created_by_id: '6e7bcba0-f194-4acf-b476-26d6594e88cb',
        has_feedback: false,
        card_favorite_count: 0,
        card_feedback_count: 0,
        created_by_name: '',
        position: cards.length,
        published: false,
        is_copy_card: true
      };
      await addCard({
        variables: {
          input: d
        },
      });
      onCloseModal();
      refetchCopyCards();
    }
  };

  const onSaveFormTemplate = async () => {
    try {
      const payload = {
        "title": cardTitle,
        "description": cardSubTitle,
        "dialog_open_button_text": "",
        "form_type": "single_form",
        organization_id,
        "position": 0,
        "schemaArr": [],
        "show_on_home_screen": true
      }
      await createForm({
        variables: payload
      })
      onCloseFormModal();
      refetchFormTemplates();
    } catch (err) {
      console.log('onSaveFormTemplate error', err)
    }
  }

  const onClickSave = () => {
    console.log('onClickSave');
    onSaveInterNal();
  }

  const onCloseFormModal = () => {
    setIsFormModalOpen(false);
    setCardTitle('');
    setCardSubTitle('');
  }

  const onCloseModal = () => {
    setIsModalOpen(false);
    setCardTitle('');
    setCardSubTitle('');
  }


  const fetchAllTemplates = (id) => {
    if (TAB_STATUS.all_cards == TAB_STATUS[id]) {
      fetchCopyCards()
    }
  }

  const onTabChange = params => {
    setTab(params.option.id)
    fetchAllTemplates(params.option.id)
  }

  const onClickImport = () => {
    setImportLoading(true);

    const formData = new FormData();
    formData.append('file', importFile);
    formData.append('organization_id', organization_id);

    let url = `${getServerUrl()}/sharepoint_forms_template_import`;

    if (!url) return;

    axios.post(url, formData)
      .then(async (result) => {
        console.log(result);
        setIsImportModalOpen(false);
        refetchFormTemplates();
      }).catch((err) => {
        console.log('error import', err)
      }).finally(() => {
        setImportLoading(false);
      })
  }

  const onCloseImportModal = () => {
    setIsImportModalOpen(false);
  }

  const onClickImportModal = () => {
    setIsImportModalOpen(true)
  }

  const loadTags = () => {
    client.query({
      query: template_tags_org(organization_id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {

      setTags([...data.template_tags_org].sort((a, b) => a.position - b.position));
    })
  };


  ////////////////////////////////////////////////////// Render Functions //////////////////////////////////////////////

  const renderHeader = () => {
    const addButtonTitle = TAB_STATUS.form_templates == TAB_STATUS[tab] ? 'Form' : 'Card';

    const SelectedTagValue = selectedTag && [{
      id: selectedTag?.id,
      name: selectedTag?.name
    }] || [];
    console.log('headerDescriptionupdated_at', updated_at);
    return (
      <Grid overrides={{
        Grid: {
          style: {
            padding: '15px 0px 40px 0px !important',
            width: '100%',
            flexWrap: 'wrap',
            // backgroundColor: 'green'
          }
        }
      }} gridMaxWidth={'unset'}>
        <div style={{ width: '100%', color: 'rgb(98, 70, 238)' }}>
          <RightSidePageHeader
            icon={null}
            onMenuClick={onMenuClick}
            title={TAB_STATUS[tab]}
            headerDescription={updated_at ? `Last updated at ${moment(updated_at).format('lll')}` : ''}
            rightContent={
              <div style={{ width: '250px', marginTop: '12px', marginRight: '12px', paddingBottom: '20px' }}>
                <Select
                  options={Object.keys(TAB_STATUS).map(el => {
                    return {
                      label: TAB_STATUS[el], id: el
                    }
                  })}
                  value={[{
                    label: tab, id: tab
                  }]}
                  placeholder="Select Setting"
                  onChange={onTabChange}
                  clearable={false}
                // size={'compact'}
                />
              </div>
            }
          ></RightSidePageHeader>

          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: '18px',
              paddingRight: '18px'
            }}
          >
            <div
              style={{
                width: '35%'
              }}
            >
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              {
                TAB_STATUS.all_cards === TAB_STATUS[tab] && (
                  <AddContentButton
                    buttonText={addButtonTitle}
                    helpText={`Click to add new ${addButtonTitle.toLowerCase()}`}
                    buttonClickOverride={onClickAddCard}

                  />
                )
              }
            </div>
          </div>
        </div>
      </Grid>
    )
  }

  const renderCard = (item, index, component_type, isCopyCard) => {
    let title = item.title;
    let imageSrc = getSrc(item.file_data || item.front_image_data);
    let card_id = item.id;
    let full_bleed = item.full_bleed;

    const onSettingsClick = () => {
      if (TAB_STATUS[tab] == TAB_STATUS.all_cards) {
        onClickCard({
          card_id
        })
      }
      // else if (TAB_STATUS[tab] == TAB_STATUS.templates) {
      //   setSelectedModule({
      //     content_module: item
      //   })
      //   setShowEditModal(true)
      // }
    }
    return (
      <div
        style={itemProps}
      >
        <Card
          key={`${card_id}_${index}`}
          title={title}
          imageSrc={imageSrc}
          toggle={false}
          isDeck={false}
          width={'148px'}
          isHomeComponent={true}
          componentType={component_type}
          home_component_app_content_scopes={[]}
          tabFilter="all"
          onSettingsClick={onSettingsClick}
          onCardClick={onSettingsClick}
          full_bleed={full_bleed}
          showPublishToggle={false}
          isCopyCard={isCopyCard || false}
          cardType={'single_card'}
        />
      </div>
    )
  }

  const renderEmptyContent = () => {
    const tabName = TAB_STATUS.form_templates == TAB_STATUS[tab] ? 'Form' : 'Card'
    return (
      <div
        style={{
          width: '100%',
          padding: '20px',
          textAlign: 'center'
        }}
      >
        <h1>{`No ${tabName} available`}</h1>
      </div>
    )
  }

  const renderCardsListSkeleton = () => {
    return (
      <div
        style={{
          paddingRight: '20px',
          paddingLeft: '20px',
          paddingBottom: '50px'
        }}
      >
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 300: 1, 500: 2, 750: 3, 900: 4, 1200: 5, 1500: 6, 1800: 7 }}
        >
          <Masonry
            gutter="1.5rem"
          >
            {
              Array.from({ length: 15 }).map(el => {
                return (
                  <div
                    style={itemProps}
                  >
                    <Skeleton
                      width="294px"
                      height="230px"
                      animation
                      overrides={{
                        Root: {
                          style: {
                            margin: '0.4rem',
                            borderRadius: '6%',
                          },
                        },
                      }}
                    />
                  </div>
                )
              })
            }
          </Masonry>
        </ResponsiveMasonry>
      </div>

    )
  }

  const CardsList = useMemo(() => {
    if (copyCardsLoading) {
      return renderCardsListSkeleton()
    } else if (!cards || cards.length <= 0) {
      return renderEmptyContent();
    }
    return (
      <div
        style={{
          paddingRight: '20px',
          paddingLeft: '20px',
          paddingBottom: '50px'
        }}
      >
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 300: 1, 500: 2, 750: 3, 900: 4, 1200: 5, 1500: 6, 1800: 7 }}
        >
          <Masonry
            gutter="1.5rem"
          >
            {
              cards?.map((el, idx) => renderCard(el, idx, "CopyCardModule", true))
            }
          </Masonry>
        </ResponsiveMasonry>
      </div>

    )
  }, [copyCardsLoading, cards])

  const FormTemplatesList = useMemo(() => {
    console.log('FormTemplatesList')
    if (formTemplatesLoading) {
      return renderCardsListSkeleton()
    } else if (!formTemplates?.sharepoint_form_templates || formTemplates?.sharepoint_form_templates?.length <= 0) {
      return renderEmptyContent();
    }
    return (
      <div
        style={{
          paddingRight: '20px',
          paddingLeft: '20px',
          paddingBottom: '50px'
        }}
      >
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 300: 1, 500: 2, 750: 3, 900: 4, 1200: 5, 1500: 6, 1800: 7 }}
        >
          <Masonry
            gutter="1.5rem"
          >
            {
              formTemplates?.sharepoint_form_templates?.filter(el => {
                if (!selectedTag?.id) {
                  return el;
                }
                const isSelected = el.tags?.find(el1 => el1.id === selectedTag?.id);
                if (isSelected) {
                  return el;
                }
              }).map((el, idx) => renderCard(el, idx, "SharePointFormModule", false)) || null
            }
          </Masonry>
        </ResponsiveMasonry>
      </div>

    )
  }, [formTemplates, formTemplatesLoading, selectedTag])

  const renderAddCardModal = () => {
    return (
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        onClose={onCloseModal}
        closeable
        isOpen={isModalOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>
          <div
            className={css({
              display: 'flex',
            })}
          >
            Add New Card
            <HelpQuestionMark text={card_help}></HelpQuestionMark>
          </div>
        </ModalHeader>
        <ModalBody>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
              <Input
                value={cardTitle}
                onChange={e => {
                  e.target.value.length <= titlesLength.card && setCardTitle(e.target.value.replace('\n', ''))
                }}
                placeholder="Enter card title here"
                clearOnEscape
              // onKeyDown={e =>() => {}
              //   saveModalOnEnterPress({ e, onEnterPress: onSaveInterNal })
              // }
              />
              <ParagraphSmall style={{ marginBottom: '0px' }}>{cardTitle.length}/{titlesLength.card}</ParagraphSmall>
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Subtitle</LabelMedium>
              <Textarea
                value={cardSubTitle}
                onChange={e =>
                  setCardSubTitle(e.target.value.replace('\n', ''))
                }
                placeholder="Enter card sub title here"
                clearOnEscape
              // onKeyDown={e => {}
              //   // saveModalOnEnterPress({ e, onEnterPress: onSaveInterNal })
              // }
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>

          <ModalButton
            isLoading={addCardLoading}
            onClick={onClickSave}
          >
            Save
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  const renderAddFormModal = () => {
    return (
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        onClose={onCloseFormModal}
        closeable
        isOpen={isFormModalOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>
          <div
            className={css({
              display: 'flex',
            })}
          >
            Add New Form
            <HelpQuestionMark text={card_help}></HelpQuestionMark>
          </div>
        </ModalHeader>
        <ModalBody>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
              <Input
                value={cardTitle}
                onChange={e => {
                  e.target.value.length <= titlesLength.card && setCardTitle(e.target.value.replace('\n', ''))
                }}
                placeholder="Enter form title"
                clearOnEscape
              // onKeyDown={e =>() => {}
              //   saveModalOnEnterPress({ e, onEnterPress: onSaveInterNal })
              // }
              />
              <ParagraphSmall style={{ marginBottom: '0px' }}>{cardTitle.length}/{titlesLength.card}</ParagraphSmall>
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Description</LabelMedium>
              <Textarea
                value={cardSubTitle}
                onChange={e =>
                  setCardSubTitle(e.target.value.replace('\n', ''))
                }
                placeholder="Enter form description"
                clearOnEscape
              // onKeyDown={e => {}
              //   // saveModalOnEnterPress({ e, onEnterPress: onSaveInterNal })
              // }
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>

          <ModalButton
            isLoading={createFormLoading}
            onClick={onSaveFormTemplate}
          >
            Save
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  const renderTabContent = () => {
    switch (TAB_STATUS[tab]) {
      case TAB_STATUS.all_cards: return CardsList;
      case TAB_STATUS.templates: return (
      <Templates
        organization_id={organization_id}
        refreshCategories={refreshCategories}
        refreshDecks={refreshDecks}
        refreshCards={refreshCards}
        refreshQuizQuestions={refreshQuizQuestions}
        updated_at={updated_at}
        set_updated_at={set_updated_at}
      />
      );
      case TAB_STATUS.form_templates: return (
        <SharepointFormTemplates
          organization_id={organization_id}
          defaultTheme={defaultTheme}
        />
      );
      case TAB_STATUS.template_tags: return (
        <TemplateTags
          organization_id={organization_id}
        />
      );
      case TAB_STATUS.channels: return (
        <Channels
          organization_id={organization_id}
        />
      );
      default: return CardsList
    }
  }

  const renderImportModal = () => {
    return (
      <Modal
        isOpen={isImportModalOpen}
        onClose={onCloseImportModal}
      >
        <ModalHeader>Import form templates</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          {importFile && <LabelMedium>File Selected {importFile.name}</LabelMedium>}
          <FileUploader
            onDrop={(acceptedFiles, rejectedFiles) => {
              setImportFile(acceptedFiles[0])

            }}
          />
        </ModalBody>
        <ModalFooter>
          <ModalButton
            isLoading={importLoading}
            disabled={!importFile}
            onClick={onClickImport}
          >
            {'Import'}
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  // console.log('TAB_STATUS[tab]', TAB_STATUS[tab], formTemplates, tab)

  return (
    <AllCardsContainer>
      <ChannelsDropDown
        organization_id={organization_id}
        defaultTheme={defaultTheme}
        onClose={closeChannelDropDown}
        isOpen={isChannelDropDownOpen}
      ></ChannelsDropDown>
      {renderHeader()}
      {/* {CardsList} */}
      {renderTabContent()}
      {renderAddCardModal()}
      {renderAddFormModal()}
      {
        showEditModal && (
          <FormBuilderHomeComponent
            organization_id={organization_id}
            refetchHomeComponents={refetchFormTemplates}
            selectedComponentType={currentComponentConfig}
            selectedModule={selectedModule}
            onClose={() => { setShowEditModal(false) }}
            updateMutation={updateSharePointFormModuleTemplate}
            isTemplate={true}
          ></FormBuilderHomeComponent>
        )
      }
      {renderImportModal()}
    </AllCardsContainer>
  )
}

AllCards.propTypes = {

}

AllCards.defaultProps = {

}

export default AllCards;