import { SET_CARDS, SET_CARD_BACK_DATA, UPDATE_CARD_BACK_DATA, SET_DECKS, SET_CREATED_CATEGORY, SET_CREATED_COMPONENT, SET_TOUR_POP_STATUS, SET_SHAREPOINT_FORM_SHEET, SET_SHAREPOINT_FORM_IMPORT, REMOVE_SHAREPOINT_FORM_IMPORT, SET_ORGANIZATION_TAGS, IMPORT_FORM_FIELD_ACTION, SET_ORG_PRODUCTS, SET_ORG_PRODUCT_ENGAGE_INTEGRATION, SET_ORG_PRODUCT_BREAKTHROUGH_INTEGRATION, SET_ORG_PRODUCT_DATA_COLLECTION_INTEGRATION, SET_ORG_PRODUCT_CRM_INTEGRATION } from '../actions/types';
import { toaster } from 'baseui/toast';
import { cloneDeep } from 'lodash'
const initialState = {
  searchText: '',
  searchPreviewData: [],
  isTourPopEnabled: false,
  formImportRequests: []
};

const checkIfCurrentChannelUpdated = (state, data) => {
  const {
    selectedChannel = {}
  } = state;
  if (selectedChannel.id === data?.id) {
    return ({ ...state, selectedChannel: data })
  }
  return state;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CARDS:
      return { ...state, cards: action.cards };
    case 'UPDATE_CARDS':
      return { ...state, cards: action.cards };
    case 'SET_QUESTION_CARDS':
      return { ...state, questionCards: action.data };
    case SET_CARD_BACK_DATA: {
      return { ...state, cardBackData: [...cloneDeep(action.data)] };
    }
    case UPDATE_CARD_BACK_DATA:
      return { ...state, cardBackData: [...action.data] };
    case SET_DECKS:
      return { ...state, decks: action.data };
    case 'UPDATE_DECK':
      return {
        ...state, decks: state.decks.map(item => {
          if (item.deck_id === action.data.deck_id) {
            return action.data
          }
          return item;
        })
      };
    case 'SET_PRIMARY_DATA_BY_ORG':
      return { ...state, primaryDataByOrg: action.data };
    case 'SET_CAT':
      return { ...state, category: action.data };
    case 'SET_GROUPS':
      return { ...state, groups: action.data };
    case 'SET_CHANNELS':
      return { ...state, channels: action.data, formImportRequests: [] };
    case 'SET_ORG':
      return { ...state, organization: action.data };
    case 'SET_ORG_SETTINGS':
      return { ...state, organization_settings: action.data };
    case 'SET_SELECTED_CHANNEL':
      return { ...state, selectedChannel: action.data };
    case 'CHECK_AND_UPDATE_SELECTED_CHANNEL':
      return checkIfCurrentChannelUpdated(state, action.data);
    case 'SET_SELECTED_GROUP':
      return { ...state, selectedGroup: action.data };
    case 'SET_BREADCRUMB':
      return { ...state, breadCrumb: action.data };
    case 'SET_LOADING':
      return { ...state, isLoading: action.data };
    case 'SET_USER':
      return { ...state, user: action.data };
    case 'SET_SEARCH_TEXT':
      return { ...state, searchText: action.data };
    case 'SET_SEARCH_PREVIEW':
      return { ...state, searchPreviewData: action.data };
    case 'SET_JWPLAYER_DATA':
      return { ...state, jwPlayerData: action.data };
    case 'SET_CLOUDINARY_DATA':
      return { ...state, cloudinarySettings: action.data };
    case 'SET_FILESTACK_DATA':
      return { ...state, filestackSettings: action.data };
    case 'SET_LIBRARY_DATA':
      return { ...state, libraries: action.data };
    case 'SHOW_ALERT':
      let d1 = action.data;
      d1.pushed_at = new Date().getTime();
      return { ...state, showAlert: d1 };
    case 'TOGGLE_THEME':
      localStorage.setItem(
        'cardware_admin_theme',
        state.theme === 'dark' ? 'light' : 'dark'
      );
      return { ...state, theme: state.theme === 'dark' ? 'light' : 'dark' };
    case SET_CREATED_CATEGORY:
      return { ...state, createdCategory: action.data };
    case SET_CREATED_COMPONENT:
      return { ...state, createdComponent: action.data };
    case SET_TOUR_POP_STATUS:
      return { ...state, isTourPopEnabled: action.data };
    case SET_SHAREPOINT_FORM_SHEET:
      console.log('SET_SHAREPOINT_FORM_SHEET', { organization: state.organization, data: action.data })
      const newState = {
        ...state,
        organization: {
          ...state.organization,
          microsoftSharePointSheets: action.data
        }
      }
      return newState;
    case SET_SHAREPOINT_FORM_IMPORT:
      // const updatedState = {
      //   ...state,
      //   formImportRequests: [
      //     ...state.formImportRequests || [],
      //     action.data
      //   ]
      // }
      const updatedState = {
        ...state,
        formImportRequests: [
          action.data
        ]
      }
      return updatedState;
    case REMOVE_SHAREPOINT_FORM_IMPORT:
      // const updatedImportReq = state.formImportRequests && state.formImportRequests.filter(el => el.request_id !== action.data.request_id)||[]
      // const updatedRequestState = {
      //   ...state,
      //   formImportRequests: [
      //     ...updatedImportReq
      //   ]
      // }

      const updatedImportReq = state.formImportRequests && state.formImportRequests.filter(el => el.request_id !== action.data.request_id) || []
      const updatedRequestState = {
        ...state,
        formImportRequests: [
        ]
      }
      return updatedRequestState
    case SET_ORGANIZATION_TAGS:
      return ({ ...state, organizationTags: action.data });
    case IMPORT_FORM_FIELD_ACTION:
      return ({ ...state, schemaDataArr: action.data });
    case SET_ORG_PRODUCTS:
      return ({ ...state, products: {
        ...(state?.products || {}),
        ...(action?.data || {})
      } })
    
    case SET_ORG_PRODUCT_BREAKTHROUGH_INTEGRATION:
      return ({ ...state, products: {
        ...(state?.products || {}),
        breakthrough_integration: action.data
      } })
    
    case SET_ORG_PRODUCT_ENGAGE_INTEGRATION:
      return ({ ...state, products: {
        ...(state?.products || {}),
        engage_integration: action.data
      } })

    case SET_ORG_PRODUCT_DATA_COLLECTION_INTEGRATION:
      return ({ ...state, products: {
        ...(state?.products || {}),
        data_collection_integration: action.data
      } })

    case SET_ORG_PRODUCT_CRM_INTEGRATION:
      return ({ ...state, products: {
        ...(state?.products || {}),
        crm_integration: action.data
      } })

    default:
      return state;
  }
}
