import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { LabelLarge, LabelMedium, ParagraphSmall } from 'baseui/typography';
import { useStyletron } from 'baseui';
import ReactPlayer from 'react-player';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import CardPickerCXL from '../modules/generic_modules/cardPickerCXL';
import { Select } from 'baseui/select';
import { client } from '../../apollo/client';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import ModalStateContainer from '../modalStateContainer';
import { getFilesUrls, showToaster } from '../../util'
import ImageUploadCXL from '../imageUploaderCXL';
import CustomFieldPicker from '../customFieldPicker';
import SearchableCheckList from '../modules/generic_modules/searchableCheckList';
import ColorPicker from '../common/ColorPicker';
import { SelectCXL } from '../builder/cardBackEditorComponents/select';
import ChannelPickerCXL from '../channelPickerCXL';
import ApiPathPicker from '../modules/generic_modules/apiPathPicker';
import Textarea from '../channel/textarea';
import { showAlert } from '../../redux/actions/appBasicControls';
import { Button } from 'baseui/button';
import { Textarea as BaseUiTextArea } from "baseui/textarea";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { useSelector } from "react-redux";
import VideoListComponent from '../common/VideoComponent'
import { toaster } from 'baseui/toast';
import store from '../../redux/store';
import { FormBuilderHomeComponent } from '../builder/formBuilder';
import FormBuilderComponent from '../builder/formBuilder';
import AdvanceTextAccordion from '../modules/generic_modules/advanceTextAccordion';
// import ActionMetadataInput from '../ActionMetadataInput';
import ActionButtonObject from '../modules/generic_modules/ActionMetadataSingleObject';
import DateTimeRangePicker from '../modules/generic_modules/dateTimeRangePicker';
import LocationModulePicker from '../modules/generic_modules/locationModulePicker';
import TagsPicker from '../modules/generic_modules/tagsPicker';
import { DatePicker } from "baseui/datepicker";
import { Accordion, Panel } from "baseui/accordion";
import StepsGamification from '../StepsGamification';
import ActionMetadataInput from '../modules/generic_modules/ActionMetadataInput';
import GenericModuleRenderer from '../modules/generic_module_renderer';

// selectedComponentType is the object from one of the card builder options
export default ({ refetchHomeComponents, selectedComponentType, selectedModule, updateMutation, onClose, jwPlayerData, libraries, dispatch, organization_id }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [css, theme] = useStyletron();
  const { display, inputs, __typename } = selectedComponentType;
  const [state, setStateInternal] = React.useState({});
  const [isVideoLibrariesSelected, setVideoLibrariesSelected] = React.useState(false)

  console.log('Selected Modules_____________', selectedModule)

  const [published, setPublished] = React.useState(selectedModule.published);
  const [selectedItem, setSelectedItem] = React.useState(selectedModule['content_module']['youtube_video_id'] || null)
  const [selectedVideoUrl, setSelectedVideo] = React.useState('')
  const [selectedTags, setSelectedTags] = React.useState('')

  const [data, setData] = React.useState([])

  const id = selectedModule.content_module.id;
  const archived = selectedModule.archived;

  const setState = (key, value) => {
    let data = { ...state };
    data[key] = value;
    setStateInternal(data);
  };

  const { is_card_link_button_css_on } = store.getState().appBasicControls.organization_settings || {};

  React.useEffect(() => {
    if (selectedModule && selectedModule.content_module) {
      for (let i in selectedModule.content_module) {
        setState(i, selectedModule.content_module[i])
      }
    }
  }, [selectedModule])

  const selector = useSelector((state) => {
    return {
      showAlert: state.appBasicControls.showAlert
    }
  });

  console.log('_____state', state);

  React.useEffect(() => {
    setData([]);
    // setState('youtube_video_id', '')
    if (!isLoading && (state['video_type'] || '').toLowerCase() == 'jwplayer') {
      getJWMediaList();
      if (state['youtube_video_id'] && state['youtube_video_id'].indexOf('http') == -1) {
        getJWVideoUrlById(state['youtube_video_id'])
      }
    }
    if ((state['video_type'] == 'library' || state['video_type'] == 'Library')) {
      if (libraries && libraries.libraries && libraries.libraries.length > 0) {
        console.log('Libraries: ', libraries)
        let nextOptions = [];
        libraries.libraries.map(item => {
          try {
            const videoExtensions = ["mp4", "3gp", "avi"]
            const url = item.library ? (JSON.parse(item.library)?.metadata?.url || null) : null;
            const extension = url ? url.split(".")[url.split(".").length - 1] : null

            if (item.type == 'video' && url && extension && videoExtensions.includes(extension.toLowerCase())) {
              nextOptions.push({
                id: item.id,
                label: item.title,
                library: item.library,
                description: item.description,
                url: url,
                duration: 0,
                tags: ''
              });
            }
          } catch (error) {
            console.log(error)
          }

        });
        setData(nextOptions)
      }
    };

    if (state['video_type'] == 'vimeo') {
      setState('youtube_video_id', `https://vimeo.com/${state.youtube_video_id}`);
    };
    if (state['video_type'] == 'youtube') {
      setState('youtube_video_id', `https://youtu.be/${state.youtube_video_id}`);
    };
  }, [state['video_type']])

  console.log('State_:::::,;m', state)
  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };


  const onSave = (req = {}, overrideState) => {
    const { archived = false } = req;
    setIsLoading(true);
    const mutation = updateMutation;
    let o = overrideState || state;
    Object.keys(o).forEach(el => {
      if (typeof o[el] === 'object') {
        if (Array.isArray(o[el])) {
          o[el] = o[el].map(el1 => {
            delete el1.__typename;
            return el1;
          })
        } else {
          console.log('')
          if (o && el && o[el] && o[el].__typename) {
            delete o[el].__typename;
          }

        }
      }
    });
    console.log(o);
    return client.mutate({
      mutation,
      variables: {
        id,
        published,
        archived,
        ...o,
      }
    }).then(async (result) => {
      dispatch(
        showAlert({
          msg: 'Successfully updated data!',
          error: false,
        })
      );
      await refetchHomeComponents();
      setStateInternal({});
      onClose();
    }).catch((err) => {
      console.log('Failed to save data', err)
      showToaster(err?.message, 'negative')
    }).finally(() => {
      setIsLoading(false);
      // if (selector.showAlert.error) {
      //   toaster.negative(selector.showAlert.msg || 'Error', {
      //     overrides: {
      //       InnerContainer: {
      //         style: { width: '100%' },
      //       },
      //     },
      //   });
      // } else {
      //   toaster.positive(selector.showAlert.msg || 'Success', {
      //        overrides: {
      //          InnerContainer: {
      //            style: { width: '100%' },
      //          },
      //        },
      //      });
      // }
      // setTimeout(() => {
      //   toaster.clear();
      // }, 3000)
    })
  };

  React.useEffect(() => {
    setStateInternal(inputs.map(i => i.key).reduce((a, b) => {
      console.log(b, selectedModule.content_module[b]);
      a[b] = b.value;
      if ((selectedModule.content_module[b] !== undefined) && (selectedModule.content_module[b] !== null)) {
        a[b] = selectedModule.content_module[b];
      }
      return a;
    }, {}))
  }, [inputs]);

  const getJWMediaList = async () => {
    if (jwPlayerData && jwPlayerData.jwplayers_org && jwPlayerData.jwplayers_org.length > 0) {
      const { app_key, app_secret } = jwPlayerData.jwplayers_org[0];
      const apiUrl = `https://api.jwplayer.com/v2/sites/${app_key}/media/?page_length=1000`;
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${app_secret}` },
      };
      setIsLoading(true)
      try {
        const apiResult = await fetch(apiUrl, requestOptions)
        const data = await apiResult.json()
        console.log('JW Player data: ', data)
        if (data && data.media) {
          let nextOptions = []
          for (let i = 0; i < data.media.length; i++) {
            const item = data.media[i]
            const apiUrl = "https://cdn.jwplayer.com/v2/media/" + item.id;
            const videoResult = await fetch(apiUrl)
            const videoData = await videoResult.json()
            const url = videoData.playlist[0].sources[1].file
            nextOptions.push({ id: item.id, label: item.metadata.title, description: item.metadata.description, url: url, duration: item.duration || null, tags: item.metadata.tags })
          }
          setData(nextOptions)
          setIsLoading(false);
        }
      } catch (e) {
        setData([]);
        setIsLoading(false)
      };
    }
  }

  const getJWVideoUrlById = async id => {
    const apiUrl = "https://cdn.jwplayer.com/v2/media/" + id;
    const videoResult = await fetch(apiUrl)
    const videoData = await videoResult.json()
    const url = videoData.playlist[0].sources[1].file
    if (url) {
      setSelectedVideo(url)
    }
    return url
  }

  const onSelectVideo = (selectedItem) => {
    const { id, url, tags } = selectedItem;
    setState('youtube_video_id', id)
    setState('jw_player_tags', id)
    setState('jw_player_stream_url', url)
    setSelectedVideo(url)
    setVideoLibrariesSelected(false)
  }

  const onCloseVideo = () => {
    setVideoLibrariesSelected(false)
  }

  return (
    <ModalStateContainer>
      {({ isConfirmationOpen, toggleConfirm }) => (
        <React.Fragment>
          {isVideoLibrariesSelected && data.length > 0 && (
            <VideoListComponent
              videoType={state['video_type']}
              data={data || []}
              onClose={onCloseVideo}
              onSelectVideo={onSelectVideo}
            />
          )}
          <Modal
            // animate={true}
            overrides={{
              Backdrop: {
                style: ({ $theme }) => ({
                  // backdropFilter: $theme.modalBackDropFilter,
                }),
              },
              Root: {
                style: {
                  // zIndex: 3,
                  // width: '90vw'
                }
              },
              Dialog: {
                style: { width: selectedComponentType.__typename == 'TableCardModule' ? '80vw' : '50vw' }
              }
            }}
            isOpen={true}
            returnFocus={false}
            onClose={onClose}
          >
            <ModalHeader>{ }</ModalHeader>
            <ModalBody style={{ flex: '1 1 0' }}>
              <Grid
                gridGaps={[12, 12, 24]}
                overrides={{
                  Grid: {
                    style: ({ $theme }) => outerGridOverride,
                  },
                }}
              >
                <div
                  className={css({
                    display: 'flex',
                  })}
                >
                  <LabelLarge style={{ marginBottom: '16px' }}>Edit {display}</LabelLarge>
                  {/* <HelpQuestionMark text={slide_help}></HelpQuestionMark> */}
                </div>
                {
                  inputs.map(el => {
                    
                    return (
                      <GenericModuleRenderer
                        input={el}
                        state={state}
                        theme={theme}
                        organization_id={organization_id}
                        onChange={(key, v) => {
                          setState(key, v);
                        }}
                        setStateInternal={setStateInternal}
                        root={selectedComponentType}
                        jwPlayerData={jwPlayerData}
                        libraries={libraries}
                        is_card_link_button_css_on={is_card_link_button_css_on}
                        __typename={__typename}


                        onSave={onSave} /// FormInputListDB prop
                        selectedComponentType={selectedComponentType} /// FormInputListDB prop
                        refetchHomeComponents={refetchHomeComponents} /// FormInputListDB prop
                        onClose={onClose} /// FormInputListDB prop
                        selectedModule={selectedModule} /// FormInputListDB prop
                        updateMutation={updateMutation} /// FormInputListDB prop
                        dispatch={dispatch} /// FormInputListDB prop

                        previewUrls={el.type == 'FilePicker' ? (el.mutation ? (state[el.mutation.key] ? getFilesUrls(state[el.mutation.key]) : getFilesUrls(state[el.key])) : getFilesUrls(state[el.key])) : []}
                      />
                    )
                  })
                }
                {/* {inputs.map((el) => {
                  const { title, placeholder, type, key, typeToPick, mutation, isVisible, visibilityParameter, extraStateKeyToUpdate, span } = el;
                  // if (type == 'VideoIdOrURLPicker' && (state['video_type'] == 'JWPlayer' || state['video_type'] == 'library' ||  state['video_type'] == 'Library')) {
                  //   !isVideoLibrariesSelected && setVideoLibrariesSelected(true)
                  // }

                  let values = [];
                  if (extraStateKeyToUpdate) {
                    values = extraStateKeyToUpdate.map(key => {
                      return state[key]
                    })
                  }

                  console.log(type);
                  if (isVisible) {
                    const visibilityVal = state[visibilityParameter]
                    console.log('visibilityVal', state)
                    if (isVisible(visibilityVal) === false) {
                      return null;
                    }
                  }

                  if ((key == 'style' || key == 'width' || key == 'icon') && !is_card_link_button_css_on && __typename == 'CardLinkButtonCardModule') return;
                  return <>
                    <Cell
                      span={[1, span || 12]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important',
                          }),
                        },
                      }}
                    >
                      <LabelMedium style={{ marginBottom: '16px' }}>{title}</LabelMedium>

                      {type == 'Input' && <Input
                        value={state[key]}
                        onChange={e => setState(key, e.target.value)}
                        placeholder={placeholder}
                        clearOnEscape
                      />}

                      {type == 'VideoIdOrURLPicker' && (state['video_type']?.toLowerCase() == 'jwplayer' || state['video_type']?.toLowerCase() == 'library') && (
                        <Button onClick={e => setVideoLibrariesSelected(true)}>Select video</Button>
                      )}
                      {(type == 'VideoIdOrURLPicker' && (state['video_type']?.toLowerCase() !== 'jwplayer' && (state['video_type']?.toLowerCase() !== 'library'))) && (
                        <Input
                          value={state[key]}
                          onChange={e => setState(key, e.target.value)}
                          placeholder={placeholder}
                          clearOnEscape
                        />
                      )}
                      {(state['youtube_video_id'] && type == 'VideoIdOrURLPicker' && selectedVideoUrl && (state['video_type']?.toLowerCase() == 'jwplayer' || state['video_type']?.toLowerCase() == 'library')) && (
                        <div style={{ width: '100%', margin: 'auto', paddingTop: '1rem' }}>
                          <ReactPlayer controls={true} url={selectedVideoUrl} width="100%" height="260px" />
                        </div>
                      )}

                      {type == 'Checkbox' &&
                        <Checkbox
                          checked={state[key]}
                          onChange={e => setState(key, e.target.checked)}
                        >
                          <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>{placeholder}</ParagraphSmall>
                        </Checkbox>
                      }

                      {type == 'CardPicker' && <CardPickerCXL
                        selectedCardId={state[key]}
                        onCardSelect={(id) => {
                          setState(key, id)
                        }}
                        title={''}
                        theme={theme}
                        marginTop={'0px'}
                        placeholder={'Select Card'}
                      />} */}
                {/* {console.log(mutation, key)}
                      {console.log(mutation ? (state[mutation.key] || state[key]) : state[key])}
                      {console.log(state)} */}
                {/* {type == 'FilePicker' && <ImageUploadCXL
                        title='Home component Image'
                        titleHelpText='Helper text'

                        onDrop={(acceptedFiles, rejectedFiles) => {
                          setState(
                            mutation ? mutation.key : key, acceptedFiles
                          );
                        }}
                        previewUrls={mutation ? (state[mutation.key] ? getFilesUrls(state[mutation.key]) : getFilesUrls(state[key])) : getFilesUrls(state[key])}
                      ></ImageUploadCXL>}

                      {type == 'Select' && <SelectCXL
                        {...el}
                        onChange={(v) => {
                          setState(key, v);
                        }}
                        theme={theme}
                        value={state[key]}
                        overrides={{
                          Popover: {
                            props: {
                              overrides: {
                                Body: { style: { zIndex: 3 } },
                              },
                            },
                          },
                        }}
                      ></SelectCXL>}

                      {type == 'TableColumnPicker' && <SearchableCheckList
                        google_sheet_integration_id={state['google_sheet_integration_id']}
                        onChange={(value) => {
                          setState(key, JSON.stringify(value));
                        }}
                        typeToPick={typeToPick}
                        value={state[key] == '' ? [] : typeof state[key] == 'string' ? JSON.parse(state[key]) : state[key]}
                      ></SearchableCheckList>}

                      {type == 'CustomFieldPicker' && <CustomFieldPicker
                        selectCustomFieldId={state[key]}
                        onFieldSelect={(id) => {
                          setState(key, id)
                        }}
                        placeholder="Select custom field"
                      ></CustomFieldPicker>}

                      {type == 'ChannelPicker' &&
                        <ChannelPickerCXL
                          selectedChannelId={state[key]}
                          onChannelSelect={(id) => {
                            setState(key, id)
                          }}
                          title={''}
                          theme={theme}
                          marginTop={'0px'}
                          placeholder={'Select Channel'}
                        />}

                      {
                        type == 'ApiPathPicker' && <ApiPathPicker
                          selectedApiId={state[key]}
                          onChange={(id) => {
                            setState(key, id)
                          }}
                          title={''}
                          theme={theme}
                          marginTop={'0px'}
                          placeholder={placeholder}
                          organization_id={organization_id}
                        />
                      }

                      {type == 'Textarea' && <Textarea
                        value={state[key]}
                        onChange={(value) => {
                          setState(key, value)
                        }}
                        textAreaKey={key}
                      >
                      </Textarea>}

                      {type == 'BaseUiTextArea' &&
                        // <BaseUiTextArea
                        //   value={state[key]}
                        //   onChange={e => {
                        //     //this.setState({ value: e.target.value })
                        //     setState(key, e.target.value)
                        //   }}
                        //   // placeholder={this.props.placeholder}
                        //   clearOnEscape
                        // />
                        <Accordion
                          accordion
                          overrides={{
                            Root: {
                              style: ({ $theme }) => ({
                                marginLeft: 0,
                              })
                            }
                          }}
                        >
                          <Panel title={placeholder}>
                            <JSONInput
                              id='a_unique_id'
                              placeholder={state[key] ? JSON.parse(state[key]) : {}}
                              theme="dark_vscode_tribute"
                              colors={{
                                string: "#DAA520" // overrides theme colors with whatever color value you want
                              }}
                              locale={locale}
                              height='550px'
                              width={'40vw'}
                              onChange={e => {
                                // console.log(e);
                                !e.error && setState(key, JSON.stringify(e.jsObject));
                              }}
                            />
                          </Panel>
                        </Accordion>
                      } */}
                {/* 
                      {type == 'JSON' &&
                        // <BaseUiTextArea
                        //   value={state[key]}
                        //   onChange={e => {
                        //     //this.setState({ value: e.target.value })
                        //     setState(key, e.target.value)
                        //   }}
                        //   // placeholder={this.props.placeholder}
                        //   clearOnEscape
                        // />
                        <JSONInput
                          id='a_unique_id'
                          placeholder={state[key] ? (state[key]) : {}}
                          theme="dark_vscode_tribute"
                          colors={{
                            string: "#DAA520" // overrides theme colors with whatever color value you want
                          }}
                          locale={locale}
                          height='550px'
                          width={'40vw'}
                          onChange={e => {
                            console.log(e);
                            if (!e.error) {
                              let obj = e.jsObject;
                              if (Array.isArray(obj)) {
                                obj = obj.map(el => {
                                  delete el.__typename;
                                  return el;
                                })
                              } else
                                delete obj.__typename
                              setState(key, (obj))
                            }
                          }}
                        />
                      }

                      {
                        type == 'AdvanceTextAccordion' && <AdvanceTextAccordion
                          values={state[key]}
                          onChange={(v) => {
                            setState(key, v);
                          }}
                        />
                      }

{
                    type == 'StepsGamification' && <StepsGamification
                      values={state[key]}
                      onChange={(v) => {
                        setState(key, v);
                      }}
                    />
                  }

                      {
                        type == 'ActionMetadataInput' && <ActionMetadataInput
                          action_type={state['button_action_type']}
                          action_resource={state['button_action_metadata']}
                          onChange={(v) => {
                            setState(key, v);
                          }}
                          theme={theme}
                        />
                      }

                      {
                        type == 'ActionButtonObject' && <ActionButtonObject
                          action={state[key]}
                          onChange={(v) => {
                            setState(key, v);
                          }}
                          theme={theme}
                        />
                      }

                      {type == 'ColorPicker' && (
                        <ColorPicker color={state[key]} onChange={color => setState(key, color)} />
                      )}


                      {type == 'FormInputList' && (
                        <FormBuilderHomeComponent
                          // app_content_scope_id={appContentScopeId}
                          // organization_id={organization_id}
                          refetchHomeComponents={refetchHomeComponents}
                          selectedComponentType={selectedComponentType}
                          selectedModule={selectedModule}
                          // home_component_id={null}}
                          onClose={onClose}
                          // overrideSave={(values) => {
                          //   console.log(values);
                          // }}
                          updateMutation={updateMutation}
                          dispatch={dispatch}
                        />
                      )} */}
                {/* {type == 'FormInputListDB' && (
                        <FormBuilderComponent
                          // app_content_scope_id={appContentScopeId}
                          // organization_id={organization_id}
                          refetchHomeComponents={refetchHomeComponents}
                          selectedComponentType={selectedComponentType}
                          selectedModule={selectedModule}
                          // home_component_id={null}}
                          onClose={onClose}
                          // overrideSave={(values) => {
                          //   console.log(values);
                          // }}
                          onSave={(d) => {
                            return new Promise((resolve, reject) => {
                              console.log(d);
                              setStateInternal(d);
                              onSave({}, d).then(() => resolve(d));
                            })
                          }}
                          updateMutation={updateMutation}
                          dispatch={dispatch}
                        />
                      )}

                      {
                        type == 'DateTimeRange' && (
                          <DateTimeRangePicker
                            values={values}
                            onChange={(res) => {
                              console.log('date change', res, key, extraStateKeyToUpdate);
                              const [d, s, e] = res;
                              console.log(d, s, e);
                              let data = { ...state };
                              data[key] = d;
                              data[extraStateKeyToUpdate[0]] = s;
                              data[extraStateKeyToUpdate[1]] = e;
                              setStateInternal(data);
                            }}
                          />
                        )
                      }

                      {type == 'LocationModulePicker' && (
                        <LocationModulePicker
                          ids={state[key]}
                          onChange={(v) => {
                            setState(key, v)
                          }}
                        />
                      )}

                      {type == 'TagsPicker' && (
                        <TagsPicker
                          ids={state[key]}
                          onChange={(v) => {
                            setState(key, v)
                          }}
                        />
                      )}

                      {type == 'Date' && (
                        <DatePicker
                          value={[state[key] ? new Date(state[key]) : new Date()]}
                          onChange={({ date }) =>
                            setState(key, date[0])
                          }
                          timeSelectStart
                          formatString={'yyyy-mm-dd HH:mm'}
                        />
                      )}
                    </Cell>
                  </>
                })} */}
                {/* <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Accessibility Control</LabelMedium>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginLeft: '16px' }}>
                      <Checkbox
                        checked={published}
                        onChange={e => setPublished(!published)}
                        labelPlacement={LABEL_PLACEMENT.right}
                      >
                        <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Published</ParagraphSmall>
                      </Checkbox>
                    </div>
                  </div>
                </Cell> */}
              </Grid>
            </ModalBody>
            <ModalFooter>
              <ModalButton
                onClick={() => {
                  toggleConfirm(true);
                }}
              >
                {archived ? 'Unarchive' : 'Archive'}
              </ModalButton>
              <ModalButton
                isLoading={isLoading}
                onClick={() => {
                  onSave();
                }}
              >
                Update
              </ModalButton>
            </ModalFooter>
          </Modal>



          <Modal
            onClose={() => toggleConfirm(false)}
            isOpen={isConfirmationOpen}
            overrides={{
              Root: {
                style: {
                  zIndex: 4,
                }
              }
            }}
            returnFocus={false}
          >
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody>Are you sure you want to {archived ? 'un archive' : 'archive'} this module?</ModalBody>
            <ModalFooter>
              <ModalButton
                kind="tertiary"
                onClick={() => toggleConfirm(false)}
              >
                No
              </ModalButton>
              <ModalButton
                onClick={() => {
                  onSave({ archived: !archived })
                }}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Yes
              </ModalButton>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      )}
    </ModalStateContainer>
  );
};


