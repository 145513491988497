import React from 'react';
import ReactPlayer from 'react-player';
import { isValidHttpUrl } from '../../builder/builderUtils/util'
import CustomInput from '../../common/input';
import VideoListComponent from '../../common/VideoComponent'
import { Button } from 'baseui/button';

class InputComponent extends React.Component {
    constructor(props) {
        super(props);
        // let inputs = this.props.root.inputs ? this.props.root.inputs : [];
        // const valueObj = inputs.find(ele => ele.type === "VideoIdOrURLPicker");
        // const val = valueObj && valueObj.value || '';
        // console.log('VideoIdOrURLPicker obj', valueObj)
        // let optionsList = inputs.find(el => el.key == 'video_type');
        // optionsList = optionsList && optionsList.options || [];
        this.state = {
            value: '',
            options: [],
            isLoading: false,
            isVideoSelected: false,
            selectedVideoUrl: this.props.value,
            isValidUrl: true
        };

    }

    isValid = () => {
        // if (this.state.value && this.props.placeholder && (this.props.placeholder.toLowerCase().includes('url') || this.props.placeholder.toLowerCase().includes('link'))) {
        //     return isValidHttpUrl(this.state.value);
        // };
        if (this.state.selectedVideoUrl && this.props.placeholder && (this.props.placeholder.toLowerCase().includes('url') || this.props.placeholder.toLowerCase().includes('link'))) {
            this.setState({
                isValidUrl: isValidHttpUrl(this.state.selectedVideoUrl)
            })
            return isValidHttpUrl(this.state.value);
        };
        // this.setState({
        //     isValidUrl: true
        // })
        return true;
    }

    componentDidMount() {
        const val = this.props.value;
        this.setState({
            value: val
        }, () => {
            this.getMediaList();
            this.setSelectedVideoUrl();
        });

    }


    getMediaList() {
        const jwPlayerData = this.props.jwPlayerData;
        if (jwPlayerData && jwPlayerData.jwplayers_org && jwPlayerData.jwplayers_org.length > 0) {
            const { app_key, app_secret } = jwPlayerData.jwplayers_org[0];
            const apiUrl = `https://api.jwplayer.com/v2/sites/${app_key}/media?page_length=1000`;
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${app_secret}` },
            };
            this.setState({ isLoading: true });
            fetch(apiUrl, requestOptions)
                .then(response => response.json())
                .then(async data => {
                    if (data && data.media) {
                        const playerData = data.media.filter(i => i.status == 'ready')
                        let nextOptions = []
                        for (let i = 0; i < playerData.length; i++) {
                            const item = playerData[i]
                            //   const apiUrl = "https://cdn.jwplayer.com/v2/media/"+item.id;
                            //   const videoResult = await fetch(apiUrl)
                            //   const videoData = await videoResult.json()
                            const url = '';
                            nextOptions.push({ id: item.id, label: item.metadata.title, description: item.metadata.description, url: url, duration: item.duration || null, tags: item.metadata.tags })
                        }

                        this.setState({
                            options: nextOptions,
                            isLoading: false
                        });

                    }
                })
                .catch(e => {
                    this.setState({ options: [], isLoading: false });
                })
        }
    }

    getLibraryList() {
        const { libraries } = this.props;
        if (libraries && libraries.libraries && libraries.libraries.length > 0) {
            let nextOptions = [];
            libraries.libraries.map(item => {
                try {
                    const videoExtensions = ["mp4", "3gp", "avi"]
                    const temp = item.library ? JSON.parse(item.library).metadata.url : null;
                    const extension = temp ? temp.split(".")[temp.split(".").length - 1] : null

                    if (item.type == 'video' && temp && extension && videoExtensions.includes(extension.toLowerCase())) {
                        nextOptions.push({ id: item.id, label: item.title, library: item.library, description: item.description, duration: 0 });
                    }
                } catch (error) {
                    console.log(error)
                }

            });
            this.setState({ options: nextOptions });
        }
    }

    async setSelectedVideoUrl() {
        if (!this.state.value) return;
        const apiUrl = "https://cdn.jwplayer.com/v2/media/" + this.state.value;
        const videoResult = await fetch(apiUrl);
        const videoData = await videoResult.json();
        const url = videoData.playlist && videoData.playlist[0].sources[1].file || '';
        this.setState({
            selectedVideoUrl: url
        }, () => {
            this.isValid();
        });
    }

    setVideoData = (selectedItem) => {
        const { id, url, tags } = selectedItem
        this.setState({ value: id, selectedVideoUrl: url, isVideoSelected: false });
        this.props.onChange(id);
    }

    render() {
        let error = !this.state.isValidUrl;
        let pickerTypeOp = this.props.root.inputs ? this.props.root.inputs.find(el => el.key == 'video_type') : null;
        let pickerType = pickerTypeOp ? pickerTypeOp.value : 'default';
        // if ((this.state.options.length == 0 || (this.state.options.length > 0 && this.state.options[0].library)) && pickerType == 'JWPlayer' ) this.getMediaList()
        if ((pickerType == 'Library' || pickerType == 'library') && (this.state.options.length == 0 || (this.state.options.length > 0 && !this.state.options[0].library))) this.getLibraryList()
        if (pickerType == 'JWPlayer' || pickerType == 'Library' || pickerType == 'library') {
            error = false;
        }
        // console.log('VideoIdOrUrlPicker video type', pickerType, this.state, this.props.placeholder);
        return (
            <>
                <h5
                    style={{
                        color: this.props.theme.colors.primaryA,
                        marginTop: this.props.theme.sizing.scale1200,
                        fontFamily: 'IBM Plex Sans',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '16px'
                    }}
                >
                    {this.props.title || ''}
                </h5>
                {this.state.isVideoSelected && (
                    <VideoListComponent
                        data={this.state.options || []}
                        onClose={() => this.setState({ isVideoSelected: false })}
                        onSelectVideo={this.setVideoData}
                    />
                )}
                <CustomInput
                    value={this.state.value}
                    onChange={e => {
                        console.log(e.target.value);
                        this.setState({ value: e.target.value })
                        this.props.onChange(e.target.value);
                    }}
                    placeholder={this.props.placeholder}
                    clearOnEscape
                    error={error}
                    maxLength={this.props.maxLength}
                />
                {(pickerType == 'JWPlayer' || pickerType == 'Library' || pickerType == 'library' || pickerType == 'jwplayer') && (
                    <Button onClick={e => {
                        debugger
                        this.setState({ isVideoSelected: true })
                    }} style={{ marginTop: '1rem' }}>Select video</Button>
                )}
                {(pickerType == 'JWPlayer' || pickerType == 'Library' || pickerType == 'library' || pickerType == 'jwplayer') && this.state.value && (
                    <div style={{ paddingTop: '1rem' }}>
                        <ReactPlayer controls={true} url={this.state.selectedVideoUrl} width="100%" height="260px" />
                    </div>
                )}

                {this.state.isValidUrl === false && (
                    <p style={{ color: 'red', fontSize: '0.6rem', margin: 0, paddingTop: '0.6rem' }}>Invalid web URL</p>
                )}
            </>
        );
    }
}

export default InputComponent;