import * as React from 'react';
import { Input, StyledInput } from 'baseui/input';
import { Tag, VARIANT as TAG_VARIANT } from 'baseui/tag';
import {
  HeadingMedium,
  ParagraphSmall,
  HeadingSmall,
  LabelXSmall,
  LabelSmall,
  LabelMedium
} from 'baseui/typography';
import { useStyletron } from 'baseui';
import PropTypes from 'prop-types';
import { StatefulPopover } from 'baseui/popover';

const InputReplacement = React.forwardRef(
  ({ tags, removeTag, onTagClick, showPopOver, popOverContent, ...restProps }, ref) => {
    const [css] = useStyletron();
    
    return (
      <div
        className={css({
          flex: '1 1 0%',
          flexWrap: 'wrap',
          display: 'flex',
          alignItems: 'center',
        })}
      >
        {tags.map((tag, index) => {
          const popOverContentElement = showPopOver ? () =>  popOverContent(tag) : null;
          return (
            <StatefulPopover
              overrides={{
                Body: { style: { zIndex: 14 } }
              }}
              content={popOverContentElement}
              returnFocus
              autoFocus
            >
              <Tag
                variant={TAG_VARIANT.solid}
                onActionClick={() => removeTag(tag)}
                key={index}
              >
                {tag}
              </Tag>
            </StatefulPopover>
          )
        }
        )}
        <StyledInput ref={ref} {...restProps} />
      </div>
    );
  }
);

const TagsInput = (props) => {

  const {
    title,
    placeholder,
    inputTip,
    onChangeOption
  } = props || {};

  const [tags, setTags] = React.useState(props.tags || []);
  const [tagValue, setTagValue] = React.useState('');

  const addTag = tag => {
    tag = `${tag}`;
    setTags([...tags, tag]);
    onChangeOption([...tags, tag]);
  };

  const removeTag = tag => {
    setTags(tags.filter(t => t !== tag));
  };

  const handleKeyDown = event => {
    switch (event.keyCode) {
      // Enter
      case 13: {
        if (!tagValue) return;
        addTag(tagValue);
        setTagValue('');
        return;
      }
      // Backspace
      case 8: {
        if (tagValue || !tags.length) return;
        removeTag(tags[tags.length - 1]);
        return;
      }
    }
  };

  console.log('tags ------>', tags);
  console.log('tags values------>', tagValue);

  return (
    <>
      <LabelSmall
        style={{
          marginTop: '16px',
          marginBottom: '8px',
        }}
      >
        {title}
      </LabelSmall>
      <Input
        placeholder={tags.length ? '' : placeholder}
        value={tagValue}
        onChange={e => setTagValue(e.currentTarget.value)}
        overrides={{
          Input: {
            style: { width: 'auto', flexGrow: 1 },
            component: InputReplacement,
            props: {
              tags: tags,
              removeTag: removeTag,
              onKeyDown: handleKeyDown,
              ...props
            },
          },
        }}
      ></Input>
      <ParagraphSmall style={{ marginBottom: '0px' }}>{inputTip}</ParagraphSmall>
    </>
  )
}

TagsInput.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  inputTip: PropTypes.string,
  onChangeOption: PropTypes.func,
  tags: PropTypes.array,
}

TagsInput.defaultProps = {
  title: 'Tags',
  placeholder: 'Enter a Tag and hit Enter',
  inputTip: 'Type name of your tag and hit "Enter" to select it',
  onChangeOption: () => { },
  tags: []
}

export default TagsInput;