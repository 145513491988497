import React from 'react';
import BaseClass from '../../builder/cardBackEditorComponents/base';

import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

class TextAreaEditor extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      // editorState: EditorState.createEmpty(),
    };
    // this.onEditorStateChange = this.onEditorStateChange.bind(this);
    // this.onChange = this.onChange.bind(this);
  }

  // onEditorStateChange = (editorState) => {
  //   this.setState({
  //     editorState,
  //     value: draftToHtml(convertToRaw(editorState.getCurrentContent()))
  //   });
  //
  // };

  onChange = html => {
    this.setState({
      value: html,
    });
  };

  componentDidMount() {
    // const Editor = require('react-draft-wysiwyg');
    // this.setState({
    //   Editor: Editor.Editor,
    //   editorState: EditorState.createWithContent(
    //     ContentState.createFromBlockArray(
    //       convertFromHTML(this.props.root.inputs[this.props.position].value)
    //     )
    //   ),
    // })
    this.setState({
      value: this.props.root.inputs[this.props.position].value,
    });

    if (window.Redactor) {
      window.Redactor(`.redactor-content${this.props.root.dId}`, {
        focus: true,
        value: this.props.root.inputs[this.props.position].value,
        callbacks: {
          changed: html => {
            console.log(html);
            this.onChange(html);
          },
        },
      });
      if (this.props.root.inputs[this.props.position].value != '')
        window.Redactor(
          `.redactor-content${this.props.root.dId}`,
          'source.setCode',
          this.props.root.inputs[this.props.position].value
        );
    }
  }

  render() {
    // const { editorState } = this.state;
    // if (!this.state.Editor) return false;
    // return <this.state.Editor
    //   editorState={editorState}
    //   wrapperClassName="demo-wrapper"
    //   editorClassName="demo-editor"
    //   onEditorStateChange={this.onEditorStateChange}
    // />
    const { value } = this.state;
    const {hasDataSource, dataPoints} = this.props;
    console.log(hasDataSource, dataPoints, this.props);
    return (
      <>
       {/* <h5
          style={{
            color: this.props.theme.colors.primaryA,
            marginTop: this.props.theme.sizing.scale1200,
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px'
          }}
        >
          {this.props.title || ''}
        </h5> */}
        <textarea
          name={'textarea'}
          placeholder={this.props.placeholder}
          className={`redactor-content` + this.props.root.dId}
          value={value}
        ></textarea>
      </>
    );
  }
}

export default TextAreaEditor;
