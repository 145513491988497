import React from "react"
import JSONInput from "react-json-editor-ajrm";
import locale from 'react-json-editor-ajrm/locale/en';

const JSONInputCXL = ({
  value,
  onChange = () => { }
}) => {

  const onChangeValue = e => {
    console.log(e);
    if (!e.error) {
      let obj = e.jsObject;
      if (Array.isArray(obj)) {
        obj = obj.map(el => {
          delete el.__typename;
          return el;
        })
      } else
        delete obj.__typename

      onChange((obj))
    }
  }

  return (
    <div
      className="JSONInputCXL"
      style={{
        height: '550px',
        width: '40vw',
      }}
    >
      <JSONInput
        id='a_unique_id'
        placeholder={value ? (value) : {}}
        theme="dark_vscode_tribute"
        colors={{
          string: "#DAA520" // overrides theme colors with whatever color value you want
        }}
        locale={locale}
        height='550px'
        width={'40vw'}
        onChange={onChangeValue}
      />
    </div>
  )
}

export default JSONInputCXL