import * as React from 'react';
import { Grid } from 'baseui/layout-grid';
import { LabelMedium } from 'baseui/typography';
import { FaRegCopy } from 'react-icons/fa'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
} from 'baseui/modal';

const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
};

export default (props) => {
    const { url, name, isOpen, onClose } = props;
    const onCopyText = (url) => {
        navigator.clipboard.writeText(url)
    };
    return (
        <Modal
            overrides={{
                Backdrop: { style: ({ $theme }) => ({ backdropFilter: $theme.modalBackDropFilter})},
                Root: {style: { zIndex: 3}}
            }}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalHeader>File uploaded successfully</ModalHeader>
            <ModalBody style={{ flex: '1 1 1' }}>
            <Grid
                gridGaps={[12, 12, 24]}
                overrides={{Grid: { style: ({ $theme }) => outerGridOverride}}}
            >
                <div style={{ width: '100%'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {url && <img src={url} style={{width: '100%', objectFit: 'fill'}} />}
                    </div>
                    <div>
                        <div>
                            <LabelMedium style={{ marginBottom: '0.5rem' }}>File Name</LabelMedium>
                            <p>{name || 'Library'}</p>
                        </div>
                        <div>
                            <LabelMedium style={{ marginBottom: '0.5rem' }}>File URL</LabelMedium>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <p style={{ maring: 0}}>{url}</p>
                                <FaRegCopy style={{ cursor: 'pointer'}} fontSize={16}  onClick={()=>onCopyText(url)} />
                            </div>
                        </div>
                    </div>
                </div>
            
            </Grid>
            </ModalBody>
            <ModalFooter>
                <ModalButton onClick={onClose}>Cancel</ModalButton>
                <ModalButton><a href={url} style={{color: 'white', textDecoration: 'none'}} target="_blank">Download</a></ModalButton>
            </ModalFooter>
        </Modal>
    )
}