import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { Radio, RadioGroup } from 'baseui/radio';
import { useStyletron } from 'baseui';
import Button from '../common/button';
import { Select, SIZE } from "baseui/select";
import {Skeleton} from 'baseui/skeleton'
import AddUser from './addUser';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import SearchUser from './searchUser';
import UsersList from './userList';
import { client } from '../../apollo/client';
import styled from '@emotion/styled';
import ExportUser from './exportUser';
import { HeadingMedium, HeadingSmall, HeadingXLarge, HeadingXSmall, HeadingXXLarge, LabelMedium, LabelSmall, LabelXSmall } from 'baseui/typography';
import { debounce } from 'lodash';
import svgIcons from '../../svg';
import { statsQuery, getUsersQuery } from './queries_v1';
import InviteUsers from './addUsersSidebar';
import { createBulkUser } from '../../quries';
import { getServerUrl } from '../../apollo/client';
import HelpQuestionMark from '../help/questionMarkHelp';
import ImportUsers from './importUsers';

const USER_CONVERTED_OPTION = [
  {
    name: 'Cardware Converted User',
    key: 'user_converted',
    dataQuery: { is_converted_to_cardware: true }
  },
  {
    name: 'Not Cardware Converted User',
    key: 'user_not_converted',
    dataQuery: { is_converted_to_cardware: false }
  },
  {
    name: 'All',
    key: 'both',
    dataQuery: {}
  }
];
const selectOptions = [
  {
    name: 'All Users',
    key: 'all',
    dataQuery: {}
  },
  {
    name: 'Pending Users',
    key: 'pending',
    dataQuery: { is_converted_to_cardware: true }
  },
  {
    name: 'Archived Users',
    key: 'archived',
    dataQuery: { is_converted_to_cardware: false }
  },
  
];

const IMPORT_EXPORT_ACTIONS = {
  "export_user": "Export",
  "import_user": "Import"
}


var queryDataDebounced;

const RadioBtnContainer = styled.div`
  width: 300px;
  margin-right: 13px; 
`;

const Admin = ({
  tabFilter,
  channels,
  organization_id,
  type,
  defaultTheme,
  listMenuOptions,
  addUserMutation,
  updateUserMutation,
  columns,
  showFilter,
  setTab,
  _t, showToaster, isTrainer
}) => {
  const [isCreateUserWithChannelLoading, setIsCreateUserWithChannelLoading] = React.useState(false);
  const [errorInUserCreate, setErrorInUserCreate] = React.useState(null);

  const [isCreateBulkUsersLoading, setIsCreateBulkUsersLoading] = React.useState(false);

  const [errorInUserUpdate, setErrorInUserUpdate] = React.useState(null);
  const [isUpdateUserWithChannelLoading, setIsUpdateUserWithChannelLoading] = React.useState(false);

  const [updatedUser, setUpdatedUser] = React.useState(null);
  const [userConvSelected, setSelectedUserConv] = React.useState([USER_CONVERTED_OPTION[2]]);

  const [css, theme] = useStyletron();

  const [users, setUsers] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPage] = React.useState(1);
  const [stats, setStats] = React.useState({});
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [isNoResult, setIsNoResult] = React.useState(false);
  const queryType = (type || '').replace(/ /g, '').includes('appuser') ? 'AppUser' : 'Admin';
  const PAGE_LIMIT = 10;
  const [showInviteUser, setShowInviteUser] = React.useState(false);
  const [showImportUser, setShowImportUser] = React.useState(false);


  const [importExportAction, setImportExportAction] = React.useState('');
  const importExportActionArr = Object.keys(IMPORT_EXPORT_ACTIONS).map(el => {
    return ({
      id: el,
      label: `${IMPORT_EXPORT_ACTIONS[el]} ${type}${type[type.length - 1] != 's' ? 's' : ''}`
    })
  })

 
  const queryData = (page, tabFilter, searchText, userConvSelected) => {
    setIsDataLoading(true);
    // setUsers([]);
    const pagination = {
      skip: (page - 1) * PAGE_LIMIT,
      limit : PAGE_LIMIT
    };
    let filter = {};
    if (tabFilter == 'pending') filter['is_pending'] = true;
    if (tabFilter == 'archived') filter['is_archived'] = true;

    if (searchText) filter['search_text'] = searchText;

    if (isTrainer) {
      filter['is_trainer'] = true;
    };
    // console.log(userConvSelected);
    // if (userConvSelected) {
    //   userConvSelected.forEach(c => {
    //     filter = {
    //       ...filter,
    //       ...c.dataQuery
    //     }
    //   });
    // };

    client.query({
      query: getUsersQuery(organization_id, queryType),
      variables: {
        pagination, filter
      },
      fetchPolicy: 'network-only'
    }).then(result => {
      let {count, rows} = result.data.users_v1;
      setTotalPage(Math.ceil(count / PAGE_LIMIT));
      setUsers(rows);
      if (count == 0) setIsNoResult(true) 
      else setIsNoResult(false)
      setTimeout(() => {
        setIsDataLoading(false);
      }, 500)
    }).catch(() => {
      setTimeout(() => {
        setIsDataLoading(false);
      }, 300)
    });
  };

  React.useEffect(() => {
    if (queryDataDebounced) {
      queryDataDebounced = null;
    };
    queryDataDebounced = debounce(queryData, 1000);
  }, []);

  React.useEffect(() => {
    queryData(page, tabFilter, searchText, userConvSelected);
  }, [page]);

  React.useEffect(() => {
    setPage(1);
    setSearchText('');
    setSelectedUserConv([USER_CONVERTED_OPTION[2]]);
    setUsers([]);
    queryData(page, tabFilter, searchText, userConvSelected);
  }, [tabFilter])

  // React.useEffect(() => {
  //   setPage(1);
  //   setSearchText('');
  //   queryData(page, tabFilter, searchText, userConvSelected);
  // }, [userConvSelected]);

  const refetchUsers = () => {
    showToaster('Loading Users...');
    setPage(1);
    setSearchText('');
    setSelectedUserConv([USER_CONVERTED_OPTION[2]]);
    queryData(page, tabFilter, searchText, userConvSelected);
  };

  React.useEffect(() => {
    if (searchText) {
      setPage(1);
      queryDataDebounced && queryDataDebounced(page, tabFilter, searchText, userConvSelected);
    }
  }, [searchText]);
  

  React.useEffect(() => {
    client.query({
      query: statsQuery(organization_id, queryType, isTrainer)
    }).then((result) => {
      setStats(result.data.main_page_stats);
    });
    queryData(page, tabFilter, searchText, userConvSelected);
  }, [_t]);

  const addUser = (data) => {
    setIsCreateUserWithChannelLoading(true);
    setErrorInUserCreate(null);
    client.mutate({
      mutation: addUserMutation,
      variables: {
        userAndChannels: { ...data, domain: window.location.origin, is_trainer: isTrainer },
      }
    }).then((user) => {
      setIsCreateUserWithChannelLoading(false);
      refetchUsers();
    }).catch(err => {
      setErrorInUserCreate(err);
      setIsCreateUserWithChannelLoading(false);
    })
  };

  const addUBulkUsers = (data) => {
    setIsCreateBulkUsersLoading(true);
    setErrorInUserCreate(null);
    client.mutate({
      mutation: createBulkUser,
      variables: {
        bulkUserInput: { ...data, domain: window.location.origin },
      }
    }).then((user) => {
      setIsCreateBulkUsersLoading(false);
      refetchUsers();
    }).catch(err => {
      setErrorInUserCreate(err);
      setIsCreateBulkUsersLoading(false);
    })
  };

  const onAddOrUpdateModalClose = () => {
    setErrorInUserCreate(null);
    setErrorInUserUpdate(null);
    setUpdatedUser(null);
  }

  const updateUser = (userId, data) => {
    setIsUpdateUserWithChannelLoading(true);
    setErrorInUserUpdate(null);
    client.mutate({
      mutation: updateUserMutation,
      variables: {
        userAndChannels: { ...data, organization_id, password: '' },
        id: userId
      }
    }).then((user) => {
      setUpdatedUser(user);
      setIsUpdateUserWithChannelLoading(false);
      refetchUsers();
    }).catch(err => {
      setErrorInUserUpdate(err);
      setIsUpdateUserWithChannelLoading(false);
    })
  };

  // ----------------------------------> Render Function <----------------------------------------------- //
  const renderFilterFunction = () => {
    const onClick = (params) => {
      // setSelectedUserConv(params.value)
      setTab(params.option.key)
    }
    return (
      <RadioBtnContainer>
        <Select
          clearable={false}
          options={selectOptions}
          labelKey="name"
          valueKey="key"
          // value={userConvSelected}
          value={selectOptions.find(i => i.key === tabFilter)}
          placeholder=""
          onChange={onClick}
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                borderRadius: "45px"
              })
            },
            ControlContainer: {
              style: ({ $theme }) => ({
                backgroundColor: '#EAEAEA',
                borderRadius: "45px",
                paddingLeft: '13px'
              })
            }
          }}
        />
      </RadioBtnContainer>
    );

  }

 
  const exportUsers = () => {
    let url = `${getServerUrl()}/${type == 'admin' ? 'admins_data' : 'app_users_data'}/download/${organization_id}`;
    let filter = userConvSelected[0];
    let query = filter ? filter.dataQuery : {};
    let __url = new URL(url);
    Object.keys(query).forEach(key => {
        __url.searchParams.append(key, query[key])
    });
    console.log(__url.href);
    window.open(
        __url.href,
        '_blank'
    );
}

  const onChangeAction = params => {
    setImportExportAction(params.value)
  }

  const onClickImport  = () =>{
    setShowImportUser(true)
  }

  const onGoClick = () => {
    const {
      id
    } = importExportAction[0] || {}
    if (!id) {
      return;
    }
    if (IMPORT_EXPORT_ACTIONS[id] === IMPORT_EXPORT_ACTIONS.export_user) {
      exportUsers()
    } else if (IMPORT_EXPORT_ACTIONS[id] === IMPORT_EXPORT_ACTIONS.import_user) {
      onClickImport();
    } 
  }


  const LoadingBar = () => {
    return (
      <div style={{ width: '100%', marginLeft: '2rem', marginTop: '1rem', marginRight: '2rem'}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          {new Array(3).fill('').map(item => <Skeleton
            width="500px"
            height="145px"
            animation
            overrides={{
                Root: {
                    style: {
                      marginRight: '60px',
                      borderRadius: '10px'
                    },
                },
            }}
          />)}
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '2rem', marginRight: '2rem'}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            {new Array(2).fill('').map(item => <Skeleton
              width="250px"
              height="60px"
              animation
              overrides={{
                  Root: {
                      style: {
                        marginRight: '25px',
                        borderRadius: '45px'
                      },
                  },
              }}
            />)}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', paddingRight: '2rem'}}>
            {new Array(2).fill('').map(item => <Skeleton
              width="250px"
              height="60px"
              animation
              overrides={{
                  Root: {
                      style: {
                        marginRight: '10px',
                        borderRadius: '48px'
                      },
                  },
              }}
            />)}
          </div>
        </div>
        <div style={{marginTop: '2rem'}}>
          {new Array(4).fill('').map((item, index) => (
            <Skeleton
              width="100%"
              height="94px"
              animation
              overrides={{
                Root: {
                  style: {
                    marginBottom: '15px',
                  },
                },
              }}
          />))}
        </div>
        <div style={{ float: 'right'}}>
          <Skeleton
            width="500px"
            height="40px"
            animation
          />
        </div>
      </div>
    )
  }

  if (isDataLoading) {
    return <LoadingBar />
  }

  return (
    <div>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <Cell span={12} >
          <div style={{ width: '100%', marginBottom: '32px' }}>
            <Grid overrides={{
              Grid: {
                style: () => ({
                  padding: '0px !important',
                  justifyContent: 'space-between'
                }),
              }
            }} gridMaxWidth={'unset'}>
              <Cell span={3.6} overrides={cellPaddingOverrides}>
                <div
                  style={{
                    width: '100%',
                    paddingTop: '6%',
                    paddingBottom: '4%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    borderRadius: '10px',
                    background: 'linear-gradient(263.23deg, rgba(0, 0, 0, 0.2) -1.5%, rgba(255, 255, 255, 0.2) 100%), #4A4CA0',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <img src={svgIcons.UsersIcon} style={{margin: 0, marginLeft: '2rem', width: '80px', height: '80px'}} />
                  <div style={{marginLeft: '3rem'}}>
                    <HeadingXXLarge
                      color='white'
                      style={{
                        marginBottom: '10px',
                        fontSize: '28px',
                        lineHeight: '28px',
                        FontFamily: 'Lexend'
                      }}
                    >
                      {stats.total_users}
                    </HeadingXXLarge>
                    <HeadingXSmall
                      color='white'
                      style={{
                        fontSize: '16px',
                        marginBottom: 0,
                        textTransform: 'capitalize',
                        FontFamily: 'Lexend'
                      }}
                    >
                      {"Total " + type}
                    </HeadingXSmall>
                  </div>
                </div>
              </Cell>
              <Cell span={3.6} overrides={cellPaddingOverrides}>
                <div
                  style={{
                    width: '100%',
                    paddingTop: '6%',
                    paddingBottom: '4%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    borderRadius: '10px',
                    background: 'linear-gradient(263.23deg, rgba(0, 0, 0, 0.2) -1.5%, rgba(255, 255, 255, 0.2) 100%), #EE4678',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <img src={svgIcons.ActiveIcon} style={{margin: 0, marginLeft: '2rem', width: '80px', height: '80px'}} />
                  <div style={{marginLeft: '3rem'}}>
                    <HeadingXXLarge
                      color='white'
                      style={{
                        marginBottom: '10px',
                        fontSize: '28px',
                        lineHeight: '28px',
                        FontFamily: 'Lexend'
                      }}
                    >
                      {stats.active_users}
                    </HeadingXXLarge>
                    <HeadingXSmall
                      color='white'
                      style={{
                        fontSize: '16px',
                        marginBottom: 0,
                        textTransform: 'capitalize',
                        FontFamily: 'Lexend'
                      }}
                    >
                      {"Active " + type}
                    </HeadingXSmall>
                  </div>
                </div>
              </Cell>
              {type == 'app user' && <Cell span={3.6} overrides={cellPaddingOverrides}>
                <div
                  style={{
                    width: '100%',
                    paddingTop: '6%',
                    paddingBottom: '4%',
                    background: 'linear-gradient(263.23deg, rgba(0, 0, 0, 0.2) -1.5%, rgba(255, 255, 255, 0.2) 100%), #000000',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <HeadingXXLarge
                    color='white'
                    style={{
                      marginBottom: '10px',
                        fontSize: '28px',
                        lineHeight: '28px',
                        FontFamily: 'Lexend'
                    }}
                  >
                    {stats.cardware_converted_users}
                  </HeadingXXLarge>
                  <HeadingXSmall
                    color='white'
                    style={{
                      fontSize: '14px',
                      marginBottom: 0,
                      textTransform: 'capitalize'
                    }}
                  >
                    {"Cardware Converted " + type}
                  </HeadingXSmall>
                </div>
              </Cell>}
              {type != 'app user' && <Cell span={3.6} overrides={cellPaddingOverrides}>
                <div
                  style={{
                    width: '100%',
                    paddingTop: '6%',
                    paddingBottom: '4%',
                    background: 'linear-gradient(263.23deg, rgba(0, 0, 0, 0.2) -1.5%, rgba(255, 255, 255, 0.2) 100%), #000000',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <img src={svgIcons.InvitationIcon} style={{margin: 0, marginLeft: '2rem', width: '80px', height: '80px'}} />
                  <div style={{marginLeft: '3rem'}}>
                    <HeadingXXLarge
                      color='white'
                      style={{
                        marginBottom: '10px',
                        fontSize: '28px',
                        lineHeight: '28px',
                        FontFamily: 'Lexend'
                      }}
                    >
                      {stats.all_channel_permission_users}
                    </HeadingXXLarge>
                    <HeadingXSmall
                      color='white'
                      style={{
                        fontSize: '14px',
                        marginBottom: 0,
                        textTransform: 'capitalize'
                      }}
                    >
                      {"Invitations sent"}
                    </HeadingXSmall>
                  </div>
                </div>
              </Cell>}
            </Grid>
          </div>
        </Cell>
        <Cell span={12}  overrides={{
        Input: {
          style: ({ $theme }) => ({
            display: 'flex',
            justifyContent: 'space-between'
          })
        }}}>
          <SearchUser
            searchText={searchText}
            setSearchText={setSearchText}
            isDataLoading={isDataLoading}
          ></SearchUser>
          <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            {/* {showFilter.cardware_converted === true && renderFilterFunction()} */}
            {renderFilterFunction()}
            {/* <ExportUser
              columns={columns}
              type={type}
              users={users}
              organization_id={organization_id}
              filters={userConvSelected}
            /> */}

            <div
              className={css({
                display: 'flex'
              })}
            >
              <Select
                backspaceRemoves={false}
                clearable={false}
                deleteRemoves={false}
                escapeClearsValue={false}
                size={SIZE.default}
                options={importExportActionArr}
                value={importExportAction}
                searchable={false}
                placeholder="More..."
                onChange={onChangeAction}
              />
              {
                (importExportAction && importExportAction.length > 0) && (
                  <Button
                    text="Go"
                    //isLoading={exportTemplateLoader || exportLoader}
                    style={{
                      marginLeft: '10px',
                      width: '50px',
                      height: '50px',
                    }}
                    onClick={onGoClick}
                  />
                )
              }

            </div> 

            <span style={{width: '10px'}}/>
            <AddUser
              addUser={addUser}
              appChannels={channels}
              organization_id={organization_id}
              isCreateUserWithChannelLoading={isCreateUserWithChannelLoading}
              type={type}
              errorInUserCreate={errorInUserCreate}
              onClose={onAddOrUpdateModalClose}
            />
            <span style={{ width: '10px' }} />

            <div span={3} style={{ float: 'right' }}>
              <Button
                border_property={"true"}
                onClick={() => setShowInviteUser(true)}
                text={`Invite Users`}
                style={{ textTransform: 'capitalize' }}
                startEnhancer={() => (
                  <img
                    src={svgIcons.AddIcon}
                    style={{
                      margin: 0
                    }}
                  />
                )}
              endEnhancer={() => (
                <HelpQuestionMark text={`add Users to the system`} button />
              )}
              />
            </div>
          </div>
        </Cell>
        <Cell span={12}>
          <UsersList
            columns={columns}
            users={users}
            defaultTheme={defaultTheme}
            listMenuOptions={listMenuOptions}
            updateUser={updateUser}
            errorInUserUpdate={errorInUserUpdate}
            isUpdateUserWithChannelLoading={isUpdateUserWithChannelLoading}
            channels={channels}
            type={type}
            onAddOrUpdateModalClose={onAddOrUpdateModalClose}
            updatedUser={updatedUser}
            organization_id={organization_id}
            theme={theme}

            totalPages={totalPages}
            page={page}
            setPage={setPage}
            isDataLoading={isDataLoading}
            isNoResult={isNoResult}
          ></UsersList>
        </Cell>
      </Grid>
      <InviteUsers
          isOpen={showInviteUser} 
          addUBulkUsers={addUBulkUsers}
          onClose={() => setShowInviteUser(false)} 
          organization_id={organization_id}
          isCreateBulkUsersLoading={isCreateBulkUsersLoading}
          errorInUserCreate={errorInUserCreate}
        />
        <ImportUsers
          isOpen={showImportUser} 
          addUBulkUsers={addUBulkUsers}
          onClose={() => setShowImportUser(false)} 
          organization_id={organization_id}
          isCreateBulkUsersLoading={isCreateBulkUsersLoading}
          errorInUserCreate={errorInUserCreate}
          type={type}
          isTrainer={isTrainer}
          refetchUsers={refetchUsers}
          title={isTrainer ? `Trainers` : `${type}${type[type.length - 1] != 's' ? 's' : ''}`}
        />
    </div>
  );
};

export default Admin;
