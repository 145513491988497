import * as React from 'react';
import { arrayMove } from 'baseui/dnd-list';
import BaseClass from '../../builder/cardBackEditorComponents/base';
import { getFilesUrls, getAttachmentUrls } from '../../../util';
import ImageUploadCXL from '../../imageUploaderCXL';
import {
    cardBackImageGuideline,
    imagePickerPreviewGuideline,
    imagePickerDownloadGuideline,
    imagePickerThumbGuideline
} from '../../help/imageSizeGuidelines.json';
import { Spinner } from 'baseui/spinner';


class ImagePickerComponent extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            value: [],
            progressAmount: 0,
        };
        this.onReorder = this.onReorder.bind(this);
        this.onImageDelete = this.onImageDelete.bind(this);
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        // debugger
        let updatedValue = [];
        console.log('____', acceptedFiles, this.props);
        // if (this.state.value && this.state.value !== null) {
        //     updatedValue = this.state.value.concat(acceptedFiles);
        // } else {
        //     updatedValue = updatedValue.concat(acceptedFiles);
        // }
        if (Array.isArray(this.state.value)) {
            updatedValue = this.state.value;
        };
        if (this.props.multiple) {
            updatedValue = [...updatedValue, ...acceptedFiles];
        } else {
            updatedValue = acceptedFiles;
        };
        console.log('_______', updatedValue);
        this.setState({
            value: updatedValue,
            progressAmount: 100,
        });
    }

    onReorder = (source, destination) => {
        let newArr = arrayMove(
            [...this.state.value],
            source.index,
            destination.index
        );
        newArr = newArr.map((ele, index) => ({ ...ele, position: index }))
        this.setState({ value: newArr, progressAmount: 100 })
    }

    onImageDelete = (index) => {
        let v = this.state.value;
        if (Array.isArray(v)) {
            v = v.map((el) => el);
            v.splice(index, 1);
            console.log(v);
            this.setState({
                value: v,
                progressAmount: 100,
            });
        } else {
            this.setState({
                value: [],
                progressAmount: 100,
            });
        }
    }

    render() {
        if (this.props.root.dId && this.props.root.dId.includes('manual') && !this.props.isCommunication) {
        // console.log('Loading...........');
        return <div style={{ display: 'flex' }}>
            <Spinner size={24} />
            <div style={{ marginLeft: '16px', textTransform: 'uppercase'}}>Setting up uploader...</div>
        </div>
        };
        return (
            <>
                {/* <h5
                    style={{
                        color: this.props.theme.colors.primaryA,
                        marginTop: this.props.theme.sizing.scale1200,
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    {this.props.title || ''}
                </h5> */}
                <ImageUploadCXL
                    showHint={this.props.showHint}
                    alwaysShowDropZone={this.props.alwaysShowDropZone}
                    title={this.props.title}
                    titleHelpText={this.props.titleHelpText}
                    urlData = {this.state.value}
                    previewUrls={getFilesUrls(this.state.value, imagePickerPreviewGuideline.fileSize, imagePickerPreviewGuideline.imageType, 'cover', this.props.root.display)}
                    thumbUrls={getFilesUrls(this.state.value, imagePickerThumbGuideline.fileSize, imagePickerThumbGuideline.imageType, 'thumbnail')}
                    downloadUrls={getFilesUrls(this.state.value, imagePickerDownloadGuideline.fileSize, imagePickerDownloadGuideline.imageType, 'cover', this.props.root.display)}
                    attachmentUrls={(this.props.root.display == 'Attachment') ? getAttachmentUrls(this.state.value, this.props.root.display) : []}
                    isMultiple={this.props.multiple}
                    onDrop={this.onDrop.bind(this)}
                    onReorder={this.onReorder}
                    onImageDelete={this.onImageDelete}
                    showFullBleedSettings={false}
                    imageSize={cardBackImageGuideline.size}
                    imageSizeGuidelineURL={cardBackImageGuideline.url}
                    style={{ background: 'transparent' }}
                    name={this.props.root.name}
                    hideTitle={false}
                >
                </ImageUploadCXL>
            </>
        );
    }
}

export default ImagePickerComponent;
