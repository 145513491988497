import React, { useState, useEffect } from 'react';
import { LabelMedium } from 'baseui/typography';
import { Input } from 'baseui/input';
import { Cell } from 'baseui/layout-grid';
import { Accordion, Panel } from "baseui/accordion";
import { Button } from 'baseui/button';
import { DatePicker } from "baseui/datepicker";
import CardPickerCXL from './cardFilterPicker';
import { useStyletron } from 'baseui';
import moment from 'moment';
import { client } from '../apollo/client';
import { filtered_cards } from '../quries';
import store from '../redux/store';

export default ({ onChange , ...rest }) => {
  const [options, setOptions] = useState([]);
  const [theme] = useStyletron();
  const getFieldId = () => Math.random().toString(36).slice(2)
  const [cardOptions, setCardOptions] = useState([]);

  useEffect(() => {
    if (rest.value)
      setOptions(Array.isArray(rest.value) ? rest.value : JSON.parse(rest.value));
  }, [rest.value]);

  const addNew = () => {
    setOptions(
      [
        ...options,
        {
          id: getFieldId(),
          name: '',
          //   is_expanded: false,
          start_date: moment().toDate().toISOString(),
          end_date: moment().toDate().toISOString(),
          redirect_card_id: "",
          position: options.length,
        }
      ]
    )
  };

  const removeIndex = index => {
    const temp = [...options];
    temp.splice(index, 1);
    setOptions(temp)
    onChange(JSON.stringify(temp));
  };

  const setValues = (index, key, value) => {
    let o = [...options];
    o[index][key] = value;
    setOptions(o);
    onChange(JSON.stringify(o));
  };

  const filterDuplicatesCard = (cardId, list) => {
    let filtered = list.filter(el => el.id == cardId)
    let c = { ...JSON.parse(JSON.stringify(filtered[0])) }
    // console.log("commmon_card_titlessssss", filtered);

    filtered.map((d) => {
      if (d.content_tracking_id) {
        c.content_tracking_id = d.content_tracking_id
        c.content_tracking_name = d.content_tracking_name
      }
      if (d.form_tracking_id) {
        c.form_tracking_id = d.form_tracking_id
        c.form_tracking_name = d.form_tracking_name
      }
      if (d.impression_tracking_id) {
        c.impression_tracking_id = d.impression_tracking_id
        c.impression_tracking_name = d.impression_tracking_name
      }
    })
    return c
  }

  const getCardTitle = (deckCard, stackCard) => {
    let title = ""
    if (deckCard) {
      let c = { ...deckCard }
      title = `${c.card_title} ( ${c.group_name != "" ? c.group_name : "Undefined"} > ${c.channel_name != "" ? c.channel_name : "Undefined"} > ${c.category_name != "" ? c.category_name : "Undefined"} > ${c.deck_title != "" ? c.deck_title : "Undefined"} ${c.content_tracking_id ? ` > ${c.content_tracking_name} (${c.content_tracking_id})` : ""} ${c.form_tracking_id ? ` > ${c.form_tracking_name} (${c.form_tracking_id})` : ""} ${c.impression_tracking_id ? ` > ${c.impression_tracking_name} (${c.impression_tracking_id})` : ""} )`
    } else if (stackCard) {
      let c = { ...stackCard }
      title = `${c.card_title} (${c.home_component_title != "" ? c.home_component_title : "Undefined"}) ${c.content_tracking_id ? ` > ${c.content_tracking_name} (${c.content_tracking_id})` : ""}${c.form_tracking_id ? ` > ${c.form_tracking_name} (${c.form_tracking_id})` : ""}${c.impression_tracking_id ? ` > ${c.impression_tracking_name} (${c.impression_tracking_id})` : ""})`
    }
    return title
  }

  const getCardFilterData = () => {
    try {
      client.query({
        query: filtered_cards(store.getState().appBasicControls.organization.id),
        fetchPolicy: "no-cache"
      }).then((result) => {
        let { data } = result
        let cards = []
        let deckCards = data?.filtered_cards?.deck_cards
        let stackCards = data?.filtered_cards?.stack_cards
        console.log("api Data", result);

        deckCards.map((item) => {
          let findCardIndex = cards.findIndex(i => i.id == item.id)
          if (findCardIndex == -1 && item.category_name && item.deck_title) {
            let c = filterDuplicatesCard(item.id, deckCards)
      
            cards.push({
              id: c.id,
              title: getCardTitle(c,null),
              selected: false,
            })
          }
        })

        stackCards.map((item) => {
          let findCardIndex = cards.findIndex(i => i.id == item.id)
          if (findCardIndex == -1) {
            // title: `${el1.card_back_home_component_card.title} (${el.title})`
            let c = filterDuplicatesCard(item.id, stackCards)
            
            cards.push({
              id: c.id,
              title: getCardTitle(null,c),
              selected: false,
            })
          }
        })
        setCardOptions(cards)
      }).catch(err => {
        console.log('error', err)
      })
    } catch (err) {
      console.log(' error', err)
    }
  }

  useEffect(() => {
    getCardFilterData()
  }, []);

  return <>
    <Accordion
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            marginLeft: 0,
          })
        },
        // PanelContainer: {
        //   style: ({ $theme }) => ({
        //     backgroundColor: 'white',
        //     marginBottom: '1rem'
        //   })
        // }
      }}
    >
      {options.map((op, index) => {
        return <Panel title={op.name || `Step ${index + 1}`}>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
            <Input
              value={op.name}
              onChange={e => {
                setValues(index, 'name', e.target.value)
              }}
              placeholder="title"
              clearOnEscape
            />
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <LabelMedium style={{ marginBottom: '16px' }}>Start Date</LabelMedium>
            <div style={{ position: 'relative' }}>

              <DatePicker
                key={"start_date"}
                value={[op.start_date ? new Date(op.start_date) : new Date()]}
                onChange={({ date }) => { setValues(index, 'start_date', Array.isArray(date) ? date[0] : date) }}
                timeSelectStart
                formatString={'yyyy-MM-dd HH:mm'}
              />

            </div>
          </Cell>

          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >
            <LabelMedium style={{ marginBottom: '16px' }}>Completion Date</LabelMedium>
            <div style={{ position: 'relative' }}>

              <DatePicker
                key={"end_date"}
                value={[op.end_date ? new Date(op.end_date) : new Date()]}
                onChange={({ date }) => { setValues(index, 'end_date', Array.isArray(date) ? date[0] : date) }}
                timeSelectStart
                creatable
                formatString={'yyyy-MM-dd HH:mm'}
              />

            </div>
          </Cell>
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                }),
              },
            }}
          >

            <CardPickerCXL
              selectedCardId={op.redirect_card_id}
              onCardSelect={(id) => {
                setValues(index, 'redirect_card_id', id)
              }}
              title={''}
              theme={theme}
              marginTop={'0px'}
              placeholder={'Select Card'}
              cardOptions={cardOptions}
            />
          </Cell>
        </Panel>;
      })}
    </Accordion>
    <Cell
      span={[1, 12]}
      overrides={{
        Cell: {
          style: () => ({
            paddingLeft: '0px !important',
          }),
        },
      }}
    >
      <Button
        style={{
          marginTop: '16px',
        }}
        onClick={() => {
          addNew();
        }}>Add Step</Button>
    </Cell>
  </>;
}