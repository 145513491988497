import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import Step from './step';
import StepNavBar from './step-nav-bar';
import { useStyletron } from 'baseui';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import {
  LabelSmall, LabelMedium, LabelXSmall
} from 'baseui/typography';
import ChevronDown from 'baseui/icon/chevron-down';
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { Input } from 'baseui/input';
import store from '../../redux/store';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { PLACEMENT } from 'baseui/toast';
import { getServerUrl } from '../../apollo/client';
import axios from 'axios';

const svgStyle = {
  left: '50%',
  height: '24px',
  position: 'relative',
  transform: 'translateX(-50%)',
  marginBottom: '0px'
};

const StepNavHeaderText = styled(LabelSmall)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StepsWizard = ({ title, subtitle, STEPS, onStepClick, current, version, onModalClose, publishButton, menu = {}, organization_id, card_id, refetch }) => {
  const [css, theme] = useStyletron();
  const defaultTheme = store.getState().appBasicControls.theme || 'black';
  const appBasicControls = useSelector(state => state.appBasicControls);
  const [checked, setChecked] = React.useState(publishButton ? publishButton.status : false);
  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
  const [importLoading, setImportLoading] = React.useState(false);
  const [documentId, setDocumentId] = React.useState('');

  const getExitBtnColor = React.useMemo(() => {
    if (appBasicControls.isLoading === true) {
      return { icon: 'rgb(117,117,117,0.3)', btn: 'rgb(117,117,117,0.3)' }
    }
    return { icon: '#757575', btn: theme.colors.primaryA }
  }, [appBasicControls.isLoading])
  console.log(menu);
  const onMenuOptionClick = React.useMemo(() => ({ item }) => menu.onOptionClick(item), [menu.options]);

  return <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
    <Cell span={12} overrides={cellPaddingOverrides}>
      <div
        style={{
          position: 'fixed',
          height: `72px`,
          background: defaultTheme === "dark" ? 'black' : 'white', // theme.colors.builderNavigationBackground,
          width: '100vw',
          boxShadow: theme.lighting.builderNavigationShadow,
          zIndex: 1000
        }}
      >

        <div style={{
          position: 'relative',
          float: 'left',
          width: '300px',
          height: '100%',
          borderRight: '2px solid #EEEEEE'
        }}>
          <div style={{
            position: 'relative',
            float: 'left',
            width: 'auto', height: 'auto', paddingLeft: '24px',
            top: '50%',
            transform: 'translateY(-50%)'
          }}>
            <div style={{ display: 'flex' }}>
              <StatefulPopover
                content={() => (
                  <div
                    className={css({
                      padding: '16px',
                      maxWidth: '400px',
                      fontSize: '14px',
                    })}
                  >
                    {title}
                  </div>
                )}
                triggerType={TRIGGER_TYPE.hover}
                accessibilityType={'tooltip'}
                showArrow
                placement={PLACEMENT.bottomRight}
                overrides={{
                  Body: {
                    style: {
                      zIndex: 100,
                    }
                  }
                }}
              >
                <StepNavHeaderText>{title}</StepNavHeaderText>
              </StatefulPopover>
              {menu && menu.isActive && <StatefulPopover
                content={() => (
                  <StatefulMenu
                    overrides={{
                      List: {
                        style: {
                          maxHeight: '80vh'
                        }
                      }
                    }}
                    items={menu.options}
                    onItemSelect={onMenuOptionClick}
                    noResultsMsg={menu.noResultsMsg}
                  />
                )}
                returnFocus
                renderAll
                autoFocus
              >
                <ChevronDown size={20} style={{ marginLeft: '2px', cursor: 'pointer' }} />
              </StatefulPopover>}
            </div>
            <LabelXSmall style={{ marginTop: '0px', fontWeight: '400' }}>{subtitle}</LabelXSmall>
          </div>
        </div>

        <div style={{
          position: 'relative',
          float: 'left'
        }}>
          {STEPS.map((element, index) => {
            return <Step
              text={element.buttonTitle}
              onClick={() => onStepClick(index)}
              disabled={element.disabled}
              selected={current === index}
              index={index}
              totalSteps={STEPS.length}
              svgName={element.svgName}
              version={version}
            ></Step>
          })}
        </div>

        {/* Published and Exit Button */}
        <div style={{
          position: 'relative',
          float: 'right'
        }}>
          {/* PUBLISH BUTTON */}
          {current == 1 && <div
            onClick={() => setIsImportModalOpen(true)}
            style={{
              width: '100px',
              height: '72px',
              position: 'relative',
              float: 'left',
              boxSizing: 'border-box',
              borderLeft: '2px solid #EEEEEE',
              cursor: 'pointer'
            }}>
            <div style={{
              position: 'relative',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
              <img src="https://img.icons8.com/fluency-systems-filled/48/null/import.png" style={{ marginBottom: '0px', height: '24px', width: '24px' }} />
              <LabelMedium style={{ textAlign: 'center', color: getExitBtnColor.btn }}>Import</LabelMedium>
            </div>
          </div>}
          {publishButton && publishButton.isActive && <div
            style={{
              width: '100px',
              height: '72px',
              position: 'relative',
              float: 'left',
              boxSizing: 'border-box',
              borderLeft: '2px solid #EEEEEE'
            }}>
            <div style={{
              position: 'relative',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}>
              <div style={{
                width: '54px',
                left: '50%',
                transform: 'translateX(-50%)',
                marginBottom: '5px',
                position: 'relative'
              }}>
                <Toggle
                  id="cheese-status"
                  defaultChecked={publishButton.defaultStatus}
                  checked={checked}
                  icons={false}
                  disabled={publishButton.disabled}
                  onChange={e => {
                    console.log(e.target.checked)
                    setChecked(e.target.checked);
                    publishButton.onChange(e.target.checked)
                  }}
                ></Toggle>
              </div>
              <LabelMedium style={{ textAlign: 'center', color: theme.colors.primaryA }}>{checked ? publishButton.onTitle : publishButton.offTitle}</LabelMedium>
            </div>
          </div>}
          {/* END PUBLISH BUTTON */}
          <div
            onClick={onModalClose}
            style={{
              width: '100px',
              height: '72px',
              position: 'relative',
              float: 'left',
              boxSizing: 'border-box',
              borderLeft: '2px solid #EEEEEE',
              cursor: 'pointer'
            }}>
            <div style={{
              position: 'relative',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}>
              <svg style={svgStyle} width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.97852 16.7867L11.963 18.6667L19 12L11.963 5.33333L9.97852 7.21333L13.6096 10.6667H0V13.3333H13.6096L9.97852 16.7867ZM22.5185 0H2.81481C1.25259 0 0 1.2 0 2.66667V8H2.81481V2.66667H22.5185V21.3333H2.81481V16H0V21.3333C0 22.8 1.25259 24 2.81481 24H22.5185C24.0667 24 25.3333 22.8 25.3333 21.3333V2.66667C25.3333 1.2 24.0667 0 22.5185 0Z" fill={getExitBtnColor.icon} />
              </svg>
              <LabelMedium style={{ textAlign: 'center', color: getExitBtnColor.btn }}>Exit</LabelMedium>
            </div>
          </div>
        </div>

      </div>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'absolute',
        left: 0,
        right: 0,
        top: '72px'
      }}>
        {STEPS[current].component()}
      </div>

      <Modal
        isOpen={isImportModalOpen}
        onClose={() => {
          setIsImportModalOpen(false);
        }}
      >
        <ModalHeader>{'Import from Google Document'}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <LabelMedium style={{ marginBottom: '16px' }}>Document ID</LabelMedium>
          <Input
            value={documentId}
            onChange={e => {
              setDocumentId(e.target.value);
            }}
            placeholder="Document ID"
            clearOnEscape
          />
        </ModalBody>
        <ModalFooter>
          <ModalButton
            isLoading={importLoading}
            onClick={() => {
              if (documentId) {
                setImportLoading(true);
                axios.post(`${getServerUrl()}/import_card_back_from_google_doc`, {
                  organization_id,
                  card_id,
                  document_id: documentId
                })
                  .then((result) => {
                    console.log(result);
                    onStepClick(0);
                    refetch();
                    setImportLoading(false);
                    setIsImportModalOpen(false);
                  })
              }
            }}
          >
            {'Import'}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </Cell>
  </Grid>
}

export default StepsWizard;