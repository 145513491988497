import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import { gridPaddingOverrides } from '../overrides';
import RightSidePageHeader from '../pageHeaders';
import Button from '../styledButton';
import CardSpecifications from './cardSpecifications';
import CardTags from './cardTags';
import { Tabs, Tab, FILL } from "baseui/tabs-motion";

const TAB_STATUS = {
  DATA_FILTERS: 'Data Filters',
  TAGS: 'Tags',

};

const pageTitlesAndDescriptions = {
  "1": {
    title: 'Tags',
    description: 'Assign tags to the card'
  },
  "0": {
    title: 'Filter & Specifications',
    description: 'Assign filter and specs for card'
  }
}

const FilterTags = ({
  card,
  organization_id,
  toaster
}) => {
  const [tab, setTab] = React.useState("0");
  // console.log(tab);
  // console.log(card);
  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        {/* <RightSidePageHeader
          icon={null}
          title={pageTitlesAndDescriptions[tab].title}
          // rightContent={
          //     <StatefulButtonGroup
          //         mode={MODE.radio}
          //         initialState={{ selected: 0 }}
          //     >
          //         {Object.keys(TAB_STATUS).map(el => {
          //             return (
          //                 <Button onClick={() => setTab(TAB_STATUS[el])}>
          //                     {TAB_STATUS[el]}
          //                 </Button>
          //             );
          //         })}
          //     </StatefulButtonGroup>
          // }
          headerDescription={pageTitlesAndDescriptions[tab].description}
        ></RightSidePageHeader> */}
        <Cell span={12}>
          <Tabs
            activeKey={tab}
            onChange={({ activeKey }) => {
              setTab(activeKey.toString());
            }}
            activateOnFocus
            // fill={FILL.fixed}
          >
            <Tab title={TAB_STATUS.DATA_FILTERS}>
              <CardSpecifications
                card={card}
                organization_id={organization_id}
                toaster={toaster}
              />
            </Tab>
            <Tab title={TAB_STATUS.TAGS}>
              <CardTags
                card={card}
                organization_id={organization_id}
                toaster={toaster} />
            </Tab>
          </Tabs>
        </Cell>
      </Grid>
    </>
  );
};

export default FilterTags;
