import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import store from '../../../redux/store';

const SelectCXL = (props) => {

  const options = React.useMemo(() => {
    console.log('================================ SelectCXL options')
    let tempOptions = props.options;
    const componentState = props.state;
    if (props.loadOptionsFromState) {
      tempOptions = props.pathToStoreValue
        .split('.')
        .reduce((o, i) => o[i], store.getState());
      if (props.optionFilter) {
        tempOptions = props.optionFilter(tempOptions)
      }
    }

    if (props.loadOptionsFromComponentState && componentState) {
      tempOptions = props.pathToStoreValue
        .split('.')
        .reduce((o, i) => o[i], componentState);
      if (props.optionFilter) {
        tempOptions = props.optionFilter(tempOptions)
      }
    }

    if (props.formatOptions) {
      tempOptions = props.formatOptions(tempOptions);
    }
    return tempOptions;
  }, [props])

  const onChange = ({ value }) => {
    if (props.multi) {
      props.onChange(value);
      return
    }
    props.onChange(value[0].id)
  }

  const placeholder = React.useMemo(() => {
    return props.value ? '' : props.placeholder;
  }, [props.value, props.placeholder])

  console.log('================================ SelectCXL', props);

  const selectedValue = React.useMemo(() => {
    let value = props.value;
    if (typeof value === 'string') {
      value = (options || []).find((v) => v.id.toLowerCase() == value.toLowerCase());
    }
    return value;
  }, [options, props.value])



  return (
    <Select
      options={options}
      onChange={onChange}
      value={selectedValue}
      placeholder={placeholder}
      multi={props.multi || false}
      labelKey={props.labelKey || 'label'}
      valueKey="id"
      maxDropdownHeight="300px"
      type={TYPE.search}
      overrides={props.overrides}
    />
  );
}

export default SelectCXL;