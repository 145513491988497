import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import gql from 'graphql-tag';
import { Grid, Cell } from 'baseui/layout-grid';
import {
  LabelLarge,
  LabelSmall,
} from 'baseui/typography';
import { useStyletron } from 'baseui';
import { Input } from 'baseui/input';
import { Button } from 'baseui/button';
import { arrayMove } from 'baseui/dnd-list';
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip';

import { useMutation } from '@apollo/client';
import AddCategory from '../category/addCategory';
import { connect } from 'react-redux';
import ReorderInModal from '../reorderInModal';
import Slides from './slides';
import EditComponent from '../category/addOrEditComponent';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import Portal from './portal';
import {
  Droppable,
  Draggable,
  DragDropContext
} from 'react-beautiful-dnd';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
let portal;
if (typeof window !== `undefined`) {
  portal = document.createElement('div');
  portal.classList.add('my-super-cool-portal-11');
  if (!document.body) {
    throw new Error('body not ready for portal creation!');
  }
  document.body.appendChild(portal);
}

class PortalAwareItem extends Component {
  render() {
    const {
      dragProvided,
      index,
      css,
      snapshot,
      setIsCollapsed,
      isCollapsed,
      el,
    } = this.props;
    const usePortal = snapshot.isDragging;
    const child = (
      <div
        {...dragProvided.dragHandleProps}
        {...dragProvided.draggableProps}
        ref={dragProvided.innerRef}
        style={{ marginTop: '16px' }}
      >
        <div
          key={index}
          className={css({
            position: 'relative',
            width: '50vw',
            height: '50px',
            lineHeight: '34px',
            background: snapshot.isDragging ? '#5D64AB' : 'white',
            margin: '0',
            display: 'flex',
            padding: '16px',
            color: 'white',
            background: '#0D0E13'
          })}
        >
          <StatefulTooltip
            content={() => <div padding={'20px'}>Reorder</div>}
            placement={PLACEMENT.bottom}
            returnFocus
            autoFocus
          >
            <svg
              width="21"
              height="18"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={css({
                cursor: 'pointer',
              })}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <path
                d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z"
                fill={snapshot.isDragging ? 'white' : 'white'}
              />
            </svg>
          </StatefulTooltip>
          <LabelSmall
            className={css({
              marginLeft: '16px',
              color: 'white !important'
            })}
          >
            {el.home_component.title}
          </LabelSmall>
        </div>
      </div>
    );
    if (!usePortal) {
      return child;
    }
    return ReactDOM.createPortal(child, portal);
  }
}

const HomeComponents = ({
  appContentScopeId,
  organization_id,
  home_component_app_content_scopes,
  refetchHomeComponents,
  category,
  defaultTheme
}) => {
  const [css, theme] = useStyletron();
  const [searchText, setSearchText] = React.useState('');
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [hoveredCategory, setHoveredCategory] = React.useState(null);
  console.log(home_component_app_content_scopes);
  const [filtered, setFiltered] = React.useState([]);
  React.useEffect(() => {
    let s = JSON.parse(JSON.stringify(home_component_app_content_scopes));
    setFiltered(s.sort((a, b) => a.position - b.position));
  }, [home_component_app_content_scopes]);
  const [selectedHomeComponent, setSelectedHomeComponent] = React.useState(null);
  const [isEditComponentOpen, setIsEditComponentOpen] = React.useState(false);
  const onClose = () => {
    setIsCollapsed(false);
    setIsEditComponentOpen(false);
    setSelectedHomeComponent(null);
  };
  const [updateComponent, { data: updatedComponent }] = useMutation(gql`
		mutation updateHomeComponent(
			$id: ID!,
			$title: String,
			$position: Int,
			$public: Boolean,
			$archived: Boolean,
			$published: Boolean
		) {
			updateHomeComponent(
				id: $id,
				title: $title,
				position: $position,
				public: $public,
				archived: $archived,
				published: $published
			) {
				title
			}
		}
	  `);
  console.log('filtered');
  console.log(filtered);
  return <>
    <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
      <Cell span={12} overrides={cellPaddingOverrides}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', padding: '0 31px 0 25px' }}>
          <div style={{ display: 'flex', }}>
            <Input
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              placeholder="Search for decks and cards"
              clearOnEscape
              overrides={{
                Input: {
                  style: {
                    width: '286px',
                    height: '45px',
                    fontSize: '12px',
                    // fontFamily: 'IBM Plex Sans',
                    background: defaultTheme === 'light' ? '#E2E2E2' : 'white',
                    color: '#1E1F2C'
                  }
                }
              }}
            />
            <Button
              className={css({
                position: 'relative',
                marginLeft: '7px',
                background: '#0D0E13 !important',
                color: 'white !important',
                fontSize: '14px'
              })}
              onClick={e => console.log('Click')}
            >Search</Button>
          </div>
          <AddCategory
            organization_id={organization_id}
            position={category.filter(el => el.app_content_scope_id === appContentScopeId).length}
            app_content_scope_id={appContentScopeId}
            home_component_app_content_scopes={home_component_app_content_scopes}
            refetchHomeComponents={refetchHomeComponents}
          />
        </div>
        <hr style={{ height: '2px', margin: 0, marginTop: '22px', background: defaultTheme === "light" ? '#E2E2E2' : 'rgba(13, 14, 19, 0.5)' }} />
      </Cell>
    </Grid>
    <ReorderInModal
      isCollapsed={isCollapsed}
      onClose={onClose}
      title={'Reorder Components'}
    >
      {isCollapsed ? <>
        <Cell span={12}>
          <div
            id="wrapper"
            className="wrapper"
            style={{
              paddingTop: theme.sizing.scale600,
            }}
          >
            <FlexGrid>
              <DragDropContext
                onDragEnd={({ destination, source, ...rest }) => {
                  console.log('drag end')
                  if (!destination) {
                    return;
                  }
                  let newArr = arrayMove(
                    JSON.parse(JSON.stringify(filtered)),
                    source.index,
                    destination.index
                  );
                  newArr = newArr.map((el, i) => {
                    el.position = i;
                    delete el.isNew;
                    return el;
                  });
                  newArr.forEach(el => {
                    let old = filtered.find(el1 => el.id == el1.id);
                    if (old.position !== el.position) {
                      updateComponent({
                        variables: {
                          id: el.home_component.id,
                          position: el.position
                        }
                      });
                    }
                  });
                  setFiltered(newArr);
                }}
              >
                <Droppable
                  droppableId={'row2_component'}
                  type={'component'}
                  direction={'vertical'}
                  isCombineEnabled={false}
                >
                  {dropProvided => (
                    <div className="scrolls" ref={dropProvided.innerRef} {...dropProvided.droppableProps}
                      style={{
                        display: 'block',
                      }}
                    >
                      {filtered.map((el, index) => {
                        return (
                          // <Grid
                          // 	overrides={gridPaddingOverrides}
                          // 	key={index}
                          // 	gridMaxWidth={'unset'}
                          // >
                          // 	<Cell span={12} overrides={cellPaddingOverrides}>
                          <Draggable
                            key={el.id}
                            draggableId={el.id}
                            index={index}
                          >
                            {(dragProvided, snapshot) => (
                              <>
                                {/* <Portal
																snapshot={snapshot}
															></Portal> */}
                                <PortalAwareItem
                                  dragProvided={dragProvided}
                                  el={el}
                                  css={css}
                                  theme={theme}
                                  snapshot={snapshot}
                                  index={index}
                                  setIsCollapsed={setIsCollapsed}
                                  isCollapsed={isCollapsed}
                                ></PortalAwareItem>
                              </>
                            )}
                          </Draggable>
                          // 	</Cell>
                          // </Grid>
                        )
                      })}
                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </FlexGrid>
          </div>
        </Cell>
      </> :
        filtered.map((el, index) => {
          return <Grid
            overrides={gridPaddingOverrides}
            key={index}
            gridMaxWidth={'unset'}
          >
            <Cell span={12} overrides={cellPaddingOverrides}>
              <div
                onMouseEnter={() => setHoveredCategory(el.id)}
                onMouseLeave={() => setHoveredCategory(null)}
                className={css({
                  width: '40vw',
                  height: '24px',
                  paddingLeft: '1.5rem',
                  marginTop: '8px'
                })}
              >
                {// hoveredCategory == el.id
                  // show reorder button always
                  // if want to rollback remove true and uncomment above line
                  true && (
                    <StatefulTooltip
                      content={() => (
                        <div padding={'20px'}>Reorder</div>
                      )}
                      placement={PLACEMENT.bottom}
                      returnFocus
                      autoFocus
                    >
                      <svg
                        width="21"
                        height="18"
                        viewBox="0 0 21 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={css({
                          position: 'relative',
                          float: 'left',
                          marginRight: theme.sizing.scale550,
                          marginTop: theme.sizing.scale100,
                          cursor: 'pointer',
                        })}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                      >
                        <path
                          d="M5 4H7.5L4 0.5L0.5 4H3V14H0.5L4 17.5L7.5 14H5V4ZM9 2V4H21V2H9ZM9 16H21V14H9V16ZM9 10H21V8H9V10Z"
                          fill={theme.colors.primaryA}
                        />
                      </svg>
                    </StatefulTooltip>
                  )}
                <LabelLarge
                  className={css({
                    position: 'relative',
                    float: 'left',
                    textTransform: 'capitalize',
                  })}
                >
                  {el.home_component.title}
                </LabelLarge>
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={css({
                    position: 'relative',
                    float: 'left',
                    marginLeft: theme.sizing.scale550,
                    marginTop: theme.sizing.scale100,
                  })}
                  onClick={() => {
                    console.log('settings click');
                    console.log(el);
                    setSelectedHomeComponent(el.home_component);
                    setIsEditComponentOpen(true);
                  }}
                >
                  <path
                    d="M16.4731 10.536C16.5091 10.236 16.5331 9.924 16.5331 9.6C16.5331 9.276 16.5091 8.964 16.4611 8.664L18.4891 7.08C18.6691 6.936 18.7171 6.672 18.6091 6.468L16.6891 3.144C16.5691 2.928 16.3171 2.856 16.1011 2.928L13.7131 3.888C13.2091 3.504 12.6811 3.192 12.0931 2.952L11.7331 0.408C11.6971 0.168 11.4931 0 11.2531 0H7.41311C7.17311 0 6.98112 0.168 6.94512 0.408L6.58511 2.952C5.99711 3.192 5.45712 3.516 4.96512 3.888L2.57711 2.928C2.36111 2.844 2.10911 2.928 1.98911 3.144L0.0691144 6.468C-0.0508856 6.684 -0.00288541 6.936 0.189115 7.08L2.21711 8.664C2.16911 8.964 2.13311 9.288 2.13311 9.6C2.13311 9.912 2.15711 10.236 2.20511 10.536L0.177114 12.12C-0.0028857 12.264 -0.0508856 12.528 0.0571144 12.732L1.97711 16.056C2.09711 16.272 2.34911 16.344 2.56511 16.272L4.95311 15.312C5.45711 15.696 5.98511 16.008 6.57311 16.248L6.93312 18.792C6.98111 19.032 7.17311 19.2 7.41311 19.2H11.2531C11.4931 19.2 11.6971 19.032 11.7211 18.792L12.0811 16.248C12.6691 16.008 13.2091 15.684 13.7011 15.312L16.0891 16.272C16.3051 16.356 16.5571 16.272 16.6771 16.056L18.5971 12.732C18.7171 12.516 18.6691 12.264 18.4771 12.12L16.4731 10.536ZM9.33311 13.2C7.35312 13.2 5.73311 11.58 5.73311 9.6C5.73311 7.62 7.35312 6 9.33311 6C11.3131 6 12.9331 7.62 12.9331 9.6C12.9331 11.58 11.3131 13.2 9.33311 13.2Z"
                    fill={defaultTheme === "light" ? "#757575" : "#4A4CA0"}
                  />
                </svg>
              </div>
              <hr style={{ height: '2px', margin: 0, marginTop: '10px', background: defaultTheme === "light" ? '#E2E2E2' : 'rgba(13, 14, 19, 0.5)' }} />
            </Cell>
            <Cell span={12} overrides={cellPaddingOverrides}>
              <div style={{ paddingLeft: '2rem', paddingRight: '25px' }}>
                <Slides
                  selectedComponent={el}
                  appContentScopeId={appContentScopeId}
                  organization_id={organization_id}
                  home_component_app_content_scopes={home_component_app_content_scopes}
                  refetchHomeComponents={refetchHomeComponents}
                ></Slides>
              </div>
              <hr style={{ height: '2px', margin: '2px 0 0', background: defaultTheme === "light" ? '#E2E2E2' : 'rgba(13, 14, 19, 0.5)' }} />
            </Cell>
          </Grid>
        })}
    </ReorderInModal>
    {isEditComponentOpen && <EditComponent
      component={selectedHomeComponent}
      onClose={onClose}
      refetchHomeComponents={refetchHomeComponents}
      organization_id={organization_id}
      app_content_scope_id={appContentScopeId}
    ></EditComponent>}
  </>;
}

const mapStateToProps = props => {
  const { category, theme } = props.appBasicControls;
  return { category, defaultTheme: theme };
};
export default connect(mapStateToProps)(HomeComponents);