import * as React from 'react';
import { LabelLarge, LabelMedium, ParagraphSmall } from 'baseui/typography';
import { useStyletron } from 'baseui';
import 'react-toggle/style.css';
import { useMutation } from '@apollo/client';
import { Textarea } from 'baseui/textarea';
import { saveModalOnEnterPress, sourceByType } from '../../util';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { card_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import titlesLength from '../../../data/titlesLength.json';
import Toggle from 'react-toggle';
import ImageUploaderCXL from '../imageUploaderCXL';
import { slideImageGuideline } from '../help/imageSizeGuidelines.json';
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';
import { createMultimediaCardModule, updateMultimediaCardModule } from '../../query/multimedia_card_modules';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../redux/actions/appBasicControls';

const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

const VideoPlayerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 230px;
`

// BASIC CARD DESIGN
const BasicCardDesign = ({
  value,
  setValue,
  bgColor,
  onSave
}) => {
  const [css, theme] = useStyletron();
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={css({
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          top: theme.sizing.scale950,
        })}
      >
        <path
          d="M2.55 17.54L3.96 18.95L5.75 17.15L4.34 15.74L2.55 17.54ZM10 21.45H12V18.5H10V21.45ZM3 9.5H0V11.5H3V9.5ZM14 5.31V0.5H8V5.31C6.21 6.35 5 8.28 5 10.5C5 13.81 7.69 16.5 11 16.5C14.31 16.5 17 13.81 17 10.5C17 8.28 15.79 6.35 14 5.31ZM19 9.5V11.5H22V9.5H19ZM16.24 17.16L18.03 18.96L19.44 17.55L17.64 15.76L16.24 17.16Z"
          fill={bgColor}
        />
      </svg>
      <LabelLarge
        className={css({
          textAlign: 'center',
          marginTop: '72px',
          color: bgColor
        })}
      >
        {'Add Multimedia card'}
      </LabelLarge>
      <div className="addCard">
        {(
          <Textarea
            value={value}
            onChange={e => setValue(e.target.value.replace('\n', ''))}
            placeholder="Enter card title here"
            clearOnEscape
            className={css({
              position: 'absolute',
              width: 'auto',
              left: '14px',
              height: '92px',
              bottom: '16px',
              right: '14px',
            })}
            onKeyUp={e => {
              if (e.keyCode == 13) {
                onSave(value);
              }
            }}
          />
        )}
      </div>
    </>
  );
}

const Version1CardDesign = ({
  bgColor
}) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        position: 'absolute',
        width: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
      })}
    >
      <svg
        width="15"
        height="15.75"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={css({
          position: 'relative',
          left: '50%',
          transform: 'translateX(-50%)',
          cursor: 'pointer',
        })}
      // onClick={addCardOpen}
      >
        <path
          d="M32.7917 0.125H4.20833C1.94208 0.125 0.125 1.9625 0.125 4.20833V32.7917C0.125 35.0375 1.94208 36.875 4.20833 36.875H32.7917C35.0375 36.875 36.875 35.0375 36.875 32.7917V4.20833C36.875 1.9625 35.0375 0.125 32.7917 0.125ZM28.7083 20.5417H20.5417V28.7083H16.4583V20.5417H8.29167V16.4583H16.4583V8.29167H20.5417V16.4583H28.7083V20.5417Z"
          fill={bgColor}
        />
      </svg>
      <LabelLarge

        className={`add-new-card-label ${css({
          color: bgColor
        })}`}
      >
        {'Add Multimedia Card'}
      </LabelLarge>
    </div>
  );
}

const AddMultimediaCard = ({
  cardStyle = 'basis',
  orgId,
  position,
  home_component_id,
  card_id,
  onCloseModal = () => { },
  modalProps = {},
  refetchHomeComponents,
  cardData
}) => {

  const [css, theme] = useStyletron();

  const [title, setTitle] = React.useState(cardData?.title || '');
  const [description, setDescription] = React.useState(cardData?.description || '');
  const [body, setBody] = React.useState(cardData?.body || '');

  const [is_text, set_is_text] = React.useState(cardData?.is_text || false);
  const [is_video, set_is_video] = React.useState(cardData?.is_video || false);
  const [is_photo, set_is_photo] = React.useState(cardData?.is_photo || false);
  const [file, setFile] = React.useState();
  const [file_data, set_file_data] = React.useState(cardData?.file_data);
  const [file_url, setFileUrl] = React.useState();
  const [previewFile, setPreviewFile] = React.useState();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [addMultimediaCardModule, { loading: addCardLoading, data: createdCard }] = useMutation(
    createMultimediaCardModule
  );

  const [editMultimediaCardModule, { loading: updateCardLoading }] = useMutation(
    updateMultimediaCardModule
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log('cardData', cardData?.title)
    if (cardData?.id && cardData?.file_data) {
      const {
        file_data
      } = cardData
      const url = sourceByType(file_data, 'full');
      setFileUrl(url)
    }
    if (cardData?.id) {
      setTitle(cardData?.title || '');
      setDescription(cardData?.description || '');
      setBody(cardData?.body || '');

      set_is_text(cardData?.is_text || false);
      set_is_video(cardData?.is_video || false);
      set_is_photo(cardData?.is_photo || false);

      set_file_data(cardData?.file_data);
      openAddModal()
    }
  }, [cardData])


  const resetPageData = () => {
    setTitle('');
    setDescription('');
    setBody('');

    set_is_text(false);
    set_is_video(false);
    set_is_photo(false);

    setFile('');
    set_file_data('');
    setFileUrl('');

    setPreviewFile('');
    setDescription('');
    setIsModalOpen(false);
  }

  const isEdit = React.useMemo(() => {
    return cardData?.id ? true : false
  }, [cardData])

  const onSave = async () => {
    try {
      let d = {
        organization_id: orgId,
        is_photo,
        is_video,
        title,
        description,
        file,
        file_data,
        body,
        is_text,
        position,
        card_id,
        home_component_id
      };
      const { data } = await addMultimediaCardModule({
        variables: d,
      });
      resetPageData();
      refetchHomeComponents();
    } catch (err) {
      console.log('onSave error', err);
      dispatch(showAlert({
        msg: JSON.stringify(err),
        error: true,
      }))
    }
  };

  const onUpdate = async () => {
    try {
      let d = {
        id: cardData?.id,
        is_photo,
        is_video,
        title,
        description,
        file,
        file_data,
        body,
        is_text,
        card_id,
        home_component_id,
        position: cardData?.position
      };
      const { data } = await editMultimediaCardModule({
        variables: d,
      });
      resetPageData();
      refetchHomeComponents();
    } catch (err) {
      console.log('onSave error', err);
      dispatch(showAlert({
        msg: JSON.stringify(err),
        error: true,
      }))
    }
  };

  const onSaveClick = async () => {
    if (isEdit) {
      return onUpdate();
    } else {
      return onSave();
    }
  }



  const openAddModal = () => {
    setIsModalOpen(true)
  }

  const onClickSave = () => {
    onSaveClick();
    setTimeout(() => {
      onCloseModal();
    }, 1000);
  }

  const setDescriptionValue = e => setDescription(e.target.value.replace('\n', ''));

  const onDescriptionKeydown = e => saveModalOnEnterPress({ e, onEnterPress: onSave })

  const setBodyValue = e => setBody(e.target.value.replace('\n', ''));

  const onBodyKeydown = e => saveModalOnEnterPress({ e, onEnterPress: onClickSave })

  const onChangeCardTitle = e => {
    e.target.value.length <= titlesLength.card && setTitle(e.target.value.replace('\n', ''))
  }

  const onCardTitleKeyDown = e => saveModalOnEnterPress({ e, onEnterPress: onClickSave });

  const onChangeIsText = (e) => {
    set_is_text(e.target.checked);
    if (e.target.checked == true) {
      setFile();
      set_is_photo(false);
      set_is_video(false);
    }
  }

  const onChangeIsPhoto = (e) => {
    set_is_photo(e.target.checked);
    if (e.target.checked == true) {
      setFile();
      setPreviewFile();
      set_is_text(false);
      set_is_video(false);
    }
  }

  const onChangeIsVideo = (e) => {
    set_is_video(e.target.checked);
    if (e.target.checked == true) {
      setFile();
      setPreviewFile();
      set_is_text(false);
      set_is_photo(false);
    }
  }

  const onModalClose = () => {
    setIsModalOpen(false);
    onCloseModal();
    resetPageData();
  }

  const onSelectFile = (acceptedFiles, rejectedFiles) => {
    setFile(acceptedFiles);
    setPreviewFile(URL.createObjectURL(acceptedFiles[0]));
  }

  const onFileDelete = () => {
    setFile();
    setPreviewFile();
  }


  const CardStyle = React.useMemo(() => {
    const bgColor = '#5D64AB';
    if (cardStyle == 'Version1') {
      return (
        <Version1CardDesign
          value={title}
          setValue={setTitle}
          bgColor={bgColor}
          onSave={onClickSave}
        />
      )
    }
    return (
      <BasicCardDesign
        value={title}
        setValue={setTitle}
        bgColor={bgColor}
        onSave={onClickSave}
      />
    )
  }, [cardStyle]);

  const MultiMediaType = React.useMemo(() => {
    return (
      <Cell
        span={[1, 12]}
        overrides={{
          Cell: {
            style: () => ({
              paddingLeft: '0px !important',
            }),
          },
        }}
      >
        <LabelMedium style={{ marginBottom: '16px' }}>Multimedia Type</LabelMedium>
        <div
          className={
            css({
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            })
          }
        >
          <Toggle
            id="cheese-status"
            checked={is_text}
            defaultChecked={is_text}
            icons={false}
            onChange={onChangeIsText}
          />
          <LabelMedium
            marginLeft={'15px'}
          >
            Is Text
          </LabelMedium>
        </div>
        <div
          className={
            css({
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '10px'
            })
          }
        >
          <Toggle
            id="cheese-status"
            checked={is_photo}
            defaultChecked={is_photo}
            icons={false}
            onChange={onChangeIsPhoto}
          />
          <LabelMedium
            marginLeft={'15px'}
          >
            Is Photo
          </LabelMedium>
        </div>
        <div
          className={
            css({
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '10px'
            })
          }
        >
          <Toggle
            id="cheese-status"
            checked={is_video}
            defaultChecked={is_video}
            icons={false}
            onChange={onChangeIsVideo}
          />
          <LabelMedium
            marginLeft={'15px'}
          >
            Is Video
          </LabelMedium>
        </div>

      </Cell>
    )
  }, [is_text, is_photo, is_video])
  // console.log('AddMultimediaCard title', title)
  return (
    <>
      {
        !isEdit && (
          <div
            className='add-new-card-container-style'
            onClick={openAddModal}
          >
            {CardStyle}
          </div>
        )
      }

      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        onClose={onModalClose}
        closeable
        isOpen={isModalOpen}
        animate
        autoFocus
        {...modalProps}
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>
          <div
            className={css({
              display: 'flex',
            })}
          >
             {isEdit ? 'Update': 'Add'} Multimedia Card
            <HelpQuestionMark text={card_help}></HelpQuestionMark>
          </div>
        </ModalHeader>
        <ModalBody>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
              <Input
                value={title}
                onChange={onChangeCardTitle}
                placeholder="Enter card title here"
                clearOnEscape
                onKeyDown={onCardTitleKeyDown}
              />
              <ParagraphSmall style={{ marginBottom: '0px' }}>{title.length}/{titlesLength.card}</ParagraphSmall>
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Description</LabelMedium>
              <Textarea
                value={description}
                onChange={setDescriptionValue}
                placeholder="Enter description here"
                clearOnEscape
              // onKeyDown={onDescriptionKeydown}
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Body</LabelMedium>
              <Textarea
                value={body}
                onChange={setBodyValue}
                placeholder="Enter body here"
                clearOnEscape
                onKeyDown={onBodyKeydown}
              />
            </Cell>
            {
              is_video && (
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <ImageUploaderCXL
                    title={'Video'}
                    titleHelpText={'Select the video'}
                    isMultiple={false}
                    onDrop={onSelectFile}
                    onImageDelete={onFileDelete}
                    showFullBleedSettings={false}
                    imageSizeGuidelineURL={slideImageGuideline.url}
                  />
                  {
                    (previewFile || file_url) && (
                      <VideoPlayerContainer>
                        <ReactPlayer
                          // ref={(e) => ref(e, index)}
                          playing={true}
                          onPause={() => { }}
                          controls={true}
                          url={previewFile || file_url}
                          width="100%"
                          height="230px"
                          style={{ width: '100%', height: '230px', background: 'rgba(0,0,0,0.5)', zIndex: 98 }}
                        />
                      </VideoPlayerContainer>
                    )
                  }

                </Cell>
              )
            }
            {
              is_photo && (
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <ImageUploaderCXL
                    title={'Image'}
                    titleHelpText={'Select the Image'}
                    previewUrls={previewFile || file_url}
                    onDrop={onSelectFile}
                    onImageDelete={onFileDelete}
                    showFullBleedSettings={false}
                    imageSize={slideImageGuideline.size}
                    imageSizeGuidelineURL={slideImageGuideline.url}
                  />
                </Cell>
              )
            }

            {MultiMediaType}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            isLoading={addCardLoading || updateCardLoading}
            onClick={onClickSave}
          >
            {isEdit ? 'Update': 'Save'}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  )

}

export default AddMultimediaCard