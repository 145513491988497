
import * as React from 'react';
import ReactPlayer from 'react-player';
import { MdPlayCircleOutline, MdOutlineClose } from 'react-icons/md'
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from '@emotion/styled';
import { Spinner } from 'baseui/spinner';
import { Button } from 'baseui/button';
import { Tag } from "baseui/tag";
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';
import { debounce } from 'lodash';


const CloseIcon = styled(MdOutlineClose)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  z-index: 1002; 
`;

const VideoContainer = styled.div`
  position: fixed;
  width: 98%;
  height: 96%;
  background: white;
  margin: 1rem;
  left: 0;
  top: 0;
  z-index: 1002;
  overflow: hidden;
`
const VideoBackDropContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  left: 0;
  top: 0;
  z-index: 1001;
  overflow: hidden;
`

const VideoCard = styled.div`
  width: 360px;
  height: auto;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 10px;
  margin: 14px;
  padding: 5px;
  float: left;
  
`

const VideoPlayerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 230px;
`

const VideoMetaDataContainer = styled.div`
  height: auto;
  padding: 0.5rem;
  position: relative;
`

const VideoPlayIcon = styled(MdPlayCircleOutline)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1000
`

const VideoPlayerBackDropContainer = styled.div`
  position: absolute;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  z-index: 99;
`

const VideoItemComponent = ({
  item,
  index,
  onStartVideo,
  onPauseVideo,
  playingIndex,
  onSelectVideo,
  ...props
}) => {

  const onClickStart = () => {
    onStartVideo(index, url)
  }

  const onClickPause = () => {
    onPauseVideo(index)
  }

  const onClickSelect = () => {
    onSelectVideo(item)
  }

  const renderPlayerContainer = () => {
    const isPlaying = playingIndex === index
    return (
      <VideoPlayerContainer>
        {isPlaying == false ? <>
          <VideoPlayerBackDropContainer />
          <VideoPlayIcon
            size={43}
            color='white'
            onClick={onClickStart}
          />
        </> : null}
        <ReactPlayer
          // ref={(e) => ref(e, index)}
          playing={isPlaying}
          onPause={onClickPause}
          controls={true}
          url={url}
          width="100%"
          height="230px"
          style={{ width: '100%', height: '230px', position: 'absolute', background: 'rgba(0,0,0,0.5)', zIndex: 98 }}
        />
      </VideoPlayerContainer>
    )
  }

  const videoDuration = React.useMemo(() => {
    return item.duration ? parseInt(item.duration) + ' seconds' : '';
  }, [item])

  // const isPlaying = React.useMemo(() => {
  //   return playingIndex == index
  // }, [playingIndex, index]);

  const tags = React.useMemo(() => {
    return item.tags && item.tags?.length > 0 ? item.tags : [];
  }, [item])


  const labelName = React.useMemo(() => {
    const { label } = item;
    return !label ? 'Data connection lost' : label.length > 28 ? label.substring(0, 28) + '...' : label
  }, [item])
  const {
    url
  } = item;

  console.log('VideoItemComponent', { labelName, isPlaying: playingIndex == index })
  return (
    <VideoCard key={index}>
      {renderPlayerContainer()}
      {/* 
        //playing debugging
        <p>{`isPlaying: ${playingIndex == index}`}</p>  
        <p>{`playingIndex: ${playingIndex}`}</p>
        <p>{`index: ${index}`}</p> 
      */}
      <VideoMetaDataContainer>
        <p style={{ fontSize: '19px', fontWeight: 600, marginBottom: '0.7rem', color: 'black' }}>{labelName}</p>
        <p style={{ marginBottom: '0.5rem', color: 'black' }}>{item.description || ''}</p>
        <p style={{ marginBottom: '0.5rem', color: 'black' }}>{videoDuration}</p>
        <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', bottom: 0, width: '95%' }}>
          <div style={{ width: '100%' }}>
            <div style={{
              display: 'flex',
              flexWrap: 'wrap'
            }}>{tags.map(item => (<Tag closeable={false} variant="solid">{item}</Tag>))}</div>
          </div>
          <div style={{ width: '100%', marginTop: '16px' }}>
            <Button
              style={{ borderRadius: '4px' }}
              onClick={onClickSelect}
            >
              Select Video
            </Button>
          </div>
        </div>
      </VideoMetaDataContainer>
    </VideoCard>
  )
}

const VideoListComponent = ({ ...props }) => {
  // console.log('Video item data______', props)
  const cardNumberPerPage = 8
  const [data, setData] = React.useState([]);
  // console.log('data', data);
  const [options, setOptions] = React.useState([])
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [playingIndex, setPlayingIndex] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);

  const [searchText, setSearchText] = React.useState('');

  const debounceFetch = React.useCallback(
    // debounce(nextValue => {
    //   console.log('nextValue', nextValue);
    //   return fetchAndSetOptions(nextValue)
    // }, 1000),
    debounce(fetchAndSetOptions, 1000),
    [], // will be created only once initially
  );

  const onClose = () => {
    props.onClose()
  };

  const onStartVideo = async (index, id) => {
    setPlayingIndex(index);
  }

  const onPauseVideo = (index) => {
    if (playingIndex === index) {
      setPlayingIndex(null);
    }
  }

  const onSelectVideo = (selectedItem) => {
    props.onSelectVideo(selectedItem)
    props.onClose()
  }

  const infiniteScrollLoader = () => {
    if (!hasMore) return;
    return (
      <div style={{ width: '90vw', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2rem' }}>
        <Spinner />
      </div>
    )
  };

  async function fetchAndSetOptions({ page, data, options }) {
    setLoading(true)
    setHasMore(true)
    // console.log('pulling data', page, data);
    let s = (page - 1) * cardNumberPerPage;
    let end = s + cardNumberPerPage;
    console.log('setHasMore', data.length, options.length);
    if (page === 1 && data.length <= 0) {
      setOptions([]);
      setLoading(false);
      return setHasMore(false)
    }
    else if (data.length == options.length) {
      setLoading(false);
      return setHasMore(false)
    }
    let next = [];
    for (var i = s; i < end; i++) {
      if (data[i])
        next.push(data[i]);
    };
    // debugger;
    // if (props.videoType?.toLowerCase() == 'jwplayer') {
    next = await Promise.all(next.map(async n => {
      const apiUrl = "https://cdn.jwplayer.com/v2/media/" + n.id;
      const videoResult = await fetch(apiUrl);
      const videoData = await videoResult.json();

      try {
        let o1 = videoData.playlist && videoData.playlist[0].sources.find(el => {
          return el.type.includes('video');
        })
        n.url = o1.file;
      } catch (e) {

      }
      try {
        const image = videoData.playlist && videoData.playlist[0].image || '';
        n.image = image;
      } catch (e) {

      }
      return n;
    }));
    // }
    console.log(options.length, 'ppppp');
    setOptions([...options, ...next]);
    setLoading(false);
  };

  React.useEffect(() => {
    debounceFetch({ page, data, options });
  }, [page]);

  React.useEffect(() => {
    // console.log('hittttt........');
    let p = page;
    let d = [];
    if (searchText.trim()) {
      d = (
        props.data.filter(el => el.label.toLowerCase().includes(searchText.toLowerCase())).sort((a, b) => a.label.localeCompare(b.label))
      )
      p = 1
    } else {
      d = (props.data.sort((a, b) => a.label.localeCompare(b.label)));
      p = 1
    };
    setPage(p);
    setData(d);
    debounceFetch({ page: p, data: d, options: [] });
  }, [searchText]);


  const EmptyVideoList = () => {
    if (options.length > 0 || loading === true) {
      return null;
    }
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          textAlign: 'center',
          marginTop: '20%'
        }}
      >
        No results found!
      </div>
    )
  }

  const videoListOptions = React.useMemo(() => {
    return options.sort((a, b) => a.label.localeCompare(b.label));
  }, [options])

  return (
    <>
      <VideoContainer>
        <div style={{ position: 'relative', }}>
          <CloseIcon color='black' size={20} onClick={onClose} />
          <div style={{
            padding: '16px',
            paddingRight: '64px',
            paddingBottom: '0px',
            width: '60%',
            marginLeft: '38px'
          }}>
            <Input
              endEnhancer={<Search size="18px" />}
              placeholder="Search video here..."
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>
          <EmptyVideoList />
          <div id="scrollableVideoContainer" style={{ width: '100%', height: '90vh', overflow: 'auto', padding: '1rem', display: 'flex' }}>
            <InfiniteScroll
              dataLength={options.length}
              next={() => {
                // console.log('next callled');
                if (loading || !hasMore) { return; }
                setLoading(true);
                let p = page;
                setPage(++p);
              }}
              style={{
                display: 'flex', flexWrap: 'wrap',
                // width: '100%',
                height: '90vh',
                minWidth: '500px',
                margin: '1rem auto',
                padding: '1rem',
                justifyContent: 'center'
              }}
              hasMore={hasMore}
              // loader={infiniteScrollLoader()}
              // scrollableTarget={'scrollableVideoContainer'}
              height={'90vh'}
            >
              {/* {options.sort((a, b) => a.label.localeCompare(b.label)).map((item, index) => {
                const { label, id, url, tags } = item;
                const labelName = !label ? 'Data connection lost' : label.length > 28 ? label.substring(0, 28) + '...' : label
                return (
                  <VideoCard key={index}>
                    <VideoPlayerContainer>
                      {playingIndex != index && <>
                        <VideoPlayerBackDropContainer />
                        <VideoPlayIcon
                          size={43}
                          color='white'
                          onClick={() => onStartVideo(index, url)}
                        />
                      </>}
                      <ReactPlayer
                        // ref={(e) => ref(e, index)}
                        playing={playingIndex == index}
                        onPause={e => onPauseVideo(index)}
                        controls={true}
                        url={url}
                        width="100%"
                        height="230px"
                        style={{ width: '100%', height: '230px', position: 'absolute', background: 'rgba(0,0,0,0.5)', zIndex: 98 }}
                      />
                    </VideoPlayerContainer>
                    <VideoMetaDataContainer>
                      <p style={{ fontSize: '19px', fontWeight: 600, marginBottom: '0.7rem', color: 'black' }}>{labelName}</p>
                      <p style={{ marginBottom: '0.5rem', color: 'black' }}>{item.description || ''}</p>
                      <p style={{ marginBottom: '0.5rem', color: 'black' }}>{item.duration ? parseInt(item.duration) + ' seconds' : ''}</p>
                      <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', bottom: 0, width: '95%' }}>
                        <div style={{ width: '100%' }}>
                          <div style={{
                            display: 'flex',
                            flexWrap: 'wrap'
                          }}>{item.tags?.length > 0 ? item.tags.map(item => (
                            // <span style={{ fontSize: '9px', background: 'black', color: 'white', border: '1px solid #eee', padding: '4px', borderRadius: '8px', marginRight: '10px', marginBottom: '4px', minWidth: '50px', textAlign: 'center' }}>
                            //   {item}</span>
                            <Tag closeable={false} variant="solid">{item}</Tag>
                          )
                          ) : ''}</div>
                        </div>
                        <div style={{ width: '100%', marginTop: '16px' }}>
                          <Button
                            style={{ borderRadius: '4px' }}
                            onClick={e => onSelectVideo(item)}
                          >
                            Select Video
                          </Button>
                        </div>
                      </div>
                    </VideoMetaDataContainer>
                  </VideoCard>
                )
              })} */}
              {
                videoListOptions.map((item, index) => (
                  <VideoItemComponent
                    item={item}
                    index={index}
                    playingIndex={playingIndex}
                    onStartVideo={onStartVideo}
                    onPauseVideo={onPauseVideo}
                    onSelectVideo={onSelectVideo}
                  />
                ))
              }
              {loading && infiniteScrollLoader()}
            </InfiniteScroll>
          </div>
        </div>
      </VideoContainer>
      <VideoBackDropContainer />
    </>
  )
}

export default VideoListComponent