import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import axios from 'axios';
import { Input } from 'baseui/input';
import { LabelLarge, LabelMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { Select } from 'baseui/select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { sourceByType } from '../../util';
import { slideImageGuideline } from '../help/imageSizeGuidelines.json';
import ImageUploadCXL from '../imageUploaderCXL';

const getSrc = data => {
  if (!data) return data;
  return sourceByType(data, 'small');
};

export default ({ defaultTheme, refetchLibraries, library, tags, site_id, api_secret, onClose, organization_id }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [css, theme] = useStyletron();
  const [title, setTitle] = React.useState(library ? library.title : '');
  const [description, setDescription] = React.useState(library ? library.description : '');
  const [image, setImage] = React.useState(getSrc(library ? library.library : null));
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [videoFile, setVideoFile] = React.useState(null);
  const [tag_id, setTagId] = React.useState(null)
  const id = library ? library.id : null;

  const fileReader = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(reader.result)
      };

      reader.readAsArrayBuffer(file)
    })
  }

  const uploadVideoToJWPlayer = async (site_id='6D82ANzG', v2_api_secret='S7DrvCgOq0STREH15SoP7mInTVVwdllrRmtWMVpoZVZWTE5tdElUa3M1VDBkTFZHeEYn') => {
    const maxFileSize = 100 * 1024 * 1024 // 100MB
    const fileSize = videoFile[0].size  * 1024 * 1024;
    const fileType = videoFile[0].type
    const uploadMethod = fileSize > maxFileSize ? "multipart" : "direct"
    const bodyParams =  { "upload": { "method": uploadMethod, "mime_type": fileType,},  "metadata": {"title": title || "My Multipart Upload Video", description} };
    try {
      const request = await axios({
          url: `https://api.jwplayer.com/v2/sites/${site_id}/media`,
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${v2_api_secret}`,
            'Content-Type': 'application/json'
          },
          data: bodyParams
      })
      const data = await fileReader(videoFile[0]);
      if (uploadMethod === "direct") {
        const media_id = request.data.id;
        const uploadLink = request.data.upload_link;
        const upload = await axios({
          url: uploadLink,
          method: 'PUT',
          headers: {
            'Content-Type': fileType,
          },
          data
        });
        onFinish();
      } else if (uploadMethod === "multipart") {
        const upload_id = request.data.upload_id;
        const upload_token = request.data.upload_token;
        const numberOfLength = Math.ceil(fileSize / 1024 / 1024 /1024 / 1024 / 5);
        const first = await axios({
          url: `https://api.jwplayer.com/v2/uploads/${upload_id}/parts?page=1&page_length=${numberOfLength}`,
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${upload_token}`,
          },
        });

        const links = first.data.parts.map(i => i.upload_link);
        await Promise.all(links.map(async (url, index) => {
          return await axios({
            url: url + `&bs=5M&count=1&skip=${index}`,
            method: 'PUT',
            headers: {
              'Content-Type': '',
            },
            data
          })
        }))
        await axios({
          url: `https://api.jwplayer.com/v2/uploads/${upload_id}/complete`,
          method: 'PUT',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${upload_token}`,
          },
        });
        onFinish();
      }
    } catch (error) {
      console.log('File Upload Error: ', error)
    }
  }

  const onFinish = () => {
    refetchLibraries();
    setIsLoading(false);
    setIsOpen(false);
    onClose();
  }

  const onSave = (req = {}) => {
    if (!title || !videoFile) {
        return;
    }
    setIsLoading(true);
    uploadVideoToJWPlayer(site_id, api_secret);
  };

  React.useEffect(() => {
    setIsLoading(false);
  }, []);

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  const onOptionChange = e => {
    setTagId(e.option.id)
  }

  const bgColor = defaultTheme === 'dark' ? '#5D64AB' : '#5D64AB';

  return (
        <React.Fragment>
          <Modal
            overrides={{
              Backdrop: {
                style: ({ $theme }) => ({
                  backdropFilter: $theme.modalBackDropFilter,
                }),
              },
              Root: {
                style: {
                  zIndex: 3,
                }
              },
            }}
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
          >
            <ModalHeader>{}</ModalHeader>
            <ModalBody style={{ flex: '1 1 0' }}>
              <Grid
                gridGaps={[12, 12, 24]}
                overrides={{
                  Grid: {
                    style: ({ $theme }) => outerGridOverride,
                  },
                }}
              >
                <div
                  className={css({
                    display: 'flex',
                  })}
                >
                  <LabelLarge style={{ marginBottom: '16px' }}>Add Library</LabelLarge>
                </div>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
                  <Input
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    placeholder="Video Title"
                    clearOnEscape
                  />
                </Cell>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Description</LabelMedium>
                  <Input
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Video Description"
                    clearOnEscape
                  />
                </Cell>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>Tag</LabelMedium>
                  <Select
                    options={tags}
                    onChange={onOptionChange}
                    value={tags.find(i => i.id == tag_id)}
                    placeholder={tag_id ? '' : 'Select tag'}
                    multi={false}
                    labelKey={'name'}
                    valueKey={'id'}
                    overrides={{
                      Popover: {
                        props: {
                          overrides: {
                            Body: { style: { zIndex: 3 } },
                          },
                        },
                      },
                    }}
                  />
                </Cell>

                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <ImageUploadCXL
                    title={'Video library'}
                    titleHelpText={'Select the video for library'}
                    isMultiple={false}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                        console.log('Accepted files: ', acceptedFiles)
                        setVideoFile(acceptedFiles);
                    //   setPreview(URL.createObjectURL(acceptedFiles[0]));
                    }}
                    onImageDelete={() => {
                      setVideoFile(null);
                    //   setPreview(null);
                      setImage(null)
                    }}
                    showFullBleedSettings={false}
                    // imageSize={slideImageGuideline.size}
                    imageSizeGuidelineURL={slideImageGuideline.url}
                  ></ImageUploadCXL>
                </Cell>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <ModalButton
                onClick={() => {
                  onClose();
                }}
              >
               Cancel
              </ModalButton>
              <ModalButton
                isLoading={isLoading}
                onClick={onSave}
              >
                Add JWPlayer Video
          </ModalButton>
            </ModalFooter>
          </Modal>
        </React.Fragment>
  );
};


