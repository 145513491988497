import * as React from 'react';
import BaseClass from '../../builder/cardBackEditorComponents/base';
import ChannelPickerCXL from '../../channelPickerCXL';

const ChannelPicker = ({
  title,
  theme,
  placeholder,
  value,
  onChange = () => { },
  multi = false,
  isEditor = false
}) => {

  const onCardSelect = (selectedChannelId) => {
    onChange(selectedChannelId);
  };
  return (
    <ChannelPickerCXL
      selectedChannelId={value}
      onChannelSelect={onCardSelect}
      title={title || ''}
      theme={theme}
      placeholder={placeholder}
      multi={multi}
      isEditor={isEditor}
    />
  )
}

export default ChannelPicker;