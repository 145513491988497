import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { LabelLarge, LabelMedium, ParagraphSmall } from 'baseui/typography';
import { useStyletron } from 'baseui';
import ReactPlayer from 'react-player';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { Select } from 'baseui/select';
import CardPickerCXL from '../modules/generic_modules/cardPickerCXL';
import { getCreateMutationsForHomeComponent } from '../builder/builderUtils/save';
import { client } from '../../apollo/client';
import ImageUploadCXL from '../imageUploaderCXL'
import { getFilesUrls } from '../../util'
import SearchableCheckList from '../modules/generic_modules/searchableCheckList';
import { SelectCXL } from '../builder/cardBackEditorComponents/select';
import ColorPicker from '../common/ColorPicker';
import CustomFieldPicker from '../customFieldPicker';
import { Checkbox } from 'baseui/checkbox';
import ChannelPickerCXL from '../channelPickerCXL';
import Textarea from '../channel/textarea';
import { showAlert } from '../../redux/actions/appBasicControls';
import { Textarea as BaseUiTextArea } from "baseui/textarea";
import store from '../../redux/store';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import AdvanceTextAccordion from '../modules/generic_modules/advanceTextAccordion';
import ActionButtonObject from '../modules/generic_modules/ActionMetadataSingleObject';
import DateTimeRangePicker from '../modules/generic_modules/dateTimeRangePicker';
import LocationModulePicker from '../modules/generic_modules/locationModulePicker';
import TagsPicker from '../modules/generic_modules/tagsPicker';
import { DatePicker } from "baseui/datepicker";
import { Search } from 'baseui/icon';
import { cellPaddingOverrides } from '../overrides';
import Button from '../styledButton';
import ActionMetadataInput from '../modules/generic_modules/ActionMetadataInput';
import GenericModuleRenderer from '../modules/generic_module_renderer';


// selectedComponentType is the object from one of the card builder options
export default ({
  position, organization_id,
  home_component_id, defaultTheme,
  refetchHomeComponents, selectedComponentType,
  jwPlayerData, libraries, dispatch,
  onSearch = () => { }, searchText }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState([])
  const [css, theme] = useStyletron();
  const { display, inputs, __typename } = selectedComponentType;
  const { is_card_link_button_css_on } = store.getState().appBasicControls.organization_settings || {};
  const [state, setStateInternal] = React.useState({});
  const [selectedItem, setSelectedItem] = React.useState(null)

  const setState = (key, value) => {
    let data = { ...state };
    data[key] = value;
    setStateInternal(data);
  };

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };


  const onSave = () => {
    setIsLoading(true);
    const mutation = getCreateMutationsForHomeComponent(selectedComponentType);
    client.mutate({
      mutation,
      variables: {
        organization_id,
        home_component_id,
        position,
        ...state
      }
    }).then(async (result) => {
      console.log(result);
      await refetchHomeComponents();
      dispatch(
        showAlert({
          msg: 'Successfully saved data!',
          error: false,
        })
      );
      onClose();
    })
  };

  const onClose = () => {
    setIsLoading(false);
    setStateInternal({});
    setIsOpen(false);
  }

  React.useEffect(() => {
    setOptions([]);
    setState('youtube_video_id', '')
  }, [state['video_type']])

  React.useEffect(() => {
    setStateInternal(inputs.reduce((a, b) => {
      a[b.key] = b.dataType == 'Boolean' ? false : '';
      return a;
    }, {}))
  }, [inputs]);

  React.useEffect(() => {
    getMediaList()
  }, []);

  const getMediaList = () => {
    if (isOpen && jwPlayerData && jwPlayerData.jwplayers_org && jwPlayerData.jwplayers_org.length > 0) {
      const { app_key, app_secret } = jwPlayerData.jwplayers_org[0];
      const apiUrl = `https://api.jwplayer.com/v2/sites/${app_key}/media/?page_length=1000`;
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${app_secret}` },
      };
      setIsLoading(true)
      fetch(apiUrl, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data && data.media) {
            const nextOptions = data.media.map(item => ({ id: item.id, label: item.metadata.title }))
            setOptions(nextOptions)
            setIsLoading(false);
          }
        })
        .catch(e => {
          setOptions([]);
          setIsLoading(false)
        });
    }
  }
  let url = null;
  if (selectedItem && state['video_type'] == 'Library' && options.length > 0) {
    const findItem = options.find(el => el.id == selectedItem);
    url = findItem && findItem.library ? JSON.parse(findItem.library).metadata.url : null;
  }

  if (!isLoading && options.length == 0 && state['video_type'] == 'JWPlayer') getMediaList();
  if (options.length == 0 && state['video_type'] == 'Library') {
    if (libraries && libraries.libraries && libraries.libraries.length > 0) {
      let nextOptions = [];
      libraries.libraries.map(item => {
        if (item.type == 'video') {
          nextOptions.push({ id: item.id, label: item.title, library: item.library });
        }
      });
      setOptions(nextOptions);
    }
  }

  return (
    <React.Fragment>
      <Cell overrides={cellPaddingOverrides} span={12} >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '20px' }}>
          <div style={{ width: '500px' }}>
            <Input
              startEnhancer={<Search
                className={css({
                  color: 'black !important',
                  cursor: 'pointer',
                })}
                size={20} />}
              value={searchText}
              onChange={onSearch}
              clearOnEscape
              placeholder='Search Locations'
            />
          </div>
          {/* <LabelMedium style={{ marginLeft: '10px' }}>Browse Tags</LabelMedium> */}
          <Button
            marginTop='0px'
            isSelected
            onClick={() => {
              setIsOpen(true);
            }}>Add</Button>
        </div>
      </Cell>
      <Modal
        // animate={true}
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Root: {
            style: {
              // zIndex: 3,
            }
          },
          Dialog: {
            style: { width: selectedComponentType.__typename == 'TableCardModule' ? '80vw' : '50vw' }
          }
        }}
        isOpen={isOpen}
        onClose={onClose}
        returnFocus={false}
      >
        <ModalHeader>{ }</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              }
            }}
          >
            <div
              className={css({
                display: 'flex',
              })}
            >
              <LabelLarge style={{ marginBottom: '16px' }}>Add {display}</LabelLarge>
              {/* <HelpQuestionMark text={slide_help}></HelpQuestionMark> */}
            </div>
            {
              inputs.map(el => {
                const {
                  key
                } = el;
                return (
                  <GenericModuleRenderer
                    input={el}
                    state={state}
                    theme={theme}
                    organization_id={organization_id}
                    onChange={(key, v) => {
                      setState(key, v);
                    }}
                    setStateInternal={setStateInternal}
                    root={selectedComponentType}
                    isAddHomeComponent={true}
                    jwPlayerData={jwPlayerData}
                    libraries={libraries}
                    is_card_link_button_css_on={is_card_link_button_css_on}
                    __typename={__typename}

                    onSave={onSave} /// FormInputListDB prop
                    selectedComponentType={selectedComponentType} /// FormInputListDB prop
                    refetchHomeComponents={refetchHomeComponents} /// FormInputListDB prop
                    onClose={onClose} /// FormInputListDB prop
                    dispatch={dispatch} /// FormInputListDB prop
                  />
                )
              })
            }
            {/* {inputs.map((el) => {
              const { title, placeholder, type, key, typeToPick, mutation, isVisible, visibilityParameter, HideInAdd, extraStateKeyToUpdate } = el;
              if (isVisible) {
                const visibilityVal = state[visibilityParameter]
                if (isVisible(visibilityVal) === false) {
                  return null;
                }
              } else if (HideInAdd) {
                return null;
              }
              if ((key == 'style' || key == 'width' || key == 'icon') && !is_card_link_button_css_on && __typename == 'CardLinkButtonCardModule') return;
              return <>
                <Cell
                  span={[1, 12]}
                  overrides={{
                    Cell: {
                      style: () => ({
                        paddingLeft: '0px !important',
                      }),
                    },
                  }}
                >
                  <LabelMedium style={{ marginBottom: '16px' }}>{title}</LabelMedium>

                  {type == 'Input' && <Input
                    value={state[key]}
                    onChange={e => setState(key, e.target.value)}
                    placeholder={placeholder}
                    clearOnEscape
                  />}
                  {type == 'VideoIdOrURLPicker' && (state['video_type'] == 'JWPlayer' || state['video_type'] == 'Library') && (
                    <Select
                      isLoading={isLoading}
                      options={options}
                      onChange={({ value }) => {
                        console.log('Value: ', value)
                        setState(key, value ? value[0].id : null)
                        setSelectedItem(value ? value[0].id : null)
                      }}
                      overrides={{
                        Popover: {
                          props: {
                            overrides: {
                              Body: { style: { zIndex: 3 } },
                            },
                          },
                        },
                      }}
                      value={state[key] ? options.filter(i => i.id == state[key]) : null}
                    />
                  )}
                  {(type == 'VideoIdOrURLPicker' && (state['video_type'] !== 'JWPlayer' && state['video_type'] !== 'Library')) && (
                    <Input
                      value={state[key]}
                      onChange={e => setState(key, e.target.value)}
                      placeholder={placeholder}
                      clearOnEscape
                    />
                  )}

                  {url && type == 'VideoIdOrURLPicker' && state['video_type'] == 'Library' && (
                    <div style={{ width: '100%', margin: 'auto', paddingTop: '1rem' }}>
                      <ReactPlayer controls={true} url={url} width="100%" height="260px" />
                    </div>
                  )}

                  {type == 'Checkbox' &&
                    <Checkbox
                      checked={state[key]}
                      onChange={e => setState(key, e.target.checked)}
                    >
                      <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>{placeholder}</ParagraphSmall>
                    </Checkbox>
                  }

                  {type == 'CardPicker' && <CardPickerCXL
                    selectedCardId={state[key]}
                    onCardSelect={(id) => {
                      setState(key, id)
                    }}
                    title={''}
                    theme={theme}
                    marginTop={'0px'}
                    placeholder={'Select Card'}
                  />}
                  {type == 'FilePicker' && <ImageUploadCXL
                    title='Title'
                    titleHelpText='Helper text'

                    onDrop={(acceptedFiles, rejectedFiles) => {
                      setState(
                        mutation ? mutation.key : key, acceptedFiles, acceptedFiles
                      );
                    }}
                    previewUrls={mutation ? (getFilesUrls(state[mutation.key]) || getFilesUrls(state[key])) : getFilesUrls(state[key])}
                  ></ImageUploadCXL>}

                  {type == 'Select' && <SelectCXL
                    {...el}
                    onChange={(v) => {
                      setState(key, v);
                    }}
                    theme={theme}
                    value={state[key]}
                    overrides={{
                      Popover: {
                        props: {
                          overrides: {
                            Body: { style: { zIndex: 3 } },
                          },
                        },
                      },
                    }}
                  ></SelectCXL>}

                  {type == 'TableColumnPicker' && <SearchableCheckList
                    google_sheet_integration_id={state['google_sheet_integration_id']}
                    onChange={(value) => {
                      setState(key, JSON.stringify(value));
                    }}
                    typeToPick={typeToPick}
                    value={state[key] == '' ? [] : typeof state[key] == 'string' ? JSON.parse(state[key]) : state[key]}
                  ></SearchableCheckList>}

                  {type == 'CustomFieldPicker' && <CustomFieldPicker
                    selectCustomFieldId={state[key]}
                    onFieldSelect={(id) => {
                      setState(key, id)
                    }}
                    placeholder="Select custom field"
                  ></CustomFieldPicker>}
                  {type == 'ChannelPicker' &&
                    <ChannelPickerCXL
                      selectedChannelId={state[key]}
                      onChannelSelect={(id) => {
                        setState(key, id)
                      }}
                      title={''}
                      theme={theme}
                      marginTop={'0px'}
                      placeholder={'Select Channel'}
                    />}
                  {type == 'Textarea' && <Textarea
                    value={state[key]}
                    onChange={(value) => {
                      setState(key, value)
                    }}
                    textAreaKey={key}
                  >
                  </Textarea>} */}

            {/* {type == 'BaseUiTextArea' &&
                    // <BaseUiTextArea
                    //   value={state[key]}
                    //   onChange={e => {
                    //     //this.setState({ value: e.target.value })
                    //     setState(key, e.target.value )
                    //   }}
                    //   // placeholder={this.props.placeholder}
                    //   clearOnEscape
                    // />
                    <JSONInput
                      id='a_unique_id'
                      placeholder={state[key] ? JSON.parse(state[key]) : {}}
                      theme="dark_vscode_tribute"
                      colors={{
                        string: "#DAA520" // overrides theme colors with whatever color value you want
                      }}
                      locale={locale}
                      height='550px'
                      width={'40vw'}
                      onChange={e => {
                        // console.log(e);
                        !e.error && setState(key, JSON.stringify(e.jsObject));
                      }}
                    />
                  }

                  {
                    type == 'AdvanceTextAccordion' && <AdvanceTextAccordion
                      values={state[key]}
                      onChange={(v) => {
                        setState(key, v);
                      }}
                    />
                  }

                  {
                    type == 'ActionMetadataInput' && <ActionMetadataInput
                      action_type={state['button_action_type']}
                      action_resource={state['button_action_metadata']}
                      onChange={(v) => {
                        setState(key, v);
                      }}
                      theme={theme}
                    />
                  }

                  {
                    type == 'ActionButtonObject' && <ActionButtonObject
                      action_metadata={state[key]}
                      onChange={(v) => {
                        setState(key, v);
                      }}
                      theme={theme}
                    />
                  }


                  {type == 'ColorPicker' && (
                    <ColorPicker color={state[key]} onChange={color => setState(key, color)} />
                  )}

                  {
                    type == 'DateTimeRange' && (
                      <DateTimeRangePicker
                        values={{
                          d: state[key],
                          s: state[extraStateKeyToUpdate[0]],
                          e: state[extraStateKeyToUpdate[1]],
                        }}
                        onChange={(res) => {
                          console.log('date change', res, key, extraStateKeyToUpdate);
                          const [d, s, e] = res;
                          console.log(d, s, e);
                          let data = { ...state };
                          data[key] = d;
                          data[extraStateKeyToUpdate[0]] = s;
                          data[extraStateKeyToUpdate[1]] = e;
                          setStateInternal(data);
                        }}
                      />
                    )
                  }

                  {type == 'LocationModulePicker' && (
                    <LocationModulePicker
                      ids={state[key]}
                      onChange={(v) => {
                        setState(key, v)
                      }}
                    />
                  )}

                  {type == 'TagsPicker' && (
                    <TagsPicker
                      ids={state[key]}
                      onChange={(v) => {
                        setState(key, v)
                      }}
                    />
                  )}

                  {type == 'Date' && (
                    <DatePicker
                      value={[state[key] ? new Date(state[key]) : new Date()]}
                      onChange={({ date }) =>
                        setState(key, date[0])
                      }
                      timeSelectStart
                      formatString={'yyyy-mm-dd HH:mm'}
                    />
                  )}
                </Cell>
              </>;
            })} */}
          </Grid>
        </ModalBody>
        <ModalFooter>

          <ModalButton
            isLoading={isLoading}
            onClick={() => {
              onSave();
            }}
          >
            Add
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};


