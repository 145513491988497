import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import gql from 'graphql-tag';

import { import_card_help, import_form_help } from "../help/helpTexts";
import HelpQuestionMark from '../help/questionMarkHelp';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { LabelSmall } from "baseui/typography";
import { importCopyCard } from "../../quries";
import { setOrg, showAlert } from "../../redux/actions/appBasicControls";
import { Skeleton } from "baseui/skeleton";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import styled from "styled-components";
import { withTheme } from "@emotion/react";
import { getSrc } from "../../util";
import { moveFormTemplateToHomeComponent, sharepoint_form_templates } from "../templates/queries";
import { client } from "../../apollo/client";
import { organization as organizationQuery } from '../../quries';


const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
  maxHeight: '70vh',
  overflowY: 'auto'
};

const MainCardDiv = styled.div`
  background-image: ${props => props.src};
  position: relative;
  border: 3px solid #4A4CA0;
  width: 100%;
  height: 40vh;
  background-size: ${props => props.full_bleed ? 'cover' : 'contain'};
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  padding: 10px;
`;

const ImportFormModal = ({
  isModalOpen = false,
  modalProps = {},
  onSuccessfullyImport = () => { },
  onCloseModal = () => { },
  forms,
  home_component_id
}) => {
  const [css, theme] = useStyletron();
  const dispatch = useDispatch();
  const organization = useSelector(state => state.appBasicControls.organization);
  const [fetchFormTemplates,{ data: formTemplates, loading: formTemplatesLoading }] = useLazyQuery(sharepoint_form_templates,
    {
      variables: {
        organization_id: organization.id
      },
      fetchPolicy: 'network-only'
    }
  );
  const [selectedForm, setSelectedForm] = useState();
  const [listMode, setListMode] = useState('list_view');

  const [
    importCard,
    {
      data: importedFormData,
      loading: loadingImportedForm
    }
  ] = useMutation(moveFormTemplateToHomeComponent)

  useEffect(() => {
    if (isModalOpen) {
      fetchFormTemplates();
    }
  },[isModalOpen])

  //////////////////////////////////////////// Event Function //////////////////////////////////////////////////////////

  const onClickImport = async () => {
    try {
      if (!selectedForm || selectedForm == null) {
        dispatch(showAlert({
          msg: 'Please select a form',
          error: true,
        }))
        return;
      }
      const res = await importCard({
        variables: {
          sharepoint_form_id: selectedForm.id,
          home_component_id,
          position: forms.length
        }
      });

      dispatch(showAlert({
        msg: 'Form successfully imported ',
        error: false,
      }));
      fetchIntegrations();
      onSuccessfullyImport();
    } catch (err) {
      console.log('onClickImport', err)
      dispatch(showAlert({
        msg: JSON.stringify(err),
        error: true,
      }))
    }
  }

  function fetchIntegrations() {
    console.log('fetchIntegrations')
    client.query({
      query: organizationQuery(organization.id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      console.log('organization', data.organization)
      dispatch(setOrg(data.organization))
    }).catch(err => {
      console.log('fetchIntegrations error', err)
    })
  }


  //////////////////////////////////////////// Render Function //////////////////////////////////////////////////////////

  const renderCardItem = (el, index) => {
    const {
      card_id,
      card_back_home_component_card
    } = el || {};

    const onClick = () => {
      if (selectedForm?.id == el?.id) {
        setSelectedForm(null);
        return;
      }
      setSelectedForm(el)
    }

    return (
      <div
        key={index}
        className={css({
          position: 'relative',
          width: '100%',
          height: '50px',
          lineHeight: '50px',
          background: '#5D64AB',
          margin: '0',
          display: 'flex',
          padding: '16px',
          color: 'white',
          background: '#0D0E13',
          marginBottom: '8px !important',
          alignItems: 'center'
        })}
        onClick={onClick}
      >
        <Radio
          value={el.id}
          overrides={{
            RadioMarkOuter: {
              style: {
                backgroundColor: '#5D64AB',
                marginRight: '10px'
              },
            },
          }}
          checked={selectedForm?.id === el?.id}
        />
        <LabelSmall
          className={css({
            color: 'white !important'
          })}
        >
          {el?.title}
        </LabelSmall>
      </div>
    )
  }

  const FormListViewItem = (card, idx) => {
    const itemProps = {
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    let title = card?.default_title || card?.title;
    let imageSrc = getSrc(card?.file_data || card?.front_image_data);
    // let card_id = card.card_id;
    // let full_bleed = card?.full_bleed;
    const onClick = () => {
      if (selectedForm?.id == card?.id) {
        setSelectedForm(null);
        return;
      }
      setSelectedForm(card)
    }
    return (
      <FlexGridItem {...itemProps}>
        <MainCardDiv
          src={`linear-gradient(180deg, rgba(0, 0, 0, 0.17), hsla(235, 32%, 52%, 0.514)), url("${imageSrc}"), linear-gradient(0deg, rgb(255, 255, 255), rgb(255, 255, 255))`}
          onClick={onClick}
        >
          <Radio
            className={css({
              position: 'absolute',
              top: '10px',
              left: '10px'
            })}
            value={card.id}
            overrides={{
              RadioMarkOuter: {
                style: {
                  backgroundColor: '#5D64AB',
                  marginRight: '10px'
                },
              },
            }}
            checked={selectedForm?.id === card?.id}
          />
          <LabelSmall
            className={css({
              color: 'white !important', //theme.colors.primaryA'',
              lineHeight: '13px',
              cursor: 'pointer',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: '-webkit-box',
              fontFamily: 'Roboto !important',
              '-webkit-line-clamp': 2,
              '-webkit-box-orient': 'vertical',
              position: 'relative',
              float: 'left',
              height: '100%',
              boxSizing: 'border-box',
              padding: '8px',
              fontWeight: '400',
              fontSize: '11px',
              position: 'absolute',
              bottom: '10px',
              height: 'auto'
            })}
          >
            {title}
          </LabelSmall>
        </MainCardDiv>
      </FlexGridItem>
    )
  }

  const FormList = () => {
    return formTemplates?.sharepoint_form_templates?.map(renderCardItem)
  };

  const FormsListView = () => {
    return (
      <FlexGrid
        flexGridColumnCount={[1, 2, 4, 6]}
        flexGridColumnGap="scale800"
        flexGridRowGap="scale800"
        overrides={{
          Root: {
            style: {
              minHeight: '50%'
            }
          }
        }}
      >
        {formTemplates?.sharepoint_form_templates?.map(FormListViewItem)}
      </FlexGrid>
    )
  };

  const ListSkeleton = () => {
    if (!formTemplatesLoading) return null;
    return (
      <div style={{ marginTop: '2rem' }}>
        {new Array(10).fill('').map((item, index) => (
          <Skeleton
            width="100%"
            height="50px"
            animation
            overrides={{
              Root: {
                style: {
                  marginBottom: '8px',
                },
              },
            }}
          />))}
      </div>
    )
  }

  const FormListSkeleton = () => {
    const itemProps = {
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    if (!formTemplatesLoading) return null;
    return (
      <div style={{ marginTop: '2rem' }}>
        <FlexGrid
          flexGridColumnCount={[1, 2, 4, 6]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
          overrides={{
            Root: {
              style: {
                minHeight: '50%'
              }
            }
          }}
        >
          {new Array(10).fill('').map((item, index) => (
            <FlexGridItem {...itemProps}>
              <Skeleton
                width="100%"
                height="40vh"
                animation
                overrides={{
                  Root: {
                    style: {

                    },
                  },
                }}
              />
            </FlexGridItem>
          ))
          }
        </FlexGrid>

      </div>
    )
  }
  // console.log('CopyCards', copyCards, selectedCard)

  const FormsList = useMemo(() => {
    if (listMode === 'list_view') {
      return (
        <>
          <FormListSkeleton />
          <FormsListView />
        </>
      );
    }

    return (
      <>
        <ListSkeleton />
        <FormList />
      </>
    )
  }, [formTemplates, selectedForm, listMode])

  return (
    <Modal
      overrides={{
        Root: {
          style: {
            zIndex: 3,
          }
        },
        Backdrop: {
          style: ({ $theme }) => ({
            backdropFilter: $theme.modalBackDropFilter,
          }),
        }
      }}
      onClose={onCloseModal}
      closeable
      isOpen={isModalOpen}
      animate
      autoFocus
      {...modalProps}
      size={SIZE.full}
      role={ROLE.dialog}
    >
      <ModalHeader>
        <div
          className={css({
            display: 'flex',
          })}
        >
          Import Form
          <HelpQuestionMark text={import_form_help}></HelpQuestionMark>
        </div>
      </ModalHeader>
      <ModalBody>
        <Grid
          gridGaps={[12, 12, 24]}
          overrides={{
            Grid: {
              style: ({ $theme }) => outerGridOverride,
            },
          }}
        >
          <Cell
            span={[1, 12]}
            overrides={{
              Cell: {
                style: () => ({
                  paddingLeft: '0px !important',
                  paddingRight: '0px !important',
                }),
              },
            }}
          >
            {FormsList}
          </Cell>
        </Grid>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          onClick={onClickImport}
          isLoading={loadingImportedForm}
        >
          Import
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default ImportFormModal;