import React, { Component } from "react";

class CloudinaryUploadWidget extends Component {
  componentDidMount() {
    try {
      const { upload_preset, cloud_name, api_key } = this.props.cloudinarySettings.cloudinaries_org[0];
      if (!cloud_name || !api_key) return alert('Invalid Cloudinary Credentials provided!')
      var myWidget = window.cloudinary.createUploadWidget(
        {
          cloudName: cloud_name,
          api_key: api_key,
          // uploadPreset: upload_preset,
          uploadSignatureTimestamp: this.props.timestamp,
          uploadSignature: this.props.signature,
          showAdvancedOptions: true,  //add advanced options (public_id and tag)
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
          }
        }
      );
      document.getElementById("upload_widget").addEventListener(
        "click",
        function () {
          myWidget.open();
        },
        false
      );
    } catch {
    }
  }

  render() {
    return (
      <button id="upload_widget" style={{ display: 'none'}} className="cloudinary-button">
        Upload
      </button>
    );
  }
}

export default CloudinaryUploadWidget;
