import { LabelMedium } from "baseui/typography";
import React from "react";

const WrapperGeneric = ({
  _component: Component,
  title,
  ...props
}) => {

  return (
    <>
      <LabelMedium style={{ marginBottom: '16px' }}>{title}</LabelMedium>
      <Component {...props} />
    </>
  )
}

export default WrapperGeneric;