import React from "react";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { ParagraphSmall } from "baseui/typography";

const CheckboxCXL = ({
  value,
  placeholder,
  onChange = () => { },
  ...props
}) => {

  const onChangeValue = e => {
    onChange(e.target.checked)
  }

  return (
    <Checkbox
      checked={value}
      onChange={onChangeValue}
      labelPlacement={LABEL_PLACEMENT.right}
    >
      <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>{placeholder}</ParagraphSmall>
    </Checkbox>
  )
}

export default CheckboxCXL;