import * as React from 'react';
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import { DeleteIcon, FolderIcon, DotIcon } from './icons';

const folderMenuOptions = [
    { label: <div style={{ display: 'flex', alignItems: 'center', }}><FolderIcon color='black'/>Open</div>, value: 'open' },
    { label: <div style={{ display: 'flex', alignItems: 'center' }}><DeleteIcon />Delete</div>, value: 'delete' },
];

export default (props) => {
    const { onDoubleClick, onDelete, onClick, index } = props;
    const { name, path } = props.data;
    return (
        <div onDoubleClick={() => onDoubleClick(path)} onClick={() => onClick && onClick(props.data)} className='video-folder'>
            <div className='video-flex-container'>
                <FolderIcon width={18} height={18}/>
                <p>{name}</p>
            </div>
            <StatefulPopover
                placement={PLACEMENT.bottom}
                content={() => (
                    <StatefulMenu
                        items={folderMenuOptions}
                        onItemSelect={({ item }) => {
                            if (item.value === 'open') {
                                onDoubleClick && onDoubleClick(path);
                            }
                            if (item.value === 'delete') {
                                onDelete && onDelete(props.data);
                                document.querySelectorAll('.setting-icon')[index].click();
                            }
                        }}
                    />
                )}
                triggerType={TRIGGER_TYPE.click}
                returnFocus
                autoFocus
            >
                <span className='setting-icon'>
                   <DotIcon />
                </span>
            </StatefulPopover>
        </div>
    )
}