import React, { useEffect, useState } from 'react'
import { useStyletron } from 'baseui';
import { Textarea } from "baseui/textarea";
import { Select } from 'baseui/select';
import { Drawer } from 'baseui/drawer';
import { HeadingSmall, LabelMedium } from 'baseui/typography';
import { ModalButton } from 'baseui/modal';
import UserTags from './userTags';


const USER_ROLE = [
    { label: 'Admin', id: 'admin' },
    { label: 'Trainer', id: 'trainer' },
    { label: 'User', id: 'user' },
]

function InviteUsers(props) {
    const { isOpen, onClose, organization_id, addUBulkUsers, isCreateBulkUsersLoading, errorInUserCreate } = props;
    const [css, theme] = useStyletron();

    const [email, setEmail] = useState("");
    const [roleId, setRoleId] = useState();
    const [userTags, setUserTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});

    const validateEmail = emails => {
        let valid = true;
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (var i = 0; i < emails.length; i++) {
            if (emails[i] == "" || !regex.test(emails[i])) {
                valid = false;
            }
        }
        return valid
    }

    const onInvite = () => {
        let emails = email.replace(/\s/g, '').split(",");
        if (!email) {
            setErrorMessage({
                email: "required!"
            })
            return false;
        }

        if (!validateEmail(emails)) {
            setErrorMessage({
                email: "Please enter valid email addresss"
            })
            return false;
        }

        if (!roleId) {
            setErrorMessage({
                role: "required!"
            });
            return false;
        }
        let tags = []
        userTags.map(item => item.checked && tags.push(item.tag_id))

        addUBulkUsers({
            email: emails,
            organization_id,
            userTags: tags,
            role: roleId
        });

    }

    const onCancel = () => {
        onClose()
        setErrorMessage({});
        setEmail('');
        setRoleId(null)
        setLoading(false)
    }

    useEffect(() => {
        if (isCreateBulkUsersLoading) {
            setLoading(true);
            return;
        };
        if (errorInUserCreate) {
            setLoading(true);
            return;
        }
        onCancel();
        setLoading(false);
    }, [isCreateBulkUsersLoading]);


    useEffect(() => {
        if (errorInUserCreate) {
            setErrorMessage({ apiError: errorInUserCreate.message });
            setLoading(false);
        }
    }, [errorInUserCreate]);

    return (
        <Drawer
            isOpen={isOpen}
            autoFocus
            onClose={onCancel}
            overrides={{
                DrawerBody: {
                    style: ({ $theme }) => ({
                        marginLeft: '20px',
                    })
                },
            }}>
            <HeadingSmall
                style={{
                    textAlign: 'center'
                }}
            >Invite users</HeadingSmall>

            {errorMessage?.apiError && (
                <LabelMedium
                    style={{ color: theme.colors.negative, marginTop: '16px' }}
                >
                    {errorMessage?.apiError}
                </LabelMedium>
            )}

            <div>
                <LabelMedium style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                }}
                > Email</LabelMedium>
                <Textarea
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Email"
                    clearOnEscape
                />
                
                <p style={{
                    fontSize:"12px"
                }} >To invite lots of people at once, just separate their emails with commas.</p>
                {errorMessage?.email && (
                    <LabelMedium
                        style={{ color: theme.colors.negative, marginTop: '16px' }}
                    >
                        {errorMessage?.email}
                    </LabelMedium>
                )}
            </div>

            <div>
                <LabelMedium style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                }}
                > Invite as</LabelMedium>
                <Select
                    options={USER_ROLE}
                    onChange={({ option }) => {
                        if (option) {
                            setRoleId(option.id);
                            setErrorMessage({ ...errorMessage, role: null })
                        } else {
                            setRoleId(null);
                        }
                    }}
                    value={USER_ROLE.filter(el => el.id == roleId)}
                    // placeholder={selectedApiId ? '' : placeholder}
                    overrides={{
                        Popover: {
                            props: {
                                overrides: {
                                    Body: { style: { zIndex: 3 } },
                                },
                            },
                        },
                    }}
                />

                {errorMessage?.role && (
                    <LabelMedium
                        style={{ color: theme.colors.negative, marginTop: '16px' }}
                    >
                        {errorMessage?.role}
                    </LabelMedium>
                )}
            </div>

            <div
                style={{
                    //  backgroundColor: "#e60707",
                   
                }}>
                <LabelMedium style={{
                    marginTop: '36px',
                    marginBottom: '2px',
                }}
                > Give access to:</LabelMedium>
                <UserTags
                    organization_id={organization_id}
                    userTags={userTags}
                    setUserTags={setUserTags}
                    showSelectAll={true}
                    inviteUsers={true}
                />
            </div>

            <div
                style={{
                    display: 'flex',
                    position: 'absolute',
                    minHeight: '50px',
                    paddingBottom: '10px',
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    flexDirection: 'column',
                }}

            >
                <div
                    className={css({
                        justifyContent: 'flex-end',
                        display: 'flex'
                    })}
                >
                    <ModalButton
                        onClick={onCancel}
                    >
                        Cancel
                    </ModalButton>

                    <ModalButton
                        isLoading={loading}
                        onClick={onInvite}>
                        Invite
                    </ModalButton>
                </div>
            </div>
        </Drawer>
    )
}

export default InviteUsers