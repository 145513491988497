import * as React from 'react';
import CardBuilderButton from './cardBuilderBtn';
import CardBuilderEditor from './cardBuilderEditor';
import { DragDropContext } from 'react-beautiful-dnd';
import { useStyletron } from 'baseui';
import { HeadingXLarge, HeadingSmall } from 'baseui/typography';
import { editorBtnMainOptions as editorBtnMainOptionsOriginal } from '../../../data/cardBuilderOptions';
import Preview from './preview';
import { ArrowLeft } from 'baseui/icon';
import { Button } from 'baseui/button';
import EditorMainOptionsButton from './editorMainOptionsButton';
import { createEditorCardsFromCardModules, getModuleMovementMethods } from './builderUtils/helpers';
import { updateCardBackData } from '../../redux/actions/appBasicControls';
import Plus from 'baseui/icon/plus';
import _ from 'lodash';
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import { setCardBackData } from '../../redux/actions/appBasicControls';
import AutoSave from './autoSave';
import { connect } from 'react-redux';

const Inner = ({ style, children }) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        background: '#868686',
        borderRadius: theme.sizing.radius300,
        color: theme.colors.accent700,
        padding: theme.sizing.scale400,
        height: '100%',
        ...(style || {}),
      })}
    >
      {children}
    </div>
  );
};

const cardBuilerButtonContainerStyle = (isModal, sideBarWidth, isMainPageModal) => {
  return {
    position: isModal ? 'relative' : 'fixed',
    height: isMainPageModal ? 'calc(100vh - 72px)' : 'calc(100vh - 52px)',
    left: isModal ? 0 : sideBarWidth + 84 + 'px',
    overflow: 'auto',
    background: '#EFF3FE',
    width: '185px',
    minWidth: '185px',
    // transition: '0.8s',
    paddingTop: '1rem !important',
    ...(isModal && !isMainPageModal) ? { maxHeight: 'calc(100vh - 95px' } : {}
  }
}
const editorBtnMainOptionsContainerStyle = (isModal, sideBarWidth, isMainPageModal, theme) => {
  return {
    position: isModal ? 'relative' : 'fixed',
    top: isModal ? 0 : '52px',
    left: isModal ? 0 : sideBarWidth + 'px',
    overflow: 'auto',
    background: theme.colors.primaryC,
    width: '83px',
    minWidth: '83px',
    height: isMainPageModal ? 'calc(100vh - 72px)' : 'calc(100vh - 52px)',
    ...(isModal && !isMainPageModal) ? { maxHeight: 'calc(100vh - 95px)' } : {}
  }
}
const cardBuilerEditorContainerStyle = (isModal, isQuizType) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // paddingLeft: '33px',
    // paddingRight: '29px',
    // paddingTop: '29px',
    maxWidth: '40vw',
    marginLeft: isQuizType || isModal ? '0px' : '268px'
  }
}

const builderContainerStyle = {
  position: 'relative',
  display: 'flex',
  height: '100%',
  flexDirection: 'row',
  width: '100%',
  overflow: 'auto'
}

var refOffset;

const Builder = ({
  cardInfo = {},
  onSave,
  onUpdate,
  data,
  noPreview,
  previewContainerVisibility,
  card_content_modules = [],
  defaultTheme,
  headerComponent,
  isQuizType,
  isQuizOptionsTab,
  jwPlayerData,
  libraries,
  isModal,
  dispatch,
  cardBackData,
  isShowHoverContent = false,
  toaster,
  isMainPageModal,
  hideLeftPanel,
  isCommunication,
  organization_settings,
  ...req
}) => {
  const [css, theme] = useStyletron();
  let hiddenModulesArr = organization_settings.card_back_hidden_modules && organization_settings.card_back_hidden_modules.split(',') || [];
  // First set the options
  // Options1 are left side options which are going to dragged
  // Options2 are thr right side options which are going to dropped
  const options1 = req.options.filter(el => {
    if (el.hideFromCardBack) return false;
    const isExist = hiddenModulesArr.find(el2 => el2 === el.__typename);
    if (!isExist) {
      return el;
    }
  });
  // options2 are data in redux for card back editor
  const options2 = cardBackData || [];
  // now if case of edit card or subscription we are going to receive data from server.
  // card_content_modules is the data from server or subscription
  // How many no of updates received from server
  const [noOfUpdatesReceived, setNoOfUpdatesReceived] = React.useState(0);

  //clean the state on first mount
  React.useEffect(() => {
    dispatch(setCardBackData([]));
  }, []);
  // on update received from server
  React.useEffect(() => {
    // here we have data from server
    // Case 1 - First time init set the data
    if (noOfUpdatesReceived == 0) {
      dispatch(setCardBackData(createEditorCardsFromCardModules(card_content_modules.slice(), { options: req.options, title: data ? data.card ? data.card.title : '' : '' })));
      var updates = noOfUpdatesReceived;
      setNoOfUpdatesReceived(++updates);
      return;
    };

    if (card_content_modules && card_content_modules.length > 0) {
      // Case 2 - No data in current state we can set the updated
      if (!cardBackData || (Array.isArray(cardBackData) && cardBackData.length == 0)) {
        dispatch(setCardBackData(createEditorCardsFromCardModules(card_content_modules.slice(), { options: req.options, title: data ? data.card ? data.card.title : '' : '' })));
        var updates = noOfUpdatesReceived;
        setNoOfUpdatesReceived(++updates);
        return
      };
      // Case 3 - We have data in state and data from server is received
      // In progress
    }
  }, [card_content_modules]);

  React.useEffect(() => {
    // if options2 are directly passed we are going to use that
    req.options2 && req.options2.length > 0 && dispatch(setCardBackData(req.options2));
  }, [req.options2]);


  const editorBtnMainOptions = editorBtnMainOptionsOriginal.filter(el => {
    if (el.addOnlyInQuiz) {
      return isQuizOptionsTab == el.addOnlyInQuiz
    };
    return true;
  })


  const navElement = document.querySelector('.side-bar-container');
  const ref = React.useRef(null);
  const [selectedMainOptionId, setSelectedMainOptionId] = React.useState(editorBtnMainOptions[0].id);
  const [sideBarWidth, setSideBarWidth] = React.useState(navElement ? navElement.getBoundingClientRect().right : 0);

  const options = {
    options1,
    options2
  };
  const { addNewToTheList, reOrderList, removeFromEditor, undoDeleted } = getModuleMovementMethods(options, () => { }, selectedMainOptionId, toaster, dispatch, () => { }, data ? data.card ? data.card.title : '' : '');
  const menuOptions = options.options1.map((el, index) => {
    return {
      ...el,
      label: el.display,
      onClick: (dropIndex) => {
        let selectedMainOptionId = el.parent;
        let index1 = options['options1'].filter(el => {
          return el.parent == selectedMainOptionId;
        }).findIndex((el1) => el1.__typename == el.__typename);
        addNewToTheList(index1, dropIndex || options.options2.length, null, selectedMainOptionId);
      }
    }
  });

  const addNewBlockButton = (index) => {
    return <StatefulPopover
      content={() => (
        <StatefulMenu
          items={_.orderBy(menuOptions, [option => option.display.toLowerCase()], ['asc'])}
          onItemSelect={({ item }) => item.onClick(index + 1)}
          overrides={{
            List: {
              style: {
                width: '200px'
              },
            }
          }}
        />
      )}
      placement={PLACEMENT.topLeft}
      returnFocus
      autoFocus
    >
      <Button size={"mini"} startEnhancer={() => <Plus size={24} />}>
        <span>Add New Block</span>
      </Button>
    </StatefulPopover>
  }

  const handleResize = () => {
    const element = document.querySelector('#app-container');
    if (element) {
      setSideBarWidth(element.getBoundingClientRect().left)
    }
  };

  // AUTO MOVEMENT OF LEFT BUTTON ON SCROLL
  React.useEffect(() => {
    let val = ref.current ? ref.current.getBoundingClientRect().top : null;
    if (ref.current) refOffset = val;
  }, [ref]);

  React.useEffect(() => {
    // window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      // window.removeEventListener('scroll', () => handleScroll);
      window.removeEventListener('resize', () => handleResize);
    };
  }, []);
  // END - // AUTO MOVEMENT OF LEFT BUTTON ON SCROLL

  // PREVIEW CONTAINER
  if (previewContainerVisibility) {
    const phoneContainer = document.getElementsByClassName('phoneContainer')
    phoneContainer[0] && (phoneContainer[0].style.display = 'block');
  } else {
    setTimeout(() => {
      const phoneContainer = document.getElementsByClassName('phoneContainer')
      phoneContainer[0] && (phoneContainer[0].style.display = 'none');
    }, 500);
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) {
      if (source.droppableId === 'options2') {
        removeFromEditor(source.index);
      }
      return;
    }
    if (destination.droppableId === source.droppableId) {
      reOrderList(source.droppableId, source.index, destination.index);
    } else {
      if (
        source.droppableId === 'options1' &&
        destination.droppableId === 'options2'
      ) {
        addNewToTheList(source.index, destination.index);
      }
    }
  }
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={builderContainerStyle}>
          {!hideLeftPanel && (
            <div style={editorBtnMainOptionsContainerStyle(isModal, sideBarWidth, isMainPageModal, theme)} ref={ref}>
              <div style={{
                position: 'relative',
                top: '40%',
                transform: 'translateY(-50%)'
              }}>
                {editorBtnMainOptions.map((el, i) => {
                  let hasOptions = options.options1.filter(el1 => el1.parent == el.id);
                  hasOptions = hasOptions.filter(el1 => {
                    let arrHiddenModules = organization_settings.card_back_hidden_modules && organization_settings.card_back_hidden_modules.split(',') || [];
                    const isExist = arrHiddenModules.find(el2 => el2 === el1.__typename);
                    if (!isExist) {
                      return el1;
                    }
                  })
                  if (hasOptions.length == 0)
                    return <></>;
                  return (
                    <div key={i} onClick={() => setSelectedMainOptionId(el.id)} style={{ width: '100%', minHeight: '83px' }}>
                      <EditorMainOptionsButton
                        data={el}
                        selectedMainOptionId={selectedMainOptionId}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {!hideLeftPanel && (
            <div style={cardBuilerButtonContainerStyle(isModal, sideBarWidth, isMainPageModal)}>
              <CardBuilderButton
                selectedMainOptionId={selectedMainOptionId}
                addNewToTheList={addNewToTheList}
                destinationIndex={options.options2.filter(el => !el.isDeleted).length}
                data={{
                  options: options.options1,
                  droppableId: 'options1',
                  type: 'CARD',
                  idKey: 'id',
                }}
              />
            </div>
          )}

          <div style={cardBuilerEditorContainerStyle(isModal, isQuizType, isMainPageModal)}>
            {/* {headerComponent && headerComponent({ flag: false })} */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ minWidth: '405px', flexGrow: 1 }}>
                <CardBuilderEditor
                  data={{
                    options: options.options2,
                    droppableId: 'options2',
                    type: 'CARD',
                    idKey: 'dId',
                  }}
                  jwPlayerData={jwPlayerData}
                  libraries={libraries}
                  methods={{
                    removeFromEditor,
                    reOrderList,
                    addNewToTheList,
                    undoDeleted,
                    onSave,
                    onUpdate
                  }}
                  isModal={isModal}
                  isMainPageModal={isMainPageModal}
                  isShowHoverContent={isShowHoverContent}
                  hoverContent={addNewBlockButton}
                  isQuizType={isQuizType}
                  isCommunication={isCommunication}
                  hasDataSource={req.hasDataSource}
                  dataPoints={req.dataPoints}
                  cardTitle={data?.card?.title}
                  css={css}
                  theme={theme}
                />
              </div>

              {/* {!noPreview && (
                  <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row-reverse', marginLeft: '2rem' }}>
                    <Button
                      onClick={onSave}
                      style={{ position: 'relative', marginLeft: '2rem' }}
                    >
                      Save
                    </Button>
                    <div
                      className={css({
                        overflow: 'hidden',
                        position: 'relative',
                        width: '100%',
                      })}
                    >
                      <div className={`phoneContainer ${!previewContainerVisibility ? 'hidden' : ''}`}>
                        <Inner
                          cellSpan={req.cellSpan}
                          style={{
                            background: '#000000',
                            width: '365px',
                            position: 'relative !important',
                            height: '691px',
                            borderRadius: '20px',
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            padding: '18px 16px 15px 17px',
                            // transition: '0.4s',
                            left: '50%',
                            transform: 'translateX(-50%)',
                          }}
                        >
                          <div
                            className={css({
                              width: '100%',
                              height: '100%',
                              backgroundColor: '#303030',
                              padding: theme.sizing.scale700,
                              overflow: 'auto',
                              marginBottom: theme.sizing.scale700,
                            })}
                          >
                            <ArrowLeft size={48} className={css({
                              color: 'white !important',
                            })} />
                            <HeadingXLarge
                              className={css({
                                color: 'white !important',
                                marginBottom: '1rem !important'
                              })}
                            >
                              {cardInfo.title}
                            </HeadingXLarge>
                            <HeadingSmall
                              className={css({
                                color: 'white !important',
                                marginBottom: '0.5rem !important'
                              })}
                            >
                              {cardInfo.sub_title}
                            </HeadingSmall>
                            <Preview returnComp={true} onSave={onSave} card_content_modules={card_content_modules} options={req.options}></Preview>
                          </div>
                        </Inner>
                      </div>
                    </div>
                  </div>
                )} */}
            </div>
          </div>
        </div>
      </DragDropContext>
      {onSave && <AutoSave
        onSave={onSave}
        cardBackData={cardBackData}
      ></AutoSave>}
    </>
  );
};

const mapStateToProps = props => {
  let { theme, cardBackData, isLoading, organization_settings } = props.appBasicControls;
  return {
    defaultTheme: theme,
    cardBackData,
    isLoading,
    organization_settings
  };
};

export default connect(mapStateToProps)(Builder);