import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';

const SelectOnCheckBox = ({
  value,
  onChange = () => { },
  theme,
  title,
  options,
  placeholder,
  multi,
  ...props
}) => {
  const [checked, setChecked] = React.useState();


  const onChangeCheck = e => setChecked(e.target.checked )

  const onChangeValue = ({ value }) => {
    onChange(value);
  }

  return (
    <>
      <Checkbox
        checked={checked}
        onChange={onChangeCheck}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        Add Contact Method
      </Checkbox>
      {checked && (
        <>
          {' '}
          <h5 style={{ marginTop: theme.sizing.scale600 }}>
            {title || ''}
          </h5>
          <Select
            options={options}
            onChange={onChangeValue}
            value={value}
            placeholder={placeholder}
            multi={multi || false}
            labelKey="label"
            valueKey="id"
            maxDropdownHeight="300px"
            type={TYPE.search}
          />
        </>
      )}
    </>
  );
}

export default SelectOnCheckBox;
