import { useLazyQuery } from '@apollo/client';
import { MODE, StatefulButtonGroup } from 'baseui/button-group';
import { Cell, Grid } from 'baseui/layout-grid';
import { Skeleton } from 'baseui/skeleton';
import { SIZE, Spinner } from 'baseui/spinner';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';

import { app_users_form_responses } from '../../quries';
import Table from '../intake/table';
import { gridPaddingMarginOverrides } from '../overrides';
import Button from '../styledButton';
import { Drawer } from 'baseui/drawer';
import { HeadingSmall, LabelMedium, LabelXSmall, ParagraphSmall } from 'baseui/typography';
import { getServerUrl } from '../../apollo/client';
import FormChannelMetrics from './form-channel-metrics';
import ChannelComplianceMetrics from './channel-compliance-metrics';
import FormChannelMetricsDetail from './form-channel-metrics-detail';
import axios from 'axios';
import { toaster } from 'baseui/toast';

const pageSize = 15;

const FORM_ANALYTICS_TAB = {
  all: 'All',
  channels: 'Channels',
  channel_compliance_overview: 'Channels Compliance Overview',
  channel_wise: 'Channel Compliance details'
}

const FormMetrics = ({
  organization_id,
  ...props
}) => {

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [selectedChannel, setSelectedChannel] = useState(null);

  const [responseDrawerVisible, setResponseDrawerVisible] = useState(false);
  const [formResponse, setFormResponse] = useState('[]');
  const [selectedTab, setSelectedTab] = useState(FORM_ANALYTICS_TAB.all);


  const [css, theme] = useStyletron();
  const [detailViewFor, setDetailViewFor] = useState(null);

  const [fetchFormResponses] = useLazyQuery(app_users_form_responses)

  useEffect(() => {
    console.log('use effect called ---->')
    loadData(page)
  }, [])

  const allColumns = [
    // {
    //   display: 'id',
    //   key: 'id',
    //   formatter: (v) => {
    //     // const {
    //     //   app_content_scope = {}
    //     // } = v || {};
    //     return v || ''
    //   }
    // },
    {
      display: 'Channel',
      key: 'app_content_scope',
      formatter: (v) => {
        const {
          name = ''
        } = v || {};
        return name
      }
    },
    // {
    //   display: 'Category Channel',
    //   key: 'category_app_content_scope',
    //   formatter: (v) => {
    //     const {
    //       app_content_scope = {}
    //     } = v || {};
    //     return app_content_scope?.name || ''
    //   }
    // },
    {
      display: 'Category',
      key: 'category_app_content_scope',
      formatter: (v) => {
        const {
          category = {}
        } = v || {};
        return category?.name || ''
      }
    },
    {
      display: 'Card',
      key: 'category_app_content_scope',
      formatter: (v) => {
        const {
          card = {}
        } = v || {};
        return card?.title || '';
      }
    },
    {
      display: 'Deck',
      key: 'category_app_content_scope',
      formatter: (v) => {
        const {
          deck = {}
        } = v || {};
        return deck?.title || '';
      }
    },
    // {
    //   display: 'Home Component Channel',
    //   key: 'home_component_app_content_scope',
    //   formatter: (v) => {
    //     const {
    //       app_content_scope = {}
    //     } = v || {};
    //     return app_content_scope?.name || ''
    //   }
    // },
    {
      display: 'Home Component',
      key: 'home_component',
      formatter: (v) => {
        console.log('columns v', v)
        return v?.title || ''
      }
    },
    {
      display: 'App User',
      key: 'app_user',
      formatter: (v) => {
        console.log('columns v', v)
        const {
          first_name = "",
          last_name = ""
        } = v | {}

        if (!v) {
          return 'N/A'
        }

        return `${v?.first_name || ''} ${v?.last_name || ''}`
      }
    }, {
      display: 'App User Email',
      key: 'app_user',
      formatter: (v) => {
        // console.log('columns v', v)
        const {
          first_name = "",
          last_name = ""
        } = v | {}

        if (!v) {
          return 'N/A'
        }

        return `${v?.email || ''}`
      }
    },
    {
      display: 'Created At',
      key: 'created_at',
      formatter: (v) => v ? moment(v).format('ll') : ''
    },
    {
      display: 'Updated At',
      key: 'updated_at',
      formatter: (v) => v ? moment(v).format('ll') : ''
    },
    {
      display: '',
      key: 'submitted_data',
      formatter: (v) => {
        const onClick = () => openResponseDrawer(v);
        return (
          <Button
            className={css({
              height: 'auto !important',
              lineHeight: 'normal !important'
            })}
            onClick={onClick}
            overrides={{
              Root: {
                style: {
                  height: 'auto !important',
                  lineHeight: 'normal !important'
                }
              }
            }}
            isSelected
          >
            View Response
          </Button>
        )
      }
    }
  ];
  //////////////////////////////////////////////////////// Event Functions //////////////////////////////////////////////////////////////////////

  const loadData = async (page) => {
    try {
      if (page <= 1) {
        setLoading(true);
      } else {
        setLoadingMore(true);
      }
      const { data: fetchedData } = await fetchFormResponses({
        variables: {
          organization_id,
          page,
          pageSize
        },
        fetchPolicy: 'network-only'
      })

      const {
        app_users_form_responses: {
          data: formResponsesData = [],
          count: formResponsesCount = 0
        }
      } = fetchedData

      setTableData(formResponsesData, page)
      setCount(formResponsesCount);
      setPages(formResponsesCount);
      setPage(page)

      setLoading(false);
      setLoadingMore(false);
    } catch (err) {
      console.log('loadData error', err);
      setLoading(false);
      setLoadingMore(false);
    }
  }

  const setPages = (formResponsesCount) => {
    // console.log('setPages', formResponsesCount, pageSize, formResponsesCount / pageSize)
    setTotalPages(formResponsesCount / pageSize)
  }

  const setTableData = (queryData, page) => {
    const formatedData = formatData(queryData)
    if (page <= 1) {
      setData(formatedData)
    } else {
      setData([
        ...data || [],
        ...formatedData
      ])
    }
  }

  const formatData = (data = []) => {
    return data.map(el => {
      const {
        category_app_content_scope = {},
        home_component_app_content_scope = {}
      } = el || {}
      return ({
        ...el,
        // app_content_scope: category_app_content_scope?.app_content_scope || home_component_app_content_scope?.app_content_scope
      })
    })
  }

  const loadMoreData = () => {
    // console.log('loadMoreData', totalPages, page);
    let p = page;
    if (totalPages >= p) return loadData(++p);
  }

  const openResponseDrawer = (resp) => {
    setResponseDrawerVisible(true);
    setFormResponse(resp)
  }

  const onClickRefreshFormReport = () => {
    axios.patch(`${getServerUrl()}/update_channel_compliance_analytics`, {
      organization_id
    }).then((result) => {
      console.log('Report Refresh success')
    })

    showToaster('Form reports will take sometime to update please refresh this page after a minute.')
  }

  const closeResponseDrawer = () => {
    setResponseDrawerVisible(false);
    setFormResponse('[]')
  }

  const onSelectAllTab = () => {
    setSelectedTab(FORM_ANALYTICS_TAB.all)
  }

  const onSelectChannelTab = () => {
    setSelectedTab(FORM_ANALYTICS_TAB.channels)
  }

  const onSelectChannelComplianceTab = () => {
    setSelectedTab(FORM_ANALYTICS_TAB.channel_compliance_overview)
  }

  const showToaster = (msg, method = 'info') => {
    let toastKey = toaster[method](
      <>
        {msg}
      </>,
      {
        overrides: {
          InnerContainer: {
            style: { width: "100%" }
          }
        }
      }
    );
    setTimeout(() => { toaster.clear(toastKey) }, 3000);
  };

  //////////////////////////////////////////////////////// Render Functions //////////////////////////////////////////////////////////////////////
  const renderAllTable = () => {
    return (
      <div
        style={{
          overflowX: 'auto'
        }}
      >
        <Table
          data={data}
          count={count}
          columns={allColumns}
          loadMoreData={loadMoreData}
        />
      </div>
    )
  }

  const renderFromResponse = () => {
    const formRespArr = JSON.parse(formResponse || '[]');
    return (
      <Drawer
        isOpen={responseDrawerVisible}
        autoFocus
        onClose={closeResponseDrawer}
        overrides={{
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: '20px',
            })
          },
          Root: {
            style: {
              zIndex: 2
            }
          }

        }}
      >
        <HeadingSmall
          style={{
            textAlign: 'center'
          }}
        >
          Form Response
        </HeadingSmall>
        <div
          className={css({
            display: 'flex',
            flex: 1,
            height: '100%',
            flexDirection: 'column'
          })}
        >
          {(formRespArr || []).map(el => {
            return (
              <div
                style={{
                  display: 'flex'
                }}
              >
                <LabelMedium>{el.name}:</LabelMedium>
                <div style={{ width: '10px' }} />
                <ParagraphSmall>{el.value}</ParagraphSmall>
              </div>
            )
          })}
        </div>
      </Drawer>
    )
  }


  const renderSelectedTabContent = () => {

    const onSelectChannelFromChannel = (selectedChannelId) => {
      setSelectedChannel(selectedChannelId);
      setSelectedTab(FORM_ANALYTICS_TAB.channel_wise);
      setDetailViewFor(FORM_ANALYTICS_TAB.channels);
    }

    const onSelectChannelFromOverview = (selectedChannelId) => {
      setSelectedChannel(selectedChannelId);
      setSelectedTab(FORM_ANALYTICS_TAB.channel_wise);
      setDetailViewFor(FORM_ANALYTICS_TAB.channel_compliance_overview);
    }

    const onBackClick = () => {
      if (detailViewFor === FORM_ANALYTICS_TAB.channels) {
        onSelectChannelTab()

      } else if (detailViewFor === FORM_ANALYTICS_TAB.channel_compliance_overview) {
        onSelectChannelComplianceTab();
      }
    }

    switch (selectedTab) {
      case FORM_ANALYTICS_TAB.all: return renderAllTable()
      case FORM_ANALYTICS_TAB.channels: return (<FormChannelMetrics
        organization_id={organization_id}
        setSelectedChannelId={onSelectChannelFromChannel}
      />)

      case FORM_ANALYTICS_TAB.channel_compliance_overview: return (<ChannelComplianceMetrics
        organization_id={organization_id}
        onSelectChannel={onSelectChannelFromOverview}
      />)
      case FORM_ANALYTICS_TAB.channel_wise: return (<FormChannelMetricsDetail
        organization_id={organization_id}
        selectedChannelId={selectedChannel}
        onBackClick={onBackClick}
      />)
      default: return renderAllTable()
    }
  }


  if (loading) {
    return (
      <Grid overrides={gridPaddingMarginOverrides} gridMaxWidth={'unset'}>
        <Cell span={12}>
          <div style={{ marginTop: '2rem' }}>
            {new Array(4).fill('').map((item, index) => (
              <Skeleton
                width="100%"
                height="94px"
                animation
                overrides={{
                  Root: {
                    style: {
                      marginBottom: '15px',
                    },
                  },
                }}
              />))}
          </div>
        </Cell>
      </Grid>
    )
  }
  return (
    <Grid overrides={gridPaddingMarginOverrides} gridMaxWidth={'unset'}>
      <Cell
        span={12}
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          })}
        >
          <StatefulButtonGroup
            mode={MODE.radio}
            initialState={{ selected: 0 }}
          >
            <Button
              onClick={onSelectAllTab}
              isSelected={FORM_ANALYTICS_TAB.all === selectedTab}
            >
              {FORM_ANALYTICS_TAB.all}
            </Button>
            <Button
              onClick={onSelectChannelTab}
              isSelected={FORM_ANALYTICS_TAB.channels === selectedTab}
            >
              {FORM_ANALYTICS_TAB.channels}
            </Button>
            <Button
              onClick={onSelectChannelComplianceTab}
              isSelected={FORM_ANALYTICS_TAB.channel_compliance_overview === selectedTab}
            >
              {FORM_ANALYTICS_TAB.channel_compliance_overview}
            </Button>
            {/* <Button
              onClick={onSelectChannelComplianceTab}
              isSelected={FORM_ANALYTICS_TAB.channel_wise === selectedTab}
            >
              {FORM_ANALYTICS_TAB.channel_wise}
            </Button> */}
          </StatefulButtonGroup>
          <div>
            <Button
              onClick={onClickRefreshFormReport}
              isSelected
              overrides={{
                Root: {
                  style: {
                    marginRight: '10px'
                  }
                }
              }}
            >
              Referesh Report
            </Button>
            {
              selectedTab === FORM_ANALYTICS_TAB.all && (
                <StatefulButtonGroup
                  mode={MODE.radio}
                  initialState={{ selected: 0 }}
                  style={{
                    float: 'right',
                    marginBottom: '10px'
                  }}
                  overrides={{
                    Root: {
                      style: {
                        float: 'right',
                        marginBottom: '10px'
                      }
                    }
                  }}
                >
                  <Button
                    onClick={() => {
                      window.open(`${getServerUrl()}/app_users_form_responses/download/${organization_id}`, '_blank').focus();
                    }}
                  >
                    Download All Data
                  </Button>
                </StatefulButtonGroup>
              )
            }
          </div>


        </div>

      </Cell>

      <Cell span={12}>
        <div style={{ marginBottom: '32px' }}>
          {/* {renderAllTable()} */}
          {renderSelectedTabContent()}
        </div>
        <div>
          {
            loadingMore && (
              <Skeleton
                width="100%"
                height="94px"
                animation
                overrides={{
                  Root: {
                    style: {
                      marginBottom: '15px',
                    },
                  },
                }}
              />
            )
          }

        </div>
      </Cell>
      {renderFromResponse()}
    </Grid>
  )
}


export default FormMetrics;
