import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { Card, StyledBody, StyledAction } from 'baseui/card';
import { HeadingMedium, ParagraphSmall, HeadingXSmall } from 'baseui/typography';
import PhoneDesign from '../builder/phone-design';
import PreviewElements, { getModuleStructure } from '../builder/previewElementsV1';
import { client } from '../../apollo/client';
import gql from 'graphql-tag';
import store from '../../redux/store';
import { connect } from 'react-redux';

const style = {
  paddingLeft: '20px',
  paddingRight: '20px',
  marginBottom: '20px'
}

const ArticlePreview = ({ feed, color_palette }) => {
  let modules = feed.communication.card_content_modules || [];
  var front_image = feed.front_image;
  console.log(feed);
  const [url, setUrl] = React.useState('');
  React.useEffect(() => {
    client.query({
      query: gql`
        query organization_pwa_app {
          organization_pwa_app(organization_id: "${store.getState().appBasicControls.organization.id}") {
            app_url
          }
        }
      `
    }).then((response) => {
      try {
        let appUrl = response.data.organization_pwa_app[response.data.organization_pwa_app.length - 1].app_url;
        const previewLink = (appUrl);
        setUrl(previewLink)
      } catch (e) {

      }
    })
  }, []);
  console.log(url);
  const cardBackData = modules;
  return <Grid overlay={gridPaddingOverrides}>
    <Cell overrides={cellPaddingOverrides} span={6}>
      <PhoneDesign title="Popup Preview" helpText="Preview of popup in phone screen">
        <div style={{
          width: '100%',
          height: '100%',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
          background: 'gray'
        }}>
          <iframe className="resp-iframe" src={url} gesture="media" allow="encrypted-media" allowfullscreen></iframe>
        </div>

        <div style={{
          position: 'absolute', width: '100%', height: 'calc(100%)', bottom: '0px', background: 'white'
        }}>
          <div style={{ padding: '10px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid gray', borderTop: '1px solid gray', borderTopRightRadius: '8px', borderTopLeftRadius: '8px' }}>
            <HeadingXSmall style={{ color: 'black', paddingBottom: '0px', marginBottom: '0px' }}>{feed.communication.title}</HeadingXSmall>
            <HeadingXSmall style={{ color: 'black', paddingBottom: '0px', marginBottom: '0px' }}>{'X'}</HeadingXSmall>
          </div>
          <div style={{ height: 'calc(100% - 35px)', overflow: 'scroll', paddingTop: '12px' }}>
            {cardBackData.map((el) => {
              return getModuleStructure({
                module: el,
                width: '100%',
                color: 'black',
                color_palette
              })
            })}
          </div>
        </div>
      </PhoneDesign>
    </Cell>
  </Grid>
};

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  let color_palette = [];
  if (organization && organization.color_palette) {
    color_palette = organization.color_palette;
  };
  return {
    color_palette
  };
};
export default connect(mapStateToProps)(ArticlePreview);