import React from 'react';
import { getFilesFromDataTransferItems } from 'datatransfer-files-promise';
import Axios from 'axios';
import { isVideo, UpdateFileExtension } from './utils';
import { client } from '../../apollo/client';
import { fileUpload } from '../builder/builderUtils/query';
import { FailedFileUploader } from './failedFileUploader';

const DragAndDrop = props => {

  const { cloudinarySettings } = props;
  const [inDropZone, setInDropZone] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState('');

  const [uploadFailedOpen, setUploadFailedOpen] = React.useState(false);
  const [failedFiles, setFailedFiles] = React.useState([]);

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const completeFileUpload = (status, file) => {
    setUploadFile('')
    props.onMessage && status !== false && props.onMessage('Files uploaded successfully!')
    props.refetch && props.refetch();
    props.refetchLibraries && props.refetchLibraries();
    props.onDone && props.onDone(file);
  };

  async function handleFolderUpload(event) {
    event.preventDefault()
    setInDropZone(false);
    const _f = [];
    console.log('uploading folder');
    try {
      const { api_key, cloud_name, } = cloudinarySettings.cloudinaries_org[0];
      if (!api_key || !cloud_name) return alert('Invalid Cloudinary Credentials provided!')
      const files = await getFilesFromDataTransferItems(event.dataTransfer.items);
      console.log(files);
      for (let i = 0; i < files.length; i++) {
        props.onMessage && props.onMessage(`Uploading ${i + 1}/${files.length}`)
        try {
          await cloudinaryFileUpload(files[i])
        } catch (err) {
          _f.push({
            f: files[i]
          })
        }
      };
      if (_f.length > 0) {
        setFailedFiles(_f);
        setUploadFailedOpen(true);
      } else completeFileUpload(true, files[0]);
    } catch (error) {
      console.log("Error: ", error)
    }
  }

  const generateCloudinarySignature = async (signatureBody) => {
    try {
      const BASE_DOMAIN = process.env.BASE_DOMAIN || `cardware-admin-api.herokuapp.com`;
      const IS_HTTPS = BASE_DOMAIN.indexOf('.com') > -1;
      const BASE_URI = `${IS_HTTPS ? 'https' : 'http'}://${BASE_DOMAIN}`;
      const res = await Axios.post(`${BASE_URI}/cloudinary_signature`, signatureBody);
      return res.data
    } catch (error) {
      return false;
    }
  }

  const cloudinaryFileUpload = (file) => {
    return new Promise(async (resolve, reject) => {
      const { api_key, cloud_name, api_secret, default_upload_path } = cloudinarySettings.cloudinaries_org[0];
      const timestamp = Math.round((new Date).getTime() / 1000);
      const isVideoFile = isVideo(file.name);
      let defaultPath = '';
      if (default_upload_path) {
        if (default_upload_path.indexOf('/') > -1) {
          defaultPath = default_upload_path;
        } else {
          defaultPath = default_upload_path + '/';
        }
      }
      const public_id = defaultPath + file.filepath
      setUploadFile(file.filepath)
      const signatureBody = { timestamp, public_id, api_key, api_secret, cloud_name }
      if (isVideoFile) {
        signatureBody["eager"] = "f_mp4";
      }
      const signature = await generateCloudinarySignature(signatureBody);;
      const formData = new FormData();
      if (!signature) return alert('Something went wrong with signature')
      if (isVideoFile) {
        formData.append("eager", "f_mp4");
      }
      formData.append('file', file);
      formData.append('api_key', api_key);
      formData.append('timestamp', timestamp);
      formData.append('signature', signature);
      formData.append('public_id', public_id);
      try {
        const transformUrl = isVideo(file.name) ? '/c_scale,h_280/f_auto/' + UpdateFileExtension(file.name) : '';
        const res = await Axios.post(`https://api.cloudinary.com/v1_1/${cloud_name}/${isVideoFile ? 'video' : 'auto'}/upload`, formData)
        resolve()
      } catch (error) {
        debugger;
        console.log('File upload Error: ', error)
        reject()
      }
    })
  }

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setInDropZone(false)
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
    setInDropZone(true)
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    handleFolderUpload(e);
    props.setIsLoading && props.setIsLoading(true);
  };

  return (
    <div className={inDropZone || uploadFile ? 'drag-drop-zone inside-drag-area' : 'drag-drop-zone'}
      onDrop={e => handleDrop(e)}
      onDragOver={e => handleDragOver(e)}
      onDragEnter={e => handleDragEnter(e)}
      onDragLeave={e => handleDragLeave(e)}
    >
      <span className='cloudIcon'>
        <svg class="svgIcon" preserveAspectRatio="xMidYMid" fill='white' viewBox="0 0 24 24"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96z"></path></svg>
      </span>
      {!uploadFile ? <p style={{ marginTop: 0, fontFamily: 'Inter,sans-serif' }}>Drag and Drop folder or assets here</p> : null}
      {uploadFile ? <p className='progress'>Uploading file - {uploadFile}... </p> : null}

      {uploadFailedOpen && <FailedFileUploader
        files={failedFiles}
        isOpen={uploadFailedOpen}
        onClose={(status) => {
          setUploadFailedOpen(false);
          setFailedFiles([]);
          completeFileUpload(status, failedFiles[0]);
          console.log('on close called');
        }}
        organization_id={props.organization_id}
        closeable={false}
      />}
    </div>
  );
};

export default DragAndDrop;