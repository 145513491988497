import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import gql from 'graphql-tag';
import { Button } from 'baseui/button';
import { useStyletron } from 'baseui';
import { Spinner } from 'baseui/spinner';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { client } from '../../apollo/client';
import _ from 'lodash';
import { getTags, getCardTags, updateCardTag } from '../../quries';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import { setIsLoading } from '../../redux/actions/appBasicControls';
import { useDispatch } from 'react-redux';

var onSaveDebounced;
export default ({ card, organization_id, toaster }) => {

  const [css, theme] = useStyletron();
  const [tags, setTags] = React.useState([]);
  const [filtered, setFiltered] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [cardTags, setCardTags] = React.useState([]);
  const dispatch = useDispatch();

  const loadTags = () => {
    client.query({
      query: getTags(organization_id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setTags(data.tags_org)
    })
  };

  const loadCardTags = () => {
    client.query({
      query: getCardTags(card.id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      const dataList = (data?.card_tag_by_card || []).reduce((acc, curr) => {
        const tagId = curr?.tag_id;
        if (!acc.includes(tagId)) {
          return ([
            ...acc,
            tagId
          ])
        }

        return acc;
      },[])
      setCardTags(dataList)
    })
  };

  React.useEffect(() => {
    loadTags()
    loadCardTags()
  }, []);

  React.useEffect(() => {
    if (searchText == '') {
      setFiltered(tags)
    } else {
      setFiltered(tags.filter(e => e.name.toLowerCase().includes(searchText)))
    }
  }, [searchText, tags]);

  const getCheckedStatus = (valueId) => {
    let isExists = cardTags.find(el => {
      return (
        el == valueId
      );
    });
    if (!isExists) return false;
    if (isExists.checked === undefined) return true;
    return isExists.checked;
  };

  const markChecked = (tagId, checked) => {
    // let tag = cardTags.findIndex(el => {
    //   return (
    //     el.tag_id == tagId
    //   );
    // });
    // let cardTagCopy = JSON.parse(JSON.stringify(cardTags));
    // if (tag == -1) {
    //   console.log('markChecked', tag)
    //   cardTagCopy.push({
    //     tag_id: tagId,
    //   });
    //   console.log('markChecked 1', cardTagCopy)
    //   tag = cardTagCopy.length - 1;
    // } else {
    //   cardTagCopy[tag] = {
    //     ...cardTagCopy[tag],
    //     checked: false
    //   }
    // }
    // cardTagCopy[tag].checked = checked;
    // console.log('markChecked 2', cardTagCopy)
    // setCardTags(cardTagCopy);
    let cardTagCopy = JSON.parse(JSON.stringify(cardTags));
    if (!checked) {
      cardTagCopy = cardTagCopy.filter(el => {
        if (el !== tagId) return el;
      });
    } else  {
      cardTagCopy.push(tagId)
    }

    setCardTags(cardTagCopy);
  };

  React.useEffect(() => {
    if (onSaveDebounced) {
      onSaveDebounced = null
    };
    onSaveDebounced = _.debounce(onSave, 1500);
  }, []);

  React.useEffect(() => {
    onSaveDebounced && onSaveDebounced(cardTags)
  }, [cardTags]);

  const onSave = React.useCallback((dataToSave) => {
    // let input = dataToSave
    //   .filter(el => el.checked !== undefined)
    //   .map(el => {
    //     el.organization_id = organization_id;
    //     el.card_id = card.id;
    //     delete el.__typename;
    //     return el;
    //   });
    // if (input.length == 0) return;
    let input = {
      organization_id: organization_id,
        card_id: card.id,
        tag_ids: dataToSave
    }

    const msg = 'Saving the card tags.';
    let toastKey = toaster.info(<>{msg}</>, {
      onClose: () => console.log('Toast closed.'),
      overrides: {
        InnerContainer: {
          style: { width: '100%' },
        },
      },
    });
    dispatch(setIsLoading(true));
    client.mutate({
      mutation: updateCardTag,
      variables: {
        input: input,
      },
    }).then(() => {
      toaster.update(toastKey, {
        children: <>Tag saved.</>,
      });
      setTimeout(() => {
        toaster.clear(toastKey);
      }, 1500);
    }).finally(() => {
      dispatch(setIsLoading(false));
    });
  },[])

  return (
    <>
      <Grid overrides={gridPaddingOverrides}>
        <Cell overrides={cellPaddingOverrides} span={12}>
          <div
            className={css({
              padding: '0px',
              marginBottom: '24px',
            })}
          >
            {tags.length <= 0 ? (
              <div
                className={css({
                  position: 'absolute',
                  width: theme.sizing.scale1200,
                  height: theme.sizing.scale1200,
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  marginTop: '52px'
                })}
              >
                <Spinner />
              </div>
            ) : (
                <Grid overrides={gridPaddingOverrides}>
                  <div
                    style={{
                      float: 'left',
                      width: '50%',
                    }}>
                    <Input
                      placeholder="Search by name"
                      clearOnEscape
                      clearable
                      onChange={search => setSearchText(search.target.value.toLowerCase())}
                      endEnhancer={<Search size="18px" />}
                    />
                  </div>
                  <div
                    style={{
                      position: 'relative',
                      float: 'left',
                      width: '100%',
                      backgroundColor: theme.colors.primaryB,
                      marginTop: '16px',
                      paddingTop: '16px',
                      paddingBottom: '16px',
                      borderRadius: '4px',
                      boxShadow: theme.lighting.shadow400,
                      marginBottom: '48px',
                    }}
                  >
                    {filtered.map((el, i2) => {
                      return (
                        <div
                          key={i2}
                          className={css({
                            paddingTop: theme.sizing.scale300,
                            paddingLeft: '24px',
                            paddingRight: '24px',
                            minWidth: '300px',
                            float: 'left',
                            display: 'block'
                          })}
                        >
                          <Checkbox
                            checked={getCheckedStatus(el.id)}
                            onChange={e =>
                              markChecked(el.id, e.target.checked)
                            }
                            labelPlacement={LABEL_PLACEMENT.right}
                          >
                            {`${el.name}`}
                          </Checkbox>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              )}
          </div>
        </Cell>
      </Grid>
    </>
  );
};
