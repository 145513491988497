import * as React from 'react';
import { apiQuery } from '../apiMappings/query';
import { client } from '../../apollo/client';
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { Grid, Cell } from 'baseui/layout-grid';
import { Spinner } from 'baseui/spinner';
import Button from '../styledButton';
import { useStyletron } from 'baseui';

import BaseApi from './base-api';
import Paths from './paths';
// import RightSidePageHeader from '../pageHeaders';
import StashFunctions from './functions';
import { connect } from 'react-redux';
import RightSidePageHeader from '../channel/channelHeader';

const Stash  = ({ organization, organization_id, dispatch, refetch }) => {

  const organizationId = organization?.id || organization_id;
  const [css, theme] = useStyletron();
  const [apis, setApis] = React.useState([]);
  const [paths, setPaths] = React.useState([]);
  const [functions, setFunctions] = React.useState([]);
  const [activeKey, setActiveKey] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const queryData = () => {
    setIsLoading(true);
    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "api_paths",
          query: `{\"where\": {\"organization_id\": \"${organizationId}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        setPaths(JSON.parse(response.data));
      }
      setIsLoading(false);
    });

    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "organization_apis",
          query: `{\"where\": {\"organization_id\": \"${organizationId}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        setApis(JSON.parse(response.data));
      }
    });

    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "organization_stash_functions",
          query: `{\"where\": {\"organization_id\": \"${organizationId}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        setFunctions(JSON.parse(response.data));
      }
    }).catch((err) => {
      console.log('organization_stash_functions error', err)
    });
  };


  React.useEffect(() => {
    queryData();
  }, []);

  console.log(apis, paths);
  return <Grid overrides={gridPaddingOverrides}>
    <div
      className={
        css({
          marginBottom: '2rem'
        })
      }
    >
      <RightSidePageHeader
        fromPage={'stash'}
        icon={null}
        title={'Stash'}
      // headerDescription={pageTitlesAndDescriptions[TAB_STATUS.GENERAL].description}
      />
    </div>
    <Cell
      span={12}

    >
      <Tabs
        activeKey={activeKey}
        onChange={({ activeKey }) => {
          setActiveKey(activeKey);
        }}
        activateOnFocus
        overrides={{
          Root: {
            style: () => ({
              padding: 0,
            })
          }
        }}
      >
        <Tab title="Connections">
          <BaseApi apis={apis} queryData={queryData} isLoading={isLoading} setIsLoading={setIsLoading} setApis={setApis} organization_id={organizationId} dispatch={dispatch} refetch={refetch} />
        </Tab>
        <Tab title="Endpoints">
          <Paths apis={apis} paths={paths} queryData={queryData} isLoading={isLoading} setIsLoading={setIsLoading} setPaths={setPaths} organization_id={organizationId} dispatch={dispatch} refetch={refetch} />
        </Tab>
        <Tab title="Functions">
          <StashFunctions
            functions={functions}
            queryData={queryData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setFunctions={setFunctions}
            organization_id={organizationId}
            dispatch={dispatch}
            refetch={refetch}
          />
        </Tab>
      </Tabs>
    </Cell>
  </Grid>
};

const mapStateToProps = props => {
  let { organization } = props.appBasicControls;
  return {
    organization
  };
};
export default connect(mapStateToProps)(Stash);