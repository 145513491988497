import * as React from 'react';
import {
  LabelMedium,
  LabelSmall,
  HeadingXSmall,
  ParagraphSmall,
} from 'baseui/typography';
import { Spinner } from 'baseui/spinner';


const Loader = ({ children, ...props }) => {
  // debugger;
  if (props.root.dId && props.root.dId.includes('manual') && !props.isCommunication) {
    return <div style={{ display: 'flex' }}>
      <Spinner size={24} />
      <div style={{ marginLeft: '16px', textTransform: 'uppercase' }}>Setting up component...</div>
    </div>
  };
  return <>
    {children}
  </>;
};

export default Loader;