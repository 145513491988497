import React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { connect } from 'react-redux';
import { gridPaddingOverrides } from '../components/overrides';
import logo from '../images/login_page_logo.png';
import logo_white from '../images/login_page_logo.png';
import RightSidePageHeader from '../components/pageHeaders';
import { useStyletron } from 'baseui';
import { HeadingLarge } from 'baseui/typography';
import {toggleTheme} from '../redux/actions/appBasicControls';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';

import withAutoplay from 'react-awesome-slider/dist/autoplay';
import AwesomeSlider from 'react-awesome-slider';
import CarouselSlider from './login/CarouselSlider';


const AutoplaySlider = withAutoplay(AwesomeSlider);


const LoginContainer = ({ defaultTheme, dispatch, sliders, hasSliders = true, children, sloganSub = "Welcome Back", height, width, hideSlogan }) => {
  const [css, theme] = useStyletron();

  var hour = new Date().getHours();
  var slogan =
    'Good ' +
    ((hour < 12 && 'Morning') || (hour < 18 && 'Afternoon') || 'Evening');

  const LOGO = defaultTheme == 'light' ? logo : logo_white;

  React.useEffect(() => {
    if (defaultTheme == 'dark') {
      dispatch(toggleTheme());
      return () => {
        dispatch(toggleTheme());
      }
    };
  }, []);

  return (
    <Grid
      overrides={gridPaddingOverrides}
      gridMaxWidth={'unset'}>
      <Cell
        span={hasSliders ? 6 : 12}
        align="center"
        className={css({
          background: theme.colors.primaryB,
        })}
        overrides={{
          Cell: {
            style: () => ({
              background: theme.colors.primaryB,
              height: '100vh',
              width: '100vw',
              overflow: 'hidden',
              '@media (max-width: 768px)': {
                paddingRight: '15px !important',
                paddingLeft: '15px !important',
              }
            }),
          },
        }}
      >
        <div style={{
          position: 'relative', width: '100%',
          height: '98vh', overflow: "scroll"
        }}>
          <div style={{ marginTop: '16px' }}></div>
          <div
            className={css({
              ...hasSliders ? { display: 'block' } : { display: 'none' },
              '@media (max-width: 768px)': {
                display: 'none',
              }
            })}
          >
            <RightSidePageHeader
              icon={LOGO}
              iconCss={{
                width: '200px',
                height: 'unset',
                marginTop: '0px'
                // background: theme.colors.primaryB === "#FFFFFF" ? 'black' : theme.colors.primaryB
              }}
            ></RightSidePageHeader>
          </div>
          <div
            className={css({
              ...hasSliders === false ? { display: 'block', marginTop: '1%' } : { display: 'none', marginTop: '10%' },
              '@media (max-width: 768px)': {
                display: 'block',
                justifyItems: 'center',
                alignItems: 'center',
                marginTop: 0
              }
            })}
          >
            <img
              src={LOGO}
              alt={'Icon'}
              className={css({
                marginTop: '9px',
                width: '200px',
                // height: '105px',
                // background: theme.colors.primaryB === "#FFFFFF" ? 'black' : theme.colors.primaryB,
                position: 'relative !important',
                left: '50%',
                transform: 'translateX(-50%)'
              })}
            ></img>
          </div>
          <div
            className={css({
              position: 'absolute',
              width: '100%',
              left: 0,
              top: 0,
              bottom: 0,
              // height: '100vh',
            })}
          >
            <div
              className={css({
                position: 'absolute',
                width: width || '500px',
                height: height || '300px',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                '@media (max-width: 768px)': {
                  width: '100%',
                  // marginTop: '50px'
                  marginTop: '0px',
                  top: '40%'
                }
              })}
            >
              {!hideSlogan && <HeadingLarge
                style={{
                  textAlign: 'center',
                  marginBottom: '5px',
                  color: theme.colors.primaryA,
                }}
              >
                {slogan} {sloganSub}!
              </HeadingLarge>}
              {children}
            </div>
          </div>
        </div>
      </Cell>
      <Cell
        span={hasSliders ? [0, 6, 6] : 0}
        overrides={{
          Cell: {
            style: () => ({
              paddingLeft: '0px !important',
              paddingRight: '0px !important',
            }),
          },
        }}
      >
        <CarouselSlider sliders={sliders} />
      </Cell>
    </Grid>
  )
}

const mapStateToProps = props => {
  const { theme } = props.appBasicControls;
  return {
    defaultTheme: theme
  };
};
export default connect(mapStateToProps)(LoginContainer);