import * as React from 'react';
import Button from '../common/button';
import StyleButton from '../styledButton';
import { Plus } from 'baseui/icon';
import svgIcons from '../../svg'
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from 'baseui/checkbox';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { useStyletron } from 'baseui';
import { LabelMedium } from 'baseui/typography';
import { Input } from 'baseui/input';
import { Select } from 'baseui/select';
import { KIND as ButtonKind } from 'baseui/button';
import { Grid, Cell } from 'baseui/layout-grid';
import HelpQuestionMark from '../help/questionMarkHelp';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import RightSidePageHeader from '../pageHeaders';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import UserTags from './userTags';
import DisabledArea from '../DisabledArea';
import UserEmailPermissions, { defaultFormPermissions } from './userEmailPermissions';

const AddUser = ({
  addUser,
  appChannels,
  organization_id,
  isCreateUserWithChannelLoading,
  type,
  errorInUserCreate,
  groups,
  onClose
}) => {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [selectedChannels, setSelectedChannels] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [allChannelsPerUser, setAllChannelsPerUser] = React.useState(false);
  const [userTags, setUserTags] = React.useState([]);

  const [formEmailPermissions, setFormEmailPermissions] = React.useState(defaultFormPermissions);


  const handleCancel = () => {
    setIsOpen(false);
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setSelectedChannels([]);
    setErrorMessage('');
    setChecked(false);
    onClose();
    setFormEmailPermissions(defaultFormPermissions);
    setTab(TAB_STATUS.USER);
  };

  const TAB_STATUS = {
    USER: 'User',
    TAGS: 'Tags',
    PERMISSIONS: 'Email Permissions',
  };

  const pageTitlesAndDescriptions = {
    [TAB_STATUS.USER]: {
      title: `Add ${type}`,
      description: ''
    },
    [TAB_STATUS.TAGS]: {
      title: 'Tags',
      description: 'Assign tags to the user'
    },
    [TAB_STATUS.PERMISSIONS]: {
      title: 'Email Permissions',
      description: 'Assign email permissions to the user'
    }

  }
  const [tab, setTab] = React.useState(TAB_STATUS.USER);
  const handleCreateUser = () => {
    let tags = []
    userTags.map(e => {
      if (e.checked != false) {
        tags.push(e.tag_id)
      }
    })
    let channels = selectedChannels.map(el => el.id);
    if (!firstName || !email) {
      setErrorMessage('All fields required!');
      return false;
    }
    if (channels.length == 0 && allChannelsPerUser == false && tags.length <= 0) {
      setErrorMessage('All fields required!');
      return false;
    };
    addUser({
      firstName,
      lastName,
      channels,
      email,
      password,
      organization_id,
      hasAllChannelsPermission: allChannelsPerUser,
      userTags: tags,
      form_email_permissions: formEmailPermissions
    });
  };

  React.useEffect(() => {
    // debugger;
    if (isCreateUserWithChannelLoading) {
      setIsLoading(true);
      return;
    };
    if (errorInUserCreate) {
      setIsLoading(false);
      return;
    }
    handleCancel();
    setIsLoading(false);
  }, [isCreateUserWithChannelLoading]);
  React.useEffect(() => {
    errorInUserCreate && setErrorMessage(errorInUserCreate.message);
  }, [errorInUserCreate]);
  return (
    <>
      <div span={3} style={{ float: 'right' }}>
        <Button
          border_property={"true"}
          onClick={() => setIsOpen(true)}
          text={`Add ${type}`}
          style={{ textTransform: 'capitalize' }}
          startEnhancer={() => (
            <img
              src={svgIcons.AddIcon}
              style={{
                margin: 0
              }}
            />
          )}
          endEnhancer={() => (
            <HelpQuestionMark text={`add ${type} to the system`} button />
          )}
        />
      </div>
      <Modal
        onClose={() => handleCancel()}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              width: '70vw',
              display: 'flex',
              flexDirection: 'column',
            },
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Root: {
            style: {
              zIndex: 3
            }
          }
        }}
      >
        <ModalHeader style={{ textTransform: 'capitalize' }}>Invite {type}
          <RightSidePageHeader
            // icon={null}
            // title={pageTitlesAndDescriptions[tab].title}
            rightContent={
              <StatefulButtonGroup
                mode={MODE.radio}
                initialState={{ selected: 0 }}
              >
                {Object.keys(TAB_STATUS).map(el => {
                  if (type !== 'admin' && TAB_STATUS.PERMISSIONS == TAB_STATUS[el]) { return null; }
                  return (
                    <StyleButton onClick={() => setTab(TAB_STATUS[el])}>
                      {TAB_STATUS[el]}
                    </StyleButton>
                  );
                })}
              </StatefulButtonGroup>
            }
          // headerDescription={pageTitlesAndDescriptions[tab].description}
          ></RightSidePageHeader>
        </ModalHeader>
        <ModalBody>


          {tab == TAB_STATUS.USER && (
            <Grid overrides={gridPaddingOverrides}>
              <Cell span={6} overrides={cellPaddingOverrides}>
                <LabelMedium
                  style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                  }}
                >
                  First Name
                </LabelMedium>
                <Input
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  placeholder="First Name"
                  clearOnEscape
                />
                <LabelMedium
                  style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                  }}
                >
                  Last Name
                </LabelMedium>
                <Input
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  placeholder="Last Name"
                  clearOnEscape
                />
                <LabelMedium
                  style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                  }}
                >
                  Email
                </LabelMedium>
                <Input
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email"
                  clearOnEscape
                />

                {errorMessage && (
                  <LabelMedium
                    style={{ color: theme.colors.negative, marginTop: '16px' }}
                  >
                    {errorMessage}
                  </LabelMedium>
                )}
              </Cell>
              <Cell span={6} overrides={cellPaddingOverrides}>
                <div>
                  <div
                    className={css({
                      paddingLeft: '18px',
                      width: '100%',
                      height: '100%',
                      overflow: 'scroll',
                      maxHeight: '368px',
                    })}
                  >
                    <LabelMedium
                      style={{
                        marginTop: '16px',
                        marginBottom: '8px',
                      }}
                    >
                      Channels
                    </LabelMedium>
                    <div style={{
                      marginTop: '16px',
                      marginBottom: '8px',
                      marginLeft: '5px'
                    }}>
                      <div className={'user-toggle-container'} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                        <Toggle
                          id="cheese-status"
                          defaultChecked={allChannelsPerUser}
                          icons={false}
                          onChange={(e) => { setAllChannelsPerUser(e.target.checked); }}
                        ></Toggle><p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem', marginRight: '0.5rem', marginTop: '3px' }}>Channel Super Admin</p>
                        <HelpQuestionMark backgroundColor='black' text={`This setting will provide access to the admin for all current and future channels for this organization.`} button />
                      </div>
                    </div>
                    <DisabledArea isDisabled={allChannelsPerUser}>
                      <div className={'user-toggle-container'} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: '0.3rem' }}>
                        <Toggle
                          id="cheese-status"
                          defaultChecked={checked}
                          icons={false}
                          onChange={(e) => {
                            setChecked(e.target.checked);
                            if (e.target.checked) setSelectedChannels(appChannels);
                            else setSelectedChannels([]);
                          }}
                        ></Toggle><p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem', marginTop: '3px' }}>Select All</p>
                      </div>
                      {[...groups]
                        .sort((a, b) => {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map(group => {
                          return appChannels
                            .filter(el => el.app_content_scope_group_id == group.id)
                            .map(el => {
                              let isChecked = selectedChannels.find(
                                el1 => el.id == el1.id
                              );
                              isChecked = isChecked?.id ? true : false

                              return (
                                <div className={'user-toggle-container'} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: '0.3rem' }}>
                                  <Toggle
                                    id="cheese-status"
                                    defaultChecked={isChecked}
                                    checked={isChecked}
                                    key={el.id}
                                    icons={false}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        let newValues = [...selectedChannels, el];
                                        setSelectedChannels(newValues);
                                        if (newValues.length == appChannels.length) {
                                          setChecked(true);
                                        };
                                      } else {
                                        let index = selectedChannels.findIndex(
                                          el1 => el.id == el1.id
                                        );
                                        let newValues = JSON.parse(
                                          JSON.stringify(selectedChannels)
                                        );
                                        newValues.splice(index, 1);
                                        setSelectedChannels(newValues);
                                        if (newValues.length != appChannels.length) {
                                          setChecked(false);
                                        };
                                      }
                                    }}
                                  ></Toggle><p style={{ color: 'black', fontSize: '16px', fontWeight: 500, margin: 0, marginLeft: '0.7rem', marginTop: '3px' }}><b>{group.name}</b> - {el.name}</p>
                                </div>
                              );
                            });
                        })}
                    </DisabledArea>
                  </div>
                </div>
              </Cell>
            </Grid>
          )}

          {tab == TAB_STATUS.TAGS && (
            <UserTags
              organization_id={organization_id}
              userTags={userTags}
              setUserTags={setUserTags}
              showLocationsTags={true}
            />
          )}

          {tab == TAB_STATUS.PERMISSIONS && (
            <UserEmailPermissions
              organization_id={organization_id}
              formEmailPermissions={formEmailPermissions}
              setFormEmailPermissions={setFormEmailPermissions}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => handleCancel()}
            kind={ButtonKind.tertiary}
          >
            Cancel
          </ModalButton>
          <ModalButton
            onClick={() => handleCreateUser()}
            kind={ButtonKind.tertiary}
            isLoading={isLoading}
            style={{ textTransform: 'capitalize' }}
          >
            Create {type}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = props => {
  const { groups } = props.appBasicControls;
  return {
    groups,
  };
};
export default connect(mapStateToProps)(AddUser);
