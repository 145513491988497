import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { LabelMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { FaRegCopy } from 'react-icons/fa'
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import ReactPlayer from 'react-player';

export default ({ library, onClose, onMessage, onDelete }) => {

  const [isOpen, setIsOpen] = React.useState(true);
  const [css, theme] = useStyletron();
  const { url, title, preview, uploaded_at} = library;

  const onCopyText = (url) => {
    navigator.clipboard.writeText(url)
    onMessage('Copied to clipboard!')
  };

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };

  return (
        <React.Fragment>
          <Modal
            overrides={{
              Backdrop: {
                style: ({ $theme }) => ({
                  backdropFilter: $theme.modalBackDropFilter,
                }),
              },
              Root: {
                style: {
                  zIndex: 3,
                }
              },
            }}
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
              onClose();
            }}
          >
            <ModalHeader>{}</ModalHeader>
            <ModalBody style={{ flex: '1 1 1' }}>
              <Grid
                gridGaps={[12, 12, 24]}
                overrides={{
                  Grid: {
                    style: ({ $theme }) => outerGridOverride,
                  },
                }}
              >
                <div style={{ width: '100%'}}>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem'}}>
                        {url && <ReactPlayer controls={true} url={url} width="100%" />}
                    </div>
                    <div>
                        <div
                            className={css({
                            })}
                            >
                            <LabelMedium style={{ marginBottom: '0.5rem' }}>File Name</LabelMedium>
                            <p>{library?.title || 'Library'}</p>
                            </div>
                            <div>
                                <LabelMedium style={{ marginBottom: '0.5rem' }}>File URL</LabelMedium>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <p style={{ maring: 0}}>{url}</p>
                                    <FaRegCopy style={{ cursor: 'pointer'}} fontSize={16}  onClick={()=>onCopyText(url)} />
                                </div>
                            </div>
                            <Cell
                                span={[1, 12]}
                                overrides={{
                                    Cell: {
                                    style: () => ({
                                        paddingLeft: '0px !important',
                                    }),
                                    },
                                }}
                            >
                                <LabelMedium style={{ marginBottom: '16px' }}>Uploaded date</LabelMedium>
                                <p>{uploaded_at ? moment(uploaded_at).format('MMMM Do YYYY, h:mm a') : 'Not provided'}</p>
                            </Cell>
                    </div>
                </div>
               
              </Grid>
            </ModalBody>
            <ModalFooter>
              <ModalButton
                onClick={() => {
                  onClose();
                }}
              >
               Cancel
              </ModalButton>
              <ModalButton
                onClick={() => {
                  onDelete();
                }}
              >
               Delete
              </ModalButton>
              <ModalButton>
                <a href={url} style={{color: 'white', textDecoration: 'none'}} target="_blank">Download</a>
          </ModalButton>
            </ModalFooter>
          </Modal>
        </React.Fragment>
  );
};


