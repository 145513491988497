import React from 'react';
import ColorPicker from '../../common/ColorPicker';

const ColorPickerCXL = ({ value, onChange }) => {
  console.log('ColorPickerXCL', { value: value, onChange: onChange})
  return (
    <ColorPicker
      color={value}
      onChange={onChange}
    />
  )
}

export default ColorPickerCXL;