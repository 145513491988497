import * as React from 'react';
import { LabelLarge, LabelMedium, ParagraphSmall } from 'baseui/typography';
import { useStyletron } from 'baseui';
import 'react-toggle/style.css';
import { useMutation } from '@apollo/client';
import { Textarea } from 'baseui/textarea';
import { addCardMutation, addCardStackCard } from '../../quries';
import { saveModalOnEnterPress } from '../../util';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { Grid, Cell } from 'baseui/layout-grid';
import { Input } from 'baseui/input';
import { card_help } from '../help/helpTexts';
import HelpQuestionMark from '../help/questionMarkHelp';
import titlesLength from '../../../data/titlesLength.json';
import ImportCardModal from './ImportCardModal';
import { cellPaddingOverrides } from '../overrides';
import { Search } from 'baseui/icon';
import Button from '../styledButton';

const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

export default ({
  cardStyle = 'basis',
  orgId,
  cards,
  home_component_id,
  defaultTheme,
  onCloseModal = () => { },
  modalProps = {},
  refetchHomeComponents,
  isCopyCard,
  onSearch = () => { }, 
  searchText,
  tabFilter
}) => {
  const [css, theme] = useStyletron();
  const [value, setValue] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
  const [subTitle, setSubTitle] = React.useState('');

  const [addCard, { loading: addCardLoading, data: createdCard }] = useMutation(
    addCardStackCard
  );

  React.useEffect(() => {
    setIsModalOpen(false);
  }, [createdCard]);

  const bgColor = '#5D64AB';

  const addCardOpen = () => {
    setIsModalOpen(true);
  };

  const onCloseImportModal = () => {
    setIsImportModalOpen(false)
  }

  const onSuccessfullyImport = () => {
    setIsImportModalOpen(false);
    setIsModalOpen(false);
    refetchHomeComponents();
  }

  const onSaveInterNal = async () => {
    if (value != '') {
      let d = {
        title: value,
        sub_title: subTitle || '',
        card_type: 'modular',
        front_image_data: null,
        link: null,
        back_image_data: null,
        back_description: null,
        youtube_video_id: null,
        file_data: null,
        archived: false,
        full_bleed: false,
        organization_id: orgId,
        created_by_id: '6e7bcba0-f194-4acf-b476-26d6594e88cb',
        has_feedback: false,
        card_favorite_count: 0,
        card_feedback_count: 0,
        created_by_name: '',
        position: cards.length,
        published: false,
        home_component_id,
        is_copy_card: isCopyCard
      };
      await addCard({
        variables: {
          input: d
        },
      });
      setValue('');
      setSubTitle('');
      refetchHomeComponents();
    }
  };

  const onClickSave = () => {
    onSaveInterNal();
    setTimeout(() => {
      onCloseModal();
    }, 1000);
  }

  const onClickImport = () => {
    setIsImportModalOpen(true)
  }

  return (
    <>
       <Cell overrides={cellPaddingOverrides} span={12} >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '20px' }}>
          <div style={{ width: '500px' }}>
            <Input
              startEnhancer={<Search
                className={css({
                  color: 'black !important',
                  cursor: 'pointer',
                })}
                size={20} />}
              value={searchText}
              onChange={onSearch}
              clearOnEscape
              placeholder='Search Card'
            />
          </div>
         {tabFilter !== "archived" && <Button
            marginTop='0px'
            isSelected
            onClick={addCardOpen}>Add Card</Button>}
        </div>
      </Cell>
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        onClose={() => {
          setIsModalOpen(false);
          setValue('');
          setSubTitle('');
          onCloseModal();
        }}
        closeable
        isOpen={isModalOpen}
        animate
        autoFocus
        {...modalProps}
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>
          <div
            className={css({
              display: 'flex',
            })}
          >
            Add {isCopyCard? 'Copy' : 'New'} Card
            <HelpQuestionMark text={card_help}></HelpQuestionMark>
          </div>
        </ModalHeader>
        <ModalBody>
          <Grid
            gridGaps={[12, 12, 24]}
            overrides={{
              Grid: {
                style: ({ $theme }) => outerGridOverride,
              },
            }}
          >
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
              <Input
                value={value}
                onChange={e => {
                  e.target.value.length <= titlesLength.card && setValue(e.target.value.replace('\n', ''))
                }}
                placeholder="Enter card title here"
                clearOnEscape
                onKeyDown={e =>
                  saveModalOnEnterPress({ e, onEnterPress: onSaveInterNal })
                }
              />
              <ParagraphSmall style={{ marginBottom: '0px' }}>{value.length}/{titlesLength.card}</ParagraphSmall>
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    paddingLeft: '0px !important',
                  }),
                },
              }}
            >
              <LabelMedium style={{ marginBottom: '16px' }}>Subtitle</LabelMedium>
              <Textarea
                value={subTitle}
                onChange={e => setSubTitle(e.target.value.replace('\n', ''))}
                placeholder="Enter card sub title here"
                clearOnEscape
                onKeyDown={e =>
                  saveModalOnEnterPress({ e, onEnterPress: onSaveInterNal })
                }
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          {
            !isCopyCard && (
              <ModalButton
                isLoading={addCardLoading}
                onClick={onClickImport}
              >
                Import
              </ModalButton>
            )
          }

          <ModalButton
            isLoading={addCardLoading}
            onClick={onClickSave}
          >
            Save
          </ModalButton>
        </ModalFooter>
      </Modal>
      <ImportCardModal
        isModalOpen={isImportModalOpen}
        cards={cards}
        onSuccessfullyImport={onSuccessfullyImport}
        onCloseModal={onCloseImportModal}
        home_component_id={home_component_id}
      />
    </>
  );
};
