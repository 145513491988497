import React from "react";
import ActionMetadataInput from "../generic_modules/ActionMetadataInput";
import BaseClass from "../../builder/cardBackEditorComponents/base";

class ActionMetadataInputEditor extends BaseClass {

  constructor(props) {
    super(props);
    const {
      root,
    } = props
    let button_action_type = '';
    if (root) {
      const {
        inputs = []
      } = root;

      const buttonActionInput = inputs.find(input => input.key === "button_action_type");
      if (buttonActionInput) {
        button_action_type = buttonActionInput?.value || '';
      }

    }
    this.state = {
      button_action_type,
      value: ''
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      root = {},
    } = nextProps;

    const {
      inputs = []
    } = root;

    const buttonActionInput = inputs.find(input => input.key === "button_action_type");
    let next_button_action_type = this.state.button_action_type;
    if (buttonActionInput) {
      next_button_action_type = buttonActionInput?.value || '';
    }

    if (buttonActionInput !== this.state.button_action_type) {
      this.setState({
        button_action_type: next_button_action_type
      })
    }

  }


  render() {
    const {
      button_action_type,
      value: button_action_metadata
    } = this.state;


    if (!button_action_type) {
      return null;
    }

    return (
      <ActionMetadataInput
        action_type={button_action_type}
        action_resource={button_action_metadata}
        theme={this.props.theme}
        onChange={(v) => {
          console.log('change', v);
          this.setState({
            value: v,
          })
        }}
        isEditor
      />
    )
  }
}

export default ActionMetadataInputEditor;