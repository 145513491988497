import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { FileUploader } from 'baseui/file-uploader';
import { Modal, ModalBody, ModalHeader, ModalFooter, ModalButton } from 'baseui/modal';
import { LabelMedium } from 'baseui/typography';
import gql from 'graphql-tag';

import RightSidePageHeader from '../channel/channelHeader';
import { client, getServerUrl } from '../../apollo/client';
import { Cell } from 'baseui/layout-grid';
import { cellPaddingOverrides } from '../overrides';
import AddContentButton from '../addContentButton';
import { useSubscription, useQuery, useLazyQuery } from '@apollo/client';
import { useStyletron } from 'baseui';
import { home_components_query } from '../../quries';
import { editorBtnOptions as CardBuilderOptions } from '../../../data/cardBuilderOptions';
import Category from '../category/category';
import { connect, useDispatch } from 'react-redux';
import axios from 'axios';
import { toaster } from 'baseui/toast';




const outerGridOverride = {
  marginTop: '30px',
  marginLeft: '0px !important',
  marginRight: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;

const useImportTagsSubscription = (request_id, subsQuery) => {
  const [importProgressData, setImportProgressData] = React.useState({})
  const { data: SubscriptionData, error: SubscriptionError } = useSubscription(
    subsQuery,
    { variables: { request_id } }
  );

  React.useEffect(() => {
    const {
      importSharepointFormTagTemplatesMultipleChannels = {}
    } = SubscriptionData || {};
    // console.log('useImportTagsSubscription SubscriptionData', SubscriptionData);
    setImportProgressData(importSharepointFormTagTemplatesMultipleChannels)
  }, [SubscriptionData]);

  return ({
    importProgressData
  })
}

export const app_content_scope_query = gql`
query app_content_scope($id: ID!){
  app_content_scope(id: $id) {
    id
    position
    name
    public
    published
    organization_id
    app_content_scope_group_id
    logo_data
    icon_data
    isNew
    pincode
    description
    content_controlled
    media_url
    abbr
    sales_button_enabled
    sales_incentive_button_title
    sales_incentive_description
    sales_incentive_title
    sales_incentive_image
    archived
    category_app_content_scopes {
      id
    category {
      name
      archived
      published
      position
      public
      created_by_id
      deck_count
      id
      organization_id
      description
      created_at
      layout
      has_data_source_connected
      api_path_id
      deck_style
      deck_categories {
        category_id
    deck_id
    deck_type
    position
    published
    isNew
    id
    deck_category_deck {
      title
      created_by_id
      cover_type
      cover_image_data
      icon_name
      id
      archived
      organization_id
      description
      card_count
      full_bleed
      home_featured
      organization_color_palette_index
      has_data_source_connected
      api_path_id
      show_answer_validation
      card_style
      use_card_template
      card_template
    }
      }
    }
    }
  }
  }
`;


export const content_template = gql`
  query content_template($organization_id: ID) {
    content_template(organization_id: $organization_id) {
      id
      channel_id
      updated_at
      categories {
        id
        category {
          name
          archived
          published
          position
          public
          created_by_id
          deck_count
          id
          organization_id
          description
          created_at
          layout
          has_data_source_connected
          api_path_id
          deck_style
          deck_categories {
            category_id
            deck_id
            deck_type
            position
            published
            isNew
            id
            deck_category_deck {
              title
              created_by_id
              cover_type
              cover_image_data
              icon_name
              id
              archived
              organization_id
              description
              card_count
              full_bleed
              home_featured
              organization_color_palette_index
              has_data_source_connected
              api_path_id
              show_answer_validation
              card_style
              use_card_template
              card_template
            }
          }
        }
      }
    }
  }
`;

export const import_category_to_channel = gql`
  mutation importCategories($category_ids: [ID], $channel_id: ID, $organization_id: ID) {
    importCategories(category_ids: $category_ids, channel_id: $channel_id, organization_id: $organization_id)
  }
`


const ImportTemplatesFromJson = ({
  isVisible,
  organization_id,
  theme,
  onRefresh,
  onClose,
  template_id,
  onImportSuccess,
  onImportError
}) => {
  const [importFile, setImportFile] = useState();
  const [importLoading, setIsImportLoading] = useState();

  const onCloseImportModal = () => {
    if (onClose) {
      onClose()
    }
  }

  const onClickImport = () => {
    setIsImportLoading(true);

    const formData = new FormData();
    formData.append('file', importFile);
    // formData.append('organization_id', organization_id);

    let url = `${getServerUrl()}/import_as_template/${organization_id}`;

    if (template_id) {
      url = `${url}?template_id=${template_id}`
    }

    if (!url) return;

    axios.post(url, formData)
      .then(async (result) => {
        console.log(result);
        await onImportSuccess();
        setIsImportLoading(false);
      }).catch((err) => {
        console.log('error import', err)
        onImportError()
      }).finally(() => {
        setIsImportLoading(false);
      })
  }

  return (
    <Modal
      isOpen={isVisible}
      onClose={onCloseImportModal}
      returnFocus={false}
    >
      <ModalHeader>Import templates</ModalHeader>
      <ModalBody style={{ flex: '1 1 0' }}>
        {importFile && <LabelMedium>File Selected {importFile.name}</LabelMedium>}
        <FileUploader
          onDrop={(acceptedFiles, rejectedFiles) => {
            setImportFile(acceptedFiles[0])
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ModalButton
          isLoading={importLoading}
          disabled={!importFile}
          onClick={onClickImport}
        >
          {'Import'}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

const channelId = "6a6874ed-0d98-42e0-aca0-8b17131bd7bf";

const Templates = ({
  organization_id,
  refreshDecks,
  refreshCards,
  refreshQuizQuestions,
  refreshCategories,
  updated_at,
  set_updated_at,
  ...props
}) => {
  const [css, theme] = useStyletron();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [channelData, setChannelData] = useState(false);
  const [componentsListData, setComponentsListData] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [home_component_app_content_scopes, setHomeComponents] = useState([]);
  const [content_template_id, setContentTemplateId] = useState();
  const [channelId, setChannelId] = useState("");

  const dispatch = useDispatch();

  const [fetchCategorys, { }] = useLazyQuery(content_template, {
    variables: { organization_id },
    fetchPolicy: 'network-only'
  })
  // const [fetchHomeComponents, { }] = useLazyQuery(home_components_query(channelId, CardBuilderOptions), {
  //   fetchPolicy: 'network-only'
  // })

  useEffect(() => {
    refetchContentTemplate();
    // refreshDecks();
    // refreshCards();
    // refreshQuizQuestions();
    // refetchHomeComponents()
  }, [])


  // const fetchAllData = async () => {
  //   const { data: category_data } = await fetchCategorys();
  //   const {
  //     app_content_scope = {}
  //   } = category_data || {};

  //   setChannelData(app_content_scope)

  //   const {
  //     category_app_content_scopes = []
  //   } = app_content_scope || {}
  //   const categories = category_app_content_scopes.map(el => {
  //     const {
  //       category
  //     } = el || {}
  //     return category
  //   }) || []

  //   setCategoryList(category_app_content_scopes);

  //   const { data: home_components_data } = await fetchHomeComponents();

  //   const {
  //     home_component_app_content_scopes_org = []
  //   } = home_components_data;

  //   const mergedComponentList = [
  //     ...categories,
  //     ...home_component_app_content_scopes_org
  //   ]

  //   setHomeComponents(home_component_app_content_scopes_org)

  //   setComponentsListData(mergedComponentList)
  // }

  const refetchHomeComponents = async () => {
    // const { data: home_components_data } = await fetchHomeComponents();

    // let {
    //   home_component_app_content_scopes_org = []
    // } = home_components_data;
    // const sorted = home_component_app_content_scopes_org.sort((a,b)=> a.position - b.position)
    // setHomeComponents(sorted)
  }

  const refetchContentTemplate = async () => {
    const { data: content_template_data } = await fetchCategorys();
    const {
      content_template = {}
    } = content_template_data || {};

    let {
      id,
      channel_id,
      categories = [],
      updated_at
    } = content_template || {}

    if (channel_id) {
      setChannelId(channel_id)
    }

    if (id) {
      setContentTemplateId(id)
    }

    let sorted = [
      ...categories || []
    ]

    sorted = sorted.sort((a, b) => a.position - b.position)
    set_updated_at(updated_at);
    setCategoryList(sorted);
  }

  const showToaster = (msg, method = 'info') => {
    let toastKey = toaster[method](
      <>
        {msg}
      </>,
      {
        overrides: {
          InnerContainer: {
            style: { width: "100%" }
          }
        }
      }
    );
    setTimeout(() => { toaster.clear(toastKey) }, 3000);
  };

  /////////////////////////////////////////////////////////////  Event Function ////////////////////////////////////////////////////////////////////

  const onOpenImportModal = () => {
    setIsImportModalOpen(true)
  }

  const onCloseImportModal = () => {
    setIsImportModalOpen(false)
  }

  const onImportSuccess = async () => {
    try {
      showToaster('Templates import successfully', 'positive');
      await refreshCategories();
      refreshDecks();
      refreshCards();
      refreshQuizQuestions();
      await refetchContentTemplate();
      onCloseImportModal();
    } catch (err) {
      console.log('onImportSuccess error', err)
    }

  }

  const onImportError = () => {
    showToaster('Something went wrong in templates import', 'negative');
    refetchContentTemplate();
  }

  ///////////////////////////////////////////////////////////// Render Function ////////////////////////////////////////////////////////////////////
  const renderHeader = () => {

    return (
      <Cell overrides={cellPaddingOverrides} span={12} >
        <div style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '-40px',
          paddingLeft: '22px',
          paddingRight: '22px',
          paddingBottom: '30px'
        }}>
          <div
            style={{
              width: '35%'
            }}
          >
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              className={css({
                display: 'flex',
                flexDirection: 'row'
              })}
            >
              <AddContentButton
                buttonText={'Import'}
                helpText={`Click to import templates`}
                buttonClickOverride={onOpenImportModal}
              />
            </div>

          </div>
        </div>
      </Cell>
    )
  }

  // console.log('--------->', { componentsListData, channelData, organization_id, content_template_id });
  return (
    <Container>
      {renderHeader()}
      <div
        className={
          css({
            display: 'flex',
            flex: 1,
          })
        }
      >
        <Category
          dispatch={dispatch}
          organization_id={organization_id}
          tabFilter={'all'}
          categoryList={categoryList}
          home_component_app_content_scopes={home_component_app_content_scopes}
          app_content_scope_id={channelId}
          refreshQuizQuestions={() => { }}
          defaultTheme={theme}
          isTemplate={true}
          refetchHomeComponents={refetchHomeComponents}
          refreshCategories={refetchContentTemplate}
          refreshDecks={refreshDecks}
          refreshCards={refreshCards}
          {...props}
        />
      </div>
      <ImportTemplatesFromJson
        isVisible={isImportModalOpen}
        organization_id={organization_id}
        theme={theme}
        onClose={onCloseImportModal}
        onImportSuccess={onImportSuccess}
        onImportError={onImportError}
        template_id={content_template_id}
      />
    </Container>
  )
}

const mapStateToProps = props => {
  const { category, cards, decks, theme, isLoading,  jwPlayerData, libraries } = props.appBasicControls;
  return { category, decks, theme, cards, isLoading, jwPlayerData, libraries };
};
export default connect(mapStateToProps)(Templates);