import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { Spinner } from 'baseui/spinner';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { client } from '../../apollo/client';
import _ from 'lodash';
import { getTags } from '../../quries';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import { Accordion, Panel } from 'baseui/accordion';
import styled from '@emotion/styled';

const HeaderTitle = styled.h5`
  margin-top: 16px;
  margin-bottom: 11px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
`

const TagsList = ({
    tags = [],
    onChange,
    getCheckedStatus
}) => {

    const [css, theme] = useStyletron();

    return tags.map((el, i2) => {
        const isChecked = getCheckedStatus(el.id);
        const onChangeStatus = (e) => {
            onChange(e, el);
        }
        return (
            <div
                key={i2}

                className={css({
                    paddingTop: theme.sizing.scale300,
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    minWidth: '300px',
                    float: 'left',
                    display: 'block'
                })}
            >
                <Checkbox
                    checked={isChecked}
                    onChange={onChangeStatus}
                    labelPlacement={LABEL_PLACEMENT.right}
                >
                    {`${el.name}`}
                </Checkbox>
            </div>
        );
    })
}


export default ({ user, organization_id, userTags, setUserTags, showSelectAll, inviteUsers, showLocationsTags = false }) => {

    const [css, theme] = useStyletron();
    const [tags, setTags] = React.useState([]);
    const [filtered, setFiltered] = React.useState([]);
    const [searchText, setSearchText] = React.useState('');
    const [userTagsLength, setUserTagsLength] = React.useState(0);

    const loadTags = () => {
        client.query({
            query: getTags(organization_id),
            fetchPolicy: 'network-only'
        }).then(({ data }) => {
            const teamTagsList = data.tags_org.filter(tag => (tag.type == 'Teams' || (showLocationsTags == true && tag.type == 'Locations')))
            setTags(teamTagsList)
        })
    };


    React.useEffect(() => {
        setUserTagsLength(userTags.filter(item => item.checked).length)
    }, [userTags]);

    React.useEffect(() => {
        loadTags()
    }, []);

    React.useEffect(() => {
        if (searchText == '') {
            setFiltered(tags)
        } else {
            setFiltered(tags.filter(e => e.name.toLowerCase().startsWith(searchText)))
        }
    }, [searchText, tags]);

    const getCheckedStatus = (valueId) => {
        let isExists = userTags.find(el => {
            return (
                el.tag_id == valueId
            );
        });
        if (!isExists) return false;
        if (isExists.checked === undefined) return true;
        return isExists.checked;
    };

    const markChecked = (tagId, checked) => {
        let tag = userTags.findIndex(el => {
            return (
                el.tag_id == tagId
            );
        });
        let userTagCopy = JSON.parse(JSON.stringify(userTags));
        if (tag == -1) {
            userTagCopy.push({
                tag_id: tagId,
            });
            tag = userTagCopy.length - 1;
        }
        userTagCopy[tag].checked = checked;
        setUserTags(userTagCopy);
    };


    const handleSelectAll = (isChecked) => {
        let tagSelect = tags?.map(item => {
            return {
                checked: isChecked,
                tag_id: item.id
            }
        })
        setUserTags(tagSelect);
    }

    const onChangeCheckedStatus = (e, item) => {
        markChecked(item.id, e.target.checked)
    }

    const teamTags = React.useMemo(() => {
        return filtered.filter(item => item.type == 'Teams');
    }, [filtered])

    const locationTags = React.useMemo(() => {
        return filtered.filter(item => item.type == 'Locations');
    }, [filtered])

    return (
        <>
            <Grid overrides={gridPaddingOverrides}>
                <Cell overrides={cellPaddingOverrides} span={12}>
                    <div
                        className={css(!inviteUsers && {
                            padding: '24px',
                            marginBottom: '24px',
                        })}
                    >
                        {tags.length <= 0 ? (
                            <div
                                className={css({
                                    position: 'absolute',
                                    width: theme.sizing.scale1200,
                                    height: theme.sizing.scale1200,
                                    left: '50%',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                })}
                            >
                                <Spinner />
                            </div>
                        ) : (
                            <Grid overrides={gridPaddingOverrides}>

                                <div
                                    style={{
                                        float: 'left',
                                        width: '100%',
                                        marginRight: '48px',
                                        paddingTop: '16px',
                                        paddingBottom: '16px',

                                    }}>
                                    <Input
                                        placeholder="Search by name"
                                        clearOnEscape
                                        clearable
                                        onChange={search => setSearchText(search.target.value.toLowerCase())}
                                        endEnhancer={<Search size="18px" />}
                                    />
                                </div>
                                {showSelectAll && <div
                                    className={css({
                                        paddingTop: theme.sizing.scale300,
                                        paddingRight: '24px',
                                        marginBottom: '8px',
                                        minWidth: '300px',
                                        float: 'left',
                                        display: 'block'
                                    })}
                                >
                                    <Checkbox
                                        onChange={event =>
                                            handleSelectAll(event.target.checked)
                                        }
                                        labelPlacement={LABEL_PLACEMENT.right}
                                        isIndeterminate={userTagsLength > 0 && userTagsLength < filtered.length}
                                        checked={filtered.length === userTagsLength}
                                    >
                                        Select All Tags
                                    </Checkbox>
                                </div>}

                                <div
                                    style={{
                                        position: 'relative',
                                        float: 'left',
                                        width: '100%',
                                        backgroundColor: theme.colors.primaryB,
                                        marginRight: inviteUsers ? '8px' : '48px',
                                        paddingTop: inviteUsers ? '8px' : '16px',
                                        paddingBottom: '16px',
                                        borderRadius: '4px',
                                        marginBottom: '48px',
                                    }}
                                >
                                    {/* {filtered.map((el, i2) => {
                                                return (
                                                    <div
                                                        key={i2}

                                                        className={css({
                                                            paddingTop: theme.sizing.scale300,
                                                            paddingLeft: '24px',
                                                            paddingRight: '24px',
                                                            minWidth: '300px',
                                                            float: 'left',
                                                            display: 'block'
                                                        })}
                                                    >
                                                        <Checkbox
                                                            checked={getCheckedStatus(el.id)}
                                                            onChange={e =>
                                                                markChecked(el.id, e.target.checked)
                                                            }
                                                            labelPlacement={LABEL_PLACEMENT.right}
                                                        >
                                                            {`${el.name} (${el.type})`}
                                                        </Checkbox>
                                                    </div>
                                                );
                                            })} */}
                                    <Accordion

                                        accordion
                                        overrides={{
                                            Root: {
                                                style: ({ $theme }) => ({
                                                    marginLeft: 0,
                                                })
                                            },
                                            PanelContainer: {
                                                style: ({ $theme }) => ({
                                                    backgroundColor: 'white',
                                                    marginBottom: '1rem'
                                                })
                                            }
                                        }}
                                    >
                                        <Panel
                                            title={
                                                <div
                                                    className={css({
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'

                                                    })}
                                                    id={'position_teams'}
                                                    key={'position_teams'}
                                                >
                                                    <HeaderTitle>Teams</HeaderTitle>
                                                </div>
                                            }
                                        >
                                            <TagsList
                                                tags={teamTags}
                                                getCheckedStatus={getCheckedStatus}
                                                onChange={onChangeCheckedStatus}
                                            />

                                        </Panel>
                                        {
                                            showLocationsTags && (
                                                <Panel
                                                    title={
                                                        <div
                                                            className={css({
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'

                                                            })}
                                                            id={'position_locations'}
                                                            key={'position_locations'}
                                                        >
                                                            <HeaderTitle>Locations</HeaderTitle>
                                                        </div>
                                                    }
                                                >
                                                    <TagsList
                                                        tags={locationTags}
                                                        getCheckedStatus={getCheckedStatus}
                                                        onChange={onChangeCheckedStatus}
                                                    />

                                                </Panel>
                                            )
                                        }
                                    </Accordion>

                                </div>
                            </Grid>
                        )}
                    </div>
                </Cell>
            </Grid>
        </>
    );
};
