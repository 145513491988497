import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { arrayMove } from 'baseui/dnd-list';
import {
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import Portal from './portal';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { FlexGrid } from 'baseui/flex-grid';
import { DragDropContext } from 'react-beautiful-dnd';
import AddCardBackHomeComponent from './addCardBackHomeComponent';
import { sourceByType } from '../../util';
import styled from '@emotion/styled';
import { editorBtnOptions as CardBuilderOptions } from '../../../data/cardBuilderOptions';
import { getUpdateMutationsForHomeComponent } from '../builder/builderUtils/save';
import { client } from '../../apollo/client';
import { ButtonCardModule, CardLinkButtonCardModule, PhoneButtonCardModule, ImageCardModule } from '../builder/previewElementsV1';
import EditHomeBackComponent from './editCardBackHomeComponent';
import Toggle from 'react-toggle';
import { StatefulTooltip } from 'baseui/tooltip';
import 'react-toggle/style.css';
import Card from '../cards/card';
import { showAlert } from '../../redux/actions/appBasicControls';
import { FormBuilderHomeComponent } from '../builder/formBuilder';
import ImportFormModal from '../cards/ImportFormModal';
import AddMultimediaCard from '../cards/addEditMultimediaCard';


const getSrc = (data, type) => {
  if (!data) return data;
  return sourceByType(data, type);
};

export default ({
  selectedComponent,
  appContentScopeId,
  organization_id,
  home_component_app_content_scopes,
  refetchHomeComponents,
  tabFilter,
  dispatch,
  defaultTheme,
  searchText,
  jwPlayerData,
  libraries
}) => {
  const [css, theme] = useStyletron();
  const [modules, setModules] = React.useState([]);
  const componentType = selectedComponent.home_component.component_type;
  const currentComponentConfig = CardBuilderOptions.find((el) => el.__typename == componentType);
  const updateMutation = getUpdateMutationsForHomeComponent(currentComponentConfig);

  const [isEditOpen, setIsEditOpen] = React.useState(false);
  const [selectedModule, setSelectedModule] = React.useState(null);
  const [isImportOpen, setIsImportOpen] = useState(false);

  const updatePosition = async (id, position) => {
    await client.mutate({
      mutation: updateMutation,
      variables: { id, position }
    });
    refetchHomeComponents();
    return;
  };

  const updatePublished = async (id, published, data = {}) => {
    let o = data || {};
    Object.keys(o).forEach(el => {
      if (typeof o[el] === 'object') {
        if (Array.isArray(o[el])) {
          o[el] = o[el].map(el1 => {
            delete el1.__typename;
            return el1;
          })
        } else {
          console.log({ o, el })
          if (o && el && o[el] && o[el].__typename) {
            delete o[el].__typename;
          }
        }
      }
    });

    dispatch(showAlert({
      msg: 'Updating Home Component'
    }))
    await client.mutate({
      mutation: updateMutation,
      variables: {
        id,
        ...data,
        published
      }
    });
    refetchHomeComponents()
    // .then(() => {
    dispatch(showAlert({
      msg: 'Home Component Updated'
    }))
    // })
    return;
  };

  const onClose = () => {
    setIsEditOpen(false);
    setSelectedModule(null);
  }



  React.useEffect(() => {
    let s = JSON.parse(JSON.stringify(selectedComponent.home_component.card_content_modules));
    s = s.filter((el) => {
      if (tabFilter === 'published') {
        return el.published == true && el.archived !== true;
      }
      if (tabFilter === 'archived') {
        return el.archived === true
      }
      if (tabFilter === 'all') {
        return el.archived !== true
      }
      return true;
    });
    setModules([...s].sort((a, b) => a.position - b.position));
  }, [selectedComponent]);

  const AddDeckContainer = styled.div`
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% + 21px);
    top: 0;
    box-shadow: -4px 1px 2px rgba(0, 0, 0, 0.1);
    background: ${theme.colors.primary50};
    padding-left: 25px;
  `;

  const isSharepointFormModule = useMemo(() => {
    if (selectedModule && selectedModule !== null) {
      return selectedModule.content_module_type === "SharePointFormModule"
    }
    return false;
  }, [selectedModule]);

  const isMultimediaCardModule = useMemo(() => {
    if (selectedModule && selectedModule !== null) {
      return selectedModule.content_module_type === "MultimediaCardModule"
    }
    return false;
  }, [selectedModule]);

  const showAddButton = useMemo(() => {
    if (selectedComponent.home_component && selectedComponent.home_component.component_type === "SharePointFormModule" && selectedComponent.home_component.is_single_form === true) {
      const statuss = selectedComponent.home_component && selectedComponent.home_component.card_content_modules && selectedComponent.home_component.card_content_modules.length <= 0 ? true : false;
      return statuss
    }
    return true;
  }, [selectedComponent]);

  const onImportClick = () => {
    setIsImportOpen(true)
  }

  const onCloseImportModal = () => {
    setIsImportOpen(false)
  }

  const onSuccessfullyImport = () => {
    onCloseImportModal();
    refetchHomeComponents();
  }

  const AddButton = useMemo(() => {
    if (selectedComponent.home_component.component_type === 'MultimediaCardModule') {
      return (
        <AddMultimediaCard
          cardStyle="Version1"
          position={modules.length}
          home_component_id={selectedComponent.home_component.id}
          orgId={organization_id}
          refetchHomeComponents={refetchHomeComponents}
          modalProps={{ returnFocus: false }}
        />
      )
    }

    return (
      <AddCardBackHomeComponent
        position={modules.length}
        app_content_scope_id={appContentScopeId}
        organization_id={organization_id}
        home_component_id={selectedComponent.home_component.id}
        refetchHomeComponents={refetchHomeComponents}
        selectedComponentType={currentComponentConfig}
        jwPlayerData={jwPlayerData}
        libraries={libraries}
        dispatch={dispatch}
        onImportClick={onImportClick}
      ></AddCardBackHomeComponent>
    )
  }, [modules, appContentScopeId, organization_id, selectedComponent, currentComponentConfig, jwPlayerData, libraries])




  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <Cell span={12} overrides={cellPaddingOverrides}>
          <div id={`wrapper_deck${selectedComponent.id}`} className="wrapper" style={{
            paddingTop: theme.sizing.scale600,
            minHeight: '274px'
          }}>
            <FlexGrid>
              <DragDropContext
                onDragEnd={({ destination, source, ...rest }) => {
                  if (!destination) {
                    return;
                  }
                  let newArr = arrayMove(
                    JSON.parse(JSON.stringify(modules)),
                    source.index,
                    destination.index
                  );
                  newArr = newArr.map((el, i) => {
                    el.position = i;
                    delete el.isNew;
                    return el;
                  });
                  newArr.forEach(el => {
                    updatePosition(el.content_module.id, el.position);
                  });
                  setModules(newArr);
                }}
              >
                <Droppable
                  droppableId={selectedComponent.id}
                  type={'HOME_COMPONENT'}
                  direction="horizontal"
                  isCombineEnabled={false}
                >
                  {dropProvided => (
                    <div
                      className="scrolls"
                      ref={dropProvided.innerRef}
                      style={{
                        display: 'flex',
                        // overflow: 'auto',
                        flexGrow: 1,
                        paddingTop: '3px',
                        marginRight: '200px',
                        scrollbarWidth: 'thin',
                        scrollbarColor: defaultTheme === "dark" ? 'black #282828' : "white black"
                      }}
                    >
                      {modules.map((el, index) => {
                        if (searchText && el.content_module.title) {
                          const isInSearch = el.content_module.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
                          if (!isInSearch) return;
                        }
                        return (
                          <Draggable
                            key={el.content_module.id}
                            draggableId={el.content_module.id}
                            index={index}
                          >
                            {(dragProvided, snapshot) => (
                              <>
                                <Portal
                                  snapshot={snapshot}
                                  child={
                                    <div
                                      {...dragProvided.dragHandleProps}
                                      {...dragProvided.draggableProps}
                                      ref={dragProvided.innerRef}
                                      onClick={() => {
                                        setSelectedModule(el);
                                        setIsEditOpen(true);
                                      }}
                                    >
                                      <Card
                                        key={index}
                                        title={el.content_module.title || el.content_module.data_label || ''}
                                        cover_type={"icon"}
                                        full_bleed
                                        // icon_name={currentComponentConfig.iconName}
                                        custom_icon={currentComponentConfig.customIcon}
                                        toggle={el.published}
                                        tabFilter={tabFilter}
                                        onToggleClick={e => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          updatePublished(el.content_module.id, e.target.checked, el.content_module);
                                          let m = JSON.parse(JSON.stringify(modules));
                                          let indexToUpdate = m.findIndex(m0 => m0.content_module.id == el.content_module.id);
                                          m[indexToUpdate].published = e.target.checked;
                                          setModules(m);
                                        }}
                                        onMoveDeck={selectedChannel => {

                                        }}
                                        onArchive={selectedChannel => {

                                        }}
                                        onUnArchive={selectedChannel => {

                                        }}
                                        onSettingsClick={e => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setSelectedModule(el);
                                          setIsEditOpen(true);
                                        }}
                                        onClone={e => {

                                        }}
                                        isDeck={true}
                                      ></Card>
                                      {/* <div className="overlap-layer card-new-gradient-style" style={{
                                        width: 'auto', height: 'auto'
                                      }}>
                                        <StatefulTooltip
                                          content={() => (
                                            <div padding={'20px'}>{el.published ? 'Toggle off to Unpublish' : 'Toggle on to Publish'}</div>
                                          )}
                                          returnFocus
                                          autoFocus
                                        >
                                          <div
                                            className={css({
                                              position: 'absolute',
                                              width: theme.sizing.scale1200,
                                              left: theme.sizing.scale300,
                                              top: theme.sizing.scale100,
                                              zIndex: 2
                                            })}
                                            onClick={e => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                            }}
                                          >
                                            <Toggle
                                              id="cheese-status"
                                              checked={el.published}
                                              icons={false}
                                              onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                              }}
                                              onChange={e => {
                                                updatePublished(el.content_module.id, e.target.checked);
                                                let m = JSON.parse(JSON.stringify(modules));
                                                let indexToUpdate = m.findIndex(m0 => m0.content_module.id == el.content_module.id);
                                                m[indexToUpdate].published = e.target.checked;
                                                setModules(m);
                                              }}
                                            />
                                          </div>
                                        </StatefulTooltip>
                                        {el.content_module_type == 'CardLinkButtonCardModule' && <CardLinkButtonCardModule
                                          title={el.content_module.title}
                                          background={theme.colors.primaryC}
                                          overrideStyle={{
                                            marginBottom: '0px',
                                            paddingLeft: '0px',
                                            paddingRight: '0px',
                                          }}
                                        ></CardLinkButtonCardModule>}
                                        {el.content_module_type == 'ButtonCardModule' && <ButtonCardModule
                                          title={el.content_module.title}
                                          background={theme.colors.primaryC}
                                          overrideStyle={{
                                            marginBottom: '0px',
                                            paddingLeft: '0px',
                                            paddingRight: '0px',
                                          }}
                                        ></ButtonCardModule>}
                                        {el.content_module_type == 'PhoneButtonCardModule' && <PhoneButtonCardModule
                                          title={el.content_module.title}
                                          background={theme.colors.primaryC}
                                          overrideStyle={{
                                            marginBottom: '0px',
                                            paddingLeft: '0px',
                                            paddingRight: '0px',
                                          }}
                                        ></PhoneButtonCardModule>}
                                        {el.content_module_type == 'TableCardModule' && <PhoneButtonCardModule
                                          title={el.content_module.title}
                                          background={theme.colors.primaryC}
                                          overrideStyle={{
                                            marginBottom: '0px',
                                            paddingLeft: '0px',
                                            paddingRight: '0px',
                                          }}
                                        ></PhoneButtonCardModule>}
                                        {el.content_module_type == 'ImageCardModule' && <ImageCardModule
                                          url={getSrc(el.content_module.file_data, 'small')}
                                          overrideStyle={{
                                            marginBottom: '0px',
                                            paddingLeft: '0px',
                                            paddingRight: '0px',
                                            width: '400px',
                                            height: '251px'
                                          }}
                                        ></ImageCardModule>}
                                        {el.content_module_type == 'SalesforceAggregatePointsModule' && <PhoneButtonCardModule
                                          title={el.content_module.point_source_title}
                                          background={theme.colors.primaryC}
                                          overrideStyle={{
                                            marginBottom: '0px',
                                            paddingLeft: '0px',
                                            paddingRight: '0px',
                                          }}
                                        ></PhoneButtonCardModule>}
                                      </div> */}
                                    </div>
                                  }
                                ></Portal>
                              </>
                            )}
                          </Draggable>
                        );
                      })}
                      {
                        showAddButton && <AddDeckContainer>
                          {/* <AddCardBackHomeComponent
                            position={modules.length}
                            app_content_scope_id={appContentScopeId}
                            organization_id={organization_id}
                            home_component_id={selectedComponent.home_component.id}
                            refetchHomeComponents={refetchHomeComponents}
                            selectedComponentType={currentComponentConfig}
                            jwPlayerData={jwPlayerData}
                            libraries={libraries}
                            dispatch={dispatch}
                            onImportClick={onImportClick}
                          ></AddCardBackHomeComponent> */}
                          {AddButton}
                          <ImportFormModal
                            isModalOpen={isImportOpen}
                            onCloseModal={onCloseImportModal}
                            home_component_id={selectedComponent.home_component.id}
                            forms={modules}
                            onSuccessfullyImport={onSuccessfullyImport}
                          />
                        </AddDeckContainer>
                      }

                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </FlexGrid>
          </div>
        </Cell>
      </Grid>
      {(isEditOpen && !isSharepointFormModule && !isMultimediaCardModule) && <EditHomeBackComponent
        app_content_scope_id={appContentScopeId}
        organization_id={organization_id}
        home_component_id={selectedComponent.home_component.id}
        refetchHomeComponents={refetchHomeComponents}
        selectedComponentType={currentComponentConfig}
        selectedModule={selectedModule}
        onClose={onClose}
        updateMutation={updateMutation}
        jwPlayerData={jwPlayerData}
        libraries={libraries}
        dispatch={dispatch}
      ></EditHomeBackComponent>}
      {isEditOpen && isSharepointFormModule && <FormBuilderHomeComponent
        app_content_scope_id={appContentScopeId}
        organization_id={organization_id}
        home_component_id={selectedComponent.home_component.id}
        refetchHomeComponents={refetchHomeComponents}
        selectedComponentType={currentComponentConfig}
        selectedModule={selectedModule}
        home_component={selectedComponent?.home_component}
        onClose={onClose}
        updateMutation={updateMutation}
        jwPlayerData={jwPlayerData}
        libraries={libraries}
        dispatch={dispatch}
      ></FormBuilderHomeComponent>}

      {isEditOpen && isMultimediaCardModule && <AddMultimediaCard
        app_content_scope_id={appContentScopeId}
        orgId={organization_id}
        home_component_id={selectedComponent.home_component.id}
        refetchHomeComponents={refetchHomeComponents}
        cardData={selectedModule?.content_module}
      />}
    </>
  )
}