import React from "react";
import { getServerUrl } from '../apollo/client';
import { toaster } from "baseui/toast";
/**
 * 
 * @param {*} data image data from server
 * @param {*} type type of image small, full medium etc
 * @param {*} forceType force any type on older version of images
 * @param {*} image_type cover or thumbnail for new version 2
 */
export const sourceByType = (data, type = 'small', forceType, image_type) => {
  if (!data) return data;
  let t = JSON.parse(data);
  console.log('getting image for size ' + type + ' for version ' + t._version);
  if (t && t.file_info && t.file_info.mime_type.indexOf('image/') == -1) {
    // for file attachment
    if (type && t.metadata[`${type}_url`]) {
      return t.metadata[`${type}_url`]
    }
    return t.metadata.url;
  }
  if (t._version == '2.0') {
    return sourceByTypeV2({
      data: t,
      type,
      image_type
    });
  };
  // for older versions returning full size until all images are fixed
  type = forceType || 'full';
  if (!t.path || !t.metadata) {
    if (t.full && t.full.full) {
      t.full = t.full.full;
    };
    if (t.full && t.full.id && t.full.storage) {
      return `https://storage.googleapis.com/catalystxl-admin-api/${t.full.storage}/${t.full.id}`
    };
    return '';
  };
  if (t.path && t.metadata) {
    return t.metadata[`${type}_url`] || t.metadata[`full_url`];
  }
  return t.path || t.metadata.full_url.replace('cache', 'store');
};


const sourceByTypeV2 = ({ data, type = 'small', image_type = 'cover' }) => {
  if (type == 'full_url' || type == 'full' || type == 'url') return data.metadata.full_url || data.metadata.url;
  return data.metadata[type] || data.metadata.full_url || data.metadata.url;
};

/**
 *
 * @param {*} e key press event from input field
 * @param {function} onEnterPress method to call enter hit
 */
export const saveModalOnEnterPress = ({ e, onEnterPress }) => {
  if (e.key === 'Enter') {
    onEnterPress && onEnterPress();
  }
};

export const getSrc = (data, type, forceType, image_type) => {
  try {
    if (!data) return data;
    return sourceByType(data, type, forceType, image_type);
  } catch (e) {
    return null;
  }
};

export const getType = data => {
  try {
    if (!data) return '';
    return (
      JSON.parse(data).mimetype || JSON.parse(data).full.metadata.mime_type
    );
  } catch (e) {
    return '';
  }
};

export const getName = data => {
  try {
    data = JSON.parse(data);
    if (!data) return '';
    if (data.filename) return data.filename;
    if (data.full.metadata && data.full.metadata.filename) return data.full.metadata.filename;
    if (data.file_info.filename) return data.file_info.filename;
    return '';
  } catch (e) {
    return '';
  }
};

export const getAttachmentUrls = (data, type = 'Attchment') => {
  if (!data) return [];
  try {
    if (Array.isArray(data)) {
      let result = [];
      data.map(el => {
        if (el.file_data) {
          const file = JSON.parse(el.file_data);
          result.push({
            name: file.file_info.filename,
            size: file.file_info.size
          })
        } else {
          let isFormattedFileData = false;
          let isFileData = false;
          if (el && el.file) {
            isFileData = true;
            isFormattedFileData = true;
          };
          if (el && el.path && el.size) isFileData = true;
          if (isFormattedFileData) el = el.file;
          if (isFileData) {
            result.push({
              name: el.name,
              size: el.size
            });
          }
        }
      });
      return result;
    }
    return [];
  }
  catch (e) {
    return [];
  }
}


export const getFilesUrls = (value, type, forceType, image_type, module_type) => {
  let data = value;
  if (!data) return [];
  if (module_type && module_type == 'CSV Card') {
    let data1
    try {
      data1 = JSON.parse(data)
    } catch {
      data1 = null
    }
    if (Array.isArray(data1)) {
      let data2 = []
      data1 = data1.map((item, index) => {
        if (index == 0) {
          let val = ''
          for (let key in item) {
            val += key + ','
          }
          data2.push(val);
        } else {
          let val = ''
          for (let key in item) {
            val += item[key] + ','
          }
          data2.push(val);
        }

      })
      const previewUrl = URL.createObjectURL(new Blob([data2.join('\n')], { type: 'text/csv' }));
      return [previewUrl]
    }
  }

  let isMultiple = false;

  if (typeof data == 'string') data = [{ file_data: data }];
  if (Array.isArray(data)) isMultiple = true;

  let paths = data.map(el => {
    let isFormattedFileData = false;
    let isFileData = false;
    if (el && el.file) {
      isFileData = true;
      isFormattedFileData = true;
    };
    if (el && el.path && el.size) isFileData = true;
    if (isFormattedFileData) el = el.file;
    if (isFileData) {
      return {
        src: URL.createObjectURL(el),
        name: el.name,
      };
    }
    return {
      src: getSrc(el.file_data, type, forceType, image_type),
      name: getName(el.file_data),
    };
  });
  return paths.map((el) => el.src);
}


export const getFileName = (value) => {
  let data = value;
  if (!data) return '';

  if (data.name) {
    return data.name;
  }

  let isFormattedFileData = false;
  let isFileData = false;
  if (data && data.file) {
    isFileData = true;
    isFormattedFileData = true;
  };
  if (data && data.path && data.size) isFileData = true;
  if (isFormattedFileData) data = data.file;
  if (isFileData) {
    return data.name;
  }
  return getName(data.file_data);
}


export const showToaster = (msg, method = 'info') => {
  
  let toastKey = toaster[method](
    <>
      {msg}
    </>,
    {
      overrides: {
        InnerContainer: {
          style: { width: "100%" }
        }
      }
    }
  );
  setTimeout(() => { toaster.clear(toastKey) }, 3000);
};

export const fetchCloudinaryFolders = async (organization_id) => {
  console.log('cloudinary');
  return fetch(`${getServerUrl()}/cloudinary_folders/${organization_id}`)
  .then((response) => response.json())
  .then((response) => {
    // console.log(response);
    return response;
  })
};

export const fetchCloudinarySubFolders = async (organization_id, path) => {
  console.log('cloudinary');
  return fetch(`${getServerUrl()}/cloudinary_folders/${organization_id}/sub/${path}`)
  .then((response) => response.json())
  .then((response) => {
    // console.log(response);
    return response;
  })
};

export function isEqualDate(dateText1, dateText2) {
  if (dateText1.getTime() === dateText2.getTime()) {
      return true;
    } else {
      return false;
    }
}