import * as React from 'react';
import { Input } from 'baseui/input';
import PropTypes from 'prop-types';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { Drawer } from "baseui/drawer";
import { Select } from 'baseui/select';

import styled from '@emotion/styled';

import CustomInput from '../common/input';
import { useMutation, useSubscription } from '@apollo/client';
import { importSharePointFormModulesByTags, importSharePointFormModulesByTagsSubscription, template_tags_org } from '../templates/queries';
import { toaster } from 'baseui/toast';

import { client } from '../../apollo/client';
import { getTags, organization as organizationQuery } from '../../quries';
import { useDispatch, useSelector } from 'react-redux';
import { removeSharepointFormImportRequest, setOrg, setSharepointFormImportRequest, showAlert } from '../../redux/actions/appBasicControls';
import { ButtonGroup } from 'baseui/button-group';
import { Button, SHAPE } from 'baseui/button';
import { Check, Upload } from 'baseui/icon';
import { useStyletron } from 'baseui';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { HeadingMedium, HeadingSmall, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { Spinner } from 'baseui/spinner';
import { Cell, Grid } from 'baseui/layout-grid';
import { ProgressBar } from 'baseui/progress-bar';
import { app_content_scope_query, content_template, import_category_to_channel } from '../templates/templates';


const channelId = "b3909ce2-d2a6-4ddd-bc7d-5235d2f7f16c";

const HeaderTitle = styled.h5`
  color: black;
  margin-top: 16px;
  margin-bottom: 11px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`

const ImportProgress = (onProgressDone, onError) => {

  const [request_id, setRequestId] = React.useState('')

  const { data: SubscriptionData, error: SubscriptionError } = useSubscription(
    importSharePointFormModulesByTagsSubscription,
    { variables: { request_id } }
  );

  const [progress, setProgress] = React.useState();

  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log('SubscriptionData', { SubscriptionData, request_id });
    const {
      importSharePointFormModulesByTags = {}
    } = SubscriptionData || {}
    const {
      current,
      error,
      is_done,
      is_error,
      result,
      total
    } = importSharePointFormModulesByTags;

    if (is_done) {
      dispatch(
        removeSharepointFormImportRequest({
          request_id
        })
      )
      onProgressDone()
    } else if (is_error) {
      dispatch(
        showAlert({
          msg: JSON.stringify(error),
          error: true,
        })
      );
      dispatch(
        removeSharepointFormImportRequest({
          request_id
        })
      )
      onError()
    }
    if (current) {
      setProgress(parseInt((current / total) * 100));
    }

  }, [SubscriptionData])

  React.useEffect(() => {
    setProgress(0)
  }, [request_id])

  function setReportRequestId(id) {
    setRequestId(id)
  }

  return ({
    ProgressComponent: (
      <div>
        <ProgressBar
          value={progress}
          getProgressLabel={(value) => <ParagraphMedium paddingBottom={0} marginBottom={'10px'}>{`${value}% import progress is complete`}</ParagraphMedium>}
          showLabel
          overrides={{
            BarProgress: {
              style: ({ $theme, $value }) => {
                return {
                  ...$theme.typography.font350,
                  backgroundColor: $theme.colors.positive,
                  color: $theme.colors.mono200,
                  position: 'relative',
                  marginBottom: '0',
                  ':after': {
                    position: 'absolute',
                    content: $value > 5 ? `"${$value}%"` : '',
                    right: '10px',
                  },
                };
              },
            },
            Bar: {
              style: ({ $theme }) => ({
                height: $theme.sizing.scale800,
              }),
            },
          }}
        />
      </div>
    ),
    progress,
    setReportRequestId
  })
}


const ImportTemplateModal = (props) => {
  const {
    isVisible = false,
    onClose = () => { },
    onRefresh,
    channel_id,
    organization_id,
    home_components = [],
    isMultipleChannelImport,
    onImportTemplatesSuccess
  } = props || {};

  const dispatch = useDispatch()
  const [categoriesList, setCategoriesList] = React.useState([]);
  const [name, setName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedTemplateCat, setSelectedTemplateCat] = React.useState([]);
  const [css, theme] = useStyletron();
  const [request_id, setRequestId] = React.useState();
  const [isImportProgress, setIsImportProgress] = React.useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const { ProgressComponent, setReportRequestId } = ImportProgress(onProgressDone, onErrorProgress);
  
  const formImportRequests = useSelector(state => state.appBasicControls.formImportRequests);

  React.useEffect(() => {
    if (isVisible) {
      loadCategories();
    }
  }, [isVisible]);


  React.useEffect(() => {
    console.log('formImportRequests', formImportRequests)
    const requestExist = formImportRequests && formImportRequests.find(el => el.channel_id === channel_id);
    if (requestExist) {
      setRequestId(requestExist.request_id)
      setReportRequestId(requestExist.request_id)
      setIsImportProgress(true);
    } else {
      setRequestId()
      setReportRequestId()
      setIsImportProgress(false);
    }
  }, [formImportRequests]);




  const loadCategories = () => {
    const { organization_id } = props
    setIsLoading(true);
    client.query({
      query: content_template,
      variables: {
        id: channelId, organization_id
      },
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      const {
        content_template = {}
      } = data || {};

      let {
        id,
        channel_id,
        categories = []
      } = content_template || {}

      // if (channel_id) {
      //   setChannelId(channel_id)
      // }

      // if (id) {
      //   setContentTemplateId(id)
      // }

      let sorted = [
        ...categories || []
      ]

      sorted = sorted.sort((a, b) => a.position - b.position)
      setCategoriesList(sorted);

    }).finally(() => setIsLoading(false))
  };

  const showToaster = (msg, method = 'info') => {
    let toastKey = toaster[method](
      <>
        {msg}
      </>,
      {
        overrides: {
          InnerContainer: {
            style: { width: "100%" }
          }
        }
      }
    );
    setTimeout(() => { toaster.clear(toastKey) }, 3000);
  };

  /////////////////////////////////////////////////// Event Functions //////////////////////////////////////////////

  const onCloseModal = () => {
    onClose();
    setCategoriesList([]);
    setSelectedTemplateCat([]);
  }

  const importTemplates = async (published) => {
    try {
      console.log(selectedTemplateCat);
      console.log('import now');
      console.log(channel_id, organization_id);
      setIsImportProgress(true);
      client.mutate({
        mutation: import_category_to_channel,
        variables: {
          category_ids: selectedTemplateCat.map(el => el.id),
          channel_id, organization_id
        }
      }).then(() => {
        onImportTemplatesSuccess();
        setIsImportProgress(false);
        onCloseModal();
      })
    } catch (err) {
      console.log(err)
      showToaster(JSON.stringify(err), 'negative')
    }
  }

  function fetchIntegrations() {
    // client.query({
    //   query: organizationQuery(organization_id),
    //   fetchPolicy: 'network-only'
    // }).then(({ data }) => {
    //   dispatch(setOrg(data.organization))
    // }).catch(err => {
    //   console.log('fetchIntegrations error', err)
    // })
  }

  const onSelectAllCategories = () => {
    if (selectedTemplateCat.length === categoriesList.length) {
      setSelectedTemplateCat([]);
      return;
    }
    setSelectedTemplateCat(categoriesList.map(el => el.category));
  }


  async function onProgressDone() {
    console.log('onProgressDone')
    setRequestId('');
    setIsImportProgress(false);
    if (onRefresh) {
      await onRefresh();
    }
    fetchIntegrations();
    onCloseModal();
    showToaster('Forms Imported Successfully', 'positive')
  }

  async function onErrorProgress() {
    console.log('onErrorProgress')
    if (onRefresh) {
      await onRefresh();
    }
    fetchIntegrations();
  }

  const onCloseWarningModal = () => {
    setIsWarningModalOpen(false)
  }

  const onClickImport = () => {
    setIsWarningModalOpen(true)
  }


  /////////////////////////////////////////////////// Render Functions //////////////////////////////////////////////

  const renderCategory = (input) => {
    const { category } = input;
    console.log('renderCategory 1', category);
    const isSelected = selectedTemplateCat.find(el => el.id === category.id);
    const onClickTag = () => {
      if (!isSelected) {
        setSelectedTemplateCat([
          ...selectedTemplateCat,
          category
        ])
      } else {
        setSelectedTemplateCat(selectedTemplateCat.filter(el => el.id !== category.id));
      }
    }

    return (

      <Checkbox
        checked={isSelected}
        onChange={onClickTag}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        <ParagraphMedium marginBottom='10px' paddingRight='14px'>{category.name}</ParagraphMedium>
      </Checkbox>
    )
  }

  const renderCategoriesList = () => {
    let isIndeterminate = selectedTemplateCat.length > 0 && selectedTemplateCat.length < categoriesList.length;
    let allChecked = selectedTemplateCat.length === categoriesList.length;
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          paddingBottom: '100px'

        }}
      >
        {
          categoriesList.length > 0 && (
            <Checkbox
              onChange={onSelectAllCategories}
              isIndeterminate={isIndeterminate}
              checked={allChecked}
            >
              Select All Categories
            </Checkbox>
          )
        }

        {/* {renderTitle('Tags')} */}
        <div
          style={{
            paddingLeft: '15px',
            paddingTop: '15px'
          }}
        >

          {categoriesList.map(renderCategory)}
        </div>
      </div>
    )
  }

  const renderWarningModal = () => {
    const onClickYes = () => {
      // if (isMultipleChannelImport) {
      //   onImportTemplates(selectedTemplateCat, true);
      // } else {
      importTemplates(true);
      // }
      onCloseWarningModal()
    }

    const onClickNo = () => {
      // if (isMultipleChannelImport) {
      //   onImportTemplates(selectedTemplateCat, false);
      // } else {
      importTemplates(false);
      // }
      onCloseWarningModal();
    }

    return (
      <Modal
        overrides={{
          Root: {
            style: () => ({
              zIndex: 1
            })
          }
        }}
        onClose={onCloseWarningModal}
        autoFocus={false}
        isOpen={isWarningModalOpen}
      >
        <ModalHeader>Do you want to publish all of the content?</ModalHeader>
        <ModalFooter>
          <ModalButton
            onClick={onClickYes}
          >
            Yes
          </ModalButton>
          <ModalButton
            onClick={onClickNo}
          >
            No
          </ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <React.Fragment>
      <Drawer
        isOpen={isVisible}
        autoFocus
        onClose={onCloseModal}
        overrides={{
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: '20px',
            })
          },
        }}
      >
        <HeadingSmall
          style={{
            textAlign: 'center'
          }}
        >
          Import Templates
        </HeadingSmall>
        {
          isLoading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '10px'
              }}
            >
              <Spinner
                size={24}

              />
            </div>
          )
        }
        {renderCategoriesList()}


        <div
          style={{
            display: 'flex',
            position: 'absolute',
            minHeight: '50px',
            paddingBottom: '10px',
            paddingRight: '20px',
            paddingLeft: '20px',
            bottom: 0,
            right: 0,
            width: '100%',
            flexDirection: 'column'
          }}

        >
          {
            request_id && ProgressComponent
          }
          <div
            className={css({
              justifyContent: 'flex-end',
              display: 'flex'
            })}
          >
            <ModalButton
              onClick={onCloseModal}
            >
              Cancel
            </ModalButton>

            <ModalButton
              isLoading={isImportProgress}
              onClick={onClickImport}
            >
              Import
            </ModalButton>
          </div>

        </div>
      </Drawer>
      {renderWarningModal()}
    </React.Fragment>
  )
}

ImportTemplateModal.propTypes = {
  home_components: PropTypes.array,
}

ImportTemplateModal.defaultProps = {
  home_components: []
}

export default (ImportTemplateModal);