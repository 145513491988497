import React from 'react';
import { Spinner } from 'baseui/spinner';
import axios from 'axios';
import { useStyletron } from 'baseui';
import { getServerUrl } from '../apollo/client';
import { Button } from 'baseui/button';
import LoginContainer from '../components/loginContainer';
import { Cell } from 'baseui/layout-grid';
import { adminSliders, userSliders } from '../helpers/slidersData';
import jwt_decode from "jwt-decode";
import { initializeApp } from "firebase/app";
import { SAMLAuthProvider, getAuth, signInWithRedirect, getRedirectResult, signInWithPopup } from "firebase/auth";
import { client } from '../apollo/client';
import gql from 'graphql-tag';

const PasswordResetSuccess = () => {
  const [css, theme] = useStyletron();
  const urlParams = new URLSearchParams(window.location.search);
  const organization_id = urlParams.get('organization_id');
  const redirect_uri = urlParams.get('redirect_uri');
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    client.query({
      query: gql`
         query data {
          organization_firebase_org(organization_id: "${organization_id}") {
             api_key
             auth_domain
             saml_provider
             saml_provider1
           }
         }
      `
    })
      .then((result) => {
        const { organization_firebase_org } = result.data;
        if (organization_firebase_org.length > 0) {
          const d = organization_firebase_org[0];
          const firebaseConfig = {
            apiKey: d.api_key,
            authDomain: d.auth_domain,
          };
          const app = initializeApp(firebaseConfig);
          const _a = getAuth(app);
          getRedirectResult(_a)
            .then((result) => {
              if (!result) {
                loginNow(_a, new SAMLAuthProvider(d.saml_provider));
                return;
              };
              console.log(result);
              axios({
                url: `${getServerUrl()}/login_from_firebase_redirect`,
                method: 'POST',
                data: {
                  organization_id,
                  token_response: result._tokenResponse
                }
              }).then((result) => {
                return result.data;
              }).then((result) => {
                console.log(result);
                window.location.href = `${redirect_uri}/${result.data.id_token}`;
              })
            })
        } else {
          setError('No integration found. Contact your Admin');
        }
      })
      .catch(err => {
        setError(err.message);
      })
  }, [organization_id]);

  const loginNow = (auth, provider) => {
    signInWithRedirect(auth, provider)
      .then((result) => {
        console.log(result);
      })
  };

  console.log(organization_id, error);

  return (
    <>
      <div style={{width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            color: theme.colors.primaryB,
            marginBottom: 16
          }}
        >
          {error || 'Loading Auth'}
        </div>
        {!error && <div
          className={css({
            width: theme.sizing.scale1200,
            height: theme.sizing.scale1200,
          })}
        >
          <Spinner />
        </div>}
      </div>
    </>
  );
};

export default PasswordResetSuccess;
