import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import { Tag, VARIANT as TAG_VARIANT } from 'baseui/tag';
import {
  HeadingMedium,
  ParagraphSmall,
  LabelSmall,
} from 'baseui/typography';
import { Input, StyledInput } from 'baseui/input';
import { Textarea } from "baseui/textarea";
import { cellPaddingOverrides } from '../overrides';
import { Select } from 'baseui/select';
import CardPicker from '../modules/generic_modules/cardPickerCXL';
import CommunicationPicker from '../communicationPickerCXL';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';

const InputReplacement = React.forwardRef(
  ({ tags, removeTag, ...restProps }, ref) => {
    const [css] = useStyletron();
    return (
      <div
        className={css({
          flex: '1 1 0%',
          flexWrap: 'wrap',
          display: 'flex',
          alignItems: 'center',
        })}
      >
        {tags.map((tag, index) => (
          <Tag
            variant={TAG_VARIANT.solid}
            onActionClick={() => removeTag(tag)}
            key={index}
          >
            {tag}
          </Tag>
        ))}
        <StyledInput ref={ref} {...restProps} />
      </div>
    );
  }
);


const Step7 = (props) => {
  const [css, theme] = useStyletron();
  console.log(props.messageType);
  return (
    <Grid overrides={{
      Grid: {
        style: () => ({
          paddingTop: '0px',
          paddingBottom: '32px',
          width: '100%',
          paddingTop: '29px'
        }),
      },
    }} gridMaxWidth={'unset'}>
      {/* <Cell span={12}>
        <HeadingMedium style={{ marginBottom: '4px' }}>
          Push Notification Actions
          </HeadingMedium>
        <ParagraphSmall>
          Select action of user when notification is clicked
          </ParagraphSmall>
      </Cell> */}
      <Cell overrides={cellPaddingOverrides} span={10}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          })}
        >
          <LabelSmall>Push Notification</LabelSmall>
          <LabelSmall
            style={{
              marginTop: '16px',
              marginBottom: '8px',
            }}
          >
            Action Type
          </LabelSmall>
          <Select
            options={[
              { label: 'Card', id: 'card' },
              { label: 'Newsroom', id: 'newsroom' }

            ]}
            value={[
              { label: 'Card', id: 'card' },
              { label: 'Newsroom', id: 'newsroom' }
            ].filter(el => el.id == props.communicationType)}
            labelKey="label"
            valueKey="id"
            placeholder='Communication Type'
            onChange={({ value }) => props.setCommunicationType(value[0].id)}
          />
          {props.communicationType == 'card' ?
            <div>
              <LabelSmall
                style={{
                  marginTop: '16px',
                  marginBottom: '8px',
                }}
              >
                Card Reference
              </LabelSmall>
              <CardPicker
                marginTop={"0px"}
                selectedCardId={props.redirectCardId}
                onCardSelect={props.setRedirectCardId}
                theme={theme}
                placeholder="Select Redirecting Card"
              />
            </div>
            : props.communicationType == 'newsroom' ?
              <div>
                <LabelSmall
                  style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                  }}
                >
                  Communication Reference
                </LabelSmall>

                {props.messageType.includes(1) && <Checkbox
                  checked={props.selfCommunication}
                  onChange={e => props.setSelfCommunication(e.target.checked)}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <LabelSmall style={{ marginTop: '4px' }}>Send current article</LabelSmall>
                </Checkbox>}
                <LabelSmall
                  style={{
                    marginTop: '16px',
                    marginBottom: '8px',
                  }}
                >
                  Select any existing article
                </LabelSmall>
                <CommunicationPicker
                  selectCommunicationId={props.redirectCommunicationId}
                  onCommunicationSelect={props.setRedirectCommunicationId}
                  placeholder="Select communication reference"
                  disabled={props.selfCommunication}
                />
              </div>
              : null
          }
        </div>
      </Cell>
    </Grid>
  )
};
export default Step7;