import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import {
  HeadingMedium,
  ParagraphSmall,
  HeadingXSmall,
  LabelSmall,
  LabelXSmall,
  LabelMedium
} from 'baseui/typography';
import { useStyletron } from 'baseui';
import FeedItem from './feed';
import moment from 'moment';
import { Button as BaseButton } from 'baseui/button';
import styled from '@emotion/styled';
import { cellPaddingOverrides } from '../overrides';
import { Alert, Check } from "baseui/icon";
import PreviewModal from './preview-modal';

const TypeButton = styled(BaseButton)`
  background-color: #5D64AB;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  height: 48px;
  line-height: 48px;
  margin-top: 8px;
  border-radius: 2px;
  font-size: 18px
`;

const Step6 = ({ feed, STEPS, onSave, isLoading }) => {
  const [css, theme] = useStyletron();
  const [previewModalOpen, setPreviewModalOpen] = React.useState(false);
  const onPreviewModalClose = () => {
    setPreviewModalOpen(false);
  };
  const getUsersMessage = () => {
    if (feed.isSendToEntireOrg) {
      return 'All users of entire organization'
    } else if (feed.isSendToTeam) {
      return `All users of ${feed.channelsSelected.length} ${feed.channelsSelected.length == 1 ? 'Team' : 'Teams'}`
    } else {
      return `All users of ${feed.channelsSelected.length} ${feed.channelsSelected.length == 1 ? 'channel' : 'channels'}`
    }
  }
  return (
    <>
    <Grid overrides={{
      Grid: {
        style: () => ({
          paddingTop: '0px',
          paddingBottom: '32px',
          width: '100%',
          paddingTop: '29px'
        }),
      },
    }} gridMaxWidth={'unset'}>
      {/* <Cell span={12} overrides={cellPaddingOverrides}>
        <HeadingMedium style={{ marginBottom: '4px' }}>
          Confirm/Preview
        </HeadingMedium>
        <ParagraphSmall>
          Preview your Message.
        </ParagraphSmall>
      </Cell> */}
      <Cell span={12} overrides={cellPaddingOverrides} span={10}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          })}
        >
          <LabelSmall>{"Preview your outgoing Connect"}</LabelSmall>
          <HeadingXSmall style={{ marginBottom: '0px' }}>Click the button to preview your Connect according to the different types of messages selected.</HeadingXSmall>
          {/* <FeedItem
            feed={feed}
            index={0}
            key={0}
            defaultTheme={''}
            front_image={feed.front_image}
            dataType={feed.dataType}
          ></FeedItem>
          <ParagraphSmall style={{marginBottom: '0px', marginTop: '32px'}}>
            Preview according to the different types of message
          </ParagraphSmall> */}
          <TypeButton onClick={() => setPreviewModalOpen(true)}>Open Preview</TypeButton>
        </div>
      </Cell>
      <Cell span={12} overrides={cellPaddingOverrides} span={10}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          })}
        >
          <LabelSmall>{"Audience"}</LabelSmall>
          <HeadingXSmall style={{ marginBottom: '0px' }}>{getUsersMessage()} is going to receive this message</HeadingXSmall>
        </div>
      </Cell>
      <Cell span={12} overrides={cellPaddingOverrides} span={10}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          })}
        >
          <LabelSmall>{"Delivery Time"}</LabelSmall>
          {feed.deliveryType == 0 && <HeadingXSmall style={{ marginBottom: '0px' }}>Right Now</HeadingXSmall>}
          {feed.deliveryType == 1 && <HeadingXSmall>{moment(feed.deliveryTime).format('llll')}</HeadingXSmall>}
        </div>
      </Cell>
      <Cell span={12} overrides={cellPaddingOverrides} span={10}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          })}
        >
          <LabelSmall>{"Connect message preflight checklist"}</LabelSmall>
          <HeadingXSmall>Below is a list of all of the actions needed to successfully send your message.  You can make any final edits by clicking the buttons on the left side.  Click the send button below to kickoff this message!</HeadingXSmall>
          {STEPS.map((element, index) => {
            return <div style={{ display: 'flex' }} key={index}> <LabelMedium style={{marginRight: '8px'}}>{element.buttonTitle}</LabelMedium> {
              element.isReady ? <Check size={24} /> : <Alert size={24} />
            }</div>
          })}
          <TypeButton isLoading={isLoading} disabled={!feed.isSendButtonReady} onClick={onSave}>Send Message</TypeButton>
        </div>
      </Cell>
    </Grid>
    <PreviewModal feed={feed} isOpen={previewModalOpen} onClose={onPreviewModalClose}></PreviewModal>
</>
  )
};

export default Step6;