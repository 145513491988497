import * as React from 'react';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { Drawer } from "baseui/drawer";
import {
  ModalButton
} from 'baseui/modal';
import { client } from '../../apollo/client';
import gql from 'graphql-tag';

export const FailedFileUploader = ({ isOpen, files, onClose, organization_id }) => {
  const [selected, setSelected] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const uploadFile = async (file) => {
    const f = file.f;
    console.log(file);
    const q = gql`
    mutation createLibrary(
        $title: String
        $document: Upload
        $organization_id: ID
        $is_cloudinary_rejected: Boolean
        $cloudinary_path: String
        $description: String
    ) {
        createLibrary(
          title: $title
          document: $document
          organization_id: $organization_id
          is_cloudinary_rejected: $is_cloudinary_rejected
          cloudinary_path: $cloudinary_path
          description: $description
        ) {
            title
            library
            is_cloudinary_rejected
            cloudinary_path
            organization_id
            description
        }
    }
  `;
    console.log(f);
    let cloudinary_path = null;
    try {
      let x = f.filepath.split('/');
      x.pop();
      cloudinary_path = x.join('/');
    } catch (e) {

    }
    try {
      await client.mutate({
        mutation: q,
        variables: {
          title: `${f.name}`,
          document: file.f,
          organization_id,
          is_cloudinary_rejected: true,
          cloudinary_path: cloudinary_path,
          description: f.filepath,
        }
      })
    } catch (e) {
      console.log(e);
    }
    return 1;
  };

  const onClickUpload = async () => {
    setIsLoading(true);
    let __f = files.filter(el => {
      return selected.includes(el.f.name)
    });
    console.log(__f);
    for (var i = 0; i < __f.length; i++) {
      await uploadFile(__f[i])
    };
    onClose(true);
  };

  console.log(files);

  return (
    <Drawer
      isOpen={isOpen}
      autoFocus
      onClose={() => onClose(false)}
      overrides={{
        Root: {
          style: {
            zIndex: 3,
          }
        },
        Backdrop: {
          style: ({ $theme }) => ({
            backdropFilter: $theme.modalBackDropFilter,
          }),
        }
      }}
    >
      <div style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'
      }}>
        <div>


          <LabelMedium>{files.length} {files.length > 1 ? 'files' : 'file'} has been Failed!</LabelMedium>
          <ParagraphSmall>You can re upload them on cardware file system. Select the files and hit upload to save them.</ParagraphSmall>

          <div>
            {files.map((el, index) => {
              return <div key={index}>
                <Checkbox
                  checked={selected.includes(el.f.name)}
                  onChange={e => {
                    let s = [...selected];
                    s.push(el.f.name);
                    s = Array.from(new Set(s));
                    setSelected(s);
                  }}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  {el.f.name}
                </Checkbox>
              </div>
            })}
          </div>
        </div>

        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'
        }}>
          <ModalButton
            isLoading={isLoading}
            onClick={() => {
              onClickUpload();
            }}
          >
            Upload
          </ModalButton>
        </div>
      </div>
    </Drawer>
  )
};