import gql from 'graphql-tag';
import { client, getAnalyticsGraphQLClient } from '../../apollo/client';
import store from '../../redux/store';

export const communicationTypes = {
  PUSH: 'Push',
  EMAIL: 'Email',
  NEWS: 'News',
  POPUP: 'Popup'
}

export const communityTypesColor = {
  PUSH: '#4A4CA0',
  EMAIL: '#EE4678',
  NEWS: '#0D0E13',
}

export const fetchCommunications = (communicationType, organization_id, selectedChannel, tabFilter, editorBtnOptions, PAGE_SIZE, page) => {
  return new Promise((resolve, reject) => {
    if (communicationType.length == 0) return;
    let type_news = false;
    let type_email = false;
    let type_push_notification = false;
    let type_popup = false;
    switch (communicationType[0].id) {
      case 'all':
        type_push_notification = true;
        type_news = true;
        type_email = true;
        break;
      case 'push':
        type_push_notification = true;
        break;
      case 'article':
        type_news = true;
        break;
      case 'email':
        type_email = true;
        break;
      case 'popup':
        type_popup = true;
        break;
    };
    client.query({
      query: gql`
        query communications {
          communication_app_content_scope_by_app_content_scope(
            organization_id: "${organization_id}"
            app_content_scope_id: "${selectedChannel.id}"
            type_push_notification: ${type_push_notification}
            type_news: ${type_news}
            type_email: ${type_email}
            type_popup: ${type_popup}
            skip: ${(page - 1) * PAGE_SIZE}
            limit: ${PAGE_SIZE}
            archived: ${tabFilter == 'Archives' ? true : false}
            ) {
            communication_id
            app_content_scope {
              name
              id
              app_content_scope_group_id
            }
            communication {
              title
              message_body
              front_image_data
              tags
              type_news
              type_email
              type_popup
              type_push_notification
              id
              created_at
              created_by_name
              redirect_communication_id
              push_action_type
              redirect_card_id
              card_content_modules {
                communication_id
                position
                content_module{
                    __typename
                    ${editorBtnOptions.map(el => {
        return `
                        ...on ${el.__typename}{
                          id
                          ${el.inputs.map(el1 => {
          return `
                              ${el1.key} ${el1.subQueryKeys
              ? `{
                                ${el1.subQueryKeys.map(el2 => el2 + '\n')}
                              }`
              : ''
            }
                            `;
        })}
                        }
                      `;
      })}
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      resolve({ data })
    })
  })

};

export const connectStatsData = {
  options: [
    {
      title: 'Total push messages sent',
      type: communicationTypes.PUSH,
      count: '',
      key: 'total_push_sent'
    },
    {
      title: 'Total email message sent',
      type: communicationTypes.EMAIL,
      count: '',
      key: 'total_email_sent'
    },
    {
      title: 'Total newsroom articles posted',
      type: communicationTypes.NEWS,
      count: '',
      key: 'total_article_posted'
    }
  ],
  _get: () => {
    let organization = store.getState().appBasicControls.organization;
    if (!organization) return Promise.resolve({});
    if (!organization.analytic_integration) return Promise.resolve({});
    let analyticsClient = getAnalyticsGraphQLClient(organization.analytic_integration.schema_name);
    return analyticsClient.query({
      query: gql`
      query connect_main_page_analysis {
        connect_main_page_analysis {
          total_push_sent
          total_email_sent
          total_article_posted
        }
      }
    `
    }).then(result => {
      let { connect_main_page_analysis } = result.data;
      return connect_main_page_analysis;
    })
  }
};

export const getDetailPageAnalysis = (communication_id) => {
  let organization = store.getState().appBasicControls.organization;
  if (!organization) return Promise.resolve({});
  if (!organization.analytic_integration) return Promise.resolve({});
  let analyticsClient = getAnalyticsGraphQLClient(organization.analytic_integration.schema_name);
  let query = gql`
    query connect_main_page_analysis {
      article_wise_analysis(article_id: "${communication_id}") {
        notification_sent
        notification_failed
        notification_opened
    
        article_posted
        article_opened
        article_seen
    
        email_sent
        email_seen
      }
  }`;
  return analyticsClient.query({
    query: query
  }).then(result => {
    let { article_wise_analysis } = result.data;
    console.log(article_wise_analysis);
    return article_wise_analysis;
  })
}

