import styled from "styled-components"

const VideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border: 1px solid rgb(198, 191, 191);
    border-radius: 10px;
    margin: .5rem;
`
const VideoTitleContainer = styled.div`
    text-align: center;
    padding: 0.5rem;
    color: rgb(66, 64, 64);
    border-top: 1px solid rgb(198, 191, 191);
    font-size: 14px;
`

const VideoPreview = styled.img`
    margin: 0;
    min-width: 400px;
    height: 210px;
    padding: 3px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
`;

export default {
    VideoContainer,
    VideoTitleContainer,
    VideoPreview,
}