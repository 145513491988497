import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import Button from '../styledButton';
import RightSidePageHeader from '../pageHeaders';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { Notification, KIND } from "baseui/notification";
import AdminsComponent from './user';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { forgotPassword } from '../../auth/index';
import { client } from '../../apollo/client';
import { toaster, ToasterContainer, PLACEMENT } from "baseui/toast";
import {
  admins as adminsQuery,
  pwa_manifest_templates,
  adminsArchived,
  createAdminWithChannels as createAdminWithChannelsMutation,
  updateAdminWithChannels as updateAdminWithChannelsMutation,
  archiveAdminFromOrganizationMutation,
  sendInviteMutation,
  organizationsByEmail,
  forgotPasswordMutation,
  getActivationLink
} from '../../quries';
import { adminUsertableColumns } from './constants';
import './pwa.css';
import AssignUsersModal from './assignUsersToTrainers';

const TAB_STATUS = {
  ALL: 'all',
  LIVE: 'pending',
  ARCHIVE: 'archived',
  // PWA: 'pwa',
};

const Admin = ({ organization_id, channels, defaultTheme, user, isTrainer }) => {
  const [tab, setTab] = React.useState(TAB_STATUS.ALL);
  const [listActionInProgress, setListActionInProgress] = React.useState(false);
  const [_t, setT] = React.useState(new Date().getTime());
  const refetchUsers = () => setT(new Date().getTime());
  const [onHover, setOnHover] = React.useState(false);

  const [assignUserModalOpen, setAssignUserModalOpen] = React.useState(false);
  const [trainer, setTrainer] = React.useState(null);
  const onTrainerModalClose = () => {
    setAssignUserModalOpen(false);
    setTrainer(null);
  }

  const listMenuOptions = [
    {
      id: 'remind',
      display: 'Remind',
      onClick: (user) => {
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        setListActionInProgress(true);
        showToaster("Sending Invite. Please Wait...");
        client.mutate({
          mutation: sendInviteMutation,
          variables: {
            organization_id,
            userId: user.id,
            domain: window.location.origin,
            isAdmin: true
          }
        }).then(() => {
          showToaster("Invite Sent");
          setListActionInProgress(false);
        });
      }
    },
    {
      id: 'archive',
      display: 'Archive',
      onClick: (user) => {
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        setListActionInProgress(true);
        showToaster("Arching User. Please Wait...");
        client.mutate({
          mutation: archiveAdminFromOrganizationMutation,
          variables: {
            organization_id,
            userId: user.id,
            archived: true
          }
        }).then(() => {
          showToaster("User Archived");
          refetchUsers();
          setListActionInProgress(false);
        });
      }
    },
    {
      id: 'password-reset',
      display: 'Password Reset',
      onClick: async (user) => {
        let message;
        let method;
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        setListActionInProgress(true);
        showToaster("Sending email to password reset");
        // try {
        //   message = await forgotPassword(organization_id, user.email);
        //   method = 'info';
        // } catch (err) { message = err; method = 'negative' }
        client.mutate({
          mutation: forgotPasswordMutation,
          variables: {
            email: user.email,
            domain: window.location.origin
          }
        })
          .then((response) => {
            let message = response.data.forgotPassword.data;
            console.log(response);
            setListActionInProgress(false);
            showToaster(message, method);
          });
      }
    },
    {
      id: 'copy-activation-link',
      display: 'Get Activation Link',
      onClick: async (user) => {
        let message;
        let method;
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        if (user.approved) {
          showToaster("User is already activated");
          return;
        };
        setListActionInProgress(true);
        showToaster("Getting activation link");
        // try {
        //   message = await forgotPassword(organization_id, user.email);
        //   method = 'info';
        // } catch (err) { message = err; method = 'negative' }
        // showToaster(message, method);
        // setListActionInProgress(false);
        client.query({
          query: getActivationLink,
          variables: {
            organization_id,
            user_id: user.id,
            isAdmin: true,
            domain: window.location.origin
          }
        })
          .then((response) => {
            let message = 'URL is copied to clipboard';
            console.log(response.data.getUserActivationUrl);
            try {
              navigator.clipboard.writeText(response.data.getUserActivationUrl);
            } catch (e) {
              console.log(e);
            }
            setListActionInProgress(false);
            showToaster(message);
          });
      }
    },
    {
      id: 'assign_user_to_trainer',
      display: 'Assign Users',
      onClick: async (user) => {
        setTrainer(user);
        setAssignUserModalOpen(true);
      }
    }
  ];

  const listMenuOptionsArchivedUser = [
    {
      id: 'archive',
      display: 'Unarchive',
      onClick: (user) => {
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        setListActionInProgress(true);
        showToaster("Arching User. Please Wait...");
        client.mutate({
          mutation: archiveAdminFromOrganizationMutation,
          variables: {
            organization_id,
            userId: user.id,
            archived: false
          }
        }).then(() => {
          showToaster("User UnArchived");
          refetchUsers();
          setListActionInProgress(false);
        });
      }
    },
    {
      id: 'delete',
      display: 'Delete',
      onClick: (user) => {
        if (listActionInProgress) {
          showToaster("Last operation in progress");
          return;
        };
        setListActionInProgress(true);
        showToaster("Deleting User. Please Wait...");
        client.mutate({
          mutation: archiveAdminFromOrganizationMutation,
          variables: {
            organization_id,
            userId: user.id,
            archived: false,
            isPermanent: true
          }
        }).then(() => {
          showToaster("User Deleted");
          refetchUsers();
          setListActionInProgress(false);
        });
      }
    },
  ];

  const showToaster = (msg, method = 'info') => {
    let toastKey = toaster[method](
      <>
        {msg}
      </>,
      {
        overrides: {
          InnerContainer: {
            style: { width: "100%" }
          }
        }
      }
    );
    setTimeout(() => { toaster.clear(toastKey) }, 3000);
  };

  const headerDescription = `Looks like you manage organizations. Click here to view all organizations which you are assigned administrator rights.`;

  return (
    <>
      <Grid overrides={gridPaddingOverrides} gridMaxWidth={'unset'}>
        <span style={{ width: '100%', height: '2px', background: '#EAEAEA', marginBottom: '1rem' }}></span>
        <Cell span={12} overrides={cellPaddingOverrides}>

          <AdminsComponent
            columns={adminUsertableColumns}
            tabFilter={tab}
            setTab={setTab}
            showFilter={{
              cardware_converted: false
            }}
            channels={channels}
            organization_id={organization_id}
            defaultTheme={defaultTheme}
            type={'admin'}
            listMenuOptions={tab === TAB_STATUS.ARCHIVE ? listMenuOptionsArchivedUser : listMenuOptions}
            isTrainer={isTrainer}
            addUserMutation={createAdminWithChannelsMutation}
            updateUserMutation={updateAdminWithChannelsMutation}
            showToaster={showToaster}
            _t={_t}
          ></AdminsComponent>
        </Cell>
      </Grid>
      <AssignUsersModal
        isOpen={assignUserModalOpen}
        onClose={onTrainerModalClose}
        trainer={trainer}
        organization_id={organization_id}
      />
    </>
  );
};

const mapStateToProps = props => {
  const { channels, theme, user } = props.appBasicControls;
  return {
    channels,
    defaultTheme: theme,
    user
  };
};
export default connect(mapStateToProps)(Admin);
