import * as React from 'react';
import { connect } from 'react-redux';

import Deck from './deck';
import AddDeck from './addDeck';

const Decks = ({
  categoryId,
  organization_id,
  decks,
  cards,
  category,
  channels,
  dispatch,
  index,
  tabFilter,
  searchText,
  defaultTheme,
  isDeckOpen,
  refreshQuizQuestions,
  deckIdFromPath,
  questionCards,
  isTemplate,
  isHomeComponent,
  homeComponentId,
  refreshCards,
  templateCategories
}) => {
  const [filteredDecks, setFilteredDecks] = React.useState([])

  React.useEffect(() => {
    setFilteredDecks(
      (decks || [])
        .filter(el => {
          if (isHomeComponent) {
            return el.home_component_id == homeComponentId
          };
          return el.category_id == categoryId;
        })
        .filter(el => {
          if (searchText && searchText !== "" && el.deck_category_deck.title.toLowerCase().indexOf(searchText.toLowerCase()) === -1) {
            const filteredCards = cards.filter(item => item.quiz_deck_id === el.deck_id || item.deck_id === el.deck_id)
              .filter(item => item.card.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            if (filteredCards.length === 0) {
              return false;
            }
          }
          if (tabFilter === 'all') {
            if (el.deck_category_deck.archived === true) {
              return false;
            }
          }
          if (tabFilter == 'archived') {
            if (el.deck_category_deck.archived === true) {
              return true;
            }
            const filteredCards = (el.deck_type == 'QuizDeck' ? questionCards : cards).filter(item => item.quiz_deck_id === el.deck_id || item.deck_id === el.deck_id)
              .filter(item => (item.card || item.quiz_question_data).archived === true)
            return el.deck_category_deck.archived == true || filteredCards.length > 0;
          }
          if (tabFilter == 'published')
            return el.published == true && el.deck_category_deck.archived != true;
          return true;
        })
        .sort((a, b) => {
          let aPosition = a.position;
          let bPosition = b.position;
          if (a.isNew) aPosition = -1;
          return aPosition - bPosition;
        }));
  }, [decks, searchText, tabFilter, categoryId, homeComponentId])

  if (!decks) return <></>;
  return (
    <>
      <Deck
        defaultTheme={defaultTheme}
        filteredDecks={filteredDecks}
        categories={category}
        channels={channels}
        organization_id={organization_id}
        oDecks={decks}
        dispatch={dispatch}
        categoryIndex={index}
        categoryId={categoryId}
        tabFilter={tabFilter}
        searchText={searchText}
        refreshQuizQuestions={refreshQuizQuestions}
        isDeckOpen={isDeckOpen}
        deckIdFromPath={deckIdFromPath}
        isTemplate={isTemplate}
        isHomeComponent={isHomeComponent}
        homeComponentId={homeComponentId}
        refreshCards={refreshCards}
        templateCategories={templateCategories}
      ></Deck>
    </>
  );
};

const mapStateToProps = props => {
  const { decks, channels, category, cards, questionCards } = props.appBasicControls;
  return { decks, channels, category, cards, questionCards };
};
export default connect(mapStateToProps)(Decks);
