import React, { lazy } from "react";
import WrapperBuilder from "./wrapper_builder";
import WrapperGeneric from "./wrapper_generic";
import ActionMetadataInput from "./generic_modules/ActionMetadataInput";
import ActionMetadataInputEditor from "./builder_modules/ActionMetadataInput";
import TextAreaEditor from "./builder_modules/TextArea";
import TextAreaComponent from "../channel/textarea";
import InputComponent from "./builder_modules/InputComponent";
import { Input } from "baseui/input";
import ColorPickerCXL from "./generic_modules/colorPicker";
import ImagePicker from "./builder_modules/imagePicker";
import ImageUploadCXL from "../imageUploaderCXL";
import CardPicker from "./builder_modules/cardPicker";
import cardPickerCXL from "./generic_modules/cardPickerCXL";
import ActionMetadataSingleObject from "./generic_modules/ActionMetadataSingleObject";
import SelectCXL from "./generic_modules/selectCXL";
import CheckboxCXL from "./generic_modules/checkboxCXL";
import TableColumnPicker from "./builder_modules/tableColumnPicker";
import SearchableCheckList from "./generic_modules/searchableCheckList";
import BaseUiTextAreaBuilder from "./builder_modules/baseuiTextArea";
import BaseUiTextArea from "./generic_modules/baseuiTextArea";
import CustomFieldPickerField from "./generic_modules/customFieldPicker";
import AdvanceTextAccordion from "./generic_modules/advanceTextAccordion";
import ApiPathPicker from './generic_modules/apiPathPicker';
import JwPlayerListSelect from "./generic_modules/jwplayerListSelect";
import DateCXL from "./generic_modules/dateCXL";
import TimeCXL from "./generic_modules/timeCXL";
import SelectOnCheckBox from "./generic_modules/selectOnCheckBox";
import TextResponse from "./generic_modules/textResponse";
import MatchingPair from "./generic_modules/matchingPair";
import ChannelPicker from "./generic_modules/channelPicker";
import Info from "./generic_modules/info";
import FormInputList from "./generic_modules/formInputList";
import DateTimeRangePicker from "./generic_modules/dateTimeRangePicker";
import LocationModulePicker from "./generic_modules/locationModulePicker";
import VideoGallery from "./generic_modules/videoGallery";
import tagsPicker from "./generic_modules/tagsPicker";
import AudioPicker from "./generic_modules/audiosPicker";
import ContactMethod from "./generic_modules/contactMethod";
import VideoIdOrURLPicker from "./generic_modules/videoIdOrURLPicker";
import { FormBuilderHomeComponent } from "../builder/formBuilder";
import QuizOption from './generic_modules/quizOption';
import { DatePicker } from "baseui/datepicker";
import JSONInputCXL from "./generic_modules/jsonInput";
import StepsGamification from "../StepsGamification";
import CrunchbaseIntegrationPicker from "./generic_modules/crunchbaseIntegrationPicker";
import StashFunctionPicker from "./generic_modules/stashFunctionsPicker";
import MongoIntegrationPicker from "./generic_modules/mongoIntegrationPicker";

{/* if component have any api calls than import it like this it will wait for component to 
load it's valuese and show loader till that time */}
// const ActionMetadataInput = lazy(() => import("../ActionMetadataInput"));

// editorType can be off 2 type home_component or builder
// if home_component it means we want to fetch component for home component
// if builder it means we want to fetch component for home component

const modules = {
  Textarea: {
    builder: (props) => <WrapperBuilder _component={TextAreaEditor} {...props} />,
    home_component: (props) => <WrapperGeneric _component={TextAreaComponent} {...props} />,
  },
  Input: {
    builder: (props) => <WrapperBuilder _component={InputComponent} {...props} />,
    home_component: (props) => <WrapperGeneric _component={Input} {...props} />,
  },
  ActionMetadataInput: {
    builder: (props) => <WrapperBuilder _component={ActionMetadataInputEditor} {...props} />,
    home_component: (props) => <WrapperGeneric _component={ActionMetadataInput} {...props} />,
  },
  ColorPicker: {
    builder: (props) => <WrapperBuilder _component={ColorPickerCXL} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={ColorPickerCXL} {...props} />,
  },
  FilePicker: {
    builder: (props) => <WrapperBuilder _component={ImagePicker} {...props} />,
    home_component: (props) => <WrapperGeneric _component={ImageUploadCXL} {...props} />,
  },
  CardPicker: {
    builder: (props) => <WrapperBuilder _component={CardPicker} {...props} />,
    home_component: (props) => <WrapperGeneric _component={cardPickerCXL} {...props} />,
  },
  ActionButtonObject: {
    builder: (props) => <WrapperBuilder _component={ActionMetadataSingleObject} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={ActionMetadataSingleObject} {...props} />,
  },
  Select: {
    builder: (props) => <WrapperBuilder _component={SelectCXL} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={SelectCXL} {...props} />,
  },
  Checkbox: {
    builder: (props) => <WrapperBuilder _component={CheckboxCXL} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={CheckboxCXL} {...props} />,
  },
  TableColumnPicker: {
    builder: (props) => <WrapperBuilder _component={TableColumnPicker} {...props} />,
    home_component: (props) => <WrapperGeneric _component={SearchableCheckList} {...props} />,
  },
  BaseUiTextArea: {
    builder: (props) => <WrapperBuilder _component={BaseUiTextAreaBuilder} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={BaseUiTextArea} {...props} />,
  },
  ApiPathPicker: {
    builder: (props) => <WrapperBuilder _component={ApiPathPicker} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={ApiPathPicker} {...props} />,
  },
  CrunchbaseIntegrationPicker: {
    builder: (props) => <WrapperBuilder _component={CrunchbaseIntegrationPicker} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={CrunchbaseIntegrationPicker} {...props} />,
  },
  CustomFieldPicker: {
    builder: (props) => <WrapperBuilder _component={CustomFieldPickerField} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={CustomFieldPickerField} {...props} />,
  },
  AdvanceTextAccordion: {
    builder: (props) => <WrapperBuilder _component={AdvanceTextAccordion} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={AdvanceTextAccordion} {...props} />,
  },
  JWPlayerListSelect: {
    builder: (props) => <WrapperBuilder _component={JwPlayerListSelect} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={JwPlayerListSelect} {...props} />,
  },
  Date: {
    builder: (props) => <WrapperBuilder _component={DateCXL} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={DateCXL} {...props} />,
  },
  Time: {
    builder: (props) => <WrapperBuilder _component={TimeCXL} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={TimeCXL} {...props} />,
  },
  SelectOnCheckBox: {
    builder: (props) => <WrapperBuilder _component={SelectOnCheckBox} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={SelectOnCheckBox} {...props} />,
  },
  TextResponse: {
    builder: (props) => <WrapperBuilder _component={TextResponse} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={TextResponse} {...props} />,
  },
  MatchingPair: {
    builder: (props) => <WrapperBuilder _component={MatchingPair} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={MatchingPair} {...props} />,
  },
  ChannelPicker: {
    builder: (props) => <WrapperBuilder _component={ChannelPicker} {...props} isEditor={true} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={ChannelPicker} {...props} />,
  },
  Info: {
    builder: (props) => <WrapperBuilder _component={Info} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={Info} {...props} />,
  },
  FormInputList: {
    builder: (props) => <WrapperBuilder _component={FormInputList} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={FormBuilderHomeComponent} {...props} />,
  },
  DateTimeRange: {
    builder: (props) => <WrapperBuilder _component={DateTimeRangePicker} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={DateTimeRangePicker} {...props} />,
  },
  LocationModulePicker: {
    builder: (props) => <WrapperBuilder _component={LocationModulePicker} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={LocationModulePicker} {...props} />,
  },
  TagsPicker: {
    builder: (props) => <WrapperBuilder _component={tagsPicker} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={tagsPicker} {...props} />,
  },
  AudioPicker: {
    builder: (props) => <WrapperBuilder _component={AudioPicker} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={AudioPicker} {...props} />,
  },
  ContactMethod: {
    builder: (props) => <WrapperBuilder _component={ContactMethod} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={ContactMethod} {...props} />,
  },
  DateTimePicker: {
    builder: (props) => <WrapperBuilder _component={DatePicker} {...props} timeSelectStart formatString={'yyyy-MM-dd HH:mm'} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={DatePicker} {...props} timeSelectStart formatString={'yyyy-MM-dd HH:mm'}/>,
  },
  FormInputListDB: {
    builder: (props) => <WrapperBuilder _component={FormInputList} {...props}  includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={FormBuilderHomeComponent} {...props} />,
  },
  JSON: {
    builder: (props) => <WrapperBuilder _component={JSONInputCXL} {...props}  includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={JSONInputCXL} {...props} />,
  },
  VideoIdOrURLPicker: {
    builder: (props) => <WrapperBuilder _component={VideoIdOrURLPicker} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={VideoIdOrURLPicker} {...props} />,
  },
  QuizOption: {
    builder: (props) => <WrapperBuilder _component={QuizOption} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={QuizOption} {...props} />,
  },
  VideoGallery: {
    builder: (props) => <WrapperBuilder _component={VideoGallery} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={VideoGallery} {...props} />,
  },
  StepsGamification: {
    builder: (props) => <WrapperBuilder _component={StepsGamification} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={StepsGamification} {...props} />,
  },
  StashFunctionsPicker: {
    builder: (props) => <WrapperBuilder _component={StashFunctionPicker} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={StashFunctionPicker} {...props} />,
  },
  MongoIntegrationPicker: {
    builder: (props) => <WrapperBuilder _component={MongoIntegrationPicker} {...props} includeWrapperClass={true} />,
    home_component: (props) => <WrapperGeneric _component={MongoIntegrationPicker} {...props} />,
  }
}

export default modules;
